// DeleteAgenteModal.jsx
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { delCrmAgentesApi, filterCrmAgentesApi } from 'api/crm/agentes'
import { NotificationManager } from 'react-notifications';

const DeleteAgenteModal = ({ open, onClose, agente, usuario, onSearchAgentes }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const handleConfirmDelete = async () => {
        setIsDeleting(true);
        try {

            // Sua lógica para deletar o agente
            await deleteAgenteById(agente.id);
            onClose(); // Fechar o modal após a exclusão
        } catch (error) {
            console.error("Erro ao deletar o agente:", error);
        }
        setIsDeleting(false);
    };
    const deleteAgenteById = (id) => {
        delCrmAgentesApi({ token: (usuario?.auth?.token || ''), id: id })
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    NotificationManager.success('O agente foi EXCLUÍDO com sucesso!', 'Sucesso!', 4000);
                    onSearchAgentes();
                } else {
                    // Você pode pegar a mensagem de erro do corpo aqui, se desejar
                    return res.json().then(err => {
                        NotificationManager.error(err.message || 'Houve um erro ao tentar EXCLUIR o agente!', 'Erro!', 4000);
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Excluir Agente</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Você tem certeza que deseja excluir o agente "{agente?.name}"?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button
                    onClick={handleConfirmDelete}
                    color="secondary"
                    disabled={isDeleting}
                >
                    {isDeleting ? "Deletando..." : "Excluir"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteAgenteModal;
