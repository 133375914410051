import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import {
  Typography,
  Paper,
  Tabs,
  Tab,
  CssBaseline,
  Grid,
  CircularProgress,
  Card,
  CardContent,
} from '@material-ui/core'
import _ from 'lodash'

import { Actions as DialogActions } from '../../../../store/ducks/dialog'
import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as GestorProdutosActions } from '../../../../store/ducks/gestor/produtos'
import GestorHeaderComp from '../../../../components/Gestor/Header'
import GestorDrawerComp from '../../../../components/Gestor/Drawer'
import { DadosProdutoTabContent, DadosCategoriasTabContent } from './components'

import useStyles from './styles'

const TabsContent = ({ tabSelected }) => {
  switch (tabSelected) {
    case 1:
      return <DadosCategoriasTabContent />
    case 2:
      return null // <DadosClientesTabContent />
    default:
      return <DadosProdutoTabContent />
  }
}

TabsContent.propTypes = {
  tabSelected: PropTypes.number.isRequired,
}

const GestorProdutosDetalhePage = (props) => {
  const classes = useStyles()
  const [tabSelected, setTabSelected] = useState(0)
  const handleSelectTab = (event, newValue) => setTabSelected(newValue)
  const {
    match: { params },
    push,
    usuario,
    gestorProdutos: { loading, produto },
    onLoadDadosProduto,
    onResetProduto,
  } = props

  const pageSubtitle = produto.produtoId ? `[${produto.produtoId}] ${produto.produtoSku} - ${produto.produtoDesc}` : ''
  // Consultado dados do usuário
  useEffect(() => {
    if (params.id) {
      onLoadDadosProduto(params.id)
    } else {
      onResetProduto()
    }
  }, [])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={10}>
                <Typography variant="h3" component="h3">
                  Produto
                </Typography>
                <Typography variant="h6" component="h6">
                  {loading.produto
                    ? 'Carregando...'
                    : pageSubtitle}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper className={classes.root}>
              <Tabs
                value={tabSelected}
                onChange={handleSelectTab}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Dados" disabled={loading.produto} />
                <Tab label="Categorias" disabled={loading.produto} />
                {/* <Tab label="Imagens" disabled /> */}
              </Tabs>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            {loading.produto ? <CircularProgress /> : <TabsContent tabSelected={tabSelected} />}
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

GestorProdutosDetalhePage.propTypes = {
  usuario: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  params: PropTypes.shape().isRequired,
  gestorProdutos: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
  onLoadDadosProduto: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  gestorProdutos: state.gestorProdutos,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...GestorProdutosActions,
    push,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GestorProdutosDetalhePage)
