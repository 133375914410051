import { createStore, compose, applyMiddleware } from 'redux'
import ReduxThunk from 'redux-thunk'
import logger from 'redux-logger'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createRootReducer from './ducks'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router', 'dialog', 'configs'],
}

const history = createBrowserHistory()
const persistedReducer = persistReducer(persistConfig, createRootReducer(history))
// Desenvolvimento
const middlewares = [routerMiddleware(history), ReduxThunk, logger]
// Produção
// const middlewares = [routerMiddleware(history), ReduxThunk]

const store = createStore(persistedReducer, compose(applyMiddleware(...middlewares)))
const persitor = persistStore(store)

export { store, persitor, history }
