import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push as crrPush } from "connected-react-router";
import {
  Typography,
  Grid,
  CssBaseline,
  Card,
  CardHeader,
  CardContent
} from "@material-ui/core";
// import _ from 'lodash'

import {
  findByIdCrmAgentesApi,
  addCrmAgentesApi,
  editCrmAgentesApi
} from "api/crm/agentes";

import { Actions as ConfigActions } from "store/ducks/config";
import { Actions as DialogActions } from "store/ducks/dialog";
import { Actions as CrmContatosActions } from "store/ducks/crm/contato";
import GestorHeaderComp from "components/Gestor/Header";
import GestorDrawerComp from "components/Gestor/Drawer";
import TableDataComp from "components/Table";
import Visibility from "components/Visibility";

import { DtFormat } from "utils";

import DadosAgenteContent from "../../../components/DadosAgenteContent";
import DialogAddAgendamento from "../../../components/Dialogs/DialogAddAgendamento";

import useStyles from "./styles";
import form from "pages/gestor/alcadas/form";
import ducks from "store/ducks";
import { duration } from "moment";
import { NotificationManager, NotificationContainer } from "react-notifications";
import { findAllCrmAgenteTipoApi } from "api/crm/agente_tipo";


const GestorCrmAgenteDetailPage = props => {
  const {
    match: { params },
    usuario,
    crrPush
  } = props;

  const classes = useStyles();

  // * State's
  const [isAdd, setIsAdd] = useState(false);

  // * Vistorias
  const [listVistorias, setListVistorias] = useState([]);
  // * Agendamento
  const [dialogAgendamento, setDialogAgendamento] = useState(false);
  const [payloadAgendamento, setPayloadAgendamento] = useState();
  const [listAgendamento, setListAgendamento] = useState({});

  const [loading, setLoading] = useState({
    saveForm: false,
    loadingAtendimentos: false
  });
  const [payload, setPayload] = useState({});
  const [agenteTipo, setAgenteTipo] = useState({});

  console.log(loading)
  const doubleLine = (text1, text2) => (
    <Grid container direction="column">
      <Grid>
        <strong>{text1}</strong>
      </Grid>
      <Grid>
        <span>{text2}</span>
      </Grid>
    </Grid>
  );
  const rowVistoria = r => {
    const { oportunidadeId, realizada, descricao } = r;
    return (
      <div className={classes.rowTable}>
        {doubleLine(
          `Oportunidade nº: ${oportunidadeId}, Realizada: ${realizada ? "SIM" : "NÃO"
          } `,
          descricao
        )}
      </div>
    );
  };
  const rowAgendamento = r => {
    const {
      crmOportunidadeId,
      dateAgenda,
      title,
      usuarioNome,
      crmAgentesName
    } = r;
    return (
      <div className={classes.rowTable}>
        <Grid container direction="column">
          <Grid>
            <strong>{`[${DtFormat(
              dateAgenda
            )}] Oportunidade: ${crmOportunidadeId ?? "S/D"}`}</strong>
          </Grid>
          <Grid>
            <span>{`Para: ${crmAgentesName || usuarioNome}`}</span>
          </Grid>
          <Grid>
            <small>{title}</small>
          </Grid>
        </Grid>
      </div>
    );
  };
  const onLoadAgenteData = () => {
    const agenteId = params.id;
    if (agenteId) {
      findByIdCrmAgentesApi({
        token: usuario?.auth?.token ?? "",
        id: agenteId
      }).then(resp => {
        if (resp) {
          setPayload({ ...resp, isAdd });
          setListVistorias(resp?.vistorias ?? []);
          setListAgendamento(resp?.agendas ?? []);
        }
      });
    } else {
      setIsAdd(true);
      setPayload({ isAdd: true, status: "L" });
    }
  };
  const onLoadAgenteTipo = () => {
    findAllCrmAgenteTipoApi({ token: usuario?.auth?.token ?? "" }).then(resp => {
      setAgenteTipo(resp)
    })
  }

  const onSaveForm = formData => {
    if (formData.id) {
      setLoading(s => ({ ...s, saveForm: true }));
      editCrmAgentesApi({
        token: usuario?.auth?.token ?? "",
        payload: { ...formData, filialId: usuario?.unidade?.id ?? 0 },
        id: formData.id
      })
        .then(() => {
          NotificationManager.success('O agente foi EDITADO com sucesso!', 'Sucesso!', 4000);
          onLoadAgenteData();
        })
        .finally(() => setLoading(s => ({ ...s, saveForm: false })));
    } else {
      if (
        formData.code != null &&
        formData.usuarioCelular != null &&
        formData.usuarioCpf != null &&
        formData.usuarioEmail != null &&
        formData.usuarioNome != null &&
        formData.usuarioSenha != null &&
        formData.usuarioReSenha !== null &&
        formData.usuarioSenha === formData.usuarioReSenha
      ) {
        setLoading(s => ({ ...s, saveForm: true }));
        addCrmAgentesApi({
          token: usuario?.auth?.token ?? "",
          payload: { ...formData, filialId: usuario?.unidade?.id ?? 0 }
        })
          .then(res => {
            // onLoadAgenteData()]
            console.log(res)
            if (res && res.body || res == null) {
              console.log(res);
            } else {
              crrPush('/gestor/crm/agentes');
              NotificationManager.success('O agente foi CRIADO com sucesso!', 'Sucesso!', 4000);
            }


          })
          .finally(() => setLoading(s => ({ ...s, saveForm: false })));
      } else {
        NotificationManager.warning('Por favor preencher todos os campos');
      }
    }
  };
  const showAgendamento = pPayload => {
    setPayloadAgendamento({
      ...pPayload,
      disabled: true
    });
    setDialogAgendamento(true);
  };

  const tableVistoriasCols = [
    {
      key: "",
      title: "VISTORIA",
      format: rowVistoria
    }
  ];
  const tableAgendamentosCols = [
    { key: "", title: "AGENDA", format: rowAgendamento }
  ];
  const tableAgendamentosActions = [
    {
      key: "visibility",
      title: "Exibir",
      icon: "visibility",
      action: showAgendamento
    }
  ];

  useEffect(() => {
    document.title = "Online - Agente";
    document.body.style = "background-image: none;";
    onLoadAgenteData();
    onLoadAgenteTipo();
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={1}>
          {/* Header */}
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" component="h2">
                  Agente
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* Body */}
          <Grid item xs={12} md={12}>
            <Grid container spacing={1}>
              {/* Informações do agente */}
              <Grid item xs={12}>
                <Card elevation={1}>
                  <CardHeader title="Informações do agente" />
                  <CardContent>
                    <DadosAgenteContent
                      loading={loading?.saveForm ?? false}
                      payload={payload}
                      onSave={onSaveForm}
                      agenteTipo={agenteTipo}
                    />
                  </CardContent>
                </Card>
              </Grid>
              {/* Vistorias */}
              <Visibility show={!isAdd}>
                <Grid item xs={12} md={6}>
                  <Card elevation={1} className={classes.fullHeight}>
                    <CardHeader title="Histórico de Vistorias" />
                    <CardContent>
                      <TableDataComp
                        size="small"
                        // loading={loading.aprovacoes}
                        rows={listVistorias}
                        cols={tableVistoriasCols}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Visibility>
              {/* Agendamentos */}
              <Visibility show={!isAdd}>
                <Grid item xs={12} md={6}>
                  <Card elevation={1}>
                    <CardHeader title="Agendamentos" />
                    <CardContent>
                      <TableDataComp
                        size="small"
                        // loading={loading.aprovacoes}
                        rows={listAgendamento || []}
                        cols={tableAgendamentosCols}
                        actions={tableAgendamentosActions}
                      />
                      <DialogAddAgendamento
                        token={usuario?.auth?.token ?? ""}
                        open={dialogAgendamento}
                        payload={payloadAgendamento}
                        onClose={() => setDialogAgendamento(false)}
                      // onSave={() => onGetClientInfo()}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Visibility>
            </Grid>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

GestorCrmAgenteDetailPage.propTypes = {
  match: PropTypes.shape().isRequired,
  usuario: PropTypes.shape().isRequired,
  crmContatos: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
  onFilterCrmContatos: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  crmContatos: state.crmContatos
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...DialogActions,
      ...ConfigActions,
      ...CrmContatosActions,
      crrPush
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GestorCrmAgenteDetailPage);
