import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ShipIcon from '@material-ui/icons/LocalShipping'
import TextField from '@material-ui/core/TextField'
import Imagens, { ImagensJpg, ImagensGVB } from './Imagens'

const styles = theme => ({
  card: {
    marginTop: 20,
    maxWidth: 230,
  },
  media: {
    height: 140,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  btnAdd: {
    paddingLeft: 30,
    paddingRight: 40,
    marginLeft: theme.defaults.margin * 2,
    backgroundColor: theme.palette.primary.light,
    color: theme.semar.text.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
})

const ProdutoCard = (props) => {
  const {
    classes, data, fator, config, onAddProdutoCarrinho, onUpdateProdutoQuant,
  } = props
  const {
    // produtoId,
    produtoSku,
    produtoUM,
    produtoDesc,
    produtoQuant = '',
    produtoQuantMin = '',
    // imagens = [],
    imagem = require('../../assets/imagens/produto/NoImage.png'),
    unidade,
    unidades,
  } = data
  const { mostraPreco } = config
  const produtoImagem = ImagensGVB[produtoSku.toUpperCase()] || imagem

  const handleAddItemPedido = () => {
    // onAddProdutoCarrinho(data)
    const { tabelasPreco } = unidades.find(u => u.id === unidade.id)
    const ativo = tabelasPreco.find(tb => tb.ativo)
    if (ativo) {
      onAddProdutoCarrinho(data)
    } else {
      NotificationManager.info(
        `No momento não existe tabela de preço liberada para o produto selecionado, você deve entrar em contato com a unidade ${unidade.descricao} para solicitar a liberação.`,
        'Atenção:',
        18000,
      )
    }
  }

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.media}
        // image={_.isEmpty(imagens) ? imagem : imagens[0].uri}
        // image={`http://central.mizu.com.br:8080/Sirius/uploads/produto/${produtoSku.toUpperCase()}.png`}
        // image={require(`../../assets/imagens/produto/${produtoSku.toUpperCase()}.png`)}
        image={produtoImagem}
        src="img"
        title={produtoDesc}
      />
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" gutterBottom align="center">
          {produtoDesc}
        </Typography>
        <Typography component="p">{`${produtoUM} - ${produtoSku}`}</Typography>
        {mostraPreco && (
          <Typography component="p" style={{ fontSize: 18, fontWeight: '900', marginTop: 20 }}>
            {`R$ ${unidades[0].tabelasPreco[0].valor}`}
          </Typography>
        )}
      </CardContent>
      <CardActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 60,
        }}
        disableSpacing
      >
        <TextField
          id="produtoQuant"
          label="Qtd."
          placeholder={produtoQuantMin}
          type="number"
          style={{ marginBottom: 20 }}
          InputLabelProps={{
            shrink: true,
          }}
          value={produtoQuant === 0 ? '' : produtoQuant}
          onChange={evt => onUpdateProdutoQuant(data, evt.target.value)}
          onKeyPress={(evt) => {
            if (evt.charCode === 13) {
              handleAddItemPedido()
            }
          }}
          margin="normal"
        />
        <Button
          aria-label="adicionar ao carrinho"
          className={classes.btnAdd}
          onClick={handleAddItemPedido}
        >
          <ShipIcon style={{ fontSize: 16, marginLeft: 5, marginRight: 5 }} />
          Adicionar
        </Button>
      </CardActions>
    </Card>
  )
}

ProdutoCard.propTypes = {
  classes: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  config: PropTypes.shape({
    mostraPreco: PropTypes.bool.isRequired,
  }),
  onAddProdutoCarrinho: PropTypes.func.isRequired,
  onUpdateProdutoQuant: PropTypes.func.isRequired,
}

ProdutoCard.defaultProps = {
  config: {
    mostraPreco: false,
  },
}

export default withStyles(styles)(ProdutoCard)
