const styles = theme => ({
  buttonDark: {
    backgroundColor: theme.palette.primary.light,
    color: theme.semar.text.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  root: {
    flexGrow: 1,
  },
  headerTitle: {
    flex: 1,
    flexDirection: 'colunn',
  },
  headerFilialTitle: {
    fontSize: 18,
    margin: 0,
    padding: 0,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})

export default styles
