const Imagens = {
  CE5: require('../../../assets/imagens/produto/CE5.png'),
  CE32MNEX: require('../../../assets/imagens/produto/CE32MNEX.png'),
  CE232: require('../../../assets/imagens/produto/CE232.png'),
  CE232E: require('../../../assets/imagens/produto/CE232E.png'),
  CE232EX: require('../../../assets/imagens/produto/CE232EX.png'),
  CE232F: require('../../../assets/imagens/produto/CE232F.png'),
  CE232MN: require('../../../assets/imagens/produto/CE232MN.png'),
  CE232Z: require('../../../assets/imagens/produto/CE232Z.png'),
  CE240: require('../../../assets/imagens/produto/CE240.png'),
  CE240A: require('../../../assets/imagens/produto/CE240A.png'),
  CE240MN: require('../../../assets/imagens/produto/CE240MN.png'),
  CE240MNEX: require('../../../assets/imagens/produto/CE240MNEX.png'),
  CE240MNZ: require('../../../assets/imagens/produto/CE240MNZ.png'),
  CE332: require('../../../assets/imagens/produto/CE332.png'),
  CE332MN: require('../../../assets/imagens/produto/CE332MN.png'),
  CE340: require('../../../assets/imagens/produto/CE340.png'),
  CE432: require('../../../assets/imagens/produto/CE432.png'),
  CE432MN: require('../../../assets/imagens/produto/CE432MN.png'),
  CEB2: require('../../../assets/imagens/produto/CEB2.png'),
  CEB3: require('../../../assets/imagens/produto/CEB3.png'),
  CEB5: require('../../../assets/imagens/produto/CEB5.png'),
  CET232: require('../../../assets/imagens/produto/CET232.png'),
  CET340: require('../../../assets/imagens/produto/CET340.png'),
}

export const ImagensJpg = {
  CE5: require('../../../assets/imagens/produto/jpg/CE5.jpg'),
  CE32MNEX: require('../../../assets/imagens/produto/jpg/CE32MNEX.jpg'),
  CE232: require('../../../assets/imagens/produto/jpg/CE232.jpg'),
  CE232E: require('../../../assets/imagens/produto/jpg/CE232E.jpg'),
  CE232EX: require('../../../assets/imagens/produto/jpg/CE232EX.jpg'),
  CE232F: require('../../../assets/imagens/produto/jpg/CE232F.jpg'),
  CE232MN: require('../../../assets/imagens/produto/jpg/CE232MN.jpg'),
  CE232Z: require('../../../assets/imagens/produto/jpg/CE232Z.jpg'),
  CE240: require('../../../assets/imagens/produto/jpg/CE240.jpg'),
  CE240A: require('../../../assets/imagens/produto/jpg/CE240A.jpg'),
  CE240MN: require('../../../assets/imagens/produto/jpg/CE240MN.jpg'),
  CE240MNEX: require('../../../assets/imagens/produto/jpg/CE240MNEX.jpg'),
  CE240MNZ: require('../../../assets/imagens/produto/jpg/CE240MNZ.jpg'),
  CE332: require('../../../assets/imagens/produto/jpg/CE332.jpg'),
  CE332MN: require('../../../assets/imagens/produto/jpg/CE332MN.jpg'),
  CE340: require('../../../assets/imagens/produto/jpg/CE340.jpg'),
  CE432: require('../../../assets/imagens/produto/jpg/CE432.jpg'),
  CE432MN: require('../../../assets/imagens/produto/jpg/CE432MN.jpg'),
  CEB2: require('../../../assets/imagens/produto/jpg/CEB2.jpg'),
  CEB3: require('../../../assets/imagens/produto/jpg/CEB3.jpg'),
  CEB5: require('../../../assets/imagens/produto/jpg/CEB5.jpg'),
  CET232: require('../../../assets/imagens/produto/jpg/CET232.jpg'),
  CET340: require('../../../assets/imagens/produto/jpg/CET340.jpg'),
}

export const ImagensGVB = {
  210001: require('../../../assets/imagens/produto/gvb/210001.png'),
  210002: require('../../../assets/imagens/produto/gvb/210002.png'),
  210003: require('../../../assets/imagens/produto/gvb/210003.png'),
  210004: require('../../../assets/imagens/produto/gvb/210004.png'),
  210005: require('../../../assets/imagens/produto/gvb/210005.png'),
  210006: require('../../../assets/imagens/produto/gvb/210006.png'),
  210007: require('../../../assets/imagens/produto/gvb/210007.png'),
  210008: require('../../../assets/imagens/produto/gvb/210008.png'),
  210009: require('../../../assets/imagens/produto/gvb/210009.png'),
  210010: require('../../../assets/imagens/produto/gvb/210010.png'),
  210011: require('../../../assets/imagens/produto/gvb/210011.png'),
  210012: require('../../../assets/imagens/produto/gvb/210012.png'),
  210013: require('../../../assets/imagens/produto/gvb/210013.png'),
  210014: require('../../../assets/imagens/produto/gvb/210014.png'),
  210015: require('../../../assets/imagens/produto/gvb/210015.png'),
  210016: require('../../../assets/imagens/produto/gvb/210016.png'),
  210017: require('../../../assets/imagens/produto/gvb/210017.png'),
  210018: require('../../../assets/imagens/produto/gvb/210018.png'),
  210019: require('../../../assets/imagens/produto/gvb/210019.png'),
  210020: require('../../../assets/imagens/produto/gvb/210020.png'),
  210021: require('../../../assets/imagens/produto/gvb/210021.png'),
  210022: require('../../../assets/imagens/produto/gvb/210022.png'),
  210023: require('../../../assets/imagens/produto/gvb/210023.png'),
  210024: require('../../../assets/imagens/produto/gvb/210024.png'),
  210025: require('../../../assets/imagens/produto/gvb/210025.png'),
  210026: require('../../../assets/imagens/produto/gvb/210026.png'),
  210027: require('../../../assets/imagens/produto/gvb/210027.png'),
  210028: require('../../../assets/imagens/produto/gvb/210028.png'),
  210029: require('../../../assets/imagens/produto/gvb/210029.png'),
  210030: require('../../../assets/imagens/produto/gvb/210030.png'),
  210031: require('../../../assets/imagens/produto/gvb/210031.png'),
  210032: require('../../../assets/imagens/produto/gvb/210032.png'),
  210033: require('../../../assets/imagens/produto/gvb/210033.png'),
}

export default Imagens
