import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from 'api'

const ref = '/visita_imagem_tipo'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - { token: String, id }
 */
export const findAllVisitaImagemTipoAPI = (token) => {
    
    return fetch(`${API_URL}${ref}/`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(httpParseJson)
      .then(httpValidState)
      .catch(httpValidErros)
  }