const styles = theme => ({
  root: {
    paddingTop: 20,
  },
  actions: {
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 20,
    marginTop: 20,
  },
  grid: {
    marginLeft: theme.defaults.margin * 2,
    marginRight: theme.defaults.margin * 2,
  },
  button: {
    marginRight: 5,
    marginBottom: 5,
  },
  leftIcon: {
    marginRight: theme.defaults.margin / 2,
  },
  iconSmall: {
    fontSize: 20,
  },
  btnCondPagamento: {
    color: theme.semar.text.blue,
  },
  btnFinalizarPedido: {
    color: theme.semar.text.green,
  },
  formContent: {
    paddingTop: 10,
  },
  formControl: {
    width: '100%',
  },
  qtdCarrinho: {
    textAlign: 'center',
  },
  loadingMotoristas: { textAlign: 'center', paddingBottom: 20 },
})

export default styles
