import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
    Typography,
    Card,
    Grid,
    Fab,
    Icon,
    Tooltip,
    TextField,
    InputAdornment,
    IconButton,
    CssBaseline,
    Input,
    Button,
} from '@material-ui/core'

// import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import GestorHeaderComp from '../../../../../components/Gestor/Header'
import GestorDrawerComp from '../../../../../components/Gestor/Drawer'
import TableDataComp from '../../../../../components/Table'
import useStyles from './styles'
import { getImportacaoCsvLogsApi, importacaoCsvApi } from './../../../../../api/lead/importacaocsv/index';
import DialogAddImportacaoCsv from '../../components/DialogAddImportacaoCsv'



const GestorImportacaoListPage = (props) => {
    const {
        usuario, push, onDialogOpen, onDialogClose
    } = props
    const classes = useStyles()

    // State
    const [listFilter, setListFilter] = useState([])
    const [filter, setFilter] = useState({
        page: 0,
        rows: 100,
        search: '',
    })
    const [payload, setpayload] = useState([])
    const tableCols = [
        { key: 'usuario', title: 'USER' },
        { key: 'fileName', title: 'NOME ARQUIVO' },
        { key: 'createdDate', title: 'DATA' },
        { key: 'status', title: 'STATUS' },
    ]

    const OnSearchLeads = () => {
        getImportacaoCsvLogsApi({ token: usuario?.auth?.token ?? '', query: filter })
            .then((resp) => {
                setpayload(resp)
            })
    }

    useEffect(() => {
        document.title = 'Online - Importação'
        document.body.style = 'background-image: none;'
        OnSearchLeads()
    }, [])
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    return (
        <div className={classes.root}>
            <CssBaseline />
            <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
            <GestorDrawerComp {...props} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h3" component="h2">
                                    Importações CSV
                                </Typography>
                            </Grid>
                            {/* Menus */}
                            <Grid item xs={12} md={6}>
                                <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                                    <Grid item>
                                        <Fab
                                            color="primary"
                                            className={classes.fab}
                                            onClick={handleOpenDialog}
                                        >
                                            CSV
                                        </Fab>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <DialogAddImportacaoCsv
                                open={openDialog}
                                onSave={handleCloseDialog}
                                onClose={handleCloseDialog}
                                payload={payload}
                                usuario={usuario}
                            />
                        </Grid>
                    </Grid>
                    {/* Body */}
                    <Grid item xs={12} md={12}>
                        <TableDataComp
                            size="small"
                            loading={false}
                            rows={payload}
                            cols={tableCols}
                        />
                    </Grid>
                </Grid>
            </main>
        </div>
    )
}

GestorImportacaoListPage.propTypes = {
    usuario: PropTypes.shape().isRequired,
    push: PropTypes.func.isRequired,
    onDialogOpen: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        ...ConfigActions,
        push: crrPush,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GestorImportacaoListPage)
