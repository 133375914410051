import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
    Typography,
    Card,
    Grid,
    Fab,
    Icon,
    Tooltip,
    TextField,
    InputAdornment,
    IconButton,
    CssBaseline,
    Input,
    Button,
    Paper,
    Divider,
} from '@material-ui/core'

import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import useStyles from './styles'
import { findByIdEntregaApi } from 'api/entrega'

const EntregaProdutoTabContent = (props) => {
    const {
        usuario,
        push,
        params
    } = props
    const [entrega, setEntrega] = useState([])


    const classes = useStyles()
    // State
    const fetchDataById = async (token, id) => {
        try {
            const response = await findByIdEntregaApi({ token: token, id: id });
            setEntrega(response)
            return response.data; // Retorna os dados da resposta
        } catch (error) {
            console.error('Erro ao buscar os dados por ID:', error);
            return null;
        }
    };
    const onUpdateEntregaField = (field, value) => {
        setEntrega((prevEntrega) => ({
            ...prevEntrega,
            [field]: value,
        }));
    };

    const saveEntrega = () => {
        // Implemente a lógica para salvar a entrega aqui
    };

    useEffect(() => {
        document.title = 'Online - Entrega Detalhe'
        document.body.style = 'background-image: none;'
        fetchDataById(usuario?.auth?.token, params.id)

    }, [])
    function formatToBRL(value) {
        if (!value) return '';
        return `R$ ${parseFloat(value).toFixed(2).replace('.', ',')}`;
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <Grid container spacing={2}>
                    {/* Body */}
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>

                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="produtoDescricao"
                                        label="Descrição do Produto"
                                        margin="normal"
                                        variant="outlined"
                                        value={entrega.produtoDescricao ? entrega.produtoDescricao.toString().toUpperCase() : ''}
                                        className={classes.textField}
                                        onChange={(e) => onUpdateEntregaField('produtoDescricao', e.target.value)}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                cursor: 'not-allowed'
                                            },
                                        }}
                                        disabled
                                    />
                                </Grid>


                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="oportunidadeItemTabelaPrecoProdutoValor"
                                        label="Valor"
                                        margin="normal"
                                        variant="outlined"
                                        value={formatToBRL(entrega.oportunidadeItemTabelaPrecoProdutoValor)}
                                        className={classes.textField}
                                        onChange={(e) => onUpdateEntregaField('oportunidadeItemTabelaPrecoProdutoValor', e.target.value.replace('R$ ', ''))}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                cursor: 'not-allowed'
                                            },
                                        }}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="oportunidadeItemTabelaPrecoProdutoValorMin"
                                        label="Parcela"
                                        margin="normal"
                                        variant="outlined"
                                        value={formatToBRL(entrega.oportunidadeItemTabelaPrecoProdutoValorMin)}
                                        className={classes.textField}
                                        onChange={(e) => onUpdateEntregaField('oportunidadeItemTabelaPrecoProdutoValorMin', e.target.value.replace('R$ ', ''))}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                cursor: 'not-allowed'
                                            },
                                        }}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="oportunidadeItemPagamentoCondicaoDescricao"
                                        label="Condição de Pagamento"
                                        margin="normal"
                                        variant="outlined"
                                        value={entrega.oportunidadeItemPagamentoCondicaoDescricao ? entrega.oportunidadeItemPagamentoCondicaoDescricao.toString().toUpperCase() : ''}
                                        className={classes.textField}
                                        onChange={(e) => onUpdateEntregaField('oportunidadeItemPagamentoCondicaoDescricao', e.target.value)}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                cursor: 'not-allowed'
                                            },
                                        }}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="oportunidadeItemTabelaPrecoDescricao"
                                        label="Tabela de Preço"
                                        margin="normal"
                                        variant="outlined"
                                        value={entrega.oportunidadeItemTabelaPrecoDescricao ? entrega.oportunidadeItemTabelaPrecoDescricao.toString().toUpperCase() : ''}
                                        className={classes.textField}
                                        onChange={(e) => onUpdateEntregaField('oportunidadeItemTabelaPrecoDescricao', e.target.value)}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                cursor: 'not-allowed'
                                            },
                                        }}
                                        disabled
                                    />
                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </main>
        </div>

    )
}

EntregaProdutoTabContent.propTypes = {
    loading: PropTypes.shape().isRequired,
    gestorUsuarios: PropTypes.shape().isRequired,
    push: PropTypes.func.isRequired,
    onDialogOpen: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
    onAddUsuarioCliente: PropTypes.func.isRequired,
    onDeleteUsuarioCliente: PropTypes.func.isRequired,
    onSearchUsuarioCliente: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
    gestorUsuarios: state.gestorUsuarios,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        ...ConfigActions,
        push: crrPush,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EntregaProdutoTabContent)
