import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import {
  Typography,
  Card,
  Grid,
  Fab,
  Icon,
  Tooltip,
  TextField,
  InputAdornment,
  IconButton,
  CssBaseline,
} from '@material-ui/core'

// import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { push as crrPush } from 'connected-react-router'
import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import { Actions as CrmContatosActions } from 'store/ducks/crm/contato'
import GestorHeaderComp from 'components/Gestor/Header'
import GestorDrawerComp from 'components/Gestor/Drawer'
import TableDataComp from 'components/Table'
import useStyles from './styles'
import GestorDashboardPage from "../../../../pages/gestor/dashboard"

const CrmDashboardPage = (props) => {
  const {
    usuario, crmContatos, push, onFilterCrmContatos,
  } = props
  const classes = useStyles()
  // State
  const [iptSearch, setIptSearch] = useState('')
  const tableCols = [
    { key: 'id', title: 'ID' },
    { key: 'setor', title: 'SETOR' },
    { key: 'tipo', title: 'TIPO' },
    { key: 'codigo', title: 'CÓDIGO' },
    { key: 'nome', title: 'NOME' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]
  const tableActionsList = [
    /*
    {
      key: 'bloquear',
      title: 'Bloquear',
      icon: 'clear',
      action: row => null,
    },
    */
    {
      key: 'detalhes',
      title: 'Detalhes',
      icon: 'list',
      action: row => push(`/crm/contatos/detail/${row.id}`),
    },
  ]

  useEffect(() => {
    document.title = 'Online - Dashboard'
    document.body.style = 'background-image: none;'
  }, [])

  return (
    <GestorDashboardPage/>
  )
}

CrmDashboardPage.propTypes = {
  usuario: PropTypes.shape().isRequired,
  crmContatos: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
  onFilterCrmContatos: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  crmContatos: state.crmContatos,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...CrmContatosActions,
    push: crrPush,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CrmDashboardPage)
