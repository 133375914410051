import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from '..'

const ref = '/agenda'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - { token: String, page, rows, search, prospectId, oportunidadeId }
 */
export const filterAgendaApi = (dto) => {
  const {
    token,
    query: {
      page = 0, rows = 50, search = '', status, code, usuarioId, crmAgentesId, crmOportunidadeId, crmProspectId, dateStartAgenda, dateAgenda, agendaTipoId, dateEndAgenda
    },
  } = dto

  let queryUrl = '?1=1'
  queryUrl += page ? `&page=${page}` : ''
  queryUrl += rows ? `&rows=${rows}` : ''
  queryUrl += search ? `&search=${search}` : ''
  queryUrl += status ? `&status=${status}` : ''
  queryUrl += code ? `&code=${code}` : ''
  queryUrl += usuarioId ? `&usuarioId=${usuarioId}` : ''
  queryUrl += crmAgentesId ? `&crmAgentesId=${crmAgentesId}` : ''
  queryUrl += crmOportunidadeId ? `&crmOportunidadeId=${crmOportunidadeId}` : ''
  queryUrl += crmProspectId ? `&crmProspectId=${crmProspectId}` : ''
  queryUrl += dateStartAgenda ? `&dateStartAgenda=${dateStartAgenda}` : ''
  queryUrl += dateEndAgenda ? `&dateEndAgenda=${dateEndAgenda}` : ''
  queryUrl += dateAgenda ? `&dateAgenda=${dateAgenda}` : ''
  queryUrl += agendaTipoId ? `&agendaTipoId=${agendaTipoId}` : ''

  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}
/**
 *
 * @param {String} dto - { token: String, page, rows, search, prospectId, oportunidadeId }
 */
export const filterPaginatorAgendaApi = (dto) => {
  const {
    token,
    query: {
      page = 0, rows = 50, search = '', status, code, usuarioId, crmAgentesId, crmOportunidadeId, crmProspectId, dateStartAgenda, dateAgenda, agendaTipoId, dateEndAgenda
    },
  } = dto

  let queryUrl = '?1=1'
  queryUrl += page ? `&page=${page}` : ''
  queryUrl += rows ? `&rows=${rows}` : ''
  queryUrl += search ? `&search=${search}` : ''
  queryUrl += status ? `&status=${status}` : ''
  queryUrl += code ? `&code=${code}` : ''
  queryUrl += usuarioId ? `&usuarioId=${usuarioId}` : ''
  queryUrl += crmAgentesId ? `&crmAgentesId=${crmAgentesId}` : ''
  queryUrl += crmOportunidadeId ? `&crmOportunidadeId=${crmOportunidadeId}` : ''
  queryUrl += crmProspectId ? `&crmProspectId=${crmProspectId}` : ''
  queryUrl += dateStartAgenda ? `&dateStartAgenda=${dateStartAgenda}` : ''
  queryUrl += dateEndAgenda ? `&dateEndAgenda=${dateEndAgenda}` : ''
  queryUrl += dateAgenda ? `&dateAgenda=${dateAgenda}` : ''
  queryUrl += agendaTipoId ? `&agendaTipoId=${agendaTipoId}` : ''

  return fetch(`${API_URL}${ref}/filter/${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, id }
 */
export const findByIdAgendaApi = (dto) => {
  const { token, id } = dto
  return fetch(`${API_URL}${ref}/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const addAgendaApi = ({ token, payload }) => fetch(`${API_URL}${ref}`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const editAgendaApi = ({ token, id, payload }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do produto, fk: ID da categoria }
 */
export const delAgendaApi = ({ token, id }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)


/**
 *
 * @param {String} token - Token de autenticação
 * @param {Number} id - Id da Agenda
 * @param {Number} motivoId - Id do motivo de cancelamento
 */
export const delAgendaApiByProspect = ({ token, id, motivoId }) => fetch(`${API_URL}${ref}/${id}/${motivoId}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

