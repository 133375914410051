import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push as crrPush } from 'connected-react-router';
import classNames from 'classnames';
import {
  Typography,
  Grid,
  Fab,
  Icon,
  Tooltip,
  TextField,
  InputAdornment,
  IconButton,
  CssBaseline,
} from '@material-ui/core';
import { Actions as ConfigActions } from 'store/ducks/config';
import { Actions as DialogActions } from 'store/ducks/dialog';
import { Actions as CrmContatosActions } from 'store/ducks/crm/contato';
import GestorHeaderComp from 'components/Gestor/Header';
import GestorDrawerComp from 'components/Gestor/Drawer';
import TableDataComp from 'components/Table';
import useStyles from './styles';
import { filterCrmOportunidadeApi } from 'api/crm/oportunidade';
import { Pagination } from '@material-ui/lab';

const GestorCrmOportunidadesListPage = (props) => {
  const {
    usuario, push,
  } = props;
  const classes = useStyles();
  const [filter, setFilter] = useState({
    rows: 20,
    search: '',
  });
  const [payload, setPayload] = useState({ data: [], total: 0 });
  const tableCols = [
    { key: 'id', title: 'ID' },
    { key: 'prospectId', title: 'PROSPECT ID' },
    { key: 'prospectRazao', title: 'NOME' },
    { key: 'usuarioNome', title: 'USUARIO' },
    { key: 'emissao', title: 'EMISSÃO' },
    { key: 'pedidoStatusDescricao', title: 'STATUS' },
    { key: 'total', title: 'TOTAL', format: (r) => `R$:${r.total}` },
  ];
  const tableActionsList = [
    {
      key: 'detalhes',
      title: 'Detalhes',
      icon: 'list',
      action: (row) => push(`/gestor/crm/prospects/detail/${row.prospectId}/${row.id}`),
    },
  ];
  const onIptSearchChange = (evt) => {
    evt.persist();
    setFilter((s) => ({ ...s, search: evt?.target?.value?.toUpperCase() ?? '' }));
  };
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const OnSearchOportunidades = (page = 1) => {
    if (page === 1 && currentPage !== page) {
      setCurrentPage(page);
    } else {
      filterCrmOportunidadeApi({ token: usuario?.auth?.token ?? '', query: { ...filter, page: page - 1 } })
        .then((resp) => {
          // Check if resp is truthy before accessing its properties
          if (resp) {
            setPayload(resp);
          } else {
            // Handle the case when resp is falsy (null or undefined)
            console.error('API response is falsy:', resp);
            setPayload({ data: [], total: 0 });
          }
        })
        .catch((error) => {
          console.error('Erro ao buscar oportunidades:', error);
          setPayload({ data: [], total: 0 });
        });
    }
  };

  useEffect(() => {
    document.title = 'Online - Oportunidades';
    document.body.style = 'background-image: none;';
    OnSearchOportunidades(currentPage);
  }, [currentPage, usuario]);

  useEffect(() => {
    document.title = 'Online - Oportunidade';
    document.body.style = 'background-image: none;';
    OnSearchOportunidades();
  }, [usuario]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" component="h2">
                  Oportunidades
                </Typography>
              </Grid>
              {/* Menus */}
              <Grid item xs={12} md={6}>
                <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <TextField
                      id="outlined-adornment-password"
                      className={classNames(classes.margin, classes.textField)}
                      variant="outlined"
                      label="Pesquisar"
                      value={filter?.search ?? ''}
                      onChange={onIptSearchChange}
                      onKeyPress={(evt) => {
                        if (evt.charCode === 13) {
                          OnSearchOportunidades();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Pesquisar"
                              onClick={OnSearchOportunidades}
                            >
                              <Icon>search</Icon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Body */}
          <Grid item xs={12} md={12}>
            <TableDataComp
              size="small"
              loading={false}
              rows={payload.data}
              cols={tableCols}
              actions={tableActionsList}
            />
          </Grid>
          <Pagination
            count={payload.total ?? 0}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Grid>
      </main>
    </div>
  );
};

GestorCrmOportunidadesListPage.propTypes = {
  usuario: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  usuario: state.usuario.usuario,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...CrmContatosActions,
    push: crrPush,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GestorCrmOportunidadesListPage);
