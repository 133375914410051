/* eslint-disable import/no-dynamic-require */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import config from 'react-global-configuration'
import Grid from '@material-ui/core/Grid'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import {
  Description,
  Update as UpdateIcon,
  FormatListBulleted as FormatLineSpacingIcon,
  AttachMoney as MoneyIcon,
  Home as HomeIcon,
  LocalShipping as ShipIcon,
} from '@material-ui/icons'

// Actions
import { Actions as UsuarioActions } from '../../store/ducks/usuario'
import { Actions as ConfigActions } from '../../store/ducks/config'
// Components
import Visibility from '../Visibility'
// Styles
import styles from './styles'

class NavComp extends Component {
  static propTypes = {
    usuario: PropTypes.shape().isRequired,
    classes: PropTypes.shape().isRequired,
    itensCarrinho: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    getParametro: PropTypes.func.isRequired,
  }

  componentDidMount() { }

  render() {
    const {
      usuario, classes, itensCarrinho, getParametro,
    } = this.props
    const isRealizaVendas = getParametro('REALIZA_VENDAS', 'SIM') === 'SIM'
    const pDescCarrinho = getParametro('DESC_CARRINHO', 'CAMINHÃO')
    const pUsaPedidoPadrao = getParametro('USA_PEDIDO_PADRAO', 'NAO') === 'SIM'
    const configDefaultAltLogoLeft = config.get('default.altLogoLeft')
    const configDefaultAltLogoRight = config.get('default.altLogoRight')
    const configDefaultLogoLeft = config.get('default.logoLeft')
    const configDefaultLogoRight = config.get('default.logoRight')
    return (
      <div className={classes.root}>
        <Grid container className={classes.root}>
          {/* LOGO LEFT */}
          <Grid item xs={2} md={2} zeroMinWidth>
            <Grid
              container
              style={{ height: 90 }}
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Link to="/home">
                  {configDefaultLogoLeft && <img alt={configDefaultAltLogoLeft} src={require(`../../assets/imagens/${configDefaultLogoLeft}`)} />}
                </Link>
              </Grid>
            </Grid>
          </Grid>
          {/* MENU NAV */}
          <Grid item xs={8} md={8}>
            <Grid
              container
              style={{ height: 90 }}
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              {/* Inicio */}
              <Grid item>
                <Button
                  disabled={!isRealizaVendas}
                  component={Link}
                  to="/home"
                  variant="contained"
                  className={[classes.buttonDark, classes.buttonNav].join(' ')}
                >
                  <HomeIcon className={classes.buttonLeftIton} />
                  Inicio
                </Button>
              </Grid>
              {/* Pedido Padrão */}
              <Visibility show={pUsaPedidoPadrao}>
                <Grid item>
                  <Button
                    disabled={!isRealizaVendas}
                    component={Link}
                    to="/pedido-padrao"
                    variant="contained"
                    className={[classes.buttonDark, classes.buttonNav].join(' ')}
                  >
                    <UpdateIcon className={classes.buttonLeftIton} />
                    Pedido Padrão
                  </Button>
                </Grid>
              </Visibility>
              {/* Financeiro */}
              <Grid item>
                <Button
                  component={Link}
                  to="/financeiro"
                  variant="contained"
                  className={[classes.buttonDark, classes.buttonNav].join(' ')}
                >
                  <MoneyIcon className={classes.buttonLeftIton} />
                  Financeiro
                </Button>
              </Grid>
              {/* Pedidos */}
              <Grid item>
                <Button
                  component={Link}
                  to="/pedidos"
                  variant="contained"
                  className={[classes.buttonDark, classes.buttonNav].join(' ')}
                >
                  <FormatLineSpacingIcon className={classes.buttonLeftIton} />
                  Pedidos
                </Button>
              </Grid>
              {/* Carrinho */}
              <Grid item>
                <Badge color="secondary" badgeContent={itensCarrinho.length}>
                  <Button
                    disabled={!isRealizaVendas}
                    component={Link}
                    to="/carrinho"
                    variant="contained"
                    className={[classes.buttonDark, classes.buttonNav].join(' ')}
                  >
                    <ShipIcon className={classes.buttonLeftIton} />
                    {pDescCarrinho}
                  </Button>
                </Badge>
              </Grid>
              {/* Consulta Vendas */}
              {/*
                <Visibility show={(usuario?.email ?? '').trim() === 'felipe@valdeirbatista.com.br'}>
              */}
              <Visibility>
                <Grid item>
                  <Button
                    component={Link}
                    to="/vendedor/vendas/consultas"
                    variant="contained"
                    className={[classes.buttonDark, classes.buttonNav].join(' ')}
                  >
                    <Description className={classes.buttonLeftIton} />
                    Consulta Vendas
                  </Button>
                </Grid>
              </Visibility>
              {/* Contato */}
              <Grid item>
                <Badge color="secondary" badgeContent={itensCarrinho.length}>
                  <Button
                    disabled={!isRealizaVendas}
                    component={Link}
                    to="/crm/contatos"
                    variant="contained"
                    className={[classes.buttonDark, classes.buttonNav].join(' ')}
                  >
                    <Icon className={classes.buttonLeftIton}>person</Icon>
                    Contatos
                  </Button>
                </Badge>
              </Grid>
            </Grid>
          </Grid>
          {/* LOGO RIGHT */}
          <Grid item xs={2} md={2}>
            <Grid
              container
              style={{ height: 90 }}
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Link to="/home">
                  {configDefaultLogoRight && <img alt={configDefaultAltLogoRight} src={require(`../../assets/imagens/${configDefaultLogoRight}`)} />}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  cliente: state.usuario.usuario.cliente,
  itensCarrinho: state.carrinho.itens,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...UsuarioActions,
    ...ConfigActions,
  },
  dispatch,
)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NavComp),
)
