import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
    Typography,
    Card,
    Grid,
    Fab,
    Icon,
    Tooltip,
    TextField,
    InputAdornment,
    IconButton,
    CssBaseline,
} from '@material-ui/core'

// import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import { Actions as CrmContatosActions } from 'store/ducks/crm/contato'
import GestorHeaderComp from 'components/Gestor/Header'
import GestorDrawerComp from 'components/Gestor/Drawer'
import TableDataComp from 'components/Table'
import useStyles from './styles'
import { filterCrmOportunidadeApi, findByIdCrmOportunidadeApi } from 'api/crm/oportunidade'
import { Pagination } from '@material-ui/lab'
import PedidoDetalhesLinhaStatusComp from 'components/Pedido/PedidoDetalhesLinhaStatus'
import PedidoDetalhesItensComp from 'components/Pedido/PedidoDetalhesItens'
import PedidoDetalhesFiscalComp from 'components/Pedido/PedidoDetalhesFiscal'
import PedidoDetalhesTitulosComp from 'components/Pedido/PedidoDetalhesTitulos'
import { getPedidosApi } from 'api/pedido'

const GestorCrmOportunidadeDetailPage = (props) => {
    const {
        usuario, crmContatos, push, onFilterCrmContatos, match: { params }
    } = props;

    const classes = useStyles();
    const [listFilter, setListFilter] = useState([]);
    const [filter, setFilter] = useState({
        rows: 20,
        search: '',
    });
    const [payload, setPayload] = useState([]);
    const onIptSearchChange = (evt) => {
        evt.persist();
        setFilter((s) => ({ ...s, search: evt?.target?.value?.toUpperCase() ?? '' }));
    };
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage); // Subtraia 1, pois a API geralmente começa a indexar a página em 0
    };

    const OnSearchOportunidade = () => {
        // findByIdCrmOportunidadeApi({ token: usuario?.auth?.token ?? '', id: params?.id })
        //     .then((resp) => {
        //         setPayload(resp);
        //     });
        getPedidosApi({ token: usuario?.auth?.token ?? '', id: params?.id })
            .then((resp) => {
                setPayload(resp);
            });
    };

    useEffect(() => {
        document.title = 'Online - Oportunidade Detalhe'
        document.body.style = 'background-image: none;'
        OnSearchOportunidade()
    }, [])

    return (
        <div className={classes.root}>
            <CssBaseline />
            <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
            <GestorDrawerComp {...props} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h4" component="h2" style={{ display: 'inline' }}>
                                    Oportunidade {params.id}
                                </Typography>
                                <Typography variant="h4" style={{ display: 'inline' }}>
                                    - {payload?.clienteNome} {payload?.statusId == 7? "(CANCELADA)":""}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Body */}
                    {/* Header do Pedido */}
                    <Grid item xs={12} md={12}>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <PedidoDetalhesLinhaStatusComp
                                    pedido={payload}
                                // params={{
                                //     kClientesEntregas: pDataEmbarque,
                                //     kPedDetEtapa2: pPedDetEtapa2,
                                // }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* - Itens do Pedido */}
                                <PedidoDetalhesItensComp
                                    itens={payload?.itens ?? []}

                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <PedidoDetalhesFiscalComp
                                    notas={payload?.notas ?? []}
                                    // params={{ kTrackingDanfe: pTrackingDanfe }}
                                    actions={{
                                        // onDownloadDanfe: onDownloadDanfeXML,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <PedidoDetalhesTitulosComp
                                    titulos={payload?.titulos ?? []}
                                    actions={{
                                        // onDownloadBoleto,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </main>
        </div>
    )
}

GestorCrmOportunidadeDetailPage.propTypes = {
    usuario: PropTypes.shape().isRequired,
    crmContatos: PropTypes.shape().isRequired,
    push: PropTypes.func.isRequired,
    onFilterCrmContatos: PropTypes.func.isRequired,
    params: PropTypes.shape(),
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
    crmContatos: state.crmContatos,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        ...ConfigActions,
        ...CrmContatosActions,
        push: crrPush,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GestorCrmOportunidadeDetailPage)
