const styles = theme => ({
  content: {
    padding: 0,
    marginTop: 50,
  },
  card: {
    // height: 600,
  },
  media: {
    // ⚠️ object-fit is not supported by IE11.
    objectFit: 'cover',
  },
  margin: {
    marginTop: 10,
  },
  containerActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  center: {
    display: 'flex',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  btnContainerEntrar: {
    marginBottom: 15,
  },
  wrapper: {
    margin: 10,
    position: 'relative',
  },
  buttonProgress: {
    color: '#000',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

export default styles
