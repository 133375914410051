import { getGeolocation } from 'utils/geolocation'
import { addEventoApi } from '../../api/eventos'

// State Config
const initialState = {
  formularios: [],
  parametros: [],
  unidades: [],
}

// Actions Types
export const Types = {
  FORMULARIOS: 'config/FORMULARIOS',
  PARAMETROS: 'config/PARAMETROS',
  UNIDADES: 'config/UNIDADES',
}

// Reducers
export default function config(state = initialState, action) {
  switch (action.type) {
    case Types.FORMULARIOS:
      return { ...state, formularios: action.payload }
    case Types.PARAMETROS:
      return { ...state, parametros: action.payload }
    case Types.UNIDADES:
      return { ...state, unidades: action.payload }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  onLoadConfig: ({ formularios, parametros, unidades }) => (dispatch) => {
    dispatch({ type: Types.FORMULARIOS, payload: formularios })
    dispatch({ type: Types.PARAMETROS, payload: parametros })
    dispatch({ type: Types.UNIDADES, payload: unidades })
  },
  onUpdateFormularios: field => (dispatch) => {
    dispatch({ type: Types.FORMULARIOS, payload: field })
  },
  onUpdateParametros: field => (dispatch) => {
    dispatch({ type: Types.PARAMETROS, payload: field })
  },
  onUpdateUnidades: field => (dispatch) => {
    dispatch({ type: Types.UNIDADES, payload: field })
  },
  getParametro: (nome, padrao) => (dispatch, getState) => {
    const { parametros } = getState().config
    const parametro = parametros.find(p => p.nome === nome)
    const ret = parametro ? parametro.valor : padrao
    // Tratando o tipo do retorno
    switch (typeof padrao) {
      case 'number':
        return Number(ret)
      default:
        return ret
    }
  },
  onAddEvento: (eventosTiposEnum, createdDate = null) => async (dispatch, getState) => {
    const {
      usuario: {
        id, cliente, auth, unidade,
      },
    } = getState()?.usuario || {}
    const { latitude, longitude } = await getGeolocation()
    await addEventoApi({
      token: auth.token,
      evento: {
        eventosTiposId: eventosTiposEnum.id,
        clienteId: cliente?.id || 1,
        filialId: unidade?.id || 1,
        geoLat: latitude,
        geoLon: longitude,
        createdBy: id,
        createdDate,
      },
    })
  },
}
