import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import _ from 'lodash'
import {
  Paper, TextField, Grid, Button, CircularProgress,
} from '@material-ui/core'
import { Save } from '@material-ui/icons'

import { Actions as GestorUsuariosActions } from '../../../../../../store/ducks/gestor/usuarios'
import { DateHoursIsoToBr, OnlyDigits } from 'utils'
import useStyles from './styles'

const DadosUsuarioTabContent = (props) => {
  const {
    gestorUsuarios: { loading, usuario },
    onSaveUsuarioGestor,
    onFieldUpdateUsuarioGestor,
  } = props
  const classes = useStyles()
  const isNewUser = usuario && usuario.form && !usuario.form.id
  return loading.usuario && (!usuario && !usuario.form) ? (
    <CircularProgress />
  ) : (
    <Paper className={classes.paper} elevation={1}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="nomeUsuario"
            label="Nome Completo:"
            margin="normal"
            variant="outlined"
            value={usuario.form.nome}
            className={classes.textField}
            onChange={evt => onFieldUpdateUsuarioGestor({ nome: evt.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="emailUsuario"
            label="E-mail:"
            margin="normal"
            variant="outlined"
            value={usuario.form.email}
            className={classes.textField}
            onChange={evt => onFieldUpdateUsuarioGestor({ email: evt.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="cpfUsuario"
            label="CPF/CNPJ:"
            margin="normal"
            variant="outlined"
            value={usuario.form.cpf}
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            // onKeyDown={(evt) => }
            onChange={(evt) => {
              const cpf = OnlyDigits(evt.target.value)
              if (_.size(evt.target.value.toString()) <= 14) {
                onFieldUpdateUsuarioGestor({ cpf })
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled
            id="statusUsuario"
            label="Status:"
            margin="normal"
            variant="outlined"
            value={usuario.form.statusDesc}
            className={classes.textField}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled
            id="createdDateUsuario"
            label="Criado dia:"
            margin="normal"
            variant="outlined"
            className={classes.textField}
            value={!isNewUser ? DateHoursIsoToBr(usuario.form.createdDate) : ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled
            id="modifiedDateUsuario"
            label="Modificado dia:"
            margin="normal"
            variant="outlined"
            className={classes.textField}
            value={!isNewUser ? DateHoursIsoToBr(usuario.form.modifiedDate) : ''}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={6}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={onSaveUsuarioGestor}
            >
              <Save className={classes.leftIcon} />
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

DadosUsuarioTabContent.propTypes = {
  loading: PropTypes.shape(),
  gestorUsuarios: PropTypes.shape().isRequired,
  onSaveUsuarioGestor: PropTypes.func.isRequired,
  onFieldUpdateUsuarioGestor: PropTypes.func.isRequired,
}

DadosUsuarioTabContent.defaultProps = {
  loading: { usuarios: false },
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  gestorUsuarios: state.gestorUsuarios,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...GestorUsuariosActions,
    push,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DadosUsuarioTabContent)
