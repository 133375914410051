import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  AppBar, Toolbar, Typography, Button, IconButton, makeStyles, SvgIcon,
} from '@material-ui/core'
import { Menu } from '@material-ui/icons'

import TrocafFilialComp from '../../Dialogs/TrocaFilial'
import useStyles from './styles'
import BusinessIcon from '@material-ui/icons/Business';


const GestorHeaderComp = (props) => {
  const env = process.env.REACT_APP_ENV;
  console.log(env)
  const { title } = props
  const classes = useStyles()
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton edge="start" className={classes.menuButton} color="secondary" aria-label="Menu">
          <Menu />
        </IconButton>
        {env === "test" && (
          <Typography variant="body1" style={{ color: "white", marginRight: "10px" }}>
            AMBIENTE AWS TESTE
          </Typography>
        )}
        <Typography variant="h6" color="secondary" className={classes.title}>
          {title}
        </Typography>
        <BusinessIcon color="action" style={{ marginRight: "5px", color: "white" }} />
        <Typography variant="h6" style={{ marginLeft: "10px", color: "white" }}>
          PUBLIKIMAGEM
        </Typography>
        <Button color="secondary" component={Link} to="/login">
          Sair
        </Button>
      </Toolbar>
    </AppBar>
  )
}

GestorHeaderComp.propTypes = {
  // classes: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
}

export default GestorHeaderComp
