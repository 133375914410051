import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  CssBaseline,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  InputAdornment,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'

import { Actions as ConfigActions } from '../../../../../store/ducks/config'
import { Actions as DialogsActions } from '../../../../../store/ducks/dialog'
import { Actions as GestorUsuariosActions } from '../../../../../store/ducks/gestor/usuarios'
import GestorHeaderComp from '../../../../../components/Gestor/Header'
import GestorDrawerComp from '../../../../../components/Gestor/Drawer'
import PageHeaderComp from '../../../../../components/PageHeader'
import TableDataComp from '../../../../../components/Table'
import BuscaGenericaComp from '../../../../../components/Dialogs/Contents/BuscaGenerica'
import { DialogContentSimples } from '../../../../../components/Dialogs/Contents'
import useStyles from './styles'

const GestorAlcadasAprovadoresListagemPage = (props) => {
  const [search, setSearch] = useState('')
  const [dialogForm, setDialogForm] = useState(false)
  const [dialogFormTitle, setDialogFormTitle] = useState('Cadastro de novo aprovador')
  const [dialogSearchUsuario, setDialogSearchUsuario] = useState(false)
  const classes = useStyles()
  const {
    unidade,
    usuario,
    loading,
    aprovadores: { list = [], form = {} },
    onLoadAprovadores,
    onUpdateFormAprovadores,
    onStartAprovador,
    onSaveAprovador,
    onDelAprovador,
    // Dialog Search
    onSearchUsuario,
    onDialogOpen,
    onDialogClose,
  } = props

  const handlePesquisarClick = () => onLoadAprovadores({ status: 'L', filial: unidade.id, search })

  const handlePesquisarKeyPress = (evt) => {
    if (evt.charCode === 13) {
      onLoadAprovadores({ status: 'L', filial: unidade.id, search })
    }
  }

  const handleLoadPage = () => {
    // status, filial, usuario, search,
    onLoadAprovadores({ status: 'L', filial: unidade.id, search })
  }

  const handleShowDialogFrom = (title) => {
    setDialogFormTitle(title)
    setDialogForm(true)
  }
  const handleHideDialogFrom = () => {
    setDialogForm(false)
  }
  const handleAddNovoAprovador = () => {
    onSaveAprovador({ ...form, search })
    handleHideDialogFrom()
  }
  // Usuários (Pesquisa)
  const handleShowDialogSearchUsuario = () => {
    setDialogSearchUsuario(true)
  }
  const handleHideDialogUsuario = () => {
    setDialogSearchUsuario(false)
  }
  const handleSearchUsuarios = (pSearch) => {
    onSearchUsuario({ search: pSearch, perfil: 'ADMIN,GESTOR,USUARIO,AUDITOR' })
  }

  const tableCols = [
    { key: 'id', title: 'ID' },
    { key: 'usuarioNome', title: 'NOME' },
    { key: 'usuarioEmail', title: 'E-MAIL' },
    { key: 'masterDescricao', title: 'MASTER' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]
  const tableActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: (row) => {
        handleShowDialogFrom('Edição de aprovador')
        onUpdateFormAprovadores({
          id: row.id,
          usuarioId: row.usuarioId,
          usuarioNome: row.usuarioNome,
          filialId: row.filialId,
          master: row.master,
          status: row.status,
        })
      },
    },
    {
      key: 'excluir',
      title: 'Exluir',
      icon: 'delete',
      action: row => onDialogOpen({
        size: 'sm',
        title: 'Remover?',
        content: (
          <DialogContentSimples
            text={`Você confirma a remoção do aprovador: "${row.usuarioNome}" ?`}
          />
        ),
        actions: [
          {
            key: 1,
            color: 'secondary',
            title: 'NÃO',
            action: () => onDialogClose(),
          },
          {
            key: 2,
            title: 'SIM',
            action: () => {
              onDelAprovador(row.id, () => handleLoadPage())
              onDialogClose()
            },
          },
        ],
      }),
    },
  ]
  const headerButtons = [
    {
      title: 'Adicionar',
      icon: 'add',
      action: () => {
        onUpdateFormAprovadores({
          id: 0,
          usuarioId: 0,
          usuarioNome: '',
          filialId: unidade.id,
          master: '',
          status: 'L',
        })
        handleShowDialogFrom('Cadastro de novo aprovador')
      },
    },
  ]

  useEffect(() => {
    onStartAprovador()
  }, [])

  useEffect(() => {
    handleLoadPage()
    onUpdateFormAprovadores({
      id: 0,
      usuarioId: 0,
      usuarioNome: '',
      filialId: unidade.id,
      master: '',
      status: 'L',
    })
  }, [unidade])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <PageHeaderComp
            title="Aprovadores"
            // subtitle=""
            buttons={headerButtons}
            value={search}
            onChange={evt => setSearch(evt.target.value.toUpperCase())}
            onClick={handlePesquisarClick}
            onKeyPress={handlePesquisarKeyPress}
          />
          <Grid item xs={12}>
            <TableDataComp
              loading={loading.aprovadores}
              size="small"
              rows={list || []}
              cols={tableCols}
              actions={tableActions}
              onClick={() => null}
            />
          </Grid>
        </Grid>
      </main>
      {/* Dialog add novo aprovador */}
      <Dialog
        open={dialogForm}
        size="md"
        fullWidth
        onClose={handleHideDialogFrom}
        aria-labelledby="dialog-form-title"
        aria-describedby="dialog-form-description"
      >
        <DialogTitle id="dialog-form-title">{dialogFormTitle}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} direction="row">
            <Grid item xs={9}>
              <FormControl fullWidth>
                <InputLabel htmlFor="usuario">Usuário</InputLabel>
                <Input
                  disabled={!!form.id}
                  readOnly
                  required
                  fullWidth
                  id="usuario"
                  value={form.usuarioId ? `${form.usuarioId} - ${form.usuarioNome}` : ''}
                  endAdornment={
                    !form.id && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Pesquisar por usuários"
                          onClick={handleShowDialogSearchUsuario}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="master">Nível?</InputLabel>
                <Select
                  required
                  fullWidth
                  value={form.master}
                  onChange={evt => onUpdateFormAprovadores({ master: evt.target.value })}
                  inputProps={{
                    name: 'master',
                    id: 'master',
                  }}
                >
                  <MenuItem value="">SELECIONE</MenuItem>
                  <MenuItem value="S">MASTER</MenuItem>
                  <MenuItem value="N">NORMAL</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHideDialogFrom} color="secondary">
            CANCELAR
          </Button>
          <Button onClick={handleAddNovoAprovador} color="primary" autoFocus>
            SALVAR
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog buscar usuários */}
      <Dialog
        size="md"
        fullWidth
        open={dialogSearchUsuario}
        onClose={handleHideDialogUsuario}
        aria-labelledby="dialog-usuario-title"
        aria-describedby="dialog-usuario-description"
      >
        <DialogTitle disableTypography id="dialog-usuario-title">
          <Typography variant="h6">Busca de usuários:</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleHideDialogUsuario}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <BuscaGenericaComp
            rowKey="id"
            rowValue="nome"
            placeholder="Buscar de usuario:"
            onSelectItem={(item) => {
              onUpdateFormAprovadores({
                usuarioId: item.id,
                usuarioNome: item.nome,
              })
              handleHideDialogUsuario()
            }}
            onSearchHandle={ipt => handleSearchUsuarios(ipt)}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

GestorAlcadasAprovadoresListagemPage.propTypes = {
  usuario: PropTypes.shape().isRequired,
  unidade: PropTypes.shape().isRequired,
  loading: PropTypes.shape().isRequired,
  aprovadores: PropTypes.shape(),
  onLoadAprovadores: PropTypes.func.isRequired,
  onUpdateFormAprovadores: PropTypes.func.isRequired,
  onStartAprovador: PropTypes.func.isRequired,
  onSearchUsuario: PropTypes.func.isRequired,
  onSaveAprovador: PropTypes.func.isRequired,
  onDelAprovador: PropTypes.func.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
}

GestorAlcadasAprovadoresListagemPage.defaultProps = {
  aprovadores: { list: [], form: {} },
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  unidade: state.usuario.usuario.unidade,
  generic: state.dialog.generic,
  loading: state.gestorUsuarios.loading,
  aprovadores: state.gestorUsuarios.aprovadores,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...ConfigActions,
    ...DialogsActions,
    ...GestorUsuariosActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GestorAlcadasAprovadoresListagemPage)
