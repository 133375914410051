const styles = theme => ({
  root: {
    paddingTop: 20,
  },
  banner: {
    backgroundColor: '#CCCCCC',
  },
  imgBanner: {
    width: '100%',
  },
  compMargin: {
    marginBottom: 10,
  },
  formControl: {
    marginTop: 8,
  },
})

export default styles
