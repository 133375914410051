import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
  Grid,
  Icon,
  TextField,
  InputAdornment,
  IconButton,
  CssBaseline,
  Typography,
  CircularProgress,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'

import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import TableDataComp from '../../../../components/Table'
import useStyles from './styles'
import { cancelVisitaApi, deleteVisitaApi, getVisitasFilterApi } from 'api/visitas'
import { DialogContentSimples } from 'components/Dialogs/Contents'
import GestorHeaderComp from 'components/Gestor/Header'
import GestorDrawerComp from 'components/Gestor/Drawer'
import { Autocomplete, Pagination } from '@material-ui/lab';
import { filterCrmAgentesApi } from 'api/crm/agentes'
import { DatePicker } from '@material-ui/pickers'
import { NotificationManager } from 'react-notifications'
import { estadoAllApi, estadoApi } from 'api/localizacao/estado'
import { listSubBairrosByBairroId } from 'api/localizacao/sub_bairro'
import { listBairrosByMunicipioIdApi } from 'api/localizacao/bairro'
import { listMunicipiosByEstadoIdApi } from 'api/localizacao/municipio'
import DeleteConfirmationDialog from '../components/delete_confirmation_dialog'
const DadosVisitaTabContent = (props) => {
  const {
    usuario, push
  } = props
  const classes = useStyles()

  // State
  const [listFilter, setListFilter] = useState([])
  const [filter, setFilter] = useState({
    page: 0,
    rows: 15,
    search: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [payload, setPayload] = useState([]);
  const [loading, setLoading] = useState(false);
  const onSearchVisitas = (page = 1) => {
    setLoading(true);
    const updatedFilter = { ...filter, page: page - 1, rows: 15, agenteId: selectedAgent, visitaDate: selectedDate, estado: selectedState?.id ?? '', municipio: selectedMunicipality?.id ?? '', bairro: selectedBairro?.id ?? '', subBairro: selectedSubBairro?.id ?? '', revisitaBonus: selectedOption };
    if (page == 1 && currentPage != page) {
      setCurrentPage(page);
    }
    else {
      getVisitasFilterApi({ token: usuario?.auth?.token ?? '', query: updatedFilter })
        .then((resp) => {
          setPayload(resp);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const onSearchVisitasClick = (page = 1) => {
    onSearchVisitas(1)
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    onSearchVisitas(currentPage);
  }, [currentPage]);
  const tableCols = [
    { key: 'visStatusDesc', title: 'STATUS' },
    { key: 'visResultadoDesc', title: 'RESULTADO' },
    { key: 'data', title: 'DATA' },
    { key: 'ucNumero', title: 'UC NÚMERO' },
    { key: 'respNome', title: 'RESPONSÁVEL' },
    { key: 'agenteNome', title: 'AGENTE' },
    { key: 'entrevNome', title: 'ENTREVISTADO' },
    { key: 'termoNum', title: 'TERMO' },
    { key: 'unidadeConsumidoraBairroNome', title: 'BAIRRO' },
    { key: 'unidadeConsumidoraSubbairroNome', title: 'SUB-BAIRRO' }
  ]
  const getTableActionsList = (role) => {
    const actions = [
      {
        key: 'detalhes',
        title: 'Detalhes',
        icon: 'list',
        action: row => push(`visita/detail/${row.id}`),
      },
      {
        key: 'detalhes',
        title: 'Prospect',
        icon: 'group',
        action: row => {
          if (row.visResultadoDesc == "REVISITA BONUS") {
            // Redirecionar para a página crm/prospects/detail/{clienteId}
            push(`crm/prospects/detail/${row.clienteId}`);
          } else {
            // Exibir uma notificação de aviso
            NotificationManager.warning("Apenas visitas com o status REVISITA BONUS");
          }
        },
      }
    ];

    // if (role === "ROLE_ADMIN" || role === "ROLE_GESTOR") {
    //   actions.push({
    //     key: 'cancelar',
    //     title: 'Cancelar',
    //     icon: 'delete',
    //     action: (row) => handleOpenCancel(row),
    //   });
    // }

    if (role === "ROLE_ADMIN") {
      actions.push({
        key: 'deletar',
        title: 'Excluir',
        icon: 'delete',
        action: (row) => handleOpenDelete(row),
      });
    }

    return actions;
  };

  const tableActionsList = getTableActionsList(usuario?.role);
  const handleOpenCancel = (row) => {
    console.log('handleOpenCancel called with row:', row);

    cancelVisitaApi({ token: usuario?.auth?.token ?? '', id: row.id })
      .then(response => {
        console.log(response)
        if (response.visStatusDesc === "CANCELADA") {
          NotificationManager.success('Visita cancelada com sucesso!', 'Success', 3000);
          onSearchVisitas()
        } else {
          NotificationManager.error('Erro ao cancelar a visita. Tente novamente.', 'Erro', 3000);
        }
      })
      .catch(error => {
      });
  };
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);

  const handleOpenDelete = (row) => {
    setDeleteRow(row);
    setOpenDeleteDialog(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
    setDeleteRow(null);
  };
  // const handleOpenDelete = (row) => {
  //   console.log('handleOpenDelete called with row:', row);

  //   deleteVisitaApi({ token: usuario?.auth?.token ?? '', id: row.id })
  //     .then(response => {
  //       if (response.status === 204) {
  //         NotificationManager.success('Visita deletada com sucesso!', 'Success', 3000);
  //         onSearchVisitas()
  //       } else {
  //         NotificationManager.error('Erro ao deletar a visita. Tente novamente.', 'Erro', 3000);
  //       }
  //     })
  //     .catch(error => {
  //     });
  // };

  const [agentes, setAgentes] = useState(false);

  const onIptSearchChange = (evt) => {
    evt.persist();
    setFilter(s => ({ ...s, search: evt?.target?.value?.toUpperCase() ?? '' }))
  }

  const onSearchAgentes = () => {
    filterCrmAgentesApi({ token: usuario?.auth?.token ?? '', query: {agenteTypeChar:"S"} })
      .then((resp) => {
        const { data = [] } = resp || {}
        setAgentes(data)
      })
  }
  const [selectedAgent, setSelectedAgent] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);

  const handleAgentChange = (event) => {
    setSelectedAgent(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  //Localization 
  //Localization Set
  // Função para carregar todos os estados
  const loadEstados = () => {
    // Chamar a função estadoAllApi para carregar os estados
    estadoAllApi(usuario?.auth?.token ?? '')
      .then((response) => {
        // Definir os estados carregados no estado local
        setEstados(response);
      })
      .catch((error) => {
        console.error('Erro ao carregar os estados:', error);
      });
  };

  // Função para carregar municípios com base no estado selecionado
  const loadMunicipios = (stateId) => {
    // Chamar a função listMunicipiosByEstadoIdApi passando o estadoId
    listMunicipiosByEstadoIdApi(usuario?.auth?.token ?? '', stateId)
      .then((response) => {
        // Definir os municípios carregados no estado local
        setMunicipios(response);
      })
      .catch((error) => {
        console.error('Erro ao carregar os municípios:', error);
      });
  };

  // Função para carregar bairros com base no município selecionado
  const loadBairros = (municipioId) => {
    // Chamar a função listBairrosByMunicipioIdApi passando o municipioId
    listBairrosByMunicipioIdApi(usuario?.auth?.token ?? '', municipioId)
      .then((response) => {
        // Definir os bairros carregados no estado local
        setBairros(response);
      })
      .catch((error) => {
        console.error('Erro ao carregar os bairros:', error);
      });
  };

  // Função para carregar subbairros com base no bairro selecionado
  const loadSubBairros = (bairroId) => {
    // Chamar a função listSubBairrosByBairroId passando o bairroId
    listSubBairrosByBairroId(usuario?.auth?.token ?? '', bairroId)
      .then((response) => {
        // Definir os subbairros carregados no estado local
        setSubBairros(response);
      })
      .catch((error) => {
        console.error('Erro ao carregar os subbairros:', error);
      });
  };

  // Estado local para armazenar os estados carregados

  const [estados, setEstados] = useState([]);
  // Estado local para armazenar os municípios carregados
  const [municipios, setMunicipios] = useState([]);

  // Estado local para armazenar os bairros carregados
  const [bairros, setBairros] = useState([]);

  // Estado local para armazenar os subbairros carregados
  const [subBairros, setSubBairros] = useState([]);

  // Estado local para armazenar a seleção do estado
  const [selectedState, setSelectedState] = useState(null);

  // Estado local para armazenar a seleção do município
  const [selectedMunicipality, setSelectedMunicipality] = useState(null);

  // Estado local para armazenar a seleção do bairro
  const [selectedBairro, setSelectedBairro] = useState(null);

  // Estado local para armazenar a seleção do bairro
  const [selectedSubBairro, setSelectedSubBairro] = useState(null);

  // Função para manipular a seleção do estado
  const handleStateChange = async (event, value) => {

    setSelectedMunicipality(null);
    setSelectedBairro(null);

    if (value?.id) {
      // Carregar os municípios com base no estado selecionado
      setSelectedState(value);
      await loadMunicipios(value.id);
    } else {
      setSelectedState(null)
      setMunicipios([]);
      setBairros([]);
      setSubBairros([]);
    }
  };

  // Função para manipular a seleção do município
  const handleMunicipalityChange = (event, value) => {
    setSelectedMunicipality(value);
    setSelectedBairro(null);

    if (value) {
      // Carregar os bairros com base no município selecionado
      loadBairros(value.id);
    } else {
      setSelectedMunicipality(null)
      setBairros([]);
      setSubBairros([]);
    }
  };

  // Função para manipular a seleção do bairro
  const handleBairroChange = (event, value) => {
    setSelectedBairro(value);

    if (value) {
      // Carregar os subbairros com base no bairro selecionado
      loadSubBairros(value.id);
    } else {
      setSelectedBairro(null)
      setSubBairros([]);
    }
  };
  // Função para manipular a seleção do subbairro
  const handleSubBairroChange = (event, value) => {
    setSelectedSubBairro(value);
  };
  // Loading
  const [loadingEstado, setLoadingEstado] = useState(false);
  const [loadingMunicipios, setLoadingMunicipios] = useState(false);
  const [loadingBairros, setLoadingBairros] = useState(false);
  const [loadingSubBairros, setLoadingSubBairros] = useState(false);
  // Style
  const stylesForm = {
    marginTop: '2rem',
    marginBottom: '1rem',
    display: 'flex',
    gap: '1rem',
    width: '100%',
  };
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedOption((prevOption) => (prevOption === value ? '' : value));
  };
  const [selectedOption, setSelectedOption] = useState('0');

  useEffect(() => {
    document.title = 'Online - Visitas'
    document.body.style = 'background-image: none;'
    onSearchVisitas()
    onSearchAgentes()
    loadEstados()
  }, [])
  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid xs={12} md={12}>
                <Typography variant="h3" component="h2">
                  Visitas
                </Typography>
              </Grid>
              <Grid xs={12} md={12}>
                <form style={stylesForm}>
                  <Grid item xs={6} md={3}>
                    {loadingEstado ? (
                      <CircularProgress /> // Indicador de carregamento com spinner
                    ) : (
                      estados && (
                        <Autocomplete
                          id="states-combo-box"
                          options={estados}
                          getOptionLabel={(option) => option.nome}
                          value={selectedState}
                          onChange={handleStateChange}
                          renderInput={(params) => <TextField {...params} label="Estado" variant="outlined" />}
                        />
                      )
                    )}
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Autocomplete
                      id="municipalities-combo-box"
                      options={municipios}
                      getOptionLabel={(option) => option.nome}
                      value={selectedMunicipality}
                      onChange={handleMunicipalityChange}
                      renderInput={(params) => <TextField {...params} label="Município" variant="outlined" />}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Autocomplete
                      id="bairro-combo-box"
                      options={bairros}
                      getOptionLabel={(option) => option.nome}
                      value={selectedBairro}
                      onChange={handleBairroChange}
                      renderInput={(params) => <TextField {...params} label="Bairro" variant="outlined" />}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Autocomplete
                      id="subbairro-combo-box"
                      options={subBairros}
                      getOptionLabel={(option) => option.nome}
                      value={selectedSubBairro}
                      onChange={handleSubBairroChange}
                      renderInput={(params) => <TextField {...params} label="Sub Bairro" variant="outlined" />}
                    />
                  </Grid>
                </form>
              </Grid>
              <Grid xs={6} md={6} container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel style={{ paddingLeft: '16px' }} id="agent-label">Agente</InputLabel>
                    <Select
                      labelId="agent-label"
                      id="agent-select"
                      value={selectedAgent}
                      onChange={handleAgentChange}
                      variant="outlined"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {agentes.length > 0 && agentes.map((agente) => (
                        <MenuItem key={agente.id} value={agente.id}>
                          {agente.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Data Visita"
                    fullWidth
                    type="date"
                    name="dataVisita"
                    InputLabelProps={{ shrink: true }}
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                </Grid>
              </Grid>
              {/* Menus */}
              <Grid xs={12} md={6} container direction="row" spacing={1} justifyContent="flex-end" alignItems="flex-end">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedOption === '1'}
                        onChange={handleCheckboxChange}
                        value="1"
                      />
                    }
                    label="REVISITA BONUS"
                  />
                </Grid>
                <Grid item >
                  <TextField
                    id="outlined-adornment-password"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label="Pesquisar"
                    value={filter?.search ?? ''}
                    onChange={onIptSearchChange}
                    onKeyPress={(evt) => {
                      if (evt.charCode === 13) {
                        onSearchVisitas()
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Pesquisar"
                            onClick={onSearchVisitasClick}
                          >
                            <Icon>search</Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item >
                  <Button variant="contained" color="primary" onClick={onSearchVisitasClick}>
                    Filtrar
                  </Button>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
          {/* Body */}
          <Grid item xs={12} md={12}>
            {loading ? (
              <div className={classNames(classes.loadingContainer, classes.centered)}>
                <CircularProgress />
              </div>
            ) : (
              <TableDataComp size="small" loading={false} rows={payload.data} cols={tableCols} actions={tableActionsList} />
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <div id="mui-pagination">
              <Pagination
                count={payload.total ?? 0}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                className={classes.pagination}
              />
            </div>
          </Grid>
          <DeleteConfirmationDialog
            open={openDeleteDialog}
            onClose={handleCloseDelete}
            row={deleteRow}
            deleteVisitaApi={deleteVisitaApi}
            usuario={usuario}
            onSearchVisitas={onSearchVisitas}
          />
        </Grid>
      </main>
    </div >
  )
}

DadosVisitaTabContent.propTypes = {
  push: PropTypes.func.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  gestorUsuarios: state.gestorUsuarios,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    push: crrPush,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DadosVisitaTabContent)
