import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepConnector from '@material-ui/core/StepConnector'

import { getStatusById } from '../../../utils/enums/StatusPedido'
import styles from './styles'

const PedidoHeaderComp = ({ classes, activeStep, labelStep2, pedido }) => {
  if (activeStep === 7) {
    if (pedido?.pedidoHistoricoStatusFrontDTOS) {
      const sortedStatus = [...pedido.pedidoHistoricoStatusFrontDTOS].sort((a, b) => {
        const aDate = a.createdDate.split(" ")[0].split("/").reverse().join("-") + " " + a.createdDate.split(" ")[1];
        const bDate = b.createdDate.split(" ")[0].split("/").reverse().join("-") + " " + b.createdDate.split(" ")[1];
        return new Date(bDate) - new Date(aDate);
      });
      const mostRecentStatus = sortedStatus[0];
      activeStep = mostRecentStatus?.statusId
      // Do something with mostRecentStatus...
    }
  }
  const status = activeStep ? getStatusById(activeStep) : undefined;
  const { sku = '', step = 0 } = status ? status : { sku: '', step: 0 };

  const getSteps = () => {
    let statusIds = [1, 2, 15, 5, 8, 16];
    if (activeStep == 14) {
      statusIds.splice(2, 0, 14);
    } else {
      statusIds.splice(2, 0, 13);
    }
    let steps = statusIds.map(id => {
      let item = pedido?.pedidoHistoricoStatusFrontDTOS?.find(item => item.statusId === id);
      return item
        ? `${getStatusById(id)?.descricao}<br><b>${item.createdDate}</b>`
        : getStatusById(id)?.descricao;
    });
    return steps;
  };
  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={step}
        connector={(
          <StepConnector
            classes={{
              active: classes.connectorActive,
              completed: classes.connectorCompleted,
              line: classes.connectorLine,
            }}
          />
        )}
      >
        {getSteps().map(label => (
          <Step key={label}>
            <StepLabel>
              <span dangerouslySetInnerHTML={{ __html: label }} />
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

PedidoHeaderComp.propTypes = {
  classes: PropTypes.shape().isRequired,
  activeStep: PropTypes.number.isRequired,
  labelStep2: PropTypes.string,
  pedido: PropTypes.object
}

PedidoHeaderComp.defaultProps = {
  labelStep2: '',
}

export default withStyles(styles)(PedidoHeaderComp)
