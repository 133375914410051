import React from 'react'
import {
  LocalShipping,
  Done,
  CloudDone,
  Beenhere,
  Description,
  DepartureBoard,
  Place,
  Cancel,
  CheckBox,
  HourglassEmpty,
  BlurOff,
  ThumbUp,
  ThumbDown,
  FolderOpen,
  ShoppingCart,
  Person,
  Block,
  Search,
} from '@material-ui/icons'

const StatusPedido = [
  {
    id: 1,
    sku: 'REALIZADA',
    descricao: 'REALIZADA',
    icon: <HourglassEmpty />,
    step: 1,
  },
  {
    id: 2,
    sku: 'AGENDADA',
    descricao: 'AGENDADA',
    icon: <Search />,
    step: 2,
  },
  {
    id: 15,
    sku: 'PAGO',
    descricao: 'PAGO ',
    icon: <Description />,
    step: 5,
  },
  {
    id: 5,
    sku: 'FATURADA',
    descricao: 'FATURADA ',
    icon: <Description />,
    step: 6,
  },
  {
    id: 8,
    sku: 'ENTREGUE',
    descricao: 'ENTREGUE',
    icon: <Place />,
    step: 8,
  },
  {
    id: 16,
    sku: 'INSTALADO',
    descricao: 'INSTALADO',
    icon: <Place />,
    step: 9,
  },
  {
    id: 13,
    sku: 'OPORT_NEGATIVADA',
    descricao: 'VISTORIADA',
    icon: <ThumbDown />,
    step: 3,
  },
  {
    id: 14,
    sku: 'OPORT_POSITIVADA',
    descricao: 'VISTORIADA',
    icon: <ThumbUp />,
    step: 3,
  },
]

const getStatusById = id => StatusPedido.find(s => s.id === id)
const getStatusSkuId = sku => StatusPedido.find(s => s.sku === sku)

export { getStatusById, getStatusSkuId }
