import React, { Fragment } from 'react'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'
import moment from 'moment'
import { push } from 'connected-react-router'
import { sendPedidoApi } from '../../api/pedido'
import { DateBrToIso } from 'utils'
import PedidoErrorComp from '../../components/ExpasionPanel/PedidoError'
import { DialogContentSimples } from '../../components/Dialogs/Contents'
import { getPagamentos, getObras, searchClienteErpFilterApi } from '../../api/cliente'
import { EventosEnum } from '../../utils/enums/eventos'

import { Actions as DialogActions } from './dialog'
import { Actions as ConfigActions } from './config'

// State carrinho
const initialState = {
  loading: {
    loadMotoristaVeiculos: false,
    loadObras: false,
  },
  buscando: false,
  buscaResultado: [],
  itens: [],
  unidade: {},
  unidades: [],
  modalidade: {},
  modalidades: [],
  pagamentoLoad: false,
  pagamentoForma: {},
  pagamentoFormas: [],
  pagamentoCondicao: {},
  pagamentoCondicoes: [],
  fobCadMotoristaVeiculo: {
    fobVeiculoModelo: '',
    fobVeiculoMarca: '',
    fobVeiculoPlaca: '',
    fobVeiculoPlacaCarreta: '',
    fobMotoristaNome: '',
    fobMotoristaCpf: '',
    fobEmpresaNome: '',
    fobCompradorNome: '',
  },
  obra: {},
  obras: [],
  obsnf: '',
  motoristas: [],
  veiculos: [],
  finalizarPedido: {
    pedidosOk: false,
    dialog: false,
    title: 'Confirmação',
    content: 0,
    pedido: '',
    msg: 'Você realmente deseja finalizar o pedido atual?',
    pedidoPadrao: false,
    pedidoPadraoDescricao: '',
  },
  quantidadePopUp: {
    quantidades: [],
    anchorQuantidade: null,
    itemSelected: null,
  },
  dialogs: {
    dialogFobMotorista: {
      open: false,
      modalidade: null,
      motorista: null,
      veiculo: null,
      motoristas: [],
      veiculos: [],
    },
    dialogFobCadMotoristaVeiculo: {
      fobVeiculoModelo: '',
      fobVeiculoMarca: '',
      fobVeiculoPlaca: '',
      fobVeiculoPlacaCarreta: '',
      fobMotoristaNome: '',
      fobMotoristaCpf: '',
      fobEmpresaNome: '',
      fobCompradorNome: '',
    },
  },
  clienteEntrega: {},
}

// Actions Types
export const Types = {
  RESET_CARRINHO: 'carrinho/RESET_CARRINHO',
  LOADING: 'carrinho/LOADING',
  BUSCANDO: 'carrinho/BUSCANDO',
  BUSCA_RESULTADO: 'carrinho/BUSCA_RESULTADO',
  LOAD_CARRINHO: 'carrinho/LOAD_CARRINHO',
  PAGAMENTO_LOAD: 'carrinho/PAGAMENTO_LOAD',
  ADD_PRODUTO: 'carrinho/ADD_PRODUTO',
  UPDATE_PRODUTO: 'carrinho/UPDATE_PRODUTO',
  UPDATE_PRODUTO_OBRA: 'carrinho/UPDATE_PRODUTO_OBRA',
  UPDATE_QUANT_CARRINHO: 'carrinho/UPDATE_QUANT_CARRINHO',
  UPDATE_CONDICAO_ITEM_CARRINHO: 'carrinho/UPDATE_CONDICAO_ITEM_CARRINHO',
  UPDATE_MODALIDADE_ITENS_CARRINHO: 'carrinho/UPDATE_MODALIDADE_ITENS_CARRINHO',
  UPDATE_MODALIDADE_ITEM_CARRINHO: 'carrinho/UPDATE_MODALIDADE_ITEM_CARRINHO',
  DELETE_ITEM_CARRINHO: 'carrinho/DELETE_ITEM_CARRINHO',
  UPDATE_MOTORISTA_VEICULO_ITEM: 'carrinho/UPDATE_MOTORISTA_VEICULO_ITEM',
  UPDATE_PRODUTO_QUANT: 'produto/UPDATE_PRODUTO_QUANT',
  UPDATE_STATE_CARRINHO: 'carrinho/UPDATE_STATE_CARRINHO',
  UPDATE_PAGAMENTO_ALL_ITENS: 'carrinho/UPDATE_PAGAMENTO_ALL_ITENS',
  UPDATE_QUANTIDADE_POPUP: 'carrinho/UPDATE_QUANTIDADE_POPUP',
  UPDATE_DIALOGS: 'carrinho/UPDATE_DIALOGS',
  UPDATE_DIALOGS_CAD_MOT_VEI: 'carrinho/UPDATE_DIALOGS_CAD_MOT_VEI',
  CLEAN_DIALOGS_CAD_MOT_VEI: 'carrinho/CLEAN_DIALOGS_CAD_MOT_VEI',
  UPDATE_OBS_NF: 'carrinho/UPDATE_OBS_NF',
  LOAD_MOTORISTA_VEICULO_ITEM: 'carrinho/LOAD_MOTORISTA_VEICULO_ITEM',
  UPDATE_EMBARQUE_ITEM_CARRINHO: 'carrinho/UPDATE_EMBARQUE_ITEM_CARRINHO', // Data de embarque
  UPDATE_CLIENTE_ENTREGA: 'carrinho/UPDATE_CLIENTE_ENTREGA',
}

// Reducers
export default function carrinhoReducers(state = initialState, action) {
  switch (action.type) {
    case Types.RESET_CARRINHO:
      return { ...state, ...initialState }
    case Types.LOADING:
      return { ...state, loading: { ...state.loading, ...action.payload } }
    case Types.BUSCANDO:
      return { ...state, buscando: !state.buscando }
    case Types.BUSCA_RESULTADO:
      return { ...state, buscaResultado: action.payload }
    case Types.PAGAMENTO_LOAD:
      return { ...state, pagamentoLoad: !state.pagamentoLoad }
    case Types.LOAD_CARRINHO:
      return { ...state, ...action.payload }
    case Types.ADD_PRODUTO:
      return {
        ...state,
        itens: [...state.itens, action.payload],
      }
    case Types.UPDATE_PRODUTO:
      return {
        ...state,
        itens: state.itens.map(i => (i.produtoSku === action.payload.produtoSku && i.item === action.payload.item
          ? {
            ...action.payload,
            produtoQuant: +i.produtoQuant + +action.payload.produtoQuant,
          }
          : i)),
      }
    case Types.UPDATE_PRODUTO_OBRA:
      return {
        ...state,
        itens: state.itens.map(i => (i.produtoSku === action.payload.produtoSku && i.item === action.payload.item
          ? action.payload
          : i)),
      }
    case Types.UPDATE_QUANT_CARRINHO:
      return {
        ...state,
        itens: state.itens.map(i => (i.produtoSku === action.payload.produtoSku && i.item === action.payload.item
          ? action.payload
          : i)),
      }
    case Types.UPDATE_CONDICAO_ITEM_CARRINHO:
      return {
        ...state,
        itens: state.itens.map(i => (i.produtoSku === action.payload.produtoSku && i.item === action.payload.item
          ? action.payload
          : i)),
      }
    case Types.UPDATE_MODALIDADE_ITENS_CARRINHO:
      return {
        ...state,
        itens: action.payload,
      }
    case Types.UPDATE_MODALIDADE_ITEM_CARRINHO:
      return {
        ...state,
        itens: state.itens.map(i => (i.produtoSku === action.payload.produtoSku && i.item === action.payload.item
          ? action.payload
          : i)),
      }
    case Types.DELETE_ITEM_CARRINHO:
      return {
        ...state,
        itens: state.itens.filter(
          // i => i.produtoSku !== action.payload.produtoSku && i.item !== action.payload.item,
          i => i.item !== action.payload.item,
        ),
      }
    case Types.UPDATE_MOTORISTA_VEICULO_ITEM:
      return {
        ...state,
        itens: state.itens.map(i => (i.produtoSku === action.payload.produtoSku && i.item === action.payload.item
          ? {
            ...i,
            ...action.payload,
          }
          : i)),
      }
    case Types.UPDATE_STATE_CARRINHO:
      return {
        ...state,
        ...action.payload,
      }
    case Types.UPDATE_PAGAMENTO_ALL_ITENS:
      return {
        ...state,
        itens: state.itens.map(i => ({ ...i, ...action.payload })),
      }
    case Types.UPDATE_QUANTIDADE_POPUP:
      return {
        ...state,
        quantidadePopUp: { ...state.quantidadePopUp, ...action.payload },
      }
    case Types.UPDATE_DIALOGS:
      return {
        ...state,
        dialogs: { ...state.dialogs, ...action.payload },
      }
    case Types.UPDATE_DIALOGS_CAD_MOT_VEI:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          dialogFobCadMotoristaVeiculo: {
            ...state.dialogs.dialogFobCadMotoristaVeiculo,
            ...action.payload,
          },
        },
      }
    case Types.CLEAN_DIALOGS_CAD_MOT_VEI:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          dialogFobCadMotoristaVeiculo: initialState.dialogs.dialogFobCadMotoristaVeiculo,
        },
      }
    case Types.UPDATE_OBS_NF:
      return {
        ...state,
        obsnf: action.payload,
      }
    case Types.LOAD_MOTORISTA_VEICULO_ITEM:
      return {
        ...state,
        motoristas: action.payload.motoristas,
        veiculos: action.payload.veiculos,
      }
    case Types.UPDATE_EMBARQUE_ITEM_CARRINHO:
      return {
        ...state,
        itens: state.itens.map(i => (i.produtoSku === action.payload.produtoSku && i.item === action.payload.item
          ? action.payload
          : i)),
      }
    case Types.UPDATE_CLIENTE_ENTREGA:
      return {
        ...state,
        clienteEntrega: {
          ...state.clienteEntrega,
          ...action.payload,
        },
      }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  onResetCarrinho: () => ({ type: Types.RESET_CARRINHO }),
  onLoading: payload => ({ type: Types.LOADING, payload }),
  onBuscandoCarrinho: () => (dispatch) => {
    dispatch({ type: Types.BUSCANDO })
  },
  onLoadingPagamentos: () => (dispatch) => {
    dispatch({ type: Types.PAGAMENTO_LOAD })
  },
  onAddProdutoCarrinho: (data, flags = {}) => (dispatch, getState) => {
    const {
      carrinho: { itens = [], unidades, modalidades },
      usuario: {
        usuario: { unidade },
      },
    } = getState()
    const { respondido = 'N', duplicaProduto = 'N' } = flags
    const aModalidadesItem = []
    const novasUnidades = []
    const novasModalidades = []
    const item = (_.isEmpty(itens) ? itens.length : _.maxBy(itens, 'item').item) + 1
    let payload = { ...data }
    const {
      produtoSku, produtoQuant = 0, produtoQuantMin, produtoDesc = '',
    } = payload
    const novaQuant = +produtoQuant
    // Pergunta
    if (respondido === 'N' && itens.findIndex(p => p.produtoSku === produtoSku) !== -1) {
      dispatch(
        DialogActions.onDialogOpen({
          size: 'sm',
          title: 'O produto já existe no caminhão.',
          content: (
            <DialogContentSimples
              text={`O produto "${produtoDesc}" já existe no caminhão você deseja somar a quantidade ou adicionar outro?`}
            />
          ),
          actions: [
            {
              key: 1,
              color: 'secondary',
              title: 'SOMAR A QUANTIDADE',
              action: () => {
                dispatch(
                  Actions.onAddProdutoCarrinho(data, {
                    ...flags,
                    respondido: 'S',
                    duplicaProduto: 'N',
                  }),
                )
                dispatch(DialogActions.onDialogClose())
              },
            },
            {
              key: 2,
              title: 'ADICIONAR OUTRO',
              action: () => {
                dispatch(
                  Actions.onAddProdutoCarrinho(data, {
                    ...flags,
                    respondido: 'S',
                    duplicaProduto: 'S',
                  }),
                )
                dispatch(DialogActions.onDialogClose())
              },
            },
          ],
        }),
      )
      return false
    }
    // Validações
    if (novaQuant < produtoQuantMin) {
      NotificationManager.warning(
        `A quantidade minima para esse produto é: ${produtoQuantMin}.`,
        'Atenção:',
        8000,
      )
      return false
    }
    const { tabelasPreco = [] } = payload.unidades.find(u => u.id === unidade.id)
    // Modalidades (ADD)
    const tabelasAtivas = tabelasPreco.filter(tb => tb.ativo)
    tabelasAtivas.forEach((itm) => {
      if (modalidades.findIndex(m => m.id === itm.id) === -1) {
        novasModalidades.push({ id: itm.id, sku: itm.sku, descricao: itm.descricao })
      }
      aModalidadesItem.push({ id: itm.id, sku: itm.sku, descricao: itm.descricao })
    })

    const aUnidades = [...unidades, ...novasUnidades]
    const aUnidade = aUnidades[0]
    const aModalidades = [...modalidades, ...novasModalidades]
    const modalidadeItem = aModalidadesItem.find(m => m.sku.toUpperCase() === 'CIF') || aModalidadesItem[0]
    // Calculando valor do produto
    const produtoValorUnit = tabelasAtivas.find(t => t.id === modalidadeItem.id).valor
    const produtoValorTotal = produtoValorUnit * novaQuant

    // Atualizando payload ( Produto ) com nova quantidade
    payload = {
      ...payload,
      item,
      produtoQuant: +novaQuant,
      produtoPesoTotal: +novaQuant * +payload.produtoPeso,
      produtoValorUnit,
      produtoValorTotal,
      isObra: false,
      modalidade: modalidadeItem,
      modalidades: aModalidadesItem,
      motorista: null,
      veiculo: null,
    }

    // Insert ou Update (PRODUTO)
    if (duplicaProduto === 'N' && itens.findIndex(p => p.produtoSku === produtoSku) !== -1) {
      dispatch({ type: Types.UPDATE_PRODUTO, payload })
    } else {
      dispatch({ type: Types.ADD_PRODUTO, payload })
    }

    // Update QTD
    // Update da porra toda.
    dispatch(
      Actions.onUpdateStateCarrinho({
        unidade: aUnidade,
        unidades: aUnidades,
        modalidade: modalidadeItem,
        modalidades: aModalidades,
      }),
    )

    // Update totais
    setTimeout(() => dispatch(Actions.onUpdateQuantItemCarrinho()), 300)

    NotificationManager.success(
      `Produto ${payload.produtoDesc} foi adicionado ao caminhão.`,
      'Sucesso!',
      3000,
    )

    return true
  },
  onAddProdutoObra: (modalidadePreco, obra, motoristaVeiculo = null) => (dispatch, getState) => {
    const {
      carrinho: { itens, modalidades },
    } = getState()
    const { motorista = null, veiculo = null, fobCadMotoristaVeiculo = {} } = motoristaVeiculo || {}
    const idxProduto = itens.findIndex(ic => ic.produtoSku === obra.produtoSku)
    const produtoValorUnit = obra[modalidadePreco]
    const produtoValorTotal = produtoValorUnit * obra.produtoQuantMin
    const modalidade = modalidades.find(m => m.sku.toUpperCase() === modalidadePreco.toUpperCase())
    const payload = {
      ...obra,
      isObra: true,
      modalidadePreco,
      modalidade,
      produtoQuant: obra.produtoQuantMin,
      produtoPesoTotal: obra.produtoQuantMin * obra.produtoPeso,
      produtoValorUnit,
      produtoValorTotal,
      motorista,
      veiculo,
      ...fobCadMotoristaVeiculo,
    }
    // Se existir o produto no carrinho e realizado o update do preço do produto,
    // para o preço da obra.
    if (idxProduto !== -1) {
      // Atualiza item do carrinho
      dispatch({ type: Types.UPDATE_PRODUTO_OBRA, payload })
    } else {
      // Adiciona item no carrinho apartir da obra
      dispatch({ type: Types.ADD_PRODUTO, payload })
    }
  },
  onLoadCarrinho: () => () => {
    // onLoadCarrinho: () => (dispatch, getState) => {
    /*
    const { usuario } = getState().usuario
    const {
      itens = [],
      unidade = {},
      unidades = [],
      modalidade = {},
      modalidades = [],
      pagamentoForma = {},
      pagamentoFormas = [],
      pagamentoCondicao = {},
      pagamentoCondicoes = [],
    } = getCarrinhoLs() || {}

    dispatch({
      type: Types.LOAD_CARRINHO,
      payload: {
        itens,
        unidade: unidade || usuario.cliente.unidades[0],
        unidades: unidades || usuario.cliente.unidades,
        modalidade,
        modalidades,
        pagamentoForma,
        pagamentoFormas,
        pagamentoCondicao,
        pagamentoCondicoes,
      },
    })
    */
  },
  onLoadPagamentos: () => (dispatch, getState) => {
    const { usuario } = getState().usuario
    const aFormas = []
    const aCondicoes = []
    dispatch(Actions.onLoadingPagamentos())
    getPagamentos({ token: usuario.auth.token, id: usuario.cliente.id }).then((formas) => {
      dispatch(Actions.onLoadingPagamentos())
      if (formas && Array.isArray(formas)) {
        formas.forEach((f) => {
          if (aFormas.findIndex(pf => pf.id === f.id) === -1) {
            const {
              id, sku, descricao, status, condicoes,
            } = f
            aFormas.push({
              id,
              sku,
              descricao,
              status,
            })
            condicoes.forEach((c) => {
              if (aCondicoes.findIndex(pc => pc.id === c.id && pc.pgtoFormaId === f.id) === -1) {
                aCondicoes.push(c)
              }
            })
          }
        })
        // Update
        dispatch({
          type: Types.LOAD_CARRINHO,
          payload: {
            pagamentoForma: aFormas[0],
            pagamentoFormas: aFormas,
            pagamentoCondicao: aCondicoes[0],
            pagamentoCondicoes: aCondicoes,
          },
        })
      }
    })
  },
  onLoadObras: () => (dispatch, getState) => {
    const { usuario } = getState()
    const params = {
      token: usuario.usuario.auth.token,
      empresa: usuario.usuario.unidade.empresaCod,
      filial: usuario.usuario.unidade.filialCod,
      codigo: usuario.usuario.cliente.codigo,
      loja: usuario.usuario.cliente.loja,
    }
    dispatch(Actions.onLoading({ loadObras: true }))
    getObras(params).then((obras) => {
      dispatch(Actions.onLoading({ loadObras: false }))
      if (obras && !obras.status) {
        dispatch({
          type: Types.LOAD_CARRINHO,
          payload: { obras },
        })
      } else {
        dispatch({
          type: Types.LOAD_CARRINHO,
          payload: { obras: [] },
        })
      }
    })
  },
  onDeleteCarrinho: () => (dispatch) => {
    dispatch({ type: Types.LOAD_CARRINHO, payload: { itens: initialState.itens } })
    dispatch(Actions.onResetCarrinho())
  },
  onUpdateQuantItemCarrinho: (item, pProdutoQuant) => (dispatch, getState) => {
    if (item && (pProdutoQuant === '' || /^\d+$/.test(pProdutoQuant))) {
      const { produtoPeso, produtoValorUnit } = item
      const produtoValorTotal = pProdutoQuant * produtoValorUnit
      const produtoPesoTotal = pProdutoQuant * produtoPeso
      // Tudo certo, roda o UPDATE!
      dispatch({
        type: Types.UPDATE_QUANT_CARRINHO,
        payload: {
          ...item,
          produtoQuant: String(pProdutoQuant),
          produtoValorTotal,
          produtoPesoTotal,
        },
      })
    }
    if (!item) {
      const {
        carrinho: { itens },
      } = getState()
      itens.forEach((i) => {
        const { produtoQuant, produtoPeso, produtoValorUnit } = i
        const produtoValorTotal = produtoQuant * produtoValorUnit
        const produtoPesoTotal = produtoQuant * produtoPeso
        // Tudo certo, roda o UPDATE!
        dispatch({
          type: Types.UPDATE_QUANT_CARRINHO,
          payload: {
            ...i,
            produtoValorTotal,
            produtoPesoTotal,
          },
        })
      })
    }
    return true
  },
  onValidQuantidadeItemCarrinho: (item, quant, target, fator = 10) => (dispatch) => {
    // Validando quantidade...
    const { produtoPeso, produtoQuantMin, produtoValorUnit } = item
    const produtoQuant = quant < produtoQuantMin ? produtoQuantMin : quant
    // Cáculando quantidade no fator...
    const resto = produtoQuant % fator
    const produtoQuantVendaMin = produtoQuant - resto
    const produtoQuantVendaMax = produtoQuantVendaMin + fator
    const produtoValorTotal = produtoQuant * produtoValorUnit
    const produtoPesoTotal = produtoQuant * produtoPeso
    // Validações
    if (quant < produtoQuantMin) {
      NotificationManager.warning(
        `A quantidade minima para esse produto é de: ${produtoQuantMin}.`,
        'Atenção:',
        3000,
      )
      dispatch({
        type: Types.UPDATE_QUANT_CARRINHO,
        payload: { ...item, produtoQuant: String(produtoQuantMin) },
      })
    }
    if (fator > 0 && resto > 0) {
      const quantidades = [{ value: produtoQuantVendaMin }, { value: produtoQuantVendaMax }]
      dispatch(Actions.onHandleShowQuantidadeItem(target, item, quantidades))
    }
    dispatch({
      type: Types.UPDATE_QUANT_CARRINHO,
      payload: {
        ...item,
        produtoQuant: String(produtoQuant),
        produtoValorTotal,
        produtoPesoTotal,
      },
    })
    return true
  },
  onUpdateModalidadeItensCarrinho: modalidade => (dispatch, getState) => {
    const {
      carrinho,
      usuario: { usuario },
    } = getState()
    const itens = carrinho.itens.map((item) => {
      const isModalidade = item.modalidades.find(m => m.id === modalidade.id)
      if (!isModalidade) {
        return item
      }
      let produtoValorUnit = 0
      let produtoValorTotal = 0
      if (!item.isObra) {
        produtoValorUnit = item.unidades
          .find(f => f.id === usuario.unidade.id)
          .tabelasPreco.find(t => t.id === modalidade.id).valor
        produtoValorTotal = produtoValorUnit * item.produtoQuant
      } else {
        produtoValorUnit = item[item.modalidadePreco]
        produtoValorTotal = produtoValorUnit * item.produtoQuantMin
      }
      return {
        ...item,
        modalidade,
        produtoValorUnit,
        produtoValorTotal,
      }
    })
    dispatch({
      type: Types.UPDATE_MODALIDADE_ITENS_CARRINHO,
      payload: itens,
    })
  },
  onUpdateCondicaoItemCarrinho: (item, pagamentoCondicao) => (dispatch) => {
    const { pagamentoForma } = pagamentoCondicao
    dispatch({
      type: Types.UPDATE_CONDICAO_ITEM_CARRINHO,
      payload: { ...item, pagamentoCondicao, pagamentoForma },
    })
  },
  onUpdateModalidadeItemCarrinho: (item, modalidade) => (dispatch, getState) => {
    const {
      // carrinho,
      usuario: { usuario },
    } = getState()
    let produtoValorUnit = 0
    let produtoValorTotal = 0
    if (!item.isObra) {
      produtoValorUnit = item.unidades
        .find(f => f.id === usuario.unidade.id)
        .tabelasPreco.find(t => t.id === modalidade.id).valor
      produtoValorTotal = produtoValorUnit * item.produtoQuant
    } else {
      produtoValorUnit = item[item.modalidadePreco]
      produtoValorTotal = produtoValorUnit * item.produtoQuantMin
    }
    dispatch({
      type: Types.UPDATE_MODALIDADE_ITEM_CARRINHO,
      payload: {
        ...item,
        modalidade,
        produtoValorUnit,
        produtoValorTotal,
      },
    })
  },
  onDeleteItemCarrinho: item => (dispatch) => {
    dispatch({ type: Types.DELETE_ITEM_CARRINHO, payload: item })
  },
  onUpdateStateCarrinho: element => (dispatch) => {
    dispatch({ type: Types.UPDATE_STATE_CARRINHO, payload: element })
  },
  onUpdatePagamento: (type, value) => (dispatch, getState) => {
    const { pagamentoCondicoes } = getState().carrinho
    if (type === 'pagamentoForma') {
      const sku = value?.sku ?? ''
      const descricao = value?.descricao ?? ''
      dispatch({
        type: Types.LOAD_CARRINHO,
        payload: { [type]: value },
      })
      if (sku === 'ANT') {
        const condPgto = pagamentoCondicoes.find(c => c.descricao.includes(descricao)) ?? {}
        dispatch({
          type: Types.LOAD_CARRINHO,
          payload: {
            pagamentoCondicao: condPgto,
          },
        })
      }
    }
    if (type === 'pagamentoCondicao') {
      dispatch({
        type: Types.LOAD_CARRINHO,
        // payload: { [type]: pagamentoCondicoes.find(f => f.sku === value) },
        payload: { [type]: value },
      })
    }
  },
  onUpdatePagamentoAllItens: () => (dispatch, getState) => {
    const { pagamentoForma, pagamentoCondicao } = getState().carrinho
    if (pagamentoForma) {
      dispatch({
        type: Types.UPDATE_PAGAMENTO_ALL_ITENS,
        payload: { pagamentoForma },
      })
    }
    if (pagamentoCondicao) {
      dispatch({
        type: Types.UPDATE_PAGAMENTO_ALL_ITENS,
        payload: { pagamentoCondicao },
      })
    }
  },
  // TODO FINALIZAR PEDIDO
  onFinalizarPedido: () => (dispatch, getState) => {
    const {
      carrinho,
      usuario: { usuario },
    } = getState()
    const {
      itens,
      pagamentoForma,
      pagamentoCondicao,
      finalizarPedido: { pedidoPadrao = false, pedidoPadraoDescricao = '' },
    } = carrinho
    const pedido = {
      token: usuario.auth.token,
      total: itens.reduce((p, o) => p + +o.produtoValorTotal, 0),
      clienteId: usuario.cliente.id,
      unidadeId: usuario.unidade.id,
      obsnf: carrinho.obsnf,
      clienteEntregaCodigo:
        carrinho.clienteEntrega && carrinho.clienteEntrega.codigo
          ? carrinho.clienteEntrega.codigo
          : '',
      clienteEntregaLoja:
        carrinho.clienteEntrega && carrinho.clienteEntrega.loja ? carrinho.clienteEntrega.loja : '',
      clienteEntregaCgc:
        carrinho.clienteEntrega && carrinho.clienteEntrega.cgc ? carrinho.clienteEntrega.cgc : '',
      clienteEntregaDescricao:
        carrinho.clienteEntrega && carrinho.clienteEntrega.nome ? carrinho.clienteEntrega.nome : '',
      pedidoPadrao,
      pedidoPadraoDescricao,
      itens: itens.map(i => ({
        isObra: i.isObra,
        obraId: i.isObra ? i.id : null,
        produtoId: i.produtoId,
        produtoSku: i.produtoSku,
        produtoQuant: i.produtoQuant,
        produtoPesoUnit: i.produtoPeso,
        produtoValorUnit: i.produtoValorUnit,
        produtoValorTotal: i.produtoValorTotal,
        modalidadeId: i.modalidade.id,
        modalidadeSku: i.modalidade.sku,
        pagamentoFormaId: i.pagamentoForma ? i.pagamentoForma.id : pagamentoForma.id,
        pagamentoFormaSku: i.pagamentoForma ? i.pagamentoForma.sku : pagamentoForma.sku,
        pagamentoCondicaoId: i.pagamentoCondicao ? i.pagamentoCondicao.id : pagamentoCondicao.id,
        pagamentoCondicaoSku: i.pagamentoCondicao ? i.pagamentoCondicao.sku : pagamentoCondicao.sku,
        motoristaSku: i.motorista && i.motorista.motoristaid ? i.motorista.motoristaid : '',
        veiculoPlaca: i.veiculo && i.veiculo.veiculoplaca ? i.veiculo.veiculoplaca : '',
        // Cadastro de novo Motorista X Veiculo
        fobVeiculoModelo: i.fobVeiculoModelo,
        fobVeiculoMarca: i.fobVeiculoMarca,
        fobVeiculoPlaca: i.fobVeiculoPlaca,
        fobVeiculoPlacaCarreta: i.fobVeiculoPlacaCarreta,
        fobMotoristaNome: i.fobMotoristaNome,
        fobMotoristaCpf: i.fobMotoristaCpf,
        fobEmpresaNome: i.fobEmpresaNome,
        fobCompradorNome: i.fobCompradorNome,
        dataEmbarque: `${DateBrToIso(i.dataEmbarque)} 00:00:00`,
      })),
    }
    dispatch({
      type: Types.LOAD_CARRINHO,
      payload: {
        finalizarPedido: {
          dialog: true,
          title: 'Finalizando pedido',
          content: 2,
          pedido: '',
          msg: '',
        },
      },
    })
    // Enviado pedido
    sendPedidoApi(pedido).then((res) => {
      if (res) {
        // Mondando tela de erro.
        if (!Array.isArray(res) && res.status > 201) {
          dispatch({
            type: Types.LOAD_CARRINHO,
            payload: {
              finalizarPedido: {
                dialog: true,
                title: 'Atenção!',
                content: 1,
                pedido: '',
                msg: res.message || res.error,
              },
            },
          })
        } else {
          dispatch(ConfigActions.onAddEvento(EventosEnum.PEDIDO_CRIADO_SUCESSO))
          const pedidosOk = _.isArray(res)
            ? res.reduce((p, o) => p + +o.status, 0) === 201 * res.length
            : false
          if (pedidosOk) {
            dispatch(Actions.onResetCarrinho())
            dispatch({
              type: Types.LOAD_CARRINHO,
              payload: {
                finalizarPedido: {
                  pedidosOk: true,
                  dialog: true,
                  title: 'Sucesso!',
                  content: 1,
                  pedido: '',
                  msg: res.map(r => (
                    <Fragment>
                      {r.message}
                      <br />
                    </Fragment>
                  )),
                },
              },
            })
            setTimeout(() => {
              dispatch(Actions.onFecharDialogFinalizarPedido())
              // redirect
              dispatch(push('/pedidos'))
            }, 20000)
          } else {
            dispatch({
              type: Types.LOAD_CARRINHO,
              payload: {
                finalizarPedido: {
                  dialog: true,
                  title: 'Atenção!',
                  content: 1,
                  pedido: '',
                  size: 'lg',
                  msg: _.isArray(res) ? (
                    res.map(r => (
                      <Fragment>
                        <PedidoErrorComp title={r.message} text={r.log || ''} />
                      </Fragment>
                    ))
                  ) : (
                    <Fragment>
                      {res.message}
                      <br />
                    </Fragment>
                  ),
                },
              },
            })
          }
        }
      } else {
        dispatch({
          type: Types.LOAD_CARRINHO,
          payload: {
            finalizarPedido: initialState.finalizarPedido,
          },
        })
      }
    })
      .catch(() => {
        dispatch({
          type: Types.LOAD_CARRINHO,
          payload: {
            finalizarPedido: initialState.finalizarPedido,
          },
        })
      })
  },
  onConfirmaFinalizarPedido: () => (dispatch, getState) => {
    const {
      usuario: { usuario },
      carrinho: { itens },
    } = getState()
    const DATA_EMBARQUE = dispatch(ConfigActions.getParametro('DATA_EMBARQUE', 'NAO'))
    /*
    const { limite } = usuario.limiteCredito
    const totalPedido = itens.reduce((p, o) => p + +o.produtoValorTotal, 0)
    if (totalPedido > limite) {
      dispatch({
        type: Types.LOAD_CARRINHO,
        payload: {
          finalizarPedido: {
            dialog: true,
            title: 'Atenção!',
            content: 1,
            pedido: '',
            msg: 'Você não possui limite para realizar esse pedido.',
          },
        },
      })
      return false
    }
    */
    if (usuario?.cliente?.status === 'B') {
      dispatch({
        type: Types.LOAD_CARRINHO,
        payload: {
          finalizarPedido: {
            dialog: true,
            title: 'Atenção!',
            content: 1,
            pedido: '',
            msg: 'Não vai ser possível finalizar o seu pedido, favor entrar em contato com setor administrativo!',
          },
        },
      })
      return false
    }
    if (DATA_EMBARQUE === 'SIM' && itens.find(i => !i.dataEmbarqueValid)) {
      dispatch({
        type: Types.LOAD_CARRINHO,
        payload: {
          finalizarPedido: {
            dialog: true,
            title: 'Atenção',
            content: 1,
            pedido: '',
            msg: 'Verifique se você informou alguma data de embarque inválida.',
          },
        },
      })
      return false
    }
    dispatch({
      type: Types.LOAD_CARRINHO,
      payload: {
        finalizarPedido: {
          dialog: true,
          title: 'Confirmação',
          content: 3,
          pedido: '',
          msg: 'Você realmente deseja finalizar o pedido atual?',
          pedidoPadrao: false,
          pedidoPadraoDescricao: '',
        },
      },
    })
    return true
  },
  onFecharDialogFinalizarPedido: () => (dispatch, getState) => {
    const { finalizarPedido } = getState().carrinho
    dispatch({
      type: Types.LOAD_CARRINHO,
      payload: {
        finalizarPedido: { ...finalizarPedido, dialog: false },
      },
    })
  },
  onCheckPedidoPadraoFinalizarPedido: (pedidoPadrao = false) => (dispatch, getState) => {
    const { finalizarPedido } = getState().carrinho
    dispatch({
      type: Types.LOAD_CARRINHO,
      payload: {
        finalizarPedido: { ...finalizarPedido, pedidoPadrao },
      },
    })
  },
  onDescricaoPedidoPadraoFinalizarPedido: (pedidoPadraoDescricao = false) => (
    dispatch,
    getState,
  ) => {
    const { finalizarPedido } = getState().carrinho
    dispatch({
      type: Types.LOAD_CARRINHO,
      payload: {
        finalizarPedido: { ...finalizarPedido, pedidoPadraoDescricao },
      },
    })
  },
  onHandleShowQuantidadeItem: (currentTarget, itemSelected, quantidades) => (dispatch) => {
    // Essa rotina é chamado no momento que o cliente informa uma quantidade
    // fora do fator, então é aberto um PopUp informando a quantidade anterior e proxima.
    dispatch({
      type: Types.UPDATE_QUANTIDADE_POPUP,
      payload: { anchorQuantidade: currentTarget, itemSelected, quantidades },
    })
  },
  onHandleCloseQuantidadeItem: () => (dispatch) => {
    // Essa rotina apenas fecha o PopUp com as sugestões de quantidade.
    dispatch({
      type: Types.UPDATE_QUANTIDADE_POPUP,
      payload: { anchorQuantidade: null },
    })
  },
  onHandleSelectQuantidadeItem: quantidade => (dispatch, getState) => {
    // Essa rotina é chamando quando o cliente seleciona a quantidade possível
    // Ex: Se o fator por 10, e o cliente digitar 233 o PopUp vai conter os valores
    // 230 ou 240.
    const {
      quantidadePopUp: { itemSelected },
    } = getState().carrinho
    dispatch(Actions.onUpdateQuantItemCarrinho(itemSelected, quantidade))
    dispatch(Actions.onHandleCloseQuantidadeItem())
  },
  onHandleShowReset: () => (dispatch) => {
    dispatch({
      type: Types.UPDATE_DIALOGS,
      payload: initialState.dialogs,
    })
  },
  onHandleShowDialogs: payload => (dispatch) => {
    dispatch({
      type: Types.UPDATE_DIALOGS,
      payload,
    })
  },
  onHandleUpdateCadMotVei: payload => (dispatch) => {
    dispatch({
      type: Types.UPDATE_DIALOGS_CAD_MOT_VEI,
      payload,
    })
  },
  onHandleCleanCadMotVei: () => (dispatch) => {
    dispatch({
      type: Types.CLEAN_DIALOGS_CAD_MOT_VEI,
    })
  },
  onUpdateObsNf: payload => (dispatch) => {
    dispatch({ type: Types.UPDATE_OBS_NF, payload })
  },
  onUpdateMotoristaVeiculoItem: payload => (dispatch) => {
    dispatch({ type: Types.UPDATE_MOTORISTA_VEICULO_ITEM, payload })
  },
  onUpdateMotoristaVeiculoItens: payload => (dispatch, getState) => {
    const { carrinho } = getState()
    const itens = carrinho.itens.map(item => ({ ...item, ...payload }))
    dispatch({
      type: Types.UPDATE_MODALIDADE_ITENS_CARRINHO,
      payload: itens,
    })
  },
  onLoadMotoristaVeiculoItem: payload => (dispatch) => {
    dispatch({ type: Types.LOAD_MOTORISTA_VEICULO_ITEM, payload })
  },
  // Cadastro de novo motorista
  // Ação para todos os itens do pedido
  onUpdateCadNovoMotVeiItens: payload => (dispatch, getState) => {
    const { carrinho } = getState()
    const itens = carrinho.itens.map(item => ({ ...item, ...payload }))
    dispatch({
      type: Types.UPDATE_MODALIDADE_ITENS_CARRINHO,
      payload: itens,
    })
  },
  // Ação para um item do pedido
  onUpdateCadNovoMotVeiItem: (item, payload) => (dispatch, getState) => {
    const { carrinho } = getState()
    const itemUpdate = carrinho.itens.find(i => i.produtoSku === item.produtoSku)
    dispatch({ type: Types.UPDATE_MOTORISTA_VEICULO_ITEM, payload: { ...itemUpdate, ...payload } })
  },
  onUpdateDataEmbarqueItemCarrinho: (item, pDataEmbarque) => (dispatch) => {
    dispatch({
      type: Types.UPDATE_EMBARQUE_ITEM_CARRINHO,
      payload: {
        ...item,
        dataEmbarque: pDataEmbarque,
      },
    })
    // }
  },
  onUpdateDataEmbarqueValidItemCarrinho: (item, pDataEmbarque) => (dispatch) => {
    if (pDataEmbarque.replace(new RegExp('/', 'g'), '').trim()) {
      const dataEmbarque = moment(pDataEmbarque, 'DD/MM/YYYY')
      const hoje = moment()
      let dataEmbarqueValid = false
      if (dataEmbarque.isValid()) {
        dataEmbarqueValid = dataEmbarque.diff(hoje, 'days') >= 0
      }
      dispatch({
        type: Types.UPDATE_EMBARQUE_ITEM_CARRINHO,
        payload: {
          ...item,
          dataEmbarqueValid,
        },
      })
    }
  },
  onUpdateValorItemCarrinho: item => (dispatch) => {
    if (item) {
      const { produtoPeso, produtoValorUnit, produtoQuant } = item
      const produtoValorTotal = produtoQuant * produtoValorUnit
      const produtoPesoTotal = produtoQuant * produtoPeso
      // Tudo certo, roda o UPDATE!
      dispatch({
        type: Types.UPDATE_QUANT_CARRINHO,
        payload: {
          ...item,
          produtoValorTotal,
          produtoPesoTotal,
        },
      })
    }
    return true
  },
  onSearchClienteErpFilter: filter => (dispatch, getState) => {
    const { usuario } = getState()
    const { search = '', clienteEntrega = 'N' } = filter
    const dto = {
      token: usuario.usuario.auth.token,
      clienteEntrega,
      search,
    }
    dispatch(DialogActions.onSearchGenericLoading(true))
    searchClienteErpFilterApi(dto).then((payload) => {
      dispatch(DialogActions.onSearchGenericLoading(false))
      if (payload && _.isArray(payload)) {
        dispatch(
          DialogActions.onSearchGenericLoadData({
            page: 1,
            pages: 1,
            rows: payload.length,
            itens: payload,
          }),
        )
      } else {
        // Não retornou nada... o form é zerado!
        dispatch(DialogActions.onSearchGenericClean())
      }
    })
  },
  onUpdateClienteEntrega: payload => ({ type: Types.UPDATE_CLIENTE_ENTREGA, payload }),
}
