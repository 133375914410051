import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Numeral from 'numeral'
import MaskedInput from 'react-text-mask'
import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  TextField,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core'

import ShipIcon from '@material-ui/icons/LocalShipping'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import LaunchIcon from '@material-ui/icons/Launch'
import SendIcon from '@material-ui/icons/Send'
import DeleteIcon from '@material-ui/icons/Delete'
import MoneyIcon from '@material-ui/icons/AttachMoney'
import ObrasIcon from '@material-ui/icons/Widgets'
import ClientesIcon from '@material-ui/icons/Person'
import ObsNfIcon from '@material-ui/icons/Assignment'
import DirectionsBus from '@material-ui/icons/DirectionsBus'

// Actions
import { Actions as UsuarioActions } from '../../store/ducks/usuario'
import { Actions as CarrinhoActions } from '../../store/ducks/carrinho'
import { Actions as ConfigActions } from '../../store/ducks/config'
// Api's
import { getMotoristasVeiculos } from '../../api/cliente'
// Components
import Imagens, { ImagensJpg, ImagensGVB } from '../../components/Produto/Imagens'
import Header from '../../components/Header'
import FormaCondPgtoDialog from '../../components/Dialogs/FormaCondPgto'
import ClienteObraDialog from '../../components/Dialogs/ClienteObras'
import BuscaGenericaComp from '../../components/Dialogs/Contents/BuscaGenerica'
import Visibility from '../../components/Visibility'
// Utils
import { validarCPF } from 'utils'
// Styles
import styles from './styles'

function PlacaMaskCustom(props) {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /\d/, /[a-zA-Z0-9]/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

PlacaMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}
function CPFMaskCustom(props) {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

CPFMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

function DataMaskCustom(props) {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

DataMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

class CarrinhoPage extends Component {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    loading: PropTypes.shape().isRequired,
    dialogs: PropTypes.shape().isRequired,
    usuario: PropTypes.shape().isRequired,
    itens: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    // unidade: PropTypes.shape().isRequired,
    // unidades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    modalidade: PropTypes.shape().isRequired,
    modalidades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    pagamentoForma: PropTypes.shape().isRequired,
    pagamentoFormas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    pagamentoCondicao: PropTypes.shape().isRequired,
    pagamentoCondicoes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fobCadMotoristaVeiculo: PropTypes.shape().isRequired,
    obras: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    obsnf: PropTypes.string.isRequired,
    finalizarPedido: PropTypes.shape().isRequired,
    onLoading: PropTypes.func.isRequired,
    onLoadLimiteCreditoCliente: PropTypes.func.isRequired,
    onDeleteCarrinho: PropTypes.func.isRequired,
    onResetCarrinho: PropTypes.func.isRequired,
    onUpdateCondicaoItemCarrinho: PropTypes.func.isRequired,
    onUpdateModalidadeItensCarrinho: PropTypes.func.isRequired,
    onUpdateModalidadeItemCarrinho: PropTypes.func.isRequired,
    onUpdateQuantItemCarrinho: PropTypes.func.isRequired,
    onValidQuantidadeItemCarrinho: PropTypes.func.isRequired,
    onDeleteItemCarrinho: PropTypes.func.isRequired,
    onUpdateStateCarrinho: PropTypes.func.isRequired,
    onUpdatePagamento: PropTypes.func.isRequired,
    onUpdatePagamentoAllItens: PropTypes.func.isRequired,
    onLoadPagamentos: PropTypes.func.isRequired,
    onLoadObras: PropTypes.func.isRequired,
    onAddProdutoObra: PropTypes.func.isRequired,
    onConfirmaFinalizarPedido: PropTypes.func.isRequired,
    onFinalizarPedido: PropTypes.func.isRequired,
    onFecharDialogFinalizarPedido: PropTypes.func.isRequired,
    onCheckPedidoPadraoFinalizarPedido: PropTypes.func.isRequired,
    onDescricaoPedidoPadraoFinalizarPedido: PropTypes.func.isRequired,
    // PopUp de quantidade
    quantidadePopUp: PropTypes.shape().isRequired,
    onHandleSelectQuantidadeItem: PropTypes.func.isRequired,
    // Dialog FOB - Morista vs Veículo
    onLoadMotoristaVeiculoItem: PropTypes.func.isRequired,
    onHandleShowReset: PropTypes.func.isRequired,
    onHandleShowDialogs: PropTypes.func.isRequired,
    onUpdateMotoristaVeiculoItem: PropTypes.func.isRequired,
    onUpdateMotoristaVeiculoItens: PropTypes.func.isRequired,
    onHandleUpdateCadMotVei: PropTypes.func.isRequired,
    onUpdateCadNovoMotVeiItem: PropTypes.func.isRequired,
    onUpdateCadNovoMotVeiItens: PropTypes.func.isRequired,
    onHandleCleanCadMotVei: PropTypes.func.isRequired,
    // Observacao da nota fiscal
    onUpdateObsNf: PropTypes.func.isRequired,
    onUpdateDataEmbarqueItemCarrinho: PropTypes.func.isRequired,
    onUpdateDataEmbarqueValidItemCarrinho: PropTypes.func.isRequired,
    onUpdateValorItemCarrinho: PropTypes.func.isRequired,
    onSearchClienteErpFilter: PropTypes.func.isRequired,
    onUpdateClienteEntrega: PropTypes.func.isRequired,
  }

  state = {
    anchorModalidade: null,
    anchorModalidadeItem: null,
    anchorCondicao: null,
    dialogCancelOpen: false,
    dialogRemoveItem: false,
    dialogPagamento: false,
    dialogObras: false,
    dialogObsNf: false,
    dialogFinalizar: false,
    itemDelete: null,
    itemSelected: null,
    obraSelected: null,
    preCadastroMotVei: false,
    dialogAjustePrecoItem: {
      open: false,
      itemSeleted: {},
    },
    dialogBuscarClienteEntrega: {
      open: false,
    },
  }

  componentDidMount() {
    const {
      onLoadMotoristaVeiculoItem,
      onLoadLimiteCreditoCliente,
      onHandleShowReset,
      onLoadPagamentos,
      onLoadObras,
      getParametro,
      onFecharDialogFinalizarPedido,
    } = this.props
    onHandleShowReset()
    onLoadLimiteCreditoCliente()
    onLoadPagamentos()
    if (getParametro('CONSULTA_OBRAS', 'NAO') === 'SIM') {
      onLoadObras()
    }
    onLoadMotoristaVeiculoItem({ motoristas: [], veiculos: [] })
    document.title = `Online - ${getParametro('DESC_CARRINHO', 'CAMINHÃO')}`
  }

  onCancelarPedido = () => {
    const { onDeleteCarrinho } = this.props
    onDeleteCarrinho()
    this.toggleDiagCancelPedido()
  }

  onDeleteItemCarrinho = (itemDelete) => {
    this.setState({ itemDelete })
    this.toggleDiagRemoveItem()
  }

  onConfirmeDeleteItemCarrinho = (itemDelete) => {
    const { onDeleteItemCarrinho, onResetCarrinho, itens } = this.props
    onDeleteItemCarrinho(itemDelete)
    if (_.isArray(itens) && itens.length === 1) {
      onResetCarrinho()
    }
    this.toggleDiagRemoveItem()
  }

  onSelectPrecoObra = (modalidade, obraSelected) => {
    const {
      usuario,
      dialogs: { dialogFobMotorista },
      fobCadMotoristaVeiculo,
      obsnf = '',
      onAddProdutoObra,
      onHandleShowReset,
      onHandleShowDialogs,
      onLoading,
      onUpdateObsNf,
      onUpdateMotoristaVeiculoItens,
      onUpdateModalidadeItensCarrinho,
      onHandleUpdateCadMotVei,
    } = this.props

    if (obraSelected.obsnf) {
      if (obsnf) {
        onUpdateObsNf(obsnf.concat(`, ${obraSelected.obsnf}`))
      } else {
        onUpdateObsNf(obraSelected.obsnf)
      }
    }
    // Fecha Dialog da obra
    if (modalidade.toUpperCase() === 'CIF') {
      // this.toggleDiagObras()
      this.openCloseDialogObras(false)
      onAddProdutoObra(modalidade, obraSelected)
    } else {
      // Marca a obra selecionada!
      this.setState({ obraSelected })
      // Abre dialog para selecionar o motorista & veiculo
      // FOB, trabalhar na escolha do motorista e veículo!
      onHandleShowReset()
      // Iniciar busca no backend pelos motoristas
      const params = {
        token: usuario.auth.token,
        empresa: usuario.unidade.empresaCod,
        filial: usuario.unidade.filialCod,
        codigo: usuario.cliente.codigo,
        loja: usuario.cliente.loja,
      }
      onLoading({ loadMotoristaVeiculos: true })
      onHandleShowDialogs({
        dialogFobMotorista: {
          ...dialogFobMotorista,
          open: true,
        },
      })
      // Consulta API de motoristas/veiculos
      getMotoristasVeiculos(params).then((data) => {
        onLoading({ loadMotoristaVeiculos: false })
        if (data && !data.status) {
          // Permite selecionar FOB porem avisa ao cliente que ele deve entrar
          // em contato com a central de atendimento!
          const { motoristas = [], veiculos = [] } = data
          if (motoristas.length === 0 || veiculos.length === 0) {
            // Confirmando modalidade do item no carrinho!
            this.handleCloseModalidade()
            // this.toggleDiagObras()
            this.openCloseDialogObras(false)
            onAddProdutoObra(modalidade, obraSelected)
            // Exibe tela de cadastro para novo MOTORISTA/VEICULO
            onHandleUpdateCadMotVei({
              ...fobCadMotoristaVeiculo,
              fobEmpresaNome: usuario.cliente.razao,
              fobCompradorNome: usuario.nome,
            })
            /*
            setTimeout(() => {
              onUpdateMotoristaVeiculoItens({
                modalidade,
                motorista: null,
                veiculo: null,
              })
            }, 50)
            setTimeout(() => {
              onUpdateModalidadeItensCarrinho(modalidade)
            }, 80)
            */

            /*
            // Confirmando modalidade do item no carrinho!
            onAddProdutoObra(modalidade, obraSelected)
            // NÃO EXISTE ELA DE CADASTRO ou MSG
            // INFORMANDO QUE NÃO EXISTE MOTORISTA/VEICULO
            onHandleShowDialogs({
              dialogFobMotorista: {
                ...dialogFobMotorista,
                open: false,
              },
            })
            // FECHA DIALOG DE OBRAS
            this.toggleDiagObras()
            */
          } else {
            onHandleShowDialogs({
              dialogFobMotorista: {
                ...dialogFobMotorista,
                open: true,
                modalidade,
                motorista: null,
                veiculo: null,
                motoristas,
                veiculos,
              },
            })
          }
        }
      })
    }
  }

  handleClickCondPagamento = () => {
    this.toggleDiagPagamento()
  }

  // TODO Modalidade >>>

  // DO PEDIDO
  handleClickModalidade = (event) => {
    this.setState({ anchorModalidade: event.currentTarget })
    /*
    const { fobCadMotoristaVeiculo, onHandleUpdateCadMotVei } = this.props
    console.log('handleClickModalidade', fobCadMotoristaVeiculo)
    if (fobCadMotoristaVeiculo && fobCadMotoristaVeiculo.fobVeiculoModelo) {
      onHandleUpdateCadMotVei(fobCadMotoristaVeiculo)
    }
    */
  }

  handleCloseModalidade = () => {
    this.setState({ anchorModalidade: null })
  }

  handleSelectModalidade = (modalidade) => {
    const {
      usuario,
      itens,
      dialogs: { dialogFobMotorista },
      fobCadMotoristaVeiculo = {},
      onLoading,
      onHandleShowReset,
      onHandleShowDialogs,
      onUpdateMotoristaVeiculoItens,
      onUpdateModalidadeItensCarrinho,
      onUpdateStateCarrinho,
      onHandleUpdateCadMotVei,
      getParametro,
    } = this.props
    const p_CAD_MOTORISTA_CARRINHO = getParametro('CAD_MOTORISTA_CARRINHO', 'NAO')
    if (modalidade.sku.toUpperCase() === 'CIF') {
      this.handleCloseModalidade()
      onUpdateStateCarrinho({ modalidade })
      onUpdateModalidadeItensCarrinho(modalidade)
      setTimeout(() => {
        onUpdateMotoristaVeiculoItens({
          // modalidade,
          motorista: null,
          veiculo: null,
        })
      }, 100)
    }
    // FOB sem motorista
    if (modalidade.sku.toUpperCase() === 'FOB' && p_CAD_MOTORISTA_CARRINHO === 'NAO') {
      this.handleCloseModalidade()
      onUpdateStateCarrinho({ modalidade })
      onUpdateModalidadeItensCarrinho(modalidade)
      setTimeout(() => {
        onUpdateMotoristaVeiculoItens({
          // modalidade,
          motorista: null,
          veiculo: null,
        })
      }, 100)
    }
    // FOB com motorista
    if (modalidade.sku.toUpperCase() === 'FOB' && p_CAD_MOTORISTA_CARRINHO === 'SIM') {
      // FOB, trabalhar na escolha do motorista e veículo!
      onHandleShowReset()
      // Iniciar busca no backend pelos motoristas
      const params = {
        token: usuario.auth.token,
        empresa: usuario.unidade.empresaCod,
        filial: usuario.unidade.filialCod,
        codigo: usuario.cliente.codigo,
        loja: usuario.cliente.loja,
      }
      const itensPre = itens.filter(i => i.fobVeiculoModelo)
      // Modalidade, pre-cadastro
      if (itensPre.length === itens.length) {
        const item = itens.find(i => i.fobVeiculoModelo)
        const {
          fobVeiculoModelo = '',
          fobVeiculoMarca = '',
          fobVeiculoPlaca = '',
          fobVeiculoPlacaCarreta = '',
          fobMotoristaNome = '',
          fobMotoristaCpf = '',
          fobEmpresaNome = usuario.cliente.razao,
          fobCompradorNome = usuario.nome,
        } = item
        // Exibe tela de cadastro para novo MOTORISTA/VEICULO
        onHandleUpdateCadMotVei({
          fobVeiculoModelo,
          fobVeiculoMarca,
          fobVeiculoPlaca,
          fobVeiculoPlacaCarreta,
          fobMotoristaNome,
          fobMotoristaCpf,
          fobEmpresaNome,
          fobCompradorNome,
        })
        onHandleShowDialogs({
          dialogFobMotorista: {
            ...dialogFobMotorista,
            open: true,
            motorista: null,
            veiculo: null,
            motoristas: [],
            veiculos: [],
          },
        })
      } else {
        // Consulta API de motoristas/veiculos
        onLoading({ loadMotoristaVeiculos: true })
        getMotoristasVeiculos(params).then((data) => {
          onLoading({ loadMotoristaVeiculos: false })
          if (data && !data.status) {
            // Permite selecionar FOB porem avisa ao cliente que ele deve entrar
            // em contato com a central de atendimento!
            const { motoristas = [], veiculos = [] } = data
            if (motoristas.length === 0 || veiculos.length === 0) {
              // Confirmando modalidade do item no carrinho!
              this.handleCloseModalidade()
              onUpdateStateCarrinho({ modalidade })
              setTimeout(() => {
                onUpdateMotoristaVeiculoItens({
                  modalidade,
                  motorista: null,
                  veiculo: null,
                })
              }, 50)
              setTimeout(() => {
                onUpdateModalidadeItensCarrinho(modalidade)
              }, 80)
              // Exibe tela de cadastro para novo MOTORISTA/VEICULO
              onHandleUpdateCadMotVei({
                ...fobCadMotoristaVeiculo,
                fobEmpresaNome: usuario.cliente.razao,
                fobCompradorNome: usuario.nome,
              })
              onHandleShowDialogs({
                dialogFobMotorista: {
                  ...dialogFobMotorista,
                  open: true,
                  motorista: null,
                  veiculo: null,
                  motoristas,
                  veiculos,
                },
              })
            } else {
              onHandleShowDialogs({
                dialogFobMotorista: {
                  ...dialogFobMotorista,
                  open: true,
                  modalidade,
                  motorista: null,
                  veiculo: null,
                  motoristas,
                  veiculos,
                },
              })
            }
          }
        })
      }
    }
  }

  // DO ITEM
  handleClickModalidadeItem = (event, itemSelected) => {
    this.setState({ anchorModalidadeItem: event.currentTarget, itemSelected })
  }

  handleCloseModalidadeItem = () => {
    this.setState({ anchorModalidadeItem: null })
  }

  handleCleanItemSelected = () => {
    this.setState({ itemSelected: null })
  }

  handleSelectModalidadeItem = (modalidade, itemGridSelected = null) => {
    const {
      usuario,
      dialogs: { dialogFobMotorista },
      onLoading,
      onHandleShowReset,
      onHandleShowDialogs,
      // onLoadMotoristaVeiculoItem,
      onUpdateMotoristaVeiculoItem,
      onUpdateModalidadeItemCarrinho,
      onHandleUpdateCadMotVei,
      getParametro,
    } = this.props
    const p_CAD_MOTORISTA_CARRINHO = getParametro('CAD_MOTORISTA_CARRINHO', 'NAO')
    const { itemSelected: itemStateSelected } = this.state
    const itemSelected = itemGridSelected || itemStateSelected
    if (itemGridSelected) this.setState({ itemSelected })
    if (itemSelected.isObra) this.setState({ obraSelected: itemSelected })
    // Ao clicar direto na DESCRIÇÃO => FOB
    this.handleCloseModalidadeItem()
    // CIF
    if (modalidade.sku.toUpperCase() === 'CIF') {
      const item = {
        ...itemSelected,
        modalidade,
        motorista: null,
        veiculo: null,
        motoristas: [],
        veiculos: [],
      }
      // Removendo o pré-cadastro
      if (item.fobVeiculoModelo) {
        delete item.fobVeiculoModelo
        delete item.fobVeiculoMarca
        delete item.fobVeiculoPlaca
        delete item.fobVeiculoPlacaCarreta
        delete item.fobMotoristaNome
        delete item.fobMotoristaCpf
        delete item.fobEmpresaNome
        delete item.fobCompradorNome
      }
      // Caso seja selecionado CIF apos um FOB o sistema limpa os dados do motorista
      onUpdateMotoristaVeiculoItem(item)
      onUpdateModalidadeItemCarrinho(item, modalidade)
    }
    // FOB sem motorista
    if (modalidade.sku.toUpperCase() === 'FOB' && p_CAD_MOTORISTA_CARRINHO === 'NAO') {
      const item = {
        ...itemSelected,
        modalidade,
        motorista: null,
        veiculo: null,
        motoristas: [],
        veiculos: [],
      }
      // Removendo o pré-cadastro
      if (item.fobVeiculoModelo) {
        delete item.fobVeiculoModelo
        delete item.fobVeiculoMarca
        delete item.fobVeiculoPlaca
        delete item.fobVeiculoPlacaCarreta
        delete item.fobMotoristaNome
        delete item.fobMotoristaCpf
        delete item.fobEmpresaNome
        delete item.fobCompradorNome
      }
      // Caso seja selecionado CIF apos um FOB o sistema limpa os dados do motorista
      onUpdateMotoristaVeiculoItem(item)
      onUpdateModalidadeItemCarrinho(item, modalidade)
    }
    // FOB com motorista
    if (modalidade.sku.toUpperCase() === 'FOB' && p_CAD_MOTORISTA_CARRINHO === 'SIM') {
      const item = {
        ...itemSelected,
        modalidade,
        motorista: null,
        veiculo: null,
      }
      onHandleShowReset()
      onLoading({ loadMotoristaVeiculos: false })
      // Iniciar busca no backend pelos motoristas
      const params = {
        token: usuario.auth.token,
        empresa: usuario.unidade.empresaCod,
        filial: usuario.unidade.filialCod,
        codigo: usuario.cliente.codigo,
        loja: usuario.cliente.loja,
      }
      // Modalidade, pre-cadastro
      if (item.fobVeiculoModelo) {
        const {
          fobVeiculoModelo = '',
          fobVeiculoMarca = '',
          fobVeiculoPlaca = '',
          fobVeiculoPlacaCarreta = '',
          fobMotoristaNome = '',
          fobMotoristaCpf = '',
          fobEmpresaNome = usuario.cliente.razao,
          fobCompradorNome = usuario.nome,
        } = item
        // Exibe tela de cadastro para novo MOTORISTA/VEICULO
        onHandleUpdateCadMotVei({
          fobVeiculoModelo,
          fobVeiculoMarca,
          fobVeiculoPlaca,
          fobVeiculoPlacaCarreta,
          fobMotoristaNome,
          fobMotoristaCpf,
          fobEmpresaNome,
          fobCompradorNome,
        })
        onHandleShowDialogs({
          dialogFobMotorista: {
            ...dialogFobMotorista,
            open: true,
            motorista: null,
            veiculo: null,
            motoristas: [],
            veiculos: [],
          },
        })
      } else {
        // Start loading
        onLoading({ loadMotoristaVeiculos: true })
        // Consulta API de motoristas/veiculos
        getMotoristasVeiculos(params).then((data) => {
          onLoading({ loadMotoristaVeiculos: false })
          if (data && !data.status) {
            // Permite selecionar FOB porem avisa ao cliente que ele deve entrar
            // em contato com a central de atendimento!
            const { motoristas = [], veiculos = [] } = data
            if (motoristas.length === 0 || veiculos.length === 0) {
              // Confirmando modalidade do item no carrinho!
              onUpdateMotoristaVeiculoItem(item)
              onUpdateModalidadeItemCarrinho(item, modalidade)
              const {
                fobVeiculoModelo = '',
                fobVeiculoMarca = '',
                fobVeiculoPlaca = '',
                fobVeiculoPlacaCarreta = '',
                fobMotoristaNome = '',
                fobMotoristaCpf = '',
                fobEmpresaNome = usuario.cliente.razao,
                fobCompradorNome = usuario.nome,
              } = item
              // Exibe tela de cadastro para novo MOTORISTA/VEICULO
              onHandleUpdateCadMotVei({
                fobVeiculoModelo,
                fobVeiculoMarca,
                fobVeiculoPlaca,
                fobVeiculoPlacaCarreta,
                fobMotoristaNome,
                fobMotoristaCpf,
                fobEmpresaNome,
                fobCompradorNome,
              })
              onHandleShowDialogs({
                dialogFobMotorista: {
                  ...dialogFobMotorista,
                  open: true,
                  motorista: null,
                  veiculo: null,
                  motoristas,
                  veiculos,
                },
              })
            } else {
              onHandleShowDialogs({
                dialogFobMotorista: {
                  ...dialogFobMotorista,
                  open: true,
                  modalidade,
                  motorista: !itemSelected.motorista ? null : itemSelected.motorista,
                  veiculo: !itemSelected.veiculo ? null : itemSelected.veiculo,
                  motoristas,
                  veiculos,
                },
              })
            }
          }
        })
      }
    }
  }

  handleConfirmeFobMotoristaVeiculo = () => {
    const {
      itemSelected, anchorModalidade, dialogObras, obraSelected,
    } = this.state
    const {
      dialogs: { dialogFobMotorista },
      onAddProdutoObra,
      onHandleShowDialogs,
      onUpdateStateCarrinho,
      onUpdateMotoristaVeiculoItem,
      onUpdateMotoristaVeiculoItens,
      onUpdateModalidadeItemCarrinho,
      onUpdateModalidadeItensCarrinho,
    } = this.props
    const { modalidade, motorista, veiculo } = dialogFobMotorista
    // Fecha popUp de ação em massa e atualiza todos os itens
    if (anchorModalidade && !dialogObras) {
      // SEM SER OBRA, PARA TODOS OS ITENS
      this.handleCloseModalidade()
      onUpdateStateCarrinho({ modalidade })
      onUpdateModalidadeItensCarrinho(modalidade)
      setTimeout(() => {
        onUpdateMotoristaVeiculoItens({
          // modalidade,
          motorista,
          veiculo,
        })
      }, 100)
    } else if (dialogObras && obraSelected) {
      // COM OBRA
      // this.toggleDiagObras()
      this.openCloseDialogObras(false)
      onAddProdutoObra(modalidade, obraSelected, { motorista, veiculo })
    } else {
      const item = {
        ...itemSelected,
        modalidade,
        motorista,
        veiculo,
      }
      // Update Motorista x Veiculo e Modalidade para o item
      // onUpdateMotoristaVeiculoItem(item)
      // Confirmando modalidade do item no carrinho!
      onUpdateModalidadeItemCarrinho(item, modalidade)
      // Remove item seleted
      this.handleCleanItemSelected()
    }
    // Fecha o dialog!
    onHandleShowDialogs({
      dialogFobMotorista: {
        ...dialogFobMotorista,
        open: false,
      },
    })
  }

  handleSaveFobCadMotoristaVeiculo = () => {
    const { itemSelected, dialogObras, obraSelected } = this.state
    const {
      dialogs: { dialogFobMotorista, dialogFobCadMotoristaVeiculo },
      onHandleShowDialogs,
      onUpdateCadNovoMotVeiItem,
      onUpdateCadNovoMotVeiItens,
      onHandleCleanCadMotVei,
      onUpdateStateCarrinho,
      onUpdateModalidadeItemCarrinho,
      onUpdateModalidadeItensCarrinho,
      onAddProdutoObra,
    } = this.props
    const {
      fobVeiculoModelo = '',
      fobVeiculoMarca = '',
      fobVeiculoPlaca = '',
      // fobVeiculoPlacaCarreta = '',
      fobMotoristaNome = '',
      fobMotoristaCpf = '',
      fobEmpresaNome = '',
      fobCompradorNome = '',
    } = dialogFobCadMotoristaVeiculo
    // let hasError = false
    let errorMsg = ''
    // Validação
    if (!errorMsg && !fobVeiculoModelo) {
      errorMsg = 'Você deve informar o MODELO do veículo'
    }
    if (!errorMsg && !fobVeiculoMarca) {
      errorMsg = 'Você deve informar a MARCA do veículo'
    }
    if (!errorMsg && !fobVeiculoPlaca) {
      errorMsg = 'Você deve informar a PLACA do veículo'
    }
    if (!errorMsg && !fobMotoristaNome) {
      errorMsg = 'Você deve informar o NOME do motorista'
    }
    if (!errorMsg && !fobMotoristaCpf) {
      errorMsg = 'Você deve informar o CPF do motorista'
    }
    if (!errorMsg && !validarCPF(dialogFobCadMotoristaVeiculo.fobMotoristaCpf)) {
      errorMsg = 'O CPF do motorista está inválido!'
    }
    if (!errorMsg && !fobEmpresaNome) {
      errorMsg = 'Você deve informar o NOME da empresa'
    }
    if (!errorMsg && !fobCompradorNome) {
      errorMsg = 'Você deve informar o NOME do comprador'
    }

    if (errorMsg) {
      NotificationManager.info(errorMsg, 'Atenção:', 3000)
    }

    if (!errorMsg) {
      const { modalidade } = dialogFobMotorista
      // Atualiza um unico item do pedido
      if (itemSelected) {
        const itemUpdated = {
          ...itemSelected,
          ...dialogFobCadMotoristaVeiculo,
          modalidade,
          motorista: null,
          veiculo: null,
        }
        onUpdateModalidadeItemCarrinho(itemUpdated, modalidade)
      } else if (obraSelected) {
        // COM OBRA
        onAddProdutoObra(modalidade.sku, obraSelected, {
          fobCadMotoristaVeiculo: dialogFobCadMotoristaVeiculo,
        })
      } else {
        this.handleCloseModalidade()
        onUpdateStateCarrinho({ modalidade, fobCadMotoristaVeiculo: dialogFobCadMotoristaVeiculo })
        onUpdateCadNovoMotVeiItens({
          ...dialogFobCadMotoristaVeiculo,
          modalidade,
          motorista: null,
          veiculo: null,
        })
        setTimeout(() => {
          onUpdateModalidadeItensCarrinho(modalidade)
        }, 80)
      }
      // Finalizando e fechando a dialog!
      onHandleShowDialogs({
        dialogFobMotorista: {
          ...dialogFobMotorista,
          open: false,
        },
      })
      onHandleCleanCadMotVei()
      // Remove itemSeleted (item do pedido selecionado)
      this.handleCleanItemSelected()
    }
  }

  // <<< Modalidade

  // Condicao >>>
  // Por item
  handleClickCondicao = (event, itemSelected) => {
    this.setState({ anchorCondicao: event.currentTarget, itemSelected })
  }

  handleSelectCondicao = (pagamentoCondicao) => {
    const { itemSelected } = this.state
    const { onUpdateCondicaoItemCarrinho, pagamentoFormas } = this.props
    onUpdateCondicaoItemCarrinho(itemSelected, {
      ...pagamentoCondicao,
      pagamentoForma: {
        ...pagamentoFormas.find(pf => pf.id === pagamentoCondicao.pgtoFormaId),
      },
    })
    this.handleCloseCondicao()
  }

  handleCloseCondicao = () => {
    this.setState({ anchorCondicao: null, itemSelected: null })
  }

  // Para todos os itens ( dialog )
  handleSaveCondicaoPagamento = () => {
    const { onUpdatePagamentoAllItens } = this.props
    onUpdatePagamentoAllItens()
    this.toggleDiagPagamento()
  }

  handleChangeField = (pField, pNewValue) => {
    const { onUpdatePagamento } = this.props
    onUpdatePagamento(pField, pNewValue)
  }
  // <<< Condicao

  // Update carrinho
  handleUpQtdItemCarrinho = (item, produtoQuant) => {
    const { onUpdateQuantItemCarrinho } = this.props
    if (/^\d+$/.test(produtoQuant)) {
      onUpdateQuantItemCarrinho(item, produtoQuant)
      _.debounce(() => {
        const { itens } = this.props
        const oItem = itens.find(i => i.produtoSku === item.produtoSku)
        if (produtoQuant && oItem.produtoQuant < oItem.produtoQuantMin) {
          NotificationManager.warning(
            `A quantidade minima de para esse produto é ${oItem.produtoQuantMin}.`,
            'Atenção:',
            3000,
          )
          onUpdateQuantItemCarrinho(item, oItem.produtoQuantMin)
        }
      }, 1000)()
    }
  }

  toggleDiagCancelPedido = () => {
    this.setState(state => ({ dialogCancelOpen: !state.dialogCancelOpen }))
  }

  toggleDiagRemoveItem = () => {
    this.setState(state => ({ dialogRemoveItem: !state.dialogRemoveItem }))
  }

  toggleDiagPagamento = () => {
    this.setState(state => ({ dialogPagamento: !state.dialogPagamento }))
  }

  toggleDiagObras = () => {
    this.setState(state => ({ dialogObras: !state.dialogObras }))
  }

  openCloseDialogObras = (dialogObras) => {
    this.setState({ dialogObras })
  }

  toggleDiagObsNf = () => {
    this.setState(state => ({ dialogObsNf: !state.dialogObsNf }))
  }

  toggleDiagFinalizarPedido = () => {
    this.setState(state => ({ dialogFinalizar: !state.dialogFinalizar }))
  }

  // {/* `O Pedido de N° ${pedido} foi criado com sucesso!` */}
  finalizarDialogContent = ({
    content,
    msg,
    pedidoPadrao = false,
    // pedidoPadraoDescricao = '',
    kUsaPedidoPadrao = false,
  }) => {
    const {
      onCheckPedidoPadraoFinalizarPedido,
      onDescricaoPedidoPadraoFinalizarPedido,
    } = this.props
    switch (content) {
      case 1:
        return <div>{msg}</div>
      case 2:
        return (
          <div style={{ textAlign: 'center', paddingBottom: 20 }}>
            <CircularProgress size={18} />
            {' Aguarde...'}
          </div>
        )
      case 3:
        return (
          <div>
            <div style={{ textAlign: 'center', paddingBottom: 20 }}>{msg}</div>
            <Visibility show={kUsaPedidoPadrao}>
              <div style={{ textAlign: 'right', fontSize: 12 }}>
                <input
                  type="checkbox"
                  checked={pedidoPadrao}
                  style={{ verticalAlign: 'middle', marginRight: 3 }}
                  onChange={() => onCheckPedidoPadraoFinalizarPedido(!pedidoPadrao)}
                />
                Salvar como pedido padrão?
              </div>
            </Visibility>
            {pedidoPadrao && (
              <div style={{ textAlign: 'right', marginTop: 3 }}>
                <label htmlFor="pedidoPadraoDescricao" style={{ marginRight: 10 }}>
                  Descrição:
                </label>
                <input
                  type="text"
                  id="pedidoPadraoDescricao"
                  name="pedidoPadraoDescricao"
                  placeholder="Informe uma descriação"
                  onChange={({ target: { value = '' } = {} }) => onDescricaoPedidoPadraoFinalizarPedido(value.toUpperCase())
                  }
                />
              </div>
            )}
          </div>
        )
      default:
        return <div style={{ textAlign: 'center' }}>{msg}</div>
    }
  }

  // Dialog pedido finalizado
  finalizarDialogActionContent = ({ content }) => {
    const {
      onFecharDialogFinalizarPedido,
      onFinalizarPedido,
      finalizarPedido: { pedidoPadrao = false, pedidoPadraoDescricao = '' },
    } = this.props
    switch (content) {
      case 1:
        return (
          <DialogActions>
            <Button onClick={onFecharDialogFinalizarPedido} color="primary">
              Fechar
            </Button>
          </DialogActions>
        )
      case 2:
        return null
      default:
        return (
          <DialogActions>
            <Button onClick={onFecharDialogFinalizarPedido} color="secondary">
              NÃO
            </Button>
            <Button
              disabled={pedidoPadrao && !pedidoPadraoDescricao.trim()}
              onClick={onFinalizarPedido}
              color="primary"
              variant="contained"
            >
              SIM
            </Button>
          </DialogActions>
        )
    }
  }

  // TODO Botoes de acao do carrinho
  cardHeaderActions = () => {
    const { anchorModalidade } = this.state
    const {
      classes,
      modalidade,
      modalidades,
      pagamentoForma,
      pagamentoCondicao,
      onConfirmaFinalizarPedido,
      getParametro,
      clienteEntrega,
    } = this.props
    // FORMA/CONDICAO de PAGAMENTO
    return (
      <Grid container direction="row" justifyContent="center" className={classes.actions}>
        <Grid item>
          {/* Forma de pagamento */}
          <Button
            variant="outlined"
            aria-label="Cond.Pagamento"
            size="small"
            className={classes.button}
            onClick={this.handleClickCondPagamento}
          >
            <MoneyIcon className={[classes.leftIcon, classes.iconSmall].join(' ')} />
            {!pagamentoForma && 'Forma/Cond. Pagamento: NENHUMA'}
            {pagamentoForma && pagamentoForma.sku === 'ANT' && 'Forma. Pagamento: [ANT] ANTECIPADO'}
            {pagamentoForma
              && pagamentoForma.sku !== 'ANT'
              && `Forma/Cond. Pagamento: <[${pagamentoCondicao?.sku}]/${pagamentoCondicao?.descricao}>`}
          </Button>
          {/* Modalidade */}
          <Button
            variant="outlined"
            aria-owns={anchorModalidade ? 'menu-modalidade' : undefined}
            aria-label="Modalidade"
            size="small"
            className={classes.button}
            onClick={this.handleClickModalidade}
          >
            <ShipIcon className={[classes.leftIcon, classes.iconSmall].join(' ')} />
            {`Modalidade: <${modalidade.descricao}>`}
          </Button>
          <Menu
            id="menu-modalidade"
            anchorEl={anchorModalidade}
            open={Boolean(anchorModalidade)}
            onClose={this.handleCloseModalidade}
          >
            {modalidades.map(m => (
              <MenuItem key={m.id} onClick={() => this.handleSelectModalidade(m)}>
                {m.descricao}
              </MenuItem>
            ))}
          </Menu>
          {getParametro('CONSULTA_OBRAS', 'NAO') === 'SIM' && (
            <Button
              variant="outlined"
              aria-label="Obra"
              size="small"
              className={classes.button}
              onClick={() => this.openCloseDialogObras(true)}
            >
              <ObrasIcon className={[classes.leftIcon, classes.iconSmall].join(' ')} />
              Obra(s)
            </Button>
          )}
          {getParametro('CLIENTES_ENTREGAS', 'NAO') === 'SIM' && (
            <Tooltip
              title={`${
                clienteEntrega && clienteEntrega.codigo
                  ? `${clienteEntrega.codigo}/${clienteEntrega.loja} - ${clienteEntrega.nome}`
                  : ''
              }`}
              placement="top"
            >
              <Button
                variant="outlined"
                aria-label="Cliente para entrega"
                size="small"
                className={classes.button}
                onClick={() => this.handleClienteEntregaOpenClose(true)}
              >
                <ClientesIcon className={[classes.leftIcon, classes.iconSmall].join(' ')} />
                {`Cliente/Ent.<${
                  clienteEntrega && clienteEntrega.codigo
                    ? `${clienteEntrega.codigo}/${clienteEntrega.loja}`
                    : 'NENHUM'
                }>`}
              </Button>
            </Tooltip>
          )}
          {/* Observação da nota fiscal */}
          <Button
            variant="outlined"
            aria-label="Obra"
            size="small"
            className={classes.button}
            onClick={this.toggleDiagObsNf}
          >
            <ObsNfIcon className={[classes.leftIcon, classes.iconSmall].join(' ')} />
            Observação da nota fiscal
          </Button>
          {/* BTN CANCELAR PEDIDO */}
          <Button
            variant="outlined"
            color="secondary"
            aria-label="Cancelar"
            size="small"
            className={classes.button}
            onClick={this.toggleDiagCancelPedido}
          >
            <HighlightOffIcon className={[classes.leftIcon, classes.iconSmall].join(' ')} />
            Cancelar
          </Button>
          {/* BTN FINALIZAR PEDIDO */}
          <Button
            variant="outlined"
            aria-label="Finalizar"
            size="small"
            className={[classes.button, classes.btnFinalizarPedido].join(' ')}
            onClick={() => onConfirmaFinalizarPedido()}
          >
            <SendIcon className={[classes.leftIcon, classes.iconSmall].join(' ')} />
            Finalizar
          </Button>
        </Grid>
      </Grid>
    )
  }

  handlePreCadastro = () => {
    const { itemSelected, obraSelected } = this.state
    const {
      usuario,
      modalidades,
      dialogs: { dialogFobMotorista, dialogFobCadMotoristaVeiculo },
      onHandleShowDialogs,
      onUpdateStateCarrinho,
    } = this.props
    const modalidade = modalidades.find(m => m.sku.toUpperCase() === 'FOB')
    if (obraSelected) {
      this.openCloseDialogObras(false)
      // this.toggleDiagObras()
    }
    onHandleShowDialogs({
      dialogFobMotorista: {
        ...dialogFobMotorista,
        open: true,
        modalidade,
        motorista: null,
        veiculo: null,
        motoristas: [],
        veiculos: [],
      },
      dialogFobCadMotoristaVeiculo: {
        ...dialogFobCadMotoristaVeiculo,
        fobEmpresaNome: usuario.cliente.razao,
        fobCompradorNome: usuario.nome,
      },
    })
  }

  handleLimparPreCadastro = () => {
    const { itemSelected, obraSelected } = this.state
    const {
      itens,
      dialogs: { dialogFobMotorista },
      modalidades,
      onHandleShowDialogs,
      onUpdateStateCarrinho,
      onUpdateModalidadeItemCarrinho,
      onAddProdutoObra,
    } = this.props
    let modalidade = modalidades.find(m => m.sku.toUpperCase() === 'CIF')
    if (itemSelected && !itemSelected.isObra && itemSelected.fobVeiculoModelo) {
      if (!modalidade) {
        modalidade = itemSelected.modalidade
      }
      delete itemSelected.fobVeiculoModelo
      delete itemSelected.fobVeiculoMarca
      delete itemSelected.fobVeiculoPlaca
      delete itemSelected.fobVeiculoPlacaCarreta
      delete itemSelected.fobMotoristaNome
      delete itemSelected.fobMotoristaCpf
      delete itemSelected.fobEmpresaNome
      delete itemSelected.fobCompradorNome
      onUpdateModalidadeItemCarrinho(itemSelected, modalidade)
      this.handleCleanItemSelected()
    } else if (obraSelected && obraSelected.fobVeiculoModelo) {
      if (!modalidade && obraSelected.cif === 0) {
        modalidade = obraSelected.modalidade
      }
      // limpar fob de obra
      delete obraSelected.fobVeiculoModelo
      delete obraSelected.fobVeiculoMarca
      delete obraSelected.fobVeiculoPlaca
      delete obraSelected.fobVeiculoPlacaCarreta
      delete obraSelected.fobMotoristaNome
      delete obraSelected.fobMotoristaCpf
      delete obraSelected.fobEmpresaNome
      delete obraSelected.fobCompradorNome
      onAddProdutoObra(modalidade.sku, { ...obraSelected, modalidade })
    } else {
      // Ação para todos os itens do pedido!
      itens.map((i) => {
        if (!modalidade && !i.isObra) {
          modalidade = i.modalidade
        }
        if (!modalidade && i.isObra && i.cif === 0) {
          modalidade = i.modalidade
        }
        delete i.fobVeiculoModelo
        delete i.fobVeiculoMarca
        delete i.fobVeiculoPlaca
        delete i.fobVeiculoPlacaCarreta
        delete i.fobMotoristaNome
        delete i.fobMotoristaCpf
        delete i.fobEmpresaNome
        delete i.fobCompradorNome
        if (i.isObra) {
          onAddProdutoObra(modalidade.sku, { ...i, modalidade })
        } else {
          onUpdateModalidadeItemCarrinho(i, modalidade)
        }
      })
      this.handleCloseModalidade()
      onUpdateStateCarrinho({ modalidade })
    }
    // Fecha dialog!
    onHandleShowDialogs({
      dialogFobMotorista: {
        ...dialogFobMotorista,
        open: false,
      },
    })
  }

  handleOnChangePreco = (produtoValorUnit) => {
    this.setState(s => ({
      ...s,
      dialogAjustePrecoItem: {
        ...s.dialogAjustePrecoItem,
        itemSeleted: {
          ...s.dialogAjustePrecoItem.itemSeleted,
          produtoValorUnit: +produtoValorUnit,
        },
      },
    }))
  }

  // Ajuste de preço
  handleAjustePrecoOpenClose = (open, itemSeleted = {}) => {
    this.setState(s => ({
      ...s,
      dialogAjustePrecoItem: {
        ...s.dialogAjustePrecoItem,
        open,
        itemSeleted,
      },
    }))
  }

  handleAjustePreco = (item) => {
    this.handleAjustePrecoOpenClose(true, item)
  }

  // Cliente entrega
  handleClienteEntregaOpenClose = (open) => {
    this.setState(s => ({
      ...s,
      dialogBuscarClienteEntrega: {
        ...s.dialogBuscarClienteEntrega,
        open,
      },
    }))
  }

  render() {
    const {
      anchorCondicao,
      anchorModalidadeItem,
      itemDelete,
      itemSelected,
      dialogCancelOpen,
      dialogRemoveItem,
      dialogPagamento,
      dialogObras,
      dialogObsNf,
      dialogAjustePrecoItem,
      dialogBuscarClienteEntrega,
    } = this.state
    const {
      classes,
      loading,
      dialogs: { dialogFobMotorista, dialogFobCadMotoristaVeiculo },
      modalidades = [],
      pagamentoForma = {},
      pagamentoFormas = [],
      pagamentoCondicao = {},
      pagamentoCondicoes = [],
      obras = [],
      obsnf = '',
      itens = [],
      finalizarPedido,
      quantidadePopUp,
      onUpdateObsNf,
      onHandleShowDialogs,
      onUpdateQuantItemCarrinho,
      onValidQuantidadeItemCarrinho,
      onHandleSelectQuantidadeItem,
      onHandleUpdateCadMotVei,
      getParametro,
      onUpdateDataEmbarqueItemCarrinho,
      onUpdateDataEmbarqueValidItemCarrinho,
      onUpdateValorItemCarrinho,
      onSearchClienteErpFilter,
      onUpdateClienteEntrega,
    } = this.props
    let totalPedido = 0
    // TODO Parâmetros
    const kDescCarrinho = getParametro('DESC_CARRINHO', 'CAMINHÃO')
    const kQtdCarrinhoFator = getParametro('QTD_CARRINHO_SIMPLES', 0)
    const kConsultaObras = getParametro('CONSULTA_OBRAS', 'NAO')
    const kDataEmbarque = getParametro('DATA_EMBARQUE', 'NAO')
    const kAteraPrecoItem = getParametro('ALTERA_PRECO_ITEM', 'NAO')
    const kUsaPedidoPadrao = getParametro('USA_PEDIDO_PADRAO', 'NAO') === 'SIM'
    return (
      <Fragment>
        <Header />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.root}
        >
          {/* GRID */}
          {itens.length === 0 && finalizarPedido.pedidosOk && (
            <Grid
              container
              spacing={2}
              direction="column"
              alignItems="center"
              style={{ marginTop: 60 }}
            >
              <Grid item>
                <img src={require('../../assets/imagens/caminhao.png')} />
              </Grid>
              <Grid item>
                <Typography variant="h3" gutterBottom>
                  Carga seguindo para análise...
                </Typography>
              </Grid>
            </Grid>
          )}
          {itens.length === 0 && !finalizarPedido.pedidosOk && (
            <Typography variant="h2" gutterBottom style={{ marginTop: 60 }}>
              {`Seu ${kDescCarrinho.toLowerCase()} está vazio.`}
            </Typography>
          )}
          {itens.length > 0 && (
            <Grid item xs={12}>
              {this.cardHeaderActions()}
              <Card className={[classes.card, classes.grid].join(' ')}>
                <CardHeader
                  title={(
                    <div>
                      <ShipIcon />
                      {` ${kDescCarrinho}`}
                    </div>
                  )}
                  // action={this.cardHeaderActions()}
                />
                <CardContent>
                  <Paper className={classes.root}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ textAlign: 'center' }}>Imagem</TableCell>
                          <TableCell>Produto</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            Condição de Pagamento
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>Modalidade</TableCell>
                          {kConsultaObras === 'SIM' && (
                            <TableCell style={{ textAlign: 'center' }}>Obra</TableCell>
                          )}
                          {kDataEmbarque === 'SIM' && (
                            <TableCell align="center">Embarque</TableCell>
                          )}
                          <TableCell style={{ textAlign: 'center' }}>Peso</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>Quantidade</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>Preço</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {itens.map((item) => {
                          const {
                            produtoSku,
                            produtoUM,
                            produtoDesc,
                            produtoPeso,
                            produtoPesoTotal,
                            produtoQuant,
                            produtoValorUnit,
                            produtoValorTotal,
                            produtoCondicao = null,
                          } = item
                          totalPedido += produtoValorTotal
                          const modalidadeDesc = item.isObra
                            ? item.modalidadePreco.toUpperCase()
                            : item.modalidade.descricao
                          const produtoImagem = ImagensGVB[produtoSku.toUpperCase()]
                            || require('../../assets/imagens/produto/NoImage.png')
                          return (
                            <TableRow key={item.produtoSku}>
                              {/* COL: IMAGEM DO PRODUTO */}
                              <TableCell style={{ textAlign: 'center' }}>
                                <img
                                  src={produtoImagem}
                                  style={{ width: 60, height: 80 }}
                                  alt={produtoDesc}
                                />
                              </TableCell>
                              {/* COL: CÓD. E DESCRICAO */}
                              <TableCell component="th" scope="row" style={{ padding: 0 }}>
                                <Typography
                                  variant="body2"
                                  component="strong"
                                  gutterBottom
                                  color="secondary"
                                >
                                  {produtoDesc}
                                </Typography>
                                <br />
                                {'CÓD.: '}
                                <strong>{produtoSku}</strong>
                                <br />
                                {'U.M.: '}
                                <strong>{produtoUM}</strong>
                              </TableCell>
                              {/* COL: CONDIÇÃO DE PGTO */}
                              <TableCell style={{ textAlign: 'center' }}>
                                <Typography variant="body2" gutterBottom>
                                  {pagamentoForma && !pagamentoForma.sku && 'NENHUMA'}
                                  {pagamentoForma && pagamentoForma.sku === 'ANT' && 'ANTECIPADO'}
                                  {pagamentoForma
                                    && pagamentoForma.sku !== 'ANT'
                                    && item.pagamentoCondicao
                                    && item.pagamentoCondicao?.descricao
                                    && item.pagamentoCondicao?.descricao}
                                  {pagamentoForma
                                    && pagamentoForma.sku !== 'ANT'
                                    && !item.pagamentoCondicao
                                    && !item.pagamentoCondicao?.descricao
                                    && produtoCondicao
                                    && pagamentoCondicao.descricao}
                                </Typography>
                                <Button
                                  disabled={
                                    (pagamentoForma && !pagamentoForma.sku)
                                    || (pagamentoForma && pagamentoForma.sku === 'ANT')
                                  }
                                  size="small"
                                  color="primary"
                                  aria-label="Forma/Cond. de pagamento"
                                  onClick={evt => this.handleClickCondicao(evt, item)}
                                >
                                  <LaunchIcon
                                    className={[classes.leftIcon, classes.iconSmall].join(' ')}
                                    style={{ fontSize: 15, padding: 0 }}
                                  />
                                </Button>
                                <Menu
                                  id={`menu-condicao-${produtoSku}`}
                                  anchorEl={anchorCondicao}
                                  open={Boolean(anchorCondicao)}
                                  onClose={this.handleCloseCondicao}
                                >
                                  {pagamentoCondicoes
                                    .filter(pc => pc.pgtoFormaId === pagamentoForma?.id ?? 0)
                                    .map(c => (
                                      <MenuItem
                                        key={c.id}
                                        onClick={() => this.handleSelectCondicao(c)}
                                      >
                                        {c.descricao}
                                      </MenuItem>
                                    ))}
                                </Menu>
                              </TableCell>
                              {/* TODO COL: MODALIDADE DE ENTREGA CIF/FOB */}
                              <TableCell style={{ textAlign: 'center' }}>
                                <Typography variant="body2" gutterBottom>
                                  {/* DADOS DO FOB: Para cadastro que já existe */}
                                  {item.modalidade.sku.toUpperCase() === 'FOB' && item.motorista && (
                                    <Tooltip
                                      title={`${item.motorista.motoristanome} - ${
                                        item.veiculo.veiculoplaca
                                      }, EIXOS: ${item.veiculo.veiculoeixos} ${
                                        item.veiculo.veiculodescricao
                                          ? `(${item.veiculo.veiculodescricao})`
                                          : ''
                                      }`}
                                      placement="top"
                                    >
                                      <Button
                                        size="small"
                                        color="primary"
                                        onClick={(evt, i) => this.handleSelectModalidadeItem(item.modalidade, item)
                                        }
                                      >
                                        FOB
                                        <DirectionsBus style={{ fontSize: 15, padding: 0 }} />
                                      </Button>
                                    </Tooltip>
                                  )}
                                  {/* DADOS DO FOB: Para PRÉ-CADASTRO */}
                                  {item.modalidade.sku.toUpperCase() === 'FOB'
                                    && item.fobMotoristaNome && (
                                      <Tooltip
                                        title={`NOVO: ${item.fobMotoristaNome} - ${item.fobVeiculoPlaca}, VEI: ${item.fobVeiculoModelo}`}
                                        placement="top"
                                      >
                                        <Button
                                          size="small"
                                          color="primary"
                                          onClick={(evt, i) => this.handleSelectModalidadeItem(item.modalidade, item)
                                          }
                                        >
                                          FOB
                                          <DirectionsBus style={{ fontSize: 15, padding: 0 }} />
                                        </Button>
                                      </Tooltip>
                                  )}
                                  {item.modalidade.sku.toUpperCase() === 'FOB'
                                    && !item.fobMotoristaNome
                                    && !item.motorista
                                    && modalidadeDesc}
                                  {/* CIF */}
                                  {item.modalidade.sku.toUpperCase() === 'CIF' && modalidadeDesc}
                                </Typography>
                                <Button
                                  size="small"
                                  color="primary"
                                  aria-owns={
                                    anchorModalidadeItem
                                      ? `menu-modalidade-item-${produtoSku}`
                                      : undefined
                                  }
                                  aria-label="Modalidade"
                                  disabled={item.isObra}
                                  onClick={evt => this.handleClickModalidadeItem(evt, item)}
                                >
                                  <LaunchIcon
                                    className={[classes.leftIcon, classes.iconSmall].join(' ')}
                                    style={{ fontSize: 15, padding: 0 }}
                                  />
                                </Button>
                                <Menu
                                  id={`menu-modalidade-item-${produtoSku}`}
                                  anchorEl={anchorModalidadeItem}
                                  open={Boolean(anchorModalidadeItem)}
                                  onClose={this.handleCloseModalidadeItem}
                                >
                                  {itemSelected
                                    && !itemSelected.isObra
                                    && itemSelected.modalidades.map(m => (
                                      <MenuItem
                                        key={m.id}
                                        onClick={() => this.handleSelectModalidadeItem(m, null)}
                                      >
                                        {m.descricao}
                                      </MenuItem>
                                    ))}
                                </Menu>
                              </TableCell>
                              {/* COL: OBRA */}
                              {kConsultaObras === 'SIM' && (
                                <TableCell style={{ textAlign: 'center' }}>
                                  {item.isObra ? (
                                    <Tooltip
                                      title={`[${Number(item.codigo)}] ${item.obra} - ${item.nome}`}
                                      placement="top"
                                    >
                                      <Typography variant="body2" gutterBottom>
                                        {`[${Number(item.codigo)}] ${item.nome.substr(0, 30)}`}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography variant="body2" gutterBottom>
                                      NENHUMA
                                    </Typography>
                                  )}
                                  <Button
                                    size="small"
                                    color="primary"
                                    aria-label="Obra"
                                    onClick={() => this.openCloseDialogObras(true)}
                                  >
                                    <LaunchIcon
                                      className={[classes.leftIcon, classes.iconSmall].join(' ')}
                                      style={{ fontSize: 15, padding: 0 }}
                                    />
                                  </Button>
                                </TableCell>
                              )}
                              {/* COL: DATA DE EMBARQUE */}
                              {kDataEmbarque === 'SIM' && (
                                <TableCell align="center">
                                  <TextField
                                    error={
                                      item.hasOwnProperty('dataEmbarqueValid')
                                      && !item.dataEmbarqueValid
                                    }
                                    helperText={
                                      item.hasOwnProperty('dataEmbarqueValid')
                                      && !item.dataEmbarqueValid
                                        ? 'Data inválida'
                                        : ''
                                    }
                                    style={{ width: 80 }}
                                    required
                                    fullWidth
                                    InputProps={{
                                      inputComponent: DataMaskCustom,
                                    }}
                                    className={classes.textField}
                                    value={item.dataEmbarque}
                                    onChange={e => onUpdateDataEmbarqueItemCarrinho(item, e.target.value)
                                    }
                                    onBlur={e => onUpdateDataEmbarqueValidItemCarrinho(item, e.target.value)
                                    }
                                    margin="dense"
                                  />
                                </TableCell>
                              )}
                              {/* COL: PESO */}
                              <TableCell style={{ textAlign: 'center', padding: 0 }}>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  style={{ fontSize: 17, fontWeight: '900', color: '#888' }}
                                >
                                  {`${Numeral(produtoPesoTotal / 1000).format('0,0.0')} t `}
                                </Typography>
                                {`${Numeral(produtoPeso).format('0,0.00')} kg`}
                              </TableCell>
                              {/* TODO COL: QUANTIDADE */}
                              <TableCell
                                style={{
                                  textAlign: 'center',
                                  padding: 0,
                                }}
                              >
                                <TextField
                                  type="number"
                                  aria-owns={
                                    quantidadePopUp.anchorQuantidade
                                      ? `menu-quantidade-item-${produtoSku}`
                                      : undefined
                                  }
                                  style={{ width: 80 }}
                                  className={classes.qtdCarrinho}
                                  value={produtoQuant}
                                  pattern="[0-9]*"
                                  onChange={e => onUpdateQuantItemCarrinho(item, e.target.value)}
                                  onBlur={evt => onValidQuantidadeItemCarrinho(
                                    item,
                                    evt.target.value,
                                    evt.currentTarget,
                                    kQtdCarrinhoFator,
                                  )
                                  }
                                  onKeyPress={(e) => {
                                    if (e.charCode === 13) {
                                      onValidQuantidadeItemCarrinho(
                                        item,
                                        e.target.value,
                                        e.currentTarget,
                                        kQtdCarrinhoFator,
                                      )
                                    }
                                  }}
                                  margin="normal"
                                />
                                <Menu
                                  id={`menu-quantidade-item-${produtoSku}`}
                                  anchorEl={quantidadePopUp.anchorQuantidade}
                                  open={Boolean(quantidadePopUp.anchorQuantidade)}
                                >
                                  {quantidadePopUp.quantidades.map(q => (
                                    <MenuItem
                                      key={q.value}
                                      onClick={() => onHandleSelectQuantidadeItem(q.value)}
                                    >
                                      {q.value}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </TableCell>
                              {/* COL: PREÇO */}
                              <TableCell align="right" style={{ textAlign: 'center', padding: 0 }}>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  style={{ fontSize: 17, fontWeight: '900', color: '#888' }}
                                >
                                  {`R$ ${Numeral(produtoValorTotal).format('0,0.00')}`}
                                </Typography>
                                {kAteraPrecoItem === 'SIM' ? (
                                  <Button
                                    onClick={() => this.handleAjustePreco(item)}
                                    size="small"
                                    variant="outlined"
                                  >
                                    {`R$ ${Numeral(produtoValorUnit).format('0,0.00')}`}
                                  </Button>
                                ) : (
                                  `R$ ${Numeral(produtoValorUnit).format('0,0.00')}`
                                )}
                              </TableCell>
                              {/* COL: BTN EXCLUIR ITEM */}
                              <TableCell>
                                <Button
                                  color="secondary"
                                  aria-label="Remover item"
                                  className={classes.button}
                                  onClick={() => this.onDeleteItemCarrinho(item)}
                                >
                                  <DeleteIcon
                                    className={[classes.leftIcon, classes.iconSmall].join(' ')}
                                  />
                                </Button>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                      <TableFooter>
                        <TableRow variant="footer" style={{ backgroundColor: '#FFF' }}>
                          {/* Label: Totais */}
                          <TableCell colSpan={5}>
                            <Typography
                              variant="body2"
                              component="strong"
                              gutterBottom
                              style={{ fontSize: 18, fontWeight: '900', color: '#000' }}
                            >
                              Totais
                            </Typography>
                          </TableCell>
                          {/* Sum: Peso total */}
                          <TableCell style={{ textAlign: 'center', padding: 0 }}>
                            <Typography
                              variant="body2"
                              component="strong"
                              gutterBottom
                              style={{ fontSize: 18, fontWeight: '900', color: '#000' }}
                            >
                              {`${Numeral(
                                itens.reduce((p, o) => p + +o.produtoPesoTotal, 0) / 1000,
                              ).format('0,0.0')} t`}
                            </Typography>
                          </TableCell>
                          {/* Sum: Quantidade */}
                          <TableCell style={{ textAlign: 'center', padding: 0 }}>
                            <Typography
                              variant="body2"
                              component="strong"
                              gutterBottom
                              style={{ fontSize: 18, fontWeight: '900', color: '#000' }}
                            >
                              {itens.reduce((p, o) => p + +o.produtoQuant, 0)}
                            </Typography>
                          </TableCell>
                          {/* Sum: Total pedido */}
                          <TableCell colSpan={2} style={{ textAlign: 'left', padding: 0 }}>
                            <Typography
                              variant="body2"
                              component="strong"
                              gutterBottom
                              style={{ fontSize: 18, fontWeight: '900', color: '#000' }}
                            >
                              {`R$ ${Numeral(totalPedido).format('0,0.00')}`}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </Paper>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
        {/* Dialogs - Forma e Condição de Pagamento */}
        <FormaCondPgtoDialog
          open={dialogPagamento}
          title="Condição de pagamento"
          onClose={this.toggleDiagPagamento}
          onConfirm={this.handleSaveCondicaoPagamento}
          onChange={this.handleChangeField}
          data={{
            pagamentoForma,
            pagamentoFormas,
            pagamentoCondicao,
            pagamentoCondicoes:
              (pagamentoForma?.sku ?? '') === 'ANT'
                ? []
                : pagamentoCondicoes.filter(pc => pc.pgtoFormaId === pagamentoForma?.id ?? 0),
          }}
        />
        {/* Dialogs - Obras do cliente */}
        <ClienteObraDialog
          open={dialogObras}
          loading={loading.loadObras}
          title="Condição de pagamento"
          onClose={() => this.openCloseDialogObras(false)}
          onSelectPrecoObra={this.onSelectPrecoObra}
          data={{ obras }}
        />
        {/* Dialog - Observação da nota fiscal */}
        <Dialog
          size="md"
          fullWidth
          open={dialogObsNf}
          onClose={this.toggleDiagObsNf}
          aria-labelledby="dialog-obsnf-title"
          aria-describedby="dialog-obsnf-content"
        >
          <DialogTitle id="dialog-obsnf-title">Observação da nota fiscal:</DialogTitle>
          <DialogContent>
            <TextField
              id="dialog-obsnf"
              label="Informe a observação:"
              multiline
              fullWidth
              rowsMax="10"
              value={obsnf}
              onChange={evt => onUpdateObsNf(evt.target.value)}
              className={classes.textField}
              margin="normal"
              inputProps={{
                maxlength: 254,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.toggleDiagObsNf()} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.toggleDiagObsNf} color="primary" autoFocus>
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialogs - Remover item do "carrinho" */}
        {itemDelete && (
          <Dialog
            open={dialogRemoveItem}
            onClose={this.toggleDiagRemoveItem}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>Remover item do carrinho:</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`Você confirma a remoção do item ${itemDelete.produtoDesc}?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleDiagRemoveItem} color="primary">
                Não
              </Button>
              <Button
                onClick={() => this.onConfirmeDeleteItemCarrinho(itemDelete)}
                color="primary"
                autoFocus
              >
                Sim
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {/* Dialogs - Confirmação do cancelamento do pedido */}
        <Dialog
          open={dialogCancelOpen}
          onClose={this.toggleDiagCancelPedido}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Cancelar pedido:</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Você confirma o cancelamento do pedido em andamento?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleDiagCancelPedido} color="primary">
              Não
            </Button>
            <Button onClick={this.onCancelarPedido} color="primary" autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialogs - Finalizar pedido */}
        <Dialog
          open={finalizarPedido.dialog}
          onClose={this.toggleDiagFinalizarPedido}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          // size={finalizarPedido?.size || 'sm'}
        >
          <DialogTitle id="alert-dialog-title">{finalizarPedido.title}</DialogTitle>
          <DialogContent>
            {this.finalizarDialogContent({ ...finalizarPedido, kUsaPedidoPadrao })}
          </DialogContent>
          {this.finalizarDialogActionContent(finalizarPedido)}
        </Dialog>
        {/* Dialogs - FOB, Selecionar Motorista vs Veiculo */}
        <Dialog
          open={dialogFobMotorista.open}
          onClose={this.toggleDiagFinalizarPedido}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {loading.loadMotoristaVeiculos && (
            <Fragment>
              <DialogTitle id="alert-dialog-title">Buscando motoristas/veículos</DialogTitle>
              <DialogContent>
                <div className={classes.loadingMotoristas}>
                  <CircularProgress size={18} />
                  {' Aguarde...'}
                </div>
              </DialogContent>
            </Fragment>
          )}
          {!loading.loadMotoristaVeiculos
            && (dialogFobMotorista.motoristas.length === 0
              || dialogFobMotorista.veiculos.length === 0) && (
              <Fragment>
                <DialogTitle id="alert-dialog-cadMotoristaVeiculo-title">
                  Novo cadastro:
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-cadMotoristaVeiculo-description">
                    {`Cadastro de Motorista x Veículo está incompleto,
                        você pode enviar um cadastro para ser aprovado.`}
                  </DialogContentText>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        id="fobVeiculoModelo"
                        label="Modelo do veículo:"
                        className={classes.textField}
                        value={dialogFobCadMotoristaVeiculo.fobVeiculoModelo}
                        onChange={evt => onHandleUpdateCadMotVei({
                          fobVeiculoModelo: evt.target.value.toUpperCase(),
                        })
                        }
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        id="fobVeiculoMarca"
                        label="Marca do veículo:"
                        className={classes.textField}
                        value={dialogFobCadMotoristaVeiculo.fobVeiculoMarca}
                        onChange={evt => onHandleUpdateCadMotVei({
                          fobVeiculoMarca: evt.target.value.toUpperCase(),
                        })
                        }
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        InputProps={{
                          inputComponent: PlacaMaskCustom,
                        }}
                        id="fobVeiculoPlaca"
                        label="Placa do veículo:"
                        className={classes.textField}
                        value={dialogFobCadMotoristaVeiculo.fobVeiculoPlaca}
                        onChange={evt => onHandleUpdateCadMotVei({
                          fobVeiculoPlaca: evt.target.value.toUpperCase(),
                        })
                        }
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        InputProps={{
                          inputComponent: PlacaMaskCustom,
                        }}
                        helperText="(opcional)"
                        id="fobVeiculoPlacaCarreta"
                        label="Placa da carreta:"
                        className={classes.textField}
                        value={dialogFobCadMotoristaVeiculo.fobVeiculoPlacaCarreta}
                        onChange={evt => onHandleUpdateCadMotVei({
                          fobVeiculoPlacaCarreta: evt.target.value.toUpperCase(),
                        })
                        }
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        id="fobMotoristaNome"
                        label="Nome do Motorista:"
                        className={classes.textField}
                        value={dialogFobCadMotoristaVeiculo.fobMotoristaNome}
                        onChange={evt => onHandleUpdateCadMotVei({
                          fobMotoristaNome: evt.target.value.toUpperCase(),
                        })
                        }
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        InputProps={{
                          inputComponent: CPFMaskCustom,
                        }}
                        id="fobMotoristaCpf"
                        label="CPF do Motorista:"
                        className={classes.textField}
                        value={dialogFobCadMotoristaVeiculo.fobMotoristaCpf}
                        onChange={evt => onHandleUpdateCadMotVei({
                          fobMotoristaCpf: evt.target.value.toUpperCase(),
                        })
                        }
                        onBlur={() => {
                          if (!validarCPF(dialogFobCadMotoristaVeiculo.fobMotoristaCpf)) {
                            NotificationManager.error(
                              'O CPF do motorista está inválido!',
                              'Atenção:',
                              9000,
                            )
                          }
                        }}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        id="fobEmpresaNome"
                        label="Nome da empresa:"
                        className={classes.textField}
                        value={dialogFobCadMotoristaVeiculo.fobEmpresaNome}
                        onChange={evt => onHandleUpdateCadMotVei({
                          fobEmpresaNome: evt.target.value.toUpperCase(),
                        })
                        }
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        id="fobCompradorNome"
                        label="Nome do comprador:"
                        className={classes.textField}
                        value={dialogFobCadMotoristaVeiculo.fobCompradorNome}
                        onChange={evt => onHandleUpdateCadMotVei({
                          fobCompradorNome: evt.target.value.toUpperCase(),
                        })
                        }
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleLimparPreCadastro} color="secondary">
                    LIMPAR PRÉ-CADASTRO
                  </Button>
                  <Button
                    onClick={() => {
                      onHandleShowDialogs({
                        dialogFobMotorista: {
                          ...dialogFobMotorista,
                          open: false,
                        },
                      })
                      this.handleCleanItemSelected()
                      this.setState(s => ({ preCadastroMotVei: false }))
                    }}
                  >
                    CANCELAR
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={this.handleSaveFobCadMotoristaVeiculo}
                    color="primary"
                  >
                    SALVAR
                  </Button>
                </DialogActions>
              </Fragment>
          )}
          {!loading.loadMotoristaVeiculos
            && dialogFobMotorista.motoristas.length > 0 && dialogFobMotorista.veiculos.length > 0 && (
              <Fragment>
                <DialogTitle id="alert-dialog-title">Selecione:</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {`Você deve selecionar o motorista juntamente com o veículo que deve ir buscar o produto "${(itemSelected
                      && itemSelected.produtoDesc)
                      || ''}".`}
                  </DialogContentText>
                  <Grid container spacing={2} className={classes.formContent}>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="dialog-fob-motorista">
                          Motorista
                        </InputLabel>
                        <Select
                          fullWidth
                          value={
                            dialogFobMotorista.motorista
                              ? dialogFobMotorista.motorista.motoristaid
                              : ''
                          }
                          onChange={e => onHandleShowDialogs({
                            dialogFobMotorista: {
                              ...dialogFobMotorista,
                              motorista: dialogFobMotorista.motoristas.find(
                                m => m.motoristaid === e.target.value,
                              ),
                            },
                          })
                          }
                          inputProps={{
                            name: 'dialog-fob-motorista',
                            id: 'dialog-fob-motorista',
                          }}
                        >
                          <MenuItem value="">NENHUM</MenuItem>
                          {dialogFobMotorista.motoristas.map(m => (
                            <MenuItem key={m.motoristaid} value={m.motoristaid}>
                              {m.motoristanome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        className={classes.formControl}
                        disabled={
                          dialogFobMotorista.motorista
                            ? !dialogFobMotorista.motorista.motoristaid
                            : true
                        }
                      >
                        <InputLabel shrink htmlFor="dialog-fob-veiculo">
                          Veículo
                        </InputLabel>
                        <Select
                          fullWidth
                          value={
                            dialogFobMotorista.veiculo
                              ? dialogFobMotorista.veiculo.veiculoplaca
                              : ''
                          }
                          onChange={e => onHandleShowDialogs({
                            dialogFobMotorista: {
                              ...dialogFobMotorista,
                              veiculo: dialogFobMotorista.veiculos.find(
                                v => v.veiculoplaca === e.target.value,
                              ),
                            },
                          })
                          }
                          inputProps={{
                            name: 'dialog-fob-veiculo',
                            id: 'dialog-fob-veiculo',
                          }}
                        >
                          <MenuItem value="">NENHUM</MenuItem>
                          {dialogFobMotorista.veiculos.map(vf => (
                            <MenuItem key={vf.veiculoplaca} value={vf.veiculoplaca}>
                              {`${vf.veiculoplaca}, EIXOS: ${vf.veiculoeixos} ${
                                vf.veiculodescricao ? `(${vf.veiculodescricao})` : ''
                              }`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button onClick={() => this.handlePreCadastro()}>INCLUIR PRÉ-CADASTRO</Button>
                    <Button
                      onClick={() => onHandleShowDialogs({
                        dialogFobMotorista: {
                          ...dialogFobMotorista,
                          open: false,
                        },
                      })
                      }
                      color="secondary"
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={
                        (!dialogFobMotorista.motorista && dialogFobMotorista.veiculo)
                        || (dialogFobMotorista.motorista && !dialogFobMotorista.veiculo)
                      }
                      onClick={() => this.handleConfirmeFobMotoristaVeiculo()}
                    >
                      OK
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Fragment>
          )}
        </Dialog>
        {/* Dialog troca de preço */}
        <Dialog
          size="md"
          open={dialogAjustePrecoItem.open}
          onClose={() => this.handleAjustePrecoOpenClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>Ajuste de preço</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item>
                <Typography variant="body2">
                  {`Produto: ${dialogAjustePrecoItem.itemSeleted.produtoDesc}.`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="dialog-ajuste-preco-item"
                  label="Novo preço:"
                  type="number"
                  fullWidth
                  value={
                    dialogAjustePrecoItem.itemSeleted.produtoValorUnit === 0
                      ? ''
                      : dialogAjustePrecoItem.itemSeleted.produtoValorUnit
                  }
                  onChange={evt => this.handleOnChangePreco(evt.target.value)}
                  className={classes.textField}
                  margin="normal"
                  onKeyPress={(e) => {
                    if (
                      dialogAjustePrecoItem.itemSeleted.produtoValorUnit > 0
                      && e.charCode === 13
                    ) {
                      onUpdateValorItemCarrinho(dialogAjustePrecoItem.itemSeleted)
                      this.handleAjustePrecoOpenClose(false)
                    }
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleAjustePrecoOpenClose(false)} color="secondary">
              Fechar
            </Button>
            <Button
              disabled={dialogAjustePrecoItem.itemSeleted.produtoValorUnit <= 0}
              onClick={() => {
                onUpdateValorItemCarrinho(dialogAjustePrecoItem.itemSeleted)
                this.handleAjustePrecoOpenClose(false)
              }}
              color="primary"
              autoFocus
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog busca de clientes entrega */}
        <Dialog
          size="md"
          fullWidth
          open={dialogBuscarClienteEntrega.open}
          onClose={() => this.handleClienteEntregaOpenClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>Cliente entrega</DialogTitle>
          <DialogContent>
            <BuscaGenericaComp
              rowKey="codigo"
              rowValue="nome"
              subRowValue="cgc"
              placeholder="Buscar clientes"
              onSelectItem={(cliente) => {
                onUpdateClienteEntrega(cliente)
                this.handleClienteEntregaOpenClose(false)
              }}
              onSearchHandle={ipt => onSearchClienteErpFilter({ search: ipt, clienteEntrega: 'S' })}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  cliente: state.usuario.usuario.cliente,
  itens: state.carrinho.itens,
  modalidade: state.carrinho.modalidade,
  modalidades: state.carrinho.modalidades,
  pagamentoLoad: state.carrinho.pagamentoLoad,
  pagamentoForma: state.carrinho.pagamentoForma,
  pagamentoFormas: state.carrinho.pagamentoFormas,
  pagamentoCondicao: state.carrinho.pagamentoCondicao,
  pagamentoCondicoes: state.carrinho.pagamentoCondicoes,
  fobCadMotoristaVeiculo: state.carrinho.fobCadMotoristaVeiculo,
  obra: state.carrinho.obra,
  obras: state.carrinho.obras,
  obsnf: state.carrinho.obsnf,
  finalizarPedido: state.carrinho.finalizarPedido,
  quantidadePopUp: state.carrinho.quantidadePopUp,
  dialogs: state.carrinho.dialogs,
  loading: state.carrinho.loading,
  clienteEntrega: state.carrinho.clienteEntrega,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...UsuarioActions,
    ...CarrinhoActions,
    ...ConfigActions,
  },
  dispatch,
)

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CarrinhoPage))
