import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
    Grid,
    Icon,
    TextField,
    InputAdornment,
    IconButton,
    CssBaseline,
    Typography,
    CircularProgress,
    Button,
    NativeSelect,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Paper,
} from '@material-ui/core'

import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import TableDataComp from '../../../../components/Table'
import useStyles from './styles'
import { getVisitasFilterApi } from 'api/visitas'
import { DialogContentSimples } from 'components/Dialogs/Contents'
import GestorHeaderComp from 'components/Gestor/Header'
import GestorDrawerComp from 'components/Gestor/Drawer'
import { Autocomplete, Pagination } from '@material-ui/lab';
import { addSubBairroAPI, filterSubBairroApi, listSubBairrosByBairroId, vincularAgenteSubbairroApi } from 'api/localizacao/sub_bairro'
import { estadoAllApi, estadoApi } from 'api/localizacao/estado'
import { addBairroAPI, listBairrosByMunicipioIdApi } from 'api/localizacao/bairro'
import { NotificationManager } from 'react-notifications'
import { filterCrmAgentesApi } from 'api/crm/agentes'
import DialogAddVinculoAgentesSubBairro from './components/DialogAddVinculoAgentesSubBairro'
import { listMunicipiosByEstadoIdApi } from 'api/localizacao/municipio'
const SubBairrosListContent = (props) => {
    const {
        usuario, push, onDialogOpen, onDialogClose
    } = props
    const classes = useStyles()

    // State
    const [listFilter, setListFilter] = useState([])
    const [filter, setFilter] = useState({
        page: 0,
        rows: 15,
        search: '',
    });
    //Localization 
    const [estados, setEstados] = useState(null);
    const [selectedMunicipality, setSelectedMunicipality] = useState(null);
    const [selectedAgente, setSelectedAgente] = useState(null);
    //Localization Set
    // Função para manipular a seleção do estado
    const handleStateChange = async (event, value) => {

        setSelectedMunicipality(null);
        setSelectedBairro(null);

        if (value?.id) {
            // Carregar os municípios com base no estado selecionado
            setSelectedState(value);
            await loadMunicipios(value.id);
        } else {
            setSelectedState(null)
            setMunicipios([]);
            setBairros([]);
            setSubBairros([]);
        }
    };
    // Função para carregar municípios com base no estado selecionado
    const loadMunicipios = (stateId) => {
        // Chamar a função listMunicipiosByEstadoIdApi passando o estadoId
        listMunicipiosByEstadoIdApi(usuario?.auth?.token ?? '', stateId)
            .then((response) => {
                // Definir os municípios carregados no estado local
                setMunicipios(response);
            })
            .catch((error) => {
                console.error('Erro ao carregar os municípios:', error);
            });
    };

    // Função para manipular a seleção do município
    const handleMunicipalityChange = (event, value) => {
        setSelectedMunicipality(value);
        setSelectedBairro(null);

        if (value) {
            // Carregar os bairros com base no município selecionado
            loadBairros(value.id);
        } else {
            setSelectedMunicipality(null)
            setBairros([]);
            setSubBairros([]);
        }
    };
    // Função para carregar bairros com base no município selecionado
    const loadBairros = (municipioId) => {
        // Chamar a função listBairrosByMunicipioIdApi passando o municipioId
        listBairrosByMunicipioIdApi(usuario?.auth?.token ?? '', municipioId)
            .then((response) => {
                // Definir os bairros carregados no estado local
                setBairros(response);
            })
            .catch((error) => {
                console.error('Erro ao carregar os bairros:', error);
            });
    };

    // Função para manipular a seleção do bairro
    const handleBairroChange = (event, value) => {
        setSelectedBairro(value);

        if (value) {
            // Carregar os subbairros com base no bairro selecionado
            loadSubBairros(value.id);
        } else {
            setSelectedBairro(null)
            setSubBairros([]);
        }
    };
    // Função para carregar subbairros com base no bairro selecionado
    const loadSubBairros = (bairroId) => {
        // Chamar a função listSubBairrosByBairroId passando o bairroId
        listSubBairrosByBairroId(usuario?.auth?.token ?? '', bairroId)
            .then((response) => {
                // Definir os subbairros carregados no estado local
                setSubBairros(response);
            })
            .catch((error) => {
                console.error('Erro ao carregar os subbairros:', error);
            });
    };
    // Função para manipular a seleção do subbairro

    const handleSubBairroChange = (event, value) => {
        setSelectedSubBairro(value);
    };


    const handleAgenteChange = (event, value) => {
        setSelectedAgente(value);
    };

    // Estado local para armazenar os municípios carregados
    const [municipios, setMunicipios] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedBairro, setSelectedBairro] = useState(null);
    const [selectedSubBairro, setSelectedSubBairro] = useState(null);

    // Estado local para armazenar os bairros carregados
    const [bairros, setBairros] = useState([]);

    // Estado local para armazenar os subbairros carregados
    const [subBairros, setSubBairros] = useState([]);


    // Load Agentes
    const [loadingAgente, setLoadingAgente] = useState(true);
    // Loading
    const [loadingEstado, setLoadingEstado] = useState(false);
    const [loadingMunicipios, setLoadingMunicipios] = useState(false);
    const [loadingBairros, setLoadingBairros] = useState(false);
    const [loadingSubBairros, setLoadingSubBairros] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [payload, setPayload] = useState([]);
    const [loading, setLoading] = useState(false);
    const [listAgente, setListAgente] = useState([]);
    const onSearchSubBairros = (page = 1) => {
        setLoading(true);
        const updatedFilter = {
            ...filter,
            page: currentPage - 1,
            rows: 15,
            estado: selectedState?.id ?? '',
            municipio: selectedMunicipality?.id ?? '',
            bairro: selectedBairro?.id ?? '',
            subbairro: selectedSubBairro?.id ?? ''
        };
        if (page == 1 && currentPage != page) {
            setCurrentPage(page);
        } else {
            filterSubBairroApi({ token: usuario?.auth?.token ?? '', query: updatedFilter })
                .then((resp) => {
                    setPayload(resp);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleFilterClick = (event) => {
        setLoading(true);
        const query = {
            ...filter,
            estado: selectedState?.id ?? '',
            municipio: selectedMunicipality?.id ?? '',
            bairro: selectedBairro?.id ?? '',
            subbairro: selectedSubBairro?.id ?? '',
            crmAgenteId: selectedAgente?.id ?? ''
        };
        filterSubBairroApi({ token: usuario?.auth?.token ?? '', query: query })
            .then((resp) => {
                setPayload(resp);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };


    useEffect(() => {
        onSearchSubBairros(currentPage);
    }, [currentPage]);
    const tableCols = [
        { key: 'nome', title: 'SUBBAIRRO' },
        { key: 'bairroNome', title: 'BAIRRO' },
    ]

    const tableActionsList = [
        {
            key: "vincularAgentes",
            title: "Vincular Agentes",
            icon: "list",
            action: row => vincularAgente(row),
        },
    ];
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [rowData, setRowData] = useState(null);

    const vincularAgente = (row) => {
        setRowData(row);
        setDialogOpen(true);
    };
    const onIptSearchChange = (evt) => {
        evt.persist();
        setFilter(s => ({ ...s, search: evt?.target?.value?.toUpperCase() ?? '' }))
    }
    // Style
    const stylesForm = {
        marginTop: '2rem',
        marginBottom: '1rem',
        display: 'flex',
        gap: '1rem',
        width: '100%',
    };
    const addBairro = () => {
        // Certifique-se de ter acesso aos valores corretos para municipioId, estadoId e bairroNome
        const bairroInput = document.getElementById('bairro-combo-box');
        const bairroValue = bairroInput.value;
        const municipioId = selectedMunicipality ? selectedMunicipality.id : null;
        if (municipioId && bairroValue) {
            addBairroAPI({
                token: usuario.auth.token,
                municipioId: municipioId,
                bairroNome: bairroValue,
            })
                .then((resp) => {
                    // Trate a resposta da API conforme necessário
                    if (selectedBairro !== resp) {
                        setSelectedBairro(resp);
                        NotificationManager.success('Bairro adicionado com sucesso!', 'Sucesso!', 4000);
                    }
                    else {
                        NotificationManager.success('Bairro selecionado com sucesso!', 'Sucesso!', 4000);
                    }
                })
                .catch((error) => {
                    // Trate o erro da API conforme necessário
                    console.error('Erro ao adicionar o bairro:', error);
                });
        } else {
            console.error('Valores insuficientes para adicionar o bairro');
        }
    };
    const addSubbairro = () => {
        // Certifique-se de ter acesso aos valores corretos para municipioId, estadoId e bairroNome
        const subBairroInput = document.getElementById('subbairro-combo-box');
        const subBairroValue = subBairroInput.value;
        const bairroId = selectedBairro ? selectedBairro.id : null;
        if (bairroId && subBairroValue) {
            addSubBairroAPI({
                token: usuario.auth.token,
                bairroId: bairroId,
                subBairroNome: subBairroValue,
            })
                .then((resp) => {
                    // Trate a resposta da API conforme necessário
                    if (selectedSubBairro !== resp) {
                        setSelectedSubBairro(resp);
                        NotificationManager.success('SubBairro adicionado com sucesso!', 'Sucesso!', 4000);
                    } else {
                        NotificationManager.success('SubBairro selecionado com sucesso!', 'Sucesso!', 4000);
                    }
                })

                .catch((error) => {
                    // Trate o erro da API conforme necessário
                    console.error('Erro ao adicionar o SubBairro:', error);
                });
        } else {
            console.error('Valores insuficientes para adicionar o SubBairro');
        }
    };
    // Função para carregar todos os estados
    const loadEstados = () => {
        // Chamar a função estadoAllApi para carregar os estados
        estadoAllApi(usuario?.auth?.token ?? '')
            .then((response) => {
                // Definir os estados carregados no estado local
                setEstados(response);
            })
            .catch((error) => {
                console.error('Erro ao carregar os estados:', error);
            });
    };

    const OnLoadAgentes = () => {
        setLoadingAgente(true)
        filterCrmAgentesApi({ token: usuario?.auth?.token ?? '', query: { agenteTypeChar: "S" } }).then((resp) => {
            setListAgente(resp.data)
            setLoadingAgente(false)
        }).catch((error) => {
            console.error(error);
            setLoadingAgente(false) // Define o estado como false em caso de erro na solicitação
        });

    };
    const handleCloseDialog = () => {
        // Logic for closing the dialog
        setDialogOpen(false);

    };

    useEffect(() => {
        document.title = 'Online - Bairros'
        document.body.style = 'background-image: none;'
        loadEstados();
        OnLoadAgentes();
        onSearchSubBairros()
    }, [])
    return (
        <div className={classes.root}>
            <CssBaseline />
            <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
            <GestorDrawerComp {...props} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <Typography variant="h3" component="h2">
                                            Bairro e Sub-bairro
                                        </Typography>
                                    </Grid>
                                    {/* Menus */}
                                    <Grid item xs={12} md={12}>
                                        <Grid>
                                            <form style={stylesForm}>
                                                <Grid item xs={6} md={3}>
                                                    {loadingAgente ? (
                                                        <CircularProgress /> // Indicador de carregamento com spinner
                                                    ) : (
                                                        listAgente && (
                                                            <Autocomplete
                                                                id="agentes-combo-box"
                                                                options={listAgente}
                                                                value={selectedAgente}
                                                                onChange={handleAgenteChange}
                                                                getOptionLabel={(option) => option.name}
                                                                renderInput={(params) => <TextField {...params} label="Agente" variant="outlined" />}
                                                            />
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    {loadingEstado ? (
                                                        <CircularProgress /> // Indicador de carregamento com spinner
                                                    ) : (
                                                        estados && (
                                                            <Autocomplete
                                                                id="states-combo-box"
                                                                options={estados}
                                                                getOptionLabel={(option) => option.nome}
                                                                value={selectedState}
                                                                onChange={handleStateChange}
                                                                renderInput={(params) => <TextField {...params} label="Estado" variant="outlined" />}
                                                            />
                                                        )
                                                    )}
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <Autocomplete
                                                        id="municipalities-combo-box"
                                                        options={municipios}
                                                        getOptionLabel={(option) => option.nome}
                                                        value={selectedMunicipality}
                                                        onChange={handleMunicipalityChange}
                                                        renderInput={(params) => <TextField {...params} label="Município" variant="outlined" />}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <Autocomplete
                                                        id="bairro-combo-box"
                                                        options={bairros}
                                                        getOptionLabel={(option) => option.nome}
                                                        value={selectedBairro}
                                                        onChange={handleBairroChange}
                                                        renderInput={(params) => <TextField {...params} label="Bairro" variant="outlined" />}
                                                        renderOption={(option) => option.nome}
                                                        noOptionsText={
                                                            selectedMunicipality && selectedState ? (
                                                                <>
                                                                    Nenhum bairro encontrado
                                                                    <Button
                                                                        color="primary"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onMouseDown={(event) => {
                                                                            event.preventDefault(); // Evita o fechamento da lista de opções
                                                                            addBairro();
                                                                        }}
                                                                    >
                                                                        Adicionar
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                'Nenhum bairro encontrado'
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <Autocomplete
                                                        id="subbairro-combo-box"
                                                        options={subBairros}
                                                        getOptionLabel={(option) => option.nome}
                                                        value={selectedSubBairro}
                                                        onChange={handleSubBairroChange}
                                                        renderInput={(params) => <TextField {...params} label="Sub Bairro" variant="outlined" />}
                                                        renderOption={(option) => option.nome}
                                                        noOptionsText={
                                                            selectedMunicipality && selectedState && selectedBairro ? (
                                                                <>
                                                                    Nenhum subbairro encontrado
                                                                    <Button
                                                                        color="primary"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onMouseDown={(event) => {
                                                                            event.preventDefault(); // Evita o fechamento da lista de opções
                                                                            addSubbairro();
                                                                        }}
                                                                    >
                                                                        Adicionar
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                'Nenhum subbairro encontrado'
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                            </form>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <TextField
                                                id="outlined-adornment-password"
                                                className={classNames(classes.margin, classes.textField)}
                                                variant="outlined"
                                                label="Pesquisar"
                                                value={filter?.search ?? ''}
                                                onChange={onIptSearchChange}
                                                onKeyPress={(evt) => {
                                                    if (evt.charCode === 13) {
                                                        onSearchSubBairros();
                                                    }
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="Pesquisar"
                                                                onClick={onSearchSubBairros}
                                                            >
                                                                <Icon>search</Icon>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" color="primary" onClick={handleFilterClick} className={classes.button}>
                                                Filtrar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    {/* Body */}
                    {/* Body */}
                    <Grid item xs={12} md={12}>
                        {loading ? (
                            <div className={classNames(classes.loadingContainer, classes.centered)}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <TableDataComp size="small" loading={false} rows={payload.data} cols={tableCols} actions={tableActionsList} />
                        )}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div id="mui-pagination">
                            <Pagination
                                count={payload?.total ?? 0}
                                page={currentPage} // Add 1 to match the paginator index
                                onChange={handlePageChange}
                                color="primary"
                                className={classes.pagination}
                            />
                        </div>
                    </Grid>
                </Grid>
                <DialogAddVinculoAgentesSubBairro
                    open={isDialogOpen}
                    // onSave={handleSaveVinculo}
                    onClose={handleCloseDialog}
                    listAgente={listAgente}
                    rowData={rowData}
                    usuario={usuario}
                    onSearchSubBairros={onSearchSubBairros}
                />
            </main>
        </div>
    )
}

SubBairrosListContent.propTypes = {
    push: PropTypes.func.isRequired,
    onDialogOpen: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
    gestorUsuarios: state.gestorUsuarios,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        push: crrPush,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SubBairrosListContent)
