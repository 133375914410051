import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  root: {
    marginTop: 100,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  card: {
    minWidth: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

export default styles
