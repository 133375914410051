import { API_URL } from '..'
import { httpParseJson, httpValidState, httpValidErros } from '../../utils/http'

const ref = '/eventos'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/*--------------
  Http Request
--------------*/
/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} search - Valor usado para pesquisar dados
 */
export const addEventoApi = (dto) => {
  const { token, evento } = dto
  return fetch(`${API_URL}${ref}`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(evento),
  })
    .then(httpParseJson)
    .then(res => httpValidState(res, true))
    .catch(err => httpValidErros(err, true))
}
