import _ from 'lodash'
import { getProdutosApi } from '../../api/produtos'

// State Produto
const initialState = {
  buscando: false,
  busca: '',
  buscaDados: [],
  produtos: [],
  categorias: [],
  maisVendidos: [],
  produtoQuant: [],
}

// Actions Types
export const Types = {
  RESET_PRODUTO: 'produto/RESET_PRODUTO',
  LOAD_PRODUTOS: 'produto/LOAD_PRODUTOS',
  LOAD_CATEGORIAS: 'produto/LOAD_CATEGORIAS',
  BUSCANDO: 'produto/BUSCANDO',
  BUSCA_PRODUTO: 'produto/BUSCA',
  RESET_BUSCA_DADOS: 'produto/RESET_BUSCA_DADOS',
  BUSCA_DADOS: 'produto/BUSCA_DADOS',
  UPDATE_PRODUTO_QUANT: 'produto/UPDATE_PRODUTO_QUANT',
}

// Reducers
export default function produtoReducers(state = initialState, action) {
  switch (action.type) {
    case Types.RESET_PRODUTO:
      return { ...state, ...initialState }
    case Types.LOAD_PRODUTOS:
      return { ...state, produtos: action.payload }
    case Types.LOAD_CATEGORIAS:
      return { ...state, categorias: action.payload }
    case Types.BUSCANDO:
      return { ...state, buscando: !state.buscando }
    case Types.BUSCA_PRODUTO:
      return { ...state, busca: action.payload }
    case Types.RESET_BUSCA_DADOS:
      return { ...state, buscaDados: initialState.buscaDados }
    case Types.BUSCA_DADOS:
      return { ...state, buscaDados: action.payload }
    case Types.UPDATE_PRODUTO_QUANT:
      return {
        ...state,
        buscaDados: state.buscaDados.map(p => (p.produtoId === action.payload.produtoId
          ? action.payload
          : p)),
      }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  onResetProduto: () => ({ type: Types.RESET_PRODUTO }),
  onResetBusca: () => ({ type: Types.RESET_BUSCA_DADOS }),
  onLoadProdutos: field => (dispatch) => {
    dispatch({ type: Types.LOAD_PRODUTOS, payload: field })
  },
  onLoadCategorias: field => (dispatch) => {
    dispatch({ type: Types.LOAD_CATEGORIAS, payload: field || initialState.categorias })
  },
  onBuscando: () => (dispatch) => {
    dispatch({ type: Types.BUSCANDO })
  },
  onProdutoBuscaField: busca => (dispatch) => {
    dispatch({ type: Types.BUSCA_PRODUTO, payload: busca })
  },
  onLoadBuscaDados: field => (dispatch) => {
    dispatch({ type: Types.BUSCA_DADOS, payload: field })
  },
  onUpdateProdutoQuant: (data, produtoQuant) => (dispatch) => {
    dispatch({
      type: Types.UPDATE_PRODUTO_QUANT,
      payload: { ...data, produtoQuant: +produtoQuant },
    })
  },
  onLoadProdutosCategoria: (cliente = null) => (dispatch, getState) => {
    const {
      usuario: { usuario },
    } = getState()
    const query = {
      ...usuario.auth,
      busca: '',
      categoriaId: '',
      clienteId: cliente?.id || usuario.cliente.id,
      filialId: cliente?.unidades[0]?.id || usuario.unidade.id,
    }
    getProdutosApi(query).then((payload) => {
      if (payload && _.isArray(payload)) {
        dispatch(Actions.onLoadBuscaDados(payload))
        const aCategorias = []
        payload.forEach((p) => {
          if (_.isArray(p.categorias) && !_.isEmpty(p.categorias)) {
            p.categorias.forEach((c) => {
              if (!aCategorias.find(i => i.id === c.id)) {
                aCategorias.push(c)
              }
            })
          }
        })
        dispatch(Actions.onLoadCategorias(aCategorias))
      }
    })
  },
}
