import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  buttonDark: {
    backgroundColor: theme.palette.primary.light,
    color: theme.semar.text.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  title: {
    fontSize: 18,
    margin: 0,
    padding: 0,
  },
  dialogRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
  tableRow: {
    cursor: 'pointer',
  },
}))

export default styles
