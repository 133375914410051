import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Paper, TextField, Grid,
} from '@material-ui/core'
import { Save } from '@material-ui/icons'

import ButtonLoadingComp from 'components/Buttons/ButtonLoading'
import useStyles from './styles'

const DadosProspectTabContent = (props) => {
  const {
    loading,
    payload,
    onSave,
  } = props
  const [form, setForm] = useState(payload)

  const onUpdateField = (data) => {
    setForm(s => ({ ...s, ...data }))
  }

  const classes = useStyles()
  return (
    <Paper className={classes.paper} elevation={1}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={1}>
          <TextField
            fullWidth
            size="small"
            id="codigo"
            label="Código:"
            margin="normal"
            variant="outlined"
            value={(form?.codigo ?? '').toUpperCase()}
            inputProps={{
              maxLength: 10,
            }}
            className={classes.textField}
            onChange={evt => onUpdateField({ codigo: evt.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            fullWidth
            size="small"
            id="loja"
            label="Loja:"
            margin="normal"
            variant="outlined"
            value={(form?.loja ?? '').toUpperCase()}
            inputProps={{
              maxLength: 3,
            }}
            className={classes.textField}
            onChange={evt => onUpdateField({ loja: evt.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            size="small"
            id="razao"
            label="Nome:"
            margin="normal"
            variant="outlined"
            value={(form?.nome ?? '').toUpperCase()}
            className={classes.textField}
            onChange={evt => onUpdateField({ nome: evt.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="small"
            id="cgc"
            label="CPF/CNPJ:"
            margin="normal"
            inputProps={{
              maxlength: 14,
            }}
            variant="outlined"
            value={(form?.cgc ?? '').toUpperCase()}
            className={classes.textField}
            onChange={evt => onUpdateField({ cgc: evt.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            id="email"
            label="E-mail"
            margin="normal"
            variant="outlined"
            value={(form?.email ?? '').toUpperCase()}
            className={classes.textField}
            onChange={evt => onUpdateField({ email: evt.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="small"
            id="telefone"
            label="Telefone"
            margin="normal"
            variant="outlined"
            value={(form?.telefone ?? '').toUpperCase()}
            className={classes.textField}
            onChange={evt => onUpdateField({ telefone: evt.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            size="small"
            disabled
            id="crmProspectStatusDescription"
            label="Status"
            margin="normal"
            variant="outlined"
            value={form?.crmProspectStatusDescription}
            className={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={6}>
            <Grid item>
              <ButtonLoadingComp
                loading={loading}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => onSave(form)}
              >
                <Save className={classes.leftIcon} />
                Salvar
              </ButtonLoadingComp>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

DadosProspectTabContent.propTypes = {
  loading: PropTypes.bool,
  payload: PropTypes.shape().isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

DadosProspectTabContent.defaultProps = {
  loading: false,
}

export default DadosProspectTabContent
