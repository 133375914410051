import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'
import { API_URL } from 'api'

const ref = 'bairro'
const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
}


export const addBairroAPI = ({ token, municipioId, bairroNome }) => {
    const requestBody = {
        municipioId,
        bairroNome,
    };

    return fetch(`${API_URL}/${ref}`, {
        method: 'POST',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    })
        .then(httpParseJson)
        .then(httpValidState)
        .catch(httpValidErros);
};

/**
 * @param {String} token - JWT token
 * @param {String} municipioId - ID do município
 */
export const listBairrosByMunicipioIdApi = (token, municipioId) => {
    return fetch(`${API_URL}/${ref}/by-municipio/${municipioId}`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        }
    })
        .then(httpParseJson)
        .then(httpValidState)
        .catch(httpValidErros)
}