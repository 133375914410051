import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import dialog from './dialog'
import config from './config'
import usuario from './usuario'
import produto from './produto'
import carrinho from './carrinho'
import financeiro from './financeiro'
import pedidos from './pedidos'
import cliente from './cliente'
import notificacoes from './notificacoes'

// Gestor
import gestorUsuarios from './gestor/usuarios'
import gestorProdutos from './gestor/produtos'
import gestorClientes from './gestor/clientes'

// Crm Contatos
import crmContatos from './crm/contato'

import checklist from './checklist'

export default history => combineReducers({
  router: connectRouter(history),
  dialog,
  config,
  usuario,
  produto,
  carrinho,
  financeiro,
  pedidos,
  cliente,
  notificacoes,
  gestorUsuarios,
  gestorProdutos,
  gestorClientes,
  crmContatos,
  checklist,
})
