// State Dialog
const initialState = {
  showCloseButton: true,
  open: false,
  size: 'md',
  title: '',
  content: null,
  actions: [],
  loading: false,
  actionLoading: {
    key: '',
    loading: false,
  },
  searchGeneric: {
    loading: false,
    data: {
      page: 0,
      pages: 0,
      rows: 0,
      itens: [],
    },
  },
  generic: {},
}

// Actions Types
export const Types = {
  DIALOG_UPDATE_FIELDS: 'dialog/DIALOG_UPDATE_FIELDS',
  DIALOG_LOAD_GENERIC: 'dialog/DIALOG_LOAD_GENERIC',
  DIALOG_GENERIC: 'dialog/DIALOG_GENERIC',
}

// Reducers
export default function dialogReducers(state = initialState, action) {
  switch (action.type) {
    case Types.DIALOG_UPDATE_FIELDS:
      return { ...state, ...action.payload }
    case Types.DIALOG_LOAD_GENERIC:
      return { ...state, searchGeneric: { ...state.searchGeneric, ...action.payload } }
    case Types.DIALOG_GENERIC:
      return { ...state, generic: { ...state.generic, ...action.payload } }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  onDialogOpen: ({
    open = true,
    size = 'md',
    title = '',
    content,
    actions = [],
    showCloseButton = true,
  }) => (dispatch) => {
    dispatch({
      type: Types.DIALOG_UPDATE_FIELDS,
      payload: {
        open,
        size,
        content,
        actions,
        title,
        showCloseButton,
      },
    })
  },
  onDialogClose: () => (dispatch) => {
    dispatch({ type: Types.DIALOG_UPDATE_FIELDS, payload: { open: false } })
    setTimeout(() => {
      dispatch({ type: Types.DIALOG_UPDATE_FIELDS, payload: initialState })
    }, 100)
  },
  onDialogLoading: loading => (dispatch) => {
    dispatch({ type: Types.DIALOG_UPDATE_FIELDS, payload: { loading } })
  },
  onDialogActionLoading: actionLoading => (dispatch) => {
    dispatch({ type: Types.DIALOG_UPDATE_FIELDS, payload: { actionLoading } })
  },
  // Search Generic
  onSearchGenericClean: () => (dispatch) => {
    dispatch({
      type: Types.DIALOG_LOAD_GENERIC,
      payload: { data: initialState.searchGeneric.data },
    })
  },
  onSearchGenericLoading: loading => (dispatch) => {
    dispatch({ type: Types.DIALOG_LOAD_GENERIC, payload: { loading } })
  },
  onSearchGenericLoadData: payload => (dispatch) => {
    dispatch({ type: Types.DIALOG_LOAD_GENERIC, payload: { data: payload } })
  },
  onUpdateGeneric: payload => ({ type: Types.DIALOG_GENERIC, payload }),
}
