import { API_URL } from '..'
import { httpParseJson, httpValidState, httpValidErros } from '../../utils/http'

const ref = '/produtos'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const getProdutoBySkuApi = dto => fetch(`${API_URL}${ref}/sku/${dto.sku}`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const getProdutoByIdApi = dto => fetch(`${API_URL}${ref}/${dto.id}`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)
export const getAllProdutosApi = dto => fetch(`${API_URL}${ref}`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteProdutoByIdApi = dto => fetch(`${API_URL}${ref}/${dto.id}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const getProdutosApi = dto => fetch(`${API_URL}${ref}/filter`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  },
  body: JSON.stringify(dto),
})
  .then(res => res.json())
  .then(httpValidState)
  .catch(httpValidErros)

export const getMaisVendidos = dto => fetch(`${API_URL}${ref}/vendidos`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  },
})
  .then(res => res.json())
  .then(httpValidState)
  .catch(httpValidErros)

export const getConfigs = dto => fetch(`${API_URL}${ref}/config`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  },
})
  .then(res => res.json())
  .then(httpValidState)
  .catch(httpValidErros)

// Área do Gestor
// ---------------
export const searchProdutosApi = (dto) => {
  const { token, q } = dto
  return fetch(`${API_URL}${ref}/gestor/filter?q=${q}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}
/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do produto, fk: ID da categoria }
 */
export const AddProdutoCategoriaApi = ({ token, dto }) => fetch(`${API_URL}${ref}/categoria`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do produto, fk: ID da categoria }
 */
export const DeleteProdutoCategoriaApi = ({ token, dto }) => fetch(`${API_URL}${ref}/categoria`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { id: 0, produtoQuant: 0, produtoQuantMin: 0 }
 */
export const UpdateProdutoGestorApi = ({ token, dto }) => fetch(`${API_URL}${ref}/gestor`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { id: 0, produtoQuant: 0, produtoQuantMin: 0 }
 */
export const AddProdutoApi = ({ token, dto }) => fetch(`${API_URL}${ref}`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { id: 0, produtoQuant: 0, produtoQuantMin: 0 }
 */
export const EditProdutoApi = ({ token, dto, id }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)
