import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import classNames from 'classnames'
import {
  Typography,
  CssBaseline,
  Grid,
  Fab,
  Icon,
  Tooltip,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import ReactPaginate from 'react-paginate'

import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as DialogActions } from '../../../../store/ducks/dialog'
import { Actions as GestorClientesActions } from '../../../../store/ducks/gestor/clientes'
import GestorHeaderComp from '../../../../components/Gestor/Header'
import GestorDrawerComp from '../../../../components/Gestor/Drawer'
import TableDataComp from '../../../../components/Table'
import useStyles from './styles'
import { Pagination } from '@material-ui/lab'

const GestorClientesListagemPage = (props) => {
  const {
    usuario,
    gestorClientes: { loading, list },
    push,
    onSearchClientes,
  } = props
  const [currentPage, setCurrentPage] = useState(1)
  const classes = useStyles()
  // State
  const [iptSearch, setIptSearch] = useState('')
  const tableCols = [
    { key: 'crmProspectStatusDescription', title: 'STATUS' },
    { key: 'codigo', title: 'CÓDIGO' },
    { key: 'nome', title: 'NOME' },
    { key: 'cgc', title: 'CPF' },
  ]
  const tableActionsList = [
    /*
    {
      key: 'bloquear',
      title: 'Bloquear',
      icon: 'clear',
      action: row => null,
    },
    */
    {
      key: 'detalhes',
      title: 'Detalhes',
      icon: 'list',
      action: row => push(`clientes/${row.id}`),
    },
  ]

  useEffect(() => {
    document.title = 'Online - Gestor | Clientes'
    document.body.style = 'background-image: none;'
  }, [])

  useEffect(() => {
    onSearchClientes({ search: iptSearch, page: currentPage - 1 })
  }, [currentPage])

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" component="h2">
                  Clientes
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container direction="row" justifyContent="flex-end">
                  <TextField
                    id="outlined-adornment-password"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label="Pesquisar"
                    value={iptSearch}
                    onChange={evt => setIptSearch(evt.target.value.toUpperCase())}
                    onKeyPress={(evt) => {
                      if (evt.charCode === 13) {
                        onSearchClientes({ search: iptSearch })
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Pesquisar"
                            onClick={() => onSearchClientes({ search: iptSearch })}
                          >
                            <Icon>search</Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Tooltip title="Adicionar">
                    <Fab
                      color="primary"
                      aria-label="Add"
                      className={classes.fab}
                      onClick={() => push('clientes/add')}
                    >
                      <Icon>add</Icon>
                    </Fab>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <TableDataComp
              size="small"
              loading={loading.list}
              rows={list?.data ?? []}
              cols={tableCols}
              actions={tableActionsList}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <div id="mui-pagination">
              <Pagination
                count={list?.total ?? 0}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                className={classes.pagination}
              />
            </div>
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

GestorClientesListagemPage.propTypes = {
  usuario: PropTypes.shape().isRequired,
  gestorClientes: PropTypes.shape().isRequired,
  onSearchClientes: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  gestorClientes: state.gestorClientes,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...GestorClientesActions,
    push,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GestorClientesListagemPage)
