import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Menu,
  MenuItem,
  Fade,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { matchSorter } from 'match-sorter'
import _ from 'lodash'

import useStyles from './styles'

const TrocaClienteComp = (props) => {
  const {
    titulo, usuario, clientes, onChange,
  } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [dialogClientes, setDialogClientes] = useState({ open: false, title: '' })
  const [listClientes, setListClientes] = useState([])
  const handleOpenClose = (evt) => {
    if (_.isArray(clientes) && clientes.length <= 10) {
      setAnchorEl(evt ? evt.currentTarget : null)
    } else {
      setDialogClientes({
        ...dialogClientes,
        open: !dialogClientes.open,
      })
    }
  }
  const handleSelectCliente = (item) => {
    handleOpenClose(null)
    onChange(item)
  }
  const open = Boolean(anchorEl)

  const handlePesquisarClientes = (evt) => {
    const inputValue = evt.target.value
    setListClientes(matchSorter(clientes, inputValue, { keys: ['razao', 'cgc'] }))
  }

  useEffect(() => {
    const title = usuario.role === 'ROLE_VENDEDOR' ? 'TROCAR CLIENTE' : 'TROCAR FILIAL'
    setDialogClientes({ ...dialogClientes, title })
    setListClientes(_.orderBy(clientes, ['descricao'], ['asc']))
  }, [])

  return (
    <>
      <Button
        aria-owns={open ? 'menu-clientes' : undefined}
        aria-haspopup="true"
        color="inherit"
        className={classes.title}
        onClick={handleOpenClose}
      >
        {titulo}
      </Button>
      <Menu
        id="menu-clientes"
        anchorEl={anchorEl}
        open={open}
        // onClose={this.handleClose}
        TransitionComponent={Fade}
      >
        {_.orderBy(clientes, ['descricao'], ['asc']).map(c => (
          <MenuItem key={c.id} onClick={() => handleSelectCliente(c)}>
            {c.razao}
          </MenuItem>
        ))}
      </Menu>
      <Dialog
        onClose={handleOpenClose}
        aria-labelledby="dialog-trocar-cliente"
        open={dialogClientes.open}
        // maxWidth={dialog.size}
        fullWidth
      >
        <DialogTitle disableTypography className={classes.dialogRoot}>
          <Typography variant="h6">{dialogClientes.title}</Typography>
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleOpenClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <TextField
                    fullWidth
                    label="Pesquisar..."
                    variant="outlined"
                    onChange={handlePesquisarClientes}
                  />
                </TableCell>
              </TableRow>
              {listClientes.map(c => (
                <TableRow
                  key={c.cgc}
                  className={classes.tableRow}
                  hover
                  onClick={() => handleSelectCliente(c)}
                >
                  <TableCell component="td" scope="row">
                    {c.status === 'B' ? (
                      <Typography variant="h6" style={{ display: 'grid', color: '#FF0000' }}>
                        <span>
                          {`${c.razao} `}
                          <small><strong>(INATIVO)</strong></small>
                        </span>
                        <small>{`CPF/CNPJ: ${c.cgc || 'NENHUM'}`}</small>
                      </Typography>
                    ) : (
                      <Typography variant="h6" style={{ display: 'grid' }}>
                        {c.razao}
                        {c.status}
                        {<small>{`CPF/CNPJ: ${c.cgc || 'NENHUM'}`}</small>}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  )
}

TrocaClienteComp.propTypes = {
  titulo: PropTypes.string.isRequired,
  usuario: PropTypes.shape().isRequired,
  clientes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default TrocaClienteComp
