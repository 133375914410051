import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from '../..'

const ref = '/crm/oportunidades'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - { token: String, page, rows, search, prospectId, oportunidadeId }
 */
export const filterCrmOportunidadeApi = (dto) => {
  const {
    token,
    query: {
      page, rows, search, usuarioId, filialId, prospectId, oportunidadeId, dateAgendaFree, cancelado,agendaTipoId,statusIds
    } = {},
  } = dto

  let queryUrl = '?1=1'
  queryUrl += dto.query.page ? `&page=${dto.query.page}` : ''
  queryUrl += dto.query.rows ? `&rows=${dto.query.rows}` : ''
  queryUrl += search ? `&search=${search}` : ''
  queryUrl += usuarioId ? `&usuarioId=${usuarioId}` : ''
  queryUrl += filialId ? `&filialId=${filialId}` : ''
  queryUrl += prospectId ? `&prospectId=${prospectId}` : ''
  queryUrl += oportunidadeId ? `&oportunidadeId=${oportunidadeId}` : ''
  queryUrl += dateAgendaFree ? `&dateAgendaFree=${dateAgendaFree}` : ''
  queryUrl += cancelado ? `&cancelado=${cancelado}` : ''
  queryUrl += agendaTipoId ? `&agendaTipoId=${agendaTipoId}` : ''
  queryUrl += statusIds ? `&statusIds=${statusIds}` : ''

  const apiUrl = `${API_URL}${ref}${queryUrl}`;
  console.log('URL da Requisição:', apiUrl);

  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, page, rows, search, prospectId, oportunidadeId }
 */
export const filterCrmOportunidadeItensApi = (dto) => {
  const {
    token,
    query: {
      page = 0, rows = 50, search = '', usuarioId, filialId, prospectId, oportunidadeId, dateAgendaFree, cancelado,agendaTipoId, statusIds
    } = {},
  } = dto

  let queryUrl = '?1=1'
  queryUrl += page ? `&page=${page}` : ''
  queryUrl += rows ? `&rows=${rows}` : ''
  queryUrl += search ? `&search=${search}` : ''
  queryUrl += usuarioId ? `&usuarioId=${usuarioId}` : ''
  queryUrl += filialId ? `&filialId=${filialId}` : ''
  queryUrl += prospectId ? `&prospectId=${prospectId}` : ''
  queryUrl += oportunidadeId ? `&oportunidadeId=${oportunidadeId}` : ''
  queryUrl += dateAgendaFree ? `&dateAgendaFree=${dateAgendaFree}` : ''
  queryUrl += cancelado ? `&cancelado=${cancelado}` : ''
  queryUrl += agendaTipoId ? `&agendaTipoId=${agendaTipoId}` : ''
  queryUrl += statusIds ? `&statusIds=${statusIds}` : ''


  return fetch(`${API_URL}${ref}/itens${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, id }
 */
export const findByIdCrmOportunidadeApi = (dto) => {
  const { token, id } = dto
  return fetch(`${API_URL}${ref}/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const addCrmOportunidadeApi = ({ token, payload }) => fetch(`${API_URL}${ref}`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const editCrmOportunidadeApi = ({ token, id, payload }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do produto, fk: ID da categoria }
 */
export const delCrmOportunidadeApi = ({ token, id }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do produto, fk: ID da categoria }
 */
export const cancelCrmOportunidadeApi = ({ token, id, motivo }) => fetch(`${API_URL}${ref}/${id}/cancel`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify({ id, motivo }),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)
