import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
    Typography,
    Card,
    Grid,
    Fab,
    Icon,
    Tooltip,
    TextField,
    InputAdornment,
    IconButton,
    CssBaseline,
    Input,
    Button,
    CircularProgress,
} from '@material-ui/core'
import { ptBR } from 'date-fns/locale';


// import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import GestorHeaderComp from '../../../../components/Gestor/Header'
import GestorDrawerComp from '../../../../components/Gestor/Drawer'
import markerImage from '../../../../assets/imagens/marker.png';

import useStyles from './styles'
import 'leaflet/dist/leaflet.css';
import { getTrackingFilterApi } from 'api/tracking'
// Importe o ícone personalizado para o marcador
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L, { icon } from 'leaflet';
import { divIcon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { format } from 'date-fns';
import { DatePicker, DateTimePicker } from '@material-ui/pickers'
import { filterCrmAgentesApi } from 'api/crm/agentes'
import { Autocomplete } from '@material-ui/lab'
import { utcToZonedTime } from 'date-fns-tz'
import { NotificationManager } from 'react-notifications'






const TrackingMapsPage = (props) => {
    const {
        usuario, push, onDialogOpen, onDialogClose
    } = props
    const classes = useStyles()

    // State
    const [listFilter, setListFilter] = useState([])
    const [markers, setMarkers] = useState([])
    const customIcon = L.divIcon({
        className: 'custom-icon', // Nome da classe CSS para o ícone personalizado
        html: '<div class="custom-icon-marker"></div>', // HTML que define o ícone
    });

    const OnLoadTracking = () => {
        const dto = {
            token: usuario?.auth?.token ?? "",
            query: {
                search: ''
            }
        };
        setLoading(true);
        getTrackingFilterApi(dto)
            .then(response => {
                // Lógica para lidar com a resposta da API
                setMarkers(response)
                console.log(response);
                setLoading(false);
            })
            .catch(error => {
                // Lógica para lidar com o erro
                console.error(error);
                setLoading(false);
            });
    };


    useEffect(() => {
        document.title = 'Online - Tracking'
        document.body.style = 'background-image: none;'
        OnLoadTracking()
        onSearchAgentes()
    }, [])
    const calculateCenter = (markers) => {
        if (markers.length === 0) {
            return [-8.0504314, -34.8933611]; // Posição padrão(SEDE DO CLIENTE) caso não haja marcadores
        }

        return [markers[0].lat, markers[0].lng]; // Retorna a posição do primeiro marcador
    };


    const [filter, setFilter] = useState({
        page: 0,
        rows: 100,
        search: '',
    });
    const onSearchAgentes = () => {
        filterCrmAgentesApi({ token: usuario?.auth?.token ?? '', query: filter })
            .then((resp) => {
                const { data = [] } = resp || {}
                setAgentes(data)
            })
    }
    // Style
    const stylesForm = {
        marginTop: '2rem',
        marginBottom: '1rem',
        display: 'flex',
        gap: '1rem',
        width: '100%',
    };
    const [startDate, setStartDate] = useState(null);
    const [finalDate, setFinalDate] = useState(null);
    const [selectedAgente, setSelectedAgente] = useState(null);
    const [agentes, setAgentes] = useState([]);
    const [loading, setLoading] = useState(true); // Estado para controlar o "Loading"


    const handleFilter = () => {
        if (!selectedAgente || !startDate || !finalDate) {
            NotificationManager.warning('Por favor, selecione todos os campos do filtro!', 'Aviso!', 4000);
            return;
        }
        // enviar no filtro da chamada da API
        const query = {
            agenteId: selectedAgente ? selectedAgente.id : null,
            startDate,
            finalDate,
        };
        // chamada à API com o filtro
        setLoading(true);
        getTrackingFilterApi({ token: usuario?.auth?.token ?? '', query })
            .then((resp) => {
                NotificationManager.success('O Mapa foi atualizado com sucesso!', 'Sucesso!', 4000);
                setMarkers(resp);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Erro ao obter os dados:", error);
                setLoading(false);
            });
    };
    var icon = L.icon({
        iconUrl: markerIcon,

        iconSize: [20, 20],
        iconAnchor: [10, 0],
    });
    return (
        <div className={classes.root}>
            <CssBaseline />
            <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
            <GestorDrawerComp {...props} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h3" component="h2">
                                    Tracking
                                </Typography>
                            </Grid>
                            {/* Menus */}
                        </Grid>
                    </Grid>
                    {/* Filtros */}
                    {/* Menus */}
                    <Grid item xs={12} md={12}>
                        <Grid>
                            <form style={stylesForm}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={3}>
                                        <Autocomplete
                                            id="agente-combo-box"
                                            options={agentes}
                                            getOptionLabel={(option) => option.name}
                                            value={selectedAgente}
                                            onChange={(event, newValue) => setSelectedAgente(newValue)}
                                            renderInput={(params) => <TextField {...params} label="Agentes" variant="outlined" />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            label="Data Inicial"
                                            fullWidth
                                            type="datetime-local"
                                            name="dataInicial"
                                            InputLabelProps={{ shrink: true }}
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            label="Data Final"
                                            fullWidth
                                            type="datetime-local"
                                            name="dataFinal"
                                            InputLabelProps={{ shrink: true }}
                                            value={finalDate}
                                            onChange={(e) => setFinalDate(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Button variant="contained" color="primary" onClick={handleFilter}>
                                            Filtrar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                    {/* Body */}
                    <Grid item xs={12} md={12}>
                        {loading ? (
                            <CircularProgress /> // Indicador de carregamento com spinner
                        ) : (
                            <MapContainer center={markers ? calculateCenter(markers) : [0, 0]} zoom={25} style={{ height: '600px' }}>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                {markers.map((marker, index) => (
                                    <Marker key={index} position={[marker.lat, marker.lng]} icon={icon}>
                                        <Popup>
                                            Data: {format(utcToZonedTime(new Date(marker.date), 'America/Sao_Paulo'), 'dd/MM/yyyy', { locale: ptBR })}<br />
                                            Hora: {marker.time.substring(0, 5)}<br />
                                            Agente: {marker.crmAgenteNome}
                                        </Popup>
                                    </Marker>
                                ))}
                            </MapContainer>
                        )}
                    </Grid>
                </Grid>
            </main>
        </div>
    )
}

TrackingMapsPage.propTypes = {
    usuario: PropTypes.shape().isRequired,
    push: PropTypes.func.isRequired,
    onDialogOpen: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        ...ConfigActions,
        push: crrPush,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TrackingMapsPage)
