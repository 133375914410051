const config = {
  cliente: {
    sku: 'online',
    nome: 'IKI',
    razao: 'Publik Image',
    usa2logos: false,
  },
  producao: {

  },
  develop: {

  },
  teste: {

  },
  default: {
    exibeAjudaLogin: 'NAO', // Valores: SIM|NAO
    altLogoLeft: 'Online',
    altLogoRight: 'Online',
    logoLeft: 'logo-publikimagem.svg', // Valores: polimix-logo-left.png, logo-gvb.png
    logoRight: '',
    loginCardHeader: 'login-card-header.png',
    cadastroCardHeader: 'card-header-cadastro.png',
  },
}

export default config
