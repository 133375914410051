import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Paper,
  Button,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  CircularProgress,
  Slide,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core'

import { history } from '../../../../store'
import ROLES from '../../../../utils/enums/roles'
import { getClienteInfoByIdApi } from '../../../../api/cliente'
import { Actions as UsuarioActions } from '../../../../store/ducks/usuario'
import { Actions as ConfigActions } from '../../../../store/ducks/config'
import styles from './styles'

class GestorClientesAprovacaoFormPage extends Component {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    location: PropTypes.shape().isRequired,
    usuario: PropTypes.shape().isRequired,
    auth: PropTypes.shape().isRequired,
    getParametro: PropTypes.func.isRequired,
    onAprovaReprovaUsuario: PropTypes.func.isRequired,
    onLoginErrorValid: PropTypes.func.isRequired,
    onLoginRedirectTo: PropTypes.func.isRequired,
  }

  state = {
    expanded: 'panel1',
    loading: true,
    novoUsuario: null,
    motivo: '',
    open: false,
    liberando: false,
    bloqueando: false,
  }

  componentDidMount() {
    this.onLoadUserData(ROLES)
  }

  handleClose = () => {
    this.setState(state => ({ ...state, open: !state.open }))
  }

  handleLiberando = () => {
    this.setState(state => ({ ...state, liberando: !state.liberando }))
  }

  handleBloqueando = () => {
    this.setState(state => ({ ...state, bloqueando: !state.bloqueando }))
  }

  handleAprova = () => {
    const { novoUsuario } = this.state
    const { onAprovaReprovaUsuario } = this.props
    this.handleLiberando()
    onAprovaReprovaUsuario(novoUsuario, 'L', '', this.handleLiberando)
  }

  handleBloqueia = () => {
    const { novoUsuario, motivo } = this.state
    const { onAprovaReprovaUsuario } = this.props
    this.handleBloqueando()
    onAprovaReprovaUsuario(novoUsuario, 'B', motivo, () => {
      this.handleClose()
      this.handleBloqueando()
    })
  }

  onLoadUserData = (pROLES) => {
    setTimeout(() => {
      const {
        location, auth, usuario, onLoginErrorValid, onLoginRedirectTo,
      } = this.props
      const queryUrl = new URLSearchParams(location.search)
      let isUsrValid = false
      let isOK = true
      const dto = {
        token: auth.token,
        clienteId: queryUrl.get('cli'),
        filialId: queryUrl.get('fil'),
        usuarioId: queryUrl.get('usr'),
        aprovadorId: queryUrl.get('apro'),
      }

      if (!auth || !auth.token) {
        isOK = false
        onLoginErrorValid('Você deve fazer login para realizar a aprovação desse cliente.')
        onLoginRedirectTo(
          `/gestor/clientes/aprovacoes/form?cli=${dto.clienteId}&fil=${dto.filialId}&usr=${dto.usuarioId}&apro=${dto.aprovadorId}`,
        )
        setTimeout(() => {
          history.push('/login')
        }, 500)
      }

      isUsrValid = usuario.perfils.find(
        p => p.role === pROLES.ROLE_ADMIN || p.role === pROLES.ROLE_AUDITOR,
      )

      if (auth && auth.token && isUsrValid) {
        document.body.style = 'background-image: none;'
      } else {
        isOK = false
        onLoginErrorValid('Usuário inválido.')
        setTimeout(() => {
          history.push('/login')
        }, 500)
      }

      // Validando se o usuário é do tipo correto para aprovar o cadastro.
      if (usuario.perfils.find(p => p.role === 'ROLE_CLIENTE')) {
        isOK = false
        onLoginErrorValid('Sua conta não tem permissão para realizar a ativação de outra.')
        setTimeout(() => {
          history.push('/login')
        }, 500)
      }

      if (isOK) {
        getClienteInfoByIdApi(dto).then((novoUsuario) => {
          this.setState({ loading: false })
          if (novoUsuario) {
            const usrNovo = novoUsuario.usuarios.find(u => u.id == dto.usuarioId)
            this.setState({
              novoUsuario: {
                ...novoUsuario,
                id: dto.usuarioId,
                unidade: { id: dto.filialId },
                cliente: { id: dto.clienteId },
                nome: usrNovo.nome,
                email: usrNovo.email,
                cpf: usrNovo.cpf,
                statusUsuario: usrNovo.status,
                statusUsuarioDesc: usrNovo.statusDesc,
              },
            })
          }
        })
      }
    }, 1000)
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    })
  }

  onChangeMotivo = (motivo) => {
    this.setState(state => ({ ...state, motivo }))
  }

  Transition = props => <Slide direction="up" {...props} />

  contentClient = () => {
    const { expanded, novoUsuario } = this.state
    const { classes } = this.props
    return (
      <Fragment>
        <ExpansionPanel
          square
          className={classes.expansionPanel}
          expanded={expanded === 'panel1'}
          onChange={this.handleChange('panel1')}
        >
          <ExpansionPanelSummary>
            <Typography variant="h6" gutterBottom>
              #1 Dados da Empresa
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Grid item>
                <Typography variant="button">RAZÃO SOCIAL:</Typography>
                <Typography gutterBottom>{` ${novoUsuario.razao}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="button">CGC:</Typography>
                <Typography gutterBottom>{` ${novoUsuario.cgc}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="button">TIPO:</Typography>
                <Typography gutterBottom>{` ${novoUsuario.tipoPessoa}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="button">STATUS:</Typography>
                <Typography gutterBottom>{` ${novoUsuario.statusCliente}`}</Typography>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === 'panel2'}
          onChange={this.handleChange('panel2')}
        >
          <ExpansionPanelSummary>
            <Typography variant="h6" gutterBottom>
              #2 Endereço da Empresa
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Grid item>
                <Typography variant="button">LOGRADOURO:</Typography>
                <Typography gutterBottom>
                  {` ${novoUsuario.logradouro}, ${novoUsuario.numero} COMP: ${novoUsuario.complemento}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="button">BAIRRO:</Typography>
                <Typography gutterBottom>{` ${novoUsuario.bairro}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="button">CIDADE:</Typography>
                <Typography gutterBottom>
                  {` ${novoUsuario.cidade} UF: ${novoUsuario.uf}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="button">CEP:</Typography>
                <Typography gutterBottom>{` ${novoUsuario.cep}`}</Typography>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === 'panel3'}
          onChange={this.handleChange('panel3')}
        >
          <ExpansionPanelSummary>
            <Typography variant="h6" gutterBottom>
              #3 Dados Usuário
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Grid item>
                <Typography variant="button">NOME:</Typography>
                <Typography gutterBottom>{` ${novoUsuario.nome}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="button">E-MAIL:</Typography>
                <Typography gutterBottom>{` ${novoUsuario.email}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="button">CPF:</Typography>
                <Typography gutterBottom>{` ${novoUsuario.cpf}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="button">STATUS:</Typography>
                <Typography gutterBottom>{` ${novoUsuario.statusUsuarioDesc}`}</Typography>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Fragment>
    )
  }

  contentAction = () => {
    const { liberando, bloqueando } = this.state
    const { classes } = this.props
    return (
      <Fragment>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.handleAprova}
            disabled={liberando}
          >
            Liberar
          </Button>
          {liberando && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={this.handleClose}
            disabled={bloqueando}
          >
            Bloquear
          </Button>
          {bloqueando && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
        <div className={classes.wrapper}>
          <Button variant="contained" className={classes.button} component={Link} to="/login">
            Cancelar
          </Button>
        </div>
        {this.contentDialogMotivo()}
      </Fragment>
    )
  }

  contentDialogMotivo = () => {
    const {
      novoUsuario, open, bloqueando, motivo,
    } = this.state
    const { classes } = this.props
    return (
      <Dialog
        open={open}
        TransitionComponent={this.Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Informe o motivo bloqueio.</DialogTitle>
        <DialogContent>
          <TextField
            id="motivo"
            label="Motivo:"
            multiline
            rowsMax="4"
            helperText={`Esse motivo será enviado para o usuário: ${novoUsuario.nome}.`}
            fullWidth
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={motivo}
            onChange={evt => this.onChangeMotivo(evt.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            CANCELAR
          </Button>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="secondary"
              disabled={bloqueando}
              className={classes.button}
              onClick={this.handleBloqueia}
            >
              BLOQUEAR
            </Button>
            {bloqueando && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
    )
  }

  render() {
    const { loading, novoUsuario } = this.state
    const { classes, usuario, getParametro } = this.props
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12} style={{ marginTop: 20 }}>
          {/* <Header showNavLoja={false} /> */}
          {!loading && novoUsuario && novoUsuario.razao ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={8}>
                <Paper className={classes.root} elevation={1}>
                  <Typography variant="h5" component="h3">
                    {`Aprovação do cliente: ${novoUsuario.razao}`}
                  </Typography>
                  <Typography component="p">
                    {`Olá ${usuario.nome.trim()}, você deve analisar essa solicitação de cadastro caso esteja
                  correta você pode liberar o acesso dele.`}
                  </Typography>
                  {this.contentClient()}
                </Paper>
              </Grid>
              <Grid item xs={8} style={{ marginTop: 20 }}>
                <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
                  {this.contentAction()}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <CircularProgress size={48} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.usuario.usuario.auth,
  usuario: state.usuario.usuario,
  cliente: state.usuario.usuario.cliente,
  buscando: state.produto.buscando,
  busca: state.produto.busca,
  buscaDados: state.produto.buscaDados,
  categorias: state.produto.categorias,
  maisVendidos: state.produto.maisVendidos,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...UsuarioActions,
    ...ConfigActions,
  },
  dispatch,
)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(GestorClientesAprovacaoFormPage),
)
