import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
    Typography,
    Card,
    Grid,
    Fab,
    Icon,
    Tooltip,
    TextField,
    InputAdornment,
    IconButton,
    CssBaseline,
    Input,
    Button,
    Paper,
    Divider,
    Checkbox,
    Box,
    CardContent,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core'

// import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import useStyles from './styles'
import { findByIdEntregaApi } from 'api/entrega'
import { findByIdCrmVistoriasApi } from 'api/crm/vistorias'
import { getChecklistRespostaItensByEntregaIdApi, getChecklistRespostaItensByVistoriaIdApi } from 'api/checklist_reposta_itens'
import TableDataComp from 'components/Table'

const EntregaChecklistTabContent = (props) => {
    const {
        usuario,
        push,
        params
    } = props
    const [payload, setPayload] = useState([]);
    const [entrega, setEntrega] = useState([])

    const classes = useStyles();

    const fetchChecklistRespostaItens = async () => {
        try {
            const response = await getChecklistRespostaItensByEntregaIdApi({ token: usuario?.auth.token, id: params.id });
            setPayload(response); // Definir a resposta no estado payload
        } catch (error) {
            console.error('Erro ao buscar os dados:', error);
        }
    };

    const fetchDataById = async (token, id) => {
        try {
            const response = await findByIdEntregaApi({ token: token, id: id });
            setEntrega(response)
            return response.data; // Retorna os dados da resposta
        } catch (error) {
            console.error('Erro ao buscar os dados por ID:', error);
            return null;
        }
    };
    const infos = [
        { label: "Descrição do checklist", value: payload?.tituloQuestionario },
        { label: "Produto", value: entrega?.produtoDescricao },
        { label: "Resultado", value: entrega?.resultado },
        { label: "Data entrega", value: entrega?.realizadaData ? new Date(entrega.realizadaData).toLocaleString('pt-BR') : "NÃO REALIZADA" },
    ];

    useEffect(() => {
        document.title = 'Online - Vistoria Detalhe';
        document.body.style = 'background-image: none;';
        fetchDataById(usuario?.auth?.token, params.id)
        fetchChecklistRespostaItens(); // Chamar a função para buscar os dados
    }, []);

    const tableCols = [
        { key: 'pergunta', title: 'PERGUNTA' },
        {
            key: 'respostas',
            title: 'RESPOSTAS',
            format: r => (
                <div>
                    {r?.respostas?.map(item => (
                        <div key={item}>
                            <Checkbox checked={true} disabled={true} />
                            {item}
                        </div>
                    ))}
                </div>
            )
        }
    ];

    const CardItem = ({ label, value }) => (
        <Box marginBottom={2}>  {/* Espaçamento na parte inferior de cada CardItem */}
            <TextField
                label={label}
                defaultValue={value}
                variant="outlined"
                InputProps={{
                    style: {
                        color: 'black',
                        cursor: 'not-allowed'
                    },
                }}
                disabled
                fullWidth
            />
        </Box>
    );

    const formatValueText = (valueText) => {
        switch (valueText) {
            case 'true':
            case 'S':
                return '✔️ SIM';
            case 'false':
            case 'N':
                return '❌ NÃO';
            case '1':
                return '✔️ POSITIVO';
            case '0':
                return '❌ NEGATIVO';
            default:
                return valueText;
        }
    };

    const getRespostasById = (id, perguntaAtual) => {
        const respostas = payload.respostas?.filter(res => res.checklistItensId === id);
        if (!respostas || respostas.length === 0) return ['Resposta Desconhecida'];

        return respostas.map(resposta => {
            const alternativa = perguntaAtual.alternativas.find(alt => alt.checklistItensAlternativaId === resposta.checklistItensAlternativasId);
            const alternativaLabel = alternativa ? alternativa.checklistItensAlternativaLabel : "Alternativa Desconhecida";
            const pergunta = perguntaAtual.checklistItem;
            // Verificando se resposta.valueText é vazio ou string em branco
            if (!resposta.valueText || resposta.valueText.trim() === "") {
                return <span>{alternativaLabel} - <span style={{ color: 'green' }}>✅</span></span>;
            }

            switch (resposta.valueText) {
                case 'true':
                case 'S':
                    return alternativaLabel === "SIM"
                        ? <span><span style={{ color: 'green' }}>✅</span> SIM</span>
                        : <span>{alternativaLabel} - <span style={{ color: 'green' }}>✅</span> SIM</span>;

                case 'false':
                case 'N':
                    return alternativaLabel === "NÃO"
                        ? <span>❌ NÃO</span>
                        : <span>{alternativaLabel} - ❌ NÃO</span>;

                case '1':

                    if (["SIM", "S", "Sim", "s"].includes(alternativaLabel)) {
                        return <span>✅ Sim</span>;
                    }
                    else if (/^QUAL\s+.+\s+PARECER\?$/.test(pergunta.trim().toUpperCase())) {
                        return <span>✅ POSITIVO</span>;
                    } else {
                        return <span> {alternativaLabel} - ✅SIM</span>;
                    }


                case '0':
                    if (["NÃO", "N", "Não", "NAO", "Nao", "n"].includes(alternativaLabel)) {
                        return <span>❌ NÃO</span>;
                    }
                    else if (/^QUAL\s+.+\s+PARECER\?$/.test(pergunta.trim().toUpperCase())) {
                        return <span>❌ NEGATIVO</span>;
                    }
                    else {
                        return <span> {alternativaLabel} - ❌ NÃO</span>;
                    }

                default:
                    return alternativaLabel === resposta.valueText
                        ? <span>{alternativaLabel}</span>
                        : <span>{alternativaLabel} - {resposta.valueText}</span>;
            }
        });
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Grid container>
                            {/* Card com título */}
                            <Grid item xs={12} md={12}>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            {/* Coluna da esquerda */}
                                            <Grid item xs={6}>
                                                <Typography variant="h5" component="h2">
                                                    {infos.filter((_, index) => index % 2 === 0).map((info, index) => (
                                                        <CardItem key={index} label={info.label} value={info.value} />
                                                    ))}
                                                </Typography>
                                            </Grid>
                                            {/* Coluna da direita */}
                                            <Grid item xs={6}>
                                                <Typography variant="h5" component="h2">
                                                    {infos.filter((_, index) => index % 2 !== 0).map((info, index) => (
                                                        <CardItem key={index} label={info.label} value={info.value} />
                                                    ))}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                </Card>
                            </Grid>
                            {/* Tabela */}
                            <Grid item xs={12} md={12}>
                                <Paper elevation={3} style={{ margin: '20px 0' }}>
                                    <Table>
                                        <TableHead style={{ backgroundColor: 'black' }}>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Perguntas</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Alternativas</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Respostas</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {payload.perguntas?.map((pergunta) => (
                                                <TableRow key={pergunta.checklistItemId}>
                                                    <TableCell>{pergunta.checklistItem}</TableCell>
                                                    <TableCell>
                                                        {pergunta.alternativas.map((alt, index) => (
                                                            <span key={alt.checklistItensAlternativaId}>
                                                                {alt.checklistItensAlternativaLabel}
                                                                {index !== pergunta.alternativas.length - 1 && <br />}
                                                            </span>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell>
                                                        {getRespostasById(pergunta.checklistItemId, pergunta).map((resposta, index) => (
                                                            <div key={index}>{resposta}</div>
                                                        ))}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </main>
        </div>
    )
}

EntregaChecklistTabContent.propTypes = {
    loading: PropTypes.shape().isRequired,
    gestorUsuarios: PropTypes.shape().isRequired,
    push: PropTypes.func.isRequired,
    onDialogOpen: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
    onAddUsuarioCliente: PropTypes.func.isRequired,
    onDeleteUsuarioCliente: PropTypes.func.isRequired,
    onSearchUsuarioCliente: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
    gestorUsuarios: state.gestorUsuarios,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        ...ConfigActions,
        push: crrPush,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EntregaChecklistTabContent)
