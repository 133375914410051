import { API_URL } from '..'
import { httpParseJson, httpValidState, httpValidErros } from '../../utils/http'

const ref = '/boletos'
const headers = {
  Accept: 'application/json',
}

export const getBoletos = ({
  token,
  empresa = null,
  filial = null,
  cgc = null,
  dtini = null,
  dtfim = null,
  titulo = null,
  valor = null,
  quitado = null,
}) => {
  let queryUrl = `/listarBoletosCliente?cgc=${cgc}`
  queryUrl = empresa ? `${queryUrl}&empresa=${empresa}` : queryUrl
  queryUrl = filial ? `${queryUrl}&filial=${filial}` : queryUrl
  queryUrl = dtini ? `${queryUrl}&dtini=${dtini}` : queryUrl
  queryUrl = dtfim ? `${queryUrl}&dtfim=${dtfim}` : queryUrl
  queryUrl = titulo ? `${queryUrl}&titulo=${titulo}` : queryUrl
  queryUrl = valor ? `${queryUrl}&valor=${valor}` : queryUrl
  queryUrl = quitado ? `${queryUrl}&quitado=${quitado}` : queryUrl

  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(res => httpValidState(res, true))
    .catch(httpValidErros)
}

export const downloadBoleto = (dto) => {
  const {
    token, usuarioCod, empresaCod, filialCod, numBoleto, prefixo, parcela,
  } = dto
  let queryUrl = `/geraBoletoCliente?user=${usuarioCod}&emp=${empresaCod}&fil=${filialCod}`
  queryUrl += `&numBol=${numBoleto}&prefixo=${prefixo}&parcela=${parcela}`
  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      'Content-Type': 'application/pdf',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}
