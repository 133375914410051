import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from 'api'

const ref = '/unidadeconsumidora'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - { token: String, page, rows, search, estado, municipio }
 */
export const filterUnidadeConsumidoraApi = ({ token, query }) => {
  const {
    page,
    rows,
    search,
    estado,
    municipio,
    bairro,
    subbairro,
    clienteId
  } = query;

  let queryUrl = '?1=1';
  queryUrl += query.page ? `&page=${query.page}` : '';
  queryUrl += rows ? `&rows=${rows}` : '';
  queryUrl += search ? `&search=${search}` : '';
  queryUrl += estado ? `&estado=${estado}` : '';
  queryUrl += municipio ? `&municipio=${municipio}` : '';
  queryUrl += bairro ? `&bairro=${bairro}` : '';
  queryUrl += subbairro ? `&subbairro=${subbairro}` : '';
  queryUrl += clienteId ? `&clienteId=${clienteId}` : '';

  return fetch(`${API_URL}${ref}/filter${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros);
};

/**
 *
 * @param {String} dto - { token: String,  }
 */
export const vincularAgenteUnidadeCApi = (dto) => {
  const {
    token,
    unidadeId,
    agenteId
  } = dto

  return fetch(`${API_URL}${ref}/vincular/${unidadeId}/${agenteId}`, {
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({})
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}
/**
 *
 * @param {String} dto - { token: String, id }
 */
export const findByIdUnidadeConsumidoraApi = (dto) => {
  const { token, id } = dto
  return fetch(`${API_URL}${ref}/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}
/**
 *
 * @param {String} dto - { token: String, id }
 */
export const editByIdUcApi = (dto) => {
  const { token, id, body } = dto
  return fetch(`${API_URL}${ref}/edit/${id}`, {
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body)
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}