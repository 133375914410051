import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import {
  Typography,
  Paper,
  Tabs,
  Tab,
  CssBaseline,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import _ from 'lodash'

import { Actions as DialogActions } from '../../../../store/ducks/dialog'
import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as GestorClientesActions } from '../../../../store/ducks/gestor/clientes'
import GestorHeaderComp from '../../../../components/Gestor/Header'
import GestorDrawerComp from '../../../../components/Gestor/Drawer'
import {
  DadosClienteTabContent,
  DadosUnidadesTabContent,
  DadosUsuariosTabContent,
} from './components'

import useStyles from './styles'

const TabsContent = ({ tabSelected }) => {
  switch (tabSelected) {
    case 1:
      return <DadosUnidadesTabContent />
    case 2:
      return <DadosUsuariosTabContent />
    default:
      return <DadosClienteTabContent />
  }
}

TabsContent.propTypes = {
  tabSelected: PropTypes.number.isRequired,
}

const GestorClientesDetalhePage = (props) => {
  const classes = useStyles()
  const [tabSelected, setTabSelected] = React.useState(0)
  const handleSelectTab = (event, newValue) => setTabSelected(newValue)
  const {
    match: { params },
    push,
    usuario,
    gestorClientes: { loading, cliente },
    onLoadDadosCliente,
    onResetDadosCliente,
    onUpdateClienteField,
  } = props
  const isNewUser = cliente && !cliente.id
  const pageSubtitle = !isNewUser ? `[${cliente.id}] ${cliente.codigo} - ${cliente.razao}` : ''
  // Consultado dados do usuário
  useEffect(() => {
    if (params.id) {
      onLoadDadosCliente(params.id)
    } else {
      onResetDadosCliente()
      onUpdateClienteField({ status: 'P', statusDesc: 'PENDENTE' })
    }
  }, [])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={10}>
                <Typography variant="h3" component="h3">
                  Cliente
                </Typography>
                <Typography variant="h6" component="h6">
                  {loading.cliente ? 'Carregando...' : pageSubtitle}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper className={classes.root}>
              <Tabs
                value={tabSelected}
                onChange={handleSelectTab}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Dados" disabled={loading.cliente} />
                <Tab label="Unidades" disabled={isNewUser || loading.cliente} />
                <Tab label="Usuários" disabled={isNewUser || loading.cliente} />
              </Tabs>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            {loading.cliente ? <CircularProgress /> : <TabsContent tabSelected={tabSelected} />}
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

GestorClientesDetalhePage.propTypes = {
  usuario: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  params: PropTypes.shape().isRequired,
  gestorClientes: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
  onLoadDadosCliente: PropTypes.func.isRequired,
  onResetDadosCliente: PropTypes.func.isRequired,
  onUpdateClienteField: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  gestorClientes: state.gestorClientes,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...GestorClientesActions,
    push,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GestorClientesDetalhePage)
