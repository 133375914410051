import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  margin: {
    marginRight: theme.spacing(2),
  },
  textField: {
    // flexBasis: 200,
  },
  progress: {
    margin: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar,
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

export default styles
