import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MaskedInput from 'react-text-mask'
import config from 'react-global-configuration'
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Card,
  CardContent,
  CardMedia,
  Button,
  TextField,
  Grid,
  Input,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core'

import { Actions as ClienteActions } from '../../../store/ducks/cliente'
import { Actions as ConfigActions } from '../../../store/ducks/config'
import PasswordMeterComp from '../../../components/PasswordMeter'
import styles from './styles'

function TelefoneMaskCustom(props) {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={['(', /[1-9]/, /\d/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

TelefoneMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}
class NovoClientePage extends Component {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    loading: PropTypes.bool.isRequired,
    buscando: PropTypes.bool.isRequired,
    formCadastro: PropTypes.shape().isRequired,
    dialogCliente: PropTypes.shape().isRequired,
    dialogValidacao: PropTypes.shape().isRequired,
    onReset: PropTypes.func.isRequired,
    onFieldUpdate: PropTypes.func.isRequired,
    onConsultaCgc: PropTypes.func.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    onCloseDialogValidacao: PropTypes.func.isRequired,
    onSaveCliente: PropTypes.func.isRequired,
  }

  state = {
    activeStep: 0,
  }

  componentDidMount() {
    const { onReset } = this.props
    const configClienteSku = config.get('cliente.sku')
    const configClienteNome = config.get('cliente.nome')
    const bg = require(`../../../assets/imagens/login/${configClienteSku}-login-bg-page.png`)
    document.title = `${configClienteNome} - Solicitar acesso`
    document.body.style = `background-image: url(${bg}); background-size:100% 100%; background-repeat:no-repeat; background-position: center;`
    document.documentElement.style = 'height: 100%'
    onReset()
    onReset()
    // onResetForm()
  }

  handleNext = () => {
    this.setState(state => ({
      activeStep:
        state.activeStep < this.getSteps().length ? state.activeStep + 1 : state.activeStep,
    }))
  }

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }))
  }

  handleReset = () => {
    this.setState({
      activeStep: 0,
    })
  }

  handleConsultaCgc = (evt) => {
    const { onConsultaCgc } = this.props
    if (evt.key === 'Enter') {
      onConsultaCgc()
    }
  }

  getSteps = () => ['Dados da Empresa', 'Endereço da Empresa', 'Dados Usuário']

  getStepContent = (label, step) => {
    switch (step) {
      case 0:
        return this.contentDadosEmpresa(label)
      case 1:
        return this.contentEnderecoEmpresa(label)
      case 2:
        return this.contentDadosContato(label)
      default:
        return this.contentDadosEmpresa(label)
    }
  }

  contentDadosEmpresa = (label) => {
    const {
      formCadastro, buscando, onFieldUpdate, onConsultaCgc,
    } = this.props
    return (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
        <StepContent>
          <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={5} md={5}>
              <Input
                autoComplete="off"
                placeholder="CNPJ"
                fullWidth
                onBlur={() => onConsultaCgc()}
                value={formCadastro.empresaCnpj}
                onKeyPress={evt => this.handleConsultaCgc(evt)}
                onChange={evt => onFieldUpdate({ empresaCnpj: evt.target.value })}
                inputProps={{
                  maxlength: 14,
                }}
                endAdornment={
                  buscando && (
                    <InputAdornment position="end">
                      <CircularProgress size={15} />
                    </InputAdornment>
                  )
                }
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                autoComplete="off"
                label="Razão Social"
                fullWidth
                disabled={formCadastro.disabled}
                value={formCadastro.empresaRazao}
                onChange={evt => onFieldUpdate({ empresaRazao: evt.target.value })}
              />
            </Grid>
          </Grid>
        </StepContent>
      </Step>
    )
  }

  contentEnderecoEmpresa = (label) => {
    const { formCadastro, onFieldUpdate } = this.props
    return (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
        <StepContent>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={7} md={7}>
              <TextField
                autoComplete="off"
                label="Logradouro"
                fullWidth
                value={formCadastro.empresaEndLogradouro}
                onChange={evt => onFieldUpdate({ empresaEndLogradouro: evt.target.value })}
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <TextField
                autoComplete="off"
                label="Número"
                fullWidth
                value={formCadastro.empresaEndNumero}
                onChange={evt => onFieldUpdate({ empresaEndNumero: evt.target.value })}
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <TextField
                autoComplete="off"
                label="Complemento"
                fullWidth
                value={formCadastro.empresaEndComplemento}
                onChange={evt => onFieldUpdate({ empresaEndComplemento: evt.target.value })}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextField
                label="Bairro"
                fullWidth
                value={formCadastro.empresaEndBairro}
                onChange={evt => onFieldUpdate({ empresaEndBairro: evt.target.value })}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextField
                autoComplete="off"
                label="Cidade"
                fullWidth
                value={formCadastro.empresaEndCidade}
                onChange={evt => onFieldUpdate({ empresaEndCidade: evt.target.value })}
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <TextField
                autoComplete="off"
                label="CEP"
                fullWidth
                value={formCadastro.empresaEndCep}
                onChange={evt => onFieldUpdate({ empresaEndCep: evt.target.value })}
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <TextField
                autoComplete="off"
                label="UF"
                fullWidth
                value={formCadastro.empresaEndUf}
                onChange={evt => onFieldUpdate({ empresaEndUf: evt.target.value })}
              />
            </Grid>
          </Grid>
        </StepContent>
      </Step>
    )
  }

  contentDadosContato = (label) => {
    const { formCadastro, onFieldUpdate, getParametro } = this.props
    const pswForte = getParametro('USA_PSW_FORTE', 'SIM')
    return (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
        <StepContent>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12} md={12}>
              <TextField
                autoComplete="off"
                label="Nome completo"
                fullWidth
                value={formCadastro.usuarioNome}
                onChange={evt => onFieldUpdate({ usuarioNome: evt.target.value })}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                autoComplete="off"
                label="E-mail"
                fullWidth
                value={formCadastro.usuarioEmail}
                onChange={evt => onFieldUpdate({ usuarioEmail: evt.target.value })}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                autoComplete="off"
                label="Celular"
                InputProps={{
                  inputComponent: TelefoneMaskCustom,
                }}
                fullWidth
                value={formCadastro.usuarioTelefone}
                onChange={evt => onFieldUpdate({ usuarioTelefone: evt.target.value })}
              />
            </Grid>
            <Grid item xs={3} md={3}>
              {pswForte === 'SIM' ? (
                <PasswordMeterComp
                  label="Senha"
                  value={formCadastro.usuarioSenha}
                  maxlength={10}
                  onChange={evt => onFieldUpdate({ usuarioSenha: evt.target.value })}
                />
              ) : (
                <TextField
                  autoComplete="new-password"
                  label="Senha"
                  type="password"
                  fullWidth
                  inputProps={{
                    maxlength: 10,
                  }}
                  value={formCadastro.usuarioSenha}
                  onChange={evt => onFieldUpdate({ usuarioSenha: evt.target.value })}
                />
              )}
            </Grid>
            <Grid item xs={3} md={3}>
              {pswForte === 'SIM' ? (
                <PasswordMeterComp
                  label="Re-Senha"
                  value={formCadastro.usuarioReSenha}
                  maxlength={10}
                  onChange={evt => onFieldUpdate({ usuarioReSenha: evt.target.value })}
                />
              ) : (
                <TextField
                  autoComplete="new-password"
                  label="Re-Senha"
                  type="password"
                  fullWidth
                  inputProps={{
                    maxlength: 10,
                  }}
                  value={formCadastro.usuarioReSenha}
                  onChange={evt => onFieldUpdate({ usuarioReSenha: evt.target.value })}
                />
              )}
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                autoComplete="off"
                label="CPF"
                fullWidth
                inputProps={{
                  maxlength: 11,
                }}
                value={formCadastro.usuarioCgc}
                onChange={evt => onFieldUpdate({ usuarioCgc: evt.target.value })}
              />
            </Grid>
          </Grid>
        </StepContent>
      </Step>
    )
  }

  contentNavButton = () => {
    const {
      classes, loading, formCadastro, onSaveCliente,
    } = this.props
    const { activeStep } = this.state
    const isFinalizar = activeStep >= this.getSteps().length - 1
    const {
      empresaCnpj,
      empresaRamo,
      empresaRazao,
      empresaEndLogradouro,
      empresaEndNumero,
      empresaEndBairro,
      empresaEndCidade,
      empresaEndCep,
      empresaEndUf,
      usuarioNome,
      usuarioEmail,
      usuarioTelefone,
      usuarioSenha,
      usuarioReSenha,
      usuarioCgc,
    } = formCadastro
    let ProximoLiberado = false
    switch (activeStep) {
      case 0:
        ProximoLiberado = !empresaCnpj || !empresaRamo || !empresaRazao
        break
      case 1:
        ProximoLiberado = !empresaEndLogradouro
          || !empresaEndNumero
          || !empresaEndBairro
          || !empresaEndCidade
          || !empresaEndCep
          || !empresaEndUf
        break
      case 2:
        ProximoLiberado = !usuarioNome
          || !usuarioEmail
          || !usuarioTelefone
          || !usuarioSenha
          || !usuarioReSenha
          || !usuarioCgc
        break
      default:
        ProximoLiberado = false
    }

    return (
      <Grid container spacing={2} className={classes.margin} alignItems="flex-start">
        <div className={classes.wrapper}>
          <Button disabled={activeStep === 0} onClick={() => this.handleBack()}>
            Voltar
          </Button>
        </div>
        {isFinalizar ? (
          <div className={classes.wrapper}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              style={{ marginLeft: 10 }}
              onClick={() => onSaveCliente()}
            >
              Finalizar
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        ) : (
          <div className={classes.wrapper}>
            <Button
              disabled={ProximoLiberado}
              variant="contained"
              color="primary"
              style={{ marginLeft: 10 }}
              onClick={() => this.handleNext()}
            >
              Próximo
            </Button>
          </div>
        )}
        <div className={classes.wrapper}>
          <Button component={Link} to="/login" color="secondary" className={classes.button}>
            Cancelar
          </Button>
        </div>
      </Grid>
    )
  }

  render() {
    const {
      classes,
      dialogCliente,
      dialogValidacao,
      onCloseDialog,
      onCloseDialogValidacao,
    } = this.props
    const { activeStep } = this.state
    const configDefaultAltLogoLeft = config.get('default.altLogoLeft')
    const configDefaultAltLogoRight = config.get('default.altLogoRight')
    const configDefaultLogoLeft = config.get('default.logoLeft')
    const configDefaultLogoRight = config.get('default.logoRight')
    const configDefaultCadastroCardHeader = config.get('default.cadastroCardHeader')
    return (
      <Grid container>
        <Grid item xs={3}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            {configDefaultLogoLeft && <img alt={configDefaultAltLogoLeft} src={require(`../../../assets/imagens/${configDefaultLogoLeft}`)} />}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container className={classes.content} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Card>
                <CardMedia
                  component="img"
                  alt="Cadastro"
                  className={classes.media}
                  height="140"
                  image={require(`../../../assets/imagens/login/login-card-header-cadastro.png`)}
                  title="Contemplative Reptile"
                />
                <CardContent height="400">
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                      <Stepper activeStep={activeStep} orientation="vertical">
                        {this.getSteps().map((label, index) => this.getStepContent(label, index))}
                      </Stepper>
                    </Grid>
                    {this.contentNavButton()}
                  </Grid>
                </CardContent>
              </Card>
              {/* Dialogs - Finalizar pedido */}
              <Dialog
                open={dialogCliente.open}
                onClose={onCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{dialogCliente.title}</DialogTitle>
                <DialogContent>{dialogCliente.msg}</DialogContent>
                <DialogActions>
                  <Button onClick={onCloseDialog} color="primary">
                    Fechar
                  </Button>
                </DialogActions>
              </Dialog>
              {/* Dialogs - Validação */}
              {dialogValidacao && (
                <Dialog
                  open={dialogValidacao.open}
                  onClose={onCloseDialogValidacao}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{dialogValidacao.title}</DialogTitle>
                  <DialogContent>
                    {dialogValidacao.msg.map((item, i) => (
                      <div key={i}>
                        <span>O campo </span>
                        <strong>{item.field}</strong>
                        <span>{`, ${item.msg}`}</span>
                        <br />
                      </div>
                    ))}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onCloseDialogValidacao} color="primary">
                      Fechar
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            {configDefaultLogoRight && <img alt={configDefaultAltLogoRight} src={require(`../../../assets/imagens/${configDefaultLogoRight}`)} />}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.cliente.loading,
  buscando: state.cliente.buscando,
  formCadastro: state.cliente.formCadastro,
  dialogCliente: state.cliente.dialogCliente,
  dialogValidacao: state.cliente.dialogValidacao,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  ...ClienteActions,
  ...ConfigActions,
}, dispatch)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NovoClientePage),
)
