import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Typography, Paper, Tabs, Tab, CssBaseline, Grid,
} from '@material-ui/core'
import _ from 'lodash'

import { Actions as UsuarioActions } from '../../../../store/ducks/usuario'
import { Actions as DialogActions } from '../../../../store/ducks/dialog'
import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as GestorUsuariosActions } from '../../../../store/ducks/gestor/usuarios'
import GestorHeaderComp from '../../../../components/Gestor/Header'
import GestorDrawerComp from '../../../../components/Gestor/Drawer'
import {
  DadosUsuarioTabContent,
  DadosUnidadesTabContent,
  DadosClientesTabContent,
  DadosPerfilsTabContent,
} from './components'

import useStyles from './styles'

const TabsContent = ({ tabSelected }) => {
  switch (tabSelected) {
    case 1:
      return <DadosUnidadesTabContent />
    case 2:
      return <DadosClientesTabContent />
    case 3:
      return <DadosPerfilsTabContent />
    default:
      return <DadosUsuarioTabContent />
  }
}
TabsContent.propTypes = {
  tabSelected: PropTypes.number.isRequired,
}

const GestorUsuariosDetalhePage = (props) => {
  const classes = useStyles()
  const [tabSelected, setTabSelected] = useState(0)
  // const [isNewUser, setIsNewUser] = useState(false)
  const handleSelectTab = (event, newValue) => setTabSelected(newValue)
  const {
    match: { params },
    usuario,
    gestorUsuarios,
    onLoadDataUsuario,
    onResetDataUsuario,
    onFieldUpdateUsuarioGestor,
  } = props
  const isNewUser = (gestorUsuarios.usuario
    && gestorUsuarios.usuario.form
    && !gestorUsuarios.usuario.form.id)
  const pageSubtitle = !isNewUser
    ? `[${gestorUsuarios.usuario.form.id}] ${gestorUsuarios.usuario.form.nome}`
    : ''
  // Consultado dados do usuário
  useEffect(() => {
    if (params.id) {
      onLoadDataUsuario(params.id)
    } else {
      // setIsNewUser(true)
      onResetDataUsuario()
      onFieldUpdateUsuarioGestor({ status: 'P', statusDesc: 'PENDENTE' })
    }
  }, [])
  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" component="h2">
                  Usuários
                </Typography>
                <Typography variant="h6" component="h6">
                  {gestorUsuarios.loading.usuario ? 'Carregando...' : pageSubtitle}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper className={classes.root}>
              <Tabs
                value={tabSelected}
                onChange={handleSelectTab}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Dados" />
                <Tab label="Unidades" disabled={isNewUser} />
                <Tab label="Clientes" disabled={isNewUser} />
                <Tab label="Perfils" disabled={isNewUser} />
              </Tabs>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <TabsContent tabSelected={tabSelected} />
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

GestorUsuariosDetalhePage.propTypes = {
  usuario: PropTypes.shape().isRequired,
  gestorUsuarios: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  params: PropTypes.shape(),
  push: PropTypes.func.isRequired,
  onLoadDataUsuario: PropTypes.func.isRequired,
  onResetDataUsuario: PropTypes.func.isRequired,
  onFieldUpdateUsuarioGestor: PropTypes.func.isRequired,
}

GestorUsuariosDetalhePage.defaultProps = {
  params: { id: 0 },
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  gestorUsuarios: state.gestorUsuarios,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...GestorUsuariosActions,
    ...UsuarioActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GestorUsuariosDetalhePage)
