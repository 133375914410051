import { API_URL } from '..'
import { httpParseJson, httpValidState, httpValidErros } from '../../utils/http'

const ref = '/usuarios'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const getUsuariosListApi = ({
  token, status = 'L', perfil = '', search,
}) => fetch(`${API_URL}${ref}?status=${status}&perfil=${perfil}&search=${search}`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const getUserDataApi = ({ id, token }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const onUserForgotApi = dto => fetch(`${API_URL}${ref}/forgot`, {
  method: 'POST',
  headers: {
    ...headers,
  },
  body: JSON.stringify(dto),
})
  .then(res => httpValidState(res, true))
  .catch(httpValidErros)

export const onUserNewPswApi = dto => fetch(`${API_URL}${ref}/newpsw`, {
  method: 'PUT',
  headers: {
    ...headers,
  },
  body: JSON.stringify(dto),
})
  .then(res => httpValidState(res, true))
  .catch(httpValidErros)

export const onUserNewPswLoggedApi = dto => fetch(`${API_URL}${ref}/newpsw/logged`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  },
  body: JSON.stringify(dto),
})
  .then(res => httpValidState(res, true))
  .catch(httpValidErros)

// Utilizado tbm na alcada para liberar novos usuários...
export const onAprovarReprovarUsuarioApi = ({ token, payload }) => fetch(`${API_URL}${ref}/liberacao`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then((res) => {
    if (res && res.status !== 204) {
      return res.json()
    }
    return res
  })
  .then(httpValidState)
  .catch(httpValidErros)

// Área do Gestor
// ---------------

// Aprovações
export const FilterAprovacoesUsuarioApi = ({
  token, st = 'L', q = '', f = 0,
}) => fetch(`${API_URL}${ref}/aprovacoes/filter?st=${st}&q=${q}&f=${f}`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const DetalhesAprovacoesUsuarioApi = ({
  token, usuario = '', filial = '', cliente = 0,
}) => fetch(
  `${API_URL}${ref}/aprovacoes/detalhes?usuario=${usuario}&filial=${filial}&cliente=${cliente}`,
  {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  },
)
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

// Aprovadores
export const FilterAprovadoresAlcadaApi = ({
  token,
  status = 'L',
  filial = '',
  usuario = '',
  search = '',
}) => {
  let queryUrl = `/aprovadores?status=${status}`
  queryUrl = filial ? `${queryUrl}&filial=${filial}` : queryUrl
  queryUrl = usuario ? `${queryUrl}&usuario=${usuario}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl

  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

export const onAddAprovadorApi = ({ token, dto }) => fetch(`${API_URL}${ref}/aprovadores`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const onEditAprovadorApi = ({ token, dto, id }) => fetch(`${API_URL}${ref}/aprovadores/${id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const onDeleteAprovadorApi = ({ token, id }) => fetch(`${API_URL}${ref}/aprovadores/${id}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const FilterUsuariosApi = ({
  token,
  status = '',
  perfil = '',
  search = '',
  page = 0,
  rows = 50,
}) => {
  let queryUrl = `filter?status=${status}`
  queryUrl = perfil ? `${queryUrl}&perfil=${perfil}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl
  queryUrl = page ? `${queryUrl}&page=${page}` : queryUrl
  queryUrl = rows ? `${queryUrl}&rows=${rows}` : queryUrl
  return fetch(`${API_URL}${ref}/gestor/${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}
export const FilterUsuariosPaginatorApi = (dto) => {

  const { status, perfil, search, page, rows } = dto.query;
  const { token } = dto;
  let queryUrl = `filter/pagination?`
  queryUrl = perfil ? `${queryUrl}&perfil=${perfil}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl
  queryUrl = page ? `${queryUrl}&page=${page}` : queryUrl
  queryUrl = rows ? `${queryUrl}&rows=${rows}` : queryUrl
  console.log(`${API_URL}${ref}/gestor/${queryUrl}`)
  const headers = {
    // adicione as propriedades de cabeçalho aqui
  };

  return fetch(`${API_URL}${ref}/gestor/${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { id: 0, nome: '', email: '', cpf: '' }
 */
export const onAddUsuarioApi = ({ token, dto }) => fetch(`${API_URL}${ref}`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { id: 0, nome: '', email: '', cpf: '' }
 * @param {Integer} id - ID do usuário
 */
export const onEditUsuarioApi = ({ token, dto, id }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { id: 0, nome: '', email: '', cpf: '' }
 */
export const onUpdateUsuarioGestorApi = ({ token, dto }) => fetch(`${API_URL}${ref}/gestor/${dto.id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do usuário, fk: ID da unidade }
 */
export const onAddUsuarioUnidadeApi = ({ token, dto }) => fetch(`${API_URL}${ref}/unidade`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const onDeleteUsuarioUnidadeApi = ({ token, usuario = 0, unidade = 0 }) => fetch(`${API_URL}${ref}/unidade/${unidade}?usuario=${usuario}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do usuário, fk: ID do cliente }
 */
export const onAddUsuarioClienteApi = ({ token, dto }) => fetch(`${API_URL}${ref}/cliente`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const onDeleteUsuarioClienteApi = ({ token, usuario = 0, cliente = 0 }) => fetch(`${API_URL}${ref}/cliente/${cliente}?usuario=${usuario}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do usuário, fk: ID do perfil }
 */
export const onAddUsuarioPerfilApi = ({ token, dto }) => fetch(`${API_URL}${ref}/perfil`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const onDeleteUsuarioPerfilApi = ({ token, usuario = 0, perfil = 0 }) => fetch(`${API_URL}${ref}/perfil/${perfil}?usuario=${usuario}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)
