import React, { useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  Drawer,
  Icon,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import _ from 'lodash'

import useStyles from './styles'

const DrawerComp = (props) => {
  const { data } = props
  const classes = useStyles()
  const [dataSorted, setDataSorted] = useState([])
  const [menuNested, setMenuNested] = useState([])

  const handleOpenClose = (m) => {
    setMenuNested(menuNested.map(s => (s.id === m.id ? { ...s, open: !s.open } : { ...s })))
  }

  useEffect(() => {
    const aDataSorted = _.orderBy(data, ['ordem'], ['asc'])
    const aMenuNested = aDataSorted.filter(p => !p.root).map(m => ({ ...m, open: false }))
    setDataSorted(aDataSorted)
    setMenuNested(aMenuNested)
  }, [data])

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />
        <List component="nav">
          {menuNested
            .filter(p => !p.root && p.status === 'L') // Apenas os roots
            .map((r) => {
              const aSubs = dataSorted.filter(c => c.root === r.id && c.status === 'L')
              return aSubs.length > 0 ? (
                <Fragment key={r.id}>
                  <Divider />
                  <ListItem button onClick={() => handleOpenClose(r)}>
                    <ListItemIcon>
                      <Icon>{r.icone}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={r.descricao} />
                    {r.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={r.open} timeout="auto" unmountOnExit>
                    {aSubs.map(sm => (
                      <List key={sm.id} component="div" disablePadding>
                        <ListItem
                          button
                          component="a"
                          href={sm.url}
                          style={{ display: 'flex' }}
                          className={classes.nested}
                        >
                          <ListItemIcon>
                            <Icon>{sm.icone}</Icon>
                          </ListItemIcon>
                          <ListItemText primary={sm.descricao} />
                        </ListItem>
                      </List>
                    ))}
                  </Collapse>
                  <Divider />
                </Fragment>
              ) : (
                <ListItem key={r.id} button component="a" href={r.url}>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <ListItemIcon>
                      <Icon>{r.icone}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={r.descricao} />
                  </Grid>
                </ListItem>
              )
            })}
        </List>
        <small className={classes.version}>
          {`© ${process.env.REACT_APP_AUTHOR} - v${process.env.REACT_APP_VERSION}`}
        </small>
      </Drawer>
    </>
  )
}

DrawerComp.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
}

DrawerComp.defaultProps = {
  data: [],
}

const mapStateToProps = state => ({
  data: state.config.formularios,
})

export default connect(mapStateToProps)(DrawerComp)
