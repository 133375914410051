import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    Typography, Paper, Tabs, Tab, CssBaseline, Grid,
} from '@material-ui/core'
import _ from 'lodash'

import { Actions as UsuarioActions } from '../../../../../../store/ducks/usuario'
import { Actions as DialogActions } from '../../../../../../store/ducks/dialog'
import { Actions as ConfigActions } from '../../../../../../store/ducks/config'
import { Actions as GestorUsuariosActions } from '../../../../../../store/ducks/gestor/usuarios'
import GestorHeaderComp from '../../../../../../components/Gestor/Header'
import GestorDrawerComp from '../../../../../../components/Gestor/Drawer'

import useStyles from './styles'
import VisitaDetailDadosTab from './abas/VisitaDetailDadosTab'
import VisitaUcDetailTab from './abas/VisitaUcDetailTab'
import VisitaClienteDetailTab from './abas/VisitaClienteDetailTab'
import VisitaProdutosDetailTab from './abas/VisitaProdutosDetailTab'
import VisitaImgDetailTab from './abas/VisitaImgDetailTab'


const VisitaDetailContent = (props) => {
    const classes = useStyles()
    const [tabSelected, setTabSelected] = useState(0)
    const handleSelectTab = (event, newValue) => setTabSelected(newValue)
    const {
        match: { params },
        usuario,
    } = props
    const TabsContent = ({ tabSelected }) => {
        switch (tabSelected) {
            case 0:
                return <VisitaDetailDadosTab
                    usuario={usuario}
                    params={params}
                />
            case 1:
                return <VisitaUcDetailTab
                    usuario={usuario}
                    params={params}
                />
            case 2:
                return <VisitaClienteDetailTab
                    usuario={usuario}
                    params={params}
                />
            case 3:
                return <VisitaProdutosDetailTab
                    usuario={usuario}
                    params={params}
                />
            case 4:
                return <VisitaImgDetailTab
                    usuario={usuario}
                    params={params}
                />
            default:
                return <VisitaDetailDadosTab />
        }
    }
    TabsContent.propTypes = {
        tabSelected: PropTypes.number.isRequired,
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
            <GestorDrawerComp {...props} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Typography variant="h4" gutterBottom>
                    Detalhamento da visita
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.root}>
                            <Tabs
                                value={tabSelected}
                                onChange={handleSelectTab}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label="Dados Visita" />
                                <Tab label="Unidade Consumidora" />
                                <Tab label="Cliente" />
                                <Tab label="Oferta" />
                                <Tab label="Fotos" />
                            </Tabs>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TabsContent tabSelected={tabSelected} />
                    </Grid>
                </Grid>
            </main>
        </div>
    )
}
const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        ...ConfigActions,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VisitaDetailContent)