import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from '../..'

const ref = '/crm/contatos'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - { token: String, page, rows, search }
 */
export const filterCrmContatosApi = (dto) => {
  const { token, query: { page = 0, rows = 50, search = '' } } = dto
  return fetch(`${API_URL}${ref}?page=${page}&rows=${rows}&search=${search}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, id }
 */
export const findByIdCrmContatosApi = (dto) => {
  const { token, id } = dto
  return fetch(`${API_URL}${ref}/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const addCrmContatosApi = ({ token, payload }) => fetch(`${API_URL}${ref}`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const editCrmContatosApi = ({ token, id, payload }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do produto, fk: ID da categoria }
 */
export const delCrmContatosApi = ({ token, id }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)
