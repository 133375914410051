import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
  Typography,
  Card,
  Grid,
  Fab,
  Icon,
  Tooltip,
  TextField,
  InputAdornment,
  IconButton,
  CssBaseline,
  Input,
  Button,
  Paper,
  Divider,
} from '@material-ui/core'

// import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import GestorHeaderComp from '../../../../../../components/Gestor/Drawer'
import GestorDrawerComp from '../../../../../../components/Gestor/Drawer'
import TableDataComp from '../../../../../../components/Table'
import useStyles from './styles'
import { getVisitasFilterApi } from 'api/visitas'
import { DialogContentSimples } from 'components/Dialogs/Contents'
import ReactPaginate from 'react-paginate'
import { searchClienteApi } from 'api/cliente'
import { findByIdCrmVistoriasApi } from 'api/crm/vistorias'
import { Save } from '@material-ui/icons'

const DadosVistoriaTabContent = (props) => {
  const {
    usuario,
    push,
    params
  } = props
  const [vistoria, setVistoria] = useState([])


  const classes = useStyles()
  // State
  const fetchDataById = async (token, id) => {
    try {
      const response = await findByIdCrmVistoriasApi({ token: token, id: id });
      setVistoria(response)
      return response.data; // Retorna os dados da resposta
    } catch (error) {
      console.error('Erro ao buscar os dados por ID:', error);
      return null;
    }
  };
  const onUpdateVistoriaField = (field, value) => {
    setVistoria((prevVistoria) => ({
      ...prevVistoria,
      [field]: value,
    }));
  };

  const saveVistoria = () => {
    // Implemente a lógica para salvar a vistoria aqui
  };

  useEffect(() => {
    document.title = 'Online - Vistoria Detalhe'
    document.body.style = 'background-image: none;'
    fetchDataById(usuario?.auth?.token, params.id)

  }, [])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Grid container spacing={2}>
          {/* Body */}
          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={1}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="statusDescricao"
                    label="Descrição do Status"
                    margin="normal"
                    variant="outlined"
                    value={vistoria.statusDescricao ? vistoria.statusDescricao.toString().toUpperCase() : ''}
                    className={classes.textField}
                    onChange={(e) => onUpdateVistoriaField('statusDescricao', e.target.value)}
                    InputProps={{
                      style: {
                        color: 'black',
                        cursor: 'not-allowed'
                      },
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="dataAgenda"
                    label="Data da Agenda"
                    margin="normal"
                    variant="outlined"
                    value={vistoria.dataAgenda ? vistoria.dataAgenda.toString().toUpperCase() : ''}
                    className={classes.textField}
                    onChange={(e) => onUpdateVistoriaField('dataAgenda', e.target.value)}
                    InputProps={{
                      style: {
                        color: 'black',
                        cursor: 'not-allowed'
                      },
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="unidadeConsumidoraNumero"
                    label="Número da Unidade Consumidora"
                    margin="normal"
                    variant="outlined"
                    value={vistoria.unidadeConsumidoraNumero ? vistoria.unidadeConsumidoraNumero.toString().toUpperCase() : ''}
                    className={classes.textField}
                    onChange={(e) => onUpdateVistoriaField('unidadeConsumidoraNumero', e.target.value)}
                    InputProps={{
                      style: {
                        color: 'black',
                        cursor: 'not-allowed'
                      },
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider variant="middle" className={classes.customDivider} />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="agenteNome"
                    label="Nome do Agente"
                    margin="normal"
                    variant="outlined"
                    value={vistoria.agenteNome ? vistoria.agenteNome.toString().toUpperCase() : ''}
                    className={classes.textField}
                    onChange={(e) => onUpdateVistoriaField('agenteNome', e.target.value)}
                    InputProps={{
                      style: {
                        color: 'black',
                        cursor: 'not-allowed'
                      },
                    }}
                    disabled
                  />
                </Grid>

                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="descricao"
                    label="Descrição"
                    margin="normal"
                    variant="outlined"
                    value={vistoria.descricao ? vistoria.descricao.toString().toUpperCase() : ''}
                    className={classes.textField}
                    onChange={(e) => onUpdateVistoriaField('descricao', e.target.value)}
                    InputProps={{
                      style: {
                        color: 'black',
                        cursor: 'not-allowed'
                      },
                    }}
                    disabled
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="clienteNome"
                    label="Nome do Cliente"
                    margin="normal"
                    variant="outlined"
                    value={vistoria.clienteNome ? vistoria.clienteNome.toString().toUpperCase() : ''}
                    className={classes.textField}
                    onChange={(e) => onUpdateVistoriaField('clienteNome', e.target.value)}
                    InputProps={{
                      style: {
                        color: 'black',
                        cursor: 'not-allowed'
                      },
                    }}
                    disabled
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="unidadeConsumidoraSubbairroNome"
                    label="Subbairro"
                    margin="normal"
                    variant="outlined"
                    value={vistoria.unidadeConsumidoraSubbairroNome ? vistoria.unidadeConsumidoraSubbairroNome.toString().toUpperCase() : ''}
                    className={classes.textField}
                    onChange={(e) => onUpdateVistoriaField('unidadeConsumidoraSubbairroNome', e.target.value)}
                    InputProps={{
                      style: {
                        color: 'black',
                        cursor: 'not-allowed'
                      },
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider variant="middle" className={classes.customDivider} />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="parecerObservacao"
                    label="Parecer Observação"
                    margin="normal"
                    variant="outlined"
                    value={vistoria.parecerObservacao ? vistoria.parecerObservacao.toString().toUpperCase() : 'SEM OBSERVAÇÃO'}
                    className={classes.textField}
                    onChange={(e) => onUpdateVistoriaField('parecerObservacao', e.target.value)}
                    InputProps={{
                      style: {
                        color: 'black',
                        cursor: 'not-allowed'
                      },
                    }}
                    disabled
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="oportunidadePositivada"
                    label="Parecer Tecnico"
                    margin="normal"
                    variant="outlined"
                    value={vistoria.parecer ?vistoria.parecer.toString().toUpperCase() : 'SEM PARECER'}
                    className={classes.textField}
                    onChange={(e) => onUpdateVistoriaField('oportunidadePositivada', e.target.value)}
                    InputProps={{
                      style: {
                        color: 'black',
                        cursor: 'not-allowed'
                      },
                    }}
                    disabled
                  />
                </Grid>

                {/* <Grid item xs={4}>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={6}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={saveVistoria}
                  >
                    <Save className={classes.leftIcon} />
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid> */}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </main>
    </div>

  )
}

DadosVistoriaTabContent.propTypes = {
  loading: PropTypes.shape().isRequired,
  gestorUsuarios: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onAddUsuarioCliente: PropTypes.func.isRequired,
  onDeleteUsuarioCliente: PropTypes.func.isRequired,
  onSearchUsuarioCliente: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  gestorUsuarios: state.gestorUsuarios,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    push: crrPush,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DadosVistoriaTabContent)
