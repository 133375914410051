import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from '../..'

const ref = '/pagamento/forma'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - { token: String, query: Object }
 */
export const filterPagamentoFormaApi = (dto) => {
  const {
    token,
    query: {
      page = 0, rows = 50, search = '', ativo, sku,
    },
  } = dto

  let queryUrl = '?1=1'
  queryUrl += page ? `&page=${page}` : ''
  queryUrl += rows ? `&rows=${rows}` : ''
  queryUrl += search ? `&search=${search}` : ''
  queryUrl += ativo ? `&ativo=${ativo}` : ''
  queryUrl += sku ? `&sku=${sku}` : ''

  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, id }
 */
export const findByIdTabelaPrecoApi = (dto) => {
  const { token, id } = dto
  return fetch(`${API_URL}${ref}/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const addTabelaPrecoApi = ({ token, payload }) => fetch(`${API_URL}${ref}`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const editTabelaPrecoApi = ({ token, id, payload }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do produto, fk: ID da categoria }
 */
export const delTabelaPrecoApi = ({ token, id }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)
