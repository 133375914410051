import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Icon,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  TableContainer,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import useStyles from './styles'

const TableHeadCuston = withStyles(() => ({
  root: {
    backgroundColor: 'orange',
  },
}))(TableHead)

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const TableActionsComp = ({ row, actions }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }
  const classes = useStyles()
  return (
    <>
      <Tooltip title="AÇÕES">
        <IconButton
          size="small"
          aria-label="Editar"
          className={classes.margin}
          onClick={handleClick}
        >
          <Icon>more_vert</Icon>
        </IconButton>
      </Tooltip>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            minWidth: 200,
          },
        }}
      >
        {actions.map(action => (
          <MenuItem
            key={action.key}
            onClick={() => action.action(row)}
            disabled={_.isFunction(action.disabled) ? action.disabled(row) : action.disabled}
          >
            <ListItemIcon>
              <Icon>{action.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={action.title} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

TableActionsComp.propTypes = {
  row: PropTypes.shape().isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func,
      ]),
    }),
  ),
}

TableActionsComp.defaultProps = {
  actions: {
    disabled: false,
    onDblClick: () => null,
  },
}

const TableDataComp = (props) => {
  const {
    keyList, size, rows, cols, actions, loading,
  } = props
  const classes = useStyles()
  return (
    <TableContainer component={Paper}>
      <Table size={size || 'medium'}>
        <TableHeadCuston className={classes.tableHeader}>
          <TableRow>
            {!_.isEmpty(actions) && <StyledTableCell>AÇÕES</StyledTableCell>}
            {cols?.map(c => (
              <StyledTableCell key={c.key}>{c.title}</StyledTableCell>
            ))}
          </TableRow>
        </TableHeadCuston>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell component="th" scope="row" colSpan={cols.length}>
                <CircularProgress className={classes.progress} color="primary" />
              </TableCell>
            </TableRow>
          )}
          {(!loading && !_.isArray(rows))
            || (!loading && _.isEmpty(rows) && (
              <TableRow>
                <TableCell component="th" scope="row" colSpan={cols.length}>
                  Sem registro(s).
                </TableCell>
              </TableRow>
            ))}
          {!loading
            && _.isArray(rows)
            && rows.map(row => (
              <TableRow hover key={row[keyList]}>
                {!_.isEmpty(actions) && (
                  <TableCell component="th" scope="row" className={classes.colAction}>
                    <TableActionsComp row={row} actions={actions} />
                  </TableCell>
                )}
                {cols.map((c) => {
                  const onClick = _.isFunction(c.onClick) ? () => c.onClick(row) : null
                  const onDoubleClick = _.isFunction(c.onDoubleClick)
                    ? () => c.onDoubleClick(row) : null
                  const linkStyle = _.isFunction(onClick) || _.isFunction(c.onDoubleClick)
                    ? classes.link : null
                  if (c.key.includes('.')) {
                    const arrData = c.key.split('.')
                    return (
                      <TableCell key={c.key}>
                        {_.isArray(row[arrData[0]])
                          ? row[arrData[0]].map(d => d[arrData[1]]).join(', ')
                          : row[arrData[0]][arrData[1]]}
                      </TableCell>
                    )
                  }
                  if (_.isFunction(c.format)) {
                    return (
                      <TableCell
                        key={c.key}
                        className={linkStyle}
                        onClick={onClick}
                        onDoubleClick={onDoubleClick}
                        align={c.align || 'left'}
                      >
                        {c.format(row)}
                      </TableCell>
                    )
                  }
                  // return <TableCell key={c.key}>{row[c.key]}</TableCell>
                  return (
                    <TableCell
                      key={c.key}
                      className={linkStyle}
                      onClick={onClick}
                      align={c.align || 'left'}
                    >
                      {row[c.key]}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

TableDataComp.propTypes = {
  keyList: PropTypes.string,
  size: PropTypes.string,
  cols: PropTypes.arrayOf(PropTypes.shape()),
  rows: PropTypes.arrayOf(PropTypes.shape()),
  actions: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
}

TableDataComp.defaultProps = {
  keyList: 'id',
  size: 'medium',
  cols: [],
  rows: [],
  actions: [],
  loading: false,
}

export default TableDataComp
