const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  buttonDark: {
    backgroundColor: theme.palette.primary.light,
    color: theme.semar.text.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
})

export default styles
