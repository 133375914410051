import PropTypes from 'prop-types'

const Visibility = ({
  show = true,
  children,
  replacement = null,
}) => (show ? children : replacement)

Visibility.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
}

Visibility.defaultProps = {
  loading: false,
  disabled: false,
}

export default Visibility
