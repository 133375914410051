import React from 'react'
import PropTypes from 'prop-types'
import { DialogContentText } from '@material-ui/core'

// import useStyles from './styles'

const DialogContentSimples = (props) => {
  const { text } = props
  return <DialogContentText>{text}</DialogContentText>
}

DialogContentSimples.propTypes = {
  text: PropTypes.string.isRequired,
}

export default DialogContentSimples
