import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { matchSorter } from 'match-sorter'

import styles from './styles'

const FormaCondPgtoDialog = ({
  classes, open, title, onClose, onConfirm, onChange, data,
}) => {
  const {
    pagamentoForma = {},
    pagamentoFormas = [],
    pagamentoCondicao = {},
    pagamentoCondicoes = [],
  } = data

  const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: ['sku', 'descricao'] })
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Grid
          container
          className={classes.root}
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10}>
            <Autocomplete
              id="combo-box-formas-pgto"
              name="pagamentoForma"
              fullWidth
              options={pagamentoFormas}
              value={pagamentoForma}
              getOptionLabel={option => `[${option.sku}] ${option.descricao}`}
              filterOptions={filterOptions}
              getOptionSelected={(option, value) => option.sku === value.sku}
              onChange={(event, newValue) => onChange('pagamentoForma', newValue)}
              renderInput={params => (
                <TextField {...params} fullWidth label="Formas de pagamento" variant="outlined" />
              )}
              renderOption={option => (
                <div style={{ display: 'grid' }}>
                  <span>{`[${option.sku}] ${option.descricao}`}</span>
                </div>
              )}
            />
          </Grid>
          <Grid item xs={10}>
            <Autocomplete
              id="combo-box-condicoes-pgto"
              name="pagamentoCondicao"
              fullWidth
              disabled={pagamentoCondicoes.length === 0}
              options={pagamentoCondicoes}
              value={pagamentoCondicoes.length === 0 ? null : pagamentoCondicao}
              getOptionLabel={option => `[${option.sku}] ${option.descricao}`}
              filterOptions={filterOptions}
              getOptionSelected={(option, value) => option.sku === value.sku}
              onChange={(event, newValue) => onChange('pagamentoCondicao', newValue)}
              renderInput={params => (
                <TextField {...params} fullWidth label="Condições" variant="outlined" />
              )}
              renderOption={option => (
                <div style={{ display: 'grid' }}>
                  <span>{`[${option.sku}] ${option.descricao}`}</span>
                </div>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

FormaCondPgtoDialog.propTypes = {
  classes: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
}

export default withStyles(styles)(FormaCondPgtoDialog)
