import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Numeral from 'numeral'
import moment from 'moment'

import PedidoHeaderComp from './Header'
import useStyles from './styles'

function PedidoDetalhesLinhaStatusComp(props) {
  const { pedido = {}, params = {} } = props
  const { kClientesEntregas = 'NAO', kPedDetEtapa2 = 'EM PROCESSAMENTO' } = params
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      {/* - Etapas do pedido (GRAFICO) */}
      <PedidoHeaderComp
        pedido={pedido}
        activeStep={pedido?.statusId ?? 0}
        labelStep2={kPedDetEtapa2}
      />
      {/* - Resumo do pedido */}
      <Table size="small" className={classes.table}>
        {/* - Grafico (steps) */}
        <TableHead>
          <TableRow>
            <TableCell>EMISSÃO</TableCell>
            <TableCell>UNIDADE</TableCell>
            {kClientesEntregas === 'SIM' && (
              <TableCell padding="none">C. ENTREGA</TableCell>
            )}
            <TableCell padding="none" align="center">
              QTD. TOTAL
            </TableCell>
            <TableCell padding="none" align="center">
              PESO TOTAL
            </TableCell>
            <TableCell align="right">TOTAL</TableCell>
            <TableCell>Cliente Nome</TableCell>
            <TableCell>Unid. Consumidora Número</TableCell>
            <TableCell>Bairro</TableCell>
            <TableCell>Subbairro</TableCell>
          </TableRow>
        </TableHead>
        {/* - Resumo do pedido */}
        <TableBody>
          <TableRow>
            <TableCell>
              {moment(pedido?.emissao, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}
            </TableCell>
            <TableCell>{pedido?.unidadeDescricao ?? ''}</TableCell>
            {kClientesEntregas === 'SIM' && (
              <TableCell padding="none">
                {pedido.clienteEntregaCodigo
                  ? `${pedido.clienteEntregaCodigo}/${pedido.clienteEntregaLoja}-${pedido.clienteEntregaDescricao}`
                  : ''}
              </TableCell>
            )}
            <TableCell align="center">
              {`${pedido?.itens?.reduce((a, b) => a + b.produtoQuant, 0) ?? 0}`}
            </TableCell>
            <TableCell padding="none" align="center">
              {`${Numeral(
                pedido?.itens?.reduce(
                  (a, b) => a + b.produtoPesoUnit * b.produtoQuant,
                  0,
                ),
              ).format('0,0.00')}kg`}
            </TableCell>
            <TableCell align="right">
              {`R$ ${Numeral(pedido?.total ?? 0).format('0,0.00')}`}
            </TableCell>
            <TableCell>{pedido?.clienteNome ?? ''}</TableCell>
            <TableCell>{pedido?.unidadeConsumidoraUnidNumero ?? ''}</TableCell>
            <TableCell>{pedido?.unidadeConsumidoraBairroNome ?? ''}</TableCell>
            <TableCell>{pedido?.unidadeConsumidoraSubbairroNome ?? ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={10}>{`OBSERVAÇÃO: ${pedido?.obsnf ?? ''}`}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

    </Paper>
  )
}

PedidoDetalhesLinhaStatusComp.propTypes = {
  pedido: PropTypes.shape().isRequired,
  params: PropTypes.shape(),
}

PedidoDetalhesLinhaStatusComp.defaultProps = {
  params: { kClientesEntregas: 'NAO', kPedDetEtapa2: 'EM PROCESSAMENTO' },
}

export default PedidoDetalhesLinhaStatusComp
