const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingTop: 10,
    marginRight: 20,
  },
  buttonLeftIton: {
    marginRight: 10,
  },
  buttonNav: {
    '&:link': {
      display: 'flex',
    },
  },
  buttonDark: {
    backgroundColor: theme.palette.primary.light,
    color: theme.semar.text.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonCarrinho: {
    color: theme.semar.text.white,
    backgroundColor: theme.semar.success.main,
    '&:hover': {
      backgroundColor: theme.semar.success.dark,
    },
  },
  formControl: {
    margin: 10,
    minWidth: 120,
  },
})

export default styles
