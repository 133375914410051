import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, CircularProgress } from '@material-ui/core';

function DialogSyncOportunidade({ open, onClose, onSave, token, payload, isLoading }) {
    const isMultiple = Array.isArray(payload);

    const renderPedidoInfo = (pedido) => (
        <div style={{ border: '1px solid #ddd', padding: '10px', marginBottom: '10px' }}>
            <strong>Nome:</strong> {pedido?.prospectRazao}<br />
            <strong>Total:</strong> {pedido?.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br />
            <strong>Unidade Consumidora Número:</strong>{pedido?.unidadeConsumidoraNumero}<br />
            <strong>Endereço:</strong>{pedido?.unidadeConsumidoraEndereco}<br />
            <strong>Número:</strong>{pedido?.unidadeConsumidoraEnderecoNumero}<br />
            <h4>Itens:</h4>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th>Produto</th>
                        <th>Valor</th>
                        <th>Forma de Pagamento</th>
                    </tr>
                </thead>
                <tbody>
                    {pedido?.itens.map(item => (
                        <tr key={item?.id}>
                            <td>{item?.produtoDescricao}</td>
                            <td>{item?.valor?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td>{item?.pagamentoFormaDescricao}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    let messageContent;
    if (isMultiple) {
        messageContent = (
            <div>
                {payload.map(pedido => renderPedidoInfo(pedido))}
                <strong>Deseja gerar Pedido(s) de Venda?</strong>
            </div>
        );
    } else {
        messageContent = (
            <div>
                {renderPedidoInfo(payload)}
                <strong>Deseja gerar Pedido(s) de Venda?</strong>
            </div>
        );
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">{"Confirmação"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {messageContent}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        onSave(payload);
                    }}
                    color="primary"
                    autoFocus
                    disabled={isLoading}  // Desabilitar o botão quando isLoading for true
                >
                    {isLoading ? <CircularProgress size={24} /> : "Sim"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogSyncOportunidade;

