import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core'

// Components
import ButtonLoadingComp from '../../../../components/Buttons/ButtonLoading'

// Api
import { getRelatVendedorClientesApi } from '../../../../api/relatorios'

function DialogFiltroRelatorioComp(props) {
  const {
    open, title, maxWidth, onClose, usuario,
  } = props
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    dataInicial: '',
    dataFinal: '',
    dataInicialPedido: '',
    dataFinalPedido: '',
  })

  const handleClean = () => {
    setForm({
      dataInicial: '',
      dataFinal: '',
      dataInicialPedido: '',
      dataFinalPedido: '',
    })
  }

  const handleCancel = () => {
    onClose()
    handleClean()
  }

  const handleFiltrar = async () => {
    setLoading(true)
    const resp = await getRelatVendedorClientesApi({
      token: usuario.auth.token,
      filter: {
        visitaStart: form.dataInicial,
        visitaEnd: form.dataFinal,
      },
    })

    if (resp) {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href = URL.createObjectURL(
        new Blob([await resp.blob()], {
          type: 'application/vnd.ms-excel',
        }),
      )
      a.download = 'Relatorio_Visitas_Realizadas.xls'
      a.click()
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const handleIptValue = (evt, propName) => {
    if (evt && evt.target && evt.target.value) {
      const save = { [propName]: evt.target.value }
      setForm({ ...form, ...save })
    }
  }

  return (
    <Dialog fullWidth maxWidth={maxWidth} open={open} onClose={handleCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="dataInicial"
              label="Data inicial (visita)"
              type="date"
              value={form.dataInicial}
              onChange={evt => handleIptValue(evt, 'dataInicial')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="dataFinal"
              label="Data final (visita)"
              type="date"
              value={form.dataFinal}
              onChange={evt => handleIptValue(evt, 'dataFinal')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClean}>
          Limpar
        </Button>
        <Button size="small" onClick={handleCancel}>
          Cancelar
        </Button>
        <ButtonLoadingComp
          loading={loading}
          size="small"
          color="primary"
          variant="contained"
          onClick={handleFiltrar}
        >
          Filtrar
        </ButtonLoadingComp>
      </DialogActions>
    </Dialog>
  )
}

DialogFiltroRelatorioComp.propTypes = {
  usuario: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

DialogFiltroRelatorioComp.defaultProps = {
  maxWidth: 'xs',
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DialogFiltroRelatorioComp)
