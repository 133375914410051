import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Numeral from 'numeral'

import {
  Table, TableBody, TableCell, TableHead, TableRow, Paper, Button,
} from '@material-ui/core'
import XmlIcon from '@material-ui/icons/FileCopy'
import DownloadIcon from '@material-ui/icons/SaveAlt'
import CircularProgress from '@material-ui/core/CircularProgress'
import styles from './styles'

export const gridDanfeXML = (props) => {
  const {
    load, gerando, data = [], onDownloadDanfeXML,
  } = props
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Doc</TableCell>
            <TableCell>Série</TableCell>
            <TableCell>Emissão</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Xml</TableCell>
            <TableCell>Danfe</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {load ? (
            <TableRow>
              <TableCell>
                <CircularProgress size={16} />
                <span style={{ marginLeft: 5 }}>Filtrando...</span>
              </TableCell>
            </TableRow>
          ) : (
            data?.map(row => (
              <TableRow key={row.doc}>
                <TableCell>{row.doc}</TableCell>
                <TableCell>{row.serie}</TableCell>
                <TableCell>{moment(row.emissao, 'YYYYMMDD').format('DD/MM/YYYY')}</TableCell>
                <TableCell>
                  {`R$ ${Numeral(Number.parseFloat(row.valbrut)).format('0,0.00')}`}
                </TableCell>
                <TableCell>
                  <Button onClick={() => onDownloadDanfeXML(row, 'xml')}>
                    {gerando && gerando.doc === row.doc && gerando.tipo === 'xml' ? (
                      <CircularProgress size={16} />
                    ) : (
                      <XmlIcon />
                    )}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button onClick={() => onDownloadDanfeXML(row, 'danfe')}>
                    {gerando && gerando.doc === row.doc && gerando.tipo === 'danfe' ? (
                      <CircularProgress size={16} />
                    ) : (
                      <DownloadIcon />
                    )}
                  </Button>
                </TableCell>
              </TableRow>
            ))
          )}
          {data.length === 0 && !load && (
            <TableRow>
              <TableCell>Nenhum documento...</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  )
}

gridDanfeXML.propTypes = {
  load: PropTypes.bool.isRequired,
  gerando: PropTypes.shape().isRequired,
  data: PropTypes.arrayOf(),
  onDownloadDanfeXML: PropTypes.func.isRequired,
}
gridDanfeXML.defaultProps = {
  data: [],
}
