import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import {
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Badge,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core'

import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  List as ListIcon,
  Close as CloseIcon,
  FileCopy,
} from '@material-ui/icons'

import Header from '../../../components/Header'
import { Actions as UsuarioActions } from '../../../store/ducks/usuario'
import { Actions as PedidosActions } from '../../../store/ducks/pedidos'
import styles from './styles'

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge)
class PedidoPadraoPage extends Component {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    loading: PropTypes.shape().isRequired,
    usuario: PropTypes.shape().isRequired,
    pedidoPadrao: PropTypes.shape().isRequired,
    onLoadListPedidoPadrao: PropTypes.func.isRequired,
    onDeletePedidoPadraoApi: PropTypes.func.isRequired,
    getPedidoPadraoCarrinho: PropTypes.func.isRequired,
  }

  state = {
    search: '',
    dialogItensOpen: false,
    itens: [],
  }

  componentDidMount() {
    const { usuario, onLoadListPedidoPadrao } = this.props
    document.title = 'Online - Pedidos Padrão'
    onLoadListPedidoPadrao({ cliente: usuario.cliente.id })
  }

  handleOpenDialogItens = (itens = []) => this.setState(s => ({
    ...s,
    dialogItensOpen: !s.dialogItensOpen,
    itens,
  }))

  handleExcluirPedidoPadrao = (id = 0, descricao = '') => {
    if (window.confirm(`Você confirma a exclusão do pedido padrão "${descricao}"?`)) {
      const { usuario, onDeletePedidoPadraoApi, onLoadListPedidoPadrao } = this.props
      onDeletePedidoPadraoApi(id, () => onLoadListPedidoPadrao({ cliente: usuario.cliente.id }))
    }
  }

  render() {
    const { search, dialogItensOpen = false, itens = [] } = this.state
    const {
      usuario,
      loading,
      classes,
      pedidoPadrao: {
        list = [],
      } = {},
      onLoadListPedidoPadrao,
      getPedidoPadraoCarrinho,
    } = this.props
    return (
      <Fragment>
        <Header />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.root}
        >
          {/* GRID */}
          <Grid item xs={12}>
            <Card className={[classes.card, classes.grid].join(' ')}>
              <CardHeader
                title="Pedidos Padrão"
                action={(
                  <TextField
                    label="Pesquisar"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={search}
                    onChange={({ target: { value = '' } }) => this.setState(s => ({ ...s, search: value }))}
                    onKeyPress={(evt) => {
                      if (evt.charCode === 13) {
                        onLoadListPedidoPadrao({ cliente: usuario.cliente.id, search })
                      }
                    }}
                  />
                )}
              />
              <CardContent>
                <Paper className={classes.root}>
                  <Table className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Observação</TableCell>
                        <TableCell align="center">Criar</TableCell>
                        <TableCell align="center">Itens</TableCell>
                        <TableCell align="center">Excluir</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading?.loadListPedidoPadrao && (
                        <CircularProgress size={25} className={classes.loadingPadding} />
                      )}
                      {!loading?.loadListPedidoPadrao && list.map(row => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {`${row.id}-${row.descricao}`}
                          </TableCell>
                          <TableCell>
                            {row.observacao}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton size="small" aria-label="delete" color="primary" onClick={() => getPedidoPadraoCarrinho(row.id)}>
                              <FileCopy fontSize="inherit" />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton size="small" onClick={() => this.handleOpenDialogItens(row.itens)}>
                              <StyledBadge badgeContent={_.isArray(row.itens) ? row.itens.length : 0} color="primary">
                                <ListIcon />
                              </StyledBadge>
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              size="small"
                              aria-label="delete"
                              color="secondary"
                              onClick={() => this.handleExcluirPedidoPadrao(row.id, row.descricao)}
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* Dialog: Pedido Padrão */}
        <Dialog
          maxWidth="md"
          fullWidth
          open={dialogItensOpen}
          onClose={() => this.handleOpenDialogItens([])}
          aria-labelledby="itensPedidoPadrao"
        >
          <DialogTitle id="itensPedidoPadrao" disableTypography className={classes.titleRoot}>
            <Typography variant="h6">Itens</Typography>
            <IconButton aria-label="Close" className={classes.closeButton} onClick={() => this.handleOpenDialogItens([])}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Produto</TableCell>
                  <TableCell>Modalidade</TableCell>
                  <TableCell>Condição</TableCell>
                  <TableCell>Quantidade</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itens.map(i => (
                  <TableRow>
                    <TableCell>{i.produtoDescricao}</TableCell>
                    <TableCell>{i.tabelaPrecoDescricao}</TableCell>
                    <TableCell>{i.pagamentoCondicaoDescricao}</TableCell>
                    <TableCell>{i.quantidade}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  cliente: state.usuario.usuario.cliente,
  loading: state.pedidos.loading,
  pedidoPadrao: state.pedidos.pedidoPadrao,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  ...UsuarioActions,
  ...PedidosActions,
}, dispatch)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PedidoPadraoPage),
)
