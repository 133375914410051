import { push } from 'connected-react-router'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'

import {
  getUsuariosListApi,
  getUserDataApi,
  FilterUsuariosApi,
  FilterAprovacoesUsuarioApi,
  onAddUsuarioUnidadeApi,
  onDeleteUsuarioUnidadeApi,
  onAddUsuarioClienteApi,
  onDeleteUsuarioClienteApi,
  onAddUsuarioPerfilApi,
  onDeleteUsuarioPerfilApi,
  FilterAprovadoresAlcadaApi,
  onAddAprovadorApi,
  onEditAprovadorApi,
  onDeleteAprovadorApi,
  onAddUsuarioApi,
  onUpdateUsuarioGestorApi,
} from '../../../api/usuario'
import { searchPerfilApi } from '../../../api/perfil'
import { searchClienteApi } from '../../../api/cliente'
import { searchFilialApi } from '../../../api/empresa/filial'
import { Actions as DialogActions } from '../dialog'

// State Usuario
const initialState = {
  loading: {
    usuarios: false,
    aprovacoes: false,
    aprovadores: false,
    usuario: false,
    unidades: false,
  },
  aprovacoes: {
    list: [],
    form: {},
  },
  aprovadores: {
    list: [],
    form: {
      id: 0,
      usuarioId: 0,
      usuarioNome: '',
      filialId: 0,
      master: '',
      status: '',
    },
  },
  usuarios: [],
  usuario: {
    form: {
      id: 0,
      cpf: '',
      nome: '',
      email: '',
      status: '',
      statusDesc: '',
      createdDate: '',
      modifiedDate: '',
    },
    unidades: [],
    filiais: [],
    perfils: [],
  },
}

// Actions Types
export const Types = {
  GU_LOADING: 'gestorUsuario/LOADING',
  GU_UPDATE_USUARIOS: 'gestorUsuario/GU_UPDATE_USUARIOS',
  GU_UPDATE_APROVACOES: 'gestorUsuario/UPDATE_APROVACOES',
  GU_UPDATE_USUARIO: 'gestorUsuario/GU_UPDATE_USUARIO',
  GU_UPDATE_FORM_USUARIO: 'gestorUsuario/GU_UPDATE_FORM_USUARIO',
  GU_START_APROVADORES: 'gestorUsuario/GU_START_APROVADORES',
  GU_UPDATE_APROVADORES: 'gestorUsuario/GU_UPDATE_APROVADORES',
  GU_UPDATE_FORM_APROVADORES: 'gestorUsuario/GU_UPDATE_FORM_APROVADORES',
}

// Reducers
export default function gestorUsuarioReducers(state = initialState, action) {
  switch (action.type) {
    case Types.GU_LOADING:
      return { ...state, loading: { ...state.loading, ...action.payload } }
    case Types.GU_UPDATE_USUARIOS:
      return { ...state, usuarios: action.payload }
    case Types.GU_UPDATE_APROVACOES:
      return { ...state, aprovacoes: { ...state.aprovacoes, ...action.payload } }
    case Types.GU_UPDATE_USUARIO:
      return { ...state, usuario: { ...state.usuario, ...action.payload } }
    case Types.GU_UPDATE_FORM_USUARIO:
      return {
        ...state,
        usuario: { ...state.usuario, form: { ...state.usuario.form, ...action.payload } },
      }
    case Types.GU_START_APROVADORES:
      return {
        ...state,
        aprovadores: { ...initialState.aprovadores },
      }
    case Types.GU_UPDATE_APROVADORES:
      return {
        ...state,
        aprovadores: { ...state.aprovadores, ...action.payload },
      }
    case Types.GU_UPDATE_FORM_APROVADORES:
      return {
        ...state,
        aprovadores: {
          ...state.aprovadores,
          form: { ...state.aprovadores.form, ...action.payload },
        },
      }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  onLoading: status => (dispatch) => {
    dispatch({ type: Types.GU_LOADING, payload: status })
  },
  onLoadDataGu: (status = '', search = '') => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    dispatch(Actions.onLoading({ usuarios: true }))
    FilterUsuariosApi({
      token,
      status,
      search,
    }).then((payload) => {
      dispatch(Actions.onLoading({ usuarios: false }))
      dispatch({ type: Types.GU_UPDATE_USUARIOS, payload })
    })
  },
  onLoadDataGuAprovacoes: (st = 'P', q = '') => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
          unidade,
        },
      },
    } = getState()
    dispatch(Actions.onLoading({ aprovacoes: true }))
    FilterAprovacoesUsuarioApi({
      token,
      st,
      q,
      f: unidade.id,
    }).then((list) => {
      dispatch(Actions.onLoading({ aprovacoes: false }))
      dispatch({ type: Types.GU_UPDATE_APROVACOES, payload: { list } })
    })
  },
  onLoadDataUsuario: usuarioId => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    dispatch({ type: Types.GU_UPDATE_USUARIO, payload: initialState.usuario })
    if (_.isInteger(+usuarioId)) {
      dispatch(Actions.onLoading({ usuario: true }))
      getUserDataApi({ id: usuarioId, token }).then((payload) => {
        dispatch(Actions.onLoading({ ...initialState.loading }))
        if (payload) {
          // Parse
          const {
            id,
            cpf,
            nome,
            email,
            status,
            statusDesc,
            createdDate,
            modifiedDate,
            unidades,
            clientes,
            perfils,
          } = payload
          const usuario = {
            form: {
              id,
              cpf,
              nome,
              email,
              status,
              statusDesc,
              createdDate,
              modifiedDate,
            },
            unidades,
            clientes,
            perfils,
          }
          dispatch({ type: Types.GU_UPDATE_USUARIO, payload: usuario })
        }
      })
    } else {
      NotificationManager.warning(
        `O Código "${usuarioId}" do usuário está inválido!`,
        'Atenção:',
        8000,
      )
    }
  },
  onFieldUpdateUsuarioGestor: payload => (dispatch) => {
    dispatch({ type: Types.GU_UPDATE_FORM_USUARIO, payload })
  },
  onResetDataUsuario: () => (dispatch) => {
    dispatch({ type: Types.GU_UPDATE_FORM_USUARIO, payload: { ...initialState.usuario.form } })
  },
  onSaveUsuarioGestor: () => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
          unidade,
        },
      },
      gestorUsuarios: {
        usuario: {
          form: {
            id, nome, email, cpf, status,
          },
        },
      },
    } = getState()

    if (id) {
      onUpdateUsuarioGestorApi({
        token,
        dto: {
          id,
          nome,
          email,
          cpf,
        },
      }).then((payload) => {
        if (payload && payload.status === 201) {
          NotificationManager.success('Dados do usuário atualizado com sucesso!', 'Atenção:', 8000)
          dispatch(Actions.onLoadDataUsuario(id))
        } else {
          NotificationManager.warning(
            'Não foi possível adicionar o cliente ao usuário.',
            'Atenção:',
            8000,
          )
        }
      })
    } else {
      onAddUsuarioApi({
        token,
        dto: {
          filialId: unidade.id,
          nome,
          email,
          cpf,
          status,
        },
      }).then((payload) => {
        if (payload && payload.status === 200) {
          NotificationManager.success(
            'O usuário foi adicionando com sucesso!',
            'Sucesso!',
            4000,
          )
          dispatch(Actions.onLoadDataUsuario(payload.id))
        }
      })
    }
  },
  onSearchUsuarioUnidade: (q = '') => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    let success = true
    // Validações
    if (!token) {
      success = false
      NotificationManager.error(
        'Seu token está inválido, você deve fazer o login novamente.',
        'Atenção:',
        8000,
      )
      dispatch(push('/login'))
    }
    if (success) {
      dispatch(DialogActions.onSearchGenericLoading(true))
      searchFilialApi({ token, q }).then((payload) => {
        dispatch(DialogActions.onSearchGenericLoading(false))
        if (payload && _.isArray(payload)) {
          dispatch(
            DialogActions.onSearchGenericLoadData({
              page: 1,
              pages: 1,
              rows: payload.length,
              itens: payload,
            }),
          )
        } else {
          // Não retornou nada... o form é zerado!
          dispatch(DialogActions.onSearchGenericClean())
        }
      })
    }
  },
  onAddUsuarioUnidade: unidade => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
      gestorUsuarios: {
        usuario: { form },
      },
    } = getState()
    onAddUsuarioUnidadeApi({ token, dto: { pk: form.id, fk: unidade.id } }).then((payload) => {
      if (payload && payload.status === 201) {
        NotificationManager.success('Unidade adicionada com sucesso.', 'Atenção:', 8000)
        dispatch(Actions.onLoadDataUsuario(form.id))
      } else {
        NotificationManager.warning(
          'Não foi possível adicionar o cliente ao usuário.',
          'Atenção:',
          8000,
        )
      }
    })
  },
  onDeleteUsuarioUnidade: unidade => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
      gestorUsuarios: {
        usuario: { form },
      },
    } = getState()
    onDeleteUsuarioUnidadeApi({ token, usuario: form.id, unidade: unidade.id }).then((payload) => {
      if (payload && payload.status === 204) {
        NotificationManager.success('Unidade removida com sucesso.', 'Atenção:', 8000)
        dispatch(Actions.onLoadDataUsuario(form.id))
      } else {
        NotificationManager.warning(
          'Não foi possível remover esse perfil do usuário.',
          'Atenção:',
          8000,
        )
      }
    })
  },
  onSearchUsuarioCliente: search => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    let success = true
    // Validações
    if (!token) {
      success = false
      NotificationManager.error(
        'Seu token está inválido, você deve fazer o login novamente.',
        'Atenção:',
        8000,
      )
      dispatch(push('/login'))
    }
    if (success) {
      dispatch(DialogActions.onSearchGenericLoading(true))
      searchClienteApi({ token, search }).then((payload) => {
        dispatch(DialogActions.onSearchGenericLoading(false))
        if (payload && _.isArray(payload)) {
          dispatch(
            DialogActions.onSearchGenericLoadData({
              page: 1,
              pages: 1,
              rows: payload.length,
              itens: payload,
            }),
          )
        } else {
          // Não retornou nada... o form é zerado!
          dispatch(DialogActions.onSearchGenericClean())
        }
      })
    }
  },
  onAddUsuarioCliente: cliente => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
      gestorUsuarios: {
        usuario: { form },
      },
    } = getState()
    onAddUsuarioClienteApi({ token, dto: { pk: form.id, fk: cliente.id } }).then((payload) => {
      if (payload && payload.status === 201) {
        NotificationManager.success('Cliente adicionado com sucesso.', 'Atenção:', 8000)
        dispatch(Actions.onLoadDataUsuario(form.id))
      } else {
        NotificationManager.warning(
          'Não foi possível adicionar o cliente ao usuário.',
          'Atenção:',
          8000,
        )
      }
    })
  },
  onDeleteUsuarioCliente: cliente => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
      gestorUsuarios: {
        usuario: { form },
      },
    } = getState()
    onDeleteUsuarioClienteApi({ token, usuario: form.id, cliente: cliente.id }).then((payload) => {
      if (payload && payload.status === 204) {
        NotificationManager.success('Cliente removido com sucesso.', 'Atenção:', 8000)
        dispatch(Actions.onLoadDataUsuario(form.id))
      } else {
        NotificationManager.warning(
          'Não foi possível remover esse perfil do usuário.',
          'Atenção:',
          8000,
        )
      }
    })
  },
  onSearchUsuarioPerfils: search => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    let success = true
    // Validações
    if (!token) {
      success = false
      NotificationManager.error(
        'Seu token está inválido, você deve fazer o login novamente.',
        'Atenção:',
        8000,
      )
      dispatch(push('/login'))
    }
    if (success) {
      dispatch(DialogActions.onSearchGenericLoading(true))
      searchPerfilApi({ token, search }).then((payload) => {
        dispatch(DialogActions.onSearchGenericLoading(false))
        if (payload && _.isArray(payload)) {
          dispatch(
            DialogActions.onSearchGenericLoadData({
              page: 1,
              pages: 1,
              rows: payload.length,
              itens: payload,
            }),
          )
        } else {
          // Não retornou nada... o form é zerado!
          dispatch(DialogActions.onSearchGenericClean())
        }
      })
    }
  },
  onAddUsuarioPerfil: perfil => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
      gestorUsuarios: {
        usuario: { form },
      },
    } = getState()
    onAddUsuarioPerfilApi({ token, dto: { pk: form.id, fk: perfil.id } }).then((payload) => {
      if (payload && payload.status === 201) {
        NotificationManager.success('Perfil adicionado com sucesso.', 'Atenção:', 8000)
        dispatch(Actions.onLoadDataUsuario(form.id))
      } else {
        NotificationManager.warning(
          'Não foi possível adicionar o perfil ao usuário.',
          'Atenção:',
          8000,
        )
      }
    })
  },
  onDeleteUsuarioPerfil: perfil => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
      gestorUsuarios: {
        usuario: { form },
      },
    } = getState()
    onDeleteUsuarioPerfilApi({ token, usuario: form.id, perfil: perfil.id }).then((payload) => {
      if (payload && payload.status === 204) {
        NotificationManager.success('Perfil removido com sucesso.', 'Atenção:', 8000)
        dispatch(Actions.onLoadDataUsuario(form.id))
      } else {
        NotificationManager.warning(
          'Não foi possível remover esse perfil do usuário.',
          'Atenção:',
          8000,
        )
      }
    })
  },
  onLoadAprovadores: ({
    status, filial, usuario, search,
  }) => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    dispatch(Actions.onLoading({ aprovadores: true }))
    FilterAprovadoresAlcadaApi({
      token,
      status,
      filial,
      usuario,
      search,
    }).then((resp) => {
      dispatch(Actions.onLoading({ aprovadores: false }))
      dispatch({ type: Types.GU_UPDATE_APROVADORES, payload: { list: resp || [] } })
    })
  },
  onUpdateFormAprovadores: payload => (dispatch) => {
    dispatch({ type: Types.GU_UPDATE_FORM_APROVADORES, payload })
  },
  onSearchUsuario: ({ perfil = '', search }) => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    let success = true
    // Validações
    if (!token) {
      success = false
      NotificationManager.error(
        'Seu token está inválido, você deve fazer o login novamente.',
        'Atenção:',
        8000,
      )
      dispatch(push('/login'))
    }
    if (success) {
      dispatch(DialogActions.onSearchGenericLoading(true))
      getUsuariosListApi({
        token,
        status: 'L',
        perfil,
        search,
      }).then((payload) => {
        dispatch(DialogActions.onSearchGenericLoading(false))
        if (payload && _.isArray(payload)) {
          dispatch(
            DialogActions.onSearchGenericLoadData({
              page: 1,
              pages: 1,
              rows: payload.length,
              itens: payload,
            }),
          )
        } else {
          // Não retornou nada... o form é zerado!
          dispatch(DialogActions.onSearchGenericClean())
        }
      })
    }
  },
  onStartAprovador: () => ({ type: Types.GU_START_APROVADORES }),
  onSaveAprovador: dto => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    const {
      id, usuarioId, filialId, master, status, search,
    } = dto
    let isError = ''
    // Validações
    if (!isError && !usuarioId) {
      isError = 'Você deve seleciona um usuário!'
    }
    if (!isError && !filialId) {
      isError = 'Você deve seleciona uma filial!'
    }
    if (!isError && !master) {
      isError = 'Você deve informar o nível de aproavação do usuário MASTER ou NORMAL!'
    }
    if (!isError && !status) {
      isError = 'O status do usuário está inválido!'
    }

    if (isError) {
      NotificationManager.warning(isError, 'Atenção:', 8000)
    } else if (!isError && !id) {
      onAddAprovadorApi({ token, dto }).then((payload) => {
        if (payload && payload.status === 201) {
          NotificationManager.success('Aprovador adicionado com sucesso!', 'Atenção:', 8000)
          dispatch(Actions.onLoadAprovadores({ status, filial: filialId, search }))
        } else {
          NotificationManager.warning(
            'Não foi possível adicionar o perfil ao usuário.',
            'Atenção:',
            8000,
          )
        }
      })
    } else {
      onEditAprovadorApi({ token, dto, id }).then((payload) => {
        if (payload && payload.status === 201) {
          NotificationManager.success('Aprovador editado com sucesso!', 'Atenção:', 8000)
          dispatch(Actions.onLoadAprovadores({ status, filial: filialId, search }))
        } else {
          NotificationManager.warning(
            'Não foi possível adicionar o perfil ao usuário.',
            'Atenção:',
            8000,
          )
        }
      })
    }
  },
  onDelAprovador: (id, callback) => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    // console.log('onDelAprovador=>', { token, id })
    onDeleteAprovadorApi({ token, id }).then((payload) => {
      if (payload && payload.status === 204) {
        NotificationManager.success('Aprovador removido com sucesso.', 'Atenção:', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      } else {
        NotificationManager.warning('Não foi possível remover o aprovador.', 'Atenção:', 8000)
      }
    })
  },
}
