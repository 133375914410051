export const EStatus = { P: 'PENDENTE', L: 'LIBERADO', B: 'BLOQUEADO' }

export const ESimNao = { S: 'SIM', N: 'NÃO' }

export const EChecklistItemType = {
  RADIO: 'ESCOLHA ÚNICA',
  CHECKBOX: 'ESCOLHA MÚLTIPLA',
  INPUT: 'CAMPO DE TEXTO',
  IMAGE: 'FOTOS',
  RATING: 'AVALIÇÃO',
}
