import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Input,
    CircularProgress,
    FormControl,
    Paper,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core'
import { NotificationManager } from 'react-notifications'
import _, { set } from 'lodash'

import useStyles from './styles'
import { importacaoCsvApi } from 'api/lead/importacaocsv'
import { id } from 'date-fns/locale'
import { vincularAgentesSubbairroApi } from 'api/localizacao/sub_bairro'

const DialogAddVinculoAgentesSubBairro = (props) => {
    const {
        token,
        open,
        payload,
        onSave,
        onClose,
        onDialogClose,
        usuario,
        loading,
        listAgente,
        rowData,
        onSearchSubBairros
    } = props

    const [isOpen, setOpen] = useState(false)
    useEffect(() => {
        setOpen(open)
    }, [open])

    const classes = useStyles()


    const onCloseDialog = () => {
        if (_.isFunction(onClose)) {
            setOpen(false)
            onClose()
        }
    }
    const vincularAgentesSubbairro = () => {
        vincularAgentesSubbairroApi({ token: usuario?.auth?.token ?? "", subBairroId: rowData.id, agentesIds: selectedAgents })
            .then((response) => {
                // Lógica após a chamada da API bem-sucedida
                onSearchSubBairros()
                NotificationManager.success('Alteracao dos agentes feita com sucesso!', 'Sucesso!', 4000);
            })
            .catch((error) => {
                console.log(error)
                // Lógica em caso de erro na chamada da API
            });
    };
    const [selectedAgents, setSelectedAgents] = useState([]);
    useEffect(() => {
        setSelectedAgents(rowData?.agentes?.map((agente) => agente.id) || [])
    }, [rowData])


    const handleAgentSelection = (agentId) => {
        setSelectedAgents((prevSelectedAgents) => {
            if (prevSelectedAgents.includes(agentId)) {
                return prevSelectedAgents.filter((id) => id !== agentId);
            } else {
                return [...prevSelectedAgents, agentId];
            }
        });
    };


    return (
        <Dialog
            size="sm"
            title="Vínculo de Agente"
            open={isOpen}
            onClose={onCloseDialog}
        >
            <DialogTitle id="dialog-vinculo-agente">Vínculo de Agente</DialogTitle>
            <DialogContent>
                <FormControl id="forms-vincular">
                    <p>Escolha o(s) Agente(s):</p>
                    <Paper style={{ maxHeight: 300, overflow: 'auto' }}>
                        <FormGroup>
                            {listAgente.map((agente) => (
                                <FormControlLabel
                                    key={agente.id}
                                    control={
                                        <Checkbox
                                            checked={selectedAgents.includes(agente.id)}
                                            onChange={() => handleAgentSelection(agente.id)}
                                        />
                                    }
                                    label={agente.name}
                                />
                            ))}
                        </FormGroup>
                    </Paper>
                    <br />
                    <p>Confirma vínculo dos agentes com o subbairro?</p>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    key={1}
                    title="Sim"
                    color="secondary"
                    disabled={loading}
                    onClick={() => {
                        // Lógica para confirmar o vínculo usando o array selectedAgents
                        // setSelectedAgents([])
                        vincularAgentesSubbairro();
                        onClose();
                    }}
                >
                    Sim
                </Button>
                <Button
                    key={2}
                    title="Não"
                    color="primary"
                    disabled={loading}
                    onClick={() => {
                        // Lógica para confirmar o vínculo usando o array selectedAgents
                        // setSelectedAgents([])
                        setSelectedAgents(rowData?.agentes?.map((agente) => agente.id) || [])
                        onClose();
                    }}
                >
                    Não
                </Button>
            </DialogActions>
        </Dialog>

    )
}

DialogAddVinculoAgentesSubBairro.propTypes = {
    open: PropTypes.bool,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
}

DialogAddVinculoAgentesSubBairro.defaultProps = {
    open: false,
    onSave: () => { },
    onClose: () => { },
    onSearchSubBairros: () => { },
    listAgente: [],
    rowData: {},
    usuario: {}
}

export default DialogAddVinculoAgentesSubBairro