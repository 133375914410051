const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.defaults.margin * 2,
    paddingBottom: theme.defaults.margin * 2,
  },
  button: {
    margin: theme.defaults.margin,
  },
  expansionPanelSummary: {
    fontSize: 17,
    fontWeight: '900',
  },
  expansionPanel: {
    marginTop: theme.defaults.margin * 2,
  },
  wrapper: {
    margin: 10,
    position: 'relative',
  },
  buttonProgress: {
    color: '#000',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

export default styles
