import { API_URL } from '..'
import { httpValidState, httpValidErros } from '../../utils/http'

const ls = '@smr-portal'
const ref = '/config'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const getConfigApi = dto => fetch(`${API_URL}${ref}`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  },
})
  .then(res => res.json())
  .then(httpValidState)
  .catch(httpValidErros)

/*--------------
  LocalStorage
--------------*/
export const saveConfigLs = config => localStorage.setItem(`${ls}-config`, btoa(JSON.stringify(config)))
export const getConfigLs = () => {
  const config = localStorage.getItem(`${ls}-config`)
  return JSON.parse(config === null ? null : atob(config))
}
export const delConfigLs = () => localStorage.removeItem(`${ls}-config`)
