import moment from 'moment'
import numeral from 'numeral'
import _ from 'lodash'

import { getBoletos, downloadBoleto } from '../../api/financeiro/boletos'
import { getDanfesXML, downloadDanfe, downloadXml } from '../../api/financeiro/danfeXml'
import { downloadBlobFile } from 'utils'
import { EventosEnum } from '../../utils/enums/eventos'

import { Actions as ConfigActions } from './config'

// State Produto
const initialState = {
  filtrando: false,
  gerando: {},
  filtro: {
    dtInicial: '',
    dtFinal: '',
  },
  boletos: [],
  danfes: [],
}

// Actions Types
export const Types = {
  FILTRANDO: 'financeiro/FILTRANDO',
  GERANDO: 'financeiro/GERANDO',
  UPDATE_FIELD_FILTRO: 'financeiro/UPDATE_FIELD_FILTRO',
  LOAD_BOLETOS: 'produto/LOAD_BOLETOS',
  LOAD_DANFES: 'produto/LOAD_DANFES',
}

// Reducers
export default function financeiroReducers(state = initialState, action) {
  switch (action.type) {
    case Types.FILTRANDO:
      return { ...state, filtrando: action.payload }
    case Types.GERANDO:
      return { ...state, gerando: action.payload }
    case Types.UPDATE_FIELD_FILTRO:
      return { ...state, filtro: { ...state.filtro, ...action.payload } }
    case Types.LOAD_BOLETOS:
      return { ...state, boletos: action.payload }
    case Types.LOAD_DANFES:
      return { ...state, danfes: action.payload }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  onFiltrando: (payload = false) => (dispatch) => {
    dispatch({ type: Types.FILTRANDO, payload })
  },
  onGerando: payload => (dispatch) => {
    dispatch({ type: Types.GERANDO, payload })
  },
  onUpdateFieldFiltro: fields => (dispatch) => {
    dispatch({ type: Types.UPDATE_FIELD_FILTRO, payload: fields })
  },
  onLoadBoletos: ({
    empresa, filial, cgc, dtini, dtfim, titulo, valor, quitado,
  }) => (
    dispatch,
    getState,
  ) => {
    dispatch(Actions.onFiltrando(true))
    dispatch({ type: Types.LOAD_BOLETOS, payload: [] })
    if (empresa && filial && cgc) {
      const {
        usuario: {
          auth: { token },
        },
      } = getState().usuario
      const dto = {
        token,
        empresa,
        filial,
        cgc,
        dtini: dtini ? moment(dtini, 'YYYY-MM-DD').format('YYYYMMDD') : null,
        dtfim: dtfim ? moment(dtfim, 'YYYY-MM-DD').format('YYYYMMDD') : null,
        titulo,
        valor: numeral(valor).value(),
        quitado,
      }
      getBoletos(dto).then((boletos) => {
        dispatch({ type: Types.LOAD_BOLETOS, payload: _.isArray(boletos) ? boletos : [] })
        dispatch(Actions.onFiltrando(false))
      })
    } else {
      dispatch(Actions.onFiltrando(false))
    }
  },
  onDownloadBoleto: row => async (dispatch, getState) => {
    const { usuario } = getState().usuario
    const { unidade, auth } = usuario
    const { titulo, prefixo, parcela } = row
    const dto = {
      token: auth.token,
      usuarioCod: '',
      empresaCod: unidade.empresaCod,
      filialCod: unidade.filialCod,
      numBoleto: titulo,
      prefixo,
      parcela,
    }
    dispatch(Actions.onGerando(row))
    const resp = await downloadBoleto(dto)
    dispatch(Actions.onGerando({}))
    if (resp) {
      const blobBoleto = await resp.blob()
      // Log Eventos
      dispatch(ConfigActions.onAddEvento(EventosEnum.BOLETO_DOWNLOAD_SUCESSO))
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href = URL.createObjectURL(
        new Blob([blobBoleto], {
          type: 'application/pdf',
        }),
      )
      a.download = `Boleto_${titulo}.pdf`
      a.click()
    }
  },
  onFiltroDanfesXML: ({
    empresa, filial, cgc, dtini, dtfim, doc, serie, valbrut,
  }) => (
    dispatch,
    getState,
  ) => {
    dispatch(Actions.onFiltrando(true))
    dispatch({ type: Types.LOAD_DANFES, payload: [] })
    const {
      usuario: {
        auth: { token },
      },
    } = getState().usuario
    const dto = {
      token,
      empresa,
      filial,
      cgc,
      dtini: dtini ? moment(dtini, 'YYYY-MM-DD').format('YYYYMMDD') : null,
      dtfim: dtfim ? moment(dtfim, 'YYYY-MM-DD').format('YYYYMMDD') : null,
      doc,
      serie,
      valbrut: numeral(valbrut).value(),
    }
    getDanfesXML(dto).then((danfes) => {
      dispatch({ type: Types.LOAD_DANFES, payload: _.isArray(danfes) ? danfes : [] })
      dispatch(Actions.onFiltrando(false))
    })
  },
  onDownloadDanfeXML: (row, tipo) => (dispatch, getState) => {
    const { usuario } = getState().usuario
    const { unidade, auth } = usuario
    const { doc, serie } = row
    const dto = {
      token: auth.token,
      usuarioCod: '',
      empresaCod: unidade.empresaCod,
      filialCod: unidade.filialCod,
      documento: doc,
      serie,
    }
    dispatch(Actions.onGerando({ ...row, tipo }))
    if (tipo === 'danfe') {
      downloadDanfe(dto).then((respBlob) => {
        dispatch(Actions.onGerando({}))
        if (respBlob) {
          // Log Eventos
          dispatch(ConfigActions.onAddEvento(EventosEnum.DANFE_DOWNLOAD_SUCESSO))
        }
        window.open(URL.createObjectURL(respBlob), `Danfe-${doc}-${serie}.pdf`, '_blank')
        URL.revokeObjectURL(respBlob)
      })
    }
    if (tipo === 'xml') {
      downloadXml(dto).then((respBlob) => {
        dispatch(Actions.onGerando({}))
        if (respBlob) {
          // Log Eventos
          dispatch(ConfigActions.onAddEvento(EventosEnum.XML_DOWNLOAD_SUCESSO))
        }
        downloadBlobFile(respBlob, `Nfe-${doc}-${serie}.xml`)
      })
    }
  },
}
