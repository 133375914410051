import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
    Typography,
    Card,
    Grid,
    Fab,
    Icon,
    Tooltip,
    TextField,
    InputAdornment,
    IconButton,
    CssBaseline,
    Input,
    Button,
    Paper,
    Divider,
} from '@material-ui/core'

// import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import GestorHeaderComp from '../../../../../../components/Gestor/Drawer'
import GestorDrawerComp from '../../../../../../components/Gestor/Drawer'
import TableDataComp from '../../../../../../components/Table'
import useStyles from './styles'
import { getVisitasFilterApi } from 'api/visitas'
import { DialogContentSimples } from 'components/Dialogs/Contents'
import ReactPaginate from 'react-paginate'
import { searchClienteApi } from 'api/cliente'
import { findByIdCrmVistoriasApi } from 'api/crm/vistorias'
import { Save } from '@material-ui/icons'
import { findByIdEntregaApi } from 'api/entrega'

const EntregaDadosTabContent = (props) => {
    const {
        usuario,
        push,
        params
    } = props
    const [entrega, setEntrega] = useState([])


    const classes = useStyles()
    // State
    const fetchDataById = async (token, id) => {
        try {
            const response = await findByIdEntregaApi({ token: token, id: id });
            setEntrega(response)
            return response.data; // Retorna os dados da resposta
        } catch (error) {
            console.error('Erro ao buscar os dados por ID:', error);
            return null;
        }
    };
    const onUpdateEntregaField = (field, value) => {
        setEntrega((prevEntrega) => ({
            ...prevEntrega,
            [field]: value,
        }));
    };
    useEffect(() => {
        document.title = 'Online - Entrega Detalhe'
        document.body.style = 'background-image: none;'
        fetchDataById(usuario?.auth?.token, params.id)

    }, [])
    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <Grid container spacing={2}>
                    {/* Body */}
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>


                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="unidadeConsumidoraDTO.enderecoNome"
                                        label="Endereço"
                                        margin="normal"
                                        variant="outlined"
                                        value={entrega?.unidadeConsumidoraDTO && entrega?.unidadeConsumidoraDTO.enderecoNome ? entrega.unidadeConsumidoraDTO.enderecoNome.toString().toUpperCase() : ''}
                                        className={classes.textField}
                                        onChange={(e) => onUpdateEntregaField('unidadeConsumidoraDTO.enderecoNome', e.target.value)}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                cursor: 'not-allowed'
                                            },
                                        }}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="unidadeConsumidoraDTO.numeroNum"
                                        label="Número"
                                        margin="normal"
                                        variant="outlined"
                                        value={entrega.unidadeConsumidoraDTO && entrega.unidadeConsumidoraDTO.numeroNum ? entrega.unidadeConsumidoraDTO.numeroNum.toString().toUpperCase() : ''}
                                        className={classes.textField}
                                        onChange={(e) => onUpdateEntregaField('unidadeConsumidoraDTO.numeroNum', e.target.value)}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                cursor: 'not-allowed'
                                            },
                                        }}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="unidadeConsumidoraBairroNome"
                                        label="Bairro"
                                        margin="normal"
                                        variant="outlined"
                                        value={entrega.unidadeConsumidoraBairroNome ? entrega.unidadeConsumidoraBairroNome.toString().toUpperCase() : ''}
                                        className={classes.textField}
                                        onChange={(e) => onUpdateEntregaField('unidadeConsumidoraBairroNome', e.target.value)}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                cursor: 'not-allowed'
                                            },
                                        }}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider variant="middle" className={classes.customDivider} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="unidadeConsumidoraDTO.cep"
                                        label="CEP"
                                        margin="normal"
                                        variant="outlined"
                                        value={entrega.unidadeConsumidoraDTO && entrega.unidadeConsumidoraDTO.cep ? entrega.unidadeConsumidoraDTO.cep.toString().toUpperCase() : ''}
                                        className={classes.textField}
                                        onChange={(e) => onUpdateEntregaField('unidadeConsumidoraDTO.cep', e.target.value)}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                cursor: 'not-allowed'
                                            },
                                        }}
                                        disabled
                                    />
                                </Grid>



                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="unidadeConsumidoraDTO.enderecoNome"
                                        label="Endereço"
                                        margin="normal"
                                        variant="outlined"
                                        value={entrega.unidadeConsumidoraDTO && entrega.unidadeConsumidoraDTO.enderecoNome ? entrega.unidadeConsumidoraDTO.enderecoNome.toString().toUpperCase() : ''}
                                        className={classes.textField}
                                        onChange={(e) => onUpdateEntregaField('unidadeConsumidoraDTO.enderecoNome', e.target.value)}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                cursor: 'not-allowed'
                                            },
                                        }}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="unidadeConsumidoraDTO.municipio"
                                        label="Município"
                                        margin="normal"
                                        variant="outlined"
                                        value={entrega.unidadeConsumidoraDTO && entrega.unidadeConsumidoraDTO.municipio ? entrega.unidadeConsumidoraDTO.municipio.toString().toUpperCase() : ''}
                                        className={classes.textField}
                                        onChange={(e) => onUpdateEntregaField('unidadeConsumidoraDTO.municipio', e.target.value)}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                cursor: 'not-allowed'
                                            },
                                        }}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider variant="middle" className={classes.customDivider} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="unidadeConsumidoraDTO.crmAgente"
                                        label="Agente"
                                        margin="normal"
                                        variant="outlined"
                                        value={entrega.unidadeConsumidoraDTO && entrega.unidadeConsumidoraDTO.crmAgente ? entrega.unidadeConsumidoraDTO.crmAgente.toString().toUpperCase() : ''}
                                        className={classes.textField}
                                        onChange={(e) => onUpdateEntregaField('unidadeConsumidoraDTO.crmAgente', e.target.value)}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                cursor: 'not-allowed'
                                            },
                                        }}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </main>
        </div>

    )
}

EntregaDadosTabContent.propTypes = {
    loading: PropTypes.shape().isRequired,
    gestorUsuarios: PropTypes.shape().isRequired,
    push: PropTypes.func.isRequired,
    onDialogOpen: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
    onAddUsuarioCliente: PropTypes.func.isRequired,
    onDeleteUsuarioCliente: PropTypes.func.isRequired,
    onSearchUsuarioCliente: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
    gestorUsuarios: state.gestorUsuarios,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        ...ConfigActions,
        push: crrPush,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EntregaDadosTabContent)
