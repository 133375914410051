import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Numeral from 'numeral'

import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  Badge,
  Grid,
  Button,
} from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'
import HelpIcon from '@material-ui/icons/Help'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import AccountCircle from '@material-ui/icons/AccountCircle'
// import MailIcon from '@material-ui/icons/Mail'
// import AccountBalance from '@material-ui/icons/AccountBalance'

import { maskCGC, capitalize } from 'utils'
import TrocafFilialComp from '../Dialogs/TrocaFilial'
import TrocaClienteComp from '../Dialogs/TrocaCliente'
import NavComp from '../Nav'
import validSession from '../../utils/session'
import ROLES from '../../utils/enums/roles'
import { Actions as UsuarioActions } from '../../store/ducks/usuario'
import { Actions as ConfigActions } from '../../store/ducks/config'
import { Actions as CarrinhoActions } from '../../store/ducks/carrinho'

import styles from './styles'

const StyledBadge = withStyles(() => ({
  badge: {
    top: 8,
    right: -12,
    padding: '5 0px',
    height: 15,
    minWidth: 15,
  },
}))(Badge)

class Header extends Component {
  static propTypes = {
    showNavLoja: PropTypes.bool,
    loadLimiteCredito: PropTypes.bool.isRequired,
    classes: PropTypes.shape().isRequired,
    usuario: PropTypes.shape().isRequired,
    cliente: PropTypes.shape(),
    clientes: PropTypes.arrayOf(PropTypes.shape()),
    onLogout: PropTypes.func.isRequired,
    onLoadConfig: PropTypes.func.isRequired,
    onLoginSuccess: PropTypes.func.isRequired,
    onTrocaCliente: PropTypes.func.isRequired,
    getParametro: PropTypes.func.isRequired,
  }

  static defaultProps = {
    showNavLoja: true,
    cliente: null,
    clientes: [],
  }

  state = {
    openMenuMore: false,
    anchorEl: null,
  }

  componentDidMount() {
    this.onCheckSession()
  }

  onCheckSession = () => {
    const { usuario, onLoginSuccess, onLoadConfig } = this.props
    validSession(usuario, onLoginSuccess, onLoadConfig)
  }

  handleMenuMore = (evt) => {
    const { openMenuMore } = this.state
    this.setState({
      anchorEl: openMenuMore ? null : evt.currentTarget,
      openMenuMore: !openMenuMore,
    })
  }

  menuMoreComp = () => {
    const { openMenuMore, anchorEl } = this.state
    const { onLogout } = this.props
    return (
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openMenuMore}
        onClose={this.handleMenuMore}
      >
        <MenuItem onClick={onLogout}>Sair</MenuItem>
      </Menu>
    )
    // <MenuItem onClick={this.handleMenuMore}>Conta</MenuItem>
  }

  render() {
    const { openMenuMore } = this.state
    const {
      classes,
      showNavLoja,
      loadLimiteCredito,
      usuario,
      cliente,
      clientes,
      onTrocaCliente,
      getParametro,
    } = this.props
    const { limiteCredito = {}, unidade = {}, role = '' } = usuario
    const { limite = 0, devedor = 0, saldo = 0 } = limiteCredito
    const isCliente = role === ROLES.ROLE_CLIENTE || role === ROLES.ROLE_VENDEDOR
    const titulo = isCliente
      ? `${cliente.codigo}/${cliente.loja} - ${cliente.razao}`
      : `${unidade.empresaCod}/${unidade.filialCod} ${unidade.descricao}`
    const IS_EXIBE_AJUDA = getParametro('IS_EXIBE_AJUDA', 'NAO')
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton>
            <Grid className={classes.headerTitle}>
              <Grid item>
                <StyledBadge badgeContent={clientes.length} color="secondary">
                  {isCliente ? (
                    <TrocaClienteComp
                      usuario={usuario}
                      titulo={titulo}
                      clientes={clientes}
                      onChange={item => onTrocaCliente(item)}
                    />
                  ) : (
                    <Button color="inherit" className={classes.headerFilialTitle}>
                      {titulo}
                    </Button>
                  )}
                </StyledBadge>
              </Grid>
              <Grid item>
                <Typography variant="caption" color="inherit" gutterBottom>
                  {`CNPJ: ${maskCGC(cliente.cgc)}${
                    isCliente && loadLimiteCredito
                      ? ' | LIMITE DE CRÉDITO: Calculando... EM ABERTO: Calculando... SALDO: Calculando...'
                      : ` | LIMITE DE CRÉDITO: R$ ${Numeral(limite).format('0,0.00')} EM ABERTO: R$ ${Numeral(devedor).format('0,0.00')} SALDO: R$ ${Numeral(saldo).format('0,0.00')}`
                  }`}
                </Typography>
              </Grid>
            </Grid>
            <TrocafFilialComp />
            {IS_EXIBE_AJUDA === 'SIM' && (
              <IconButton
                color="inherit"
                style={{ fontSize: 16, marginLeft: 10 }}
                data-toggle="modal"
                data-target=".bd-home-modal-lg"
              >
                <HelpIcon />
                AJUDA
              </IconButton>
            )}
            <IconButton color="inherit" style={{ fontSize: 16 }}>
              <AccountCircle />
              {capitalize(usuario.nome.split(' ')[0])}
            </IconButton>
            {/*
            <div style={{ marginLeft: 10, marginRight: 10 }}>
            </div>
            <IconButton color="inherit">
              <Badge badgeContent={1} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton color="inherit">
              <AccountCircle />
            </IconButton>
            */}
            <div>
              <IconButton
                aria-owns={openMenuMore ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                color="inherit"
                onClick={this.handleMenuMore}
              >
                <MoreVertIcon />
              </IconButton>
              {this.menuMoreComp()}
            </div>
          </Toolbar>
        </AppBar>
        {showNavLoja && <NavComp />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  loadLimiteCredito: state.usuario.loadLimiteCredito,
  cliente: state.usuario.usuario.cliente,
  clientes: state.usuario.usuario.clientes,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...UsuarioActions,
    ...ConfigActions,
    ...CarrinhoActions,
  },
  dispatch,
)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Header),
)
