import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from 'api'

const ref = '/visita'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - { token: String, page, rows, search, estado, municipio }
 */
export const getVisitasFilterApi = (dto) => {
  const {
    token,
   
  } = dto
  let queryUrl = '?1=1'
  queryUrl += `&page=${dto.query.page}`
  queryUrl += dto.query.rows ? `&rows=${dto.query.rows}` : ''
  queryUrl += dto.query.search ? `&search=${dto.query.search}` : ''
  queryUrl += dto.query.agenteId ? `&agenteId=${dto.query.agenteId}` : ''
  queryUrl += dto.query.visitaDate ? `&visitaDate=${dto.query.visitaDate}` : ''
  queryUrl += dto.query.estado ? `&estado=${dto.query.estado}` : ''
  queryUrl += dto.query.municipio ? `&municipio=${dto.query.municipio}` : ''
  queryUrl += dto.query.bairro ? `&bairro=${dto.query.bairro}` : ''
  queryUrl += dto.query.subBairro ? `&subBairro=${dto.query.subBairro}` : ''
  queryUrl += dto.query.revisitaBonus ? `&revisitaBonus=${dto.query.revisitaBonus}` : ''
  return fetch(`${API_URL}${ref}/filter${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)

}
/**
 *
 * @param {String} dto - { token: String, id }
 */
export const findByIdVisitaApi = (dto) => {
  const { token, id } = dto
  return fetch(`${API_URL}${ref}/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}
/**
 *
 * @param {String} dto - { token: String, id }
 */
export const editByIdVisitaApi = (dto) => {
  const { token, id, body } = dto
  return fetch(`${API_URL}${ref}/edit/${id}`, {
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body)
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}
/**
 *
 * @param {String} dto - { token: String, id }
 */
export const cancelVisitaApi = (dto) => {
  const { token, id } = dto;
  return fetch(`${API_URL}${ref}/cancel/${id}`, {
    method: 'PUT',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros);
}

/**
 *
 * @param {String} dto - { token: String, id }
 */
export const deleteVisitaApi = (dto) => {
  const { token, id } = dto;
  return fetch(`${API_URL}${ref}/${id}`, {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros);
}
