import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import parse from 'html-react-parser'

import {
  Grid, CssBaseline, Icon, IconButton, Typography, CircularProgress, Paper, Button, MenuItem, Select, TextField, FormControl, InputLabel,
} from '@material-ui/core'

// Api
import {
  getEventosTiposSum,
  getEventosTiposSumPositionsApi,
  getEventosTiposSumExcelApi,
  getFunilVendasData,
  getPieChartVisitasData,
  getPaperData,
} from 'api/dashboard'

// Store
import { Actions as DialogActions } from 'store/ducks/dialog'

// Comnponent's
import GestorHeaderComp from 'components/Gestor/Header'
import GestorDrawerComp from 'components/Gestor/Drawer'
import CardInfoComp from 'components/Dashboard/CardInfo'

// Util's
import { downloadExcelFile, DateIsoToBr } from 'utils'

// Component's
import DashboardDialogFilter from './components/filtros/dialog'
import GestorDashboardMaps from './components/maps'
import AgendaDialogFilter from '../../../pages/gestor/dashboard/components/filtros/agendaFilter'

// Styles's
import useStyles from './styles'

// ChartJs
import Chart, { LinearScale, CategoryScale } from 'chart.js';

import Plotly from 'plotly.js-dist';
import { filterAgendaApi, filterPaginatorAgendaApi } from 'api/agenda'
import TableDataComp from 'components/Table'
import { findAllAgendaTipoApi } from 'api/agenda_tipo'
import { filterCrmAgentesApi } from 'api/crm/agentes'
import { format } from 'date-fns'

import { push as crrPush, push } from 'connected-react-router'
import { Actions as ConfigActions } from 'store/ducks/config'
// import { Actions as DialogActions } from 'store/ducks/dialog'
import { Actions as CrmContatosActions } from 'store/ducks/crm/contato'
import { NotificationManager } from 'react-notifications'
import { Pagination } from '@material-ui/lab'
const GestorDashboardPage = (props) => {
  const { usuario, onDialogOpen, onDialogClose, push } = props
  const [paperData, setPaperData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [funilData, setFunilData] = useState(null);

  const fetchPaperData = async () => {
    try {
      // Chama a função getPaperData passando o token como argumento
      const data = await getPaperData({ token: usuario.auth.token });

      // Define os dados retornados na variável de estado
      setPaperData(data);
    } catch (error) {
      console.error('Erro ao carregar os dados do papel:', error);
    }
  };

  const loadData = async (pFilter = {}) => {
    setLoading(true);
    const resp = await getFunilVendasData({ token: usuario.auth.token });
    setLoading(false);
    setFunilData(resp);
    // Atualize o gráfico somente após o carregamento dos dados
    updateChart(resp);
  };
  const loadPieChartVisitasData = async () => {
    setLoading(true);
    try {
      // Chame a função getPieChartVisitasData passando os parâmetros necessários
      const resp = await getPieChartVisitasData({ token: usuario.auth.token });

      // Atualize o gráfico somente após o carregamento dos dados
      createPieChart(resp);
    } catch (error) {
      console.error('Ocorreu um erro ao carregar os dados do gráfico de pizza:', error);
    }
    setLoading(false);
  };

  const updateChart = (data) => {
    const funnelData = [
      {
        type: 'funnel',
        y: ["VISITAS REALIZADAS", "INTERESSE DE COMPRA", "AGUARDANDO AGENDAMENTO", "AGUARDANDO VISTORIAS", "VISTORIA POSITIVADA", "VENDA FECHADA"],
        x: [data?.visitasRealizadas, data?.interessesCompra, data?.aguardandoAgendamento, data?.aguardandoVistorias, data?.vistoriasPositivada, data?.vendaFechada],
        hoverinfo: 'text',
        textinfo: 'value',
        textposition: 'inside',
        marker: {
          colors: ['rgba(54, 162, 235, 0.1)', 'rgba(54, 162, 235, 0.2)', 'rgba(54, 162, 235, 0.4)', 'rgba(54, 162, 235, 0.6)', 'rgba(54, 162, 235, 0.8)', 'rgba(54, 162, 235, 1)'],
          line: {
            color: 'rgba(54, 162, 235, 1)',
            width: 1,
          },
        },
        connector: {
          line: {
            color: 'rgba(54, 162, 235, 1)',
            width: 1,
          },
        },
      },
    ];

    const layout = {
      title: {
        text: 'FUNIL DE VENDAS',
        font: {
          size: 24,
        },
      },
      margin: { l: 200 }, // Aumente a margem esquerda (l) para ajustar o espaço das legendas
      funnelmode: 'stack',
      height: 400,
    };
    var config = {
      responsive: true,
      staticPlot: true,
      scrollZoom: false, // Desabilita a interação de zoom com o scroll do mouse
      modeBarButtonsToRemove: ['zoomIn2d', 'zoomOut2d', 'autoScale2d', 'resetScale2d', 'lasso2d', 'select2d', 'pan2d', 'zoom'], // Remove os botões de zoom      responsive: true,
    }
    Plotly.newPlot('funil-vendas', funnelData, layout, config);
  };

  const createPieChart = (data) => {
    const chartData = [{
      values: [data.casaVazia, data.impossibilidadeTecnica, data.localFechado, data.modouSe, data.realizadaSucesso, data.revisitaBonus, data.recusa],
      labels: ['CASA VAZIA', 'IMPOSSIBILIDADE TÉCNICA', 'LOCAL FECHADO', 'MODOU-SE', 'REALIZADA COM SUCESSO', 'REVISITA BONUS', 'RECUSA'],
      type: 'pie',
      hoverinfo: 'label+percent',
      textinfo: 'value',
      textposition: 'inside',
      marker: {
        colors: ['rgba(54, 162, 235, 0.1)', 'rgba(54, 162, 235, 0.2)', 'rgba(54, 162, 235, 0.4)', 'rgba(54, 162, 235, 0.6)', 'rgba(54, 162, 235, 0.8)', 'rgba(54, 162, 235, 1)'],
        line: {
          color: 'rgba(54, 162, 235, 1)',
          width: 1,
        },
      },
    }];

    const layout = {
      title: {
        text: 'DISTRIBUIÇÃO DE VISITAS',
        font: {
          size: 24,
        },
      },
      height: 400,
    };
    var config = { responsive: true }
    Plotly.newPlot('pie-chart-visitas', chartData, layout, config);

  };
  const [agendasData, setAgendasData] = useState([])
  const [agendasTipoData, setAgendasTipoData] = useState([])

  const loadAgendaData = async () => {
    setLoading(true);
    try {
      const resp = await filterPaginatorAgendaApi({
        token: usuario?.auth?.token ?? "",
        query: {
          page: currentPage,
          rows: 5,
          // dateAgenda: moment().tz('America/Sao_Paulo').toDate(),
          // agendaTipoId:2
        }
      });
      setAgendasData(resp)
    } catch (error) {
      console.error('Ocorreu um erro ao carregar os dados da agenda:', error);
    }
    setLoading(false);
  };
  const loadAgendaTipo = async () => {
    setLoading(true);
    try {
      const resp = await findAllAgendaTipoApi(usuario?.auth?.token ?? "");
      setAgendasTipoData(resp)
    } catch (error) {
      console.error('Ocorreu um erro ao carregar os dados da agenda TIPO:', error);
    }
    setLoading(false);
  };
  const tableCols = [
    { key: 'agendaTipoDescricao', title: 'TIPO' },
  ];

  tableCols.push(
    { key: 'dateAgenda', title: 'DATA', format: row => format(new Date(row.dateAgenda), 'dd/MM/yyyy HH:mm') },
    { key: 'title', title: 'ATIVIDADE' },
    { key: 'observation', title: 'OBSERVAÇÃO' },
    { key: 'crmProspectNome', title: 'CLIENTE' },
    { key: 'crmAgentesName', title: 'AGENTE' },
    { key: 'crmOportunidadeId', title: 'Oportunidade ID' }
  );
  if (usuario.role === 'ROLE_ADMIN') {
    tableCols.push({ key: 'usuarioNome', title: 'USUÁRIO' });
  }
  const tableActionsList = [
    {
      key: 'agenda',
      title: 'Agenda',
      icon: 'list',
      disabled: row => !row.crmProspectId,
      action: row => {
        if (row.crmProspectId) {
          push(`/gestor/crm/prospects/detail/${row.crmProspectId}`);
        }
      },
    },
    {
      key: 'oportunidade',
      title: 'Oportunidade',
      icon: 'list',
      disabled: row => !row.crmProspectId || !row.crmOportunidadeId,
      action: row => {
        if (row.crmProspectId && row.crmOportunidadeId) {
          push(`/gestor/crm/prospects/detail/${row.crmProspectId}/${row.crmOportunidadeId}`);
        }
      },
    }
  ];
  tableActionsList.push({
    key: 'visita',
    title: 'Visita',
    icon: 'list',
    disabled: row => !row.visitaId,
    action: row => {
      if (row.visitaId) {
        push(`/gestor/visita/detail/${row.visitaId}/`);
      }
    },
  },
    {
      key: 'Vistoria',
      title: 'Vistoria',
      icon: 'list',
      disabled: row => !row.vistoriaId,
      action: row => {
        if (row.vistoriaId) {
          push(`/gestor/vistorias/detail/${row.vistoriaId}/`);
        }
      },
    }
  );





  const [agentes, setAgentes] = useState([])

  const onLoadAgentes = () => {
    filterCrmAgentesApi({ token: usuario?.auth?.token ?? "", query: {} }).then((resp) => {
      const { data = [] } = resp || {}
      setAgentes(data)
    })
  }
  const handleAgendasChange = (novasAgendas) => {
    setAgendasData(novasAgendas);
  };
  const [currentPage, setCurrentPage] = React.useState(0);
  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1); // Subtract 1 because pagination component is 1-indexed and your currentPage state is 0-indexed
    // Perform other necessary updates here
  };

  useEffect(() => {
    document.title = 'PUBLIKIMAGEM Online - Dashboard'
    document.body.style = 'background-image: none;'
    loadData()
    loadPieChartVisitasData()
    fetchPaperData()
    loadAgendaData()
    loadAgendaTipo()
    onLoadAgentes()
  }, [])
  useEffect(() => {
    loadAgendaData();
  }, [currentPage]);

  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={5} id="pie-chart-visitas" className={classes.responsiveChart}>
            {/* Conteúdo da primeira coluna */}
          </Grid>
          <Grid item xs={5} id="funil-vendas" className={classes.responsiveChart}>
            {/* Conteúdo da segunda coluna */}
          </Grid>
          <Grid item xs={2}>
            {/* Conteúdo da terceira coluna */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper elevation={3} style={{ padding: 20 }} className="text-center">
                  <Typography variant="h4" style={{ marginBottom: 10 }}>{paperData?.prospectAtivo ? paperData.prospectAtivo : 0}</Typography>
                  <Typography variant="subtitle1">PROSPECT ATIVO</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={3} style={{ padding: 20 }} className="text-center">
                  <Typography variant="h4" style={{ marginBottom: 10 }}>{paperData?.vistoriasPendentes ? paperData.vistoriasPendentes : 0}</Typography>
                  <Typography variant="subtitle1">VISTORIAS PENDENTES</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={3} style={{ padding: 20 }} className="text-center">
                  <Typography variant="h4" style={{ marginBottom: 10 }}>0</Typography>
                  <Typography variant="subtitle1">ENTREGAS REALIZADAS</Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="start" spacing={2}>
          <Grid item xs={10} md={10}>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="secondary"
                  aria-label="Menu"
                  onClick={() =>
                    onDialogOpen({
                      size: 'sm',
                      title: 'Filtro de Agendas',
                      content: (
                        <AgendaDialogFilter
                          onClose={onDialogClose}
                          agendasTipoData={agendasTipoData}
                          onAgendaChange={handleAgendasChange}
                          onPageChange={handlePageChange}
                          agentes={agentes}
                        />
                      ),
                    })
                  }
                >
                  <Icon>filter_alt</Icon>
                </IconButton>
                <Typography variant="subtitle1" component="span" style={{ fontWeight: 'bold', color: '#333333', marginLeft: '8px' }}>
                  Agenda: Compromisso, Vistoria, Entrega e Instalação
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ maxHeight: '400px' }}>
              <div className={classes.tableContainer}>
                <TableDataComp
                  cols={tableCols}
                  rows={agendasData?.data}
                  actions={tableActionsList}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div id="mui-pagination">
                <Pagination
                  count={agendasData?.total ?? 0}
                  page={currentPage + 1} // Add 1 to match the paginator index
                  onChange={handlePageChange}
                  color="primary"
                  className={classes.pagination}
                />
              </div>
            </Grid>
          </Grid>

          <Grid item xs={2} md={2}>
            {/* Conteúdo da terceira coluna da grid de baixo */}
          </Grid>
        </Grid>
      </main>
    </div >
  )
}

GestorDashboardPage.propTypes = {
  usuario: PropTypes.shape().isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  crmContatos: state.crmContatos,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...CrmContatosActions,
    push: crrPush,
  },
  dispatch,
)

export default connect(mapStateToProps, mapDispatchToProps)(GestorDashboardPage)
