import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Input,
    CircularProgress,
} from '@material-ui/core'
import { NotificationManager } from 'react-notifications'
import _, { set } from 'lodash'

import useStyles from './styles'
import { importacaoCsvApi } from 'api/lead/importacaocsv'

const DialogAddImportacaoCsv = (props) => {
    const {
        token,
        open,
        payload,
        onSave,
        onClose,
        usuario
    } = props

    const [isOpen, setOpen] = useState(false)
    useEffect(() => {
        setOpen(open)
    }, [open])

    const classes = useStyles()


    const onCloseDialog = () => {
        if (_.isFunction(onClose)) {
            setOpen(false)
            onClose()
        }
    }

    const [fileData, setFileData] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleFileUpload = (event) => {
        const fileName = event.target.files[0].name;
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setFileData(e.target.result);
        };
        reader.readAsText(file);
    };

    const handleSubmit = (fileName, dataCsv) => {
        setLoading(true);
        if (!fileData) {
            console.log('File data is empty.'); // adicionar esta linha para verificar se o fileData está vazio
            setLoading(false);
            return;
        }
        if (payload.some((element) => element.fileName === fileName)) {
            NotificationManager.warning(
                'Arquivo já importado',
                'Atenção',
                8000
            );
            setLoading(false);
        } else if (fileName.split('.')[1] !== 'csv') {
            NotificationManager.warning(
                'Selecione apenas arquivos .CSV',
                'Atenção',
                8000
            );
            setLoading(false);
        } else {
            importacaoCsvApi({
                token: usuario.auth.token,
                usuario: usuario.id,
                fileName: fileName,
                file: dataCsv,
            }).then((resp) => {
                setLoading(false);
                if (resp && resp.length > 0) {
                    // criar um novo objeto Blob com o conteúdo do arquivo
                    var respFormated = [];
                    for (let index = 0; index < resp.length - 1; index += 2) {
                        var element = resp[index] + '\n' + resp[index + 1] + '\n\n';
                        respFormated.push(element);
                    }
                    const file = new Blob(respFormated, { type: 'text/plain' });

                    // criar um novo elemento anchor e definir seus atributos de download e href
                    const a = document.createElement('a');
                    a.setAttribute('download', 'error_log.txt');
                    a.setAttribute('href', URL.createObjectURL(file));

                    // clicar no elemento anchor para solicitar ao usuário que salve o arquivo
                    a.click();
                }
                onSave();
                onClose();
            });
        }
    };


    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={isOpen}
            onClose={onCloseDialog}
            aria-labelledby="dialog-crm-atendimento"
        >
            <DialogTitle id="dialog-crm-atendimento-title">Importacao CSV</DialogTitle>
            {loading ? <CircularProgress size={50} color="inherit" /> : ''}
            <DialogContent>
                <div className={classes.root}>
                    <div>
                        <Input
                            accept=".csv"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={handleFileUpload}
                            disabled={loading}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!fileData || loading}
                    onClick={() => handleSubmit(document.getElementById("contained-button-file").files[0].name, fileData)}
                >
                    Enviar
                </Button>
                <Button onClick={onCloseDialog} color="secondary" disabled={loading}>
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

DialogAddImportacaoCsv.propTypes = {
    // loading: PropTypes.bool,
    token: PropTypes.string.isRequired,
    open: PropTypes.bool,
    payload: PropTypes.shape().isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}

DialogAddImportacaoCsv.defaultProps = {
    // loading: false,
    open: false,
}

export default DialogAddImportacaoCsv
