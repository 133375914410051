import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function MessageModal({ isOpen, messages, onClose }) {
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Atenção
                    <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {Array.isArray(messages) && messages.map((msg, index) => (
                    <ExpansionPanel key={index}>
                        <ExpansionPanelSummary
                            expandIcon={msg.log && msg.log !== "" ? <ExpandMoreIcon /> : null}
                            aria-controls={`panel-content-${index}`}
                            id={`panel-header-${index}`}
                        >
                            <Typography>{msg.message}</Typography>
                        </ExpansionPanelSummary>
                        {msg.log && msg.log !== "" && (
                            <ExpansionPanelDetails>
                                <Typography component="pre">{msg.log}</Typography>
                            </ExpansionPanelDetails>
                        )}
                    </ExpansionPanel>
                ))}
            </DialogContent>

        </Dialog>
    );
}

export default MessageModal;
