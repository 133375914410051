import { makeStyles } from '@material-ui/core/styles'
const env = process.env.REACT_APP_ENV;

const styles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: env === "test" ? "pink" : undefined,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))

export default styles
