import { API_URL } from '../..'
import { httpParseJson, httpValidState, httpValidErros } from '../../../utils/http'

const ref = '/filial'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/*--------------
  Http Request
--------------*/
/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} search - Valor usado para pesquisar dados
 */
export const searchFilialApi = (dto) => {
  const { token, q } = dto
  return fetch(`${API_URL}${ref}/filter?q=${q}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}
