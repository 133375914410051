import { NotificationManager } from 'react-notifications'
import { push } from 'connected-react-router'
import _ from 'lodash'

// Repository
import {
  filterChecklistApi,
  getByIdChecklistApi,
  addChecklistApi,
  editChecklistApi,
  delChecklistApi,
  delChecklistItensApi,
  delChecklistItensAlternativasApi,
} from 'pages/gestor/checklist/repository'

// State Auth
const initialState = {
  loading: {
    resultSet: false,
    save: false,
    deleteChecklist: false,
    deleteChecklistItem: false,
    deleteChecklistAlternativa: false,
  },
  resultSet: {
    page: 0,
    rows: 0,
    data: [],
  },
  formChecklist: {
    id: 0,
    descricao: '',
    status: '',
    itens: [],
  },
  formPeguntas: {
    open: false,
    id: 0,
    checklistId: 0,
    type: '',
    label: '',
    ordem: 0,
    status: '',
    resposta: '',
    alternativas: [],
  },
  formAlternativas: {
    open: false,
    id: 0,
    checklistItensId: 0,
    label: '',
    value: '',
    ordem: 0,
    checked: false,
  },
}

// Actions Types
export const Types = {
  RESET: 'reset',
  CHECKLIST_LOADING: 'checklist/LOADING',
  CHECKLIST_RESULTSET: 'checklist/RESULTSET',
  CHECKLIST_FORM_CHECKLIST: 'checklist/FORM_CHECKLIST',
  CHECKLIST_FORM_PERGUNTAS: 'checklist/FORM_PERGUNTAS',
  CHECKLIST_FORM_ALTERNATIVAS: 'checklist/FORM_ALTERNATIVAS',
}

// Reducers
export default function checklistReducers(state = initialState, action) {
  switch (action.type) {
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      }
    case Types.CHECKLIST_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    case Types.CHECKLIST_RESULTSET:
      return {
        ...state,
        resultSet: { ...state.resultSet, ...action.payload },
      }
    case Types.CHECKLIST_FORM_CHECKLIST:
      return {
        ...state,
        formChecklist: { ...state.formChecklist, ...action.payload },
      }
    case Types.CHECKLIST_FORM_PERGUNTAS:
      return {
        ...state,
        formPeguntas: { ...state.formPeguntas, ...action.payload },
      }
    case Types.CHECKLIST_FORM_ALTERNATIVAS:
      return {
        ...state,
        formAlternativas: { ...state.formAlternativas, ...action.payload },
      }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onReset: () => ({ type: Types.RESET }),
  onChecklistLoading: payload => ({ type: Types.CHECKLIST_LOADING, payload }),
  onChecklistResultSet: payload => ({ type: Types.CHECKLIST_RESULTSET, payload }),
  onChecklistResultSetClean: () => ({
    type: Types.CHECKLIST_RESULTSET,
    payload: initialState.session,
  }),
  onChecklistFormChecklist: payload => ({ type: Types.CHECKLIST_FORM_CHECKLIST, payload }),
  onChecklistFormChecklistClean: () => ({
    type: Types.CHECKLIST_FORM_CHECKLIST,
    payload: initialState.formChecklist,
  }),
  onChecklistFormPerguntas: payload => ({ type: Types.CHECKLIST_FORM_PERGUNTAS, payload }),
  onChecklistFormPerguntasClean: () => ({
    type: Types.CHECKLIST_FORM_PERGUNTAS,
    payload: initialState.formPeguntas,
  }),
  onChecklistFormAlternativas: payload => ({ type: Types.CHECKLIST_FORM_ALTERNATIVAS, payload }),
  onChecklistFormAlternativasClean: () => ({
    type: Types.CHECKLIST_FORM_ALTERNATIVAS,
    payload: initialState.formAlternativas,
  }),

  // Any Actions
  // * Checklist
  onFilterChecklist: (params = {}, page = 0, rows = 50) => (dispatch, getState) => {
    const {
      usuario: { usuario: { auth: { token } } },
    } = getState()
    dispatch(Actions.onChecklistLoading({ resultSet: true }))
    filterChecklistApi(token, params, page, rows)
      .then((resp) => {
        dispatch(Actions.onChecklistLoading({ resultSet: false }))
        if (resp && _.isArray(resp.data)) {
          dispatch(Actions.onChecklistResultSet(resp))
        }
      })
      .catch(() => {
        dispatch(Actions.onChecklistLoading({ resultSet: false }))
      })
  },
  onLoadCheckist: pId => (dispatch, getState) => {
    const {
      usuario: { usuario: { auth: { token } } },
    } = getState()
    if (pId === -1) {
      dispatch(Actions.onChecklistFormChecklistClean())
      dispatch(Actions.onChecklistFormPerguntasClean())
      dispatch(Actions.onChecklistFormAlternativasClean())
    } else {
      getByIdChecklistApi(token, pId).then((resp) => {
        dispatch(Actions.onChecklistLoading({ load: true }))
        if (resp) {
          dispatch(Actions.onChecklistLoading({ load: false }))
          dispatch(
            Actions.onChecklistFormChecklist({
              id: resp.id,
              descricao: resp.descricao,
              status: resp.status,
              itens: resp.itens,
            }),
          )
          const alternativas = []
          resp.itens.forEach((i) => {
            alternativas.push(...i.alternativas)
          })
          dispatch(Actions.onChecklistFormPerguntas({ alternativas }))
        }
      })
    }
  },
  onSaveChecklist: () => (dispatch, getState) => {
    const {
      usuario: { usuario: { auth: { token } } },
      checklist: { formChecklist, formPeguntas },
    } = getState()
    const dto = {
      ...formChecklist,
      itens: formChecklist.itens.map(i => ({
        ...i,
        id: i.new ? null : i.id,
        alternativas: formPeguntas.alternativas
          .filter(a => a.checklistItensId === i.id)
          .map(a => ({
            ...a,
            id: a.new ? null : a.id,
            checklistItensId: i.new ? null : i.id,
          })),
      })),
    }
    let isValid = true
    // Validando as perguntas
    if (_.isEmpty(dto.itens)) {
      NotificationManager.warning('Você precisa informar uma pergunta!', 'Atenção!', 8000)
      isValid = false
    }
    // Validando alternativas
    dto.itens.forEach((i) => {
      if (_.isEmpty(i.alternativas)) {
        NotificationManager.warning(
          `Atenção pergunta "${i.label}" não possui alternativas!`,
          'Atenção!',
          8000,
        )
        isValid = false
        return false
      }
      return true
    })
    if (isValid) {
      dispatch(Actions.onChecklistLoading({ save: true }))
      if (dto.id) {
        editChecklistApi(token, dto).then((resp) => {
          // dispatch(Actions.onChecklistLoading({ save: true }))
          if (resp && resp.status === 200) {
            NotificationManager.success('Checklist editado com sucesso!', 'Sucesso!', 8000)
            dispatch(push(`/gestor/checklist/edit/${resp.id}`))
          }
        }).finally(() => dispatch(Actions.onChecklistLoading({ save: false })))
      } else {
        addChecklistApi(token, dto).then((resp) => {
          // dispatch(Actions.onChecklistLoading({ save: true }))
          if (resp && resp.status === 200) {
            NotificationManager.success('Checklist adicionado com sucesso!', 'Sucesso!', 8000)
            // dispatch(push(`/checklist/edit/${resp.id}`))
            dispatch(push('/gestor/checklist'))
          }
        }).finally(() => dispatch(Actions.onChecklistLoading({ save: false })))
      }
    }
  },
  onDelChecklist: (pId, onCallbackSuccess, onCallbackError) => (dispatch, getState) => {
    const {
      usuario: { usuario: { auth: { token } } },
    } = getState()
    dispatch(Actions.onChecklistLoading({ deleteChecklist: true }))
    delChecklistApi({ token, id: pId }).then((resp) => {
      if (resp && resp.status === 200) {
        NotificationManager.success('Checklist excluído com sucesso!', 'Sucesso!', 8000)
        // dispatch(push('/gestor/checklist'))
        if (_.isFunction(onCallbackSuccess)) {
          onCallbackSuccess()
        }
        return
      }
      if (_.isFunction(onCallbackError)) {
        onCallbackError()
      }
    }).catch(() => {
      if (_.isFunction(onCallbackError)) {
        onCallbackError()
      }
    }).finally(() => dispatch(Actions.onChecklistLoading({ deleteChecklist: false })))
  },
  // * Perguntas
  onLoadPergunta: pId => (dispatch, getState) => {
    const {
      checklist: { formChecklist },
    } = getState()
    // * Nova pergunta
    if (pId === -1) {
      dispatch(
        Actions.onChecklistFormPerguntas({
          open: true,
          id: 0,
          type: '',
          label: '',
          ordem: formChecklist?.itens?.length + 1 || 1,
          isRequired: 'N',
          status: 'L',
        }),
      )
    }
    if (pId > 0) {
      const pergunta = formChecklist.itens.find(i => i.id === pId)
      dispatch(
        Actions.onChecklistFormPerguntas({
          open: true,
          id: pergunta.id,
          type: pergunta.type,
          label: pergunta.label,
          ordem: pergunta.ordem,
          isRequired: pergunta.isRequired,
          status: pergunta.status,
        }),
      )
    }
  },
  onAddPergunta: () => (dispatch, getState) => {
    const {
      checklist: { formChecklist, formPeguntas },
    } = getState()
    if (_.isEmpty((formPeguntas?.label ?? '').trim())) {
      NotificationManager.warning('Pergunta inválida, você deve formar um frase.', 'Atenção', 8000)
      return
    }
    if (_.isEmpty((formPeguntas?.type ?? '').trim())) {
      NotificationManager.warning('Tipo inválido, você deve informar um tipo da pergunta válido.', 'Atenção', 8000)
      return
    }
    const itens = [
      ...formChecklist.itens,
      {
        new: true,
        id: Date.now(),
        type: formPeguntas.type,
        label: formPeguntas.label,
        ordem: formPeguntas.ordem,
        isRequired: formPeguntas.isRequired,
        status: formPeguntas.status,
      },
    ]
    dispatch(Actions.onChecklistFormChecklist({ itens }))
    dispatch(
      Actions.onChecklistFormPerguntas({
        id: 0,
        type: '',
        label: '',
        ordem: itens?.length + 1 || 1,
        status: 'L',
      }),
    )
    NotificationManager.success('Pergunta adicionada com sucesso!', 'Sucesso!', 8000)
    // }
  },
  onEditPergunta: () => (dispatch, getState) => {
    const {
      checklist: { formChecklist, formPeguntas },
    } = getState()
    if (_.isEmpty((formPeguntas?.label ?? '').trim())) {
      NotificationManager.warning('Pergunta inválida, você deve formar um frase.', 'Atenção', 8000)
      return
    }
    if (_.isEmpty((formPeguntas?.type ?? '').trim())) {
      NotificationManager.warning('Tipo inválido, você deve informar um tipo válido.', 'Atenção', 8000)
      return
    }
    const itens = formChecklist.itens.map((i) => {
      if (i.id === formPeguntas.id) {
        return {
          ...i,
          type: formPeguntas.type,
          label: formPeguntas.label,
          ordem: formPeguntas.ordem,
          isRequired: formPeguntas.isRequired,
          status: formPeguntas.status,
        }
      }
      return i
    })
    dispatch(Actions.onChecklistFormChecklist({ itens }))
    NotificationManager.success('Pergunta editada com sucesso!', 'Sucesso!', 8000)
  },
  onDelPergunta: (pId, onCallbackSuccess, onCallbackError) => (dispatch, getState) => {
    const {
      usuario: { usuario: { auth: { token } } },
      checklist: { formChecklist },
    } = getState()
    dispatch(Actions.onChecklistLoading({ deleteChecklistItem: true }))
    if (!formChecklist.id) {
      dispatch(
        Actions.onChecklistFormChecklist({
          itens: formChecklist.itens.filter(i => i.id !== pId),
        }),
      )
      dispatch(Actions.onChecklistLoading({ deleteChecklistItem: false }))
      if (_.isFunction(onCallbackSuccess)) {
        onCallbackSuccess()
      }
      NotificationManager.success('Pergunta removida com sucesso!', 'Sucesso!', 8000)
    } else {
      delChecklistItensApi({ token, id: pId }).then((resp) => {
        if (resp && resp.status === 200) {
          dispatch(
            Actions.onChecklistFormChecklist({
              itens: formChecklist.itens.filter(i => i.id !== pId),
            }),
          )
          NotificationManager.success('Pergunta excluída com sucesso!', 'Sucesso!', 8000)
          if (_.isFunction(onCallbackSuccess)) {
            onCallbackSuccess()
          }
          return
        }
        if (_.isFunction(onCallbackError)) {
          onCallbackError()
        }
      }).finally(() => dispatch(Actions.onChecklistLoading({ deleteChecklistItem: false })))
    }
  },
  // * Alternativas
  onLoadAlternativa: (pId, checklistItensId = 0) => (dispatch, getState) => {
    const {
      checklist: { formPeguntas },
    } = getState()
    // * Nova pergunta
    if (pId === -1) {
      dispatch(
        Actions.onChecklistFormAlternativas({
          open: true,
          id: 0,
          checklistItensId,
          label: '',
          value: '',
          ordem: 0,
          checked: false,
          isRequired: 'N',
        }),
      )
    }
    if (pId > 0) {
      const alternativa = formPeguntas.alternativas.find(i => i.id === pId)
      dispatch(
        Actions.onChecklistFormAlternativas({
          open: true,
          id: alternativa.id,
          checklistItensId: alternativa.checklistItensId,
          label: alternativa.label,
          value: alternativa.value,
          checked: alternativa.checked,
          isRequired: alternativa.isRequired,
        }),
      )
    }
  },
  onAddAlternativa: () => (dispatch, getState) => {
    const {
      checklist: { formPeguntas, formAlternativas },
    } = getState()
    if (_.isEmpty((formAlternativas?.label ?? '').trim())) {
      NotificationManager.warning('Alternativa inválida, você deve formar um texto válido.', 'Atenção', 8000)
      return
    }
    if (_.isEmpty((formAlternativas?.value ?? '').trim())) {
      NotificationManager.warning('Valor inválido, você deve informar um valor para essa alternativa.', 'Atenção', 8000)
      return
    }
    dispatch(
      Actions.onChecklistFormPerguntas({
        alternativas: [
          ...formPeguntas.alternativas,
          {
            new: true,
            id: Date.now(),
            checklistItensId: formAlternativas.checklistItensId,
            label: formAlternativas.label,
            value: formAlternativas.value,
            checked: formAlternativas.checked,
            isRequired: formAlternativas.isRequired,
          },
        ],
      }),
    )
    dispatch(
      Actions.onChecklistFormAlternativas({
        open: true,
        id: 0,
        label: '',
        value: '',
        checked: false,
      }),
    )
    NotificationManager.success('Alternativa adicionada com sucesso!', 'Sucesso!', 8000)
    // }
  },
  onEditAlternativa: () => (dispatch, getState) => {
    const {
      checklist: { formPeguntas, formAlternativas },
    } = getState()
    if (_.isEmpty((formAlternativas?.label ?? '').trim())) {
      NotificationManager.warning('Alternativa inválida, você deve formar um texto válido.', 'Atenção', 8000)
      return
    }
    if (_.isEmpty((formAlternativas?.value ?? '').trim())) {
      NotificationManager.warning('Valor inválido, você deve informar um valor para essa alternativa.', 'Atenção', 8000)
      return
    }
    const alternativas = formPeguntas.alternativas.map((i) => {
      if (i.id === formAlternativas.id) {
        return {
          ...i,
          checklistItensId: formAlternativas.checklistItensId,
          label: formAlternativas.label,
          value: formAlternativas.value,
          checked: formAlternativas.checked,
          isRequired: formAlternativas.isRequired,
        }
      }
      return i
    })
    dispatch(Actions.onChecklistFormPerguntas({ alternativas }))
    NotificationManager.success('Alternativa editada com sucesso!', 'Sucesso!', 8000)
  },
  onDelAlternativa: (pId, onCallbackSuccess, onCallbackError) => (dispatch, getState) => {
    const {
      usuario: { usuario: { auth: { token } } },
      checklist: { formChecklist, formPeguntas },
    } = getState()
    if (!formChecklist.id) {
      dispatch(
        Actions.onChecklistFormPerguntas({
          alternativas: formPeguntas.alternativas.filter(i => i.id !== pId),
        }),
      )
      NotificationManager.success('Alternativa removida com sucesso!', 'Sucesso!', 8000)
    } else {
      delChecklistItensAlternativasApi({ token, id: pId }).then((resp) => {
        if (resp && resp.status === 200) {
          dispatch(
            Actions.onChecklistFormPerguntas({
              alternativas: formPeguntas.alternativas.filter(i => i.id !== pId),
            }),
          )
          NotificationManager.success('Alternativa excluída com sucesso!', 'Sucesso!', 8000)
          if (_.isFunction(onCallbackSuccess)) {
            onCallbackSuccess()
          }
          return
        }
        if (_.isFunction(onCallbackError)) {
          onCallbackError()
        }
      }).finally(() => dispatch(Actions.onChecklistLoading({ deleteChecklistAlternativa: false })))
    }
  },
}
