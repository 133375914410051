import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import {
  Typography,
  Card,
  Grid,
  Icon,
  Tooltip,
  TextField,
  Paper,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import _ from 'lodash'

// import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DucksDialogActions } from 'store/ducks/dialog'
import { Actions as CrmContatosActions } from 'store/ducks/crm/contato'
import Header from 'components/Header'
import {
  maskCGC, validarCPF, OnlyDigits, maskCellphone,
} from 'utils'
import useStyles from './styles'

const CrmContatosDetalhePage = (props) => {
  const {
    match: { params },
    usuario, crmContatos, push, onFindById, onUpdateFormField,
    onSaveCrmContato, onResetCrmContatos, onDeleteCrmContato,
  } = props
  const [dialogDel, setDialogDel] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    document.title = 'Online - Contatos'
    document.body.style = 'background-image: none;'
    // onFilterCrmContatos()
    if (params.id) {
      onFindById(params.id)
    } else {
      onResetCrmContatos()
      onUpdateFormField({ entidadeId: usuario?.cliente?.id ?? 0 })
    }
  }, [])

  return (
    <Fragment>
      <Header />
      <Grid container spacing={2} className={classes.root} direction="row" justifyContent="center">
        <Grid item xs={12} md={12} lg={8}>
          <Card className={classes.grid}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h2">
                      Contato - Detalhe
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid container direction="row" justifyContent="flex-end">
                      <Tooltip title="Adicionar">
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.button}
                          onClick={() => setDialogDel(true)}
                        >
                          <Icon className={classes.leftIcon}>clear</Icon>
                          Excluir Contato
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper className={classes.paper} elevation={1}>
                  <Grid className={classes.grids} container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="setor"
                        label="Setor:"
                        margin="normal"
                        variant="outlined"
                        value={crmContatos.form.setor}
                        className={classes.textField}
                        onChange={evt => onUpdateFormField({ setor: evt.target.value })}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.textField} variant="outlined" margin="normal">
                        <InputLabel htmlFor="tipo">Tipo</InputLabel>
                        <Select
                          fullWidth
                          label="Tipo"
                          value={crmContatos.form.tipo}
                          onChange={evt => onUpdateFormField({ tipo: evt.target.value })}
                          inputProps={{
                            name: 'tipo',
                            id: 'tipo',
                          }}
                        >
                          <MenuItem value="">
                            <em>Selecione</em>
                          </MenuItem>
                          <MenuItem value="CLIENTE">
                            CLIENTE
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        disabled
                        id="codigo"
                        label="Código:"
                        margin="normal"
                        variant="outlined"
                        value={crmContatos.form.codigo}
                        className={classes.textField}
                        onChange={evt => onUpdateFormField({ codigo: evt.target.value })}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="cpfUsuario"
                        label="CPF:"
                        margin="normal"
                        variant="outlined"
                        value={maskCGC(crmContatos?.form?.cgc ?? '')}
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={_.size(crmContatos?.form?.cgc ?? '') === 11 && !validarCPF(crmContatos?.form?.cgc ?? '')}
                        // error={false}
                        // helperText="CPF inválido."
                        helperText={_.size(crmContatos?.form?.cgc ?? '') === 11 && !validarCPF(crmContatos?.form?.cgc ?? '') ? 'CPF inválido.' : ''}
                        className={classes.textField}
                        onChange={(evt) => {
                          const cgc = OnlyDigits(evt?.target?.value ?? '')
                          if (_.size(cgc) <= 11) {
                            onUpdateFormField({ cgc })
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="nomeUsuario"
                        label="Nome Completo:"
                        margin="normal"
                        variant="outlined"
                        value={crmContatos.form.nome}
                        className={classes.textField}
                        onChange={evt => onUpdateFormField({ nome: evt.target.value })}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="emailUsuario"
                        label="E-mail:"
                        margin="normal"
                        variant="outlined"
                        value={crmContatos.form.email}
                        className={classes.textField}
                        onChange={evt => onUpdateFormField({ email: evt.target.value })}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        id="telefone"
                        label="Telefone:"
                        margin="normal"
                        variant="outlined"
                        value={maskCellphone(crmContatos?.form?.telefone ?? '')}
                        className={classes.textField}
                        onChange={(evt) => {
                          // const telefone = (evt?.target?.value ?? '').replace(/[^\d]/g, '')
                          const telefone = OnlyDigits(evt?.target?.value ?? '')
                          if (_.size(telefone) <= 11) {
                            onUpdateFormField({ telefone })
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl fullWidth className={classes.textField} variant="outlined" margin="normal">
                        <InputLabel htmlFor="status">Status</InputLabel>
                        <Select
                          fullWidth
                          label="Status"
                          value={crmContatos.form.status}
                          onChange={evt => onUpdateFormField({ status: evt.target.value })}
                          inputProps={{
                            name: 'status',
                            id: 'status',
                          }}
                        >
                          <MenuItem value="">
                            <em>Selecione</em>
                          </MenuItem>
                          <MenuItem value="P">
                            PENDENTE
                          </MenuItem>
                          <MenuItem value="L">
                            LIBERADO
                          </MenuItem>
                          <MenuItem value="B">
                            BLOQUEADO
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={6}>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.margin}
                          onClick={() => push('/crm/contatos')}
                        >
                          <Icon className={classes.leftIcon}>arrow_back</Icon>
                          Voltar
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          disabled={crmContatos?.loading?.save ?? false}
                          onClick={() => onSaveCrmContato(crmContatos.form)}
                        >
                          <Icon className={classes.leftIcon}>save</Icon>
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      {/* Dialog delete contato */}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={dialogDel}
        onClose={() => setDialogDel(false)}
        aria-labelledby="responsive-dialog-filtroNfe-title"
      >
        <DialogTitle id="responsive-dialog-delete-contato">Filtro</DialogTitle>
        <DialogContent>
          <div>
            <span>Você confirma a exclusão do contato </span>
            <strong>{crmContatos.form.nome ?? ''}</strong>
            <span> ?</span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogDel(false)} color="secondary">
            Cancelar
          </Button>
          <Button onClick={() => onDeleteCrmContato(crmContatos.form)} color="primary" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

CrmContatosDetalhePage.propTypes = {
  usuario: PropTypes.shape().isRequired,
  crmContatos: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  params: PropTypes.shape(),
  push: PropTypes.func.isRequired,
  onFindById: PropTypes.func.isRequired,
  onUpdateFormField: PropTypes.func.isRequired,
  onSaveCrmContato: PropTypes.func.isRequired,
  onResetCrmContatos: PropTypes.func.isRequired,
  onDeleteCrmContato: PropTypes.func.isRequired,
}

CrmContatosDetalhePage.defaultProps = {
  params: { id: 0 },
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  crmContatos: state.crmContatos,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DucksDialogActions,
    ...ConfigActions,
    ...CrmContatosActions,
    push: crrPush,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CrmContatosDetalhePage)
