const stylesTitle = theme => ({
  // Title
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
})

const stylesContent = theme => ({
  // Content
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
})

const stylesActions = theme => ({
  // Actions
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(),
  },
  progress: {
    margin: theme.spacing(2),
  },
})

export { stylesTitle, stylesContent, stylesActions }
