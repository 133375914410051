import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
    Grid,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core'
import { filterAgendaApi, filterPaginatorAgendaApi } from 'api/agenda'

const AgendaDialogFilter = (props) => {
    const {
        dataFilter,
        onClose,
        onFilter,
        // usuario: {
        // agendaTipos: { agendaTipos = [] },
        agendasTipoData,
        agentes,
        usuario
        // },
    } = props

    const [tipoIsOpen, setTipoIsOpen] = useState(false)
    const [formFilter, setFormFilter] = useState({})
    const [agentesFilter, setAgentes] = useState(agentes)
    const handleFiltrar = () => {
        console.log(formFilter)
        const newFormFilter = { ...formFilter }
    }
    const handleChangeDate = (evt) => {
        const value = evt?.target?.value ?? ''
        if (value) {
            const field = evt.target.name
            setFormFilter(s => ({ ...s, [field]: value }))
        }
    }
    const handleChangeAgendaTipo = (event) => {
        const selectedTipo = event.target.value;
        // Atualizando o formFilter com o novo tipoAgendaId
        setFormFilter((prevFormFilter) => ({
            ...prevFormFilter,
            tipoAgendaId: selectedTipo,
        }));

        // Filtre os agentes baseados no tipoAgendaId
        var filteredAgentes = agentes.filter(agente =>
            (selectedTipo == 2 && agente.crmAgenteTipo.id == 2) ||
            (selectedTipo == 3 && agente.crmAgenteTipo.id == 3)
        );
        if (selectedTipo == 0) {
            filteredAgentes = agentes
        }
        // Atualize a lista de agentes (assumindo que você tem setAgentes)
        setAgentes(filteredAgentes);
    };

    const handleChangeAgente = (event) => {
        const selectedAgente = event.target.value;
        setFormFilter((prevFormFilter) => ({
            ...prevFormFilter,
            agenteId: selectedAgente,
        }));
    };
    const handleAgendasChanve = (novasAgendas) => {
        // Chame a função de retorno de chamada para notificar o componente pai
        props.onAgendaChange(novasAgendas);
        props.onPageChange(null, 1)
    };
    const loadAgendaData = async () => {
        try {
            const resp = await filterPaginatorAgendaApi({
                token: usuario?.auth?.token ?? "",
                query: {
                    page: 0,
                    rows: 5,
                    dateStartAgenda: formFilter.de,
                    dateEndAgenda: formFilter.ate,
                    agendaTipoId: formFilter.tipoAgendaId,
                    crmAgentesId: formFilter.agenteId
                }
            });
            handleAgendasChanve(resp)
            onClose()
        } catch (error) {
            console.error('Ocorreu um erro ao carregar os dados da agenda:', error);
        }
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    id="de"
                    name="de"
                    label="Data inicial"
                    type="date"
                    value={formFilter.de}
                    onChange={handleChangeDate}
                    inputProps={{
                        // min: '2021-06-03',
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    id="ate"
                    name="ate"
                    label="Data final"
                    type="date"
                    value={formFilter.ate}
                    onChange={handleChangeDate}
                    inputProps={{
                        min: formFilter.de,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="dashboard-filtro-lista-agenda-tipo">Agenda Tipo</InputLabel>
                    <Select
                        id="dashboard-filtro-tipo-agenda"
                        labelId="dashboard-filtro-tipo-agenda"
                        value={formFilter?.tipoAgendaId ?? []}
                        onChange={handleChangeAgendaTipo}
                    >
                        <MenuItem key={0} value={0}>TODOS</MenuItem>
                        {agendasTipoData?.map(tipo => (
                            <MenuItem key={tipo.id} value={tipo.id}>{tipo.descricao}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                {/* Segundo Select */}
                <FormControl fullWidth>
                    <InputLabel id="dashboard-filtro-lista-agentes">Agente</InputLabel>
                    <Select
                        id="dashboard-filtro-agentes"
                        labelId="dashboard-filtro-agente"
                        value={formFilter?.agenteId ?? []}
                        onChange={handleChangeAgente}
                    >
                        {agentesFilter?.map(agente => (
                            <MenuItem key={agente.id} value={agente.id}>{agente.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Button color="secondary" onClick={onClose}>CANCELAR</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={loadAgendaData}>FILTRAR</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

AgendaDialogFilter.propTypes = {
    dataFilter: PropTypes.shape().isRequired,
    onClose: PropTypes.func.isRequired,
    onFilter: PropTypes.func.isRequired,
    usuario: PropTypes.shape().isRequired,
    agendasTipoData: PropTypes.array.isRequired,
    agentes: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(AgendaDialogFilter)