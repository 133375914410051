import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid, Paper, Typography, Button,
} from '@material-ui/core'

// import { clearSession } from '../../../utils/session'
import { Actions as UsuarioActions } from '../../../store/ducks/usuario'
import styles from './styles'

class TokenExpiradoPage extends Component {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    usuario: PropTypes.shape().isRequired,
  }

  componentDidMount() {
    // Clear session
    // clearSession()
  }

  render() {
    const { classes, usuario } = this.props
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item xs={10}>
          <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
            <Grid item>
              <img
                alt="Token expirado!"
                src={require('../../../assets/imagens/login/token-expired-img.png')}
              />
            </Grid>
            <Grid item>
              <Paper className={classes.root} elevation={1}>
                <Typography variant="h5" component="h3">
                  {`Olá ${usuario.nome} sua token infelizmente expirou.`}
                </Typography>
                <Typography component="p">
                  {'Você deve fazer '}
                  <Button color="secondary" href="/login">
                    "login"
                  </Button>
                  {' no site novamente.'}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  loading: state.usuario.loading,
  error: state.usuario.error,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...UsuarioActions,
  },
  dispatch,
)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TokenExpiradoPage),
)
