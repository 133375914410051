import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'
import { API_URL } from 'api'

const ref = '/importacao_csv'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - { token: String , userId: String, fileName: String, file: FormData }
 */
export const importacaoCsvApi = ({token, usuario, fileName, file}) => {
  const payload = {usuario, fileName, file}
  return fetch(`${API_URL}${ref}`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload)
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} token - { token: String }
 */
export const getImportacaoCsvLogsApi = ({token}) => {
  return fetch(`${API_URL}${ref}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}



  


