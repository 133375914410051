import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import {
  Paper,
  TextField,
  Grid,
  Button,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core'
import { Save } from '@material-ui/icons'

import { Actions as GestorProdutosActions } from '../../../../../../store/ducks/gestor/produtos'
import useStyles from './styles'

const DadosProdutoTabContent = (props) => {
  const {
    gestorProdutos: { loading, produto },
    onUpdateProdutoField,
    onSaveProduto,
  } = props
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  const classes = useStyles()
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])
  return loading.produto && !produto ? (
    <CircularProgress />
  ) : (
    <Paper className={classes.paper} elevation={1}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="produtoSku"
            label="Código:"
            margin="normal"
            variant="outlined"
            value={produto.produtoSku}
            className={classes.textField}
            onChange={evt => onUpdateProdutoField({ produtoSku: evt.target.value })}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            id="produtoDesc"
            label="Descrição:"
            margin="normal"
            variant="outlined"
            value={produto.produtoDesc}
            className={classes.textField}
            onChange={evt => onUpdateProdutoField({ produtoDesc: evt.target.value })}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl margin="normal" variant="outlined" fullWidth>
            <InputLabel ref={inputLabel} htmlFor="produtoUM" id="produtoUM">
              Unidade de medida
            </InputLabel>
            <Select
              labelId="produtoUM"
              value={produto.produtoUM}
              onChange={evt => onUpdateProdutoField({ produtoUM: evt.target.value })}
              inputProps={{
                id: 'produtoUM',
                name: 'produtoUM',
              }}
              labelWidth={labelWidth}
            >
              <MenuItem value="">SELECIONE</MenuItem>
              <MenuItem value="SC">SC</MenuItem>
              <MenuItem value="TL">TL</MenuItem>
              <MenuItem value="UN">UN</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="produtoPeso"
            label="Peso"
            margin="normal"
            variant="outlined"
            value={produto.produtoPeso}
            className={classes.textField}
            onChange={evt => onUpdateProdutoField({ produtoPeso: evt.target.value })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="produtoQuantMin"
            label="Qtd. Minima"
            margin="normal"
            variant="outlined"
            value={produto.produtoQuantMin}
            className={classes.textField}
            onChange={evt => onUpdateProdutoField({ produtoQuantMin: evt.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={10}>
          <TextField
            fullWidth
            id="produtoDescComplementar"
            label="Descrição Complementar:"
            margin="normal"
            variant="outlined"
            value={produto.produtoDescComplementar}
            className={classes.textField}
            onChange={evt => onUpdateProdutoField({ produtoDescComplementar: evt.target.value })}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={6}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => onSaveProduto()}
            >
              <Save className={classes.leftIcon} />
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

DadosProdutoTabContent.propTypes = {
  loading: PropTypes.shape().isRequired,
  gestorProdutos: PropTypes.shape().isRequired,
  onUpdateProdutoField: PropTypes.func.isRequired,
  onSaveProduto: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  gestorProdutos: state.gestorProdutos,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...GestorProdutosActions,
    push,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DadosProdutoTabContent)
