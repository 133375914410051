import moment from 'moment'
import Numeral from 'numeral'

export const empFilByTenantId = (tenantId = '') => {
  const aEmpFil = tenantId.split(',')
  return {
    empresasCodigo: aEmpFil[0],
    filiaisCodigo: aEmpFil[1],
  }
}

export const moneyFormat = money => `R$ ${Numeral(money).format('0,0.00')}`

export const maskCGC = (cgc) => {
  if (cgc && cgc.length === 11) {
    return cgc.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }
  if (cgc && cgc.length === 14) {
    return cgc.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  }
  return null
}
export const maskCellphone = cgc => cgc.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, '($1)$2.$3-$4')

export const downloadBlobFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  a.href = url
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(url)
}

export const downloadExcelFile = (blob, fileName) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = URL.createObjectURL(
    new Blob([blob], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
  )
  a.download = fileName
  a.click()
}

export const DtFormat = (dateIso, format = 'DD/MM/YYYY HH:mm', inFormat = 'YYYY-MM-DD HH:mm:ss') => {
  if (!dateIso) return ''
  return moment(dateIso, inFormat).format(format)
}

export const currentTimestamp = (format = 'YYYY-MM-DD HH:mm:ss') => moment().format(format)

export const DateHoursIsoToBr = (dateIso) => {
  if (!dateIso) return ''
  return moment(dateIso, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')
}
export const DateIsoToBr = dateIso => moment(dateIso, 'YYYY-MM-DD').format('DD/MM/YYYY')

export const DateBrToIso = dateIso => moment(dateIso, 'DD/MM/YYYY').format('YYYY-MM-DD')
export const SToC = date => (date ? moment(date, 'YYYYMMDD').format('DD/MM/YYYY') : '')
export const DToS = date => (date ? moment(date, 'DD/MM/YYYY').format('YYYYMMDD') : '')
export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}
export const validarCPF = (pCpf) => {
  const cpf = pCpf.replace(/[^\d]+/g, '')
  if (cpf === '') return false
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11
    || cpf === '00000000000'
    || cpf === '11111111111'
    || cpf === '22222222222'
    || cpf === '33333333333'
    || cpf === '44444444444'
    || cpf === '55555555555'
    || cpf === '66666666666'
    || cpf === '77777777777'
    || cpf === '88888888888'
    || cpf === '99999999999'
  ) return false
  // Valida 1o digito
  let add = 0
  for (let i = 0; i < 9; i += 1) add += parseInt(cpf.charAt(i), 10) * (10 - i)
  let rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cpf.charAt(9), 10)) return false
  // Valida 2o digito
  add = 0
  for (let i = 0; i < 10; i += 1) add += parseInt(cpf.charAt(i), 10) * (11 - i)
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cpf.charAt(10), 10)) return false
  return true
}
export const OnlyDigits = digits => String(digits).replace(/[^\d]/g, '')
