import React from 'react'
import PropTypes from 'prop-types'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const PedidoErrorComp = (props) => {
  const { title = '', text = '' } = props
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ overflowWrap: 'anywhere' }}>
        <pre>
          {text}
        </pre>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
PedidoErrorComp.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}
export default PedidoErrorComp
