import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  margin: {
    marginRight: theme.spacing(2),
  },
  textField: {
    // flexBasis: 200,
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolbar: theme.mixins.toolbar,
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    appearance: "none",
    WebkitAppearance: "none",
    MozAppearance: "none",
    borderRadius: "50%",
    width: "16px",
    height: "16px",
    border: "1px solid #ccc",
    outline: "none",
    transition: "border-color 0.2s ease-in-out",
  },
  checked: {
    backgroundColor: "#007bff",
    borderColor: "#007bff",
  },
  hover: {
    borderColor: "#007bff",
  },
}))

export default styles
