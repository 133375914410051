import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import classNames from 'classnames'
import {
    Typography,
    CssBaseline,
    Grid,
    Fab,
    Icon,
    Tooltip,
    TextField,
    InputAdornment,
    IconButton,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Button,
    Checkbox,
} from '@material-ui/core'
import ReactPaginate from 'react-paginate'

import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as DialogActions } from '../../../../store/ducks/dialog'
import { Actions as GestorClientesActions } from '../../../../store/ducks/gestor/clientes'
import GestorHeaderComp from '../../../../components/Gestor/Header'
import GestorDrawerComp from '../../../../components/Gestor/Drawer'
import TableDataComp from '../../../../components/Table'
import useStyles from './styles'
import { Autocomplete, Pagination } from '@material-ui/lab'
import { delCrmVistoriasApi, filterCrmVistoriasApi } from 'api/crm/vistorias'
import { estadoAllApi, estadoApi } from 'api/localizacao/estado'
import { filterCrmAgentesApi } from 'api/crm/agentes'
import { listMunicipiosByEstadoIdApi } from 'api/localizacao/municipio'
import { listBairrosByMunicipioIdApi } from 'api/localizacao/bairro'
import { listSubBairrosByBairroId } from 'api/localizacao/sub_bairro'
import DeleteVistoriaModal from 'components/Dialogs/Vistoria/delete'
import { NotificationManager } from 'react-notifications'

const VistoriaListPage = (props) => {
    const {
        usuario,
        gestorClientes: { loading, list },
        push,
        onSearchClientes,
    } = props
    const [currentPage, setCurrentPage] = useState(1)
    const classes = useStyles()
    // State
    const [iptSearch, setIptSearch] = useState('')
    const tableCols = [
        { key: 'statusDescricao', title: 'STATUS' },
        { key: 'parecer', title: 'PARECER' },
        { key: 'agenteNome', title: 'AGENTE' },
        { key: 'dataAgenda', title: 'DATA' },
        { key: 'unidadeConsumidoraNumero', title: 'UC NUMERO' },
        { key: 'oportunidadeId', title: 'OPORTUNIDADE' },
        { key: 'clienteNome', title: 'CLIENTE' },
        { key: 'produtoDescricao', title: 'PRODUTO' },
        { key: 'unidadeConsumidoraBairroNome', title: 'BAIRRO' },
        { key: 'unidadeConsumidoraSubbairroNome', title: 'SUB-BAIRRO' }

    ]
    const tableActionsList = [
        {
            key: 'detail',
            title: 'Detalhes',
            icon: 'list',
            action: row => push(`vistorias/detail/${row.id}`),
        },
        {
            key: 'delete',
            title: 'Excluir',
            icon: 'delete',
            action: row => openDeleteModal(row),
        },
        {
            key: 'detalhes',
            title: 'Prospect',
            icon: 'group',
            action: row => {
                push(`crm/prospects/detail/${row.prospectId}`);
            },
          }
    ]
    const [payload, setPayload] = useState({});

    useEffect(() => {
        document.title = 'Online - Vistorias'
        document.body.style = 'background-image: none;'
        loadEstados()
        onLoadVistorias()
        onSearchAgentes()
    }, [])
    const onLoadVistorias = (page = 1) => {
        const updatedFilter = { ...filter, page: page - 1, rows: 10, agenteId: selectedAgent, vistoriaDate: selectedDate, estado: selectedState?.id ?? '', municipio: selectedMunicipality?.id ?? '', bairro: selectedBairro?.id ?? '', subBairro: selectedSubBairro?.id ?? '' };
        if (page == 1 && currentPage != page) {
            console.log('ENTREI')
            setCurrentPage(page);
        } else {
            filterCrmVistoriasApi({ token: usuario?.auth?.token ?? "", query: updatedFilter }).then(resp => {
                setPayload(resp)
            })
        }
    }
    const onLoadVistoriasClick = () => {
        onLoadVistorias(1);
    }

    useEffect(() => {
        onLoadVistorias(currentPage)
    }, [currentPage])

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const [agentes, setAgentes] = useState(false);
    const [filter, setFilter] = useState({
        page: 0,
        rows: 10,
        search: '',
    });
    const onIptSearchChange = (evt) => {
        evt.persist();
        setFilter(s => ({ ...s, search: evt?.target?.value?.toUpperCase() ?? '' }))
    }

    const onSearchAgentes = () => {
        filterCrmAgentesApi({ token: usuario?.auth?.token ?? '', query: { agenteTypeChar: "S" } })
            .then((resp) => {
                const { data = [] } = resp || {}
                setAgentes(data)
            })
    }
    const [selectedAgent, setSelectedAgent] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);

    const handleAgentChange = (event) => {
        setSelectedAgent(event.target.value);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    //Localization 
    //Localization Set
    // Função para carregar todos os estados
    const loadEstados = () => {
        // Chamar a função estadoAllApi para carregar os estados
        estadoAllApi(usuario?.auth?.token ?? '')
            .then((response) => {
                // Definir os estados carregados no estado local
                setEstados(response);
            })
            .catch((error) => {
                console.error('Erro ao carregar os estados:', error);
            });
    };

    // Função para carregar municípios com base no estado selecionado
    const loadMunicipios = (stateId) => {
        // Chamar a função listMunicipiosByEstadoIdApi passando o estadoId
        listMunicipiosByEstadoIdApi(usuario?.auth?.token ?? '', stateId)
            .then((response) => {
                // Definir os municípios carregados no estado local
                setMunicipios(response);
            })
            .catch((error) => {
                console.error('Erro ao carregar os municípios:', error);
            });
    };

    // Função para carregar bairros com base no município selecionado
    const loadBairros = (municipioId) => {
        // Chamar a função listBairrosByMunicipioIdApi passando o municipioId
        listBairrosByMunicipioIdApi(usuario?.auth?.token ?? '', municipioId)
            .then((response) => {
                // Definir os bairros carregados no estado local
                setBairros(response);
            })
            .catch((error) => {
                console.error('Erro ao carregar os bairros:', error);
            });
    };

    // Função para carregar subbairros com base no bairro selecionado
    const loadSubBairros = (bairroId) => {
        // Chamar a função listSubBairrosByBairroId passando o bairroId
        listSubBairrosByBairroId(usuario?.auth?.token ?? '', bairroId)
            .then((response) => {
                // Definir os subbairros carregados no estado local
                setSubBairros(response);
            })
            .catch((error) => {
                console.error('Erro ao carregar os subbairros:', error);
            });
    };

    // Estado local para armazenar os estados carregados

    const [estados, setEstados] = useState([]);
    // Estado local para armazenar os municípios carregados
    const [municipios, setMunicipios] = useState([]);

    // Estado local para armazenar os bairros carregados
    const [bairros, setBairros] = useState([]);

    // Estado local para armazenar os subbairros carregados
    const [subBairros, setSubBairros] = useState([]);

    // Estado local para armazenar a seleção do estado
    const [selectedState, setSelectedState] = useState(null);

    // Estado local para armazenar a seleção do município
    const [selectedMunicipality, setSelectedMunicipality] = useState(null);

    // Estado local para armazenar a seleção do bairro
    const [selectedBairro, setSelectedBairro] = useState(null);

    // Estado local para armazenar a seleção do bairro
    const [selectedSubBairro, setSelectedSubBairro] = useState(null);

    // Função para manipular a seleção do estado
    const handleStateChange = async (event, value) => {

        setSelectedMunicipality(null);
        setSelectedBairro(null);

        if (value?.id) {
            // Carregar os municípios com base no estado selecionado
            setSelectedState(value);
            await loadMunicipios(value.id);
        } else {
            setSelectedState(null)
            setMunicipios([]);
            setBairros([]);
            setSubBairros([]);
        }
    };

    // Função para manipular a seleção do município
    const handleMunicipalityChange = (event, value) => {
        setSelectedMunicipality(value);
        setSelectedBairro(null);

        if (value) {
            // Carregar os bairros com base no município selecionado
            loadBairros(value.id);
        } else {
            setSelectedMunicipality(null)
            setBairros([]);
            setSubBairros([]);
        }
    };

    // Função para manipular a seleção do bairro
    const handleBairroChange = (event, value) => {
        setSelectedBairro(value);

        if (value) {
            // Carregar os subbairros com base no bairro selecionado
            loadSubBairros(value.id);
        } else {
            setSelectedBairro(null)
            setSubBairros([]);
        }
    };
    // Função para manipular a seleção do subbairro
    const handleSubBairroChange = (event, value) => {
        setSelectedSubBairro(value);
    };
    // Loading
    const [loadingEstado, setLoadingEstado] = useState(false);
    const [loadingMunicipios, setLoadingMunicipios] = useState(false);
    const [loadingBairros, setLoadingBairros] = useState(false);
    const [loadingSubBairros, setLoadingSubBairros] = useState(false);
    // Style
    const stylesForm = {
        marginTop: '2rem',
        marginBottom: '1rem',
        display: 'flex',
        gap: '1rem',
        width: '100%',
    };

    // Deletar Vistoria
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedVistoria, setSelectedVistoria] = useState(null);

    const openDeleteModal = (vistoria) => {
        setSelectedVistoria(vistoria);
        setModalOpen(true);
    }

    const handleDeleteClick = vistoria => {
        setSelectedVistoria(vistoria);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedVistoria(null);
    };

    const handleConfirmDelete = async () => {
        try {
            if(selectedVistoria.statusDescricao != "PENDENTE" && usuario.role != "ROLE_ADMIN") {
                NotificationManager.error('A Vistoria não pode ser EXCLUÍDA!', 'Error!', 4000);
                return false;
            }
            const response = await delCrmVistoriasApi({ token: usuario?.auth?.token ?? "", id: selectedVistoria?.id });

            if (response.status === 204) { // Sucesso na deleção
                setModalOpen(false);
                onLoadVistorias()
                NotificationManager.success('A Vistoria foi EXCLUÍDA com sucesso!', 'Sucesso!', 4000);
            } else if (response.status === 404) {
                console.error("Vistoria não encontrada.");
                // Aqui você pode mostrar uma mensagem para o usuário indicando que a vistoria não foi encontrada.

            } else {
                console.error("Erro ao deletar vistoria.");
                // Aqui você pode mostrar uma mensagem genérica para o usuário indicando que ocorreu um erro.

            }
        } catch (error) {
            console.error("Erro na requisição de deletar vistoria:", error);
            // Aqui você pode mostrar uma mensagem genérica para o usuário indicando que ocorreu um erro na requisição.
        }
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
            <GestorDrawerComp {...props} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Grid container>
                            <Grid xs={12} md={12}>
                                <Typography variant="h3" component="h2">
                                    Vistorias
                                </Typography>
                            </Grid>
                            <Grid xs={12} md={12}>
                                <form style={stylesForm}>
                                    <Grid item xs={6} md={3}>
                                        {loadingEstado ? (
                                            <CircularProgress /> // Indicador de carregamento com spinner
                                        ) : (
                                            estados && (
                                                <Autocomplete
                                                    id="states-combo-box"
                                                    options={estados}
                                                    getOptionLabel={(option) => option.nome}
                                                    value={selectedState}
                                                    onChange={handleStateChange}
                                                    renderInput={(params) => <TextField {...params} label="Estado" variant="outlined" />}
                                                />
                                            )
                                        )}
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Autocomplete
                                            id="municipalities-combo-box"
                                            options={municipios}
                                            getOptionLabel={(option) => option.nome}
                                            value={selectedMunicipality}
                                            onChange={handleMunicipalityChange}
                                            renderInput={(params) => <TextField {...params} label="Município" variant="outlined" />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Autocomplete
                                            id="bairro-combo-box"
                                            options={bairros}
                                            getOptionLabel={(option) => option.nome}
                                            value={selectedBairro}
                                            onChange={handleBairroChange}
                                            renderInput={(params) => <TextField {...params} label="Bairro" variant="outlined" />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Autocomplete
                                            id="subbairro-combo-box"
                                            options={subBairros}
                                            getOptionLabel={(option) => option.nome}
                                            value={selectedSubBairro}
                                            onChange={handleSubBairroChange}
                                            renderInput={(params) => <TextField {...params} label="Sub Bairro" variant="outlined" />}
                                        />
                                    </Grid>
                                </form>
                            </Grid>
                            <Grid xs={6} md={6} container direction="row" spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel style={{ paddingLeft: '16px' }} id="agent-label">Agente</InputLabel>
                                        <Select
                                            labelId="agent-label"
                                            id="agent-select"
                                            value={selectedAgent}
                                            onChange={handleAgentChange}
                                            variant="outlined"
                                        >
                                            <MenuItem value="">Todos</MenuItem>
                                            {agentes.length > 0 && agentes.map((agente) => (
                                                <MenuItem key={agente.id} value={agente.id}>
                                                    {agente.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label="Data Vistoria"
                                        fullWidth
                                        type="date"
                                        name="dataVistoria"
                                        InputLabelProps={{ shrink: true }}
                                        value={selectedDate}
                                        onChange={(e) => setSelectedDate(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            {/* Menus */}
                            <Grid xs={12} md={6} container direction="row" spacing={1} justifyContent="flex-end" alignItems="flex-end">
                                <Grid item >
                                    <TextField
                                        id="outlined-adornment-password"
                                        className={classNames(classes.margin, classes.textField)}
                                        variant="outlined"
                                        label="Pesquisar"
                                        value={filter?.search ?? ''}
                                        onChange={onIptSearchChange}
                                        onKeyPress={(evt) => {
                                            if (evt.charCode === 13) {
                                                onLoadVistorias()
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Pesquisar"
                                                        onClick={onLoadVistoriasClick}
                                                    >
                                                        <Icon>search</Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item >
                                    <Button variant="contained" color="primary" onClick={onLoadVistoriasClick}>
                                        Filtrar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TableDataComp
                            size="small"
                            // loading={loading.list}
                            rows={payload?.data ?? []}
                            cols={tableCols}
                            actions={tableActionsList}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div id="mui-pagination">
                            <Pagination
                                count={payload?.total ?? 0}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                                className={classes.pagination}
                            />
                        </div>
                    </Grid>
                </Grid>
                {selectedVistoria && (
                    <DeleteVistoriaModal
                        open={modalOpen}
                        onClose={handleModalClose}
                        vistoria={selectedVistoria}
                        onDelete={handleConfirmDelete}
                    />
                )}
            </main>
        </div>
    )
}

VistoriaListPage.propTypes = {
    usuario: PropTypes.shape().isRequired,
    gestorClientes: PropTypes.shape().isRequired,
    onSearchClientes: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
    gestorClientes: state.gestorClientes,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        ...ConfigActions,
        ...GestorClientesActions,
        push,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VistoriaListPage)
