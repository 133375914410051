import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'

import { getProdutosApi } from '../../api/produtos'
import { Actions as UsuarioActions } from '../../store/ducks/usuario'
import { Actions as ProdutoActions } from '../../store/ducks/produto'
import { Actions as CarrinhoActions } from '../../store/ducks/carrinho'
import { Actions as ConfigActions } from '../../store/ducks/config'
import Header from '../../components/Header'
import ProdutoCard from '../../components/Produto'
import styles from './styles'

class HomePage extends Component {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    cliente: PropTypes.shape().isRequired,
    buscando: PropTypes.bool.isRequired,
    busca: PropTypes.string.isRequired,
    usuario: PropTypes.shape().isRequired,
    categorias: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    buscaDados: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onBuscando: PropTypes.func.isRequired,
    onProdutoBuscaField: PropTypes.func.isRequired,
    onLoadBuscaDados: PropTypes.func.isRequired,
    onLoadProdutosCategoria: PropTypes.func.isRequired,
    onAddProdutoCarrinho: PropTypes.func.isRequired,
    onUpdateProdutoQuant: PropTypes.func.isRequired,
    getParametro: PropTypes.func.isRequired,
  }

  state = {
    categoria: '',
  }

  componentDidMount() {
    const { onLoadProdutosCategoria } = this.props
    document.title = 'Online'
    document.body.style = 'background-image: none;'
    onLoadProdutosCategoria()
  }

  onProdutoBuscaIptKeypress = (evt) => {
    if (evt.charCode === 13) {
      this.onProdutoBuscaBtnClick()
    }
  }

  onProdutoBuscaBtnClick = () => {
    const { categoria } = this.state
    const {
      busca, usuario, onLoadBuscaDados, onBuscando,
    } = this.props
    // if (busca) {
    const query = {
      busca,
      ...usuario.auth,
      categoriaId: categoria || null,
      clienteId: usuario.cliente.id,
      filialId: usuario.unidade.id,
    }
    onBuscando()
    getProdutosApi(query).then((payload) => {
      onBuscando()
      if (payload && _.isArray(payload)) {
        onLoadBuscaDados(payload)
      }
    })
  }

  onCategoriaChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { categoria } = this.state
    const {
      classes,
      usuario,
      categorias = [],
      buscando,
      busca,
      buscaDados = [],
      // maisVendidos,
      onProdutoBuscaField,
      onAddProdutoCarrinho,
      onUpdateProdutoQuant,
      getParametro,
    } = this.props
    const fatorMulti = getParametro('FATOR_MULTI', 10)
    const utilizaBanner = getParametro('UTILIZA_BANNER', 'N')
    return (
      <Fragment>
        <Header />
        <Grid container direction="row" justifyContent="center" alignItems="center">
          {/* IPT DE PESQUISA */}
          <Grid item xs={8}>
            <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={3}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="categorias">Categoria(s)</InputLabel>
                  <Select
                    fullWidth
                    value={categoria}
                    onChange={this.onCategoriaChange}
                    inputProps={{
                      name: 'categoria',
                      id: 'categoria',
                    }}
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {categorias.map(c => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="ipt-pesquisa-produto"
                  label="Pesquisar"
                  placeholder="Procure por código do produto, nome, descrição ou peso."
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={busca}
                  onChange={evt => onProdutoBuscaField(evt.target.value)}
                  onKeyPress={evt => this.onProdutoBuscaIptKeypress(evt)}
                />
              </Grid>
              <Grid item>
                <Fab color="primary" aria-label="Add" onClick={() => this.onProdutoBuscaBtnClick()}>
                  {buscando ? <CircularProgress size={24} color="secondary" /> : <SearchIcon />}
                </Fab>
              </Grid>
            </Grid>
          </Grid>
          {utilizaBanner === 'SIM' && (
          <Grid item xs={8} className={[classes.banner, classes.compMargin].join(' ')}>
            <img
              className={classes.imgBanner}
              src={require('../../assets/imagens/banner.jpg')}
              alt="banners"
            />
          </Grid>
          )}
          {buscaDados && buscaDados.length === 0 ? (
            <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={8}>
                <Typography variant="h5" gutterBottom>
                  Nenhum produto localizado, você pode melhorar a pesquisa.
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Fragment>
              <Grid item xs={8} style={{ paddingTop: 20 }}>
                <Typography component="h4" variant="h4" gutterBottom>
                  Resultado da pesquisa:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid
                  container
                  direction="row"
                  spacing={8}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {buscaDados.map(p => (
                    <Grid key={p.produtoId} item>
                      <ProdutoCard
                        data={{ ...p, unidade: usuario.unidade }}
                        fator={fatorMulti}
                        onAddProdutoCarrinho={data => onAddProdutoCarrinho(data, { duplicaProduto: 'S' })}
                        onUpdateProdutoQuant={onUpdateProdutoQuant}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  cliente: state.usuario.usuario.cliente,
  buscando: state.produto.buscando,
  busca: state.produto.busca,
  buscaDados: state.produto.buscaDados,
  categorias: state.produto.categorias,
  maisVendidos: state.produto.maisVendidos,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...UsuarioActions,
    ...ProdutoActions,
    ...CarrinhoActions,
    ...ConfigActions,
  },
  dispatch,
)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(HomePage),
)
