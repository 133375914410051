import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import _ from 'lodash'

import {
  Typography,
  CssBaseline,
  Grid,
  Paper,
  Tabs,
  Tab,
  Fab,
  Icon,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core'

import { getClienteInfoByIdApi } from '../../../../api/cliente'
import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as DialogActions } from '../../../../store/ducks/dialog'
import { Actions as GestorUsuariosActions } from '../../../../store/ducks/gestor/usuarios'
import { Actions as UsuarioActions } from '../../../../store/ducks/usuario'
import GestorHeaderComp from '../../../../components/Gestor/Header'
import GestorDrawerComp from '../../../../components/Gestor/Drawer'
import AprovacoesStatusGridComp from '../../../../components/Dialogs/Usuario/AprovacoesStatus'
import TableDataComp from '../../../../components/Table'
import { DialogContentSimples } from '../../../../components/Dialogs/Contents'

import useStyles from './styles'

const tableCols = [
  { key: 'id', title: 'ID' },
  { key: 'nome', title: 'NOME' },
  { key: 'email', title: 'E-MAIL' },
  { key: 'statusDesc', title: 'STATUS' },
]

const DialogContentDetalhes = (props) => {
  const { row } = props
  const [expanded, setExpanded] = useState('dadosEmpresa')
  const [cliente, setCliente] = useState(null)
  const classes = useStyles()

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  useEffect(() => {
    getClienteInfoByIdApi({
      token: row.auth.token,
      clienteId: row.cliente.id,
      usuarioId: row.id,
    }).then((resp) => {
      setCliente(resp)
    })
  }, [])

  return !cliente ? (
    <CircularProgress />
  ) : (
    <>
      <ExpansionPanel
        square
        className={classes.expansionPanel}
        expanded={expanded === 'dadosEmpresa'}
        onChange={handleChange('dadosEmpresa')}
      >
        <ExpansionPanelSummary>
          <Typography variant="h6" gutterBottom>
            #1 Dados da Empresa
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
              <Typography variant="button">RAZÃO SOCIAL:</Typography>
              <Typography gutterBottom>{` ${cliente.razao || ''}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="button">CGC:</Typography>
              <Typography gutterBottom>{` ${cliente.cgc || ''}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="button">TIPO:</Typography>
              <Typography gutterBottom>{` ${cliente.tipoPessoa || ''}`}</Typography>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === 'dadosEnderecoEmpresa'}
        onChange={handleChange('dadosEnderecoEmpresa')}
      >
        <ExpansionPanelSummary>
          <Typography variant="h6" gutterBottom>
            #2 Endereço da Empresa
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
              <Typography variant="button">LOGRADOURO:</Typography>
              <Typography gutterBottom>
                {` ${cliente.logradouro || ''}, ${cliente.numero
                  || ''} COMP: ${cliente.complemento || ''}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="button">BAIRRO:</Typography>
              <Typography gutterBottom>{` ${cliente.bairro || ''}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="button">CIDADE:</Typography>
              <Typography gutterBottom>
                {` ${cliente.cidade || ''} UF: ${cliente.uf || ''}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="button">CEP:</Typography>
              <Typography gutterBottom>{` ${cliente.cep || ''}`}</Typography>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === 'dadosUsuario'}
        onChange={handleChange('dadosUsuario')}
      >
        <ExpansionPanelSummary>
          <Typography variant="h6" gutterBottom>
            #3 Dados Usuário
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
              <Typography variant="button">NOME:</Typography>
              <Typography gutterBottom>{` ${row.nome || ''}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="button">E-MAIL:</Typography>
              <Typography gutterBottom>{` ${row.email || ''}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="button">TELEFONE:</Typography>
              <Typography gutterBottom>{` ${row.telefone || ''}`}</Typography>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  )
}

DialogContentDetalhes.propTypes = {
  row: PropTypes.shape().isRequired,
}

const mapStateToProps2 = state => ({
  generic: state.dialog.generic,
})

const mapDispatchToProps2 = dispatch => bindActionCreators(DialogActions, dispatch)

const DialogContentReprovar = connect(
  mapStateToProps2,
  mapDispatchToProps2,
)((props) => {
  const { text, generic, onUpdateGeneric } = props
  return (
    <Grid container>
      <Grid item xs={12}>
        {text}
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="motivo"
          label="Motivo da reprovação:"
          value={generic.motivo}
          onChange={evt => onUpdateGeneric({ motivo: evt.target.value })}
          margin="normal"
        />
      </Grid>
    </Grid>
  )
})

DialogContentReprovar.propTypes = {
  text: PropTypes.string.isRequired,
}

const GestorAlcadasListagemPage = (props) => {
  const {
    unidade,
    usuario,
    gestorUsuarios: { aprovacoes, loading },
    onDialogOpen,
    onDialogClose,
    onDialogActionLoading,
    onLoadDataGuAprovacoes,
    onAprovaReprovaUsuario,
  } = props
  // State
  const [iptSearch, setIptSearch] = useState('')
  const [tabSelected, setTabSelected] = React.useState(0)
  const classes = useStyles()

  const handleLoadDataGuAprovacoes = (action = null) => {
    const value = action === null ? tabSelected : action
    switch (value) {
      case 1:
        onLoadDataGuAprovacoes('B', iptSearch)
        break
      default:
        onLoadDataGuAprovacoes('P', iptSearch)
        break
    }
  }
  const handleSelectTab = (event, newValue) => {
    setTabSelected(newValue)
    handleLoadDataGuAprovacoes(newValue)
  }
  // DidMount :D
  useEffect(() => {
    document.title = 'Online - Gestor | Aprovações'
    document.body.style = 'background-image: none;'
    handleLoadDataGuAprovacoes()
  }, [unidade])

  const tableActionsList = [
    {
      key: 'aprovar',
      title: 'Aprovar',
      icon: 'thumb_up',
      action: row => onDialogOpen({
        size: 'sm',
        title: 'Aprovar?',
        content: (
          <DialogContentSimples text={`Você confirma a aprovação do usuário: "${row.nome}" ?`} />
        ),
        actions: [
          {
            key: 1,
            title: 'Aprovar',
            action: () => {
              onDialogActionLoading({ key: 1, loading: true })
              onAprovaReprovaUsuario(row, 'L', '', () => {
                onDialogActionLoading({ key: 1, loading: false })
                onDialogClose()
                handleLoadDataGuAprovacoes()
              })
            },
          },
          { key: 2, title: 'Cancelar', action: () => onDialogClose() },
        ],
      }),
    },
    {
      key: 'reprovar',
      title: 'Reprovar',
      icon: 'thumb_down',
      disabled: tabSelected === 1,
      action: row => onDialogOpen({
        size: 'sm',
        title: 'Reprovar?',
        content: (
          <DialogContentReprovar
            text={`Você confirma a reprovação do usuário: "${row.nome}" ?`}
          />
        ),
        actions: [
          {
            key: 1,
            title: 'Reprovar',
            action: () => {
              onDialogActionLoading({ key: 1, loading: true })
              onAprovaReprovaUsuario(row, 'B', null, () => {
                onDialogActionLoading({ key: 1, loading: false })
                onDialogClose()
                handleLoadDataGuAprovacoes()
              })
            },
            color: 'secondary',
          },
          { key: 2, title: 'Cancelar', action: () => onDialogClose() },
        ],
      }),
    },
    {
      key: 'detalhes',
      title: 'Detalhes',
      icon: 'list',
      action: row => onDialogOpen({
        title: 'Detalhes',
        content: <DialogContentDetalhes row={{ ...row, auth: usuario.auth }} />,
      }),
    },
    {
      key: 'aprovacoes',
      title: 'Aprovações',
      icon: 'done_all',
      action: row => onDialogOpen({
        title: `Detalhes das aprovações do usuário: "${row.nome}".`,
        content: (
          <AprovacoesStatusGridComp
            dto={{
              token: usuario.auth.token,
              usuario: row.id,
              filial: row.unidade.id,
              cliente: row.cliente.id,
            }}
          />
        ),
      }),
    },
  ]

  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          {/* Header - Page */}
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" component="h2">
                  Aprovações
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container direction="row" justifyContent="flex-end">
                  <TextField
                    id="outlined-adornment-password"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label="Pesquisar"
                    value={iptSearch}
                    onChange={evt => setIptSearch(evt.target.value.toUpperCase())}
                    onKeyPress={(evt) => {
                      if (evt.charCode === 13) {
                        handleLoadDataGuAprovacoes()
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Pesquisar"
                            onClick={
                              () => handleLoadDataGuAprovacoes()
                            }
                          >
                            <Icon>search</Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/*
                  <Tooltip title="Adicionar">
                    <div>
                      <Fab disabled color="primary" aria-label="Add" className={classes.fab}>
                        <Icon>add</Icon>
                      </Fab>
                    </div>
                  </Tooltip>
                  */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Tab */}
          <Grid item xs={12} md={12}>
            <Paper className={classes.root}>
              <Tabs
                value={tabSelected}
                onChange={handleSelectTab}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Pendente(s)" />
                <Tab label="Bloqueado(s)" />
              </Tabs>
            </Paper>
          </Grid>
          {/* Page content */}
          <Grid item xs={12} md={12}>
            <TableDataComp
              loading={loading.aprovacoes}
              rows={aprovacoes.list}
              cols={tableCols}
              actions={tableActionsList}
            />
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

GestorAlcadasListagemPage.propTypes = {
  unidade: PropTypes.shape().isRequired,
  usuario: PropTypes.shape().isRequired,
  gestorUsuarios: PropTypes.shape().isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onDialogLoading: PropTypes.func.isRequired,
  onDialogActionLoading: PropTypes.func.isRequired,
  onLoadDataGuAprovacoes: PropTypes.func.isRequired,
  onAprovaReprovaUsuario: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  unidade: state.usuario.usuario.unidade,
  generic: state.dialog.generic,
  gestorUsuarios: state.gestorUsuarios,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...GestorUsuariosActions,
    ...UsuarioActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GestorAlcadasListagemPage)
