import React from 'react'
import PropTypes from 'prop-types'
import { TextField, InputAdornment } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import zxcvbn from 'zxcvbn'

import useStyles from './styles'

const PasswordMeterComp = (props) => {
  const {
    label, value, onChange, maxlength,
  } = props
  const classes = useStyles()
  const pswStrength = zxcvbn(value)

  const pswLevel = (result) => {
    switch (result.score) {
      case 0:
        return 'Muito fraca'
      case 1:
        return 'Fraca'
      case 2:
        return 'Boa'
      case 3:
        return 'Forte'
      case 4:
        return 'Excelente'
      default:
        return 'Muito fraca'
    }
  }

  return (
    <TextField
      className={classes[`textField${pswStrength.score}`]}
      autoComplete="new-password"
      label={label}
      type="password"
      fullWidth
      value={value}
      onChange={onChange}
      helperText={value && pswLevel(pswStrength)}
      inputProps={{
        maxlength,
        endAdornment:
          pswStrength.score >= 4 ? (
            <InputAdornment position="end">
              <Done style={{ color: 'green' }} />
            </InputAdornment>
          ) : null,
      }}
    />
  )
}

PasswordMeterComp.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxlength: PropTypes.func,
}
PasswordMeterComp.defaultProps = {
  maxlength: 10,
}

export default PasswordMeterComp
