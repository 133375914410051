import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Paper,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import { Actions as DialogActions } from '../../../../store/ducks/dialog'
import styles from './styles'

const BuscaGenericaComp = (props) => {
  const {
    rowKey,
    rowValue,
    subRowValue,
    classes,
    isLoading,
    itens,
    placeholder,
    onSearchHandle,
    onSelectItem,
    onSearchGenericClean,
  } = props
  useEffect(() => {
    onSearchGenericClean()
  }, [])
  const [iptSearch, setIptSearch] = useState('')

  const GridResultLine = ({ classes, itens, onSelectItem }) => itens.map(item => (
    <TableRow
      key={item[rowKey]}
      className={classes.tableRow}
      hover
      onClick={() => onSelectItem(item)}
    >
      <TableCell component="td" scope="row">
        <Typography variant="h6" style={{ display: 'grid' }}>
          {item[rowValue]}
          {subRowValue && (<small>{item[subRowValue] || 'NENHUM'}</small>)}
        </Typography>
      </TableCell>
    </TableRow>
  ))

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
      <Grid item xs={12} md={12}>
        <Paper className={classes.root} elevation={1}>
          <InputBase
            className={classes.input}
            value={iptSearch}
            placeholder={placeholder}
            onChange={evt => setIptSearch(evt.target.value.toUpperCase())}
            onKeyPress={(evt) => {
              if (evt.charCode === 13) {
                onSearchHandle(iptSearch)
              }
            }}
          />
          <IconButton
            disabled={isLoading}
            className={classes.iconButton}
            aria-label="Buscar"
            onClick={() => onSearchHandle(iptSearch)}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <Table>
            <TableBody>
              <GridResultLine classes={classes} itens={itens} onSelectItem={onSelectItem} />
            </TableBody>
          </Table>
        )}
      </Grid>
    </Grid>
  )
}

BuscaGenericaComp.propTypes = {
  rowKey: PropTypes.string,
  rowValue: PropTypes.string,
  subRowValue: PropTypes.string,
  classes: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
  itens: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  placeholder: PropTypes.string.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  onSearchHandle: PropTypes.func.isRequired,
  onSearchGenericClean: PropTypes.func.isRequired,
}
BuscaGenericaComp.defaultProps = {
  rowKey: 'codigo',
  rowValue: 'descricao',
  subRowValue: '',
}

const mapStateToProps = state => ({
  isLoading: state.dialog.searchGeneric.loading,
  itens: state.dialog.searchGeneric.data.itens,
})

const mapDispatchToProps = dispatch => bindActionCreators(DialogActions, dispatch)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BuscaGenericaComp),
)
