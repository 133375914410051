import React from 'react'
import PropTypes from 'prop-types'
import {
  MapContainer,
  Marker,
  TileLayer,
  // Popup,
} from 'react-leaflet'

import {
  CircularProgress,
} from '@material-ui/core'


const GestorDashboardMaps = (props) => {
  const { positions, loadingPositions } = props
  const defaultProps = {
    position: [-12.211180, -46.757813],
    zoom: 4,
  }

  if (loadingPositions) return <CircularProgress />

  return (
    <MapContainer center={defaultProps.position} zoom={defaultProps.zoom} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {
        positions.map(p => (
          <Marker position={[p.lat, p.lng]} />
        ))
      }
    </MapContainer>
  )
}

GestorDashboardMaps.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loadingPositions: PropTypes.bool,
}

GestorDashboardMaps.defaultProps = {
  loadingPositions: false,
}

export default GestorDashboardMaps
