import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
  Grid,
  Icon,
  TextField,
  InputAdornment,
  IconButton,
  CssBaseline,
  Paper,
  Divider,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Button,
} from '@material-ui/core'

import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import TableDataComp from '../../../../../../../../components/Table'
import useStyles from './styles'
import { editByIdVisitaApi, findByIdVisitaApi, getVisitasFilterApi } from 'api/visitas'
import { DialogContentSimples } from 'components/Dialogs/Contents'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import { Save } from '@material-ui/icons'
import { NotificationManager } from 'react-notifications'
import ReactInputMask from 'react-input-mask'


const VisitaDetailDadosTab = (props) => {
  const {
    usuario, push, params
  } = props
  const classes = useStyles()

  const [payload, setpayload] = useState([])

  const OnSerachVisitas = () => {
    findByIdVisitaApi({ token: usuario?.auth?.token ?? '', id: params.id })
      .then((resp) => {
        setpayload(resp)
      })
  }
  const onUpdatePayloadField = (updateObj) => {
    setpayload((prevPayload) => ({ ...prevPayload, ...updateObj }));
  };
  const editVisita = async (payload, token) => {
    const moment = require('moment');
    const formatoOriginal = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/;

    if (formatoOriginal.test(payload.data)) {
      // Convertendo a data para o novo formato usando o Moment.js
      const dataConvertida = moment(payload.data, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      payload.data = dataConvertida; // Output: 2023-04-06T12:00:00.000Z
    } else {
      console.log('A data original não está no formato correto.');
    }
    const resp = await editByIdVisitaApi({ token: usuario?.auth?.token, id: payload?.id, body: payload });
    setpayload(resp);
    NotificationManager.success(
      'Editado com sucesso!',
      'Atenção',
      8000,
    )
  }


  useEffect(() => {
    document.title = 'Online - Visita'
    document.body.style = 'background-image: none;'
    OnSerachVisitas()
  }, [])
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Grid container spacing={2}>
          {/* Body */}
          <Paper className={classes.paper} elevation={1}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  disabled
                  id="status"
                  label="Status da Visita"
                  margin="normal"
                  variant="outlined"
                  value={payload.visStatusDesc ? payload.visStatusDesc.toString().toUpperCase() : ''}
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  disabled
                  id="resultado"
                  label="Resultado da Visita"
                  margin="normal"
                  variant="outlined"
                  value={payload.visResultadoDesc ? payload.visResultadoDesc.toString().toUpperCase() : ''}
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="termo"
                  label="Termo:"
                  margin="normal"
                  variant="outlined"
                  value={payload.termoNum ? payload.termoNum.toString().toUpperCase() : ""}
                  className={classes.textField}
                  onChange={evt => onUpdatePayloadField({ termoNum: evt.target.value.toUpperCase() })}
                />
              </Grid>
              <Grid item xs={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data:"
                    value={payload.data}
                    onChange={(date) => onUpdatePayloadField({ data: date })}
                    invalidDateMessage="" // Remove invalid date format validation
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12}>
                <Divider variant="middle" className={classes.customDivider} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="entrevistado"
                  label="Entrevistado:"
                  margin="normal"
                  variant="outlined"
                  value={payload.entrevNome ? payload.entrevNome.toString().toUpperCase() : ""}
                  className={classes.textField}
                  onChange={evt => onUpdatePayloadField({ entrevNome: evt.target.value.toUpperCase() })}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth variant="outlined" >
                  <InputLabel id="responsavel-label">Responsável pelo imóvel</InputLabel>
                  <Select
                    labelId="responsavel-label"
                    id="responsavel"
                    value={payload.entrevRespImovel ? payload.entrevRespImovel : ''}
                    onChange={evt => onUpdatePayloadField({ entrevRespImovel: evt.target.value })}
                    label="Responsável pelo imóvel"
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    <MenuItem value="SIM">SIM</MenuItem>
                    <MenuItem value="NÃO">NÃO</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Divider variant="middle" className={classes.customDivider} />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="respEmail"
                  label="E-mail do Responsável:"
                  margin="normal"
                  variant="outlined"
                  value={payload.respEmail ? payload.respEmail.toString().toUpperCase() : ""}
                  className={classes.textField}
                  onChange={evt => onUpdatePayloadField({ respEmail: evt.target.value.toUpperCase() })}
                />
              </Grid>
              <Grid item xs={3}>
                <ReactInputMask
                  mask="(99) 99999-9999"
                  value={payload.respCelNum ? payload.respCelNum.toString().toUpperCase() : ''}
                  onChange={evt => onUpdatePayloadField({ respCelNum: evt.target.value.toUpperCase() })}
                >
                  {() => (
                    <TextField
                      fullWidth
                      id="respCelNum"
                      label="Celular do Responsável"
                      margin="normal"
                      variant="outlined"
                      className={classes.textField}
                    />
                  )}
                </ReactInputMask>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="sexo-label">Sexo do Responsável</InputLabel>
                  <Select
                    labelId="sexo-label"
                    id="respSexo"
                    value={payload.respSexo ? payload.respSexo : ''}
                    onChange={evt => onUpdatePayloadField({ respSexo: evt.target.value })}
                    label="Sexo do Responsável"
                  >
                    <MenuItem value="">SELECIONE</MenuItem>
                    <MenuItem value="MASCULINO">MASCULINO</MenuItem>
                    <MenuItem value="FEMININO">FEMININO</MenuItem>
                    <MenuItem value="OUTROS">OUTROS</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="sexo-label">Gênero do Responsável</InputLabel>
                  <Select
                    labelId="genero-label"
                    id="respGenero"
                    value={payload.respGenero ? payload.respGenero : ''}
                    onChange={evt => onUpdatePayloadField({ respGenero: evt.target.value })}
                    label="Gênero do Responsável"
                  >
                    <MenuItem value="">SELECIONE</MenuItem>
                    <MenuItem value="HOMEM">HOMEM</MenuItem>
                    <MenuItem value="MULHER">MULHER</MenuItem>
                    <MenuItem value="OUTROS">OUTROS</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="respNome"
                  label="Nome do Responsável"
                  margin="normal"
                  variant="outlined"
                  value={payload.respNome ? payload.respNome.toString().toUpperCase() : ''}
                  className={classes.textField}
                  onChange={evt => onUpdatePayloadField({ respNome: evt.target.value.toUpperCase() })}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="respPai"
                  label="Nome do pai do Responsável"
                  margin="normal"
                  variant="outlined"
                  value={payload.respPai ? payload.respPai.toString().toUpperCase() : ''}
                  className={classes.textField}
                  onChange={evt => onUpdatePayloadField({ respPai: evt.target.value.toUpperCase() })}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="respMae"
                  label="Nome da mãe do Responsável"
                  margin="normal"
                  variant="outlined"
                  value={payload.respMae ? payload.respMae.toString().toUpperCase() : ''}
                  className={classes.textField}
                  onChange={evt => onUpdatePayloadField({ respMae: evt.target.value.toUpperCase() })}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider variant="middle" className={classes.customDivider} />
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={6}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => editVisita(payload, usuario?.auth?.token)}
                    >
                      <Save className={classes.leftIcon} />
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </main>
    </div >
  )
}

VisitaDetailDadosTab.propTypes = {
  push: PropTypes.func.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  gestorUsuarios: state.gestorUsuarios,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    push: crrPush,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VisitaDetailDadosTab)
