import React from 'react'
import { bindActionCreators } from 'redux'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import HomePage from '../pages/home'
import FinanceiroPage from '../pages/financeiro'
import GestorDashboardPage from '../pages/gestor/dashboard'
import GestorCrmDashboardPage from '../pages/gestor/crm/dashboard'
import { Actions as ConfigActions } from '../store/ducks/config'

const PrivateRoute = ({
  usuario, location, getParametro, ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (usuario.status === 'L') {
        const REALIZA_VENDAS = getParametro('REALIZA_VENDAS', 'SIM')
        if (REALIZA_VENDAS !== 'SIM') {
          return <FinanceiroPage {...props} />
        }
        if ('ROLE_CLIENTE/ROLE_VENDEDOR'.includes(usuario.role)) {
          return <HomePage {...props} />
        }
        if ('ROLE_CRM'.includes(usuario.role)) {
          return <GestorCrmDashboardPage {...props} />
        }
        return <GestorDashboardPage {...props} />
      }
      return <Redirect to={{ pathname: '/login', state: { from: location } }} />
    }}
  />
)

PrivateRoute.propTypes = {
  usuario: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  getParametro: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...ConfigActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateRoute)
