import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Numeral from 'numeral'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Badge,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Chip,
  Avatar,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FormatLineSpacingIcon from '@material-ui/icons/FormatListBulleted'

import Header from '../../components/Header'
import { getStatusById } from '../../utils/enums/StatusPedido'
import FiltroPedidoDialog from '../../components/Dialogs/Pedido/Filtro'
import { Actions as PedidosActions } from '../../store/ducks/pedidos'
import styles from './styles'

class PedidosPage extends Component {
  static propTypes = {
    match: PropTypes.shape().isRequired,
    classes: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
    filtrando: PropTypes.shape().isRequired,
    pedidos: PropTypes.shape().isRequired,
    pedStatus: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onLoadPedidos: PropTypes.func.isRequired,
    onLoadPedidoStatus: PropTypes.func.isRequired,
  }

  state = {
    filtroOpen: false,
    filtro: {
      pedido: '',
      dtInicial: '',
      dtFinal: '',
    },
  }

  componentDidMount() {
    const { onLoadPedidos, onLoadPedidoStatus, match } = this.props
    const statusId = match.params.status || null
    document.title = 'Online - Pedidos'
    onLoadPedidos(statusId, 30)
    onLoadPedidoStatus()
  }

  onOpenCloseFiltro = () => {
    this.setState(state => ({ filtroOpen: !state.filtroOpen }))
  }

  onConfirmFiltro = () => {}

  onChangeFiltro = (payload) => {
    const { filtro } = this.state
    this.setState({ ...filtro, filtro: payload })
  }

  createData = (id, name, calories, fat, carbs, protein) => ({
    id,
    name,
    calories,
    fat,
    carbs,
    protein,
  })

  render() {
    const { filtroOpen, filtro } = this.state
    const {
      classes, filtrando, pedidos, history, pedStatus, onLoadPedidos,
    } = this.props
    const totalUltimosPedidos = pedStatus.find(ps => ps.pedidoStatusId === 999)
    return (
      <Fragment>
        <Header />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.root}
        >
          {/* MENU FILTROS */}
          <Grid item xs={2}>
            <List component="nav">
              <ListItem selected>
                <FormatLineSpacingIcon />
                <ListItemText primary="Pedidos" />
              </ListItem>
              <ListItem button onClick={() => onLoadPedidos(null, 30)}>
                <ChevronRight />
                <ListItemText primary="ÚLTIMOS PEDIDOS" />
                <Badge
                  badgeContent={totalUltimosPedidos ? totalUltimosPedidos.total : 0}
                  color="secondary"
                >
                  {''}
                </Badge>
              </ListItem>
              {pedStatus
                .filter(ps => ps.pedidoStatusId !== 999)
                .map(ps => (
                  <ListItem
                    key={ps.pedidoStatusId}
                    button
                    onClick={() => onLoadPedidos(ps.pedidoStatusId)}
                  >
                    <ChevronRight />
                    <ListItemText primary={getStatusById(ps.pedidoStatusId).descricao} />
                    <Badge badgeContent={ps.total} color="secondary">
                      {''}
                    </Badge>
                  </ListItem>
                ))}
            </List>
          </Grid>
          {/* GRID */}
          <Grid item xs={10}>
            <Card className={[classes.card, classes.grid].join(' ')}>
              <CardHeader
                title="Pedidos"
                action={(
                  <IconButton onClick={this.onOpenCloseFiltro}>
                    <SearchIcon />
                  </IconButton>
)}
              />
              <CardContent>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">STATUS</TableCell>
                        <TableCell>PEDIDO</TableCell>
                        <TableCell>EMISSÃO</TableCell>
                        <TableCell>TOTAL</TableCell>
                        <TableCell align="right">ITENS</TableCell>
                        <TableCell align="right">PESO</TableCell>
                        <TableCell>UNIDADE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filtrando ? (
                        <TableRow>
                          <TableCell>
                            <CircularProgress size={16} />
                            {' Filtrando... '}
                          </TableCell>
                        </TableRow>
                      ) : (
                        pedidos.map(pedido => (
                          <TableRow
                            hover
                            className={classes.tableRow}
                            key={pedido.id}
                            onClick={() => history.push(`/pedidos/detalhe/${pedido.id}`)}
                          >
                            <TableCell align="center">
                              <Chip
                                avatar={<Avatar>{getStatusById(pedido.statusId).icon}</Avatar>}
                                label={pedido.statusDesc}
                              />
                            </TableCell>
                            <TableCell>{`${pedido.ref || '-'}`}</TableCell>
                            <TableCell>
                              {moment(pedido.emissao, 'YYYY-MM-DD HH:mm').format(
                                'DD/MM/YYYY HH:mm',
                              )}
                            </TableCell>
                            <TableCell>{`R$ ${Numeral(pedido.total).format('0,0.00')}`}</TableCell>
                            <TableCell align="right">{pedido.qtdItens}</TableCell>
                            <TableCell align="right">{`${Numeral(pedido.qtdPeso).format('0,0.00')}kg`}</TableCell>
                            <TableCell>{pedido.unidadeDesc}</TableCell>
                          </TableRow>
                        ))
                      )}
                      {pedidos.length === 0 && !filtrando && (
                        <TableRow>
                          <TableCell>NENHUM PEDIDO ATÉ O MOMENTO.</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Paper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <FiltroPedidoDialog
          open={filtroOpen}
          title="Filtro"
          filtro={filtro}
          onClose={this.onOpenCloseFiltro}
          onConfirm={this.onConfirmFiltro}
          onChange={this.onChangeFiltro}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  cliente: state.usuario.usuario.cliente,
  loading: state.pedidos.loading,
  pedidos: state.pedidos.pedidos,
  pedStatus: state.pedidos.pedStatus,
  filtrando: state.pedidos.filtrando,
})

const mapDispatchToProps = dispatch => bindActionCreators(PedidosActions, dispatch)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PedidosPage),
)
