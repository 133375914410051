import React, { Fragment } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Tooltip,
  Button,
  Icon,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import moment from 'moment'

// Components
import TrackingSSW from '../Tracking/SSW'

import useStyles from './styles'

function PedidoDetalhesAtualizacoesComp(props) {
  const {
    itens = [],
    gerando = {},
    actions = {},
    params = {},
  } = props
  const classes = useStyles()
  const { kTrackingDanfe = 'NAO' } = params
  return (
    <Paper className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Detalhes
        </Typography>
      </div>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>CÓDIGO</TableCell>
            <TableCell>EMISSÃO</TableCell>
            <TableCell>AGENDAMENTO</TableCell>
            <TableCell>CARREGAMENTO</TableCell>
            <TableCell>
              NOTA #1
              {kTrackingDanfe === 'SIM' ? ' / RASTREIO' : ''}
            </TableCell>
            <TableCell>
              NOTA #2
              {kTrackingDanfe === 'SIM' ? ' / RASTREIO' : ''}
            </TableCell>
            <TableCell>BOLETOS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itens.map((i) => {
            const { updates = [] } = i
            return (
              <Fragment key={i?.id}>
                {updates.map(u => (
                  <TableRow key={u.datacad}>
                    <TableCell>{i.produtoSku}</TableCell>
                    <TableCell>
                      {moment(u.datacad, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>{u.agendamento}</TableCell>
                    <TableCell>{u.ordemcarreg}</TableCell>
                    <TableCell>
                      {u.nota1 && (
                        <Tooltip
                          title="Download de boleto"
                          aria-label="Download de boleto"
                        >
                          <Button
                            onClick={() => actions.handleDownloadDanfeXML(
                              { doc: u.nota1, serie: u.serie1 },
                              'danfe',
                            )
                            }
                          >
                            {(gerando && gerando.documento === u.nota1 && gerando.tipo === 'danfe') ? (
                              <CircularProgress size={16} />
                            ) : (
                              <>
                                {`${u.nota1}/${u.serie1}`}
                                <Icon style={{ marginLeft: 5 }}>save_alt</Icon>
                              </>
                            )}
                          </Button>
                        </Tooltip>
                      )}
                      {kTrackingDanfe === 'SIM' && u.chave1 && (
                        <TrackingSSW chaveNfe={u.chave1} />
                      )}
                    </TableCell>
                    <TableCell>
                      {u.nota2 && (
                        <Button
                          onClick={() => actions.handleDownloadDanfeXML(
                            { doc: u.nota2, serie: u.serie2 },
                            'danfe',
                          )
                          }
                        >
                          {(gerando && gerando.documento === u.nota2 && gerando.tipo === 'danfe') ? (
                            <CircularProgress size={16} />
                          ) : (
                            <Fragment>
                              {`${u.nota2}/${u.serie2}`}
                              <Icon style={{ marginLeft: 5 }}>save_alt</Icon>
                            </Fragment>
                          )}
                        </Button>
                      )}
                      {kTrackingDanfe === 'SIM' && u.chave2 && (
                        <TrackingSSW chaveNfe={u.chave2} />
                      )}
                    </TableCell>
                    <TableCell>
                      {(u.nota1 || u.nota2) && (
                        <Button
                          onClick={() => actions.handleShowBoletos(
                            u.nota1,
                            u.serie1,
                            u.nota2,
                            u.serie2,
                          )
                          }
                        >
                          BOLETOS
                          <Icon>attach_money</Icon>
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </Fragment>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}

PedidoDetalhesAtualizacoesComp.propTypes = {
  itens: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  gerando: PropTypes.shape().isRequired,
  actions: PropTypes.shape().isRequired,
  params: PropTypes.shape(),
}

PedidoDetalhesAtualizacoesComp.defaultProps = {
  params: { kTrackingDanfe: 'NAO' },
}

export default PedidoDetalhesAtualizacoesComp
