import { push } from 'connected-react-router'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'


import {
  filterCrmContatosApi,
  findByIdCrmContatosApi,
  addCrmContatosApi,
  editCrmContatosApi,
  delCrmContatosApi,
} from 'api/crm/contatos'

import { validarCPF } from 'utils'

// import { Actions as DialogActions } from '../dialog'

// State Crm/Contato
const initialState = {
  loading: {
    list: false,
    save: false,
    payload: false,
  },
  list: [],
  form: {
    id: 0,
    entidadeId: 0,
    setor: '',
    tipo: '',
    codigo: '',
    cgc: '',
    nome: '',
    email: '',
    telefone: '',
    status: '',
    statusDescricao: '',
  },
}

// Actions Types
export const Types = {
  CC_LOADING: 'crmContatos/CC_LOADING',
  CC_UPDATE_LIST: 'crmContatos/CC_UPDATE_LIST',
  CC_UPDATE_CONTATO: 'crmContatos/CC_UPDATE_CONTATO',
  CC_UPDATE_CONTATO_FIELD: 'crmContatos/CC_UPDATE_CONTATO_FIELD',
}

// Reducers
export default function crmContatosReducers(state = initialState, action) {
  switch (action.type) {
    case Types.CC_LOADING:
      return { ...state, loading: { ...state.loading, ...action.payload } }
    case Types.CC_UPDATE_LIST:
      return { ...state, list: action.payload }
    case Types.CC_UPDATE_CONTATO:
      return { ...state, form: action.payload }
    case Types.CC_UPDATE_CONTATO_FIELD:
      return { ...state, form: { ...state.form, ...action.payload } }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  onLoading: payload => (dispatch) => {
    dispatch({ type: Types.CC_LOADING, payload })
  },
  onUpdateFormField: payload => (dispatch) => {
    dispatch({ type: Types.CC_UPDATE_CONTATO_FIELD, payload })
  },
  onResetCrmContatos: () => (dispatch) => {
    dispatch({ type: Types.CC_UPDATE_CONTATO, payload: initialState.form })
  },
  onFilterCrmContatos: (query = {}) => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    dispatch(Actions.onLoading({ list: true }))
    setTimeout(() => {
      filterCrmContatosApi({ token, query }).then((payload) => {
        dispatch(Actions.onLoading({ list: false }))
        if (payload && _.isArray(payload)) {
          dispatch({ type: Types.CC_UPDATE_LIST, payload })
        } else {
          dispatch({ type: Types.CC_UPDATE_LIST, payload: [] })
        }
      })
    }, 1000)
  },
  onFindById: id => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    dispatch(Actions.onLoading({ payload: true }))
    setTimeout(() => {
      findByIdCrmContatosApi({ token, id }).then((payload) => {
        dispatch(Actions.onLoading({ payload: false }))
        if (payload && _.isObject(payload)) {
          dispatch({ type: Types.CC_UPDATE_CONTATO, payload })
        } else {
          dispatch({ type: Types.CC_UPDATE_CONTATO, payload: initialState.form })
        }
      })
    }, 200)
  },
  onSaveCrmContato: payload => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    const dto = {
      token,
      payload,
    }
    if (!validarCPF(payload?.cgc ?? '')) {
      NotificationManager.error('CPF inválido!', 'Atenção:', 8000)
      return
    }

    dispatch(Actions.onLoading({ save: true }))
    if (payload.id) {
      editCrmContatosApi({ ...dto, id: payload.id }).then((response) => {
        if (response && response.status === 200) {
          NotificationManager.success('Dados do CONTATO atualizado com sucesso!', 'Atenção:', 8000)
          dispatch(Actions.onFindById(response.id))
        }
      }).finally(() => {
        dispatch(Actions.onLoading({ save: false }))
      })
    } else {
      addCrmContatosApi(dto).then((response) => {
        if (response && response.status === 200) {
          NotificationManager.success('CONTATO adicionando com sucesso!', 'Atenção:', 8000)
          dispatch(push('/crm/contatos'))
        }
      }).finally(() => {
        dispatch(Actions.onLoading({ save: false }))
      })
    }
  },
  onDeleteCrmContato: payload => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    delCrmContatosApi({ token, id: payload.id }).then(
      (response) => {
        if (response && response.status === 204) {
          NotificationManager.success('CONTATO removido com sucesso.', 'Atenção:', 8000)
          dispatch(push('/crm/contatos'))
        } else {
          NotificationManager.warning(
            'Não foi possível remover o CONTATO.',
            'Atenção:',
            8000,
          )
        }
      },
    )
  },
}
