import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'
import { API_URL } from 'api'

const ref = 'subbairro'
const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
}


export const vincularAgenteSubbairroApi = (dto) => {
    const { token, subBairroId, agenteId } = dto
    return fetch(`${API_URL}/${ref}/${subBairroId}/vincular-agente/${agenteId}`, {
        method: 'POST',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    })
        .then(httpParseJson)
        .then(httpValidState)
        .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, page, rows, search, estado, municipio }
 */
export const filterSubBairroApi = ({ token, query }) => {
    console.log(token, query)
    const {
        page,
        rows,
        search,
        estado,
        municipio,
        bairro,
        subbairro,
        crmAgenteId
    } = query;

    let queryUrl = '?1=1';
    queryUrl += query.page ? `&page=${query.page}` : '';
    queryUrl += rows ? `&rows=${rows}` : '';
    queryUrl += search ? `&search=${search}` : '';
    queryUrl += estado ? `&estado=${estado}` : '';
    queryUrl += municipio ? `&municipio=${municipio}` : '';
    queryUrl += bairro ? `&bairro=${bairro}` : '';
    queryUrl += crmAgenteId ? `&crmAgenteId=${crmAgenteId}` : '';
    queryUrl += subbairro ? `&subbairro=${subbairro}` : '';
    console.log(`${API_URL}${ref}/filter${queryUrl}`);

    return fetch(`${API_URL}/${ref}/filter${queryUrl}`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    })
        .then(httpParseJson)
        .then(httpValidState)
        .catch(httpValidErros);
};


export const addSubBairroAPI = ({ token, bairroId, subBairroNome }) => {
    const requestBody = {
        bairroId,
        subBairroNome,
    };

    return fetch(`${API_URL}/${ref}`, {
        method: 'POST',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    })
        .then(httpParseJson)
        .then(httpValidState)
        .catch(httpValidErros);
};
export const vincularAgentesSubbairroApi = (dto) => {
    const { token, subBairroId, agentesIds } = dto;
    const body = agentesIds;
    return fetch(`${API_URL}/${ref}/${subBairroId}/vincular-agente`, {
        method: 'POST',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(httpParseJson)
        .then(httpValidState)
        .catch(httpValidErros);
};
/**
 * @param {String} token - JWT token
 * @param {String} bairroId - ID do bairro
 */
export const listSubBairrosByBairroId = (token, bairroId) => {
    return fetch(`${API_URL}/${ref}/by-bairro/${bairroId}`, {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        }
    })
        .then(httpParseJson)
        .then(httpValidState)
        .catch(httpValidErros)
}
