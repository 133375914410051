import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Icon,
} from '@material-ui/core'
import PropTypes from 'prop-types'

// Utils
import { SToC } from 'utils'
// Components
import TrackingSSW from '../Tracking/SSW'

import useStyles from './styles'

function PedidoDetalhesFiscalComp(props) {
  const {
    notas = [],
    params = {},
    actions = {},
  } = props
  const classes = useStyles()
  const { kTrackingDanfe = 'NAO' } = params
  const { onDownloadDanfe } = actions
  /*
  const handleTrackinDanfe = (pChaveNfe) => {
    this.handleTrackinDanfeOpen(true)
    this.handleTrackinDanfeLoading(true)
    this.setState(s => ({
      ...s,
      dialogTranking: { ...s.dialogTranking, chave: pChaveNfe },
    }))
    getTrackingdanfe(pChaveNfe)
      .then((resp) => {
        this.handleTrackinDanfeLoading(false)
        if (resp && resp.documento) {
          this.setState(s => ({
            ...s,
            dialogTranking: { ...s.dialogTranking, documento: resp.documento },
          }))
        }
      })
      .catch(() => this.handleTrackinDanfeLoading(false))
  }
  */
  return (
    <Paper className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Fiscal
        </Typography>
      </div>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            {/* <TableCell>TIPO</TableCell> */}
            <TableCell>EMISSÃO</TableCell>
            <TableCell>NOTA</TableCell>
            <TableCell>CHAVE</TableCell>
            {kTrackingDanfe === 'SIM' && <TableCell align="center">RASTREIO</TableCell>}
            <TableCell align="center">DOWNLOAD</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notas.map(n => (
            <TableRow>
              {/* <TableCell>{n?.descricao ?? ''}</TableCell> */}
              <TableCell>{SToC(n?.emissao ?? '')}</TableCell>
              <TableCell>{`${n?.documento ?? ''}/${n?.serie ?? ''}`}</TableCell>
              <TableCell>{n?.chave ?? ''}</TableCell>
              {kTrackingDanfe === 'SIM' && (
                <TableCell align="center">
                  <TrackingSSW chaveNfe={n?.chave} />
                </TableCell>
              )}
              <TableCell align="center">
                <IconButton onClick={() => onDownloadDanfe({ doc: n.documento, serie: n.serie }, 'danfe')}>
                  <Icon>picture_as_pdf</Icon>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper >
  )
}

PedidoDetalhesFiscalComp.propTypes = {
  notas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  params: PropTypes.shape(),
  actions: PropTypes.shape(),
}

PedidoDetalhesFiscalComp.defaultProps = {
  params: { kTrackingDanfe: 'NAO' },
  actions: {},
}

export default PedidoDetalhesFiscalComp
