import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
  Avatar,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Numeral from 'numeral'
import _ from 'lodash'

import { getStatusById } from '../../utils/enums/StatusPedido'
import { DateIsoToBr } from 'utils'

import useStyles from './styles'

function PedidoDetalhesItensComp(props) {
  const { itens = [], params = {} } = props
  const { kDataEmbarque = 'NAO' } = params
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Itens
        </Typography>
      </div>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>STATUS</TableCell>
            <TableCell>PRODUTO</TableCell>
            {kDataEmbarque === 'SIM' && (
              <TableCell align="center">DT. EMBARQUE</TableCell>
            )}
            <TableCell align="center">PESO UNT.</TableCell>
            <TableCell align="center">QUANT.</TableCell>
            <TableCell>VLR. UNT.</TableCell>
            <TableCell>VLR. TOTAL</TableCell>
            <TableCell>FORMA/COND.</TableCell>
            {/* <TableCell>PROCESSAMENTO</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.orderBy(itens, ['id'], ['asc']).map(i => (
            <TableRow key={i.id} hover>
              <TableCell>
                <Chip
                  avatar={<Avatar>{getStatusById(i?.statusId ?? 0).icon}</Avatar>}
                  label={i?.statusDesc ?? 'SEM STATUS'}
                />
              </TableCell>
              <TableCell>
                <strong>{i?.produtoSku ?? ''}</strong>
                {` - ${i?.produtoDesc ?? ''}`}
              </TableCell>
              {kDataEmbarque === 'SIM' && (
                <TableCell align="center">
                  {i.dataEmbarque ? DateIsoToBr(i.dataEmbarque) : ''}
                </TableCell>
              )}
              <TableCell align="center">
                {`${Numeral((i?.produtoPesoUnit ?? 0) * (i?.produtoQuant ?? 0)).format(
                  '0,0.00',
                )}kg`}
              </TableCell>
              <TableCell align="center">{i?.produtoQuant ?? 0}</TableCell>
              <TableCell>
                {`R$ ${Numeral(i?.produtoValorUnit ?? 0).format('0,0.00')}`}
              </TableCell>
              <TableCell>
                {`R$ ${Numeral(i?.produtoValorTotal ?? 0).format('0,0.00')}`}
              </TableCell>
              <TableCell align="center">
                {`${i?.pagamentoFormaDescricao ?? ''}${i.pagamentoFormaSku !== 'ANT'
                  ? `/${i?.pagamentoCondicaoDescricao ?? ''}`
                  : ''}`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

PedidoDetalhesItensComp.propTypes = {
  itens: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  params: PropTypes.shape(),
}

PedidoDetalhesItensComp.defaultProps = {
  params: { kDataEmbarque: 'NAO' },
}

export default PedidoDetalhesItensComp
