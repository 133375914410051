import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from '..'

const ref = '/motivo_cancel'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - { token: String }
 */
export const findAllMotivosApi = ({token}) => {
  
  return fetch(`${API_URL}${ref}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}




