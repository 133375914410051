import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Numeral from 'numeral'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Badge,
  Card,
  CardHeader,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
} from '@material-ui/core'
import {
  ChevronRight,
  SaveAlt,
  FormatLineSpacing,
  Print,
} from '@material-ui/icons'

// Api's
import { getBoletos } from '../../../api/financeiro/boletos'
import { printPedidoApi } from '../../../api/pedido'

// Components
import Header from '../../../components/Header'
import ButtonLoadingComp from '../../../components/Buttons/ButtonLoading'
import Visibility from '../../../components/Visibility'
import PedidoDetalhesItensComp from '../../../components/Pedido/PedidoDetalhesItens'
import PedidoDetalhesAtualizacoesComp from '../../../components/Pedido/PedidoDetalhesAtualizacoes'
import PedidoDetalhesLinhaStatusComp from '../../../components/Pedido/PedidoDetalhesLinhaStatus'

// Utils
import { getStatusById } from '../../../utils/enums/StatusPedido'

// Actions
import { Actions as ConfigActions } from '../../../store/ducks/config'
import { Actions as PedidosActions } from '../../../store/ducks/pedidos'
import { Actions as FinanceiroActions } from '../../../store/ducks/financeiro'

import styles from './styles'

class PedidoDetalhePage extends Component {
  static propTypes = {
    match: PropTypes.shape().isRequired,
    usuario: PropTypes.shape().isRequired,
    loading: PropTypes.shape().isRequired,
    gerando: PropTypes.shape().isRequired,
    pedido: PropTypes.shape().isRequired,
    classes: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
    pedStatus: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onSendPedidoPadrao: PropTypes.func.isRequired,
    onLoadPedido: PropTypes.func.isRequired,
    onCancelarPedido: PropTypes.func.isRequired,
    onDownloadBoleto: PropTypes.func.isRequired,
    onDownloadDanfeXML: PropTypes.func.isRequired,
    getParametro: PropTypes.func.isRequired,
  }

  state = {
    dialogBoletos: false,
    pedidoPadrao: {
      open: false,
      descricao: '',
    },
    loadingBoletos: false,
    documentos: {
      doc1: '',
      serie1: '',
      doc2: '',
      serie2: '',
    },
    listBoletos: [],
    printing: false,
  }

  componentDidMount() {
    const { onLoadPedido, match } = this.props
    const pedidoId = match.params.id
    document.title = 'Online - Detalhe Pedido'
    onLoadPedido({ pedidoId })
  }

  handleDownloadTituloVencido = (row) => {
    NotificationManager.warning(
      `O Titulo ${row.titulo}${row.parcela ? `/${row.parcela}` : ''} no valor de R$ ${Numeral(
        row.valor_ori,
      ).format('0,0.00')} está vencido, você deve entrar em contato com o nosso financeiro.`,
      'Atenção:',
      10000,
    )
  }

  handleDownloadTituloQuitado = (row) => {
    NotificationManager.success(
      `O Titulo ${row.titulo}${row.parcela ? `/${row.parcela}` : ''
      } consta como PAGO e foi dado baixa em nosso sistema dia ${moment(
        row.baixa,
        'YYYYMMDD',
      ).format('DD/MM/YYYY')}.`,
      'Atenção:',
      10000,
    )
  }

  handleShowBoletos = (doc1, serie1, doc2, serie2) => {
    const {
      usuario: {
        auth: { token },
        unidade: { empresaCod, filialCod },
        cliente: { cgc },
      },
    } = this.props

    this.setState(s => ({ ...s, dialogBoletos: true, loadingBoletos: true }))

    if (doc1) {
      getBoletos({
        token,
        empresa: empresaCod,
        filial: filialCod,
        cgc,
        titulo: doc1,
        prefixo: serie1,
        quitado: 'SIM',
      }).then((res) => {
        if (res) {
          this.setState(s => ({
            ...s,
            loadingBoletos: false,
            listBoletos: res,
          }))
        }
      })
    }

    if (doc2) {
      getBoletos({
        token,
        empresa: empresaCod,
        filial: filialCod,
        cgc,
        titulo: doc2,
        prefixo: serie2,
        quitado: 'SIM',
      }).then((res) => {
        if (res && _.isArray(res)) {
          this.setState(s => ({
            ...s,
            loadingBoletos: false,
            listBoletos: _.merge(s.listBoletos, res),
          }))
        }
      })
    }
  }

  handleHideBoletos = () => this.setState({ dialogBoletos: false })

  handleChangePedidoPadrao = payload => this.setState(s => ({
    ...s,
    pedidoPadrao: {
      ...s.pedidoPadrao,
      ...payload,
    },
  }))

  handlePrint = () => {
    const {
      pedido,
      usuario: {
        auth: { token },
      },
    } = this.props
    this.setState(s => ({ ...s, printing: true }))
    printPedidoApi({ token, pedidoId: pedido.id }).then(async (res) => {
      this.setState(s => ({ ...s, printing: false }))
      if (res && res.status === 200) {
        const pdf = await res.blob()
        window.open(URL.createObjectURL(pdf), 'Pedido')
        URL.revokeObjectURL(pdf)
      } else {
        const json = await res.json()
        NotificationManager.warning(json.message, 'Atenção:', 8000)
      }
    })
  }

  render() {
    const {
      loadingBoletos,
      dialogBoletos,
      listBoletos,
      pedidoPadrao,
      printing,
    } = this.state
    const {
      loading,
      pedido,
      classes,
      history,
      gerando,
      onSendPedidoPadrao,
      onDownloadBoleto,
      onDownloadDanfeXML,
      onCancelarPedido,
      pedStatus,
      getParametro,
    } = this.props
    const totalUltimosPedidos = pedStatus.find(ps => ps.pedidoStatusId === 999)
    // const isCancelaPedido = getParametro('IS_CANCELA_PEDIDO', 'NAO') === 'SIM'
    const kUsaPedidoPadrao = getParametro('USA_PEDIDO_PADRAO', 'NAO') === 'SIM'
    const pClientesEntregas = getParametro('CLIENTES_ENTREGAS', 'NAO')
    const pDataEmbarque = getParametro('DATA_EMBARQUE', 'NAO')
    const pTrackingDanfe = getParametro('TRACKING_DANFE', 'NAO')
    const pPedDetEtapa2 = getParametro('PED_DET_ETAPA_2', 'EM PROCESSAMENTO')
    return (
      <Fragment>
        <Header />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.root}
        >
          {/* MENU FILTROS */}
          <Grid item xs={2}>
            <List component="nav">
              <ListItem selected>
                <FormatLineSpacing />
                <ListItemText primary="Pedidos" />
              </ListItem>
              <ListItem button onClick={() => history.push('/pedidos')}>
                <ChevronRight />
                <ListItemText primary="ÚLTIMOS PEDIDOS" />
                <Badge
                  badgeContent={totalUltimosPedidos ? totalUltimosPedidos.total : 0}
                  color="secondary"
                />
              </ListItem>
              {pedStatus
                .filter(ps => ps.pedidoStatusId !== 999)
                .map(ps => (
                  <ListItem
                    key={ps.pedidoStatusId}
                    button
                    onClick={() => history.push(`/pedidos/status/${ps.pedidoStatusId}`)}
                  >
                    <ChevronRight />
                    <ListItemText primary={getStatusById(ps.pedidoStatusId).descricao} />
                    <Badge badgeContent={ps.total} color="secondary" />
                  </ListItem>
                ))}
            </List>
          </Grid>
          {/* GRID */}
          <Grid item xs={10}>
            <Card className={[classes.card, classes.grid].join(' ')}>
              {loading.loadPedidoById && <CircularProgress />}
              {!loading.loadPedidoById && pedido.id && (
                <Fragment>
                  {/* Header Title */}
                  <CardHeader
                    title={`Detalhe do pedido${pedido.ref ? `: ${pedido.ref}` : ''}`}
                    action={(
                      <Grid container spacing={2}>
                        <div className={classes.wrapper}>
                          <ButtonLoadingComp
                            loading={printing}
                            variant="outlined"
                            color="primary"
                            onClick={() => this.handlePrint()}
                            startIcon={<Print />}
                          >
                            Imprimir
                          </ButtonLoadingComp>
                        </div>
                        <Visibility show={kUsaPedidoPadrao}>
                          <div className={classes.wrapper}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => this.handleChangePedidoPadrao({ open: true, descricao: '' })
                              }
                            >
                              Criar pedido padrão
                            </Button>
                          </div>
                        </Visibility>
                        <div className={classes.wrapper}>
                          <Button
                            disabled={pedido.statusId > 2 || loading.cancelandoPedido}
                            variant="contained"
                            color="secondary"
                            onClick={() => onCancelarPedido(pedido.id)}
                          >
                            Cancelar pedido
                          </Button>
                          {loading.cancelandoPedido && (
                            <CircularProgress size={24} className={classes.buttonProgress} />
                          )}
                        </div>
                      </Grid>
                    )}
                  />
                  <CardContent>
                    {/* Header do pedido */}
                    <PedidoDetalhesLinhaStatusComp
                      pedido={pedido}
                      params={{
                        kClientesEntregas: pClientesEntregas,
                        kPedDetEtapa2: pPedDetEtapa2,
                      }}
                    />
                    {/* - Itens do pedido */}
                    <PedidoDetalhesItensComp
                      itens={pedido.itens}
                      params={{ kDataEmbarque: pDataEmbarque }}
                    />
                    {/* - Detalhes do pedido (Notas, Boletos, Orden de carregamento...) */}
                    <PedidoDetalhesAtualizacoesComp
                      itens={pedido.itens}
                      gerando={gerando}
                      params={{ kTrackingDanfe: pTrackingDanfe }}
                      actions={{
                        handleDownloadDanfeXML: onDownloadDanfeXML,
                        handleShowBoletos: this.handleShowBoletos,
                      }}
                    />
                  </CardContent>
                </Fragment>
              )}
            </Card>
          </Grid>
        </Grid>
        {/* Dialog: Pedido Padrão */}
        <Dialog
          maxWidth="sm"
          fullWidth
          open={pedidoPadrao.open}
          onClose={() => this.handleChangePedidoPadrao({ open: false, descricao: '' })}
          aria-labelledby="pedido-padrao"
        >
          <DialogTitle id="pedido-padrao">Pedido padrão</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <form noValidate autoComplete="off">
                  <TextField
                    fullWidth
                    id="pedidoPadraoDescricao"
                    name="pedidoPadraoDescricao"
                    label="Descrição"
                    value={pedidoPadrao.descricao}
                    onChange={({ target: { value = '' } = {} }) => this.handleChangePedidoPadrao({ descricao: value.toUpperCase() })
                    }
                  />
                </form>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => this.handleChangePedidoPadrao({ open: false, descricao: '' })}
            >
              CANCELAR
            </Button>
            <ButtonLoadingComp
              loading={loading.pedidoPadrao}
              disabled={!pedidoPadrao.descricao.trim()}
              color="primary"
              variant="contained"
              onClick={() => onSendPedidoPadrao(
                {
                  pedidoId: pedido.id,
                  descricao: pedidoPadrao.descricao,
                },
                () => this.handleChangePedidoPadrao({ open: false, descricao: '' }),
              )
              }
            >
              SALVAR
            </ButtonLoadingComp>
          </DialogActions>
        </Dialog>
        {/* Dialog: Listagem de boletos do PEDIDO */}
        <Dialog
          maxWidth="md"
          fullWidth
          open={dialogBoletos}
          onClose={this.handleHideBoletos}
          aria-labelledby="boleto-list"
        >
          <DialogTitle id="boleto-list">{`Boletos (${listBoletos.length})`}</DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Titulo</TableCell>
                  <TableCell align="right">Parcela</TableCell>
                  <TableCell align="right">Principal</TableCell>
                  <TableCell align="right">Emissão</TableCell>
                  <TableCell align="right">Vencimento</TableCell>
                  <TableCell align="right">Baixa</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingBoletos ? (
                  <TableRow>
                    <TableCell>
                      <CircularProgress size={16} className={classes.loadingPadding} />
                      Filtrando...
                    </TableCell>
                  </TableRow>
                ) : (
                  listBoletos.map(row => (
                    <TableRow key={row.titulo}>
                      <TableCell component="th" scope="row">
                        {row.titulo}
                      </TableCell>
                      <TableCell align="right">{row.parcela}</TableCell>
                      <TableCell align="right">
                        {`R$ ${Numeral(row.valor).format('0,0.00')}`}
                      </TableCell>
                      <TableCell align="right">
                        {moment(row.emissao, 'YYYYMMDD').format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell align="right">
                        {moment(row.vencrea, 'YYYYMMDD').format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell align="right">
                        {row.baixa && moment(row.baixa, 'YYYYMMDD').format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {row.saldo > 0 && row.situacao.toUpperCase() === 'EMDIA' && (
                          <Button variant="outlined" onClick={() => onDownloadBoleto(row)}>
                            {(!_.isEmpty(gerando)
                              && gerando.titulo === row.titulo
                              && gerando.parcela === row.parcela)
                              ? (<CircularProgress size={16} />) : (<SaveAlt />)}
                          </Button>
                        )}
                        {row.saldo > 0 && row.situacao.toUpperCase() === 'VENCIDO' && (
                          <Button
                            variant="outlined"
                            onClick={() => this.handleDownloadTituloVencido(row)}
                            color="secondary"
                          >
                            {(!_.isEmpty(gerando)
                              && gerando.titulo === row.titulo
                              && gerando.parcela === row.parcela) ? (<CircularProgress size={16} />) : ('VENCIDO')}
                          </Button>
                        )}
                        {row.saldo === 0 && row.situacao.toUpperCase() === 'QUITADO' && (
                          <Button
                            variant="outlined"
                            onClick={() => this.handleDownloadTituloQuitado(row)}
                            className={classes.pago}
                          >
                            {(!_.isEmpty(gerando)
                              && gerando.titulo === row.titulo
                              && gerando.parcela === row.parcela) ? (<CircularProgress size={16} />) : ('PAGO')}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
                {listBoletos.length === 0 && !loadingBoletos && (
                  <TableRow>
                    <TableCell colSpan={8}>Nenhum boleto.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </DialogContent>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  loading: state.pedidos.loading,
  pedido: state.pedidos.pedido,
  pedidos: state.pedidos.pedidos,
  pedStatus: state.pedidos.pedStatus,
  filtrando: state.pedidos.filtrando,
  gerando: state.financeiro.gerando,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...ConfigActions,
    ...PedidosActions,
    ...FinanceiroActions,
  },
  dispatch,
)

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PedidoDetalhePage))
