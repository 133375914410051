import { NotificationManager } from 'react-notifications'
import { getCgcApi, addNovoClienteApi } from '../../api/cliente'

// State Cliente
const initialState = {
  loading: false,
  buscando: false,
  formCadastro: {
    disabled: true,
    empresaCnpj: '',
    empresaCodigo: '',
    empresaLoja: '',
    empresaRamo: 1,
    empresaRazao: '',
    empresaEndLogradouro: '',
    empresaEndNumero: '',
    empresaEndComplemento: '',
    empresaEndBairro: '',
    empresaEndCidade: '',
    empresaEndCep: '',
    empresaEndUf: '',
    usuarioNome: '',
    usuarioEmail: '',
    usuarioTelefone: '',
    usuarioSenha: '',
    usuarioReSenha: '',
    usuarioCgc: '',
  },
  dialogCliente: {
    open: false,
    title: '',
    msg: '',
  },
  dialogValidacao: {
    open: false,
    title: '',
    msg: [],
  },
}

// Actions Types
export const Types = {
  RESET: 'cliente/RESET',
  LOADING: 'cliente/LOADING',
  BUSCANDO: 'cliente/BUSCANDO',
  FIELD_UPDATE: 'cliente/FIELD_UPDATE',
  CLIENTE_DIALOG: 'cliente/DIALOG',
  CLIENTE_DIALOG_VALIDACAO: 'cliente/CLIENTE_DIALOG_VALIDACAO',
}

// Reducers
export default function clienteReducers(state = initialState, action) {
  switch (action.type) {
    case Types.RESET:
      return { ...state, ...initialState }
    case Types.LOADING:
      return { ...state, loading: action.payload }
    case Types.BUSCANDO:
      return { ...state, buscando: action.payload }
    case Types.FIELD_UPDATE:
      return { ...state, formCadastro: { ...state.formCadastro, ...action.payload } }
    case Types.CLIENTE_DIALOG:
      return { ...state, dialogCliente: { ...state.dialogCliente, ...action.payload } }
    case Types.CLIENTE_DIALOG_VALIDACAO:
      return { ...state, dialogValidacao: { ...state.dialogValidacao, ...action.payload } }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  onReset: () => ({ type: Types.RESET }),
  onResetForm: () => dispatch => dispatch(Actions.onFieldUpdate(initialState.formCadastro)),
  onLoading: status => (dispatch) => {
    dispatch({ type: Types.LOADING, payload: status })
  },
  onBuscando: status => (dispatch) => {
    dispatch({ type: Types.BUSCANDO, payload: status })
  },
  onFieldUpdate: field => (dispatch) => {
    dispatch({ type: Types.FIELD_UPDATE, payload: field })
  },
  onConsultaCgc: () => (dispatch, getState) => {
    const { formCadastro } = getState().cliente
    dispatch(Actions.onBuscando(true))
    if (!formCadastro.empresaCnpj) {
      NotificationManager.warning(
        'Você deve informar um CGC para realizar a consulta',
        'Atenção!',
        8000,
      )
      dispatch(Actions.onBuscando(false))
    } else {
      getCgcApi({ cgc: formCadastro.empresaCnpj }).then((payload) => {
        dispatch(Actions.onBuscando(false))
        if (payload) {
          const {
            codigo, loja, cgc, nome, endereco, bairro, uf, cep,
          } = payload
          dispatch(
            Actions.onFieldUpdate({
              disabled: false,
              empresaCnpj: cgc,
              empresaCodigo: codigo,
              empresaLoja: loja,
              empresaRazao: nome,
              empresaEndLogradouro: endereco,
              empresaEndBairro: bairro,
              empresaEndUf: uf,
              empresaEndCep: cep,
            }),
          )
        }
      })
    }
  },
  onSaveCliente: () => (dispatch, getState) => {
    const { formCadastro } = getState().cliente
    // Enviar
    const cliente = {
      codigo: formCadastro.empresaCodigo,
      loja: formCadastro.empresaLoja,
      razao: formCadastro.empresaRazao,
      cgc: formCadastro.empresaCnpj,
      ramoAtuacao: formCadastro.empresaRamo,
      tipoPessoa: formCadastro.empresaCnpj.length === 14 ? 'J' : 'F',
      logradouro: formCadastro.empresaEndLogradouro,
      numero: formCadastro.empresaEndNumero,
      complemento: formCadastro.empresaEndComplemento,
      bairro: formCadastro.empresaEndBairro,
      cidade: formCadastro.empresaEndCidade,
      uf: formCadastro.empresaEndUf,
      cep: formCadastro.empresaEndCep,
      nome: formCadastro.usuarioNome,
      email: formCadastro.usuarioEmail,
      cpf: formCadastro.usuarioCgc,
      telefone: formCadastro.usuarioTelefone.replace(/\D/g, ''),
      senha: formCadastro.usuarioSenha,
      resenha: formCadastro.usuarioReSenha,
    }
    dispatch(Actions.onLoading(true))
    addNovoClienteApi(cliente).then((resp) => {
      dispatch(Actions.onLoading(false))
      if (resp && resp.status === 201) {
        dispatch(Actions.onFieldUpdate(initialState.formCadastro))
        dispatch(
          Actions.onOpenDialog({
            open: true,
            title: 'Sucesso',
            msg: resp.message,
          }),
        )
      }
    })
  },
  onOpenDialog: payload => ({
    type: Types.CLIENTE_DIALOG,
    payload,
  }),
  onCloseDialog: () => ({
    type: Types.CLIENTE_DIALOG,
    payload: { open: false },
  }),
  onOpenDialogValidacao: payload => ({
    type: Types.CLIENTE_DIALOG_VALIDACAO,
    payload,
  }),
  onCloseDialogValidacao: () => ({
    type: Types.CLIENTE_DIALOG_VALIDACAO,
    payload: { open: false },
  }),
}
