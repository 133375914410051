import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { NotificationContainer } from 'react-notifications'
import { MuiThemeProvider } from '@material-ui/core/styles'

// Componentes
import DialogComp from '../components/Dialogs'

import PrivateRoute from '../components/PrivateRoute'
import LoginPage from '../pages/auth/login'
import SelecionarClientePage from '../pages/auth/SelecionarCliente'
import TokenExpiradoPage from '../pages/auth/tokenExpirado'
import RecoveryPage from '../pages/recovery'
import ForgotPage from '../pages/forgot'
import NovoClientePage from '../pages/cliente/novo'
import HomePage from '../pages/home'
import PedidoPage from '../pages/pedidos'
import PedidoDetalhePage from '../pages/pedidos/detalhe'
import PedidoPadraoPage from '../pages/pedidos/padrao'
import CarrinhoPage from '../pages/carrinho'
import FinanceiroPage from '../pages/financeiro'
// * Crm Contatos
import CrmContatosListagemPage from '../pages/crm/contato/pages/listagem'
import CrmContatosDetalhePage from '../pages/crm/contato/pages/detalhe'

// ! Gestor
// * Admin/Gestor
import GestorDashboardPage from '../pages/gestor/dashboard'
import GestorClientesListagemPage from '../pages/gestor/clientes/listagem'
import GestorClientesDetalhePage from '../pages/gestor/clientes/detalhe'

// * Alcadas ( Apenas de novos cadastros )
import GestorClientesAprovacaoFormPage from '../pages/gestor/alcadas/form'
import GestorAlcadasListagemPage from '../pages/gestor/alcadas/listagem'
import GestorAlcadasAprovadoresListagemPage from '../pages/gestor/alcadas/aprovadores/listagem'

// * Usuários
import GestorUsuariosMeusDadosPage from '../pages/gestor/usuarios/meusdados'
import GestorUsuariosDetalhePage from '../pages/gestor/usuarios/detalhe'
import GestorUsuariosListagemPage from '../pages/gestor/usuarios/listagem'

// * Produtos
import GestorProdutosListagemPage from '../pages/gestor/produtos/listagem'
import GestorProdutosDetalhePage from '../pages/gestor/produtos/detalhe'

// * Lgpd
import TermosLgpdPage from '../pages/lgpd/termos'
import PoliticaLgpdPage from '../pages/lgpd/politica'

// * Relatórios
import RelatoriosPage from '../pages/gestor/relatorios'

// * CRM
import GestorCrmDashboardPage from '../pages/gestor/crm/dashboard'
// * CRM/Prospects
import GestorCrmProspectListPage from '../pages/gestor/crm/prospects/pages/list'
import GestorCrmProspectDetailPage from '../pages/gestor/crm/prospects/pages/detail'
// * CRM/Agentes
import GestorCrmAgenteListPage from '../pages/gestor/crm/agentes/pages/list'
import GestorCrmAgenteDetailPage from '../pages/gestor/crm/agentes/pages/detail'
// * CRM/Oportunidades
import GestorCrmOportunidadesListPage from '../pages/gestor/crm/oportunidades/pages/list'
import GestorCrmOportunidadeDetailPage from '../pages/gestor/crm/oportunidades/pages/detail'
// * CRM/Pedidos
import GestorCrmPedidosListPage from '../pages/gestor/crm/pedidos/pages/list'
// import GestorCrmListagemPage from '../pages/gestor/crm/page/listagem'

// * Checklist
import GestorChecklistListPage from '../pages/gestor/checklist/pages/list'
import GestorChecklistFormPage from '../pages/gestor/checklist/pages/form'

// ! Vendas
// * Vendedor
import VendasConsultasPage from '../pages/vendas/consultas'

// Leads
import GestorLeadListPage from '../pages/gestor/leads/list'
import GestorVisitasListPage from '../pages/gestor/visitas/list'
import GestorImportacaoListPage from '../pages/gestor/leads/importacao/list'
import SubBairrosListContent from '../pages/gestor/leads/subbairros'
import TrackingMapsPage from '../pages/gestor/tracking/list'

// Vistoria
import VistoriaListPage from '../pages/gestor/vistorias/list'
import VistoriaDetailContent from '../pages/gestor/vistorias/detail'

// Entrega
import EntregaListPage from '../pages/gestor/entrega/list'
import EntregaDetailContent from '../pages/gestor/entrega/detail/abas'

import { theme } from '../styles'
import GestorVisitaUcPage from "../pages/gestor/visitas/visita_uc_cliente";
import VisitaDetailContent from 'pages/gestor/visitas/visita_uc_cliente/detail/VisitaDetailContent'

const Router = ({ history }) => (
  <ConnectedRouter history={history}>
    <MuiThemeProvider theme={theme}>
      <Fragment>
        <Switch>
          <PrivateRoute exact path="/" />
          <Route path="/login" component={LoginPage} />
          <Route path="/token-expirado" component={TokenExpiradoPage} />
          <Route path="/recovery" component={RecoveryPage} />
          <Route path="/forgot" component={ForgotPage} />
          <Route path="/selecionar-cliente" component={SelecionarClientePage} />
          <Route path="/novo-cliente" component={NovoClientePage} />
          <Route path="/carrinho" component={CarrinhoPage} />
          <Route path="/pedidos/status/:status" component={PedidoPage} />
          <Route path="/pedidos/detalhe/:id" component={PedidoDetalhePage} />
          <Route path="/pedidos" component={PedidoPage} />
          <Route path="/financeiro" component={FinanceiroPage} />
          <Route path="/pedido-padrao" component={PedidoPadraoPage} />
          <Route path="/home" component={HomePage} />
          {/* CRM / Contatos */}
          <Route path="/crm/contatos/detail/:id" component={CrmContatosDetalhePage} />
          <Route path="/crm/contatos/add" component={CrmContatosDetalhePage} />
          <Route path="/crm/contatos" component={CrmContatosListagemPage} />
          {/* <Route path="/crm/contatos/edit" component={CrmContatosListagemPage} /> */}
          {/* Admin/Gestão */}
          <Route path="/gestor/dashboard" component={GestorDashboardPage} />
          {/* <Route path="/gestor/dashboard" component={GestorAlcadasListagemPage} /> */}
          {/* Admin/Gestão - Produtos */}
          <Route path="/gestor/produtos/add" component={GestorProdutosDetalhePage} />
          <Route path="/gestor/produtos/:id" component={GestorProdutosDetalhePage} />
          <Route path="/gestor/produtos" component={GestorProdutosListagemPage} />
          {/* Admin/Gestão - Clientes */}

          {/* Remover essa rota! */}
          <Route
            path="/gestor/clientes/aprovacoes/form"
            component={GestorClientesAprovacaoFormPage}
          />
          <Route
            path="/gestor/alcadas/detalhe/aprovacao"
            component={GestorClientesAprovacaoFormPage}
          />
          <Route
            path="/gestor/alcadas/aprovadores"
            component={GestorAlcadasAprovadoresListagemPage}
          />
          <Route path="/gestor/alcadas" component={GestorAlcadasListagemPage} />
          <Route path="/gestor/clientes/add" component={GestorClientesDetalhePage} />
          <Route path="/gestor/clientes/:id" component={GestorClientesDetalhePage} />
          <Route path="/gestor/clientes" component={GestorClientesListagemPage} />
          {/* Admin/Gestão - Usuários */}
          <Route path="/gestor/usuarios/meus-dados" component={GestorUsuariosMeusDadosPage} />
          <Route path="/gestor/usuarios/add" component={GestorUsuariosDetalhePage} />
          <Route path="/gestor/usuarios/:id" component={GestorUsuariosDetalhePage} />
          <Route path="/gestor/usuarios" component={GestorUsuariosListagemPage} />
          {/*  */}
          <Route path="/gestor/prospects" exact component={GestorCrmProspectListPage} />
          <Route path="/gestor/oportunidades" component={GestorCrmOportunidadesListPage} />
          <Route path="/gestor/oportunidades" component={GestorCrmOportunidadesListPage} />
          <Route path="/gestor/pedidos" component={GestorCrmPedidosListPage} />
          <Route path="/gestor/dashboard_crm" component={GestorCrmDashboardPage} />
          {/* RELATÓRIOS */}
          <Route path="/gestor/relatorios" component={RelatoriosPage} />
          {/* LGPD */}
          <Route path="/lgpd/termos" component={TermosLgpdPage} />
          <Route path="/lgpd/politica" component={PoliticaLgpdPage} />
          {/* CRM */}
          <Route path="/gestor/crm/agentes/detail/:id" component={GestorCrmAgenteDetailPage} />
          <Route path="/gestor/crm/agentes/add" component={GestorCrmAgenteDetailPage} />
          <Route path="/gestor/crm/agentes" exact component={GestorCrmAgenteListPage} />
          <Route path="/gestor/crm/prospects/detail/:id/:oportunidadeId" component={GestorCrmProspectDetailPage} />
          <Route path="/gestor/crm/prospects/detail/:id" component={GestorCrmProspectDetailPage} />
          <Route path="/gestor/crm/prospects" exact component={GestorCrmProspectListPage} />
          <Route path="/gestor/crm/oportunidades/detail/:id" component={GestorCrmOportunidadeDetailPage} />
          <Route path="/gestor/crm/oportunidades" component={GestorCrmOportunidadesListPage} />
          <Route path="/gestor/crm/pedidos" component={GestorCrmPedidosListPage} />
          <Route path="/gestor/crm/dashboard" component={GestorCrmDashboardPage} />
          <Route path="/gestor/crm" component={GestorCrmDashboardPage} />
          {/* CHECKLIST */}
          <Route path="/gestor/checklist/edit/:id" component={GestorChecklistFormPage} />
          <Route path="/gestor/checklist/add" component={GestorChecklistFormPage} />
          <Route path="/gestor/checklist" component={GestorChecklistListPage} />
          {/* VENDAS CONSULTAS */}
          <Route path="/vendedor/vendas/consultas" component={VendasConsultasPage} />
          {/* LEADS */}
          <Route path="/gestor/leads/importacao" component={GestorImportacaoListPage} />
          <Route path="/gestor/leads" component={GestorLeadListPage} />
          {/* VISITAS */}
          <Route path="/gestor/visitas" component={GestorVisitaUcPage} />
          <Route path="/gestor/visita/detail/:id" component={VisitaDetailContent} />
          {/* SUBBAIRRO */}
          <Route path="/gestor/subbairros" component={SubBairrosListContent} />
          {/* TRACKING */}
          <Route path="/gestor/tracking" component={TrackingMapsPage} />
          {/* VISTORIAS */}
          <Route path="/gestor/vistorias/detail/:id" component={VistoriaDetailContent} />
          <Route path="/gestor/vistorias" component={VistoriaListPage} />

          {/* ENTREGA */}
          <Route path="/gestor/entrega/detail/:id" component={EntregaDetailContent} />
          <Route path="/gestor/entrega" component={EntregaListPage} />




        </Switch>
        <DialogComp />
        <NotificationContainer onClick={() => null} />
      </Fragment>
    </MuiThemeProvider>
  </ConnectedRouter>
)

Router.propTypes = {
  history: PropTypes.shape().isRequired,
}

export default Router
