import { API_URL } from '..'
import { httpParseJson, httpValidState, httpValidErros } from '../../utils/http'

const ls = '@smr-portal-usr'
const ref = '/login'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const authActionApi = login => fetch(`${API_URL}${ref}`, {
  method: 'POST',
  headers: {
    ...headers,
  },
  body: JSON.stringify(login),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)


export const setUserLocal = usuario => localStorage.setItem(ls, btoa(JSON.stringify(usuario)))

export const getUserLocal = () => {
  const usuario = localStorage.getItem(ls)
  return JSON.parse(usuario === null ? null : atob(usuario))
}

export const delUserLocal = () => localStorage.removeItem(ls)
