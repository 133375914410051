import moment from 'moment'
import { API_URL } from '..'
import { httpValidErros } from '../../utils/http'

const ref = '/relatorios'
const headers = {
  Accept: 'application/json',
  // 'Content-Type': 'application/json',
}

export const getExtratoFinanceiro = (dto) => {
  const {
    token, user, emp, fil, dataInicial, dataFinal, cnpj,
  } = dto
  const dtIni = moment(dataInicial, 'YYYY-MM-DD').format('DD/MM/YYYY')
  const dtFin = moment(dataFinal, 'YYYY-MM-DD').format('DD/MM/YYYY')
  // const query = `?user=${user}&emp=02&fil=10&dtIni=${dtIni}&dtFin=${dtFin}&cnpj=10613062000122`
  const query = `?user=${user}&emp=${emp}&fil=${fil}&dtIni=${dtIni}&dtFin=${dtFin}&cnpj=${cnpj}`
  return fetch(`${API_URL}${ref}/extratoFinanceiro${query}`, {
    method: 'GET',
    headers: {
      ...headers,
      'Content-Type': 'application/pdf',
      Authorization: `Bearer ${token}`,
    },
  }).catch(httpValidErros)
}
// (TODO: Mudar nome da funcao para visita)
export const getRelatVendedorClientesApi = (dto) => {
  const {
    token,
    filter: {
      visitaStart, visitaEnd
    },
  } = dto

  let queryParam = '?1=1'
  queryParam += visitaStart ? `&visitaStart=${visitaStart}` : ''
  queryParam += visitaEnd ? `&visitaEnd=${visitaEnd}` : ''

  return fetch(`${API_URL}${ref}/clientes/clientes-vendedor/visitas/excel${queryParam}`, {
    method: 'GET',
    headers: {
      ...headers,
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      Authorization: `Bearer ${token}`,
    },
  }).catch(httpValidErros)
}
