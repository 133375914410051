import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from '../..'

const ref = '/crm/agente_tipo'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}


/**
 *
 * @param {String} dto - { token: String }
 */
export const findAllCrmAgenteTipoApi = (dto) => {
  const { token } = dto
  return fetch(`${API_URL}${ref}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const addCrmAgentesApi = ({ token, payload }) => fetch(`${API_URL}${ref}`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const editCrmAgentesApi = ({ token, id, payload }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do produto, fk: ID da categoria }
 */
export const delCrmAgentesApi = ({ token, id }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)
