import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { Actions as DialogActionsCreators } from '../../store/ducks/dialog'
import { stylesTitle, stylesContent, stylesActions } from './styles'

const DialogTitleComp = withStyles(stylesTitle)((props) => {
  const { children, classes, onClose } = props
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
})
const DialogContentComp = withStyles(stylesContent)(DialogContent)
const DialogActionsComp = withStyles(stylesActions)(DialogActions)

const DialogComp = (props) => {
  const { dialog, onDialogClose } = props
  return (
    <Dialog
      onClose={() => onDialogClose()}
      aria-labelledby="dialog-title-comp"
      open={dialog.open}
      maxWidth={dialog.size}
      fullWidth
    >
      <DialogTitleComp
        id="dialog-title-comp"
        onClose={dialog.showCloseButton ? () => onDialogClose() : null}
      >
        {dialog.title}
      </DialogTitleComp>
      <DialogContentComp>{dialog.content}</DialogContentComp>
      {dialog.actions && (
        <DialogActionsComp>
          {dialog.actions.map(b => (
            <div
              style={{
                margin: 10,
                position: 'relative',
              }}
            >
              <Button
                key={b.key}
                disabled={dialog.actionLoading.loading}
                variant={b.variant ? b.variant : 'text'}
                color={b.color ? b.color : 'primary'}
                onClick={b.action}
              >
                {b.title}
              </Button>
              {dialog.actionLoading.loading && dialog.actionLoading.key === b.key && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </div>
          ))}
        </DialogActionsComp>
      )}
    </Dialog>
  )
}

DialogComp.propTypes = {
  dialog: PropTypes.shape().isRequired,
  onDialogClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  dialog: state.dialog,
})

const mapDispatchToProps = dispatch => bindActionCreators(DialogActionsCreators, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DialogComp)
