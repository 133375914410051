/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import {
  Typography,
  Grid,
  CssBaseline,
  Tooltip,
  Icon,
  Fab,
} from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import queryString from 'query-string'

import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import { Actions as ChecklistActions } from 'store/ducks/checklist'

import { EStatus } from 'utils/enums'
import GestorHeaderComp from 'components/Gestor/Header'
import GestorDrawerComp from 'components/Gestor/Drawer'
import MoreActionsComp from 'components/MoreActions'

import useStyles from './styles'

const GestorChecklistListPage = (props) => {
  const {
    history,
    location,
    usuario,
    unidade,
    checklist,
    onFilterChecklist,
    onDelChecklist,
    onDialogOpen,
    onDialogActionLoading,
    onDialogClose,
  } = props
  /*
  const {
    auth: { session: { tenantId = '' } = {} },
    usuario: { usuario },
    checklist: { loading, resultSet },
  } = useSelector(s => s)
  const dispatch = useDispatch()
  */

  const classes = useStyles()
  const [filter, setFilter] = useState({
    params: {},
    page: 0,
    rows: 50,
  })

  const handleFilterChecklist = () => {
    onFilterChecklist(
      { ...filter.params },
      filter.page,
      filter.rows,
    )
  }

  const columns = [
    {
      field: 'acoes',
      headerName: 'AÇÕES',
      headerClassName: 'super-app-theme--header',
      width: 150,
      renderCell: rParams => (
        <MoreActionsComp
          row={rParams.row}
          actions={[
            {
              key: 'editar',
              title: 'Editar',
              icon: 'edit',
              action: row => history.push(`checklist/edit/${row.id}`),
            },
            {
              key: 'excluir',
              title: 'Excluir',
              icon: 'delete',
              action: row => onDialogOpen({
                size: 'sm',
                title: 'Excluir?',
                content: `Você confirma a exclusão do checklist: "${row.descricao}" ?`,
                actions: [
                  {
                    key: 1,
                    title: 'Sim',
                    action: () => {
                      onDialogActionLoading({ key: 1, loading: true })
                      onDelChecklist(row.id, () => {
                        onDialogClose()
                        handleFilterChecklist()
                        onDialogActionLoading({ key: 1, loading: false })
                      }, () => {
                        onDialogActionLoading({ key: 1, loading: false })
                      })
                    },
                    color: 'secondary',
                  },
                  { key: 2, title: 'Não', action: () => onDialogClose() },
                ],
              }),
            },
          ]}
        />
      ),
    },
    {
      field: 'qtdPeguntas',
      headerName: 'Qtd. Perguntas',
      headerClassName: 'super-app-theme--header',
      width: 180,
      type: 'number',
      description: 'Quantas perguntas o checklist tem para ser respondida',
      valueGetter: rParams => `${rParams.row.itens.length}`,
    },
    { field: 'id', headerName: 'ID', headerClassName: 'super-app-theme--header' },
    {
      field: 'descricao',
      headerName: 'Descrição',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'statusDescricao',
      headerName: 'Status',
      headerClassName: 'super-app-theme--header',
      width: 130,
      valueGetter: rParams => EStatus[rParams.row.status],
    },
  ]

  useEffect(() => {
    const query = queryString.parse(location.search)
    if (location.search) {
      setFilter(s => ({ ...s, params: { ...s.params, ...query } }))
    }
    handleFilterChecklist()
  }, [location.search, unidade?.id])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={1}>
          {/* Header */}
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" component="h2">
                  Checklist
                </Typography>
              </Grid>
              {/* Menus */}
              <Grid item xs={12} md={6}>
                <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Tooltip title="Adicionar">
                      <Fab
                        color="primary"
                        aria-label="Adicionar"
                        className={classes.fab}
                        onClick={() => history.push('checklist/add')}
                      >
                        <Icon>add</Icon>
                      </Fab>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Body */}
          <Grid item xs={12} md={12}>
            <DataGrid
              densit="compact"
              autoHeight
              loading={checklist?.loading?.resultSet ?? false}
              rows={checklist?.resultSet?.data ?? []}
              columns={columns}
              pageSize={10}
            />
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

GestorChecklistListPage.propTypes = {
  auth: PropTypes.shape().isRequired,
  usuario: PropTypes.shape().isRequired,
  unidade: PropTypes.shape().isRequired,
  checklist: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
  onFilterChecklist: PropTypes.func.isRequired,
  onDelChecklist: PropTypes.func.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onDialogActionLoading: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...ChecklistActions,
    push: crrPush,
  },
  dispatch,
)

const mapStateToProps = state => ({
  auth: state.usuario.usuario.auth,
  usuario: state.usuario.usuario,
  unidade: state.usuario.usuario.unidade,
  checklist: state.checklist,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GestorChecklistListPage)
