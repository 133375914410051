const ROLES = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_GESTOR: 'ROLE_GESTOR',
  ROLE_USUARIO: 'ROLE_USUARIO',
  ROLE_AUDITOR: 'ROLE_AUDITOR',
  ROLE_CLIENTE: 'ROLE_CLIENTE',
  ROLE_VENDEDOR: 'ROLE_VENDEDOR',
}

export default ROLES
