import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from '../..'

const ref = '/crm/vistorias'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - {
 *  token: String, page, rows, search, usuarioId, dateAgendaFree, dateAgendaInterval
 * }
 */
export const filterCrmVistoriasApi = (dto) => {
  const {
    token,
    query: {
      page = 0, rows = 50, search = '', usuarioId, crmProspectId, dateAgendaFree, dateAgendaInterval, agenteId, vistoriaDate, municipio, estado, bairro, subBairro
    },
  } = dto

  let queryUrl = '?1=1'
  queryUrl += page ? `&page=${page}` : ''
  queryUrl += rows ? `&rows=${rows}` : ''
  queryUrl += search ? `&search=${search}` : ''
  queryUrl += usuarioId ? `&usuarioId=${usuarioId}` : ''
  queryUrl += crmProspectId ? `&crmProspectId=${crmProspectId}` : ''
  queryUrl += dateAgendaFree ? `&dateAgendaFree=${dateAgendaFree}` : ''
  queryUrl += dateAgendaInterval ? `&dateAgendaInterval=${dateAgendaInterval}` : ''

  queryUrl += agenteId ? `&agenteId=${agenteId}` : ''
  queryUrl += vistoriaDate ? `&vistoriaDate=${vistoriaDate}` : ''
  queryUrl += municipio ? `&municipio=${municipio}` : ''
  queryUrl += estado ? `&estado=${estado}` : ''
  queryUrl += bairro ? `&bairro=${bairro}` : ''
  queryUrl += subBairro ? `&subBairro=${subBairro}` : ''
  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, id }
 */
export const findByIdCrmVistoriasApi = (dto) => {
  const { token, id } = dto
  return fetch(`${API_URL}${ref}/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const addCrmVistoriasApi = ({ token, payload }) => fetch(`${API_URL}${ref}`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const editCrmVistoriasApi = ({ token, id, payload }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)


/**
* Deletar uma CrmVistoria pelo ID
* @param {Object} dto - { token: String, id: ID da vistoria }
*/
export const delCrmVistoriasApi = ({ token, id }) => {
  return fetch(`${API_URL}${ref}/${id}`, {
    method: 'DELETE',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}