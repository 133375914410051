import React, { useState, useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import PropTypes from 'prop-types'
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import _ from 'lodash'

import { addCrmAtendimentosApi } from 'api/crm/atendimentos'

import Visibility from 'components/Visibility'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'
// import { DtFormat } from 'utils'
import useStyles from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions as UsuarioActions } from '../../../../../../store/ducks/usuario'


const DialogAddAtendimento = (props) => {
  const {
    usuario,
    token,
    open,
    payload,
    onSave,
    onClose,
  } = props

  const [isSavingForm, setSavingForm] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [form, setForm] = useState()

  const classes = useStyles()

  const onUpdateField = (data) => {
    setForm(s => ({ ...s, ...data }))
  }
  const onCloseDialog = () => {
    if (_.isFunction(onClose)) {
      setOpen(false)
      onClose()
    }
  }
  const checkStatusPermission = () => {
    if ((form?.disabled ?? true) || usuario.role != 'ROLE_ADMIN') {
      return true;
    } else if (usuario.role == 'ROLE_ADMIN') {
      return false;
    }

  }
  const onSaveForm = () => {
    if (!form.id) {
      setSavingForm(true)
      addCrmAtendimentosApi({ token, payload: form })
        .then((resp) => {
          const { status } = resp ?? {}
          if (status === 200) {
            NotificationManager.success(
              'Atendimento criado com sucesso!',
              'Atenção',
              8000,
            )
            onCloseDialog()
            if (_.isFunction(onSave)) {
              onSave(form, () => setSavingForm(true), () => setSavingForm(false))
            }
          }
        }).finally(() => {
          setSavingForm(false)
        })
    }
  }

  useEffect(() => {
    setForm(payload)
  }, [payload])

  useEffect(() => {
    setOpen(open)
  }, [open])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      onClose={onCloseDialog}
      aria-labelledby="dialog-crm-atendimento"
    >
      <DialogTitle id="dialog-crm-atendimento-title">Atendimento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          {/* Data Atendimento */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={true}
              id="dataAtendimento"
              label="Data Atendimento"
              type="datetime-local"
              // defaultValue="2017-05-24T10:30"
              // className={classes.textField}
              value={form?.dataAtendimento ?? new Date().toLocaleDateString('pt-BR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => onUpdateField({ dataAtendimento: e?.target?.value })}
            />
          </Grid>
          {/* Status do prospect */}
          <Grid item xs={12}>
            <FormControl
              fullWidth
              disabled={checkStatusPermission()}
              className={classes.formControl}
            >
              <InputLabel shrink htmlFor="dialog-fob-motorista">
                Status do prospect
              </InputLabel>
              <Select
                fullWidth
                value={form?.statusProspectId ?? ''}
                inputProps={{
                  name: 'statusProspectId',
                  id: 'statusProspectId',
                }}
                onChange={e => onUpdateField({ statusProspectId: e?.target?.value })}
              >
                {form?.listProspectStatus?.map(i => <MenuItem key={i?.id ?? 0} value={i?.id ?? 0}>{i?.description ?? ''}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          {/* Atendimento */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={form?.disabled ?? true}
              id="textoAtendimento"
              label="Atendimento"
              multiline
              minRows={6}
              maxRows={10}
              variant="outlined"
              value={form?.textoAtendimento ?? ''}
              onChange={e => onUpdateField({ textoAtendimento: e?.target?.value?.toUpperCase() })}
            />
          </Grid>
          <Visibility show={!(form?.disabled ?? true)}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormControlLabel
                    control={<Checkbox checked={form?.addDateAgenda ?? false} onChange={e => onUpdateField({ addDateAgenda: e?.target?.checked })} name="addDateAgenda" />}
                    label="Agendar"
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    disabled={form?.disabled ?? true}
                    id="dateAgenda"
                    label="Data Agendamento"
                    type="datetime-local"
                    // defaultValue="2017-05-24T10:30"
                    // className={classes.textField}
                    value={form?.dateAgenda ?? ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => onUpdateField({ dateAgenda: e?.target?.value })}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Visibility>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog} color="secondary">
          Cancelar
        </Button>
        <ButtonLoadingComp disabled={form?.disabled ?? true} loading={isSavingForm} onClick={onSaveForm} color="primary" autoFocus>
          Salvar
        </ButtonLoadingComp>
      </DialogActions>
    </Dialog>
  )
}

DialogAddAtendimento.propTypes = {
  // loading: PropTypes.bool,
  token: PropTypes.string.isRequired,
  open: PropTypes.bool,
  payload: PropTypes.shape(),
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  token: state.usuario.usuario.auth.token,
  cliente: state.usuario.usuario.cliente,
  usuario: state.usuario.usuario,
})

DialogAddAtendimento.defaultProps = {
  // loading: false,
  open: false,
  payload: {},
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...UsuarioActions,
  },
  dispatch,
)

export default (
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DialogAddAtendimento)
)
