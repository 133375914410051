/* eslint-disable no-debugger */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push as crrPush } from "connected-react-router";
import {
  Typography,
  Grid,
  CssBaseline,
  Tabs,
  Tab,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Icon,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  Button,
  NativeSelect
} from "@material-ui/core";

import { getClienteByIdApi, editClienteApi } from "api/cliente";
import { filterAgendaApi, findByIdAgendaApi } from "api/agenda";
import { filterCrmVistoriasApi } from "api/crm/vistorias";
import { filterCrmOportunidadeApi } from "api/crm/oportunidade";
import { filterCrmAtendimentosApi } from "api/crm/atendimentos";
import { filterCrmProspectStatusApi } from "api/crm/prospect/status";

import { Actions as ConfigActions } from "store/ducks/config";
import { Actions as DialogActions } from "store/ducks/dialog";
import { Actions as CrmContatosActions } from "store/ducks/crm/contato";
import GestorHeaderComp from "components/Gestor/Header";
import GestorDrawerComp from "components/Gestor/Drawer";
import TableDataComp from "components/Table";
// Agenda
import { Actions as AgendaActions } from "store/ducks/crm/agendamento";

import { DtFormat, currentTimestamp, moneyFormat } from "utils";

import DadosProspectTabContent from "pages/gestor/crm/components/DadosProspectTabContent";
import DadosProspectEnderecoTabContent from "pages/gestor/crm/components/DadosProspectEnderecoTabContent";
import DialogAddAtendimento from "pages/gestor/crm/components/Dialogs/DialogAddAtendimento";
import DialogAddOportunidade from "pages/gestor/crm/components/Dialogs/DialogAddOportunidade";
import DialogShowOportunidade from "pages/gestor/crm/components/Dialogs/DialogShowOportunidade";
import DialogAddAgendamento from "pages/gestor/crm/components/Dialogs/DialogAddAgendamento";

import useStyles from "./styles";
import { findAllMotivosApi } from "api/motivos";
import { listPedidoPadraoApi, sendMultiplosPedidosErpApi, sendPedidoErpApi } from "api/pedido";
import DadosProspectInteresseCompraTab from "./components/DadosProspectInteresseCompraTab";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import DialogSyncOportunidade from "pages/gestor/crm/components/DialogSyncOportunidade";
import { NotificationManager } from "react-notifications";
import MessageModal from "pages/gestor/crm/components/Dialogs/MessageModal";

const GestorCrmProspectDetailPage = props => {
  const {
    match: { params },
    usuario,
    // Dialog
    onDialogOpen,
    onDialogClose,
    onDialogActionLoading,
    onDelAgendaByprospect
  } = props;

  const classes = useStyles();

  // * State's
  const [listProspectStatus, setListProspectStatus] = useState([]);
  const { id, oportunidadeId } = useParams();
  // * Vistorias
  // const [dialogVistoria, setDialogVistoria] = useState(false)
  // const [payloadVistoria, setPayloadVistoria] = useState()
  const [listVistorias, setListVistorias] = useState([]);
  // * Atendimento
  const [dialogAtendimento, setDialogAtendimento] = useState(false);
  const [payloadAtendimento, setPayloadAtendimento] = useState();
  const [listAtendimentos, setListAtendimentos] = useState([]);
  // * Oportunidade
  const [dialogOportunidade, setDialogOportunidade] = useState(false);
  const [dialogShowOportunidade, setDialogShowOportunidade] = useState(false);
  const [payloadOportunidade, setPayloadOportunidade] = useState();
  const [listOportunidade, setListOportunidade] = useState([]);
  // * Agendamento
  const [dialogAgendamento, setDialogAgendamento] = useState(false);
  const [payloadAgendamento, setPayloadAgendamento] = useState();
  const [listAgendamento, setListAgendamento] = useState({});
  const [listMotivoCancel, setListMotivoCancel] = useState({});

  const [loading, setLoading] = useState({
    saveForm: false,
    loadingProspectInfo: false,
    loadingVistoria: false,
    loadingAtendimentos: false,
    loadingOportunidades: false,
    loadingAgendamentos: false,
    loadingMotivoCancel: false
  });
  const [tabSelected, setTabSelected] = useState(0);
  const [filterBy, setFilterBy] = useState({
    type: "",
    id: 0,
    listVistorias: [],
    listAtendimentos: [],
    listAgendamento: []
  });
  const [payload, setPayload] = useState({});
  const handleSelectTab = (event, newValue) => setTabSelected(newValue);

  const doubleLine = (text1, text2) => (
    <Grid container direction="column">
      <Grid>
        <strong>{text1}</strong>
      </Grid>
      <Grid>
        <span>{text2}</span>
      </Grid>
    </Grid>
  );
  const threeLine = (text1, text2, text3) => (
    <Grid container direction="column">
      <Grid>
        <strong>{text1}</strong>
      </Grid>
      <Grid>
        <span>{text2}</span>
      </Grid>
      <Grid>
        {text3}
      </Grid>
    </Grid>
  );
  const threeLineVistoria = (text1, text2, text3) => (
    <Grid container direction="column">
      <Grid>
        <strong>{text1}</strong>
      </Grid>
      <Grid>
        {text2[0]} <strong>{text2[1]}</strong>
      </Grid>
      <Grid>
        <span>{text3}</span>
      </Grid>
    </Grid>
  );
  const rowVistoria = r => {
    // const dataAgenda =
    const { oportunidadeId, realizada, descricao, agenteNome, dataAgenda } = r;
    return (
      <div className={classes.rowTable}>
        {threeLineVistoria(
          `[${DtFormat(dataAgenda)}] Oportunidade nº: ${oportunidadeId} `,
          [descricao, ` Realizada: ${realizada ? "SIM" : "NÃO"}`],
          `Agente: ${agenteNome}`
        )}
      </div>
    );
  };
  const rowAtendimento = r => {
    const { oportunidadeId, dataAtendimento, textoAtendimento } = r;
    let firstPart = ""
    let secondPart = ""

    if (textoAtendimento.includes("COMPROMISSO:")) {
      var textoAtendimentoSplit = textoAtendimento.split(',')
      firstPart = textoAtendimentoSplit[0];
      secondPart = textoAtendimentoSplit[1];
    }
    else if (textoAtendimento.includes("CANCELAMENTO DE AGENDA: ")) {
      let str = textoAtendimento;

      let splitStr = str.split(/:\s*/);

      firstPart = splitStr[0] + ":";

      secondPart = splitStr[1];

    }
    else if (textoAtendimento.includes("CANCELAMENTO DE VISTORIA: ")) {
      let str = textoAtendimento;

      let splitStr = str.split(/:\s*/);

      firstPart = splitStr[0] + ":";

      secondPart = splitStr[1];

    }
    else if (textoAtendimento.includes("OPORTUNIDADE CANCELADA: ")) {
      let str = textoAtendimento;

      let splitStr = str.split(/:\s*/);

      firstPart = splitStr[0] + ":";

      secondPart = splitStr[1];

    }
    else if (textoAtendimento.includes("VISTORIA AGENDADA")) {
      let str = textoAtendimento
      // Split the string using regular expressions
      let splitStr = str.split(/PRODUTO:/);

      // The first part of the split string is "VISTORIA AGENDADA"
      firstPart = splitStr[0].trim();

      // The second part of the split string is "PRODUTO:(ANY STRING)"
      secondPart = "PRODUTO:" + splitStr[1];

    }
    else if (textoAtendimento.includes("OPORTUNIDADE RECUSADA")) {
      let str = textoAtendimento

      let splitStr = str.split(/PRODUTO:/);

      firstPart = splitStr[0].trim();

      secondPart = "PRODUTO:" + splitStr[1];
    }
    else if (textoAtendimento.includes("GERADO POR INTERESSE DE COMPRA")) {
      let str = textoAtendimento

      let splitStr = str.split(/PRODUTO:/);

      firstPart = splitStr[0].trim();

      secondPart = "PRODUTO:" + splitStr[1];
    }
    else if (textoAtendimento.includes("OPORTUNIDADE ACEITA")) {
      let str = textoAtendimento

      let splitStr = str.split(/PRODUTO:/);

      firstPart = splitStr[0].trim();

      secondPart = "PRODUTO:" + splitStr[1];
    }
    else if (textoAtendimento.includes("ATEND.")) {
      let splitStr = textoAtendimento.split("OPORTUNID.");

      firstPart = splitStr[0].trim();
      secondPart = "OPORTUNID." + splitStr[1].trim();
    }
    else if (textoAtendimento.includes("ENTREGA")) {
      let str = textoAtendimento

      let splitStr = str.split(/PRODUTO:/);

      firstPart = splitStr[0].trim();

      secondPart = "PRODUTO:" + splitStr[1];
    }
    else if (textoAtendimento.includes("INTERESSE DE COMPRA")) {
      firstPart = "ATENDIMENTO - INTERESSE DECLINADO";
      secondPart = textoAtendimento;
    }
    else if (textoAtendimento.includes("CANCELAMENTO AGENDA")) {
      firstPart = "CANCELAMENTO";
      secondPart = textoAtendimento;
    }
    else {
      firstPart = "ATENDIMENTO AVULSO"
      secondPart = textoAtendimento
    }
    return (
      <div className={classes.rowTable}>
        {threeLine(
          `[${DtFormat(dataAtendimento)}] ${oportunidadeId ? "Oportunidade:" + oportunidadeId : "Atendimento"
          }`,
          firstPart,
          secondPart

        )}
      </div>
    );
  };
  const rowOportunidade = r => {
    const { pedidoStatusDescricao, emissao, id, total, valor, itens = [] } = r;
    return (
      <div className={classes.rowTable}>
        {doubleLine(
          `[${DtFormat(emissao)}] Oportunidade: ${id ??
          "S/D"} - ${pedidoStatusDescricao}`,
          `${itens.length} item(ns) no total de: ${moneyFormat(total)}`
        )}
      </div>
    );
  };
  const rowAgendamento = r => {
    const {
      crmOportunidadeId,
      dateAgenda,
      title,
      usuarioNome,
      crmAgentesName
    } = r;
    return (
      <div className={classes.rowTable}>
        <Grid container direction="column">
          <Grid>
            <strong>{`[${DtFormat(
              dateAgenda
            )}] ${crmOportunidadeId ? `Oportunidade: ` + crmOportunidadeId : `Atendimento`}`}
            </strong>
          </Grid>
          <Grid>
            <span>{`Para: ${crmAgentesName || usuarioNome}`}</span>
          </Grid>
          <Grid>
            <small>{title}</small>
          </Grid>
        </Grid>
      </div>
    );
  };

  const onLoadVistorias = () => {
    const crmProspectId = params.id;
    setLoading(s => ({ ...s, loadingVistoria: true }));
    filterCrmVistoriasApi({
      token: usuario?.auth?.token ?? "",
      query: { crmProspectId, crmOportunidadeId: oportunidadeId ?? "" }
    })
      .then(resp => {
        const { data = [] } = resp || {};
        setListVistorias(data);
      })
      .finally(() => {
        setLoading(s => ({ ...s, loadingVistoria: false }));
      });
  };
  const onLoadProspectStatus = () => {
    filterCrmProspectStatusApi({ token: usuario?.auth?.token ?? "" }).then(
      setListProspectStatus
    );
  };
  const onLoadAgendamentos = () => {
    const crmProspectId = params.id;
    const dateStartAgenda = currentTimestamp("YYYY-MM-DD");
    setLoading(s => ({ ...s, loadingAgendamentos: true }));
    filterAgendaApi({
      token: usuario?.auth?.token ?? "",
      query: { crmProspectId, dateStartAgenda, crmOportunidadeId: oportunidadeId ?? "" }
    })
      .then(resp => {
        setListAgendamento(resp);
      })
      .finally(() => {
        setLoading(s => ({ ...s, loadingAgendamentos: false }));
      });
  };
  const onLoadOportunidades = () => {
    const prospectId = params.id;
    setLoading(s => ({ ...s, loadingOportunidades: true }));
    filterCrmOportunidadeApi({
      token: usuario?.auth?.token ?? "",
      query: { prospectId: prospectId, rows: 1000, oportunidadeId: oportunidadeId ?? "" }
    })
      .then(resp => {
        const { data = [] } = resp ?? {};
        setListOportunidade(data);
        const pedidosWithStatus14 = data.filter(pedido => pedido.pedidoStatusId === 14);
        const idsWithStatus14 = pedidosWithStatus14.map(pedido => pedido.id);
        setSelectedPedidos(idsWithStatus14);
        setSelectedOportunidades(pedidosWithStatus14);
      })
      .finally(() => {
        setLoading(s => ({ ...s, loadingOportunidades: false }));
      });
  };
  const onLoadAtendimentos = () => {
    const prospectId = params.id;
    setLoading(s => ({ ...s, loadingAtendimentos: true }));
    filterCrmAtendimentosApi({
      token: usuario?.auth?.token ?? "",
      query: { prospectId: prospectId, oportunidadeId: oportunidadeId ?? "" }
    })
      .then(resp => {
        setListAtendimentos(resp);
      })
      .finally(() => {
        setLoading(s => ({ ...s, loadingAtendimentos: false }));
      });
  };
  const [pedidoPadraoItensByProspect, setPedidoPadraoItensByProspect] = useState([]);
  const criarListaItensPedidos = (usuario, prospectId) => {
    listPedidoPadraoApi({ token: usuario?.auth?.token ?? "", filter: { cliente: prospectId ? prospectId : "", oportunidadeId: oportunidadeId ? oportunidadeId : "" } })
      .then((resp) => {
        // Criação da lista vazia
        const listaItensPedidos = [];

        // Loop para percorrer cada pedido em "resp"
        for (let i = 0; i < resp.length; i++) {
          const pedido = resp[i];
          // Loop para percorrer cada item do pedido
          if (pedido && pedido.itens) {
            for (let j = 0; j < pedido.itens.length; j++) {
              const item = pedido.itens[j];
              // Adiciona o item na lista de itens de pedidos
              if (item && item.produtoId) {
                // Adiciona o item na lista de itens de pedidos apenas se não for vazio
                listaItensPedidos.push(item);
              }
            }
          }
        }
        setPedidoPadraoItensByProspect(listaItensPedidos);
      });
  };

  const onGetClientInfo = () => {
    const prospectId = params.id;
    if (prospectId) {
      setLoading(s => ({ ...s, loadingProspectInfo: true }));
      getClienteByIdApi({ token: usuario?.auth?.token ?? "", id: prospectId })
        .then(resp => {
          setPayload(resp);
          onLoadAtendimentos();
          onLoadOportunidades();
          onLoadAgendamentos();
          onLoadVistorias();
          onLoadMotivo();
          criarListaItensPedidos(usuario, prospectId);
        })
        .finally(() => {
          setLoading(s => ({ ...s, loadingProspectInfo: false }));
        });
    }
  };
  const onSaveFormProspect = formData => {
    if (formData.id) {
      setLoading(s => ({ ...s, saveForm: true }));
      editClienteApi({
        token: usuario?.auth?.token ?? "",
        dto: formData,
        id: formData.id
      })
        .then(() => {
          // setPayload(resp)
          onGetClientInfo();
        })
        .finally(() => setLoading(s => ({ ...s, saveForm: false })));
    }
  };
  /*
  const addVistorias = () => {
    console.log('NO ACTION')
  }
  */
  // ? Atendimento
  const addAtendimento = () => {
    setPayloadAtendimento({
      disabled: false,
      prospectId: payload?.id ?? 0,
      statusProspectId: payload?.crmProspectStatusId ?? 0,
      dataAtendimento: currentTimestamp("YYYY-MM-DDTHH:mm"),
      listProspectStatus
    });
    setDialogAtendimento(true);
  };
  const showAtendimento = pPayload => {
    const { prospectId, dataAtendimento, textoAtendimento } = pPayload;
    setPayloadAtendimento({
      disabled: true,
      prospectId,
      dataAtendimento,
      statusProspectId: payload?.crmProspectStatusId ?? 0,
      textoAtendimento,
      listProspectStatus
    });
    setDialogAtendimento(true);
  };
  // ? Oportunidade
  const addOportunidade = () => {
    setPayloadOportunidade({
      disabled: false,
      filialId: usuario?.unidade?.id ?? 0,
      prospectId: payload?.id ?? 0
    });
    setDialogOportunidade(true);
  };
  const showOportunidade = pPayload => {
    setPayloadOportunidade(pPayload);
    setDialogShowOportunidade(true);
  };
  const cancelOportunidade = pPayload => {
    setPayloadOportunidade({
      cancel: true,
      ...pPayload
    });
    setDialogShowOportunidade(true);
  };
  const onSavedOportunidade = dto => {
    onGetClientInfo();
  };
  const onFilterDataByOportunidade = row => {
    const { id } = row;
    if (!filterBy.type) {
      setFilterBy({
        id,
        type: "oportunidade",
        listVistorias,
        listAtendimentos,
        listAgendamento
      });
      setListVistorias(listVistorias?.filter(r => r.oportunidadeId === id));
      setListAtendimentos(
        listAtendimentos?.filter(r => r.oportunidadeId === id)
      );
      setListAgendamento(
        listAgendamento?.filter(r => r.crmOportunidadeId === id)
      );
      return;
    }

    if (row.id === filterBy.id) {
      setListVistorias(filterBy?.listVistorias);
      setListAtendimentos(filterBy?.listAtendimentos);
      setListAgendamento(filterBy?.listAgendamento);
      setFilterBy({
        id: 0,
        type: "",
        listVistorias: [],
        listAtendimentos: [],
        listAgendamento: []
      });
    } else {
      setFilterBy(s => ({ ...s, id }));
      setListVistorias(
        filterBy?.listVistorias?.filter(r => r.oportunidadeId === id)
      );
      setListAtendimentos(
        filterBy?.listAtendimentos?.filter(r => r.oportunidadeId === id)
      );
      setListAgendamento(
        filterBy?.listAgendamento?.filter(r => r.crmOportunidadeId === id)
      );
    }
  };
  // ? Agendamento
  const addAgendamento = () => {
    setPayloadAgendamento({ disabled: false, prospectId: payload?.id ?? 0 });
    setDialogAgendamento(true);
  };
  const showAgendamento = pPayload => {
    setPayloadAgendamento({
      ...pPayload,
      disabled: true
    });
    setDialogAgendamento(true);
  };

  const optionsCancel = [
    { value: "reagendamento", label: "Agente solicitou reagendamento " },
    { value: "erroOperador", label: "Erro do opeador" }
  ];

  const onLoadMotivo = () => {
    setLoading(s => ({ ...s, loadingMotivo: true }));
    findAllMotivosApi({ token: usuario?.auth?.token ?? "" })
      .then(resp => {
        setListMotivoCancel(resp);
      })
      .finally(() => {
        setLoading(s => ({ ...s, loadingMotivo: false }));
      });
  };

  const cancelAgendamento = pPayload => {
    setPayloadAgendamento({ cancel: true, ...pPayload });
    onDialogOpen({
      size: "sm",
      title: "Excluir?",
      content: (
        <FormControl id="forms-cancel">
          <p>Motivo do Cancelamento:</p>
          <NativeSelect
            defaultValue={"reagendamento"}
            labelId=""
            id="cancel-select-agenda"
            label="Motivo do Cancelamento"
          >
            {listMotivoCancel.map(response => (
              <option value={response.motivo_id}>{response.descricao}</option>
            ))}
          </NativeSelect>
          <br></br>
          <p>Tem certeza que deseja excluir este Agendamento?</p>
        </FormControl>
      ),
      actions: [
        {
          key: 1,
          title: "Sim",
          action: () => {
            onDialogActionLoading({ key: 1, loading: true });
            // Request
            onDelAgendaByprospect(
              pPayload.id,
              document.getElementById("cancel-select-agenda").selectedIndex + 1
            );
            onDialogClose();
            // Update components
            onGetClientInfo();
          },
          color: "secondary"
        },
        { key: 2, title: "Não", action: () => onDialogClose() }
      ]
    });
  };
  const onSavedAgendamento = dto => {
    onGetClientInfo();
  };

  const tableVistoriasCols = [
    {
      key: "",
      title: "VISTORIA",
      format: rowVistoria
    }
  ];
  const tableAtendimentosCols = [
    {
      key: "",
      title: "ATENDIMENTO",
      format: rowAtendimento
    }
  ];
  const tableAtendimentosActions = [
    {
      key: "visibility",
      title: "Exibir",
      icon: "visibility",
      action: showAtendimento
    }
  ];
  const tableOportunidadesCols = [
    {
      key: "",
      title: "OPORTUNIDADE",
      format: rowOportunidade,
      onClick: onFilterDataByOportunidade
    }
  ];
  const tableOportunidadesActions = [
    {
      key: "visibility",
      title: "Exibir",
      icon: "visibility",
      action: showOportunidade
    },
    {
      key: "cancel",
      title: "Cancelar",
      icon: "close",
      action: cancelOportunidade,
      disabled: row =>
        row.pedidoStatusDescricao === "CANCELADO" ||
        payload.crmProspectStatusDescription !== "DESENVOLVIMENTO"
    },
    {
      key: "sync",
      title: "Gerar Pedido",
      icon: "send",
      action: row => {
        setButtonClicked("single");
        // Defina o payload com base nos dados da linha (row)
        setPayloadSyncOportunidade(row); // Supondo que row tenha uma propriedade id
        // Abra o modal
        setDialogSyncOportunidade(true);
      },
      disabled: row => row.pedidoStatusId != 14
    },

    {
      key: "filter",
      title:
        filterBy.type === "oportunidade" ? "Remover Filtro" : "Aplicar Filtro",
      icon: "filter_alt",
      action: onFilterDataByOportunidade
    }
  ];
  const tableAgendamentosCols = [
    { key: "", title: "AGENDA", format: rowAgendamento }
  ];
  const tableAgendamentosActions = [
    {
      key: "visibility",
      title: "Exibir",
      icon: "visibility",
      action: showAgendamento
    },
    {
      key: "cancel",
      title: "Cancelar",
      icon: "close",
      action: cancelAgendamento
    }
  ];

  useEffect(() => {
    document.title = "Online - Prospect";
    document.body.style = "background-image: none;";
    onGetClientInfo();
    onLoadProspectStatus();
  }, []);

  const TabsContent = ({ tabActive }) => {
    switch (tabActive) {
      case 1:
        return (
          <DadosProspectEnderecoTabContent
            token={usuario?.auth?.token ?? ""}
            payload={payload?.enderecos ?? []}
            onSave={onGetClientInfo}
          />
        );
      case 2:
        return (
          <DadosProspectInteresseCompraTab
            token={usuario?.auth?.token ?? ""}
            payload={payload?.enderecos ?? []}
            onSave={onGetClientInfo}
            pedidoPadraoItensByProspect={pedidoPadraoItensByProspect ?? ""}
            usuario={usuario}
            criarListaItensPedidos={criarListaItensPedidos}
            prospectId={id}
            onDialogOpen={onDialogOpen}
            onDialogClose={onDialogClose}
            oportunidadeId={oportunidadeId}
          />
        );
      default:
        return loading?.loadingProspectInfo ? (
          <CircularProgress style={{ margin: 10 }} />
        ) : (
          <DadosProspectTabContent
            loading={loading?.saveForm ?? false}
            payload={payload}
            onSave={onSaveFormProspect}
          />
        );
    }
  };
  TabsContent.propTypes = {
    tabActive: PropTypes.number.isRequired
  };
  const handleEnviarClick = () => {
    if (!oportunidadeId || !usuario?.auth?.token) {
      // Verificar se oportunidadeId ou token são undefined ou vazios
      console.error('Valores inválidos: oportunidadeId ou token ausentes');
      return;
    }

    // Organizar o DTO com os valores dos estados
    const dto = {
      pedidoId: oportunidadeId,
      token: usuario.auth.token,
    };

    // Chamar a função sendPedidoErpApi com o DTO
    sendPedidoErpApi(dto)
      .then((response) => {
        // Lógica adicional após a chamada da API, se necessário
        console.log('Pedido enviado com sucesso:', response);
      })
      .catch((error) => {
        // Tratamento de erros, se necessário
        console.error('Erro ao enviar o pedido:', error);
      });
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleEnviarClickByRowID = (row) => {
    setIsLoading(true); // Iniciar loading
    // Organizar o DTO com os valores dos estados
    const dto = {
      pedidoId: row.id,
      token: usuario.auth.token,
    };

    // Chamar a função sendPedidoErpApi com o DTO
    sendPedidoErpApi(dto)
      .then((response) => {
        setIsLoading(false); // Finalizar loading
        if (response && response.success) {
          onGetClientInfo()
          // Notificação de sucesso
          setIsModalOpen(true)
          setModalMessage(response.data);
          // NotificationManager.info('', response.message);
        } else {
          // Notificação de aviso
          NotificationManager.warning('', response.message);
        }
      })
      .catch((error) => {
        // Tratamento de erros
        console.error('Erro ao enviar o pedido:', error);
        console.log(error)
        NotificationManager.error('Erro', 'Erro ao enviar o pedido. Por favor, tente novamente.');
      });
  };

  const [selectedPedidos, setSelectedPedidos] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(null); // "single" ou "multiple"

  const handleEnviarMultiplosPedidos = () => {
    setIsLoading(true); // Iniciar loading
    const dto = {
      pedidoIds: selectedPedidos,
      token: usuario.auth.token,
    };

    sendMultiplosPedidosErpApi(dto)
      .then((response) => {
        setIsLoading(false); // Finalizar loading
        if (response && response.success) {
          // Notificação de sucesso
          setIsModalOpen(true)
          setModalMessage(response.data);
          onGetClientInfo()
          // NotificationManager.info('', response.message);
          // setSelectedPedidos([]); // Limpe a seleção após o envio bem-sucedido
        } else {
          // Notificação de aviso
          NotificationManager.warning('Aviso', response.message);
        }
      })
      .catch((error) => {
        // Tratamento de erros
        console.error('Erro ao enviar o pedido:', error);
        console.log(error)
        NotificationManager.error('Erro', 'Erro ao enviar os pedidos. Por favor, tente novamente.');
      });
  };

  // Estado para controlar a abertura/fechamento do modal
  const [dialogSyncOportunidade, setDialogSyncOportunidade] = useState(false);

  // Estado para armazenar o payload da oportunidade a ser sincronizada
  const [payloadSyncOportunidade, setPayloadSyncOportunidade] = useState(null);

  // Função para abrir o modal e configurar o payload
  const handleOpenDialog = (payload) => {
    setPayloadSyncOportunidade(payload);
    setDialogSyncOportunidade(true);
  };
  const [selectedOportunidades, setSelectedOportunidades] = useState([]);

  const syncMultipleOportunidades = () => {
    setButtonClicked("multiple");
    if (!selectedOportunidades || selectedOportunidades.length === 0) {
      // Enviar notificação de erro
      NotificationManager.warning('Não existem oportunidades válidas para gerar pedido!');
      return;
    }

    // Defina o payload como o array de oportunidades selecionadas
    setPayloadSyncOportunidade(selectedOportunidades);
    // Abra o dialog
    setDialogSyncOportunidade(true);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={1}>
          {/* Header */}
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" component="h2">
                  {oportunidadeId ? `Oportunidade ${oportunidadeId}` : 'Prospect'}
                </Typography>
                <small>Detalhe</small>
              </Grid>
            </Grid>
          </Grid>
          {/* Body */}
          <Grid item xs={12} md={12}>
            <Grid container spacing={1}>
              {/* Informacoes do prospect */}
              <Grid item xs={12} md={8}>
                <Card elevation={1}>
                  <CardHeader
                    title={
                      "Informações do prospect: " +
                      (payload && payload.crmProspectStatusDescription
                        ? payload.crmProspectStatusDescription
                        : "")
                    }

                  >
                  </CardHeader>
                  {oportunidadeId && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEnviarClick}
                      disabled={listOportunidade[0]?.pedidoStatusId !== 14}
                    >
                      Enviar
                    </Button>
                  )}



                  <CardContent>
                    <Tabs
                      value={tabSelected}
                      onChange={handleSelectTab}
                      indicatorColor="primary"
                      textColor="primary"
                      style={{ marginBottom: 10 }}
                    >
                      <Tab
                        label="Dados"
                        disabled={loading?.loadingProspectInfo ?? false}
                      />
                      <Tab
                        label="Endereço(s)"
                        disabled={loading?.loadingProspectInfo ?? false}
                      />
                      <Tab
                        label="INTERESSE COMPRA"
                        disabled={loading?.loadingProspectInfo ?? false}
                      />
                    </Tabs>
                    <TabsContent tabActive={tabSelected} />

                  </CardContent>
                </Card>
              </Grid>
              {/* Vistorias */}
              <Grid item xs={12} md={4}>
                <Card elevation={1} className={classes.fullHeight}>
                  <CardHeader title="Histórico de Vistorias" />
                  <CardContent>
                    <TableDataComp
                      size="small"
                      loading={loading?.loadingVistoria ?? false}
                      rows={listVistorias}
                      cols={tableVistoriasCols}
                    />
                  </CardContent>
                </Card>
                <DialogAddAtendimento
                  token={usuario?.auth?.token ?? ""}
                  open={dialogAtendimento}
                  payload={payloadAtendimento}
                  onClose={() => setDialogAtendimento(false)}
                  onSave={() => onGetClientInfo()}
                />
              </Grid>
              {/* Atendimentos */}
              <Grid item xs={12} md={4}>
                <Card elevation={1}>
                  <CardHeader
                    title="Histórico de atendimento"
                    action={
                      <IconButton color="secondary" onClick={addAtendimento}>
                        <Icon>add</Icon>
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <TableDataComp
                      size="small"
                      loading={loading?.loadingAtendimentos ?? false}
                      rows={listAtendimentos}
                      cols={tableAtendimentosCols}
                      actions={tableAtendimentosActions}
                    />
                  </CardContent>
                </Card>
                <DialogAddAtendimento
                  token={usuario?.auth?.token ?? ""}
                  open={dialogAtendimento}
                  payload={payloadAtendimento}
                  onClose={() => setDialogAtendimento(false)}
                  onSave={() => onGetClientInfo()}
                />
              </Grid>
              {/* Oportunidades */}
              <Grid item xs={12} md={4}>
                <Card elevation={1}>
                  <CardHeader
                    title="Oportunidades"
                    action={
                      <>
                        <IconButton color="secondary" onClick={addOportunidade}>
                          <Icon>add</Icon>
                        </IconButton>
                        <IconButton style={{ color: 'green' }} onClick={syncMultipleOportunidades}>
                          <Icon>send</Icon>
                          <span style={{ fontSize: '1rem' }}>Gerar Pedidos</span>
                        </IconButton>

                      </>
                    }
                  />
                  <CardContent>
                    <TableDataComp
                      size="small"
                      loading={loading?.loadingOportunidades ?? false}
                      rows={listOportunidade}
                      cols={tableOportunidadesCols}
                      actions={tableOportunidadesActions}
                    />
                    <DialogAddOportunidade
                      token={usuario?.auth?.token ?? ""}
                      open={dialogOportunidade}
                      payload={payloadOportunidade || {}}
                      onClose={() => setDialogOportunidade(false)}
                      onSave={onSavedOportunidade}
                      clienteId={params.id}
                      usuario={usuario}
                    />
                    <DialogShowOportunidade
                      token={usuario?.auth?.token ?? ""}
                      open={dialogShowOportunidade}
                      payload={payloadOportunidade}
                      onClose={() => setDialogShowOportunidade(false)}
                      onSave={onSavedOportunidade}
                    />
                    <DialogSyncOportunidade
                      token={usuario?.auth?.token ?? ""}
                      open={dialogSyncOportunidade}
                      payload={payloadSyncOportunidade}
                      onClose={() => setDialogSyncOportunidade(false)}
                      onSave={buttonClicked === "single" ? handleEnviarClickByRowID : handleEnviarMultiplosPedidos}
                      isLoading={isLoading ?? false}
                    />
                    <MessageModal
                      isOpen={isModalOpen}
                      messages={modalMessage}
                      onClose={() => setIsModalOpen(false)}
                    />
                  </CardContent>
                </Card>
              </Grid>
              {/* Agendamentos */}
              <Grid item xs={12} md={4}>
                <Card elevation={1}>
                  <CardHeader
                    title="Agendamentos"
                    action={
                      <IconButton color="secondary" onClick={addAgendamento}>
                        <Icon>add</Icon>
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <TableDataComp
                      size="small"
                      loading={loading?.loadingAgendamentos ?? false}
                      rows={listAgendamento || []}
                      cols={tableAgendamentosCols}
                      actions={tableAgendamentosActions}
                    />
                    <DialogAddAgendamento
                      token={usuario?.auth?.token ?? ""}
                      open={dialogAgendamento}
                      payload={payloadAgendamento}
                      onClose={() => setDialogAgendamento(false)}
                      onSave={onSavedAgendamento}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

GestorCrmProspectDetailPage.propTypes = {
  match: PropTypes.shape().isRequired,
  usuario: PropTypes.shape().isRequired,
  crmContatos: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
  onFilterCrmContatos: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  crmContatos: state.crmContatos
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...DialogActions,
      ...ConfigActions,
      ...CrmContatosActions,
      ...AgendaActions,
      push: crrPush
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GestorCrmProspectDetailPage);
