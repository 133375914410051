import {makeStyles} from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
  }, content: {
    flexGrow: 1, padding: theme.spacing(3),
  }, leftIcon: {
    marginRight: theme.defaults.margin / 2,
  }, margin: {
    marginRight: theme.spacing(2),
  }, textField: {
    // flexBasis: 200,
  }, toolbar: theme.mixins.toolbar, // Detalhes
  expansionPanel: {
    marginTop: theme.spacing(2),
  }, expansionPanelSummary: {
    fontSize: 17, fontWeight: '900',
  }, paper: {
    ...theme.mixins.gutters(),
  },
}))

export default styles
