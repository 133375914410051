const styles = theme => ({
  root: {
    paddingTop: 20,
  },
  grid: {
    marginLeft: theme.defaults.margin * 2,
    marginRight: theme.defaults.margin * 2,
  },
  titleRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
  loadingPadding: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
})

export default styles
