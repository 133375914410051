import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Divider,
} from '@material-ui/core'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'

import { cancelCrmOportunidadeApi } from 'api/crm/oportunidade'
// import { addCrmAtendimentosApi } from 'api/crm/atendimentos'
import TableDataComp from 'components/Table'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'
import Visibility from 'components/Visibility'
import { moneyFormat, DtFormat } from 'utils'
import useStyles from './styles'

const DialogShowOportunidade = (props) => {
  const {
    token,
    open,
    payload,
    onSave,
    onClose,
  } = props

  const [isSavingForm, setSavingForm] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [form, setForm] = useState()
  const [itensOportunidade, setItensOportunidade] = useState([])

  const classes = useStyles()

  const onUpdateField = (data) => {
    setForm(s => ({ ...s, ...data }))
  }
  const onCloseDialog = () => {
    if (_.isFunction(onClose)) {
      setOpen(false)
      onClose()
    }
  }
  const onCancelOportunidade = () => {
    if (!form.id) {
      NotificationManager.warning(
        'Identificador da oportunidade está inválido!',
        'Atenção',
        8000,
      )
      return
    }
    if (!form.motivo) {
      NotificationManager.warning(
        'Você deve informar um motivo!',
        'Atenção',
        8000,
      )
      return
    }
    setSavingForm(true)
    cancelCrmOportunidadeApi({ token, id: form?.id ?? 0, motivo: form?.motivo ?? '' })
      .then((resp) => {
        if (resp && resp.status === 204) {
          NotificationManager.success(
            `A oportunidade ${form.id} foi cancelada!`,
            'Sucesso',
            8000,
          )
        }
      }).finally(() => {
        setSavingForm(false)
        onCloseDialog()
        if (_.isFunction(onSave)) {
          onSave(form, () => setSavingForm(true), () => setSavingForm(false))
        }
      })
  }
  const tableAtendimentosCols = [
    { key: 'dataAtendimento', title: 'DATA', format: r => DtFormat(r.dataAtendimento) },
    { key: 'textoAtendimento', title: 'ATENDIMENTO' },
  ]
  const tableItensCols = [
    { key: 'produtoDescricao', title: 'PRODUTO' },
    { key: 'a', title: 'PAGAMENTO', format: r => `${r.pagamentoFormaDescricao}/${r.pagamentoCondicaoDescricao}` },
    { key: 'b', title: 'VALOR', format: r => moneyFormat(r.valor) },
  ]

  useEffect(() => {
    if (!_.isEmpty(payload?.itens)) {
      setForm({ ...payload, statusProspect: '1' })
      setItensOportunidade(payload.itens)
    } else {
      setForm({ ...payload, statusProspect: '1' })
    }
  }, [payload])

  useEffect(() => {
    setOpen(open)
  }, [open])

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={isOpen}
      onClose={onCloseDialog}
      aria-labelledby="dialog-crm-atendimento"
    >
      <DialogTitle id="dialog-crm-atendimento-title">
        {`Oportunidade: Nº ${form?.id ?? 'NÃO GERADO'}`}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row">
          <Visibility show={form?.cancel ?? false}>
            <Grid item xs={12}>
              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item>
                  <h3>Você confirma o cancelamento da oportunidade baixo?</h3>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="motivo"
                    label="Qual o motivo cancelamento?"
                    multiline
                    maxRows={5}
                    variant="outlined"
                    value={form?.motivo ?? ''}
                    onChange={(e) => {
                      onUpdateField({ motivo: e?.target?.value?.toUpperCase() })
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <ButtonLoadingComp
                    autoFocus
                    disabled={_.isEmpty(form?.motivo ?? '')}
                    loading={isSavingForm}
                    color="primary"
                    variant="contained"
                    onClick={onCancelOportunidade}
                  >
                    SIM, EU CONFIRMO!
                  </ButtonLoadingComp>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ marginTop: 20 }} />
              </Grid>
            </Grid>
          </Visibility>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={10}>
            <Grid container spacing={2} direction="row">
              <Grid item xs={12}>
                <h4>Informações</h4>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <strong>Orçamento nº: </strong>
                    {form?.orcamento || 'NÃO GERADO'}
                    <br />
                    <strong>Criado por: </strong>
                    {form?.usuarioNome ?? ''}
                    <br />
                    <strong>Emissão: </strong>
                    {DtFormat(form?.emissao ?? '')}
                    <br />
                    <strong>Total: </strong>
                    {moneyFormat(form?.total ?? '')}
                    <br />
                    <strong>Status: </strong>
                    {form?.pedidoStatusDescricao ?? ''}
                  </Grid>
                </Grid>
              </Grid>
             
              {/* Itens Oportunidade */}
              <Grid item xs={12}>
                <h4>Item(ns) da oportunidade</h4>
              </Grid>
              <Grid item xs={12}>
                <TableDataComp
                  size="small"
                  // loading={loading.aprovacoes}
                  loading={false}
                  rows={itensOportunidade}
                  cols={tableItensCols}
                  // actions={tableItensActions}
                />
              </Grid>
               {/* Atendimentos */}
               <Grid item xs={12}>
                <h4>Atendimento(s) da oportunidade</h4>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableDataComp
                  size="small"
                  // loading={loading.aprovacoes}
                  loading={false}
                  rows={form?.atendimentos ?? []}
                  cols={tableAtendimentosCols}
                  // actions={tableItensActions}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={1} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog} color="secondary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogShowOportunidade.propTypes = {
  // loading: PropTypes.bool,
  token: PropTypes.string.isRequired,
  open: PropTypes.bool,
  payload: PropTypes.shape().isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

DialogShowOportunidade.defaultProps = {
  // loading: false,
  open: false,
}

export default DialogShowOportunidade
