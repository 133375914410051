/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import Numeral from 'numeral'
import moment from 'moment'
// import { withStyles } from '@material-ui/core/styles'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { SaveAlt, Info } from '@material-ui/icons'
// import styles from './styles'

export const gridBoleto = (props) => {
  const {
    load, gerando, onDownloadBoleto, data = [],
  } = props

  const handleDownloadTituloVencido = (pRow) => {
    NotificationManager.warning(
      `O Titulo ${pRow.titulo}${pRow.parcela ? `/${pRow.parcela}` : ''} no valor de R$ ${Numeral(
        pRow.valor_ori,
      ).format('0,0.00')} está vencido, você deve entrar em contato com o nosso financeiro.`,
      'Atenção:',
      10000,
    )
  }
  const handleDownloadTituloQuitado = (pRow) => {
    NotificationManager.success(
      `O Titulo ${pRow.titulo}${
        pRow.parcela ? `/${pRow.parcela}` : ''
      } consta como PAGO e foi dado baixa em nosso sistema dia ${moment(
        pRow.baixa,
        'YYYYMMDD',
      ).format('DD/MM/YYYY')}.`,
      'Atenção:',
      10000,
    )
  }
  const handleDownloadTituloSemConta = (pRow) => {
    NotificationManager.warning(
      `Não é possível gerar o titulo ${pRow.titulo}${
        pRow.parcela ? `/${pRow.parcela}` : ''
      }, pois existe bloqueio no ERP (CONTA: ${pRow.numero_conta}).`,
      'Atenção:',
      10000,
    )
  }
  const ButtonDownloadBoletoComp = (pTitulo) => {
    const {
      numero_conta, saldo, situacao, titulo, parcela,
    } = pTitulo
    if (!numero_conta) {
      return (
        <Button variant="outlined" onClick={() => handleDownloadTituloSemConta(pTitulo)}>
          {!_.isEmpty(gerando)
          && (gerando.titulo === titulo && gerando.parcela === parcela) ? (
            <CircularProgress size={16} />
            ) : (
              <Info />
            )}
        </Button>
      )
    }
    if (saldo > 0 && situacao.toUpperCase() === 'EMDIA') {
      return (
        <Button variant="outlined" onClick={() => onDownloadBoleto(pTitulo)}>
          {!_.isEmpty(gerando)
          && (gerando.titulo === titulo && gerando.parcela === parcela) ? (
            <CircularProgress size={16} />
            ) : (
              <SaveAlt />
            )}
        </Button>
      )
    }
    if (saldo > 0 && situacao.toUpperCase() === 'VENCIDO') {
      return (
        <Button
          variant="outlined"
          onClick={() => handleDownloadTituloVencido(pTitulo)}
          color="secondary"
        >
          {!_.isEmpty(gerando)
          && (gerando.titulo === titulo && gerando.parcela === parcela) ? (
            <CircularProgress size={16} />
            ) : (
              'VENCIDO'
            )}
        </Button>
      )
    }
    if (saldo === 0 && situacao.toUpperCase() === 'QUITADO') {
      return (
        <Button
          variant="outlined"
          onClick={() => handleDownloadTituloQuitado(pTitulo)}
          style={{ color: '#008000' }}
        >
          {!_.isEmpty(gerando)
          && (gerando.titulo === titulo && gerando.parcela === parcela) ? (
            <CircularProgress size={16} />
            ) : (
              'PAGO'
            )}
        </Button>
      )
    }
    return null
  }

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Titulo</TableCell>
            <TableCell align="right">Parcela</TableCell>
            <TableCell align="right">Principal</TableCell>
            <TableCell align="right">Saldo</TableCell>
            <TableCell align="right">Emissão</TableCell>
            <TableCell align="right">Vencimento</TableCell>
            <TableCell align="right">Baixa</TableCell>
            <TableCell align="right">Loja</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {load ? (
            <TableRow>
              <TableCell>
                <CircularProgress size={16} />
                <span style={{ marginLeft: 5 }}>Filtrando...</span>
              </TableCell>
            </TableRow>
          ) : (
            _.orderBy(data ?? [], ['titulo', 'parcela'], ['asc', 'asc']).map(row => (
              <TableRow key={row.titulo}>
                <TableCell component="th" scope="row">
                  {row.titulo}
                </TableCell>
                <TableCell align="right">{row.parcela}</TableCell>
                <TableCell align="right">
                  {`R$ ${Numeral(row.valor || row.valor_ori).format(
                    '0,0.00',
                  )}`}
                </TableCell>
                <TableCell align="right">{`R$ ${Numeral(row.saldo).format('0,0.00')}`}</TableCell>
                <TableCell align="right">
                  {moment(row.emissao, 'YYYYMMDD').format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="right">
                  {moment(row.vencrea, 'YYYYMMDD').format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="right">
                  {row.baixa && moment(row.baixa, 'YYYYMMDD').format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="right">{row.loja}</TableCell>
                <TableCell>
                  {ButtonDownloadBoletoComp(row)}
                </TableCell>
              </TableRow>
            ))
          )}
          {(data?.length ?? 0) > 0 && !load && (
            <TableRow>
              <TableCell colSpan={2}>TOTAL:</TableCell>
              <TableCell align="right">
                {`R$ ${Numeral(data?.reduce((a, b) => a + (b.valor || b.valor_ori), 0)).format('0,0.00')}`}
              </TableCell>
              <TableCell align="right">
                {`R$ ${Numeral(data?.reduce((a, b) => a + b.saldo, 0)).format('0,0.00')}`}
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !load && (
            <TableRow>
              <TableCell>Nenhum boleto.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  )
}

gridBoleto.propTypes = {
  load: PropTypes.bool.isRequired,
  gerando: PropTypes.shape().isRequired,
  data: PropTypes.arrayOf(),
  onDownloadBoleto: PropTypes.func.isRequired,
}
gridBoleto.defaultProps = {
  data: [],
}
