import { API_URL } from '..'
import { httpParseJson, httpValidState, httpValidErros } from '../../utils/http'

const ref = '/clientes'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/*--------------
  Http Request
--------------*/
/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} id - ID do cliente no portal
 */
export const getClienteByIdApi = (dto) => {
  const { token, id } = dto
  return fetch(`${API_URL}${ref}/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} query - Valor usado para pesquisar dados
 */
export const searchClienteApi = ({ token, query }) => {

  
  let queryUrl = query.page ? `&page=${query.page}` : ''
  queryUrl += query.rows ? `&rows=${query.rows}` : ''
  queryUrl += query.search ? `&search=${query.search}` : ''
  queryUrl += query.isProspects ? `&isProspects=${query.isProspects}` : ''
  queryUrl += query.crmProspectStatusId ? `&crmProspectStatusId=${query.crmProspectStatusId}` : ''

  return fetch(`${API_URL}${ref}/filter?1=1${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  }).then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} q - Valor usado para pesquisar dados
 */
export const searchClienteErpFilterApi = (dto) => {
  const { token, search = '', clienteEntrega = 'N' } = dto
  return fetch(`${API_URL}${ref}/erp/filter?search=${search}&clienteEntrega=${clienteEntrega}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} id - ID do cliente no portal
 */
export const getClienteInfoByIdApi = (dto) => {
  const { token, clienteId } = dto
  return fetch(`${API_URL}${ref}/info/${clienteId}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} crmProspectStatusId - ID do cliente no portal
 */
export const getClienteInfoByCrmProspectId = (dto) => {
  const { token, crmProspectStatusId } = dto
  return fetch(`${API_URL}${ref}/crmprospectstatusid/${crmProspectStatusId}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}


/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} cgc - Cgc do cliente
 */
export const getCgcApi = ({ cgc }) => {
  const queryUrl = `?cgc=${cgc}`
  return fetch(`${API_URL}${ref}/novo${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
    },
  })
    .then(res => res.json())
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - DTO com os dados do cliente
 */
export const addClienteApi = ({ token, dto }) => fetch(`${API_URL}${ref}`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - DTO com os dados do cliente
 * @param {Integer} id - ID do cliente
 */
export const editClienteApi = ({ token, dto, id }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} cgc - Cgc do cliente
 */
export const addNovoClienteApi = cliente => fetch(`${API_URL}${ref}/novo`, {
  method: 'POST',
  headers: {
    ...headers,
  },
  body: JSON.stringify(cliente),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} codigo - Código do CLIENTE no Protheus
 * @param {String} loja - Código da LOJA no Protheus
 */
export const getLimiteCredito = ({
  token,
  empresa,
  filial,
  codigo,
  loja,
}) => {
  const queryUrl = `?empresa=${empresa}&filial=${filial}&codigo=${codigo}&loja=${loja}`
  return fetch(`${API_URL}${ref}/limiteCredito${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(res => httpValidState(res, true))
    .catch(httpValidErros)
}

/**
 *
 * @function getPagamentos - Retorna um array de formas x condições de pagamento
 * @param {String} token - Token de autenticação
 * @param {Number} id - Código do cliente
 */
export const getPagamentos = ({ token, id }) => {
  const queryUrl = `/pagamentos?id=${id}`
  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(res => httpValidState(res, true))
    .catch(httpValidErros)
}

/**
 *
 * @function getObras - Retorna um array de objetos de obras
 * @param {String} token - Token de autenticação
 * @param {String} empresa - Código da EMPRESA no Protheus (cEmpAnt)
 * @param {String} filial - Código da FILIAL no Protheus (cFilAnt)
 * @param {String} codigo - Código do CLIENTE no Protheus
 * @param {String} loja - Código da LOJA no Protheus
 */
export const getObras = ({
  token, empresa, filial, codigo, loja,
}) => {
  const queryUrl = `/obras?empresa=${empresa}&filial=${filial}&codigo=${codigo}&loja=${loja}`
  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(res => httpValidState(res, true))
    .catch(httpValidErros)
}

/*
 * Sync Produtos Padrão
 */
export const syncProdutosProtheusApi = ({
  token, empresaCod, filialCod, clienteCod, clienteLoja,
}) => {
  const queryUrl = `/sync/padrao/produtos?empresa=${empresaCod}&filial=${filialCod}&cliente=${clienteCod}&loja=${clienteLoja}`
  return fetch(`${API_URL}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(res => httpValidState(res, true))
    .catch(httpValidErros)
}

/*
 * Sync Produtos
 */
export const syncClienteProtheusApi = ({
  token, empresaCod, filialCod, clienteCod, clienteLoja,
}) => {
  const queryUrl = `/sync/mizu/produtos?empresa=${empresaCod}&filial=${filialCod}&cliente=${clienteCod}&loja=${clienteLoja}`
  return fetch(`${API_URL}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @function getMotoristasVeiculos - Retorna um array de objetos de obras
 * @param {String} token - Token de autenticação
 * @param {String} empresa - Código da EMPRESA no Protheus (cEmpAnt)
 * @param {String} filial - Código da FILIAL no Protheus (cFilAnt)
 * @param {String} codigo - Código do CLIENTE no Protheus
 * @param {String} loja - Código da LOJA no Protheus
 */
export const getMotoristasVeiculos = ({
  token, empresa, filial, codigo, loja,
}) => {
  const queryUrl = `/veiculos?empresa=${empresa}&filial=${filial}&codigo=${codigo}&loja=${loja}`
  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(res => httpValidState(res, true))
    .catch(httpValidErros)
}

/**
 *
 * @function getTitulosVencidos- Retorna um array de objetos de obras
 * @param {String} token - Token de autenticação
 * @param {String} empresa - Código da EMPRESA no Protheus (cEmpAnt)
 * @param {String} filial - Código da FILIAL no Protheus (cFilAnt)
 * @param {String} codigo - Código do CLIENTE no Protheus
 * @param {String} loja - Código da LOJA no Protheus
 */
export const getTitulosVencidos = ({
  token, empresa, filial, codigo, loja,
}) => {
  const queryUrl = `/titulos-vencidos?empresa=${empresa}&filial=${filial}&codigo=${codigo}&loja=${loja}`
  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(res => httpValidState(res, true))
    .catch(res => httpValidErros(res, true))
}

// Área do Gestor
// ---------------

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do cliente, fk: ID da unidade }
 */
export const AddClienteUnidadeAPI = ({ token, dto }) => fetch(`${API_URL}${ref}/unidade`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const DeleteClienteUnidadeApi = ({ token, cliente = 0, unidade = 0 }) => fetch(`${API_URL}${ref}/unidade?cliente=${cliente}&unidade=${unidade}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)
