/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Input,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core'

import { EChecklistItemType } from 'utils/enums'

// Actions
import { Actions as ChecklistActions } from 'store/ducks/checklist'

const DialogformAlternativas = (props) => {
  const {
    // auth: { session: { tenantId = '' } = {} },
    checklist: { loading, formChecklist = {}, formAlternativas = {} },
    onChecklistFormAlternativas, onAddAlternativa, onEditAlternativa,
  } = props

  const handleClose = () => onChecklistFormAlternativas({ open: false })

  const handleAdicionar = () => onAddAlternativa()

  const handleEditar = () => onEditAlternativa()

  const isSelecionadoDisabled = (pChecklistItensId) => {
    const item = formChecklist.itens.find(i => i.id === pChecklistItensId)
    return (item && item.type !== 'RADIO')
  }

  const isObrigatorioDisabled = (pChecklistItensId) => {
    const item = formChecklist.itens.find(i => i.id === pChecklistItensId)
    return (item && (item.type === 'RADIO' || item.type === 'CHECKBOX'))
  }

  return (
    <Dialog
      open={formAlternativas.open}
      onClose={handleClose}
      aria-labelledby="dialog-checklist-alternativas-title"
      aria-describedby="dialog-checklist-alternativas-description"
    >
      <DialogTitle id="dialog-checklist-alternativas-title">
        {`${formAlternativas.id ? 'Edição' : 'Cadastro'} de Alternativa`}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="checklist-alternativas-checklistItensId-label">Pergunta</InputLabel>
              <Select
                labelId="checklist-alternativas-checklistItensId-label"
                id="checklist-alternativas-checklistItensId"
                value={formAlternativas.checklistItensId}
                onChange={evt => onChecklistFormAlternativas({
                  checklistItensId: evt.target.value,
                })}
              >
                <MenuItem value="">
                  <em>SELECIONE</em>
                </MenuItem>
                {formChecklist.itens.map(i => (
                  <MenuItem value={i.id}>{`${i.label} (${EChecklistItemType[i.type]})`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="checklist-alternativas-descricao-label">
                Descrição da resposta
              </InputLabel>
              <Input
                id="checklist-alternativas-descricao-label"
                label="Descrição"
                value={formAlternativas.label}
                onChange={evt => onChecklistFormAlternativas({
                  label: evt.target.value,
                })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="checklist-alternativas-value-label">
                Valor da resposta
              </InputLabel>
              <Input
                id="checklist-alternativas-value-label"
                label="Valor"
                value={formAlternativas.value}
                onChange={evt => onChecklistFormAlternativas({
                  value: evt.target.value,
                })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel id="checklist-alternativas-checked-label">Selecionado?</InputLabel>
              <Select
                disabled={isSelecionadoDisabled(formAlternativas.checklistItensId)}
                labelId="checklist-alternativas-checked-label"
                id="checklist-alternativas-checked"
                value={formAlternativas.checked ? 'SIM' : 'NAO'}
                onChange={evt => onChecklistFormAlternativas({
                  checked: evt.target.value === 'SIM',
                })}
              >
                <MenuItem value="">
                  <em>SELECIONE</em>
                </MenuItem>
                <MenuItem value="SIM">SIM</MenuItem>
                <MenuItem value="NAO">NÃO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel id="checklist-alternativas-checked-label">Obrigatório?</InputLabel>
              <Select
                disabled={isObrigatorioDisabled(formAlternativas.checklistItensId)}
                labelId="checklist-alternativas-checked-label"
                id="checklist-alternativas-checked"
                value={formAlternativas.isRequired}
                onChange={evt => onChecklistFormAlternativas({
                  isRequired: evt.target.value,
                })}
              >
                <MenuItem value="">
                  <em>SELECIONE</em>
                </MenuItem>
                <MenuItem value="S">SIM</MenuItem>
                <MenuItem value="N">NÃO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        {formAlternativas.id ? (
          <Button onClick={handleEditar} color="secondary" autoFocus>
            Editar
          </Button>
        ) : (
          <Button onClick={handleAdicionar} color="secondary" autoFocus>
            Adicionar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

DialogformAlternativas.propTypes = {
  checklist: PropTypes.shape().isRequired,
  onChecklistFormAlternativas: PropTypes.func.isRequired,
  onAddAlternativa: PropTypes.func.isRequired,
  onEditAlternativa: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...ChecklistActions,
  },
  dispatch,
)

const mapStateToProps = state => ({
  auth: state.usuario.usuario.auth,
  usuario: state.usuario.usuario,
  unidade: state.usuario.usuario.unidade,
  checklist: state.checklist,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DialogformAlternativas)
