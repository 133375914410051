import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'

import {
  Typography,
  CssBaseline,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Fab,
  Icon,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core'

import { Actions as ConfigActions } from '../../../../store/ducks/config'
import GestorHeaderComp from '../../../../components/Gestor/Header'
import GestorDrawerComp from '../../../../components/Gestor/Drawer'

import useStyles from './styles'

const TableData = (props) => {
  const { rows } = props
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>UM</TableCell>
            <TableCell>PESO</TableCell>
            <TableCell>DESCRIÇÃO</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.produtoSku}>
              <TableCell component="th" scope="row">
                {row.produtoId}
              </TableCell>
              <TableCell>{row.produtoUm}</TableCell>
              <TableCell>{row.produtoPeso}</TableCell>
              <TableCell>{`${row.produtoSku} - ${row.produtoDesc}`}</TableCell>
              <TableCell>
                <IconButton aria-label="Editar" className={classes.margin}>
                  <Icon>create</Icon>
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton aria-label="Deletar" className={classes.margin}>
                  <Icon>delete</Icon>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

TableData.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

const GestorUsuariosMeusDadosPage = (props) => {
  const { usuario } = props
  useEffect(() => {
    document.title = 'Online - Gestor | Meus dados'
    document.body.style = 'background-image: none;'
  }, [])
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={4} md={4}>
                <Typography variant="h3" component="h2">
                  Meus dados
                </Typography>
              </Grid>
              <Grid item xs={8} md={8}>
                <Grid container direction="row" justifyContent="flex-end">
                  <TextField
                    id="outlined-adornment-password"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    // type={values.showPassword ? 'text' : 'password'}
                    label="Pesquisar"
                    // value={values.password}
                    // onChange={handleChange('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Pesquisar"
                            // onClick={handleClickShowPassword}
                          >
                            <Icon>search</Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Fab disabled color="primary" aria-label="Add" className={classes.fab}>
                    <Icon>add</Icon>
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <TableData
              rows={[
                {
                  produtoId: 1,
                  produtoSku: 'CE5',
                  produtoUm: 'SC',
                  produtoPeso: 0,
                  produtoDesc: 'CIMENTO CPV ARI RS NBR 5733, NBR5737 - 4',
                },
                {
                  produtoId: 2,
                  produtoSku: 'CE240',
                  produtoUm: 'SC',
                  produtoPeso: 0,
                  produtoDesc: 'CIMENTO CPII - E 40 RS - NBR 16697 - 50 KG - CF25232910',
                },
                {
                  produtoId: 2,
                  produtoSku: 'CE340',
                  produtoUm: 'SC',
                  produtoPeso: 0,
                  produtoDesc: 'CIMENTO CPIII - 40 RS - NBR 16697 - 50 KG -CF25232910',
                },
              ]}
            />
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

GestorUsuariosMeusDadosPage.propTypes = {
  usuario: PropTypes.shape().isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...ConfigActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GestorUsuariosMeusDadosPage)
