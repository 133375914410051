import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'

const DashboardDialogFilter = (props) => {
  const {
    dataFilter,
    onClose,
    onFilter,
    // usuario: {
    usuario: { unidades = [] },
    // },
  } = props
  const [filiaisOpen, setFiliaisOpen] = useState(false)
  const [formFilter, setFormFilter] = useState(dataFilter || {})
  const handleFiltrar = () => {
    const newFormFilter = { ...formFilter, filialId: formFilter?.filialId?.filter(f => f !== 'ALL') ?? [] }
    onFilter(newFormFilter)
  }
  const handleChangeDate = (evt) => {
    const value = evt?.target?.value ?? ''
    if (value) {
      const field = evt.target.name
      setFormFilter(s => ({ ...s, [field]: value }))
    }
  }
  const handleChangeFiliais = (evt) => {
    const value = evt?.target?.value ?? ''
    setFormFilter(s => ({ ...s, filialId: value }))
    const filterAll = i => i === 'ALL'
    const filterNotAll = i => i !== 'ALL'
    if (value.findIndex(filterAll) > 0) {
      setFormFilter(s => ({ ...s, filialId: value.filter(filterAll) }))
      setFiliaisOpen(false)
    }
    if (value.findIndex(filterAll) === 0 && value.length > 1) {
      setFormFilter(s => ({ ...s, filialId: value.filter(filterNotAll) }))
    }
    if (value.findIndex(filterAll) === 0 && value.length === 1) {
      setFiliaisOpen(false)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="de"
          name="de"
          label="Data inicial"
          type="date"
          value={formFilter.de}
          onChange={handleChangeDate}
          inputProps={{
            min: '2021-06-03',
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="ate"
          name="ate"
          label="Data final"
          type="date"
          value={formFilter.ate}
          onChange={handleChangeDate}
          inputProps={{
            min: formFilter.de,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="dashboard-filtro-lista-filiais">Filial</InputLabel>
          <Select
            multiple
            open={filiaisOpen}
            onClose={() => setFiliaisOpen(false)}
            onOpen={() => setFiliaisOpen(true)}
            id="dashboard-filtro-lista-filiais"
            labelId="dashboard-filtro-lista-filiais"
            value={formFilter?.filialId ?? []}
            onChange={handleChangeFiliais}
          >
            <MenuItem value="ALL">TODAS</MenuItem>
            {unidades.map(f => (
              <MenuItem key={f.id} value={f.id}>{f.descricao}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button color="secondary" onClick={onClose}>CANCELAR</Button>
          </Grid>
          <Grid item>
            <Button onClick={handleFiltrar}>FILTRAR</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

DashboardDialogFilter.propTypes = {
  dataFilter: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  usuario: PropTypes.shape().isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDialogFilter)
