import { API_URL } from '..'
import { httpParseJson, httpValidState, httpValidErros } from '../../utils/http'

const ls = '@smr-portal-carrinho'
const ref = '/pedidos'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/*--------------
  LocalStorage
--------------*/
export const saveCarrinhoLs = carrinho => localStorage.setItem(ls, btoa(JSON.stringify(carrinho)))
export const getCarrinhoLs = () => {
  const carrinho = localStorage.getItem(ls)
  return JSON.parse(carrinho === null ? null : atob(carrinho))
}
export const delCarrinhoLs = () => localStorage.removeItem(ls)

/*--------------
  Http Request
--------------*/
export const searchPedidosApi = (dto) => {
  const {
    token, search, page = 0, rows = 50,
  } = dto
  return fetch(`${API_URL}${ref}/filtro?page=${page}&rows=${rows}&search=${search}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(httpValidState)
    .catch(httpValidErros)
}

export const sendPedidoApi = dto => fetch(`${API_URL}${ref}`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  },
  body: JSON.stringify(dto),
})
  .then(res => res.json())
  .then(res => httpValidState(res, true))
  .catch(err => httpValidErros(err, true))

export const cancelarPedidosApi = ({ token, pedidoId }) => fetch(`${API_URL}${ref}/cancelar/${pedidoId}`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const filtroGridPedidosApi = dto => fetch(`${API_URL}${ref}/filtro/grid`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  },
  body: JSON.stringify(dto),
})
  .then(res => res.json())
  .then(httpValidState)
  .catch(httpValidErros)

export const getPedidosApi = dto => fetch(`${API_URL}${ref}/${dto.id}`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  },
})
  .then(res => res.json())
  .then(res => httpValidState(res, true))
  .catch(httpValidErros)

export const getPedidosByRefApi = (dto) => {
  const {
    token, pedidoRef, codigoEmpresa, codigoFilial,
  } = dto
  return fetch(`${API_URL}${ref}/ref/${pedidoRef}?codigoEmpresa=${codigoEmpresa}&codigoFilial=${codigoFilial}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(res => httpValidState(res, true))
    .catch(httpValidErros)
}

export const getUltimosPedidosCount = ({
  token, dias, unidadeId, usuarioId, clienteId,
}) => fetch(`${API_URL}${ref}/status/soma/utimos/${dias}`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify({ unidadeId, usuarioId, clienteId }),
})
  .then(res => res.json())
  .then(httpValidState)
  .catch(httpValidErros)

export const getPedidosStatus = ({
  token, unidadeId, usuarioId, clienteId,
}) => fetch(`${API_URL}${ref}/status/soma`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify({ unidadeId, usuarioId, clienteId }),
})
  .then(res => res.json())
  .then(httpValidState)
  .catch(httpValidErros)

/* --------------
  Pedido padrão
---------------- */
export const getPedidoPadraoApi = ({ token, id }) => fetch(`${API_URL}${ref}/padrao/${id}`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const getPedidoPadraoCarrinhoApi = ({ token, id, filial }) => fetch(`${API_URL}${ref}/padrao/${id}/carrinho?filial=${filial}`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const listPedidoPadraoApi = (dto) => {
  const {
    token,
    filter: { cliente = 0, search = '', oportunidadeId = 0 },
  } = dto;

  let query = '?1=1';
  query += `&cliente=${cliente}`;
  query += search.trim() ? `&search=${search}` : '';
  query += oportunidadeId !== 0 ? `&oportunidadeId=${oportunidadeId}` : '';

  return fetch(`${API_URL}${ref}/padrao${query}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then((res) => httpValidState(res, true))
    .catch(httpValidErros);
};

export const sendPedidoPadraoApi = dto => fetch(`${API_URL}${ref}/padrao`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(res => httpValidState(res, true))
  .catch(httpValidErros)

export const deletePedidoPadraoApi = ({ token, id }) => fetch(`${API_URL}${ref}/padrao/${id}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const printPedidoApi = (dto) => {
  const { token, pedidoId } = dto
  return fetch(`${API_URL}${ref}/${pedidoId}/impressao`, {
    method: 'GET',
    headers: {
      ...headers,
      'Content-Type': 'application/pdf',
      Authorization: `Bearer ${token}`,
    },
  }).catch(httpValidErros)
}

export const getTrackingdanfe = pChaveNfe => fetch('https://ssw.inf.br/api/trackingdanfe', {
  method: 'POST',
  headers: {
    ...headers,
    // Authorization: `Bearer ${dto.token}`,
  },
  body: JSON.stringify({ chave_nfe: pChaveNfe }),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)


export const sendPedidoErpApi = (dto) => {
  const url = `${API_URL}${ref}/erp/add/${dto.pedidoId}`;
  const headers = {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  };

  return fetch(url, {
    method: 'POST',
    headers: headers,
  })
    .then((res) => res.json())
    .then((res) => httpValidState(res, true))
    .catch((err) => httpValidErros(err, true));
};

export const sendMultiplosPedidosErpApi = (dto) => {
  const url = `${API_URL}${ref}/erp/add-multiplos`; // Ajuste a URL conforme necessário
  const headers = {
      ...headers,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${dto.token}`,
  };

  return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(dto.pedidoIds), // Enviando a lista de IDs no corpo da requisição
  })
      .then((res) => res.json())
      .then((res) => httpValidState(res, true))
      .catch((err) => httpValidErros(err, true));
};