// State Notificacoes
const initialState = {
  alertaInicial: false,
  list: [
    {
      id: 0,
      titulo: 'Titulos em aberto',
      mensagem: 'Foi identificado 30 titulos em aberto em nosso sistema.',
      read: false,
    },
  ],
}

// Actions Types
export const Types = {
  SHOW_ALERTA_INICIAL: 'notificacao/SHOW_ALERTA_INICIAL',
  LOAD_NOTIFICACAO: 'notificacao/LOAD_NOTIFICACAO',
}

// Reducers
export default function config(state = initialState, action) {
  switch (action.type) {
    case Types.SHOW_ALERTA_INICIAL:
      return { ...state, alertaInicial: action.payload }
    case Types.LOAD_NOTIFICACAO:
      return { ...state, notificacoes: action.payload }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  onShowAlertaInicial: payload => (dispatch) => {
    dispatch({ type: Types.SHOW_ALERTA_INICIAL, payload })
  },
  onLoadNotificacao: payload => (dispatch) => {
    dispatch({ type: Types.LOAD_NOTIFICACAO, payload })
  },
  onAddNotificacao: payload => (dispatch, getState) => {
    const {
      notificacoes: { list },
    } = getState()
    dispatch(Actions.onLoadNotificacao([...list, payload]))
  },
}
