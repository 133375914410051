import React, { useState, useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import PropTypes from 'prop-types'
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core'
import _ from 'lodash'

import { editClienteEnderecoApi } from 'api/cliente/endereco'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'

const DialogFormClienteEndereco = (props) => {
  const {
    token,
    open,
    payload,
    onSave,
    onClose,
  } = props

  const [isSavingForm, setSavingForm] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [form, setForm] = useState()

  const onUpdateField = (data) => {
    setForm(s => ({ ...s, ...data }))
  }
  const onCloseDialog = () => {
    if (_.isFunction(onClose)) {
      setOpen(false)
      onClose()
    }
  }
  const onSaveClienteEndereco = () => {
    if (form.id) {
      setSavingForm(true)
      editClienteEnderecoApi({ token, id: form.id, payload: form })
        .then((resp) => {
          const { status } = resp ?? {}
          if (status === 200) {
            NotificationManager.success(
              'Endereço editado com sucesso!',
              'Atenção',
              8000,
            )
            onCloseDialog()
            if (_.isFunction(onSave)) {
              onSave(form, () => setSavingForm(true), () => setSavingForm(false))
            }
          }
        }).finally(() => {
          setSavingForm(false)
          onCloseDialog()
          if (_.isFunction(onSave)) {
            onSave(form, () => setSavingForm(true), () => setSavingForm(false))
          }
        })
    }
  }

  // * Effects
  useEffect(() => {
    setForm(payload)
  }, [payload])
  useEffect(() => {
    setOpen(open)
  }, [open])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      onClose={onCloseDialog}
      aria-labelledby="dialog-cliente-endereco"
    >
      <DialogTitle id="dialog-cliente-endereco-title">Endereço</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row">
          {/* Logradouro */}
          <Grid item xs={12} md={9}>
            <TextField
              fullWidth
              disabled={form?.disabled ?? false}
              id="logradouro"
              label="Logradouro"
              variant="outlined"
              value={form?.logradouro ?? ''}
              onChange={e => onUpdateField({ logradouro: e?.target?.value?.toUpperCase() })}
            />
          </Grid>
          {/* Número */}
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              disabled={form?.disabled ?? false}
              id="numero"
              label="Número"
              variant="outlined"
              value={form?.numero ?? ''}
              onChange={e => onUpdateField({ numero: e?.target?.value?.toUpperCase() })}
            />
          </Grid>
          {/* Complemento */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled={form?.disabled ?? false}
              id="complemento"
              label="Complemento"
              variant="outlined"
              value={form?.complemento ?? ''}
              onChange={e => onUpdateField({ complemento: e?.target?.value?.toUpperCase() })}
            />
          </Grid>
          {/* Bairro */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled={form?.disabled ?? false}
              id="bairro"
              label="Bairro"
              variant="outlined"
              value={form?.bairro ?? ''}
              onChange={e => onUpdateField({ bairro: e?.target?.value?.toUpperCase() })}
            />
          </Grid>
          {/* Cidade */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled={form?.disabled ?? false}
              id="cidade"
              label="Cidade"
              variant="outlined"
              value={form?.cidade ?? ''}
              onChange={e => onUpdateField({ cidade: e?.target?.value?.toUpperCase() })}
            />
          </Grid>
          {/* UF */}
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              disabled={form?.disabled ?? false}
              id="uf"
              label="UF"
              variant="outlined"
              value={form?.uf ?? ''}
              onChange={e => onUpdateField({ uf: e?.target?.value?.toUpperCase() })}
            />
          </Grid>
          {/* CEP */}
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              disabled={form?.disabled ?? false}
              id="cep"
              label="CEP"
              variant="outlined"
              value={form?.cep ?? ''}
              onChange={e => onUpdateField({ cep: e?.target?.value?.toUpperCase() })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog} color="secondary">
          Cancelar
        </Button>
        <ButtonLoadingComp loading={isSavingForm} onClick={onSaveClienteEndereco} color="primary" autoFocus>
          Salvar
        </ButtonLoadingComp>
      </DialogActions>
    </Dialog>
  )
}

DialogFormClienteEndereco.propTypes = {
  // loading: PropTypes.bool,
  token: PropTypes.string.isRequired,
  open: PropTypes.bool,
  payload: PropTypes.shape().isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

DialogFormClienteEndereco.defaultProps = {
  // loading: false,
  open: false,
}

export default DialogFormClienteEndereco
