import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
    Typography,
    Grid,
    TextField,
    CssBaseline,
    Icon,
    InputAdornment,
    Checkbox,
    CardContent,
    Tabs,
    Tab,
    NativeSelect,
    FormControl,
    FormControlLabel,
    TableCell,
    TableRow,
    TableBody,
    Table,
    TableHead,
    makeStyles,
    Badge,
    CircularProgress,
} from '@material-ui/core'

import NotificationsIcon from '@material-ui/icons/Notifications';


import { IconButton } from '@material-ui/core';


import {
    Button,
} from "@material-ui/core";


// import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import GestorHeaderComp from 'components/Gestor/Header'
import GestorDrawerComp from 'components/Gestor/Drawer'
import TableDataComp from 'components/Table'
import useStyles from './styles'
import { Autocomplete, Pagination } from '@material-ui/lab'
import { estadoAllApi, estadoApi } from 'api/localizacao/estado'
import { filterUnidadeConsumidoraApi, vincularAgenteUnidadeCApi } from 'api/unidade_consumidora'
import { filterCrmAgentesApi } from 'api/crm/agentes'
import { listSubBairrosByBairroId, vincularAgenteSubbairroApi } from 'api/localizacao/sub_bairro'
import { NotificationManager } from 'react-notifications'
import { listMunicipiosByEstadoIdApi } from 'api/localizacao/municipio'
import { listBairrosByMunicipioIdApi } from 'api/localizacao/bairro'
import { set } from 'lodash'

const GestorLeadListPage = (props) => {
    const {
        usuario, onDialogOpen, onDialogClose
    } = props
    const classes = useStyles()
    // State
    const [listFilter, setListFilter] = useState([])
    const [filter, setFilter] = useState({
        rows: 100,
        search: '',
    })

    const [selectedRows, setSelectedRows] = useState([])
    const [selectAll, setSelectAll] = useState(false)

    const handleSelectAllClick = () => {
        if (selectAll) {
            setSelectedRows([]);
            setSelectAll(false);
        } else {
            setSelectedRows(payload.data.map((row) => row.id));
            setSelectAll(true);
        }
    };

    const handleRowClick = (id) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
            setSelectAll(false);
        } else {
            setSelectedRows([...selectedRows, id]);
            if (selectedRows.length === payload.length - 1) {
                setSelectAll(true);
            }
        }
    };
    const [payload, setPayload] = useState([])

    const onIptSearchChange = (evt) => {
        evt.persist();
        setFilter(s => ({ ...s, search: evt?.target?.value?.toUpperCase() ?? '' }))
    }

    const OnLoadUnidadeConsumidora = () => {
        filterUnidadeConsumidoraApi({ token: usuario?.auth?.token ?? '', query: {} }).then((resp) => {
            setPayload(resp);
        });
    };

    const OnloadAgentes = () => {
        filterCrmAgentesApi({ token: usuario?.auth?.token ?? '', query: { agenteTypeChar: "S" } }).then((resp) => {
            if (resp) setListAgente(resp)
        })
    };

    useEffect(() => {
        document.title = 'Online - Leads'
        document.body.style = 'background-image: none;'
        loadEstados()
        OnloadAgentes()
        OnLoadUnidadeConsumidora()
    }, [])

    // Agente
    const [listAgente, setListAgente] = useState({});
    // Search State
    const [iptSearch, setIptSearch] = useState('')

    //Localization Set
    // Função para carregar todos os estados
    const loadEstados = () => {
        // Chamar a função estadoAllApi para carregar os estados
        estadoAllApi(usuario?.auth?.token ?? '')
            .then((response) => {
                // Definir os estados carregados no estado local
                setEstados(response);
            })
            .catch((error) => {
                console.error('Erro ao carregar os estados:', error);
            });
    };

    // Função para carregar municípios com base no estado selecionado
    const loadMunicipios = (stateId) => {
        // Chamar a função listMunicipiosByEstadoIdApi passando o estadoId
        listMunicipiosByEstadoIdApi(usuario?.auth?.token ?? '', stateId)
            .then((response) => {
                // Definir os municípios carregados no estado local
                setMunicipios(response);
            })
            .catch((error) => {
                console.error('Erro ao carregar os municípios:', error);
            });
    };

    // Função para carregar bairros com base no município selecionado
    const loadBairros = (municipioId) => {
        // Chamar a função listBairrosByMunicipioIdApi passando o municipioId
        listBairrosByMunicipioIdApi(usuario?.auth?.token ?? '', municipioId)
            .then((response) => {
                // Definir os bairros carregados no estado local
                setBairros(response);
            })
            .catch((error) => {
                console.error('Erro ao carregar os bairros:', error);
            });
    };

    // Função para carregar subbairros com base no bairro selecionado
    const loadSubBairros = (bairroId) => {
        // Chamar a função listSubBairrosByBairroId passando o bairroId
        listSubBairrosByBairroId(usuario?.auth?.token ?? '', bairroId)
            .then((response) => {
                // Definir os subbairros carregados no estado local
                setSubBairros(response);
            })
            .catch((error) => {
                console.error('Erro ao carregar os subbairros:', error);
            });
    };

    // Estado local para armazenar os estados carregados

    const [estados, setEstados] = useState([]);
    // Estado local para armazenar os municípios carregados
    const [municipios, setMunicipios] = useState([]);

    // Estado local para armazenar os bairros carregados
    const [bairros, setBairros] = useState([]);

    // Estado local para armazenar os subbairros carregados
    const [subBairros, setSubBairros] = useState([]);

    // Estado local para armazenar a seleção do estado
    const [selectedState, setSelectedState] = useState(null);

    // Estado local para armazenar a seleção do município
    const [selectedMunicipality, setSelectedMunicipality] = useState(null);

    // Estado local para armazenar a seleção do bairro
    const [selectedBairro, setSelectedBairro] = useState(null);

    // Estado local para armazenar a seleção do bairro
    const [selectedSubBairro, setSelectedSubBairro] = useState(null);

    // Função para manipular a seleção do estado
    const handleStateChange = async (event, value) => {

        setSelectedMunicipality(null);
        setSelectedBairro(null);

        if (value?.id) {
            // Carregar os municípios com base no estado selecionado
            setSelectedState(value);
            await loadMunicipios(value.id);
        } else {
            setSelectedState(null)
            setMunicipios([]);
            setBairros([]);
            setSubBairros([]);
        }
    };

    // Função para manipular a seleção do município
    const handleMunicipalityChange = (event, value) => {
        setSelectedMunicipality(value);
        setSelectedBairro(null);

        if (value) {
            // Carregar os bairros com base no município selecionado
            loadBairros(value.id);
        } else {
            setSelectedMunicipality(null)
            setBairros([]);
            setSubBairros([]);
        }
    };

    // Função para manipular a seleção do bairro
    const handleBairroChange = (event, value) => {
        setSelectedBairro(value);

        if (value) {
            // Carregar os subbairros com base no bairro selecionado
            loadSubBairros(value.id);
        } else {
            setSelectedBairro(null)
            setSubBairros([]);
        }
    };
    // Função para manipular a seleção do subbairro
    const handleSubBairroChange = (event, value) => {
        setSelectedSubBairro(value);
    };
    // Loading
    const [loadingEstado, setLoadingEstado] = useState(false);
    const [loadingMunicipios, setLoadingMunicipios] = useState(false);
    const [loadingBairros, setLoadingBairros] = useState(false);
    const [loadingSubBairros, setLoadingSubBairros] = useState(false);


    // Set
    const handleFilterClick = (event) => {
        filterUnidadeConsumidoraApi({ 
            token: usuario?.auth?.token ?? '', 
            query: { 
                page: 0,
                rows: 10,
                search: iptSearch ?? '',
                estado: selectedState?.id ?? '', 
                municipio: selectedMunicipality?.id ?? '', 
                bairro: selectedBairro?.id ?? '', 
                subbairro: selectedSubBairro?.id ?? '' 
            } 
        }).then((resp) => {
            setPayload(resp);
        });
    };
    const onSearchUnidadeConsumidora = (page = 1) => {
        setLoading(true);
        if (page == 1 && currentPage != page) {
            setCurrentPage(page);
            setLoading(false);
        } else {
            filterUnidadeConsumidoraApi({
                token: usuario?.auth?.token ?? '',
                query: {
                    page: page - 1,
                    rows: 10,
                    search: iptSearch ?? '',
                    estado: selectedState?.id ?? '', 
                    municipio: selectedMunicipality?.id ?? '', 
                    bairro: selectedBairro?.id ?? '', 
                    subbairro: selectedSubBairro?.id ?? '' 
                }
            }).then((resp) => {
                setPayload(resp);
                setLoading(false);
            });
        }
    };
    const [currentPage, setCurrentPage] = useState(1)

    const handlePageChange = (event, newPage) => {
        const page = newPage;
        setFilter(prevFilter => ({ ...prevFilter, page }));
        setCurrentPage(newPage);
    };
    useEffect(() => {
        onSearchUnidadeConsumidora(currentPage);
    }, [currentPage]);
    const [loading, setLoading] = useState(false);

    

    // End Filter

    // Vincular Agente

    const vincularAgente = pPayload => {
        if (selectedRows.length > 0) {
            onDialogOpen({
                size: "sm",
                title: "Vínculo de Agente",
                content: (
                    <FormControl id="forms-vincular">
                        <p>Escolha o Agente:</p>
                        <NativeSelect
                            id="vincular-select-agente"
                            label="Agente"
                        >
                            {listAgente.data.map(response => (
                                <option value={response.id}>{response.name}</option>
                            ))}
                        </NativeSelect>
                        <br></br>
                        <p>Confirma vínculo do agente com as localidades selecionadas ?</p>
                    </FormControl>
                ),
                actions: [
                    {
                        key: 1,
                        title: "Sim",
                        action: () => {
                            // Request

                            const promises = []

                            for (let index = 0; index < selectedRows.length; index++) {
                                const unidadeId = selectedRows[index];
                                const agenteId = document.getElementById("vincular-select-agente").value
                                promises.push(vincularAgenteUnidadeCApi({ token: usuario?.auth?.token ?? '', unidadeId: unidadeId, agenteId: agenteId }))
                            }

                            Promise.all(promises).then(() => {
                                onSearchUnidadeConsumidora()
                                onDialogClose();
                            }).catch(error => {
                                console.error(error)
                            })

                            // Update components
                        },
                        color: "secondary"
                    },
                    { key: 2, title: "Não", action: () => onDialogClose() }
                ]
            });
        } else {
            NotificationManager.warning('Nenhuma Unidade Consumidora selecionada');
        }
    };
    // Style
    const stylesForm = {
        marginTop: '2rem',
        marginBottom: '1rem',
        display: 'flex',
        gap: '1rem',
        width: '100%',
    };
    const style = makeStyles({
        root: {
            padding: "0 !important",
        },
    });
    return (
        <div className={classes.root}>
            <CssBaseline />
            <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
            <GestorDrawerComp {...props} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h3" component="h2">
                                    Distribuição de Leads
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Body */}
                </Grid>
                {/* Filter */}
                <Grid container >
                    <form style={stylesForm}>
                        <Grid item xs={6} md={3}>
                            {loadingEstado ? (
                                <CircularProgress /> // Indicador de carregamento com spinner
                            ) : (
                                estados && (
                                    <Autocomplete
                                        id="states-combo-box"
                                        options={estados}
                                        getOptionLabel={(option) => option.nome}
                                        value={selectedState}
                                        onChange={handleStateChange}
                                        renderInput={(params) => <TextField {...params} label="Estado" variant="outlined" />}
                                    />
                                )
                            )}
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Autocomplete
                                id="municipalities-combo-box"
                                options={municipios}
                                getOptionLabel={(option) => option.nome}
                                value={selectedMunicipality}
                                onChange={handleMunicipalityChange}
                                renderInput={(params) => <TextField {...params} label="Município" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Autocomplete
                                id="bairro-combo-box"
                                options={bairros}
                                getOptionLabel={(option) => option.nome}
                                value={selectedBairro}
                                onChange={handleBairroChange}
                                renderInput={(params) => <TextField {...params} label="Bairro" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Autocomplete
                                id="subbairro-combo-box"
                                options={subBairros}
                                getOptionLabel={(option) => option.nome}
                                value={selectedSubBairro}
                                onChange={handleSubBairroChange}
                                renderInput={(params) => <TextField {...params} label="Sub Bairro" variant="outlined" />}
                            />
                        </Grid>
                    </form>
                    <Grid item xs={6} md={8}>

                        <TextField
                            id="outlined-adornment-password"
                            className={classNames(classes.margin, classes.textField)}
                            style={{ width: '100%', marginBottom: '1rem' }}
                            variant="outlined"
                            label="Pesquisar"
                            value={iptSearch}
                            onChange={evt => setIptSearch(evt.target.value.toLocaleUpperCase())}
                            onKeyPress={(evt) => {
                                if (evt.charCode === 13) {
                                    onSearchUnidadeConsumidora()
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            aria-label="Pesquisar"
                                            onClick={() => onSearchUnidadeConsumidora()}
                                        >
                                            <Icon>search</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={4} style={{ display: 'grid', justifyContent: 'end', alignContent: 'center', gap: '10px', marginBottom: '10px' }}>
                        <Button variant="contained" color="primary" onClick={handleFilterClick} className={classes.button} >
                            Filtrar
                        </Button>
                        <Button variant="contained" color="primary" onClick={vincularAgente} className={classes.button} >
                            Vincular
                        </Button>
                    </Grid>
                </Grid>
                <IconButton>
                    <Badge badgeContent={selectedRows.length} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <Grid>
                    <Grid>
                        <Table>
                            <TableHead style={{ background: 'black' }}>
                                <TableRow>
                                    <TableCell classes={{ root: style.root }}>

                                        <Checkbox
                                            style={{ color: 'white' }}
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAllClick}
                                        >
                                        </Checkbox>
                                    </TableCell>
                                    <TableCell classes={{ root: style.root }} style={{ color: 'white' }}>UNIDADE</TableCell>
                                    <TableCell classes={{ root: style.root }} style={{ color: 'white' }}>TERMO</TableCell>
                                    <TableCell classes={{ root: style.root }} style={{ color: 'white' }}>MUNICIPIO</TableCell>
                                    <TableCell classes={{ root: style.root }} style={{ color: 'white' }}>BAIRRO</TableCell>
                                    <TableCell classes={{ root: style.root }} style={{ color: 'white' }}>SUBBAIRRO</TableCell>
                                    <TableCell classes={{ root: style.root }} style={{ color: 'white' }}>CEP</TableCell>
                                    <TableCell classes={{ root: style.root }} style={{ color: 'white' }}>AGENTE</TableCell>
                                    <TableCell classes={{ root: style.root }} style={{ color: 'white' }}>REFERÊNCIA</TableCell>
                                </TableRow>
                            </TableHead>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                payload.data && payload.data.length > 0 ? (
                                    payload.data.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell>
                                                <Checkbox checked={selectedRows.includes(row.id)} onChange={() => handleRowClick(row.id)} />
                                            </TableCell>
                                            <TableCell>{row.unidNumero}</TableCell>
                                            <TableCell>{row.unidTermo}</TableCell>
                                            <TableCell>{row.municipio}</TableCell>
                                            <TableCell>{row.bairro}</TableCell>
                                            <TableCell>{row.subBairro}</TableCell>
                                            <TableCell>{row.cep}</TableCell>
                                            <TableCell>{row.crmAgente}</TableCell>
                                            <TableCell>{row.endPontRef}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">Sem registros</TableCell>
                                    </TableRow>
                                )
                            )}
                        </Table>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div id="mui-pagination">
                            <Pagination
                                count={payload ? payload.total ?? 0 : 0}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                                className={classes.pagination}
                            />
                        </div>
                    </Grid>
                </Grid>
            </main>
        </div >
    )
}

GestorLeadListPage.propTypes = {
    usuario: PropTypes.shape().isRequired,
    push: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
    onDialogOpen: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        ...ConfigActions,
        push: crrPush,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GestorLeadListPage)
