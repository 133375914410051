export const getGeolocation = async () => {
  if (navigator.geolocation) {
    const { state = 'prompt' } = (await navigator?.permissions?.query({ name: 'geolocation' })) || {}
    if (state === 'granted' || state === 'prompt') {
      const position = await new Promise((resolve) => {
        const options = {
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 0,
        }
        if (state === 'prompt') {
          setTimeout(() => resolve({ coords: { latitude: 0, longitude: 0 } }), 16000)
        }
        navigator.geolocation.getCurrentPosition(
          resolve,
          () => resolve({ coords: { latitude: 0, longitude: 0 } }),
          options,
        )
      })
      const {
        coords: { latitude = 0, longitude = 0 },
      } = position || {}
      return { latitude, longitude }
    }
  }
  return { latitude: 0, longitude: 0 }
}
