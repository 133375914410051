import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
  Typography,
  Grid,
  Fab,
  Icon,
  Tooltip,
  TextField,
  InputAdornment,
  IconButton,
  CssBaseline,
  Badge,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import _ from 'lodash'

import { delCrmAgentesApi, filterCrmAgentesApi } from 'api/crm/agentes'

import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import { Actions as CrmContatosActions } from 'store/ducks/crm/contato'
import GestorHeaderComp from 'components/Gestor/Header'
import GestorDrawerComp from 'components/Gestor/Drawer'
import TableDataComp from 'components/Table'
import useStyles from './styles'
import { NotificationManager } from 'react-notifications'
import { Pagination } from '@material-ui/lab'
import { findAllCrmAgenteTipoApi } from 'api/crm/agente_tipo'
import DeleteAgenteModal from 'components/Dialogs/Agentes/delete'

const GestorCrmAgenteListPage = (props) => {
  const {
    usuario, push,
  } = props
  const classes = useStyles();
  // State
  const [listFilter, setListFilter] = useState([]);
  const [selectedAgentType, setSelectedAgentType] = useState("all");
  const [filter, setFilter] = useState({
    search: '',
    rows: 10,
  });

  const [payload, setPayload] = useState([]);
  const tableCols = [
    { key: 'code', title: 'CÓDIGO' },
    { key: 'name', title: 'NOME' },
    { key: 'usuarioEmail', title: 'EMAIL' },
    { key: '', title: 'USUÁRIO / STATUS', format: row => `${row.usuarioNome} / ${row.usuarioStatusDescricao}` },
    { key: 'usuarioCelular', title: 'CELULAR' },
    { key: '', title: 'TIPO', format: row => `${row.crmAgenteTipo.descricao} / ${row.crmAgenteTipo.tipo}` },
  ];

  const tableActionsList = [
    {
      key: 'edit',
      title: 'Editar',
      icon: 'edit',
      action: row => push(`agentes/detail/${row.id}`),
    },
    {
      key: 'deletar',
      title: 'Excluir',
      icon: 'delete',
      action: row => handleDeleteRequest(row),
    }
  ];

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);

  const handleDeleteRequest = (agente) => {
    setAgentToDelete(agente);
    setShowDeleteModal(true);
  };

  const onIptSearchChange = (evt) => {
    evt.persist();
    setFilter(s => ({ ...s, search: evt?.target?.value?.toUpperCase() ?? '' }))
  };

  const deleteAgenteById = (id) => {
    delCrmAgentesApi({ token: (usuario?.auth?.token || ''), id: id })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          NotificationManager.success('O agente foi EXCLUÍDO com sucesso!', 'Sucesso!', 4000);
          onSearchAgentes();
        } else {
          return res.json().then(err => {
            NotificationManager.error(err.message || 'Houve um erro ao tentar EXCLUIR o agente!', 'Erro!', 4000);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSearchAgentes = (page = 1) => {
    if (page == 1 && currentPage != page) {
      setCurrentPage(page);
    }
    else {
      filterCrmAgentesApi({ token: usuario?.auth?.token ?? '', query: { page: page - 1, ...filter } })
        .then((resp) => {
          setPayload(resp);
        });
    }
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const [agentTypes, setAgentTypes] = useState([]);

  useEffect(() => {
    const fetchAgentTypes = async () => {
      try {
        const data = await findAllCrmAgenteTipoApi({ token: usuario?.auth?.token ?? '' });
        setAgentTypes(data);
      } catch (error) {
        console.error("Erro ao buscar tipos de agentes:", error);
      }
    };

    fetchAgentTypes();
  }, [usuario?.auth?.token]);

  useEffect(() => {
    document.title = 'Online - Agentes';
    document.body.style = 'background-image: none;';
    onSearchAgentes();
  }, []);

  useEffect(() => {
    setFilter(prevFilter => {
      let newFilter = { ...prevFilter };

      if (selectedAgentType !== 'all') {
        newFilter.agenteTypeChar = selectedAgentType;
      } else {
        delete newFilter.agenteTypeChar;
      }

      return newFilter;
    });
  }, [selectedAgentType]);

  useEffect(() => {
    onSearchAgentes();
  }, [filter]);

  useEffect(() => {
    onSearchAgentes(currentPage);
  }, [currentPage]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" component="h2">
                  Agentes
                </Typography>
              </Grid>
              {/* Menus */}
              <Grid item xs={12} md={6}>
                <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <FormControl variant="outlined" className={classes.formControl} style={{ width: '200px' }}>
                      <InputLabel
                        id="agent-type-label"
                        style={{
                          background: 'white',
                          padding: '0 4px',
                        }}
                        shrink
                      >
                        Tipo de Agente
                      </InputLabel>
                      <Select
                        labelId="agent-type-label"
                        value={selectedAgentType}
                        onChange={e => setSelectedAgentType(e.target.value)}
                      >
                        <MenuItem key={"all"} value={"all"}>TODOS</MenuItem>
                        {agentTypes?.map(type => (
                          <MenuItem key={type.id} value={type.tipo}>{type.descricao}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <TextField
                      id="outlined-adornment-password"
                      className={classNames(classes.margin, classes.textField)}
                      variant="outlined"
                      label="Pesquisar"
                      value={filter?.search ?? ''}
                      onChange={onIptSearchChange}
                      onKeyPress={(evt) => {
                        if (evt.charCode === 13) {
                          onSearchAgentes()
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Pesquisar"
                              onClick={onSearchAgentes}
                            >
                              <Icon>search</Icon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title="Adicionar">
                      <Fab
                        color="primary"
                        aria-label="Add"
                        className={classes.fab}
                        onClick={() => push('/gestor/crm/agentes/add')}
                      >
                        <Icon>add</Icon>
                      </Fab>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Body */}
          <Grid item xs={12} md={12}>
            <TableDataComp
              size="small"
              loading={false}
              rows={payload.data}
              cols={tableCols}
              actions={tableActionsList}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <div id="mui-pagination">
              <Pagination
                count={payload?.total ?? 0}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                className={classes.pagination}
              />
            </div>
          </Grid>
          <DeleteAgenteModal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            agente={agentToDelete}
            usuario={usuario}
            onSearchAgentes={onSearchAgentes}
          />
        </Grid>
      </main>
    </div >
  )
}

GestorCrmAgenteListPage.propTypes = {
  usuario: PropTypes.shape().isRequired,
  crmContatos: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
  onFilterCrmContatos: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  crmContatos: state.crmContatos,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...CrmContatosActions,
    push: crrPush,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GestorCrmAgenteListPage)
