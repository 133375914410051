import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Paper, Grid, CircularProgress,
} from '@material-ui/core'

import TableDataComp from 'components/Table'
import DialogFormClienteEndereco from '../Dialogs/DialogFormClienteEndereco'

import useStyles from './styles'

const DadosProspectEnderecoTabContent = (props) => {
  const {
    token,
    loading,
    payload,
    onSave,
    // onUpdateField,
  } = props
  const classes = useStyles()

  // * State's
  const [dialogEndereco, setDialogEndereco] = useState(false)
  const [payloadEndereco, setPayloadEndereco] = useState()

  const formEndereco = (row) => {
    setPayloadEndereco(row)
    setDialogEndereco(true)
  }

  const tableAtendimentosCols = [
    { key: 'logradouro', title: 'Logradouro' },
    { key: 'numero', title: 'Número' },
    { key: 'complemento', title: 'Complemento' },
    { key: 'bairro', title: 'Bairro' },
    { key: 'cidade', title: 'Cidade' },
    { key: 'uf', title: 'UF' },
    { key: 'cep', title: 'CEP' },
    { key: 'unidNumero', title: 'UC NÚMERO' }
  ]

  const tableActionsList = [
    {
      key: 'edit',
      title: 'Editar',
      icon: 'edit',
      action: formEndereco,
    },
  ]

  return loading ? (
    <CircularProgress />
  ) : (
    <Paper className={classes.paper} elevation={1}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={12}>
          <TableDataComp
            size="small"
            loading={false}
            rows={payload ?? []}
            cols={tableAtendimentosCols}
            actions={tableActionsList}
          />
        </Grid>
      </Grid>
      <DialogFormClienteEndereco
        token={token ?? ''}
        open={dialogEndereco}
        payload={payloadEndereco}
        onClose={() => setDialogEndereco(false)}
        onSave={onSave}
      />
    </Paper>
  )
}

DadosProspectEnderecoTabContent.propTypes = {
  loading: PropTypes.bool,
  token: PropTypes.string.isRequired,
  payload: PropTypes.shape().isRequired,
  onSave: PropTypes.func.isRequired,
}

DadosProspectEnderecoTabContent.defaultProps = {
  loading: false,
}

export default DadosProspectEnderecoTabContent
