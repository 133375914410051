import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
    Typography,
    Card,
    Grid,
    Fab,
    Icon,
    Tooltip,
    TextField,
    InputAdornment,
    IconButton,
    CssBaseline,
    Input,
    Button,
    CircularProgress,
} from '@material-ui/core'

// import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import GestorHeaderComp from '../../../../../../components/Gestor/Drawer'
import GestorDrawerComp from '../../../../../../components/Gestor/Drawer'
import TableDataComp from '../../../../../../components/Table'
import useStyles from './styles'
import { getVisitasFilterApi } from 'api/visitas'
import { DialogContentSimples } from 'components/Dialogs/Contents'
import ReactPaginate from 'react-paginate'
import { searchClienteApi } from 'api/cliente'
import { Carousel } from 'react-responsive-carousel'
import { findByIdEntregaApi } from 'api/entrega'

const EntregaFotosTabContent = (props) => {
    const {
        usuario,
        push,
        params,
    } = props;

    const [payload, setPayload] = useState([]);
    const [entrega, setEntrega] = useState([]);
    const [loading, setLoading] = useState(false);

    const classes = useStyles();

    const fetchDataById = async (token, id) => {
        setLoading(true);
        try {
            const response = await findByIdEntregaApi({ token: token, id: id });
            setEntrega(response);
            setLoading(false);
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar os dados por ID:', error);
            setLoading(false);
            return null;
        }
    };

    function createImageUrl(base64Image) {
        const byteCharacters = atob(base64Image);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: 'image/jpeg' });
        return URL.createObjectURL(blob);
    }

    useEffect(() => {
        document.title = 'Online - Entrega Detalhe';
        document.body.style = 'background-image: none;';
        fetchDataById(usuario?.auth?.token, params.id);
    }, []);

    return (
        <div className={classes.root}>
            <CssBaseline />
            {loading ? (
                <div className={classes.loader}>
                    <CircularProgress />
                </div>
            ) : (
                <main className={classes.content}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Grid container>
                                {/* Menus */}
                                <Grid item xs={12} md={12}>
                                    {entrega && entrega.imagens && entrega.imagens.length > 0 ? (
                                        <Carousel>
                                            {entrega.imagens.map((imagem) => (
                                                <Grid item key={imagem.id}>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                    }}>
                                                        <Button
                                                            color="primary"
                                                            className={classes.button}
                                                            href={createImageUrl(imagem.imagem)}
                                                            download={`Imagem_${imagem.id}.jpg`}
                                                            style={{
                                                                textDecoration: 'none',
                                                                marginBottom: '10px',
                                                                justifyContent: 'left',
                                                                alignSelf: 'flex-start',
                                                            }}
                                                        >
                                                            Baixar
                                                        </Button>
                                                    </div>
                                                    <img
                                                        src={createImageUrl(imagem.imagem)}
                                                        alt={`Imagem ${imagem.id}`}
                                                        style={{
                                                            width: '100%',
                                                            height: 'auto',
                                                            maxHeight: '500px',
                                                            objectFit: 'contain',
                                                        }}
                                                    />
                                                </Grid>
                                            ))}
                                        </Carousel>
                                    ) : (
                                        <div className={classes.noPhotos}>
                                            <p>Nenhuma foto disponível</p>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Body */}
                    </Grid>
                </main>
            )}
        </div>
    );
};

EntregaFotosTabContent.propTypes = {
    usuario: PropTypes.shape().isRequired,
    push: PropTypes.func.isRequired,
    params: PropTypes.shape().isRequired,
    // Outras propTypes conforme necessário
};

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
    // Outros mapeamentos conforme necessário
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        ...ConfigActions,
        push: crrPush,
    },
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EntregaFotosTabContent);