import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { light: '#333333', main: '#000000', dark: '#000000' },
    secondary: { light: '#ff6333', main: '#ff3d00', dark: '#b22a00' },
    error: { light: '#ff4569', main: '#ff1744', dark: '#b2102f' },
  },
  semar: {
    text: { white: '#FFFFFF', green: '#2CB851', blue: '#3333ff' },
    success: { light: '#6fbf73', main: '#2CB851', dark: '#357a38' },
    error: { light: '#ff4569', main: '#ff1744', dark: '#b2102f' },
    grey: { light: '#D3D3D3', main: '#C0C0C0', dark: '#A9A9A9' },
  },
  defaults: {
    margin: 10,
    padding: 10,
  },
})
