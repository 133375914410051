import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& .super-app-theme--header': {
      backgroundColor: '#000000',
      color: '#FFFFFF',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  rowTable: {
    overflow: 'hidden',
    textOverflow: 'ellipse',
    maxHeight: 60,
  },
  fullHeight: {
    height: '100%',
  },
}))

export default styles
