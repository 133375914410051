import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import config from 'react-global-configuration'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Person from '@material-ui/icons/Person'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ReCAPTCHA from 'react-google-recaptcha'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Actions as UsuarioActions } from '../../store/ducks/usuario'

const styles = {
  content: {
    padding: 0,
    marginTop: 50,
  },
  card: {
    maxWidth: 345,
    maxHeight: 800,
  },
  media: {
    // ⚠️ object-fit is not supported by IE11.
    objectFit: 'cover',
  },
  margin: {
    marginTop: 20,
  },
  containerActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  center: {
    display: 'flex',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  btnContainerEntrar: {
    marginBottom: 15,
  },
  voltarContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  buttonProgress: {
    // color: '#FFF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}

class RecoveryPage extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    classes: PropTypes.shape().isRequired,
    usuario: PropTypes.shape().isRequired,
    dialogForgot: PropTypes.shape().isRequired,
    reCaptcha: PropTypes.string.isRequired,
    onReCaptcha: PropTypes.func.isRequired,
    onFieldUpdate: PropTypes.func.isRequired,
    onUserForgot: PropTypes.func.isRequired,
    onUpdateDialogForgot: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const configClienteSku = config.get('cliente.sku')
    const configClienteNome = config.get('cliente.nome')
    const bg = require(`../../assets/imagens/login/${configClienteSku}-login-bg-page.png`)
    document.title = `${configClienteNome} - Recuperação de senha`
    document.body.style = `background-image: url(${bg}); background-size:100% 100%; background-repeat:no-repeat; background-position: center;`
    document.documentElement.style = 'height: 100%'
  }

  onRecaptchaClick = (value) => {
    const { onReCaptcha } = this.props
    if (value) onReCaptcha(value)
  }

  render() {
    const {
      classes,
      loading,
      usuario,
      reCaptcha,
      dialogForgot,
      onFieldUpdate,
      onUserForgot,
      onUpdateDialogForgot,
    } = this.props
    const configDefaultAltLogoLeft = config.get('default.altLogoLeft')
    const configDefaultAltLogoRight = config.get('default.altLogoRight')
    const configDefaultLogoLeft = config.get('default.logoLeft')
    const configDefaultLogoRight = config.get('default.logoRight')
    const configDefaultLoginCardHeader = config.get('default.loginCardHeader')
    return (
      <Grid container>
        <Grid item xs={4}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            {configDefaultLogoLeft && <img alt={configDefaultAltLogoLeft} src={require(`../../assets/imagens/${configDefaultLogoLeft}`)} />}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <div className={[classes.content, classes.center].join(' ')}>
            <Card className={classes.card}>
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                className={classes.media}
                height="140"
                image={require(`../../assets/imagens/login/${configDefaultLoginCardHeader}`)}
                title="Contemplative Reptile"
              />
              <CardContent>
                <Grid container>
                  <Typography gutterBottom variant="h5" component="h2">
                    Recuperação
                  </Typography>
                  <Typography component="p">
                    Informe seu usuário para que possamos enviar o link de recuperação de senha.
                  </Typography>
                </Grid>
                <Grid container spacing={8} alignItems="flex-end">
                  <Grid item xs={2} md={2}>
                    <Person />
                  </Grid>
                  <Grid item xs={10} md={10}>
                    <TextField
                      label="Email"
                      fullWidth
                      value={usuario.email}
                      onChange={evt => onFieldUpdate({ email: evt.target.value })}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.margin}>
                  <ReCAPTCHA
                    sitekey="6LczQ0MnAAAAALGNg22IXaPEIs_otQgp12wDeAdx"
                    onChange={value => this.onRecaptchaClick(value)}
                  />
                </Grid>
              </CardContent>
              <CardActions className={classes.containerActions}>
                <Grid
                  container
                  className={classes.btnContainerEntrar}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    disabled={!reCaptcha || !usuario.email}
                    variant="contained"
                    color="primary"
                    onClick={onUserForgot}
                  >
                    {loading ? (
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    ) : (
                      'ENVIAR'
                    )}
                  </Button>
                </Grid>
                <Grid
                  container
                  className={classes.voltarContainer}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button component={Link} to="/login" className={classes.button}>
                    VOLTAR
                  </Button>
                </Grid>
              </CardActions>
            </Card>
            <Dialog
              maxWidth="xs"
              open={dialogForgot.open}
              onClose={() => onUpdateDialogForgot({ open: false })}
              aria-labelledby="forgot-dialog-title"
            >
              <DialogTitle id="forgot-dialog-title">{dialogForgot.title}</DialogTitle>
              <DialogContent>{dialogForgot.msg}</DialogContent>
              <DialogActions>
                <Button onClick={() => onUpdateDialogForgot({ open: false })} color="secondary">
                  Fechar
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Grid>
        <Grid item xs={4}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            {configDefaultLogoRight && <img alt={configDefaultAltLogoRight} src={require(`../../assets/imagens/${configDefaultLogoRight}`)} />}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  reCaptcha: state.usuario.reCaptcha,
  usuario: state.usuario.usuario,
  loading: state.usuario.loading,
  dialogForgot: state.usuario.dialogForgot,
})

const mapDispatchToProps = dispatch => bindActionCreators(UsuarioActions, dispatch)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RecoveryPage),
)
