import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import { NotificationManager } from 'react-notifications'
import Description from '@material-ui/icons/Description'
import Layers from '@material-ui/icons/Layers'
import DateRanger from '@material-ui/icons/DateRange'
import MoneyIcon from '@material-ui/icons/AttachMoney'
import ChevronRight from '@material-ui/icons/ChevronRight'
import SearchIcon from '@material-ui/icons/Search'

import Header from '../../components/Header'
import { gridBoleto } from './Boletos'
import { gridDanfeXML } from './DanfeXML'
import { formExtratoFinanceiro } from './ExtratoFinanceiro'
import { getExtratoFinanceiro } from '../../api/relatorios'
import { Actions as FinanceiroActions } from '../../store/ducks/financeiro'
import { Actions as ConfigActions } from '../../store/ducks/config'
import { EventosEnum } from '../../utils/enums/eventos'
import styles from './styles'

class FinanceiroPage extends Component {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    gerando: PropTypes.shape().isRequired,
    filtrando: PropTypes.bool.isRequired,
    // filtro: PropTypes.shape().isRequired,
    boletos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    danfes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    usuario: PropTypes.shape().isRequired,
    unidades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onGerando: PropTypes.func.isRequired,
    getParametro: PropTypes.func.isRequired,
    onLoadBoletos: PropTypes.func.isRequired,
    onDownloadBoleto: PropTypes.func.isRequired,
    onFiltroDanfesXML: PropTypes.func.isRequired,
    onDownloadDanfeXML: PropTypes.func.isRequired,
    onAddEvento: PropTypes.func.isRequired,
  }

  state = {
    filtroOpen: false,
    dialogs: {
      filtroBoletos: false,
      filtroNfe: false,
    },
    filtros: {
      boletos: {
        empresa: '',
        filial: '',
        cgc: '',
        dtini: '',
        dtfim: '',
        titulo: '',
        parcela: '',
        valor: 0,
        quitado: 'SIM',
      },
      nfe: {
        empresa: '',
        filial: '',
        cgc: '',
        dtini: '',
        dtfim: '',
        doc: '',
        serie: '',
        valbrut: 0,
      },
    },
    subPage: {
      id: 0,
      titulo: 'Boletos',
      descricao: 'Você pode emitir seus boletos por período.',
    },
    subPages: [
      {
        id: 0,
        titulo: 'Boletos',
        descricao: 'Você pode emitir seus boletos por período.',
      },
      {
        id: 1,
        titulo: 'Danfe/XML',
        descricao: 'Você pode fazer download de sua danfe ou xml, por período.',
      },
      {
        id: 2,
        titulo: 'Extrato financeiro',
        descricao: 'Você pode emitir um extrato por período.',
      },
    ],
    extratoFinanceiro: {
      dataInicial: '',
      dataFinal: '',
      cliente: '',
      unidade: '',
    },
  }

  componentDidMount() {
    // const { subPage, subPages } = this.state
    const { onGerando } = this.props
    document.title = 'Online - Financeiro'
    this.onLoadBoletosInitial()
    // this.onSelectSubPage(subPage)
    onGerando({})
  }

  componentDidUpdate(nextProps) {
    const {
      usuario: {
        unidade: { empresaCod, filialCod },
      },
    } = this.props
    if (
      nextProps.usuario.unidade.empresaCod !== empresaCod
      || nextProps.usuario.unidade.filialCod !== filialCod
    ) {
      const { subPage, subPages } = this.state
      this.onSelectSubPage(subPage || subPages.find(sp => sp.id === 0))
    }
  }

  onLoadBoletosInitial() {
    const {
      usuario: {
        unidade: { empresaCod, filialCod },
        cliente: { cgc },
      },
      onLoadBoletos,
      getParametro,
    } = this.props
    const dtInicial = moment()
      .subtract(getParametro('DIAS_BOLETO', 30), 'days')
      .format('YYYY-MM-DD')
    const dtFinal = moment()
      .add(getParametro('DIAS_BOLETO', 30), 'days')
      .format('YYYY-MM-DD')
    const dto = {
      empresa: empresaCod,
      filial: filialCod,
      cgc,
      dtini: dtInicial,
      dtfim: dtFinal,
      titulo: '',
      parcela: '',
      valor: '',
      quitado: 'SIM',
    }
    // Atualizando o STATE com a data inicial e final padrão!
    this.setState(s => ({
      ...s,
      filtros: {
        ...s.filtros,
        boletos: {
          ...s.filtros.boletos,
          ...dto,
        },
        nfe: {
          ...s.filtros.nfe,
          empresa: empresaCod,
          filial: filialCod,
          cgc,
          dtini: dtInicial,
          dtfim: dtFinal,
          doc: '',
          serie: '',
          valbrut: '',
        },
      },
    }))
    onLoadBoletos(dto)
  }

  onHandleFilterBoleto(f, v) {
    this.setState(s => ({
      ...s,
      filtros: {
        ...s.filtros,
        boletos: {
          ...s.filtros.boletos,
          [f]: v,
        },
      },
    }))
  }

  onHandleFilterNfe(f, v) {
    this.setState(s => ({
      ...s,
      filtros: {
        ...s.filtros,
        nfe: {
          ...s.filtros.nfe,
          [f]: v,
        },
      },
    }))
  }

  onSelectSubPage(subPage) {
    const {
      filtros: { boletos, nfe },
    } = this.state
    const {
      usuario: {
        unidade: { empresaCod, filialCod },
      },
      onLoadBoletos,
      onFiltroDanfesXML,
    } = this.props
    const empresaFilial = { empresa: empresaCod, filial: filialCod }
    switch (subPage.id) {
      case 0:
        onLoadBoletos({ ...boletos, ...empresaFilial })
        this.setState(s => ({
          ...s,
          subPage,
          filtros: {
            ...s.filtros,
            boletos: {
              ...s.filtros.boletos,
              ...empresaFilial,
            },
          },
        }))
        break
      case 1:
        onFiltroDanfesXML({ ...nfe, ...empresaFilial })
        this.setState(s => ({
          ...s,
          subPage,
          filtros: {
            ...s.filtros,
            nfe: {
              ...s.filtros.nfe,
              ...empresaFilial,
            },
          },
        }))
        break
      case 2:
        this.setState(s => ({ ...s, subPage }))
        break
      default:
    }
  }

  onOpenCloseDialog = (d) => {
    this.setState(s => ({ ...s, dialogs: { ...s.dialogs, [d]: !s.dialogs[d] } }))
  }

  onOpenCloseFiltro = () => {
    this.setState(state => ({ filtroOpen: !state.filtroOpen }))
  }

  onConfirmFiltro = () => {
    const {
      subPage,
      filtros: { boletos, nfe },
    } = this.state
    const { onLoadBoletos, onFiltroDanfesXML } = this.props
    switch (subPage.id) {
      case 0:
        onLoadBoletos(boletos)
        this.onOpenCloseDialog('filtroBoletos')
        break
      case 1:
        onFiltroDanfesXML(nfe)
        this.onOpenCloseDialog('filtroNfe')
        break
      case 2:
        console.log('Confirmou o extrato', subPage.titulo)
        break
      default:
    }
    // this.onOpenCloseFiltro()
  }

  onCancelFiltro = () => {
    const { subPage } = this.state
    const {
      usuario: {
        unidade: { empresaCod, filialCod },
        cliente: { cgc },
      },
      onLoadBoletos,
      onFiltroDanfesXML,
      getParametro,
    } = this.props
    const dtInicial = moment()
      .subtract(getParametro('DIAS_BOLETO', 30), 'days')
      .format('YYYY-MM-DD')
    const dtFinal = moment()
      .add(getParametro('DIAS_BOLETO', 30), 'days')
      .format('YYYY-MM-DD')
    switch (subPage.id) {
      case 0:
        onLoadBoletos({
          empresa: empresaCod,
          filial: filialCod,
          cgc,
          dtini: dtInicial,
          dtfim: dtFinal,
          titulo: '',
          parcela: '',
          valor: 0,
          quitado: 'SIM',
        })
        this.onOpenCloseDialog('filtroBoletos')
        break
      case 1:
        onFiltroDanfesXML({
          empresa: empresaCod,
          filial: filialCod,
          cgc,
          dtini: dtInicial,
          dtfim: dtFinal,
          doc: '',
          serie: '',
          valbrut: 0,
        })
        this.onOpenCloseDialog('filtroNfe')
        break
      case 2:
        console.log('Confirmou o extrato', subPage.titulo)
        break
      default:
    }
    // this.onOpenCloseFiltro()
  }

  onIptExtratoFinanceiro = (evt) => {
    const { extratoFinanceiro } = this.state
    this.setState({
      extratoFinanceiro: {
        ...extratoFinanceiro,
        [evt.target.name]: evt.target.value,
      },
    })
  }

  onFilterClickExtratoFinanceiro = () => {
    const {
      usuario, unidades, onGerando, onAddEvento,
    } = this.props
    const { extratoFinanceiro } = this.state
    const filial = unidades.find(f => f.id === extratoFinanceiro.unidade)
    const dto = {
      ...usuario.auth,
      user: '',
      emp: filial.empresaCod,
      fil: filial.filialCod,
      dataInicial: extratoFinanceiro.dataInicial,
      dataFinal: extratoFinanceiro.dataFinal,
      cnpj: usuario.cliente.cgc, // extratoFinanceiro.cliente,
    }
    onGerando({ tipo: 'extratofinanceiro' })
    getExtratoFinanceiro(dto).then(async (res) => {
      onGerando({})
      if (res && res.status === 200) {
        onAddEvento(EventosEnum.EXTRATO_FINANCEIRO_SUCESSO)
        const pdf = await res.blob()
        window.open(URL.createObjectURL(pdf), 'ExtratoFinanceiro')
        URL.revokeObjectURL(pdf)
      } else {
        const json = await res.json()
        NotificationManager.warning(json.message, 'Atenção:', 8000)
      }
    })
  }

  rootContent = () => {
    const { subPage, extratoFinanceiro } = this.state
    const {
      usuario,
      unidades,
      filtrando,
      gerando,
      boletos,
      danfes,
      onDownloadBoleto,
      onDownloadDanfeXML,
    } = this.props
    const { id = null } = subPage || {}
    switch (id) {
      case 2:
        return formExtratoFinanceiro({
          cliente: usuario.cliente, // extratoFinanceiro.cliente,
          clientes: usuario.clientes,
          unidade: extratoFinanceiro.unidade,
          unidades,
          dataInicial: extratoFinanceiro.dataInicial,
          dataFinal: extratoFinanceiro.dataFinal,
          gerando,
          onSelectCliente: this.onIptExtratoFinanceiro,
          onSelectUnidade: this.onIptExtratoFinanceiro,
          onDataChange: this.onIptExtratoFinanceiro,
          onFilterClick: this.onFilterClickExtratoFinanceiro,
        })
      case 1:
        return gridDanfeXML({
          load: filtrando,
          gerando,
          onDownloadDanfeXML,
          data: danfes || [],
        })
      case 0:
        return gridBoleto({
          load: filtrando,
          gerando,
          onDownloadBoleto,
          data: boletos || [],
        })
      default:
        return gridBoleto({
          load: filtrando,
          gerando,
          onDownloadBoleto,
          data: boletos || [],
        })
      // return this.financeiroRootContent()
    }
  }

  financeiroRootContent = () => {
    const { subPages } = this.state
    const { classes } = this.props
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
        spacing={2}
      >
        {subPages.map(sp => (
          <Grid key={sp.id} item>
            <Button onClick={() => this.onSelectSubPage(sp)}>
              <Paper
                style={{
                  height: 200,
                  width: 250,
                  padding: 10,
                }}
                elevation={1}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: 200, width: 250 }}
                >
                  <Typography variant="h5" component="h3">
                    {sp.titulo}
                  </Typography>
                  <Typography component="p">{sp.descricao}</Typography>
                </Grid>
              </Paper>
            </Button>
          </Grid>
        ))}
      </Grid>
    )
  }

  SubHeader = () => {
    const {
      subPage,
      filtros: { boletos, nfe },
    } = this.state
    if (subPage.id === 0 && boletos.dtini) {
      return `Filtro ${moment(boletos.dtini, 'YYYY-MM-DD').format('DD/MM/YYYY')} à ${moment(
        boletos.dtfim,
        'YYYY-MM-DD',
      ).format('DD/MM/YYYY')}`
    }
    if (subPage.id === 1 && nfe.dtini) {
      return `Filtro ${moment(nfe.dtini, 'YYYY-MM-DD').format('DD/MM/YYYY')} à ${moment(
        nfe.dtfim,
        'YYYY-MM-DD',
      ).format('DD/MM/YYYY')}`
    }
    return ''
  }

  render() {
    const {
      subPage,
      subPages,
      dialogs: { filtroBoletos, filtroNfe },
      filtros: { boletos, nfe },
    } = this.state
    const { classes /* filtro */ } = this.props
    return (
      <Fragment>
        <Header />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.root}
        >
          {/* MENU FILTROS */}
          <Grid item xs={2}>
            <List component="nav">
              <ListItem selected button onClick={() => this.onSelectSubPage(subPages)}>
                <MoneyIcon />
                <ListItemText primary="FINANCEIRO" />
              </ListItem>
              {subPages.map(sp => (
                <ListItem key={sp.id} button>
                  <ChevronRight />
                  <ListItemText primary={sp.titulo} onClick={() => this.onSelectSubPage(sp)} />
                </ListItem>
              ))}
            </List>
          </Grid>
          {/* GRID */}
          <Grid item xs={10}>
            <Card className={[classes.card, classes.grid].join(' ')}>
              {!!subPage && (
                <CardHeader
                  title={subPage.titulo}
                  subheader={<this.SubHeader />}
                  action={
                    subPage.id < 2 && (
                      <IconButton
                        onClick={() => this.onOpenCloseDialog(subPage.id === 0 ? 'filtroBoletos' : 'filtroNfe')
                        }
                      >
                        <SearchIcon />
                      </IconButton>
                    )
                  }
                />
              )}
              <CardContent>{this.rootContent()}</CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* Dialog de filtro (BOLETOS) */}
        <Dialog
          maxWidth="sm"
          fullWidth
          open={filtroBoletos}
          onClose={() => this.onOpenCloseDialog('filtroBoletos')}
          aria-labelledby="responsive-dialog-filtroBoletos-title"
        >
          <DialogTitle id="responsive-dialog-filtroBoletos-title">Filtro</DialogTitle>
          <DialogContent>
            <Grid
              container
              className={classes.root}
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Data Inicial"
                  className={classes.margin}
                  value={boletos.dtini}
                  onChange={evt => this.onHandleFilterBoleto('dtini', evt.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRanger />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Data Final"
                  className={classes.margin}
                  value={boletos.dtfim}
                  onChange={evt => this.onHandleFilterBoleto('dtfim', evt.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRanger />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="text"
                  label="Titulo"
                  className={classes.margin}
                  value={boletos.titulo}
                  onChange={evt => this.onHandleFilterBoleto('titulo', evt.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Description />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="text"
                  label="Parcela"
                  className={classes.margin}
                  value={boletos.parcela}
                  onChange={evt => this.onHandleFilterBoleto('parcela', evt.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Layers />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="text"
                  label="Valor"
                  className={classes.margin}
                  value={boletos.valor}
                  onChange={evt => this.onHandleFilterBoleto('valor', evt.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MoneyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCancelFiltro} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.onConfirmFiltro} color="primary" autoFocus>
              Filtrar
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog de filtro (NFE) */}
        <Dialog
          maxWidth="sm"
          fullWidth
          open={filtroNfe}
          onClose={() => this.onOpenCloseDialog('filtroNfe')}
          aria-labelledby="responsive-dialog-filtroNfe-title"
        >
          <DialogTitle id="responsive-dialog-filtroNfe-title">Filtro</DialogTitle>
          <DialogContent>
            <Grid
              container
              className={classes.root}
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Data Inicial"
                  className={classes.margin}
                  value={nfe.dtini}
                  onChange={evt => this.onHandleFilterNfe('dtini', evt.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRanger />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Data Final"
                  className={classes.margin}
                  value={nfe.dtfim}
                  onChange={evt => this.onHandleFilterNfe('dtfim', evt.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRanger />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="text"
                  label="Documento"
                  className={classes.margin}
                  value={nfe.doc}
                  onChange={evt => this.onHandleFilterNfe('doc', evt.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Description />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="text"
                  label="Serie"
                  className={classes.margin}
                  value={nfe.serie}
                  onChange={evt => this.onHandleFilterNfe('serie', evt.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Description />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="text"
                  label="Valor"
                  className={classes.margin}
                  value={nfe.valbrut}
                  onChange={evt => this.onHandleFilterNfe('valbrut', evt.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MoneyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCancelFiltro} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.onConfirmFiltro} color="primary" autoFocus>
              Filtrar
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  cliente: state.usuario.usuario.cliente,
  unidades: state.usuario.usuario.cliente.unidades,
  boletos: state.financeiro.boletos,
  danfes: state.financeiro.danfes,
  filtrando: state.financeiro.filtrando,
  gerando: state.financeiro.gerando,
  filtro: state.financeiro.filtro,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...FinanceiroActions,
    ...ConfigActions,
  },
  dispatch,
)

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FinanceiroPage))
