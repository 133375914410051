import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Grid,
} from '@material-ui/core'
import _ from 'lodash'

// Actions
import { Actions as ChecklistActions } from 'store/ducks/checklist'

const DialogFormPeguntas = (props) => {
  const {
    checklist: { formPeguntas = {} },
    onChecklistFormPerguntas,
    onAddPergunta,
    onEditPergunta,
  } = props

  const handleClose = () => {
    onChecklistFormPerguntas({ open: false })
  }

  const handleAdicionar = () => {
    onAddPergunta()
  }

  const handleEditar = () => {
    onEditPergunta()
  }

  const isObrigatorioDisabled = (pType) => {
    if (pType !== 'RADIO' && pType !== 'CHECKBOX') {
      return true
    }
    return false
  }

  return (
    <Dialog
      open={formPeguntas.open}
      onClose={handleClose}
      aria-labelledby="dialog-checklist-perguntas-title"
      aria-describedby="dialog-checklist-perguntas-description"
    >
      <DialogTitle id="dialog-checklist-perguntas-title">
        {`${formPeguntas.id ? 'Edição' : 'Cadastro'} de Pergunta`}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="checklist-perguntas-label">Pergunta</InputLabel>
              <Input
                id="checklist-perguntas-label"
                label="Pergunta"
                value={formPeguntas.label}
                onChange={evt => onChecklistFormPerguntas({
                  label: evt.target.value,
                })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={5}>
            <FormControl fullWidth>
              <InputLabel id="checklist-perguntas-type-label">Tipo</InputLabel>
              <Select
                labelId="checklist-perguntas-type-label"
                id="checklist-perguntas-type"
                value={formPeguntas.type}
                onChange={(evt) => {
                  const vType = evt.target.value
                  onChecklistFormPerguntas({
                    type: vType,
                    isRequired: isObrigatorioDisabled(vType) ? 'N' : 'S',
                  })
                }}
              >
                <MenuItem value="">
                  <em>SELECIONE</em>
                </MenuItem>
                <MenuItem value="RADIO">ESCOLHA ÚNICA</MenuItem>
                <MenuItem value="CHECKBOX">ESCOLHA MÚLTIPLA</MenuItem>
                <MenuItem value="INPUT">CAMPO DE TEXTO</MenuItem>
                <MenuItem value="IMAGE">FOTOS</MenuItem>
                <MenuItem value="RATING">AVALIÇÃO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="checklist-perguntas-ordem">Ordem</InputLabel>
              <Input
                id="checklist-perguntas-ordem"
                label="Ordem"
                type="number"
                value={formPeguntas.ordem}
                onChange={evt => onChecklistFormPerguntas({
                  ordem: evt.target.value,
                })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel id="checklist-perguntas-status-label">Status</InputLabel>
              <Select
                labelId="checklist-perguntas-status-label"
                id="checklist-perguntas-status"
                value={formPeguntas.status}
                onChange={evt => onChecklistFormPerguntas({
                  status: evt.target.value,
                })}
              >
                <MenuItem value="">
                  <em>SELECIONE</em>
                </MenuItem>
                <MenuItem value="P">PENDENTE</MenuItem>
                <MenuItem value="L">LIBERADO</MenuItem>
                <MenuItem value="B">BLOQUEADO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel id="checklist-alternativas-checked-label">Obrigatório?</InputLabel>
              <Select
                disabled={isObrigatorioDisabled(formPeguntas.type)}
                labelId="checklist-alternativas-checked-label"
                id="checklist-alternativas-checked"
                value={formPeguntas.isRequired}
                onChange={evt => onChecklistFormPerguntas({
                  isRequired: evt.target.value,
                })}
              >
                <MenuItem value="">
                  <em>SELECIONE</em>
                </MenuItem>
                <MenuItem value="S">SIM</MenuItem>
                <MenuItem value="N">NÃO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        {formPeguntas.id ? (
          <Button
            onClick={handleEditar}
            color="secondary"
            disabled={_.isEmpty((formPeguntas?.label ?? '').trim())}
            autoFocus
          >
            Editar
          </Button>
        ) : (
          <Button
            onClick={handleAdicionar}
            color="secondary"
            disabled={_.isEmpty((formPeguntas?.label ?? '').trim())}
            autoFocus
          >
            Adicionar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

DialogFormPeguntas.propTypes = {
  checklist: PropTypes.shape().isRequired,
  onChecklistFormPerguntas: PropTypes.func.isRequired,
  onAddPergunta: PropTypes.func.isRequired,
  onEditPergunta: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...ChecklistActions,
  },
  dispatch,
)

const mapStateToProps = state => ({
  auth: state.usuario.usuario.auth,
  usuario: state.usuario.usuario,
  unidade: state.usuario.usuario.unidade,
  checklist: state.checklist,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DialogFormPeguntas)
