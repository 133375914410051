import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Icon,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from '@material-ui/core'
import { NotificationManager } from 'react-notifications'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

import { filterTabelaPrecoProdutoApi } from 'api/tabelapreco/produto'
import { filterPagamentoFormaApi } from 'api/pagamento/formas'
import { filterPagamentoCondicaoApi } from 'api/pagamento/condicoes'
import { addCrmOportunidadeApi } from 'api/crm/oportunidade'
// import { addCrmAtendimentosApi } from 'api/crm/atendimentos'
import TableDataComp from 'components/Table'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'
import Visibility from 'components/Visibility'
import { moneyFormat } from 'utils'
import useStyles from './styles'
import list from 'pages/gestor/leads/list'
import { getAllProdutosApi } from 'api/produtos'
import { filterTabelaPrecoApi } from 'api/tabelapreco'
import { filterUnidadeConsumidoraApi } from 'api/unidade_consumidora'

const DialogAddOportunidade = (props) => {
  const {
    token,
    open,
    payload,
    onSave,
    onClose,
    listItensOportunidade,
    usuario,
    clienteId
  } = props

  const [isSavingForm, setSavingForm] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [form, setForm] = useState()
  const [tabelaPreco, setTabelaPreco] = useState([])
  const [tabelaPrecoProduto, setTabelaPrecoProduto] = useState([])
  const [pagamentoFormas, setPagamentoFormas] = useState([])
  const [pagamentoCondicoes, setPagamentoCondicoes] = useState([])
  const [itensOportunidade, setItensOportunidade] = useState([])

  const classes = useStyles()

  const onUpdateField = (data) => {
    setForm(s => ({ ...s, ...data }))
  }
  useEffect(() => {
  }, [form]);

  const onCloseDialog = () => {
    if (_.isFunction(onClose)) {
      setOpen(false)
      onClose()
    }
  }
  const onSaveOportunidade = () => {
    if (!form?.statusAceite) {
      NotificationManager.warning(
        'Para continuar você deve selecionar o status da oportunidade (ACEITOU/RECUSOU).',
        'Atenção',
        8000,
      )
      return
    }
    if (_.isEmpty(itensOportunidade)) {
      NotificationManager.warning(
        'Para continuar você deve adicionar no mínimo um PRODUTO para essa oportunidade!',
        'Atenção',
        8000,
      )
      return
    }
    if (!form.id) {
      const oportunidadeDTO = {
        id: usuario.id,
        filialId: form?.filialId ?? 0,
        prospectId: form?.prospectId ?? 0,
        statusAceite: form?.statusAceite ?? '',
        textoAtendimento: form?.textoAtendimento ?? '',
        itens: itensOportunidade ?? [],
      }
      setSavingForm(true)
      addCrmOportunidadeApi({ token, payload: oportunidadeDTO })
        .then((resp) => {
          if (resp) {
            if (!Array.isArray(resp) && resp.status > 201) {
              NotificationManager.warning(
                resp.message || resp.error,
                'Atenção',
                8000,
              )
            } else {
              const pedidosOk = _.isArray(resp)
                ? resp.reduce((p, o) => p + +o.status, 0) === 200 * resp.length
                : false
              if (pedidosOk) {
                NotificationManager.success(
                  'Oportunidade criada com sucesso!',
                  'Atenção',
                  8000,
                )
                onCloseDialog()
                if (_.isFunction(onSave)) {
                  onSave({ form, resp }, () => setSavingForm(true), () => setSavingForm(false))
                }
              } else {
                NotificationManager.warning(
                  resp.message,
                  'Atenção',
                  8000,
                )
              }
            }
          }
        }).finally(() => {
          setSavingForm(false)
        })
    }
  }
  const addProduto = () => {
    const tbPrecoProduto = tabelaPrecoProduto?.find(tpp => tpp.id == form?.tabelaPrecoProdutoId)
    const dto = {
      ...form,
      itemId: uuidv4(),
      quantidade: 1,
      valor: tbPrecoProduto?.valor ?? 0,
      valorMin: tbPrecoProduto?.valorMin ?? 0,
      produtoDescricao: tbPrecoProduto?.produtoDescricao ?? '',
      produtoDescricaoComplementar: tbPrecoProduto?.produtoDescricaoComplementar ?? '',
      pagamentoFormaDescricao: pagamentoFormas?.find(pf => pf?.id == form?.pagamentoFormaId)?.descricao,
      pagamentoCondicaoDescricao: pagamentoCondicoes?.find(pc => pc?.id == form?.pagamentoCondicaoId)?.descricao,
    }
    if (hasDTOErrors(dto)) {
      NotificationManager.warning('Precisa preencher todos os campos', 'Atenção', 8000);
      return; // Sai da função e não adiciona o produto se houver erros
    }
    let repetido = false
    for (let index = 0; index < itensOportunidade.length; index++) {
      const element = itensOportunidade[index];
      if (element.produtoId === dto.produtoId) {
        NotificationManager.warning(
          'Apenas um produto de cada!',
          'Atenção',
          8000,
        )
        repetido = true
      }
    }
    if (!repetido) {
      setItensOportunidade(prevItens => [...prevItens, dto]);
    }
  }
  const hasDTOErrors = (dto) => {
    console.log(dto)
    // Verifica se algum dos campos obrigatórios está faltando ou inválido:
    return !dto.itemId ||
      dto.valor <= 0 ||
      dto.valorMin <= 0 ||
      !dto.produtoDescricao ||
      !dto.pagamentoFormaDescricao ||
      !dto.pagamentoCondicaoDescricao;
  };
  const addProdutoItem = (item) => {
    const tbPrecoProduto = tabelaPrecoProduto?.find(tpp => tpp.id == item?.tabelaPrecoProdutoId);
    const dto = {
      ...item,
      itemId: uuidv4(),
      quantidade: 1,
      valor: tbPrecoProduto?.valor ?? 0,
      valorMin: tbPrecoProduto?.valorMin ?? 0,
      produtoDescricao: tbPrecoProduto?.produtoDescricao ?? '',
      pagamentoFormaDescricao: pagamentoFormas?.find(pf => pf?.id == item?.pagamentoFormaId)?.descricao,
      pagamentoCondicaoDescricao: pagamentoCondicoes?.find(pc => pc?.id == item?.pagamentoCondicaoId)?.descricao,
    };
    if (hasDTOErrors(dto)) {
      NotificationManager.warning('Precisa preencher todos os campos', 'Atenção', 8000);
      return; // Sai da função e não adiciona o produto se houver erros
    }
    var repetido = false
    for (let index = 0; index < itensOportunidade.length; index++) {
      const element = itensOportunidade[index];
      if (element.produtoId === dto.produtoId) {
        NotificationManager.warning(
          'Apenas um produto de cada!',
          'Atenção',
          8000,
        )
        repetido = true
      }
    }
    if (!repetido) {
      setItensOportunidade(prevItens => [...prevItens, dto]);
    }

  };
  const delProduto = (row) => {
    setItensOportunidade(itensOportunidade.filter(r => r.itemId != row.itemId))
  }
  const onLoadPagamentoForma = () => {
    filterPagamentoFormaApi({ token, query: {} })
      .then((resp) => {
        if (resp) {
          setPagamentoFormas(resp)
        }
      })
  }
  const onLoadPagamentoCondicao = () => {
    filterPagamentoCondicaoApi({ token, query: {} })
      .then((resp) => {
        if (resp) {
          setPagamentoCondicoes(resp)
        }
      })
  }
  const onLoadTabelaPreco = () => {
    filterTabelaPrecoApi({ token, query: {} })
      .then((resp) => {
        if (resp) {
          setTabelaPreco(resp)
        }
      })
  }
  const onLoadTabelaPrecoProduto = (pTabelaPrecoId) => {
    filterTabelaPrecoProdutoApi({ token, query: { filialId: 1, tabelaPrecoId: pTabelaPrecoId } })
      .then((resp) => {
        if (resp) {
          setTabelaPrecoProduto(resp)
        }
      })
  }

  const tableItensCols = [
    { key: 'produtoDescricao', title: 'PRODUTO' },
    { key: 'a', title: 'PAGAMENTO', format: r => `${r.pagamentoFormaDescricao}/${r.pagamentoCondicaoDescricao}` },
    { key: 'b', title: 'VALOR', format: r => `${moneyFormat(r.valor)}` },
    { key: 'c', title: '', format: r => !r.interesseCompraId && <IconButton size="small" onClick={() => delProduto(r)}><Icon>delete</Icon></IconButton> }
  ]
  const tableItensActions = [
    {
      key: 'delete',
      title: 'Excluir',
      icon: 'delete',
      action: () => null,
    },
  ]

  useEffect(() => {
    if (form?.tabelaPrecoId ?? 0) {
      onLoadTabelaPrecoProduto(form?.tabelaPrecoId)
    }
  }, [form?.tabelaPrecoId])

  useEffect(() => {
    if (payload?.disabled ?? false) {
      setForm({ ...payload, statusProspect: '1' })
      setItensOportunidade(payload.itens)
    } else {
      setForm({ ...payload, statusProspect: '1' })
    }
  }, [payload])
  useEffect(() => {
    console.log('--------------------PAYLOAD--------------------')
    console.log(payload)
  }, [])

  useEffect(() => {
    setOpen(open)
    if (open) {
      setItensOportunidade([])
      onLoadTabelaPreco()
      onLoadPagamentoForma()
      onLoadPagamentoCondicao()
      onLoadProdutos()
    }
  }, [open])
  const [updateCount, setUpdateCount] = useState(0);
  const [produtos, setProdutos] = useState([])
  const onLoadProdutos = () => {
    getAllProdutosApi({ token, query: {} })
      .then((resp) => {
        if (resp) {
          setProdutos(resp)
        }
      })
  }
  useEffect(() => {
    setUpdateCount(count => count + 1);
  }, [tabelaPreco, tabelaPrecoProduto, pagamentoFormas]);

  useEffect(() => {
    if (updateCount === 4) {
      setItensOportunidade([])
      listItensOportunidade.forEach(item => {
        addProdutoItem(item);
      });

    }
  }, [updateCount]);
  useEffect(() => {
    console.log("Valor atual de tabelaPrecoProdutoId:", form?.tabelaPrecoProdutoId);
  }, [form?.tabelaPrecoProdutoId]);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={isOpen}
      onClose={onCloseDialog}
      aria-labelledby="dialog-crm-atendimento"
    >
      <DialogTitle id="dialog-crm-atendimento-title">Oportunidade</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row">
          {/* Tabela de preço */}
          <Grid item xs={12} md={9}>
            <FormControl disabled={form?.disabled ?? true} fullWidth className={classes.formControl}>
              <InputLabel shrink htmlFor="dialog-fob-motorista">
                Tabela de preço
              </InputLabel>
              <Select
                fullWidth
                value={form?.tabelaPrecoId ?? ''}
                onChange={e => onUpdateField({
                  tabelaPrecoId: e?.target?.value,
                })}
                inputProps={{
                  name: 'dialog-op-tabelaPrecoId',
                  id: 'dialog-op-tabelaPrecoId',
                }}
              >
                <MenuItem value="">SELECIONE</MenuItem>
                {tabelaPreco?.map(tb => (
                  <MenuItem
                    key={tb.tabelaPrecoId}
                    value={tb.tabelaPrecoId}
                  >
                    {tb.tabelaPrecoDescricao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Status */}
          <Grid item xs={12} md={3}>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item>
                <FormControl disabled={form?.disabled ?? true} size="small" component="fieldset" fullWidth>
                  <FormLabel component="legend">Status</FormLabel>
                  <RadioGroup
                    row
                    defaultValue="1"
                    aria-label="gender"
                    name="status-prospect"
                    value={form?.statusAceite ?? ''}
                    onChange={e => onUpdateField({
                      statusAceite: e?.target?.value?.toUpperCase(),
                    })}
                  >
                    <FormControlLabel value="A" control={<Radio size="small" />} label="Aceitou" />
                    <FormControlLabel value="R" control={<Radio size="small" />} label="Recusou" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* Produto */}
          <Grid item xs={12}>
            <FormControl disabled={form?.disabled ?? true} fullWidth className={classes.formControl}>
              <InputLabel shrink htmlFor="dialog-op-produto">
                Produto
              </InputLabel>
              <Select
                fullWidth
                value={form?.produtoId ?? ''}
                onChange={e => {
                  onUpdateField({
                    produtoId: e?.target?.value,
                    pagamentoFormaId: '', // limpa o campo Forma de pagamento
                    pagamentoCondicaoId: '', // limpa o campo Condição de pagamento
                  });
                }}
                inputProps={{
                  name: 'dialog-op-produto',
                  id: 'dialog-op-produto',
                }}
              >
                <MenuItem value="">SELECIONE</MenuItem>
                {produtos
                  ?.filter(produto => tabelaPrecoProduto.some(tpp => tpp.produtoId === produto.produtoId && tpp.tabelaPrecoId === form.tabelaPrecoId))
                  ?.map(produtoFiltrado => (
                    <MenuItem
                      key={produtoFiltrado?.produtoId}
                      value={produtoFiltrado?.produtoId}
                    >
                      <Grid container direction="column">
                        <Grid item>
                          {`${produtoFiltrado.produtoDesc} `}
                        </Grid>
                        <Grid item>
                          <small
                            dangerouslySetInnerHTML={{ __html: produtoFiltrado.produtoDescComplementar }}
                          />
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Forma de pagamento */}
          <Grid item xs={12} md={6}>
            <FormControl disabled={form?.disabled ?? true} fullWidth className={classes.formControl}>
              <InputLabel shrink htmlFor="dialog-op-pgto-forma">
                Forma de pagamento
              </InputLabel>
              <Select
                fullWidth
                value={form?.pagamentoFormaId ?? ''}
                onChange={e => onUpdateField({
                  pagamentoFormaId: e?.target?.value,
                })}
                inputProps={{
                  name: 'dialog-op-pgto-forma',
                  id: 'dialog-op-pgto-forma',
                }}
              >
                <MenuItem value="">SELECIONE</MenuItem>
                {pagamentoFormas?.map(fp => (
                  <MenuItem
                    key={fp.id}
                    value={fp.id}
                  >
                    {fp.descricao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Condição de pagamento */}
          <Grid item xs={12} md={6}>
            <FormControl disabled={form?.disabled ?? true} fullWidth className={classes.formControl}>
              <InputLabel shrink htmlFor="dialog-fob-motorista">
                Condição de pagamento
              </InputLabel>
              <Select
                fullWidth
                value={form?.pagamentoCondicaoId ?? ''}
                onChange={e => {
                  console.log(tabelaPrecoProduto)
                  console.log('PAGAMENTO CONDICAO ID', )
                  const selectedTpp = tabelaPrecoProduto.find(tpp =>
                    tpp.pagamentoCondicaoId === e.target.value &&
                    tpp.pagamentoCondicaoPagamentoFormaId === form?.pagamentoFormaId &&
                    form?.produtoId === tpp.produtoId
                  );

                  onUpdateField({
                    pagamentoCondicaoId: e.target.value,
                    tabelaPrecoProdutoId: selectedTpp?.id
                  });
                }}
                inputProps={{
                  name: 'dialog-op-produto',
                  id: 'dialog-op-produto',
                }}
              >
                <MenuItem value="">SELECIONE</MenuItem>
                {
                  tabelaPrecoProduto
                    ?.filter(tpp => tpp?.produtoId == (form?.produtoId ?? 0) && tpp.tabelaPrecoId == form.tabelaPrecoId && form.pagamentoFormaId == tpp.pagamentoCondicaoPagamentoFormaId)
                    ?.sort((a, b) => b.valorMin - a.valorMin)
                    ?.map(tpp => (
                      <MenuItem
                        key={tpp.id}
                        value={tpp.pagamentoCondicaoId}
                      >
                        {`${tpp.pagamentoCondicaoDescricao} - R$${tpp.valorMin}${tpp.valorMin !== tpp.valor ? ` / R$${tpp.valor}` : ''}`}
                      </MenuItem>
                    ))
                }
              </Select>
            </FormControl>
          </Grid>
          {/* Adicionar */}
          <Grid item xs={12} md={12}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Grid item>
                <ButtonLoadingComp
                  disabled={form?.disabled || false}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={addProduto}
                >
                  <Icon className={classes.leftIcon}>add</Icon>
                  Adicionar
                </ButtonLoadingComp>
              </Grid>
            </Grid>
          </Grid>
          {/* textoAtendimento */}
          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={form?.disabled ?? true}
                  id="textoAtendimento"
                  label="Atendimento"
                  multiline
                  maxRows={10}
                  variant="outlined"
                  value={form?.textoAtendimento ?? ''}
                  onChange={e => onUpdateField({ textoAtendimento: e?.target?.value?.toUpperCase() })}
                />
              </Grid>
              {/*
              <Grid item xs={2} className={classes.statusAjust}>
                <FormControlLabel
                  control={<Checkbox checked={form?.addDateAgenda ?? false} onChange={e => onUpdateField({ addDateAgenda: e?.target?.checked })} name="addDateAgenda" />}
                  label="Agendar"
                />
              </Grid>
              */}
            </Grid>
          </Grid>
          {/* Itens Oportunidade */}
          <Grid item xs={12}>
            <TableDataComp
              size="small"
              // loading={loading.aprovacoes}
              loading={false}
              rows={itensOportunidade}
              cols={tableItensCols}
            // actions={tableItensActions}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                {`Total: ${itensOportunidade !== undefined ? moneyFormat(itensOportunidade.reduce((a, b) => a + (b.valor), 0)) : 'N/A'}`}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog} color="secondary">
          Cancelar
        </Button>
        <ButtonLoadingComp disabled={form?.disabled ?? true} loading={isSavingForm} onClick={onSaveOportunidade} color="primary" autoFocus>
          Salvar
        </ButtonLoadingComp>
      </DialogActions>
    </Dialog>
  )
}

DialogAddOportunidade.propTypes = {
  // loading: PropTypes.bool,
  token: PropTypes.string.isRequired,
  open: PropTypes.bool,
  payload: PropTypes.shape().isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  listItensOportunidade: PropTypes.arrayOf(PropTypes.object).isRequired,
  usuario: PropTypes.object.isRequired
}

DialogAddOportunidade.defaultProps = {
  // loading: false,
  open: false,
}

export default DialogAddOportunidade
