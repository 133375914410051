import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Typography } from '@material-ui/core'

// Vendor Components
import Card from 'components/Vendor/CreativeTim/Card/Card'
import CardHeader from 'components/Vendor/CreativeTim/Card/CardHeader'
import CardIcon from 'components/Vendor/CreativeTim/Card/CardIcon'
import CardFooter from 'components/Vendor/CreativeTim/Card/CardFooter'

import useStyles from './styles'

function CardInfoComp(props) {
  const {
    color, icon, textTitle, textCenter, textCenterSub, footer,
  } = props
  const classes = useStyles()
  return (
    <Card>
      <CardHeader color={color} stats icon>
        <CardIcon color={color}>
          <Icon>{icon}</Icon>
        </CardIcon>
        <p className={classes.cardCategory}>{textTitle}</p>
        <Typography variant="h5" gutterBottom className={classes.cardTitle}>
          {textCenter}
        </Typography>
        {textCenterSub && <p className={classes.cardCategory}>{textCenterSub}</p>}
      </CardHeader>
      <CardFooter stats>{footer && <div className={classes.stats}>{footer}</div>}</CardFooter>
    </Card>
  )
}

CardInfoComp.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  textTitle: PropTypes.string.isRequired,
  textCenter: PropTypes.string.isRequired,
  textCenterSub: PropTypes.string,
  footer: PropTypes.node,
}

CardInfoComp.defaultProps = {
  color: 'primary',
  icon: 'assignment',
  textCenterSub: '',
  footer: null,
}

export default CardInfoComp
