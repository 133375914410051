import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  IconButton,
  Icon,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { NotificationManager } from 'react-notifications'
import { getTrackingdanfe } from '../../../api/pedido'
import { DateHoursIsoToBr } from 'utils'

// import { Container } from './styles';

function TrackingSSW(props) {
  const { chaveNfe, color } = props
  const [dialogOpen, setDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [documento, setDocumento] = useState(null)
  const handleTrackinAction = async () => {
    if (!chaveNfe) {
      NotificationManager.warning('Atenção', 'Chave NF-E não informada.', 8000)
    } else {
      // Run
      setLoading(true)
      const { success = false, documento = null } = await getTrackingdanfe(chaveNfe)
      setLoading(false)
      setDocumento(documento)
      setDialogOpen(true)
    }
  }

  return (
    <>
      <Tooltip
        title="Rastreio da entrega"
        aria-label="Rastreio da entrega"
      >
        <IconButton
          color={color}
          onClick={handleTrackinAction}
          disabled={loading}
        >
          {loading
            ? (<CircularProgress size={16} />)
            : (<Icon>local_shipping</Icon>)}
        </IconButton>
      </Tooltip>
      <Dialog
        maxWidth="md"
        fullWidth
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="traking-list"
      >
        <DialogTitle id="traking-list">Rastreio</DialogTitle>
        <DialogContent>
          {!documento ? (
            <p><strong>Rastreio não disponível para a CHAVE NFE ({chaveNfe}).</strong></p>
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <strong>Remetente: </strong>
                        {documento?.header?.remetente ?? ''}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Destinatário: </strong>
                        {documento?.header?.destinatario ?? ''}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>N Fiscal: </strong>
                        {documento?.header?.nro_nf ?? ''}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>DATA/HORA</TableCell>
                    <TableCell>EVENTO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documento?.tracking.map(t => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {t.data_hora ? DateHoursIsoToBr(t?.data_hora) : 'SEM DATA/HORA'}
                      </TableCell>
                      <TableCell>
                        <strong>Dominio:</strong>
                        <span>{`${t?.dominio ?? ''} ${t?.filial ?? ''}`}</span>
                        <br />
                        <strong>Cidade:</strong>
                        <span>{t?.cidade ?? ''}</span>
                        <br />
                        <strong>Ocorrência:</strong>
                        <span>{t?.ocorrencia ?? ''}</span>
                        <br />
                        <strong>Descrição:</strong>
                        <span>{t?.descricao ?? ''}</span>
                        <br />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

TrackingSSW.propTypes = {
  chaveNfe: PropTypes.string.isRequired,
  color: PropTypes.string,
}

TrackingSSW.defaultProps = {
  color: 'secondary'
}

export default TrackingSSW
