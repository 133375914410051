const styles = theme => ({
  root: {
    paddingTop: 20,
  },
  grid: {
    marginLeft: theme.defaults.margin * 2,
    marginRight: theme.defaults.margin * 2,
  },
})

export default styles
