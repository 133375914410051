import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import config from 'react-global-configuration'
// import queryString from 'query-string'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import VpnKey from '@material-ui/icons/VpnKey'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ReCAPTCHA from 'react-google-recaptcha'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Actions as UsuarioActions } from '../../store/ducks/usuario'
import { onUserNewPswApi, onUserNewPswLoggedApi } from 'api/usuario'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { createBrowserHistory } from 'history/cjs/history.min'
import { push as crrPush } from "connected-react-router";
import { NotificationManager } from 'react-notifications'


const styles = {
  content: {
    padding: 0,
    marginTop: 50,
  },
  card: {
    maxWidth: 345,
    maxHeight: 800,
  },
  media: {
    // ⚠️ object-fit is not supported by IE11.
    objectFit: 'cover',
  },
  margin: {
    marginTop: 20,
  },
  containerActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  center: {
    display: 'flex',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  btnContainerEntrar: {
    marginBottom: 15,
  },
  voltarContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  buttonProgress: {
    // color: '#FFF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}

class RecoveryPage extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    classes: PropTypes.shape().isRequired,
    location: PropTypes.shape().isRequired,
    formForgot: PropTypes.shape().isRequired,
    dialogForgot: PropTypes.shape().isRequired,
    reCaptcha: PropTypes.string.isRequired,
    onReCaptcha: PropTypes.func.isRequired,
    onUserNewPsw: PropTypes.func.isRequired,
    onUpdateDialogForgot: PropTypes.func.isRequired,
    onFieldUpdateFormForgot: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { location, onFieldUpdateFormForgot, usuario } = this.props
    const configClienteSku = config.get('cliente.sku')
    const configClienteNome = config.get('cliente.nome')
    const bg = require(`../../assets/imagens/login/${configClienteSku}-login-bg-page.png`)
    const queryString = new URLSearchParams(location.search)
    document.body.style = `background-image: url(${bg}); background-size: cover; background-position: center;`
    document.title = `${configClienteNome} - Recuperação de senha`
    onFieldUpdateFormForgot({
      id: queryString.get('id'),
      name: queryString.get('name'),
      token: queryString.get('token'),
    })
  }

  onRecaptchaClick = (value) => {
    const { onReCaptcha } = this.props
    if (value) onReCaptcha(value)
  }

  render() {
    const {
      classes,
      loading,
      formForgot,
      reCaptcha,
      dialogForgot,
      onUserNewPsw,
      onUpdateDialogForgot,
      onFieldUpdateFormForgot,
      usuario
    } = this.props
    const configDefaultAltLogoLeft = config.get('default.altLogoLeft')
    const configDefaultAltLogoRight = config.get('default.altLogoRight')
    const configDefaultLogoLeft = config.get('default.logoLeft')
    const configDefaultLogoRight = config.get('default.logoRight')
    const configDefaultLoginCardHeader = config.get('default.loginCardHeader')
    const history = createBrowserHistory()
    const userNewPsw = () => {
      const button = document.querySelector('#cancel');

      if (
        usuario &&
        usuario.id &&
        usuario.auth &&
        usuario.auth.token &&
        formForgot &&
        formForgot.senha &&
        formForgot.resenha
      ) {
        return onUserNewPswLoggedApi({
          id: usuario.id,
          token: usuario.auth.token,
          senha: formForgot.senha,
          resenha: formForgot.resenha
        })
          .then((response) => {
            button.click();
            NotificationManager.success('A senha foi ALTERADA com sucesso!', 'Sucesso!', 4000);
            // Lógica adicional após a chamada da API, se necessário
            return response;
          })
          .catch((error) => {
            // Tratamento de erros, se necessário
            throw error;
          });
      } else {
        // Chamar a função onUserNewPsw
        onUserNewPsw();
        return new Promise(async (resolve) => {
          await onUserNewPsw(); // Aguardar a conclusão da função onUserNewPsw
          button.click();
          NotificationManager.success('A senha foi ALTERADA com sucesso!', 'Sucesso!', 4000);
          resolve(); // Resolver a Promise após o clique no botão
        });
      }
    };

    return (
      <>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              {configDefaultLogoLeft && <img alt={configDefaultAltLogoLeft} src={require(`../../assets/imagens/${configDefaultLogoLeft}`)} />}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={[classes.content, classes.center].join(' ')}>
              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  alt="Contemplative Reptile"
                  className={classes.media}
                  height="140"
                  image={require(`../../assets/imagens/login/${configDefaultLoginCardHeader}`)}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                    <Grid item>
                      <Typography gutterBottom variant="h5" component="h2">
                        Recuperação
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography component="p">
                        {'Olá '}
                        <strong>{formForgot.name}</strong>
                        {', Para finalizar o processo de troca de sua senha, informe sua nova senha e confirme-a.'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={2} md={2}>
                          <VpnKey />
                        </Grid>
                        <Grid item xs={10} md={10}>
                          <TextField
                            label="Senha"
                            fullWidth
                            type="password"
                            value={formForgot.senha}
                            onChange={evt => onFieldUpdateFormForgot({ senha: evt.target.value })}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={2} md={2}>
                          <VpnKey />
                        </Grid>
                        <Grid item xs={10} md={10}>
                          <TextField
                            label="Re-Senha"
                            fullWidth
                            type="password"
                            value={formForgot.resenha}
                            onChange={evt => onFieldUpdateFormForgot({ resenha: evt.target.value })}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <ReCAPTCHA
                        sitekey="6LczQ0MnAAAAALGNg22IXaPEIs_otQgp12wDeAdx"
                        onChange={value => this.onRecaptchaClick(value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions className={classes.containerActions}>
                  <Grid
                    container
                    className={classes.btnContainerEntrar}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      disabled={
                        !reCaptcha
                        || !formForgot.senha
                        || !formForgot.resenha
                        || formForgot.senha !== formForgot.resenha
                      }
                      variant="contained"
                      color="primary"
                      onClick={userNewPsw}
                    >
                      CONFIRMAR
                    </Button>
                  </Grid>
                  <Grid
                    container
                    className={classes.voltarContainer}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button id="cancel" color="secondary" component={Link} to="/login" className={classes.button}>
                      CANCELAR
                    </Button>
                  </Grid>
                </CardActions>
              </Card>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              {configDefaultLogoRight && <img alt={configDefaultAltLogoRight} src={require(`../../assets/imagens/${configDefaultLogoRight}`)} />}
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          maxWidth="xs"
          open={dialogForgot.open}
          onClose={() => onUpdateDialogForgot({ open: false })}
          aria-labelledby="forgot-dialog-title"
        >
          <DialogTitle id="forgot-dialog-title">{dialogForgot.title}</DialogTitle>
          <DialogContent>{dialogForgot.msg}</DialogContent>
          <DialogActions>
            <Button onClick={() => onUpdateDialogForgot({ open: false })} color="secondary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = state => ({
  reCaptcha: state.usuario.reCaptcha,
  usuario: state.usuario.usuario,
  loading: state.usuario.loading,
  dialogForgot: state.usuario.dialogForgot,
  formForgot: state.usuario.formForgot,
})

const mapDispatchToProps = dispatch => bindActionCreators(UsuarioActions, dispatch)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RecoveryPage),
)
