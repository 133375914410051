import { API_URL } from '..'
import { httpParseJson, httpValidState, httpValidErros } from '../../utils/http'

const ls = '@smr-portal-carrinho'
const ref = '/pedidos_padrao/item'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/* --------------
  Pedido padrão Item
---------------- */
export const desvinculaPedidoPadraoItemApi = ({ token, id }) => fetch(`${API_URL}${ref}/declinar/${id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(''),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/* --------------
  Pedido padrão Item
---------------- */
export const converterPedidoPadraoItemApi = ({ token, id }) => fetch(`${API_URL}${ref}/converter/${id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(''),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

export const converterTodosPedidoPadraoItemApi = ({ token, id, unidNumero }) => fetch(`${API_URL}${ref}/converter/all/${id}/${unidNumero}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(''),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)  