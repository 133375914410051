/* eslint-disable import/no-dynamic-require */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import {
  Grid,
  CssBaseline,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TableFooter,
  TablePagination,
  TableSortLabel,
  IconButton,
  Icon,
  Menu,
  MenuItem,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  // DialogActions,
} from '@material-ui/core'

import config from 'react-global-configuration'

// * Api
import { filterVendedorConsultaPedidosApi } from '../../../api/vendedor'

import { Actions as ConfigActions } from '../../../store/ducks/config'
import { Actions as DialogActions } from '../../../store/ducks/dialog'
import { Actions as GestorUsuariosActions } from '../../../store/ducks/gestor/usuarios'
import { Actions as UsuarioActions } from '../../../store/ducks/usuario'
import { Actions as PedidosActions } from '../../../store/ducks/pedidos'
import { Actions as FinanceiroActions } from '../../../store/ducks/financeiro'

import GestorHeaderComp from '../../../components/Gestor/Header'
import PedidoDetalhesItensComp from '../../../components/Pedido/PedidoDetalhesItens'
import PedidoDetalhesLinhaStatusComp from '../../../components/Pedido/PedidoDetalhesLinhaStatus'
import PedidoDetalhesFiscalComp from '../../../components/Pedido/PedidoDetalhesFiscal'
import PedidoDetalhesTitulosComp from '../../../components/Pedido/PedidoDetalhesTitulos'
import { moneyFormat, SToC } from 'utils'

import TablePaginationActions from './components/table.pagination.actions'
import useStyles from './styles'

function VendasConsultasPage(props) {
  const {
    history,
    usuario,
    pedido,
    // gerando,
    pedidoLoading,
    getParametro,
    onLoadPedido,
    onDownloadDanfeXML,
    onDownloadBoleto,
  } = props
  const [dialogDetalhe, setDialogDetalhe] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [rowSelected, setRowSelected] = useState(null)
  const [tableFilterFields, setTableFilterFields] = useState([])
  const [tableColsHeader, setTableColsHeader] = useState([])
  const [tableRowsData, setTableRowsData] = useState([])
  const classes = useStyles()
  const configDefaultAltLogoLeft = config.get('default.altLogoLeft')
  const configDefaultLogoLeft = config.get('default.logoLeft')
  const pDataEmbarque = getParametro('DATA_EMBARQUE', 'NAO')
  const pTrackingDanfe = getParametro('TRACKING_DANFE', 'NAO')
  const pPedDetEtapa2 = getParametro('PED_DET_ETAPA_2', 'EM PROCESSAMENTO')

  const onFilterData = async (pFormFilter = {}) => {
    let { formFilterPeriodoDe, formFilterPeriodoAte } = pFormFilter

    if (!formFilterPeriodoDe || !formFilterPeriodoAte) {
      formFilterPeriodoDe = moment().subtract(90, 'days').format('YYYY-MM-DD')
      formFilterPeriodoAte = moment().format('YYYY-MM-DD')
    }

    const formFilter = { ...pFormFilter, formFilterPeriodoDe, formFilterPeriodoAte }
    setLoading(true)
    const resp = await filterVendedorConsultaPedidosApi({
      token: usuario.auth.token,
      params: {
        cgc: usuario.cpf,
      },
      formFilter,
    })
    setLoading(false)
    // Atualizando filtro inicial
    const aFormFilter = resp?.tableFilterFields

    if (_.isArray(aFormFilter)) {
      Object.entries(formFilter).forEach(([key, value]) => {
        const keyIdx = aFormFilter.findIndex(ff => ff.key === key)
        aFormFilter[keyIdx] = { ...aFormFilter[keyIdx], value }
      })
    }

    setTableFilterFields(aFormFilter)
    setTableColsHeader(resp?.tableColsHeader ?? [])
    setTableRowsData(resp?.tableRowsData ?? [])
  }

  const handleFilterChangeValue = (pEvt, pFilterField) => {
    const value = pEvt?.target?.value ?? ''
    setTableFilterFields(s => s.map((i) => {
      if (i.key === pFilterField.key) {
        return { ...i, value }
      }
      return i
    }))
  }

  const handleDetailAction = (pRow) => {
    setAnchorEl(null)
    setDialogDetalhe(true)
    onLoadPedido({
      pedidoRef: pRow.pedidoNumero,
      codigoEmpresa: pRow.codigoEmpresa,
      codigoFilial: pRow.codigoFilial,
    })
  }

  const handleShowActions = (pEvt, pRow) => {
    setAnchorEl(pEvt.currentTarget)
    setRowSelected(pRow)
  }

  const handleRequestSort = (pEvt, pProperty) => {
    const isAsc = orderBy === pProperty && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(pProperty)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRenderData = (pCol, pRow) => {
    switch (pCol.key) {
      case 'pedidoEmissao':
        return SToC(pRow[pCol.key])
      default:
        return pRow[pCol.key]
    }
  }

  const handleFilterData = () => {
    const formFilter = Object.fromEntries(tableFilterFields.map(f => [f.key, f.value]))
    onFilterData(formFilter)
  }

  const renderTotais = (pTableColsHeader) => {
    const hasValorVenda = pTableColsHeader.findIndex(h => h.key === 'valorVenda') !== -1
    const hasValorPago = pTableColsHeader.findIndex(h => h.key === 'valorPago') !== -1
    const hasValorSaldo = pTableColsHeader.findIndex(h => h.key === 'valorSaldo') !== -1
    return (hasValorVenda && hasValorPago && hasValorSaldo)
  }

  const rowsPage = _.orderBy(tableRowsData, [orderBy], [order])
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  useEffect(() => {
    /*
    if ((usuario?.email ?? '').trim() !== 'felipe@valdeirbatista.com.br') {
      history.push('/')
    }
    */
    onFilterData()
  }, [])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`${usuario.nome}`} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          {/* Page Header */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Link to="/home">
                  {configDefaultLogoLeft && <img alt={configDefaultAltLogoLeft} src={require(`../../../assets/imagens/${configDefaultLogoLeft}`)} />}
                </Link>
              </Grid>
              <Grid container item xs={10} direction="row" justifyContent="flex-start" alignItems="flex-end">
                <Typography variant="h5" component="h5">
                  Consulta Vendas - Clientes
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* Filtro */}
          <Grid item xs={12} className={classes.tableContainer}>
            <Accordion>
              <AccordionSummary
                expandIcon={<Icon>expand_more</Icon>}
                aria-controls="panel1a-content"
                id="accor-form-filter"
              >
                <Icon>filter_alt</Icon>
                <Typography className={classes.heading}>FILTROS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="column">
                  <Grid
                    container
                    item
                    spacing={1}
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    {_.orderBy(tableFilterFields, ['order'], ['asc']).map(f => (
                      <Grid key={f.key} item xs={12} md={f.size}>
                        <TextField
                          fullWidth
                          id={f.key}
                          label={`${f.description}`}
                          type={f.type}
                          value={f.value}
                          onChange={evt => handleFilterChangeValue(evt, f)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <Button
                      variant="outlined"
                      className={classes.btnFiltrar}
                      onClick={handleFilterData}
                      color="secondary"
                    >
                      APLICAR
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* Table Content */}
          <Grid item xs={12} className={classes.tableContainer}>
            <TableContainer component={Paper}>
              {loading ? (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                <Table size="small" className={classes.table} aria-label="vendas consultas">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {_.orderBy(tableColsHeader, ['order', ['asc']]).map(h => (
                        <TableCell key={h.key} sortDirection={order} align={h.align}>
                          <TableSortLabel
                            active={orderBy === h.key}
                            direction={order}
                            onClick={evt => handleRequestSort(evt, h.key)}
                          >
                            {h.description}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0 ? rowsPage : tableRowsData
                    ).map(row => (
                      <TableRow key={row.key}>
                        <TableCell>
                          <IconButton aria-label="expand row" size="small" onClick={evt => handleShowActions(evt, row)}>
                            <Icon>more_vert</Icon>
                          </IconButton>
                        </TableCell>
                        {_.orderBy(tableColsHeader, ['order', ['asc']]).map(c => (
                          <TableCell key={c.key} align={c.align}>
                            {handleRenderData(c, row)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                    {renderTotais(tableColsHeader) && (
                      <>
                        <TableRow>
                          <TableCell colSpan={(tableColsHeader?.length ?? 0) - 3} />
                          <TableCell>Total Página:</TableCell>
                          <TableCell align="right">{moneyFormat(_.sumBy(rowsPage, r => r.valorVenda))}</TableCell>
                          <TableCell align="right">{moneyFormat(_.sumBy(rowsPage, r => r.valorPago))}</TableCell>
                          <TableCell align="right">{moneyFormat(_.sumBy(rowsPage, r => r.valorSaldo))}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={(tableColsHeader?.length ?? 0) - 3} />
                          <TableCell>Total Geral:</TableCell>
                          <TableCell align="right">{moneyFormat(_.sumBy(tableRowsData, r => r.valorVenda))}</TableCell>
                          <TableCell align="right">{moneyFormat(_.sumBy(tableRowsData, r => r.valorPago))}</TableCell>
                          <TableCell align="right">{moneyFormat(_.sumBy(tableRowsData, r => r.valorSaldo))}</TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      {/*  ${from}-${to} of ${count !== -1 ? count : more than ${to}} */}
                      <TablePagination
                        labelDisplayedRows={({ from, to, count }) => ((count > 0 && to > 0) ? `${from}-${to} de ${count}` : 'Todas')}
                        labelRowsPerPage="Linhas por página"
                        rowsPerPageOptions={[10, 25, 50, { label: 'Todas', value: -1 }]}
                        // colSpan={(tableColsHeader?.length ?? 0) - 2}
                        count={tableRowsData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'por página' },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              )}
            </TableContainer>
          </Grid>
        </Grid>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => handleDetailAction(rowSelected)}>Detalhe do Pedido</MenuItem>
        </Menu>
      </main>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={dialogDetalhe}
        onClose={() => setDialogDetalhe(false)}
        aria-labelledby="detalhe-pedido"
      >
        <DialogTitle id="detalhe-pedido">{`Detalhe do Pedido: ${pedido?.ref ?? ''}, ORIGEM: ${pedido?.origem ?? 'ERP'}`}</DialogTitle>
        <DialogContent>
          {pedidoLoading.loadPedidoById ? (
            <CircularProgress />
          ) : (
            <>
              {/* Header do Pedido */}
              <PedidoDetalhesLinhaStatusComp
                pedido={pedido}
                params={{
                  kClientesEntregas: pDataEmbarque,
                  kPedDetEtapa2: pPedDetEtapa2,
                }}
              />
              {/* - Itens do Pedido */}
              <PedidoDetalhesItensComp
                itens={pedido?.itens ?? []}

              />
              <PedidoDetalhesFiscalComp
                notas={pedido?.notas ?? []}
                params={{ kTrackingDanfe: pTrackingDanfe }}
                actions={{
                  onDownloadDanfe: onDownloadDanfeXML,
                }}
              />
              <PedidoDetalhesTitulosComp
                titulos={pedido?.titulos ?? []}
                actions={{
                  onDownloadBoleto,
                }}
              />
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

VendasConsultasPage.propTypes = {
  usuario: PropTypes.shape().isRequired,
  pedido: PropTypes.shape().isRequired,
  pedidoLoading: PropTypes.shape().isRequired,
  // gerando: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  getParametro: PropTypes.func.isRequired,
  onLoadPedido: PropTypes.func.isRequired,
  onDownloadDanfeXML: PropTypes.func.isRequired,
  onDownloadBoleto: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  pedido: state.pedidos.pedido,
  pedidoLoading: state.pedidos.loading,
  gerando: state.financeiro.gerando,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...GestorUsuariosActions,
    ...UsuarioActions,
    ...PedidosActions,
    ...FinanceiroActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendasConsultasPage)
