import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from '../..'

const ref = '/crm/atendimentos'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - { token: String, page, rows, search, prospectId, oportunidadeId }
 */
export const filterCrmAtendimentosApi = (dto) => {
  const {
    token,
    query: {
      page = 0, rows = 50, search = '', prospectId, oportunidadeId,
    },
  } = dto

  let queryUrl = '?1=1'
  queryUrl += page ? `&page=${page}` : ''
  queryUrl += rows ? `&rows=${rows}` : ''
  queryUrl += search ? `&search=${search}` : ''
  queryUrl += prospectId ? `&prospectId=${prospectId}` : ''
  queryUrl += oportunidadeId ? `&oportunidadeId=${oportunidadeId}` : ''

  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, id }
 */
export const findByIdCrmAtendimentosApi = (dto) => {
  const { token, id } = dto
  return fetch(`${API_URL}${ref}/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const addCrmAtendimentosApi = ({ token, payload }) => fetch(`${API_URL}${ref}`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const editCrmAtendimentosApi = ({ token, id, payload }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do produto, fk: ID da categoria }
 */
export const delCrmAtendimentosApi = ({ token, id }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)
