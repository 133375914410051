import { push } from 'connected-react-router'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'

import { Actions as DialogActions } from '../dialog'
import {
  searchClienteApi,
  getClienteByIdApi,
  AddClienteUnidadeAPI,
  DeleteClienteUnidadeApi,
  addClienteApi,
  editClienteApi,
} from '../../../api/cliente'
import { searchFilialApi } from '../../../api/empresa/filial'

// State Gestor/Produtos
const initialState = {
  loading: {
    list: false,
    cliente: false,
  },
  list: { page: 0, pages: 0, data: [] },
  cliente: {
    id: 0,
    codigo: '',
    loja: '',
    email: '',
    nome: '',
    razao: '',
    cgc: '',
    telefone: '',
    status: '',
    unidades: [
      // { id: 5, sku: '105', descricao: 'CPV',},
    ],
    usuarios: [],
    endereco: {},
  },
}

// Actions Types
export const Types = {
  GC_RESET: 'gestorClientes/GC_RESET',
  GC_LOADING: 'gestorClientes/GC_LOADING',
  GC_UPDATE_LIST: 'gestorClientes/GC_UPDATE_LIST',
  GC_UPDATE_CLIENTE: 'gestorClientes/GC_UPDATE_CLIENTE',
  GC_UPDATE_CLIENTE_FIELD: 'gestorClientes/GC_UPDATE_CLIENTE_FIELD',
  GC_UPDATE_CLIENTE_ENDERECO_FIELD: 'gestorClientes/GC_UPDATE_CLIENTE_ENDERECO_FIELD',
}

// Reducers
export default function gestorClientesReducers(state = initialState, action) {
  switch (action.type) {
    case Types.GC_RESET:
      return { ...state, ...initialState }
    case Types.GC_LOADING:
      return { ...state, loading: { ...state.loading, ...action.payload } }
    case Types.GC_UPDATE_LIST:
      return { ...state, list: action.payload }
    case Types.GC_UPDATE_CLIENTE:
      return { ...state, cliente: action.payload }
    case Types.GC_UPDATE_CLIENTE_FIELD:
      return { ...state, cliente: { ...state.cliente, ...action.payload } }
    case Types.GC_UPDATE_CLIENTE_ENDERECO_FIELD:
      return {
        ...state,
        cliente: { ...state.cliente, endereco: { ...state.cliente.endereco, ...action.payload } },
      }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  onLoading: payload => (dispatch) => {
    dispatch({ type: Types.GC_LOADING, payload })
  },
  onUpdateClienteField: payload => (dispatch) => {
    dispatch({ type: Types.GC_UPDATE_CLIENTE_FIELD, payload })
  },
  onUpdateClienteEnderecoField: payload => (dispatch) => {
    dispatch({ type: Types.GC_UPDATE_CLIENTE_ENDERECO_FIELD, payload })
  },
  onSearchClientes: ({ search = '', page = 0 }) => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()

    const query = {search:search , page:page}
    dispatch({ type: Types.GC_RESET })
    dispatch(Actions.onLoading({ list: true }))
    setTimeout(() => {
      searchClienteApi({ token, query }).then((payload) => {
        dispatch(Actions.onLoading({ list: false }))
        if (payload && _.isArray(payload.data)) {
          dispatch({ type: Types.GC_UPDATE_LIST, payload })
        } else {
          dispatch({ type: Types.GC_UPDATE_LIST, payload: [] })
        }
      })
    }, 1000)
  },
  onLoadDadosCliente: id => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    dispatch(Actions.onLoading({ cliente: true }))
    setTimeout(() => {
      getClienteByIdApi({ token, id }).then((payload) => {
        dispatch(Actions.onLoading({ cliente: false }))
        if (payload && _.isObject(payload)) {
          dispatch({ type: Types.GC_UPDATE_CLIENTE, payload })
        } else {
          dispatch({ type: Types.GC_UPDATE_CLIENTE, payload: initialState.cliente })
        }
      })
    }, 200)
  },
  onResetDadosCliente: () => (dispatch) => {
    dispatch({ type: Types.GC_UPDATE_CLIENTE, payload: initialState.cliente })
  },
  onSaveCliente: () => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
      gestorClientes: { cliente },
    } = getState()
    if (cliente.id) {
      editClienteApi({ token, dto: cliente, id: cliente.id }).then((resp) => {
        if (resp && resp.status === 200) {
          NotificationManager.success('Cliente editado com sucesso!', 'Atenção:', 8000)
          dispatch(Actions.onLoadDadosCliente(resp.id))
        }
      })
    } else {
      addClienteApi({ token, dto: cliente }).then((resp) => {
        if (resp && resp.status === 200) {
          NotificationManager.success('Cliente adicionando com sucesso!', 'Atenção:', 8000)
          dispatch(Actions.onLoadDadosCliente(resp.id))
          dispatch(push(`/gestor/clientes`))
        }
      })
    }
  },
  onSearchUnidades: (q = '') => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    dispatch(DialogActions.onSearchGenericLoading(true))
    searchFilialApi({ token, q }).then((payload) => {
      dispatch(DialogActions.onSearchGenericLoading(false))
      if (payload && _.isArray(payload)) {
        dispatch(
          DialogActions.onSearchGenericLoadData({
            page: 1,
            pages: 1,
            rows: payload.length,
            itens: payload,
          }),
        )
      } else {
        // Não retornou nada... o form é zerado!
        dispatch(DialogActions.onSearchGenericClean())
      }
    })
  },
  onAddClienteUnidade: unidade => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
      gestorClientes: { cliente },
    } = getState()
    AddClienteUnidadeAPI({ token, dto: { pk: cliente.id, fk: unidade.id } }).then((payload) => {
      if (payload && payload.status === 201) {
        NotificationManager.success('Unidade adicionada com sucesso.', 'Atenção:', 8000)
        dispatch(Actions.onLoadDadosCliente(cliente.id))
      } else {
        NotificationManager.warning(
          'Não foi possível adicionar a unidade ao cliente.',
          'Atenção:',
          8000,
        )
      }
    })
  },
  onDeleteClienteUnidade: unidade => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
      gestorClientes: { cliente },
    } = getState()
    DeleteClienteUnidadeApi({ token, cliente: cliente.id, unidade: unidade.id }).then((payload) => {
      if (payload && payload.status === 204) {
        NotificationManager.success('Unidade removida com sucesso.', 'Atenção:', 8000)
        dispatch(Actions.onLoadDadosCliente(cliente.id))
      } else {
        NotificationManager.warning(
          'Não foi possível remover a unidade do cliente.',
          'Atenção:',
          8000,
        )
      }
    })
  },
}
