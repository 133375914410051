import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
    Grid,
    Icon,
    TextField,
    InputAdornment,
    IconButton,
    CssBaseline,
} from '@material-ui/core'

import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import TableDataComp from '../../../../../../../../components/Table'
import useStyles from './styles'
import { findByIdVisitaApi, getVisitasFilterApi } from 'api/visitas'
import { DialogContentSimples } from 'components/Dialogs/Contents'
import { getClienteByIdApi } from 'api/cliente'
import { buscarDadosTabelaPrecoProdutoApi } from 'api/tabelapreco/produto'

const VisitaProdutosDetailTab = (props) => {
    const {
        usuario, push, params
    } = props
    const classes = useStyles()

    // State
    const [listFilter, setListFilter] = useState([])
    const [filter, setFilter] = useState({
        page: 0,
        rows: 100,
        search: '',
    })
    const [payload, setpayload] = useState([])
    const tableCols = [
        { key: 'sku', title: 'SKU' },
        { key: 'valor', title: 'VALOR' },
        { key: 'descricao', title: 'DESCRICAO' },
        { key: 'descricao_complementar', title: 'DESC COMPLEMENTAR' },
        { key: 'interesse', title: 'INTERESSE' }
    ]

    const OnSerachProdutos = () => {
        buscarDadosTabelaPrecoProdutoApi({ token: usuario?.auth?.token ?? '', id: params?.id ?? '' })
            .then((resp) => {
                setpayload(resp)
            })
    }

    useEffect(() => {
        document.title = 'Online - Visita'
        document.body.style = 'background-image: none;'
        OnSerachProdutos()
    }, [])
    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <Grid container spacing={2}>
                    {/* Body */}
                    <Grid item xs={12} md={12}>
                        <TableDataComp
                            rows={payload}
                            cols={tableCols}
                        />
                    </Grid>
                </Grid>
            </main>
        </div>
    )
}

VisitaProdutosDetailTab.propTypes = {
    push: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        push: crrPush,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VisitaProdutosDetailTab)
