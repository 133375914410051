import { NotificationManager } from "react-notifications";
import { push } from "connected-react-router";
import _ from "lodash";

// API
import {
  delAgendaApiByProspect,
  editAgendaApi,
  addAgendaApi,
  findByIdAgendaApi,
  filterAgendaApi
} from "../../../api/agenda";

// Actions Types
export const Types = {
  RESET: "reset",
  AGENDA_LOADING: "agenda/LOADING",
  AGENDA_RESULTSET: "agenda/RESULTSET"
};

// Reducers
// export default function agendaReducers(state = initialState, action) {
//     switch (action.type) {
//       case Types.RESET:
//         return {
//           ...state,
//           ...initialState,
//         }
//       case Types.AGENDA_LOADING:
//         return {
//           ...state,
//           loading: { ...state.loading, ...action.payload },
//         }
//       case Types.AGENDA_RESULTSET:
//         return {
//           ...state,
//           resultSet: { ...state.resultSet, ...action.payload },
//         }
//       default:
//         return state
//     }
//   }

// Actions Creators
export const Actions = {
  // Store Update
  onReset: () => ({ type: Types.RESET }),
  onAgendaLoading: payload => ({ type: Types.AGENDA_LOADING, payload }),
  onAgendaResultSet: payload => ({ type: Types.AGENDA_RESULTSET, payload }),
  // onAgendaResultSetClean: () => ({
  // type: Types.AGENDA_RESULTSET,
  // payload: initialState.session,
  // }),
  onDelAgendaByprospect: (agendaId, motivoId, onCallbackSuccess, onCallbackError) => (
    dispatch,
    getState
  ) => {
    const {
      usuario: {
        usuario: {
          auth: { token }
        }
      }
    } = getState();
    dispatch(Actions.onAgendaLoading({ deleteAgenda: true }));
    delAgendaApiByProspect({ token, id: agendaId, motivoId: motivoId })
      .then(resp => {
        if (resp && resp.status === 204) {
          NotificationManager.success(
            "Agenda excluída com sucesso!",
            "Sucesso!",
            8000
          );
          // window.location.reload();
          if (_.isFunction(onCallbackSuccess)) {
            onCallbackSuccess();
          }
          return;
        }
        if (_.isFunction(onCallbackError)) {
          onCallbackError();
        }
      })
      .catch(() => {
        if (_.isFunction(onCallbackError)) {
          onCallbackError();
        }
      })
      .finally(() =>
        dispatch(Actions.onAgendaLoading({ deleteAgendamento: false }))
      );
  }
};
