import React from 'react'
import ReactDOM from 'react-dom'
import config from 'react-global-configuration'
import configuration from './config'
import 'react-notifications/lib/notifications.css'
import './index.css'
import App from './App'

// Config da aplicação
config.set(configuration)
// import registerServiceWorker from './registerServiceWorker';

const linkIconMeterial = document.createElement('link')
linkIconMeterial.href = 'https://fonts.googleapis.com/icon?family=Material+Icons'
linkIconMeterial.rel = 'stylesheet'

const metaSiteBlindado = document.createElement('meta')
metaSiteBlindado.name = 'sb.validation_hash'
metaSiteBlindado.content = 'BLI-BYUIlVEOMPvqRWhOpOZwuLbbgfJYPnxnagQoFBQWrdDQMXuKnGLdhjmmASLQmKrNkhZXADEpxKPsKmVVseMQkuMqsBnJFBuL'

const faviconElement = document.createElement('link')
faviconElement.rel = 'shortcut icon'
// faviconElement.type = 'image/png'
faviconElement.href = require('./assets/'+config.get('cliente.sku')+'-favicon.ico')

const jQueryScript = document.createElement('script')
jQueryScript.src = 'https://code.jquery.com/jquery-3.5.1.slim.min.js'
jQueryScript.integrity = 'sha256-4+XzXVhsDmqanXGHaHvgh1gMQKX40OUvDEBTu8JcmNs='
jQueryScript.crossorigin = 'anonymous'

const PopperScript = document.createElement('script')
PopperScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js'
PopperScript.integrity = 'sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1'
PopperScript.crossorigin = 'anonymous'

const BootStrapScript = document.createElement('script')
BootStrapScript.src = 'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js'
BootStrapScript.integrity = 'sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM'
BootStrapScript.crossorigin = 'anonymous'

const BootStrapCss = document.createElement('script')
BootStrapCss.src = 'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css'
BootStrapCss.rel = 'stylesheet'
BootStrapCss.integrity = 'sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T'
BootStrapCss.crossorigin = 'anonymous'

document.getElementsByTagName('head')[0].appendChild(metaSiteBlindado)
document.getElementsByTagName('head')[0].appendChild(linkIconMeterial)
document.getElementsByTagName('head')[0].appendChild(faviconElement)
/*
document.getElementsByTagName('head')[0].appendChild(jQueryScript)
document.getElementsByTagName('head')[0].appendChild(PopperScript)
document.getElementsByTagName('head')[0].appendChild(BootStrapScript)
document.getElementsByTagName('head')[0].appendChild(BootStrapCss)
*/

ReactDOM.render(<App />, document.getElementById('root'))
// registerServiceWorker()
