import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from '../..'

const ref = '/tabelaprecoproduto'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - { token: String, query: Object }
 */
export const filterTabelaPrecoProdutoApi = (dto) => {
  // (TODO: Ajusta numero de rows ou ajustar api para retornar todos os TPP(tabela_preco_produto))
  const {
    token,
    query: {
      page = 0, rows = 900, search = '', ativo, tabelaPrecoId, produtoId, clienteId, filialId,
    },
  } = dto

  let queryUrl = '?1=1'
  queryUrl += page ? `&page=${page}` : ''
  queryUrl += rows ? `&rows=${rows}` : ''
  queryUrl += search ? `&search=${search}` : ''
  queryUrl += ativo ? `&ativo=${ativo}` : ''
  queryUrl += tabelaPrecoId ? `&tabelaPrecoId=${tabelaPrecoId}` : ''
  queryUrl += produtoId ? `&produtoId=${produtoId}` : ''
  queryUrl += clienteId ? `&clienteId=${clienteId}` : ''
  queryUrl += filialId ? `&filialId=${filialId}` : ''

  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, id }
 */
export const findByIdTabelaPrecoProdutoApi = (dto) => {
  const { token, id } = dto
  return fetch(`${API_URL}${ref}/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const addTabelaPrecoProdutoApi = ({ token, payload }) => fetch(`${API_URL}${ref}`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const editTabelaPrecoProdutoApi = ({ token, id, payload }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(payload),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do produto, fk: ID da categoria }
 */
export const delTabelaPrecoProdutoApi = ({ token, id }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} dto - { token: String }
 */
export const buscarDadosTabelaPrecoProdutoApi = (dto) => {
  const { token, id } = dto
  // ID DA VISITA
  return fetch(`${API_URL}${ref}/buscar_dados/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}