import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    paddingTop: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  card: {
    marginTop: 40,
    padding: theme.spacing(3),
  },
  grid: {
    padding: theme.spacing(3),
    // margin: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing(2),
  },
  margin: {
    marginRight: theme.spacing(2),
  },
  textField: {
    // flexBasis: 200,
  },
  leftIcon: {
    marginRight: theme.defaults.margin / 2,
  },
  toolbar: theme.mixins.toolbar,
}))

export default styles
