import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { NotificationManager } from 'react-notifications';

const DeleteConfirmationDialog = ({ open, onClose, row, deleteVisitaApi, usuario, onSearchVisitas }) => {
    const handleConfirmDelete = () => {
        onClose();
        if (row) {
            deleteVisitaApi({ token: usuario?.auth?.token ?? '', id: row.id })
                .then(response => {
                    if (response.status === 204) {
                        NotificationManager.success('Visita deletada com sucesso!', 'Success', 3000);
                        onSearchVisitas();
                    } else {
                        NotificationManager.error('Erro ao deletar a visita. Tente novamente.', 'Erro', 3000);
                    }
                })
                .catch(error => {
                });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Confirma exclusão da visita?</DialogTitle>
            <DialogContent>
                <DialogContent>
                    <DialogContentText>
                        UC: {row?.ucNumero}
                        <br />
                        Cliente: {row?.respNome}
                    </DialogContentText>
                </DialogContent>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirmDelete} color="primary">
                    Sim
                </Button>
                <Button onClick={onClose} color="primary">
                    Não
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationDialog;