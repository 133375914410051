const styles = theme => ({
  root: {
    flexGrow: 1,
    minWidth: 700,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  table: {
    minWidth: 700,
  },
  descricao: {
    marginRight: 4,
  },
  row: {
    padding: 0,
    paddingTop: 8,
    paddingBottom: 8,
  },
  row2: {
    padding: 0,
    paddingLeft: 8,
    paddingTop: 8,
    paddingBottom: 8,
  },
  cell: {
    textAlign: 'center',
  },
})

export default styles
