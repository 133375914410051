import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
    Grid,
    Icon,
    TextField,
    InputAdornment,
    IconButton,
    CssBaseline,
    Card,
    CardMedia,
    makeStyles,
    CardContent,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    CircularProgress,
} from '@material-ui/core'

import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import TableDataComp from '../../../../../../../../components/Table'
import useStyles from './styles'
import { findByIdVisitaApi, getVisitasFilterApi } from 'api/visitas'
import { DialogContentSimples } from 'components/Dialogs/Contents'
import { findAllVisitaImagemTipoAPI } from 'api/visita_imagem_tipo'
import { findByVisitaIdVisitaImagemApi } from 'api/visita_imagem'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { GridCloseIcon } from '@material-ui/data-grid'


const VisitaImgDetailTab = (props) => {
    const {
        usuario, push, params
    } = props
    const classes = useStyles()
    const [loading, setLoading] = useState(false); // Estado de carregamento

    const [payload, setPayload] = useState([])

    const OnSearchImageByVisitaId = () => {
        setLoading(true); // Defina o estado de carregamento como true antes da chamada da API
    
        findByVisitaIdVisitaImagemApi({ token: usuario?.auth?.token ?? '', id: params.id })
            .then((resp) => {
                setPayload(resp);
                setLoading(false); // Defina o estado de carregamento como false após receber a resposta
            })
            .catch((error) => {
                // Trate qualquer erro que possa ocorrer aqui
                console.error(error);
                setLoading(false); // Certifique-se de também definir o estado de carregamento como false em caso de erro
            });
    }
    const [visitaImageTipos, setVisitaImageTipos] = useState([]);
    const OnSearchVisitaImageTipo = () => {
        findAllVisitaImagemTipoAPI(usuario?.auth?.token ?? '')
            .then((resp) => {
                console.log(resp)
                setVisitaImageTipos(resp)
            })
    }
    function createImageUrl(base64Image) {
        const byteCharacters = atob(base64Image);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: 'image/jpeg' });
        return URL.createObjectURL(blob);
    }
    const [open, setOpen] = useState(false);
    const [selectedImageTipoId, setSelectedImageTipoId] = useState('');

    const handleOpenModal = (tipo) => {
        setSelectedImageTipoId(tipo);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };
    useEffect(() => {
        document.title = 'Online - Fotos'
        document.body.style = 'background-image: none;'
        OnSearchVisitaImageTipo()
        OnSearchImageByVisitaId()
    }, [])
    return (
        <div className={classes.root}>
            {loading ? (
                <div className={classes.loader}>
                    <CircularProgress />
                </div>
            ) : payload.length > 0 ? (
                <Card className={classes.card}>
                    <Grid container spacing={2}>
                        {Array.from({ length: visitaImageTipos.length }).map((_, index) => (
                            <Grid item xs={3} key={index}>
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {visitaImageTipos[index].descricao}
                                    </Typography>
                                    {payload
                                        .filter((imagem) => imagem.visitaImageTipoId === visitaImageTipos[index].id)
                                        .length > 0 ? (
                                        <Button color="primary" className={classes.button} onClick={() => handleOpenModal(visitaImageTipos[index].id)}>Visualizar</Button>
                                    ) : (
                                        <Button color="primary" disabled>Visualizar</Button>
                                    )}
                                </CardContent>
                            </Grid>
                        ))}
                    </Grid>
                    <Dialog open={open} onClose={handleCloseModal} maxWidth="md" fullWidth>
                        <Grid container justifyContent="space-between">
                            <DialogTitle>{visitaImageTipos
                                .filter((tipo) => tipo.id === selectedImageTipoId)
                                .find((tipo) => tipo)
                                ?.descricao}
                            </DialogTitle>
                            <IconButton onClick={handleCloseModal}>
                                <GridCloseIcon />
                            </IconButton>
                        </Grid>
                        <DialogContent>
                            <Carousel>
                                {/* Renderizar apenas as imagens do tipo selecionado */}
                                {payload
                                    .filter((imagem) => imagem.visitaImageTipoId === selectedImageTipoId)
                                    .map((imagem) => (
                                        <Grid item key={imagem.id}>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                            }}>
                                                <Button
                                                    color="primary"
                                                    className={classes.button}
                                                    href={imagem.imagem}
                                                    download={`Imagem_${imagem.id}.jpg`}
                                                    style={{
                                                        textDecoration: 'none',
                                                        marginBottom: '10px',
                                                        justifyContent: 'left',
                                                        alignSelf: 'flex-start',
                                                    }}
                                                >
                                                    Baixar
                                                </Button>
                                            </div>
                                            <img
                                                src={imagem.imagem}
                                                alt={`Imagem ${imagem.id}`}
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    maxHeight: '500px',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        </Grid>
                                    ))}
                            </Carousel>
                        </DialogContent>
                    </Dialog>
                </Card>
            ) : (
                <div className={classes.noPhotos}>
                    <p>Nenhuma foto disponível</p>
                </div>
            )}
        </div>
    );
}

VisitaImgDetailTab.propTypes = {
    push: PropTypes.func.isRequired,
    onDialogOpen: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
    gestorUsuarios: state.gestorUsuarios,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        push: crrPush,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VisitaImgDetailTab)
