import { API_URL } from '..'
import { httpParseJson, httpValidState, httpValidErros } from '../../utils/http'

const ref = '/categorias'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const getCategorias = dto => fetch(`${API_URL}${ref}`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${dto.token}`,
  },
})
  .then(res => res.json())
  .then(httpValidState)
  .catch(httpValidErros)

// Área do Gestor
// ---------------
export const searchCategoriasApi = (dto) => {
  const { token, q } = dto
  return fetch(`${API_URL}${ref}/filter?q=${q}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}
