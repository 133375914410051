import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Icon,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import useStyles from './styles'

const MoreActionsComp = ({ row, actions }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleDisable(pAction, pRow) {
    if (_.isFunction(pAction)) {
      return pAction(pRow)
    }
    return pAction
  }

  function handleAction(pAction, pRow) {
    handleClose()
    pAction(pRow)
  }

  const classes = useStyles()
  return (
    <>
      <Tooltip title="AÇÕES">
        <IconButton
          size="small"
          aria-label="Editar"
          className={classes.margin}
          onClick={handleClick}
        >
          <Icon>more_vert</Icon>
        </IconButton>
      </Tooltip>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: 200,
          },
        }}
      >
        {actions.map(action => (
          <MenuItem
            disabled={handleDisable(action.disabled, row)}
            key={action.key}
            onClick={() => handleAction(action.action, row)}
          >
            <ListItemIcon>
              <Icon>{action.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={action.title} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

MoreActionsComp.propTypes = {
  row: PropTypes.shape().isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func,
      ]),
    }),
  ),
}

MoreActionsComp.defaultProps = {
  actions: {
    disabled: false,
  },
}

export default MoreActionsComp
