import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Icon,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Numeral from 'numeral'
import { SToC } from 'utils'

import useStyles from './styles'

function PedidoDetalhesTitulosComp(props) {
  const {
    titulos = [],
    actions = {},
  } = props
  const classes = useStyles()
  const { onDownloadBoleto } = actions
  return (
    <Paper className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Titulos
        </Typography>
      </div>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>EMISSÃO</TableCell>
            <TableCell>TITULO</TableCell>
            <TableCell>PARCELA</TableCell>
            <TableCell>VENCIMENTO</TableCell>
            <TableCell>BAIXA</TableCell>
            <TableCell align="right">VALOR</TableCell>
            <TableCell align="right">SALDO</TableCell>
            <TableCell align="center">DOWNLOAD</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {titulos.map((t) => {
            const { saldo = 0 } = t
            return (
              <TableRow>
                <TableCell>{SToC(t?.emissao ?? '')}</TableCell>
                <TableCell>{`${t?.titulo ?? ''}/${t?.prefixo ?? ''}`}</TableCell>
                <TableCell>{t?.parcela ?? ''}</TableCell>
                <TableCell>{SToC(t?.vencimento ?? '')}</TableCell>
                <TableCell>{SToC(t?.baixa ?? '')}</TableCell>
                <TableCell align="right">{`R$ ${Numeral(t?.valor ?? 0).format('0,0.00')}`}</TableCell>
                <TableCell align="right">{`R$ ${Numeral(saldo).format('0,0.00')}`}</TableCell>
                <TableCell align="center">
                  <IconButton
                    disabled={saldo === 0}
                    onClick={() => onDownloadBoleto({
                      titulo: t.titulo, prefixo: t.prefixo, parcela: t.parcela,
                    })}
                  >
                    <Icon>save_alt</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}

PedidoDetalhesTitulosComp.propTypes = {
  titulos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  actions: PropTypes.shape(),
}

PedidoDetalhesTitulosComp.defaultProps = {
  actions: {},
}

export default PedidoDetalhesTitulosComp
