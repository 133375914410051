import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
  Typography,
  Grid,
  Fab,
  Icon,
  Tooltip,
  TextField,
  InputAdornment,
  IconButton,
  CssBaseline,
  Chip,
  Badge,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import _ from 'lodash'

import { searchClienteApi } from 'api/cliente'

import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import { Actions as CrmContatosActions } from 'store/ducks/crm/contato'
import GestorHeaderComp from 'components/Gestor/Header'
import GestorDrawerComp from 'components/Gestor/Drawer'
import TableDataComp from 'components/Table'
import useStyles from './styles'
import { Pagination } from '@material-ui/lab'
import { NotificationManager } from 'react-notifications'

const GestorCrmProspectListPage = (props) => {
  const {
    usuario,
    push,
  } = props;
  const classes = useStyles();
  // State
  const [listFilter, setListFilter] = useState([]);
  const [loading, setLoading] = useState(false); // Estado de carregamento

  const [filter, setFilter] = useState({
    page: 0, // Inicialize com 0 em vez de `currentPage - 1`
    rows: 15,
    search: '',
    isProspects: true,
  });
  const [payload, setPayload] = useState([]);
  const tableCols = [
    {
      key: '',
      title: 'STATUS',
      format: (row) => {
        if (row.crmProspectStatusDescription !== "LEAD") {
          return (
            <Chip
              clickable
              size="small"
              label={row.crmProspectStatusDescription}
              onClick={() => push(`/gestor/crm/prospects/detail/${row.id}`)}
            />
          );
        } else {
          return (
            <Chip
              clickable
              size="small"
              label={row.crmProspectStatusDescription}
              onClick={() =>
                NotificationManager.warning("Cliente do tipo LEAD não pode acessar essa página")
              }
            />
          );
        }
      },

    },
    { key: 'nome', title: 'NOME' },
    { key: 'cgc', title: 'CPF' },
    { key: 'telefone', title: 'TELEFONE' },
  ];
  const tableActionsList = [
    {
      key: 'detalhe',
      title: 'Detalhe',
      icon: 'more',
      action: (row) => {
        if (row.crmProspectStatusDescription !== "LEAD") {
          // Redirecionar para a rota específica para crm/prospects/detail/{row.id}
          push(`/gestor/crm/prospects/detail/${row.id}`);
        } else {
          // Exibir uma notificação de aviso
          NotificationManager.warning("Cliente do tipo LEAD não pode acessar essa página");
        }
      },
    },
  ];

  const onIptSearchChange = (evt) => {
    evt.persist();
    setFilter((s) => ({ ...s, search: evt?.target?.value?.toUpperCase() ?? '' }));
  };
  const [currentPage, setCurrentPage] = useState(1);

  const onSearchClients = (page = 1) => {
    setLoading(true); // Set loading state to true before the API call
    const updatedFilter = { ...filter, page: page - 1, crmProspectStatusId: selectedOption ?? 0 };
    if (page == 1 && currentPage != page) {
      setCurrentPage(page);
    } else {
      searchClienteApi({ token: usuario?.auth?.token ?? '', query: updatedFilter })
        .then((resp) => {
          setPayload(resp);
          setListFilter(resp.data.filter((item) => item.crmProspectStatusCode !== '8'));
        })
        .catch((error) => {
          // Handle the error here if needed
        })
        .finally(() => {
          setLoading(false); // Set loading state to false after the API call
        });
    }
  };

  useEffect(() => {
    document.title = 'Online - Prospects';
    document.body.style = 'background-image: none;';
    onSearchClients(currentPage); // Chame a função diretamente
  }, [currentPage]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const [selectedOption, setSelectedOption] = useState('0');

  const handleCheckboxChange = (event) => {
    setSelectedOption(event.target.value);

  };

  useEffect(() => {
    const fetchData = async () => {
      setCurrentPage(1);
      await setCurrentPage(1); // Aguarda a finalização de setCurrentPage(1)
      onSearchClients();
    };

    fetchData();
  }, [selectedOption]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" component="h2">
                  Prospects
                </Typography>
                <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                  {/* Checkbox "TODOS" */}
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedOption === '0'}
                          onChange={handleCheckboxChange}
                          value="0"
                        />
                      }
                      label="TODOS"
                    />
                  </Grid>

                  {/* Checkbox "CLASSIFICADOS" */}
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedOption === '1'}
                          onChange={handleCheckboxChange}
                          value="1"
                        />
                      }
                      label="CLASSIFICADOS"
                    />
                  </Grid>

                  {/* Checkbox "DESENVOLVIMENTO" */}
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedOption === '2'}
                          onChange={handleCheckboxChange}
                          value="2"
                        />
                      }
                      label="DESENVOLVIMENTO"
                    />
                  </Grid>

                  {/* Checkbox "LEAD" */}
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedOption === '8'}
                          onChange={handleCheckboxChange}
                          value="8"
                        />
                      }
                      label="LEAD"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* Menus */}
              <Grid item xs={12} md={6}>
                <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <TextField
                      id="outlined-adornment-password"
                      className={classNames(classes.margin, classes.textField)}
                      variant="outlined"
                      label="Pesquisar"
                      value={filter?.search ?? ''}
                      onChange={onIptSearchChange}
                      onKeyPress={(evt) => {
                        if (evt.charCode === 13) {
                          onSearchClients()
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Pesquisar"
                              onClick={onSearchClients}
                            >
                              <Icon>search</Icon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Verifica o estado de carregamento e renderiza o spinner ou o conteúdo */}
          {loading ? (
            <div className={classes.loadingSpinner}>
              <CircularProgress size={40} />
            </div>
          ) : (
            <TableDataComp
              size="small"
              rows={payload?.data}
              cols={tableCols}
              actions={tableActionsList}
            />
          )}
          <Grid item xs={12} md={12}>
            <div id="mui-pagination">
              <Pagination
                count={payload?.total ?? 0}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                className={classes.pagination}
              />
            </div>
          </Grid>
        </Grid>

      </main>
    </div>
  )
}

GestorCrmProspectListPage.propTypes = {
  usuario: PropTypes.shape().isRequired,
  crmContatos: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
  onFilterCrmContatos: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  crmContatos: state.crmContatos,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...CrmContatosActions,
    push: crrPush,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GestorCrmProspectListPage)
