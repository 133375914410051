import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Paper, TextField, Grid, FormControl, InputLabel, Select, MenuItem, IconButton, Icon,
} from '@material-ui/core'
import _ from 'lodash'
import { Save } from '@material-ui/icons'

import ButtonLoadingComp from 'components/Buttons/ButtonLoading'
import useStyles from './styles'

const DadosProspectTabContent = (props) => {
  const {
    loading,
    payload,
    onSave,
    agenteTipo
  } = props
  const [form, setForm] = useState()
  const [showPassword, setShowPassword] = useState(false)
  const [showRePassword, setShowRePassword] = useState(false)
  const [textValidation, setTextValidation] = useState({
    password: '',
  })
  const onUpdateField = (data) => {
    setForm(s => ({ ...s, ...data }))
  }

  const classes = useStyles()

  useEffect(() => {
    if (!_.isEmpty(form?.usuarioSenha)
      && !_.isEmpty(form?.usuarioReSenha)
      && form?.usuarioSenha !== form?.usuarioReSenha) {
      setTextValidation(s => ({ ...s, password: 'A senha não são iguais' }))
    } else {
      setTextValidation(s => ({ ...s, password: '' }))
    }
  }, [form])

  useEffect(() => {
    console.log(payload)
    setForm(payload)
  }, [payload])

  return (
    <Paper className={classes.paper} elevation={1}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="small"
            id="code"
            label="Matrícula:"
            margin="normal"
            variant="outlined"
            value={(form?.code ?? '').toUpperCase()}
            inputProps={{
              maxlength: 10,
            }}
            className={classes.textField}
            onChange={evt => onUpdateField({ code: evt.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            id="usuarioNome"
            label="Nome:"
            margin="normal"
            variant="outlined"
            value={(form?.usuarioNome ?? '').toUpperCase()}
            className={classes.textField}
            onChange={evt => onUpdateField({ usuarioNome: evt.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            size="small"
            id="usuarioCpf"
            label="CPF/CNPJ:"
            margin="normal"
            inputProps={{
              maxlength: 14,
            }}
            variant="outlined"
            value={(form?.usuarioCpf ?? '').toUpperCase()}
            className={classes.textField}
            onChange={evt => onUpdateField({ usuarioCpf: evt.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            id="usuarioEmail"
            label="E-mail"
            margin="normal"
            variant="outlined"
            value={(form?.usuarioEmail ?? '').toUpperCase()}
            className={classes.textField}
            onChange={evt => onUpdateField({ usuarioEmail: evt.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            size="small"
            id="usuarioCelular"
            label="Celular"
            margin="normal"
            variant="outlined"
            value={(form?.usuarioCelular ?? '').toUpperCase()}
            className={classes.textField}
            onChange={evt => onUpdateField({ usuarioCelular: evt.target.value.toUpperCase() })}
            inputProps={{
              maxlength: 11,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth size="small" className={classes.textField} variant="outlined" margin="normal">
            <InputLabel htmlFor="status">Status</InputLabel>
            <Select
              fullWidth
              label="Status"
              value={form?.status ?? ''}
              onChange={evt => onUpdateField({ status: evt.target.value })}
              inputProps={{
                name: 'status',
                id: 'status',
              }}
            >
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              <MenuItem value="P">
                PENDENTE
              </MenuItem>
              <MenuItem value="L">
                LIBERADO
              </MenuItem>
              <MenuItem value="B" disabled={form?.isAdd ?? false}>
                BLOQUEADO
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            size="small"
            id="usuarioSenha"
            label="Senha"
            margin="normal"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            value={(form?.usuarioSenha ?? '')}
            className={classes.textField}
            onChange={evt => onUpdateField({ usuarioSenha: evt?.target?.value })}
            InputProps={{
              endAdornment: (
                <IconButton
                  size="small"
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            size="small"
            id="usuarioReSenha"
            label="Confirmação da Senha"
            margin="normal"
            variant="outlined"
            type={showRePassword ? 'text' : 'password'}
            autoComplete="new-password"
            value={(form?.usuarioReSenha ?? '')}
            className={classes.textField}
            onChange={evt => onUpdateField({ usuarioReSenha: evt?.target?.value })}
            InputProps={{
              endAdornment: (
                <IconButton
                  size="small"
                  aria-label="toggle password visibility"
                  onClick={() => setShowRePassword(!showRePassword)}
                >
                  {showRePassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
                </IconButton>
              ),
            }}
            error={!_.isEmpty(textValidation?.password ?? '')}
            helperText={textValidation?.password ?? ''}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth size="small" className={classes.textField} variant="outlined" margin="normal">
            <InputLabel htmlFor="tipoAgente">Tipo do Agente</InputLabel>
            <Select
              fullWidth
              label="Tipo do Agente"
              value={form?.crmAgenteTipo?.id ?? ''}
              onChange={evt => onUpdateField(
                { crmAgenteTipo: { id: evt.target.value }}
                )}
              inputProps={{
                name: 'agenteTipo',
                id: 'agenteTipo',
              }}
            >
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {Array.isArray(agenteTipo) && agenteTipo.map((tipo) => (
                <MenuItem key={tipo.id} value={tipo.id}>
                  <em>{tipo.descricao} / {tipo.tipo}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={6}>
            <Grid item>
              <ButtonLoadingComp
                loading={loading}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => onSave(form)}
              >
                <Save className={classes.leftIcon} />
                Salvar
              </ButtonLoadingComp>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

DadosProspectTabContent.propTypes = {
  loading: PropTypes.bool,
  payload: PropTypes.shape().isRequired,
  onSave: PropTypes.func.isRequired,
}

DadosProspectTabContent.defaultProps = {
  loading: false,
}

export default DadosProspectTabContent
