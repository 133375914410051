import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import config from 'react-global-configuration'
import { matchSorter } from 'match-sorter'
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  CssBaseline,
  Icon,
  CircularProgress,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { push } from 'connected-react-router'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'

// Api's
import { getUserDataApi } from '../../../api/usuario'
import { syncVendedorClientesERPApi } from '../../../api/vendedor'
import { syncClienteProtheusApi, syncProdutosProtheusApi } from '../../../api/cliente'

// Actions
import { Actions as UsuarioActions } from '../../../store/ducks/usuario'
import { Actions as ConfigActions } from '../../../store/ducks/config'
import { Actions as ProdutoActions } from '../../../store/ducks/produto'
import { Actions as DialogActions } from '../../../store/ducks/dialog'

// Styles
import useStyles from './styles'

const SelecionarClientePage = (props) => {
  const {
    auth,
    usuario, // : { cliente, clientes = [] },
    pushNav,
    onFieldUpdate,
    onLoadLimiteCreditoCliente,
    onLoadProdutosCategoria,
    getParametro,
    onDialogOpen,
    onDialogClose,
  } = props
  const configClienteSku = config.get('cliente.sku')
  const classes = useStyles()
  const [loadingClientes, setLoadingClientes] = useState(false)
  const handleChange = (evt, newValue) => onFieldUpdate({ cliente: newValue })
  const vendedorEmpDefault = getParametro('VENDEDOR_EMP_DEFAULT', '03')
  const vendedorFilDefault = getParametro('VENDEDOR_FIL_DEFAULT', '01')
  const vendedorTplDefault = getParametro('VENDEDOR_TPL_DEFAULT', '0')
  const emailSuporte0 = getParametro('EMAIL_SUPORTE0', 'suporte@semarsistemas.com.br')

  const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: ['razao', 'cgc'] })

  const handleAtualizarClientes = () => {
    // token, empresaCod, filialCod, tpl, cgc, listclientes,
    setLoadingClientes(true)
    syncVendedorClientesERPApi({
      token: auth.token,
      empresa: vendedorEmpDefault,
      filial: vendedorFilDefault,
      tpl: vendedorTplDefault,
      cgc: usuario.cpf,
      // listclientes: _.isArray(usuario.clientes) ? usuario.clientes.map(c => c.cgc) : [],
      listclientes: [],
    }).then((resp) => {
      setLoadingClientes(false)
      if (resp && _.isArray(resp.data)) {
        getUserDataApi({ ...auth }).then((data) => {
          if (data) {
            const dto = {
              clientes: _.isArray(data.clientes) ? data.clientes : [],
            }
            onFieldUpdate(dto)
          }
        })
      }
      if (resp && _.isArray(resp.errors)) {
        onDialogOpen({
          size: 'md',
          title: 'Atenção!',
          content: (
            <>
              <p>
                <span>
                  Ocorreu uma ou mais erros no processo de sincronização de seus clientes o
                  suporte já foi informado, verifique abaixo:
                </span>
                <br />
                <small>
                  <i>{`Favor entrar em contato com suporte atravéz do e-mail: ${emailSuporte0}`}</i>
                </small>
              </p>
              <hr />
              <ul>
                {resp.errors.map(err => (
                  <li>{err.errorMessage}</li>
                ))}
              </ul>
            </>
          ),
          actions: [
            {
              key: 1,
              title: 'Ok',
              action: () => onDialogClose(),
            },
          ],
        })
      }
    })
  }
  const handleContinuar = () => {
    // alert(JSON.stringify(usuario.cliente))
    if (!_.isEmpty(usuario.cliente)) {
      const unidade = usuario.cliente.unidades[0]
      onFieldUpdate({
        unidade,
        unidades: usuario.cliente.unidades,
        limiteCredito: {
          limite: 0,
        },
      })
      // Sync dados do cliente
      const dtoSyncProtheus = {
        token: auth.token,
        empresaCod: unidade.empresaCod,
        filialCod: unidade.filialCod,
        clienteCod: usuario.cliente.codigo,
        clienteLoja: usuario.cliente.loja,
      }
      const smTemplate = getParametro('SM_TEMPLATE', 0)
      if (smTemplate === 0) {
        syncProdutosProtheusApi(dtoSyncProtheus).then(() => {
          // Faz a carga dos produtos depois de realizar a SYNC com o ERP
          onLoadProdutosCategoria()
        })
      }
      if (smTemplate === 1) {
        syncClienteProtheusApi(dtoSyncProtheus).then(() => {
          // Faz a carga dos produtos depois de realizar a SYNC com o ERP
          onLoadProdutosCategoria()
        })
      }
      setTimeout(() => {
        onLoadLimiteCreditoCliente(props.usuario)
      }, 200)
      pushNav('/')
    } else {
      NotificationManager.warning(
        'Para continuar você deve selecionar um cliente.',
        'Atenção',
        8000,
      )
    }
  }

  useEffect(() => {
    const bg = require(`../../../assets/imagens/login/${configClienteSku ?? 'gvb'}-login-bg-page.png`)
    document.title = 'Siryus Online - Selecionar Cliente'
    document.body.style = `background-image: url(${bg}); background-size:100% 100%; background-repeat:no-repeat; background-position: center;`
    document.documentElement.style = 'height: 100%'
    handleAtualizarClientes()
    // setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h5" component="h2" className={classes.title} gutterBottom>
                Selecione um cliente
              </Typography>
              <Autocomplete
                id="combo-box-clientes"
                fullWidth
                options={usuario.clientes}
                getOptionLabel={option => `[CNPJ:${option.cgc}] ${option.razao}`}
                filterOptions={filterOptions}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={handleChange}
                renderInput={params => (
                  <TextField {...params} fullWidth label="Clientes" variant="outlined" />
                )}
                renderOption={(option) => {
                  if (option.status === 'B') {
                    return (
                      <div style={{ display: 'grid', color: '#FF0000' }}>
                        <span>
                          {`${option.razao} `}
                          <strong>(INATIVO)</strong>
                        </span>
                        <small>{`CNPJ: ${option.cgc}`}</small>
                      </div>
                    )
                  }
                  return (
                    <div style={{ display: 'grid' }}>
                      <span>
                        {option.razao}
                      </span>
                      <small>{`CNPJ: ${option.cgc}`}</small>
                    </div>
                  )
                }}
              />
            </CardContent>
            <CardActions disableSpacing className={classes.actions}>
              <Button
                disabled={loadingClientes}
                variant="contained"
                size="small"
                onClick={handleAtualizarClientes}
                startIcon={<Icon>autorenew</Icon>}
                endIcon={
                  loadingClientes && (
                    <CircularProgress size={25} className={classes.buttonProgress} />
                  )
                }
              >
                Atualizar Clientes
              </Button>
              <Button
                disabled={loadingClientes}
                variant="contained"
                size="small"
                color="primary"
                onClick={handleContinuar}
              >
                Continuar
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

SelecionarClientePage.propTypes = {
  auth: PropTypes.shape().isRequired,
  usuario: PropTypes.shape().isRequired,
  pushNav: PropTypes.func.isRequired,
  onFieldUpdate: PropTypes.func.isRequired,
  onLoadLimiteCreditoCliente: PropTypes.func.isRequired,
  getParametro: PropTypes.func.isRequired,
  onLoadProdutosCategoria: PropTypes.func.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  auth: state.usuario.usuario.auth,
  usuario: state.usuario.usuario,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...UsuarioActions,
    ...ConfigActions,
    ...ProdutoActions,
    ...DialogActions,
    pushNav: push,
  },
  dispatch,
)

export default connect(mapStateToProps, mapDispatchToProps)(SelecionarClientePage)
