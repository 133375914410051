import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Menu,
  MenuItem,
  Fade,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
} from '@material-ui/core'
import AccountBalance from '@material-ui/icons/AccountBalance'
import _ from 'lodash'

import { Actions as UsuarioActions } from '../../../store/ducks/usuario'
import { Actions as ProdutoActions } from '../../../store/ducks/produto'
import { Actions as CarrinhoActions } from '../../../store/ducks/carrinho'
import { Actions as ConfigActions } from '../../../store/ducks/config'
import { syncClienteProtheusApi, syncProdutosProtheusApi } from '../../../api/cliente'
import styles from './styles'

class TrocafFilialComp extends Component {
  static propTypes = {
    gestor: PropTypes.bool,
    classes: PropTypes.shape().isRequired,
    token: PropTypes.string.isRequired,
    unidade: PropTypes.shape().isRequired,
    cliente: PropTypes.shape(),
    unidades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    itens: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onSelectUnidade: PropTypes.func.isRequired,
    onDeleteCarrinho: PropTypes.func.isRequired,
    onResetBusca: PropTypes.func.isRequired,
    getParametro: PropTypes.func.isRequired,
  }

  static defaultProps = {
    gestor: false,
    cliente: {},
  }

  state = {
    anchorEl: null,
    dialogAviso: false,
    dialogTrocandoFilial: false,
    unidadeSelected: {},
  }

  handleOpenCloseListUnidades = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleOpenCloseDialog = (payload) => {
    this.setState({ dialogAviso: payload })
  }

  handleCloseTrocandoFilial = (payload) => {
    this.setState({ dialogTrocandoFilial: payload })
  }

  handleSelectUnidade = (event, unidadeSelected) => {
    const {
      gestor,
      itens = [],
      onSelectUnidade,
      onResetBusca,
      unidade,
      token,
      cliente,
      getParametro,
    } = this.props

    this.setState({ anchorEl: null, unidadeSelected })
    if (gestor) {
      onSelectUnidade(unidadeSelected)
      return
    }

    if (itens.length > 0 && unidadeSelected.id !== unidade.id) {
      this.handleOpenCloseDialog(true)
    } else if (unidadeSelected.id !== unidade.id) {
      // Sync dados do cliente
      const dtoSyncProtheus = {
        token,
        empresaCod: unidadeSelected.empresaCod,
        filialCod: unidadeSelected.filialCod,
        clienteCod: cliente.codigo,
        clienteLoja: cliente.loja,
      }
      this.handleCloseTrocandoFilial(true)
      const smTemplate = getParametro('SM_TEMPLATE', 0)
      if (smTemplate === 0) {
        syncProdutosProtheusApi(dtoSyncProtheus).then(() => {
          this.handleCloseTrocandoFilial(false)
          this.handleOpenCloseDialog(false)
          this.setState({ anchorEl: null, unidadeSelected: {} })
          onSelectUnidade(unidadeSelected)
          onResetBusca()
        })
      }
      if (smTemplate === 1) {
        syncClienteProtheusApi(dtoSyncProtheus).then(() => {
          this.handleCloseTrocandoFilial(false)
          this.handleOpenCloseDialog(false)
          this.setState({ anchorEl: null, unidadeSelected: {} })
          onSelectUnidade(unidadeSelected)
          onResetBusca()
        })
      }
    } else {
      onSelectUnidade(unidadeSelected)
    }
  }

  handleConfirmSelectUnidade = (unidadeSelected) => {
    const {
      onSelectUnidade, onDeleteCarrinho, onResetBusca, token, cliente, getParametro,
    } = this.props
    // Sync dados do cliente
    const dtoSyncProtheus = {
      token,
      empresaCod: unidadeSelected.empresaCod,
      filialCod: unidadeSelected.filialCod,
      clienteCod: cliente.codigo,
      clienteLoja: cliente.loja,
    }
    this.handleCloseTrocandoFilial(true)
    const smTemplate = getParametro('SM_TEMPLATE', 0)
    if (smTemplate === 0) {
      syncProdutosProtheusApi(dtoSyncProtheus).then(() => {
        this.handleCloseTrocandoFilial(false)
        this.handleOpenCloseDialog(false)
        this.setState({ anchorEl: null, unidadeSelected: {} })
        onSelectUnidade(unidadeSelected)
        onResetBusca()
      })
    }
    if (smTemplate === 1) {
      syncClienteProtheusApi(dtoSyncProtheus).then(() => {
        this.handleCloseTrocandoFilial(false)
        this.handleOpenCloseDialog(false)
        this.setState({ anchorEl: null, unidadeSelected: {} })
        onSelectUnidade(unidadeSelected)
        onDeleteCarrinho()
        onResetBusca()
      })
    }
  }

  render() {
    const {
      anchorEl, dialogAviso, dialogTrocandoFilial, unidadeSelected,
    } = this.state
    const {
      classes, unidade, unidades, itens = [],
    } = this.props
    const open = Boolean(anchorEl)
    return (
      <Fragment>
        <Button
          aria-owns={open ? 'fade-menu-unidades-header' : undefined}
          aria-haspopup="true"
          className={classes.buttonDark}
          onClick={this.handleOpenCloseListUnidades}
        >
          <AccountBalance style={{ marginRight: 5 }} />
          {unidade && unidade.descricao !== undefined ? unidade.descricao : ""}
        </Button>
        <Menu
          id="fade-menu-unidades-header"
          anchorEl={anchorEl}
          open={open}
          // onClose={this.handleClose}
          TransitionComponent={Fade}
        >
          {_.orderBy(unidades, ['descricao'], ['asc']).map(u => (
            <MenuItem key={u.id} onClick={e => this.handleSelectUnidade(e, u)}>
              {u.descricao}
            </MenuItem>
          ))}
        </Menu>
        <Dialog
          open={dialogAviso}
          onClose={() => this.handleOpenCloseDialog(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Atenção!</DialogTitle>
          <DialogContent>
            <Typography variant="body1" component="span" inline>
              Existe
            </Typography>
            <Typography variant="button" inline>{` "${itens.length}" item(ns) `}</Typography>
            <Typography variant="body1" component="span" inline>
              no caminhão da unidade
            </Typography>
            <Typography variant="button" inline>
              {` ${unidade && unidade.descricao ? unidade.descricao : ""} `}
            </Typography>
            <Typography variant="body1" component="span" inline>
              ao trocar para a unidade
            </Typography>
            <Typography variant="button" inline>
              {` ${unidadeSelected.descricao} `}
            </Typography>
            <Typography variant="body1" component="span" inline>
              seu caminhão sera ZERADO!
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleOpenCloseDialog(false)} color="primary" autoFocus>
              CANCELAR
            </Button>
            <Button
              onClick={() => this.handleConfirmSelectUnidade(unidadeSelected)}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={dialogTrocandoFilial}
          // onClose={this.handleCloseTrocandoFilial}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Aguarde...</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Estamos preparando a nova filial ${unidadeSelected.descricao}.`}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  token: state.usuario.usuario.auth.token,
  cliente: state.usuario.usuario.cliente,
  unidade: state.usuario.usuario.unidade,
  unidades: state.usuario.usuario.unidades,
  itens: state.carrinho.itens,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...UsuarioActions,
    ...ProdutoActions,
    ...CarrinhoActions,
    ...ConfigActions,
  },
  dispatch,
)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TrocafFilialComp),
)
