import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import classNames from 'classnames'
import {
  Typography,
  CssBaseline,
  Grid,
  Fab,
  Icon,
  Tooltip,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core'

import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as DialogActions } from '../../../../store/ducks/dialog'
import { Actions as GestorProdutosActions } from '../../../../store/ducks/gestor/produtos'
import GestorHeaderComp from '../../../../components/Gestor/Header'
import GestorDrawerComp from '../../../../components/Gestor/Drawer'
import TableDataComp from '../../../../components/Table'
import useStyles from './styles'
import { deleteProdutoByIdApi } from 'api/produtos'
import { NotificationManager } from 'react-notifications'


const GestorProdutosListagemPage = (props) => {
  const {
    usuario, gestorProdutos, push, onSearchProdutos,
  } = props
  const classes = useStyles()
  // State
  const [iptSearch, setIptSearch] = useState('')
  const tableCols = [
    { key: 'produtoSku', title: 'CÓDIGO' },
    { key: 'produtoDesc', title: 'DESCRIÇÃO' },
    { key: 'produtoDescComplementar', title: 'DESCRIÇÃO COMPLEMENTAR' }
  ]
  const tableActionsList = [
    /*
    {
      key: 'bloquear',
      title: 'Bloquear',
      icon: 'clear',
      action: row => null,
    },
    */
    {
      key: 'detalhes',
      title: 'Detalhes',
      icon: 'list',
      action: row => push(`produtos/${row.produtoId}`),
    },
    {
      key: 'deletar',
      title: 'Excluir',
      icon: 'delete',
      action: row => deleteProdutoById(row.produtoId),
    }
  ]
  const deleteProdutoById = (id) => {
    deleteProdutoByIdApi({ token: (usuario?.auth?.token || ''), id: id })
      .then((res) => {
        onSearchProdutos();
        NotificationManager.success('O produto foi EXCLUÍDO com sucesso!', 'Sucesso!', 4000);
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error('Houve um erro ao tentar EXCLUIR o produto!', 'Erro!', 4000);
      });
  }
  useEffect(() => {
    document.title = 'Online - Gestor | Produtos'
    document.body.style = 'background-image: none;'
    onSearchProdutos()
  }, [])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" component="h2">
                  Produtos
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container direction="row" justifyContent="flex-end">
                  <TextField
                    id="outlined-adornment-password"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label="Pesquisar"
                    value={iptSearch}
                    onChange={evt => setIptSearch(evt.target.value.toUpperCase())}
                    onKeyPress={(evt) => {
                      if (evt.charCode === 13) {
                        onSearchProdutos(iptSearch)
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Pesquisar"
                            onClick={() => onSearchProdutos(iptSearch)}
                          >
                            <Icon>search</Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Tooltip title="Adicionar">
                    <Fab
                      color="primary"
                      aria-label="Add"
                      className={classes.fab}
                      onClick={() => push('produtos/add')}
                    >
                      <Icon>add</Icon>
                    </Fab>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <TableDataComp
              // loading={loading.aprovacoes}
              loading={gestorProdutos.loading.list}
              rows={gestorProdutos.list}
              cols={tableCols}
              actions={tableActionsList}
            />
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

GestorProdutosListagemPage.propTypes = {
  usuario: PropTypes.shape().isRequired,
  gestorProdutos: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
  onSearchProdutos: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  gestorProdutos: state.gestorProdutos,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...GestorProdutosActions,
    push,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GestorProdutosListagemPage)
