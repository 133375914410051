import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from '..'

const ref = '/agenda_tipo'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}



/**
 *
 * @param {String} dto - token
 */
export const findAllAgendaTipoApi = (token) => {
  return fetch(`${API_URL}${ref}/`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

