import { push } from 'connected-react-router'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'

import { Actions as DialogActions } from '../dialog'
import {
  searchProdutosApi,
  getProdutoByIdApi,
  AddProdutoCategoriaApi,
  DeleteProdutoCategoriaApi,
  UpdateProdutoGestorApi,
  AddProdutoApi,
  EditProdutoApi,
} from '../../../api/produtos'
import { searchCategoriasApi } from '../../../api/categorias'

// State Gestor/Produtos
const initialState = {
  loading: {
    list: false,
    produto: false,
  },
  list: [],
  produto: {
    produtoId: 0,
    produtoSku: '',
    produtoUM: '',
    produtoPeso: 0.0,
    produtoDesc: '',
    produtoDescComplementar:'',
    produtoQuant: 0.0,
    produtoQuantMin: 0.0,
    categorias: [
      // { id: 5, sku: '105', descricao: 'CPV',},
    ],
    imagens: [],
  },
}

// Actions Types
export const Types = {
  GP_LOADING: 'gestorProdutos/GP_LOADING',
  GP_UPDATE_LIST: 'gestorProdutos/GP_UPDATE_LIST',
  GP_UPDATE_PRODUTO: 'gestorProdutos/GP_UPDATE_PRODUTO',
  GP_UPDATE_PRODUTO_FIELD: 'gestorProdutos/GP_UPDATE_PRODUTO_FIELD',
}

// Reducers
export default function gestorProdutosReducers(state = initialState, action) {
  switch (action.type) {
    case Types.GP_LOADING:
      return { ...state, loading: { ...state.loading, ...action.payload } }
    case Types.GP_UPDATE_LIST:
      return { ...state, list: action.payload }
    case Types.GP_UPDATE_PRODUTO:
      return { ...state, produto: action.payload }
    case Types.GP_UPDATE_PRODUTO_FIELD:
      return { ...state, produto: { ...state.produto, ...action.payload } }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  onLoading: payload => (dispatch) => {
    dispatch({ type: Types.GP_LOADING, payload })
  },
  onUpdateProdutoField: payload => (dispatch) => {
    dispatch({ type: Types.GP_UPDATE_PRODUTO_FIELD, payload })
  },
  onResetProduto: () => (dispatch) => {
    dispatch({ type: Types.GP_UPDATE_PRODUTO, payload: initialState.produto })
  },
  onSearchProdutos: (q = '') => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    dispatch(Actions.onLoading({ list: true }))
    setTimeout(() => {
      searchProdutosApi({ token, q }).then((payload) => {
        dispatch(Actions.onLoading({ list: false }))
        if (payload && _.isArray(payload)) {
          console.log(payload)
          payload = payload.sort((a, b) => {
            if (a.produtoDesc < b.produtoDesc) {
              return -1;
            }
            if (a.produtoDesc > b.produtoDesc) {
              return 1;
            }
            return 0;
          });
          dispatch({ type: Types.GP_UPDATE_LIST, payload })
        } else {
          dispatch({ type: Types.GP_UPDATE_LIST, payload: [] })
        }
      })
    }, 1000)
  },
  onLoadDadosProduto: id => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    dispatch(Actions.onLoading({ produto: true }))
    setTimeout(() => {
      getProdutoByIdApi({ token, id }).then((payload) => {
        dispatch(Actions.onLoading({ produto: false }))
        if (payload && _.isObject(payload)) {
          dispatch({ type: Types.GP_UPDATE_PRODUTO, payload })
        } else {
          dispatch({ type: Types.GP_UPDATE_PRODUTO, payload: initialState.produto })
        }
      })
    }, 200)
  },
  onSearchCategorias: (q = '') => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
    } = getState()
    dispatch(DialogActions.onSearchGenericLoading(true))
    searchCategoriasApi({ token, q }).then((payload) => {
      dispatch(DialogActions.onSearchGenericLoading(false))
      if (payload && _.isArray(payload)) {
        dispatch(
          DialogActions.onSearchGenericLoadData({
            page: 1,
            pages: 1,
            rows: payload.length,
            itens: payload,
          }),
        )
      } else {
        // Não retornou nada... o form é zerado!
        dispatch(DialogActions.onSearchGenericClean())
      }
    })
  },
  onAddProdutoCategoria: categoria => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
      gestorProdutos: { produto },
    } = getState()
    AddProdutoCategoriaApi({ token, dto: { pk: produto.produtoId, fk: categoria.id } }).then(
      (payload) => {
        if (payload && payload.status === 201) {
          NotificationManager.success('Categoria adicionada com sucesso.', 'Atenção:', 8000)
          dispatch(Actions.onLoadDadosProduto(produto.produtoId))
        } else {
          NotificationManager.warning(
            'Não foi possível adicionar a categoria ao produto.',
            'Atenção:',
            8000,
          )
        }
      },
    )
  },
  onDeleteProdutoCategoria: categoria => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
      gestorProdutos: { produto },
    } = getState()
    DeleteProdutoCategoriaApi({ token, dto: { pk: produto.produtoId, fk: categoria.id } }).then(
      (payload) => {
        if (payload && payload.status === 204) {
          NotificationManager.success('Categoria removida com sucesso.', 'Atenção:', 8000)
          dispatch(Actions.onLoadDadosProduto(produto.produtoId))
        } else {
          NotificationManager.warning(
            'Não foi possível remover a categoria ao produto.',
            'Atenção:',
            8000,
          )
        }
      },
    )
  },
  onSaveProduto: () => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth: { token },
        },
      },
      gestorProdutos: {
        produto: {
          produtoId, produtoSku, produtoDesc, produtoUM, produtoPeso, produtoQuantMin,produtoDescComplementar
        },
      },
    } = getState()
    const dto = {
      token,
      dto: {
        produtoId,
        produtoSku,
        produtoDesc,
        produtoUM,
        produtoPeso,
        produtoQuantMin,
        produtoDescComplementar
      },
      id: produtoId,
    }

    if (produtoId) {
      EditProdutoApi(dto).then((payload) => {
        if (payload && payload.status === 200) {
          NotificationManager.success('Dados do produto atualizado com sucesso!', 'Atenção:', 8000)
          dispatch(Actions.onLoadDadosProduto(produtoId))
        }
      })
    } else {
      AddProdutoApi(dto).then((payload) => {
        if (payload && payload.status === 200) {
          NotificationManager.success(
            'Produto adicionando atualizado com sucesso!',
            'Atenção:',
            8000,
          )
          dispatch(Actions.onLoadDadosProduto(payload.id))
        }
      })
    }
  },
}
