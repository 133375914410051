import React from 'react';
import { Dialog, DialogActions, DialogTitle, Button, Typography } from '@material-ui/core';

function DeleteVistoriaModal({ open, onClose, vistoria, onDelete }) {
    console.log(vistoria)
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogTitle>Excluir Vistoria</DialogTitle>
            <div style={{ padding: '20px' }}>
                <Typography variant="body1">
                    Tem certeza de que deseja excluir a vistoria com as seguintes informações?
                </Typography>

                {/* Exibindo informações adicionais */}
                <Typography variant="body2">OPORTUNIDADE: {vistoria?.oportunidadeId}</Typography>
                <Typography variant="body2">CLIENTE: {vistoria?.clienteNome}</Typography>
                <Typography variant="body2">PRODUTO: {vistoria?.produtoDescricao}</Typography>
            </div>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={onDelete} color="secondary">
                    Excluir
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteVistoriaModal;
