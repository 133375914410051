import React from 'react'
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CssBaseline,
  Grid,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Actions as UsuarioActions } from '../../../store/ducks/usuario'

import GestorDrawerComp from '../../../components/Gestor/Drawer'
import GestorHeaderComp from '../../../components/Gestor/Header'
import PageHeaderComp from '../../../components/PageHeader'
import DialogFiltroRelatorioComp from './components/dialogFiltroRelatorio'

import useStyles from './styles'

function RelatoriosPage(props) {
  const { usuario } = props
  const classes = useStyles()
  const [dialogFiltro, setDialogFiltro] = useState({ open: false })
  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <PageHeaderComp title="Relatórios" />
          <Grid item xs={4} md={4}>
            <Card>
              <CardActionArea onClick={() => setDialogFiltro(s => ({ ...s, open: true }))}>
                <CardHeader
                  title="Relatório de Visitas Realizadas"
                />
                <CardContent>
                  Este relatório proporciona uma listagem completa das Visitas Realizadas, incluindo informações adicionais sobre o cliente e a unidade consumidora.
                  <br />
                  <br />
                  * Oferecemos a possibilidade de filtragem por um intervalo de datas específico para a realização das visitas.
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        <DialogFiltroRelatorioComp
          open={dialogFiltro.open}
          title="Filtro"
          maxWidth="xs"
          onClose={() => setDialogFiltro(s => ({ ...s, open: false }))}
        />
      </main>
    </div>
  )
}

RelatoriosPage.propTypes = {
  usuario: PropTypes.shape().isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...UsuarioActions,
  },
  dispatch,
)

export default connect(mapStateToProps, mapDispatchToProps)(RelatoriosPage)
