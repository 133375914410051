import { getUserLocal, delUserLocal } from '../api/auth'
import { getConfigLs, delConfigLs } from '../api/config'
import { history } from '../store'

const validSession = (usuario, onLoginSuccess, onLoadConfig) => {
  if (!usuario || usuario.id === 0) {
    history.push('/login')
  }

  /*
  if (usuario.id === 0) {
    const usuarioLocal = getUserLocal()
    const configLs = getConfigLs()
    if (!!usuarioLocal && !!configLs) {
      onLoginSuccess(usuarioLocal)
      onLoadConfig(configLs)
      return true
    }
    history.push('/login')
    return false
  }
  return false
  */
}

export const clearSession = () => {
  delUserLocal()
  delConfigLs()
}

export default validSession
