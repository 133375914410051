import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import {
  Grid,
  Paper,
  Button,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import PinDropIcon from '@material-ui/icons/PinDrop'
import FilialIcon from '@material-ui/icons/AccountBalance'
import CircularProgress from '@material-ui/core/CircularProgress'

export const formExtratoFinanceiro = (props) => {
  const {
    cliente = {},
    clientes = [],
    unidade = {},
    unidades = [],
    dataInicial = '',
    dataFinal = '',
    gerando,
    onSelectCliente,
    onSelectUnidade,
    onDataChange,
    onFilterClick,
  } = props
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={6}>
        <Paper style={{ padding: 20 }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" component="h2">
                Filtro
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p">
                Você deve informar a data inicial e final para gerar o pdf contendo o extrato
                financeiro.
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="flex-end" style={{ marginTop: 10 }}>
            <Grid item xs={1} md={1}>
              <PinDropIcon />
            </Grid>
            <Grid item xs={11} md={11}>
              <FormControl fullWidth>
                <InputLabel htmlFor="cliente-cliente">Unidade Cliente</InputLabel>
                <Select
                  disabled
                  value={cliente}
                  // onChange={evt => onSelectCliente(evt)}
                  inputProps={{
                    id: 'cliente',
                    name: 'cliente',
                  }}
                >
                  <MenuItem key={cliente.id} value={cliente}>
                    {cliente.razao}
                  </MenuItem>
                  {/*
                    clientes.map(c => (
                    <MenuItem key={c.id} value={c}>
                      {c.razao}
                    </MenuItem>
                  ))
                  */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/*
            !!clientes && clientes.length > 1 && (

          )
          */}
          <Grid container alignItems="flex-end" style={{ marginTop: 10 }}>
            <Grid item xs={1} md={1}>
              <FilialIcon />
            </Grid>
            <Grid item xs={11} md={11}>
              <FormControl fullWidth>
                <InputLabel htmlFor="unidade">Unidade</InputLabel>
                <Select
                  value={unidade}
                  onChange={evt => onSelectUnidade(evt)}
                  inputProps={{
                    id: 'unidade',
                    name: 'unidade',
                  }}
                >
                  {unidades.map(f => (
                    <MenuItem key={f.id} value={f.id}>
                      {f.descricao}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="flex-end" style={{ marginTop: 10 }}>
            <Grid item xs={1} md={1}>
              <CalendarIcon />
            </Grid>
            <Grid item xs={11} md={11}>
              <TextField
                label="Data Inicial"
                fullWidth
                type="date"
                inputProps={{ name: 'dataInicial' }}
                InputLabelProps={{ shrink: true }}
                value={dataInicial}
                onChange={evt => onDataChange(evt)}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="flex-end" style={{ marginTop: 10 }}>
            <Grid item xs={1} md={1}>
              <CalendarIcon />
            </Grid>
            <Grid item xs={11} md={11}>
              <TextField
                label="Data Final"
                fullWidth
                type="date"
                inputProps={{ name: 'dataFinal' }}
                InputLabelProps={{ shrink: true }}
                value={dataFinal}
                onChange={evt => onDataChange(evt)}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="flex-end" style={{ marginTop: 20, flex: 1 }}>
            <div style={{ margin: 10, position: 'relative' }}>
              <Button
                disabled={gerando && gerando.tipo === 'extratofinanceiro'}
                variant="contained"
                color="primary"
                onClick={onFilterClick}
              >
                FILTRAR
              </Button>
              {gerando && gerando.tipo === 'extratofinanceiro' && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </div>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

formExtratoFinanceiro.propTypes = {
  cliente: PropTypes.shape({
    cgc: PropTypes.string,
  }),
  gerando: PropTypes.shape().isRequired,
  clientes: PropTypes.arrayOf().isRequired,
  unidade: PropTypes.shape().isRequired,
  unidades: PropTypes.arrayOf().isRequired,
  dataInicial: PropTypes.string.isRequired,
  dataFinal: PropTypes.string.isRequired,
  onSelectCliente: PropTypes.func.isRequired,
  onSelectUnidade: PropTypes.func.isRequired,
  onDataChange: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired,
}

formExtratoFinanceiro.defaultProps = {
  cliente: {
    cgc: 'a',
  },
}
