import { API_URL } from '..'
import { httpValidState, httpValidErros } from '../../utils/http'

const ref = '/nfe'
const headers = {
  Accept: 'application/json',
}

export const getDanfesXML = ({
  token,
  empresa = null,
  filial = null,
  cgc = null,
  dtini = null,
  dtfim = null,
  doc = null,
  valbrut = null,
}) => {
  let queryUrl = `/listarNfeCliente?cgc=${cgc}`
  queryUrl = empresa ? `${queryUrl}&empresa=${empresa}` : queryUrl
  queryUrl = filial ? `${queryUrl}&filial=${filial}` : queryUrl
  queryUrl = dtini ? `${queryUrl}&dtini=${dtini}` : queryUrl
  queryUrl = dtfim ? `${queryUrl}&dtfim=${dtfim}` : queryUrl
  queryUrl = doc ? `${queryUrl}&doc=${doc}` : queryUrl
  queryUrl = valbrut ? `${queryUrl}&valbrut=${valbrut}` : queryUrl

  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(res => httpValidState(res, true))
    .catch(httpValidErros)
}

export const downloadDanfe = (dto) => {
  const {
    token, usuarioCod, empresaCod, filialCod, documento, serie,
  } = dto
  let queryUrl = `/geraDanfeSimplesClinte?user=${usuarioCod}&emp=${empresaCod}&fil=${filialCod}`
  queryUrl += `&doc=${documento}&serie=${serie}`
  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      'Content-Type': 'application/pdf',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.blob())
    .then(httpValidState)
    .catch(httpValidErros)
}

export const downloadXml = (dto) => {
  const {
    token, usuarioCod, empresaCod, filialCod, documento, serie,
  } = dto
  let queryUrl = `/geraXMLClinte?user=${usuarioCod}&emp=${empresaCod}&fil=${filialCod}`
  queryUrl += `&doc=${documento}&serie=${serie}`
  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      'Content-Type': 'application/xml',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.blob())
    .then(httpValidState)
    .catch(httpValidErros)
}
