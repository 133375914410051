import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
  Typography,
  Card,
  Grid,
  Fab,
  Icon,
  Tooltip,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core'

// import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import { Actions as CrmContatosActions } from 'store/ducks/crm/contato'
import Header from 'components/Header'
import TableDataComp from 'components/Table'
import useStyles from './styles'

const CrmContatosListagemPage = (props) => {
  const {
    usuario, crmContatos, push, onFilterCrmContatos,
  } = props
  const classes = useStyles()
  // State
  const [iptSearch, setIptSearch] = useState('')
  const tableCols = [
    { key: 'id', title: 'ID' },
    { key: 'setor', title: 'SETOR' },
    { key: 'tipo', title: 'TIPO' },
    { key: 'codigo', title: 'CÓDIGO' },
    { key: 'nome', title: 'NOME' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]
  const tableActionsList = [
    /*
    {
      key: 'bloquear',
      title: 'Bloquear',
      icon: 'clear',
      action: row => null,
    },
    */
    {
      key: 'detalhes',
      title: 'Detalhes',
      icon: 'list',
      action: row => push(`/crm/contatos/detail/${row.id}`),
    },
  ]

  useEffect(() => {
    document.title = 'Online - Contatos'
    document.body.style = 'background-image: none;'
    onFilterCrmContatos()
  }, [])

  return (
    <Fragment>
      <Header />
      <Grid container spacing={2} className={classes.root} direction="row" justifyContent="center">
        <Grid item xs={12} md={12} lg={8}>
          <Card className={[classes.card, classes.grid].join(' ')}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h2">
                      Contatos
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid container direction="row" justifyContent="flex-end">
                      <TextField
                        id="outlined-adornment-password"
                        className={classNames(classes.margin, classes.textField)}
                        variant="outlined"
                        label="Pesquisar"
                        value={iptSearch}
                        onChange={evt => setIptSearch(evt.target.value.toUpperCase())}
                        onKeyPress={(evt) => {
                          if (evt.charCode === 13) {
                            onFilterCrmContatos({ search: iptSearch })
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                aria-label="Pesquisar"
                                onClick={() => onFilterCrmContatos({ search: iptSearch })}
                              >
                                <Icon>search</Icon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Tooltip title="Adicionar">
                        <Fab
                          color="primary"
                          aria-label="Add"
                          className={classes.fab}
                          onClick={() => push('/crm/contatos/add')}
                        >
                          <Icon>add</Icon>
                        </Fab>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableDataComp
                  // loading={loading.aprovacoes}
                  loading={crmContatos.loading.list}
                  rows={crmContatos.list}
                  cols={tableCols}
                  actions={tableActionsList}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  )
}

CrmContatosListagemPage.propTypes = {
  usuario: PropTypes.shape().isRequired,
  crmContatos: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
  onFilterCrmContatos: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  crmContatos: state.crmContatos,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...CrmContatosActions,
    push: crrPush,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CrmContatosListagemPage)
