import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
    Grid,
    Icon,
    TextField,
    InputAdornment,
    IconButton,
    CssBaseline,
    Paper,
    DatePicker,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Divider
} from '@material-ui/core'

import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import TableDataComp from '../../../../../../../../components/Table'
import useStyles from './styles'
import { findByIdVisitaApi, getVisitasFilterApi } from 'api/visitas'
import { DialogContentSimples } from 'components/Dialogs/Contents'
import { editClienteApi, getClienteByIdApi } from 'api/cliente'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import { Save } from '@material-ui/icons'
import { NotificationManager } from 'react-notifications'
import ReactInputMask from 'react-input-mask'


const VisitaClienteDetailTab = (props) => {
    const {
        usuario, push, params
    } = props
    const classes = useStyles()

    // State
    const [listFilter, setListFilter] = useState([])
    const [filter, setFilter] = useState({
        page: 0,
        rows: 100,
        search: '',
    })
    const [payload, setpayload] = useState([])
    const tableCols = [
        { key: 'crmProspectStatusDescription', title: 'STATUS' },
        { key: 'nome', title: 'NOME' },
        { key: 'cgc', title: 'CGC' },
        { key: 'titularConta', title: 'TITULAR' },
    ]

    const OnSerachCliente = () => {
        findByIdVisitaApi({ token: usuario?.auth?.token ?? '', id: params.id })
            .then((resp) => {
                if (resp.unidadeConsumidoraClienteId) {
                    getClienteByIdApi({ token: usuario?.auth?.token ?? '', id: resp.unidadeConsumidoraClienteId })
                        .then((resp) => {
                            console.log(resp)
                            setpayload(resp)
                            console.log(payload)
                        })
                } else {
                    setpayload(null)
                }
            })
    }
    const onUpdatePayloadField = (updateObj) => {
        setpayload((prevPayload) => ({ ...prevPayload, ...updateObj }));
    };
    function converterData(dataOriginal) {
        const data = new Date(dataOriginal);

        const dia = data.getUTCDate().toString().padStart(2, '0');
        const mes = (data.getUTCMonth() + 1).toString().padStart(2, '0');
        const ano = data.getUTCFullYear().toString();
        const hora = data.getUTCHours().toString().padStart(2, '0');
        const minuto = data.getUTCMinutes().toString().padStart(2, '0');
        const segundo = data.getUTCSeconds().toString().padStart(2, '0');

        const dataFormatada = `${ano}-${mes}-${dia} ${hora}:${minuto}:${segundo}`
        return dataFormatada;
    }
    const editCliente = (payload, token) => {
        payload.telefone = payload.telefone.replace(/\D/g, "");
        payload.cgc = payload.cgc.replace(/\D/g, "")
        editClienteApi({ token, dto: payload, id: payload.id }).then(resp =>
            OnSerachCliente(),
            NotificationManager.success(
                'Editado com sucesso!',
                'Atenção',
                8000,
            )
        );
    }

    useEffect(() => {
        document.title = 'Online - Visita'
        document.body.style = 'background-image: none;'
        OnSerachCliente()
        console.log(payload)
    }, [])
    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <Paper className={classes.paper} elevation={1}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                id="nome"
                                label="Nome:"
                                margin="normal"
                                variant="outlined"
                                value={payload?.nome ? payload.nome.toString().toUpperCase() : ''}
                                className={classes.textField}
                                onChange={evt => onUpdatePayloadField({ nome: evt.target.value })}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="titular-conta-label">Titular Conta:</InputLabel>
                                <Select
                                    labelId="titular-conta-label"
                                    id="titular-conta"
                                    value={payload?.titularConta ? payload.titularConta : ''}
                                    onChange={evt => onUpdatePayloadField({ titularConta: evt.target.value })}
                                    label="Titular Conta"
                                    className={classes.selectField}
                                >
                                    <MenuItem value="">Selecione</MenuItem>
                                    <MenuItem value="SIM">SIM</MenuItem>
                                    <MenuItem value="NÃO">NÃO</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                id="email"
                                label="E-mail:"
                                margin="normal"
                                variant="outlined"
                                value={payload?.email ? payload.email.toString().toUpperCase() : ''}
                                className={classes.textField}
                                onChange={evt => onUpdatePayloadField({ email: evt.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="middle" className={classes.customDivider} />
                        </Grid>
                        <Grid item xs={3}>
                            <ReactInputMask
                                mask="999.999.999-99"
                                value={payload?.cgc ? payload.cgc.toString() : ''}
                                onChange={(evt) => onUpdatePayloadField({ cgc: evt.target.value })}
                                maskChar={null}
                            >
                                {() => (
                                    <TextField
                                        fullWidth
                                        id="cgc"
                                        label="CPF:"
                                        margin="normal"
                                        variant="outlined"
                                        className={classes.textField}
                                    />
                                )}
                            </ReactInputMask>
                        </Grid>
                        <Grid item xs={3}>
                            <ReactInputMask
                                mask="(99) 99999-9999"
                                value={payload?.telefone ? payload.telefone.toString() : ''}
                                onChange={(evt) => onUpdatePayloadField({ telefone: evt.target.value })}
                                maskChar={null}
                            >
                                {() => (
                                    <TextField
                                        fullWidth
                                        id="telefone"
                                        label="Telefone:"
                                        margin="normal"
                                        variant="outlined"
                                        className={classes.textField}
                                    />
                                )}
                            </ReactInputMask>
                        </Grid>
                        <Grid item xs={3}>
                            <ReactInputMask
                                mask="(99) 9999-9999"
                                value={payload?.telFixo ? payload.telFixo.toString() : ''}
                                onChange={(evt) => onUpdatePayloadField({ telFixo: evt.target.value })}
                                maskChar={null}
                            >
                                {() => (
                                    <TextField
                                        fullWidth
                                        id="telefone"
                                        label="Telefone FIXO:"
                                        margin="normal"
                                        variant="outlined"
                                        className={classes.textField}
                                    />
                                )}
                            </ReactInputMask>
                        </Grid>
                        <Grid item xs={3}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    disableToolbar
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Data de expedição do RG"
                                    value={payload?.rgDtExpedicao || null}
                                    onChange={date => onUpdatePayloadField({ rgDtExpedicao: converterData(date) })}
                                    KeyboardButtonProps={{
                                        'aria-label': 'selecione a data',
                                    }}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="rgOrgao"
                                label="Órgão expedidor do RG:"
                                margin="normal"
                                variant="outlined"
                                value={payload?.rgOrgao ? payload.rgOrgao.toString() : ''}
                                className={classes.textField}
                                onChange={evt => onUpdatePayloadField({ rgOrgao: evt.target.value })}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="nomPai"
                                label="Nome do pai:"
                                margin="normal"
                                variant="outlined"
                                value={payload?.nomPai ? payload.nomPai.toString().toUpperCase() : ''}
                                className={classes.textField}
                                onChange={evt => onUpdatePayloadField({ nomPai: evt.target.value })}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="nomMae"
                                label="Nome da mãe:"
                                margin="normal"
                                variant="outlined"
                                value={payload?.nomMae ? payload.nomMae.toString().toUpperCase() : ''}
                                className={classes.textField}
                                onChange={evt => onUpdatePayloadField({ nomMae: evt.target.value })}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="rgNum"
                                label="Número do RG:"
                                margin="normal"
                                variant="outlined"
                                value={payload?.rgNum ? payload.rgNum.toString() : ''}
                                className={classes.textField}
                                onChange={evt => onUpdatePayloadField({ rgNum: evt.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="middle" className={classes.customDivider} />
                        </Grid>
                        <Grid item xs={3}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    disableToolbar
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Data de nascimento"
                                    value={payload?.dtNascimento || null}
                                    onChange={date => onUpdatePayloadField({ dtNascimento: converterData(date) })}
                                    KeyboardButtonProps={{
                                        'aria-label': 'selecione a data',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="sexo-label">Sexo:</InputLabel>
                                <Select
                                    labelId="sexo-label"
                                    id="sexo"
                                    value={payload?.sexo ? payload.sexo.toString() : ''}
                                    onChange={evt => onUpdatePayloadField({ sexo: evt.target.value })}
                                    label="Sexo"
                                >
                                    <MenuItem value="">SELECIONE</MenuItem>
                                    <MenuItem value="MASCULINO">MASCULINO</MenuItem>
                                    <MenuItem value="FEMININO">FEMININO</MenuItem>
                                    <MenuItem value="OUTROS">OUTROS</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="sexo-label">Gênero:</InputLabel>
                                <Select
                                    labelId="genero-label"
                                    id="genero"
                                    value={payload?.genero ? payload.genero.toString() : ''}
                                    onChange={evt => onUpdatePayloadField({ genero: evt.target.value })}
                                    label="Gênero"
                                >
                                    <MenuItem value="">SELECIONE</MenuItem>
                                    <MenuItem value="HOMEM">HOMEM</MenuItem>
                                    <MenuItem value="MULHER">MULHER</MenuItem>
                                    <MenuItem value="OUTROS">OUTROS</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={6}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() => editCliente(payload, usuario?.auth?.token ?? '')}
                                        disabled={!payload}
                                    >
                                        <Save className={classes.leftIcon} />
                                        Salvar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            </main>
        </div>
    )
}

VisitaClienteDetailTab.propTypes = {
    push: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        push: crrPush,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VisitaClienteDetailTab)
