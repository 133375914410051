import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'
import { API_URL } from 'api'


const ref = '/checklist'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - { token, params, page = 0, rows = 50 }
 */
export const filterChecklistApi = (token, params, page = 0, rows = 50) => {
  let queryUrl = `?page=${page}&rows=${rows}`
  Object.keys(params).forEach((key) => {
    queryUrl += `&${key}=${params[key]}`
  })
  return fetch(`${API_URL}${ref}${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 *
 * @param {String} dto - { token: String, id }
 */
export const getByIdChecklistApi = (token, id) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'GET',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const addChecklistApi = (token, dto) => fetch(`${API_URL}${ref}`, {
  method: 'POST',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} dto - { token: String, payload }
 */
export const editChecklistApi = (token, dto) => fetch(`${API_URL}${ref}/${dto.id}`, {
  method: 'PUT',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(dto),
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - { pk: ID do produto, fk: ID da categoria }
 */
export const delChecklistApi = ({ token, id }) => fetch(`${API_URL}${ref}/${id}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} dto - Id da pergunta
 */
export const delChecklistItensApi = ({ token, id }) => fetch(`${API_URL}${ref}/itens/${id}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {String} token - Token de autenticação
 * @param {String} id - Id da alternativa
 */
export const delChecklistItensAlternativasApi = ({ token, id }) => fetch(`${API_URL}${ref}/alternativas/${id}`, {
  method: 'DELETE',
  headers: {
    ...headers,
    Authorization: `Bearer ${token}`,
  },
})
  .then(httpParseJson)
  .then(httpValidState)
  .catch(httpValidErros)
