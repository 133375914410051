const styles = theme => ({
  root: {
    marginTop: 20,
    paddingTop: 20,
  },
  grid: {
    marginLeft: theme.defaults.margin * 2,
    marginRight: theme.defaults.margin * 2,
  },
  tableRow: {
    cursor: 'pointer',
  },
  title: {
    marginLeft: 20,
    flex: '0 0 auto',
  },
  wrapper: {
    margin: 10,
    position: 'relative',
  },
  buttonProgress: {
    // color: '#FFF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  loadingPadding: {
    marginRight: theme.defaults.margin,
  },
  pago: {
    color: '#008000',
  },
})

export default styles
