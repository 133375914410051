export const EventosEnum = {
  LOGIN_PORTAL_SUCESSO: {
    id: 1,
    descricao: 'SUCESSO ACESSO AO PORTAL (LOGIN)',
    icon: 'person',
  },
  BOLETO_DOWNLOAD_SUCESSO: {
    id: 2,
    descricao: 'SUCESSO DOWNLOAD BOLETO',
    icon: 'request_quote',
  },
  XML_DOWNLOAD_SUCESSO: {
    id: 3,
    descricao: 'SUCESSO DOWNLOAD XML NFE',
    icon: 'text_snippet',
  },
  DANFE_DOWNLOAD_SUCESSO: {
    id: 4,
    descricao: 'SUCESSO DOWNLOAD DANFE NFE',
    icon: 'picture_as_pdf',
  },
  EXTRATO_FINANCEIRO_SUCESSO: {
    id: 5,
    descricao: 'SUCESSO DOWNLOAD EXTRATO FINANCEIRO',
    icon: 'assessment',
  },
  PEDIDO_CRIADO_SUCESSO: {
    id: 6,
    descricao: 'SUCESSO PEDIDO CRIADO',
    icon: 'local_shipping',
  },
  PEDIDO_CANCELADO_SUCESSO: {
    id: 7,
    descricao: 'SUCESSO PEDIDO CANCELADO',
    icon: 'close',
  },
  FATURAMENTO: {
    id: 8,
    descricao: 'FATURAMENTO',
    icon: 'attach_money',
  },
}
