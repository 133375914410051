import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { DetalhesAprovacoesUsuarioApi } from '../../../../api/usuario'
import TableDataComp from '../../../Table'
// import useStyles from './styles'

const AprovacoesStatusGridComp = (props) => {
  const { dto } = props
  // const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  const tableCols = [
    { key: 'usuarioAprovacoesId', title: 'ID' },
    { key: 'usuarioAprovadoresUsuarioDesc', title: 'APROVADOR' },
    { key: 'aprovadoDesc', title: 'STATUS' },
    { key: 'motivo', title: 'MOTIVO' },
  ]
  useEffect(() => {
    setLoading(true)
    DetalhesAprovacoesUsuarioApi(dto).then((res) => {
      if (res && _.isArray(res)) {
        setLoading(false)
        setRows(res)
      }
    })
  }, [])
  return (
    <TableDataComp
      keyList="usuarioAprovacoesId"
      loading={loading}
      rows={rows}
      cols={tableCols}
      actions={[]}
    />
  )
}

AprovacoesStatusGridComp.propTypes = {
  dto: PropTypes.shape().isRequired,
}

export default AprovacoesStatusGridComp
