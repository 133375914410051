import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    paddingTop: 20,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  card: {
    marginTop: 40,
    padding: theme.spacing(3),
  },
  grid: {
    marginLeft: theme.defaults.margin * 2,
    marginRight: theme.defaults.margin * 2,
  },
  margin: {
    marginRight: theme.spacing(2),
  },
  textField: {
    // flexBasis: 200,
  },
  toolbar: theme.mixins.toolbar,
}))

export default styles
