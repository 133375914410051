import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import config from 'react-global-configuration'
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  TextField,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import Person from '@material-ui/icons/Person'
import VpnKey from '@material-ui/icons/VpnKey'
import Help from '@material-ui/icons/Help'
import ReCAPTCHA from 'react-google-recaptcha'

import { Actions as UsuarioActions } from '../../../store/ducks/usuario'
import { Actions as ConfigActions } from '../../../store/ducks/config'
import styles from './styles'

class LoginPage extends Component {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    loading: PropTypes.bool.isRequired,
    usuario: PropTypes.shape().isRequired,
    reCaptcha: PropTypes.string.isRequired,
    onReCaptcha: PropTypes.func.isRequired,
    onFieldUpdate: PropTypes.func.isRequired,
    onLoginAction: PropTypes.func.isRequired,
    onResetLoginPage: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { onResetLoginPage } = this.props
    const configClienteSku = config.get('cliente.sku')
    const configClienteNome = config.get('cliente.nome')
    const bg = require(`../../../assets/imagens/login/${configClienteSku}-login-bg-page.png`)
    document.title = `${configClienteNome} - Online`
    document.body.style = `background-image: url(${bg}); background-size:100% 100%; background-repeat:no-repeat; background-position: center;`
    document.documentElement.style = 'height: 100%'
    onResetLoginPage()
  }

  onLoginClick = () => {
    const { usuario, onLoginAction } = this.props
    const { email, senha } = usuario
    onLoginAction(email, senha)
  }

  onRecaptchaClick = (value) => {
    const { onReCaptcha } = this.props
    if (value) onReCaptcha(value)
  }

  render() {
    const {
      classes, reCaptcha, usuario, onFieldUpdate, loading,
    } = this.props
    const configDefaultAltLogoLeft = config.get('default.altLogoLeft')
    const configDefaultAltLogoRight = config.get('default.altLogoRight')
    const configDefaultLogoLeft = config.get('default.logoLeft')
    const configDefaultLogoRight = config.get('default.logoRight')
    const configDefaultLoginCardHeader = config.get('default.loginCardHeader')
    const configDefaultExibeAjudaLogin = config.get('default.exibeAjudaLogin')
    return (
      <>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Grid container direction="row" justifyContent="center" alignItems="center" >
              {configDefaultLogoLeft && <img width="400px" alt={configDefaultAltLogoLeft} src={require(`../../../assets/imagens/${configDefaultLogoLeft}`)} />}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={[classes.content, classes.center].join(' ')}>
              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  alt="Contemplative Reptile"
                  className={classes.media}
                  height="140"
                  image={require(`../../../assets/imagens/login/${configDefaultLoginCardHeader}`)}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Grid item xs={12} md={12} className={classes.margin}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Login
                    </Typography>
                  </Grid>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
                    <Grid item xs={2} md={2}>
                      <Person />
                    </Grid>
                    <Grid item xs={10} md={10}>
                      <TextField
                        label="E-mail"
                        autoComplete="off"
                        fullWidth
                        value={usuario.email}
                        onChange={evt => onFieldUpdate({ email: evt.target.value })}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
                    <Grid item xs={2} md={2}>
                      <VpnKey />
                    </Grid>
                    <Grid item xs={10} md={10}>
                      <TextField
                        label="Senha"
                        autoComplete="new-password"
                        type="password"
                        value={usuario.senha}
                        fullWidth
                        onChange={evt => onFieldUpdate({ senha: evt.target.value })}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={classes.margin}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12}>
                      <ReCAPTCHA
                        sitekey="6LczQ0MnAAAAALGNg22IXaPEIs_otQgp12wDeAdx"
                        onChange={value => this.onRecaptchaClick(value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions className={classes.containerActions}>
                  <Grid
                    container
                    className={classes.btnContainerEntrar}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <div className={classes.wrapper}>
                      <Button
                        disabled={!usuario.email || !usuario.senha || !reCaptcha || loading}
                        variant="contained"
                        color="primary"
                        onClick={this.onLoginClick}
                      >
                        Entrar
                      </Button>
                      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                  </Grid>
                  <Grid container className={classes.margin} justifyContent="flex-end" alignItems="center">
                    <Link to="/recovery">Esqueceu a senha?</Link>
                  </Grid>
                  <Grid container className={classes.margin} justifyContent="flex-end" alignItems="center">
                    <Link to="/novo-cliente">Solicitar acesso</Link>
                  </Grid>
                </CardActions>
              </Card>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              {configDefaultLogoRight && <img alt={configDefaultAltLogoRight} src={require(`../../../assets/imagens/${configDefaultLogoRight}`)} />}
            </Grid>
          </Grid>
          {configDefaultExibeAjudaLogin === 'SIM' && (
            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Button
                  color="default"
                  endIcon={<Help />}
                  data-toggle="modal"
                  data-target=".bd-login-modal-lg"
                >
                  Ajuda
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    )
  }
}

const mapStateToProps = state => ({
  reCaptcha: state.usuario.reCaptcha,
  usuario: state.usuario.usuario,
  loading: state.usuario.loading,
  error: state.usuario.error,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...UsuarioActions,
    ...ConfigActions,
  },
  dispatch,
)

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LoginPage),
)
