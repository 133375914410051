import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import classNames from 'classnames'
import {
  Typography,
  CssBaseline,
  Grid,
  Fab,
  Icon,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core'

import { getClienteInfoByIdApi } from '../../../../api/cliente'
import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as DialogActions } from '../../../../store/ducks/dialog'
import { Actions as GestorUsuariosActions } from '../../../../store/ducks/gestor/usuarios'
import { Actions as UsuarioActions } from '../../../../store/ducks/usuario'
import GestorHeaderComp from '../../../../components/Gestor/Header'
import GestorDrawerComp from '../../../../components/Gestor/Drawer'
import TableDataComp from '../../../../components/Table'

import useStyles from './styles'
import { Pagination } from '@material-ui/lab'
import { FilterUsuariosApi, FilterUsuariosPaginatorApi } from 'api/usuario'

const tableCols = [
  { key: 'id', title: 'ID' },
  { key: 'nome', title: 'NOME' },
  { key: 'email', title: 'E-MAIL' },
  { key: 'perfils.descricao', title: 'PERFIL(S)' },
  { key: 'statusDesc', title: 'STATUS' },
]

const DialogContentDetalhes = (props) => {
  const {
    row: {
      auth, nome, email, telefone, clientes,
    },
  } = props
  const [expanded, setExpanded] = useState('dadosEmpresa')
  const [cliente, setCliente] = useState(null)
  const classes = useStyles()
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  useEffect(() => {
    getClienteInfoByIdApi({
      token: auth.token,
      clienteId: clientes[0].id,
      usuarioId: auth.id,
    }).then((resp) => {
      setCliente(resp)
    })
  }, [])

  return !cliente ? (
    <CircularProgress />
  ) : (
    <>
      <ExpansionPanel
        square
        className={classes.expansionPanel}
        expanded={expanded === 'dadosEmpresa'}
        onChange={handleChange('dadosEmpresa')}
      >
        <ExpansionPanelSummary>
          <Typography variant="h6" gutterBottom>
            #1 Dados da Empresa
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
              <Typography variant="button">RAZÃO SOCIAL:</Typography>
              <Typography gutterBottom>{` ${cliente.razao || ''}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="button">CGC:</Typography>
              <Typography gutterBottom>{` ${cliente.cgc || ''}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="button">TIPO:</Typography>
              <Typography gutterBottom>{` ${cliente.tipoPessoa || ''}`}</Typography>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === 'dadosEnderecoEmpresa'}
        onChange={handleChange('dadosEnderecoEmpresa')}
      >
        <ExpansionPanelSummary>
          <Typography variant="h6" gutterBottom>
            #2 Endereço da Empresa
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
              <Typography variant="button">LOGRADOURO:</Typography>
              <Typography gutterBottom>
                {` ${cliente.logradouro || ''}, ${cliente.numero
                  || ''} COMP: ${cliente.complemento || ''}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="button">BAIRRO:</Typography>
              <Typography gutterBottom>{` ${cliente.bairro || ''}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="button">CIDADE:</Typography>
              <Typography gutterBottom>
                {` ${cliente.cidade || ''} UF: ${cliente.uf || ''}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="button">CEP:</Typography>
              <Typography gutterBottom>{` ${cliente.cep || ''}`}</Typography>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === 'dadosUsuario'}
        onChange={handleChange('dadosUsuario')}
      >
        <ExpansionPanelSummary>
          <Typography variant="h6" gutterBottom>
            #3 Dados Usuário
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
              <Typography variant="button">NOME:</Typography>
              <Typography gutterBottom>{` ${nome || ''}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="button">E-MAIL:</Typography>
              <Typography gutterBottom>{` ${email || ''}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="button">TELEFONE:</Typography>
              <Typography gutterBottom>{` ${telefone || ''}`}</Typography>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  )
}

DialogContentDetalhes.propTypes = {
  row: PropTypes.shape().isRequired,
}

const DialogContentReprovar = connect(
  state => ({ generic: state.dialog.generic }),
  dispatch => bindActionCreators(DialogActions, dispatch),
)((props) => {
  const { text, generic, onUpdateGeneric } = props
  return (
    <Grid container>
      <Grid item xs={12}>
        {text}
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="motivo"
          label="Motivo do bloqueio:"
          value={generic.motivo}
          onChange={evt => onUpdateGeneric({ motivo: evt.target.value })}
          margin="normal"
        />
      </Grid>
    </Grid>
  )
})

DialogContentReprovar.propTypes = {
  text: PropTypes.string.isRequired,
}

const GestorUsuariosListagemPage = (props) => {
  const {
    push,
    unidade,
    usuario,
    gestorUsuarios: { usuarios, loading },
    onDialogOpen,
    onDialogClose,
    onDialogActionLoading,
    onLoadDataGu,
    onAprovaReprovaUsuario,
  } = props

  // State
  const [iptSearch, setIptSearch] = useState('')
  // const [iptMotivo, setIptMotivo] = useState('')

  // DidMount :D
  const [filter, setFilter] = useState({
    rows: 10,
  });
  const [currentPage, setCurrentPage] = useState(1); // Ajuste conforme necessário
  const onSearchUsuarios = () => {
    FilterUsuariosPaginatorApi({ token: usuario?.auth?.token ?? '', query: { page: currentPage - 1, ...filter,search: iptSearch } })
      .then((resp) => {
        setPayload(resp);
      })
      .catch((error) => {
        // Tratar o erro aqui...
      });
  };
  const onSearchUsuariosWithPage = (page) => {
    setIsSearching(true);
    setCurrentPage(1)
    FilterUsuariosPaginatorApi({ token: usuario?.auth?.token ?? '', query: { page: page - 1, ...filter, search: iptSearch } })
      .then((resp) => {
        setPayload(resp);
        setIsSearching(false);
      })
      .catch((error) => {
        // Tratar o erro aqui...
        setIsSearching(false);
      });
  };
  const [payload, setPayload] = useState(null);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (!isSearching) {
      document.title = 'Online - Gestor | Aprovações'
      document.body.style = 'background-image: none;'
      onSearchUsuarios()
    }
  }, [currentPage])
  const tableActionsList = [
    {
      key: 'bloquear',
      title: 'Bloquear',
      icon: 'clear',
      action: row => onDialogOpen({
        size: 'sm',
        title: 'Bloquear acesso?',
        content: (
          <DialogContentReprovar
            text={`Você confirma o bloqueio do acesso para o usuário: "${row.nome}" ?`}
          />
        ),
        actions: [
          {
            key: 1,
            title: 'Bloquear',
            action: () => {
              onDialogActionLoading({ key: 1, loading: true })
              onAprovaReprovaUsuario(row, 'B', null, () => {
                onDialogActionLoading({ key: 1, loading: false })
                onDialogClose()
                onSearchUsuariosWithPage(1)
              })
            },
            color: 'secondary',
          },
          { key: 2, title: 'Cancelar', action: () => onDialogClose() },
        ],
      }),
    },
    {
      key: 'detalhes',
      title: 'Detalhes',
      icon: 'list',
      action: row => push(`usuarios/${row.id}`),
    },
  ]

  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" component="h2">
                  Usuários
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container direction="row" justifyContent="flex-end">
                  <TextField
                    id="outlined-adornment-password"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    // type={values.showPassword ? 'text' : 'password'}
                    label="Pesquisar"
                    value={iptSearch}
                    onChange={evt => setIptSearch(evt.target.value.toUpperCase())}
                    onKeyPress={(evt) => {
                      if (evt.charCode === 13) {
                        onSearchUsuariosWithPage(1)
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Pesquisar"
                            onClick={() => onSearchUsuariosWithPage(1)}
                          >
                            <Icon>search</Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Tooltip title="Adicionar">
                    <Fab
                      color="primary"
                      aria-label="Add"
                      className={classes.fab}
                      onClick={() => push('usuarios/add')}
                    >
                      <Icon>add</Icon>
                    </Fab>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <TableDataComp
              loading={loading.usuarios}
              rows={payload?.data}
              cols={tableCols}
              actions={tableActionsList}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <div id="mui-pagination">
              <Pagination
                count={payload?.total ?? 0}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                className={classes.pagination}
              />
            </div>
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

GestorUsuariosListagemPage.propTypes = {
  loadingAprovacao: PropTypes.bool.isRequired,
  // usuarios: PropTypes.ArrayOf(PropTypes.shape()).isRequired,
  unidade: PropTypes.shape().isRequired,
  usuario: PropTypes.shape().isRequired,
  gestorUsuarios: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onDialogLoading: PropTypes.func.isRequired,
  onDialogActionLoading: PropTypes.func.isRequired,
  onLoadDataGu: PropTypes.func.isRequired,
  onAprovaReprovaUsuario: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  unidade: state.usuario.usuario.unidade,
  gestorUsuarios: state.gestorUsuarios,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    ...GestorUsuariosActions,
    ...UsuarioActions,
    push,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GestorUsuariosListagemPage)
