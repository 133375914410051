import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
    Paper, Grid, CircularProgress, Button, FormControl,
} from '@material-ui/core'

import TableDataComp from 'components/Table'
import DialogFormClienteEndereco from '../Dialogs/DialogFormClienteEndereco'

import useStyles from './styles'
import { converterPedidoPadraoItemApi, converterTodosPedidoPadraoItemApi, desvinculaPedidoPadraoItemApi } from 'api/pedido_padrao_item'
import { NotificationManager } from 'react-notifications'
import DialogAddOportunidade from '../Dialogs/DialogAddOportunidade'
import { v4 as uuidv4 } from 'uuid'

const DadosProspectInteresseCompraTab = (props) => {
    const {
        params,
        loading,
        pedidoPadraoItensByProspect,
        usuario,
        criarListaItensPedidos,
        prospectId,
        onDialogClose,
        onDialogOpen,
        oportunidadeId
    } = props
    const classes = useStyles()

    const tableAtendimentosCols = [
        { key: 'pedidoStatusDescricao', title: 'STATUS' },
        { key: 'unidadeConsumidoraUnidNumero', title: 'UC Número' },
        { key: 'tabelaPrecoSku', title: 'CÓDIGO' },
        { key: 'produtoDescricao', title: 'DESCRIÇÃO' },
        { key: 'tabelaPrecoProdutoValorMin', title: 'VALOR', format: r => `R$: ${r.tabelaPrecoProdutoValorMin}` },
    ]

    const confirmarGeracaoOportunidades = (row) => {
        onDialogOpen({
            size: "sm",
            title: "Confirmação",
            content: (
                <FormControl id="forms-confirmar">
                    <p>Confirma geração da Oportunidade para a <b>Unidade Consumidora Nr {row.unidadeConsumidoraUnidNumero}</b>?</p>
                </FormControl>
            ),
            actions: [
                {
                    key: 1,
                    title: "Sim",
                    action: () => {
                        handleConvertToOpportunities(row.unidadeConsumidoraUnidNumero);
                        onDialogClose();
                    },
                    color: "secondary",
                },
                {
                    key: 2,
                    title: "Não",
                    action: () => onDialogClose(),
                },
            ],
        });
    };

    const tableActionsList = [
        {
            key: 'declinar',
            title: 'Declinar',
            icon: 'delete',
            action: row => desvincularPedidoPadraoItem(usuario.auth.token, row)
        },
        {
            key: 'converter',
            title: 'Gerar Oport p/ Este Item',
            icon: 'add',
            // action: row => converterPedidoPadraoItem(usuario.auth.token, row)
            action: row => addOportunidade(row)
        },
        {
            key: 'converter-todos',
            title: 'Gerar Oport. p/ Todos',
            icon: 'add',
            // action: row => confirmarGeracaoOportunidades(row)
            action: row => addOportunidadeMultipleItens(row)
        }
    ];


    const desvincularPedidoPadraoItem = (token, row) => {
        if (row.pedidoStatusDescricao == "OPORTUNIDADE ABERTA") {
            NotificationManager.warning('Pedido já foi convertido para OPORTUNIDADE');

        }
        else if (row.pedidoStatusDescricao == "CANCELADO") {
            NotificationManager.warning('Pedido já está CANCELADO');
        }
        else {
            desvinculaPedidoPadraoItemApi({ token: token, id: row.id })
                .then((resp) => {
                    if (resp.status === 200 || resp.status === 201) { // Somente se o status for 200 (OK) ou 201 (Created)
                        NotificationManager.success('O Pedido foi cancelado com sucesso!', 'Sucesso!', 4000);
                        criarListaItensPedidos(usuario, prospectId)
                    }
                })
                .catch((err) => {
                    console.error('Erro ao desvincular pedido:', err);
                    // Aqui, você pode adicionar uma mensagem de erro genérica se quiser.
                });
        }

    };
    const converterPedidoPadraoItem = (token, row) => {
        if (row.pedidoStatusDescricao == "OPORTUNIDADE ABERTA") {
            NotificationManager.warning('Pedido convertido para OPORTUNIDADE');

        }
        else if (row.pedidoStatusDescricao == "CANCELADO") {
            NotificationManager.warning('Pedido está CANCELADO');
        } else {
            converterPedidoPadraoItemApi({ token: token, id: row.id })
                .then((resp) => {
                    NotificationManager.success('A Oportunidade foi gerada com sucesso!', 'Sucesso!', 4000);
                    criarListaItensPedidos(usuario, prospectId)
                    window.location.reload(); // Recarrega a página
                })
                .catch((err) => {
                    console.error('Erro ao converter pedido:', err);
                });
        }

    };
    const handleConvertToOpportunities = (unidNumero) => {
        let allOpportunities = true;

        for (let i = 0; i < pedidoPadraoItensByProspect.length; i++) {
            const pedido = pedidoPadraoItensByProspect[i];
            if (pedido.pedidoStatusDescricao !== 'OPORTUNIDADE ABERTA' && pedido.pedidoStatusDescricao !== 'CANCELADO' && pedido.pedidoStatusDescricao !== 'CANCELADA') {
                allOpportunities = false;
                break;
            }
        }

        if (allOpportunities) {
            NotificationManager.warning('Todos os pedidos são OPORTUNIDADES ou estão CANCELADOS.');
        }
        else {
            converterTodosPedidoPadraoItemApi({ token: usuario.auth.token, id: prospectId, unidNumero: unidNumero })
                .then((resp) => {
                    NotificationManager.success('Oportunidades geradas com sucesso!', 'Sucesso!', 4000);
                    criarListaItensPedidos(usuario, prospectId)
                    window.location.reload(); // Recarrega a página
                })
                .catch((err) => {
                    console.error('Erro ao converter pedido:', err);
                });
        }
    };
    useEffect(() => {

    }, [])
    // * Oportunidade
    const [dialogOportunidade, setDialogOportunidade] = useState(false);
    const [dialogShowOportunidade, setDialogShowOportunidade] = useState(false);
    const [payloadOportunidade, setPayloadOportunidade] = useState();
    const [listOportunidade, setListOportunidade] = useState([]);
    const [setItensOportunidade, itensOportunidade] = useState([])
    useEffect(() => {
        setDialogOportunidade(true);
    }, [payloadOportunidade]);
    const addOportunidade = (row) => {
        if (row.pedidoStatusDescricao !== 'OPORTUNIDADE ABERTA' && row.pedidoStatusDescricao !== 'CANCELADO' && row.pedidoStatusDescricao !== 'CANCELADA') {
            setListOportunidade(prevList => {
                // Limpar a lista antes de adicionar um novo item
                const newList = [];
                const newItem = {
                    disabled: false,
                    filialId: usuario?.unidade?.id ?? 0,
                    itemId: uuidv4(),
                    pagamentoCondicaoDescricao: row.pagamentoCondicaoDescricao,
                    pagamentoCondicaoId: row.tabelaPrecoProdutoPagamentoCondicaoId,
                    pagamentoFormaId: row.tabelaPrecoProdutoPagamentoFormaId,
                    tabelaPrecoProdutoId: parseInt(row.tabelaPrecoProdutoId, 10),
                    produtoId: row.produtoId,
                    pagamentoFormaDescricao: row.pagamentoFormaDescricao,
                    produtoDescricao: row.produtoDescricao,
                    produtoDescricaoComplementar: row.produtoDescricaoComplementar,
                    prospectId: prospectId,
                    quantidade: 1,
                    statusAceite: "A",
                    statusProspect: "1",
                    valor: parseFloat(row.tabelaPrecoProdutoValor),
                    valormin: parseFloat(row.tabelaPrecoProdutoValor),
                    tabelaPrecoId: row.tabelaPrecoId,
                    interesseCompraId: row.id,
                };
                newList.push(newItem); // Adicionar o novo item à lista vazia
                // Retornar a nova lista como o novo estado
                return newList;
            });

            const newPayload = {
                disabled: false,
                filialId: usuario?.unidade?.id ?? 0,
                prospectId: prospectId,
                itens: [...listOportunidade], // Usar o estado atualizado de listOportunidade
                statusProspect: 1,
                statusAceite: "A",
                pagamentoCondicaoId: row.tabelaPrecoProdutoPagamentoCondicaoId,
                pagamentoFormaId: row.tabelaPrecoProdutoPagamentoFormaId,
                tabelaPrecoId: row.tabelaPrecoId,
                tabelaPrecoProdutoId: parseInt(row.tabelaPrecoProdutoId, 10),
                produtoId: row.produtoId,
            };
            checkNullFields(newPayload);
            setPayloadOportunidade(newPayload);

        }
        else {
            NotificationManager.warning('Pedido inválido para gerar OPORTUNIDADE!');

        }

    };
    const checkNullFields = (obj) => {
        for (const [key, value] of Object.entries(obj)) {
            if (value === null || value === undefined) {
                console.warn(`Campo ${key} está nulo ou indefinido.`);
            }
        }
    };

    const addOportunidadeMultipleItens = async (row) => {
        // Criar uma cópia atualizada da lista de oportunidades
        let updatedListOportunidade = [];
        let count = 0
        for (const item of pedidoPadraoItensByProspect) {
            if (item.unidadeConsumidoraUnidNumero === row.unidadeConsumidoraUnidNumero && item.pedidoStatusDescricao !== 'OPORTUNIDADE ABERTA' && item.pedidoStatusDescricao !== 'CANCELADO' && item.pedidoStatusDescricao !== 'CANCELADA') {
                count += 1
                const newItem = {
                    disabled: false,
                    filialId: usuario?.unidade?.id ?? 0,
                    itemId: uuidv4(),
                    pagamentoCondicaoDescricao: item.pagamentoCondicaoDescricao,
                    pagamentoCondicaoId: item.tabelaPrecoProdutoPagamentoCondicaoId,
                    pagamentoFormaDescricao: item.pagamentoFormaDescricao,
                    pagamentoFormaId: item.tabelaPrecoProdutoPagamentoFormaId,
                    produtoDescricao: item.produtoDescricao,
                    produtoDescricaoComplementar: item.produtoDescricaoComplementar,
                    prospectId: prospectId,
                    quantidade: 1,
                    statusAceite: "A",
                    statusProspect: "1",
                    valor: parseFloat(item.tabelaPrecoProdutoValor),
                    valormin: parseFloat(item.tabelaPrecoProdutoValor),
                    tabelaPrecoId: item.tabelaPrecoId,
                    tabelaPrecoProdutoId: item.tabelaPrecoProdutoId,
                    interesseCompraId: item.id,
                    produtoId: item.produtoId,
                };

                updatedListOportunidade.push(newItem);
            }
        }
        if (count == 0) {
            NotificationManager.warning('Nenhum Pedido válido para gerar OPORTUNIDADE!');
        }

        // Atualizar a lista de oportunidades com a cópia atualizada
        await setListOportunidade(updatedListOportunidade);

        const newPayload = {
            disabled: false,
            filialId: usuario?.unidade?.id ?? 0,
            prospectId: prospectId,
            itens: updatedListOportunidade,
            statusProspect: 1,
            statusAceite: "A",
            tabelaPrecoId: row.tabelaPrecoId,
            tabelaPrecoProdutoId: row.tabelaPrecoProdutoId,
            produtoId: row.produtoId,
            pagamentoCondicaoId: row.tabelaPrecoProdutoPagamentoCondicaoId,
            pagamentoFormaId: row.tabelaPrecoProdutoPagamentoFormaId,
            tabelaPrecoProdutoId: parseInt(row.tabelaPrecoProdutoId, 10),
        };

        await setPayloadOportunidade(newPayload);
    };

    function onSavedOportunidade() {
        // Lógica da função aqui

        // Recarrega a página
        window.location.reload();
    }
    return loading ? (
        <CircularProgress />
    ) : (

        <Paper className={classes.paper} elevation={1}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <TableDataComp
                        size="small"
                        rows={pedidoPadraoItensByProspect}
                        cols={tableAtendimentosCols}
                        actions={tableActionsList}
                    />
                </Grid>
            </Grid>
            <Grid>
                {/* // Renderização do componente DialogAddOportunidade */}
                {dialogOportunidade && listOportunidade.length > 0 && (
                    <DialogAddOportunidade
                        token={usuario?.auth?.token ?? ""}
                        open={dialogOportunidade}
                        payload={payloadOportunidade || {}}
                        onClose={() => setDialogOportunidade(false)}
                        onSave={onSavedOportunidade}
                        listItensOportunidade={listOportunidade}
                        usuario={usuario}
                    />
                )}

            </Grid>
        </Paper>
    )
}

DadosProspectInteresseCompraTab.propTypes = {
    loading: PropTypes.bool,
    token: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    usuario: PropTypes.object.isRequired,
    criarListaItensPedidos: PropTypes.func.isRequired,
    prospectId: PropTypes.string.isRequired,
    onDialogOpen: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
    oportunidadeId: PropTypes.string.isRequired,
    // params: PropTypes.shape(),
}

DadosProspectInteresseCompraTab.defaultProps = {
    loading: false,
}

export default DadosProspectInteresseCompraTab
