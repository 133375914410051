import { NotificationManager } from 'react-notifications'
import _ from 'lodash'

import { EventosEnum } from '../../utils/enums/eventos'
import { Actions as ConfigActions } from './config'

import {
  filtroGridPedidosApi,
  getPedidosApi,
  getPedidosByRefApi,
  getPedidosStatus,
  getUltimosPedidosCount,
  cancelarPedidosApi,
  // Pedido Padrão
  getPedidoPadraoApi,
  listPedidoPadraoApi,
  sendPedidoPadraoApi,
  deletePedidoPadraoApi,
  getPedidoPadraoCarrinhoApi,
} from '../../api/pedido'
import { Actions as CarrinhoActions } from './carrinho'

// State Produto
const initialState = {
  loading: {
    loadPedidoById: false,
    cancelandoPedido: false,
    pedidoPadrao: false,
    loadPedidoPadraoById: false,
    loadListPedidoPadrao: false,
  },
  filtrando: false,
  filtro: {
    dtInicial: '',
    dtFinal: '',
    status: '',
    pedido: '',
  },
  pedido: {},
  pedidos: [],
  pedStatus: [],
  pedidoPadrao: {
    form: {
      id: 0,
      clienteId: 0,
      clienteCodigo: '',
      clienteLoja: '',
      clienteRazao: '',
      clienteCgc: '',
      descricao: '',
      observacao: '',
      itens: [],
    },
    list: [],
  },
}

// Actions Types
export const Types = {
  LOADING: 'pedidos/LOADING',
  FILTRANDO: 'pedidos/FILTRANDO',
  LOAD_PEDIDO: 'pedidos/LOAD_PEDIDO',
  LOAD_PEDIDOS: 'pedidos/LOAD_BOLETOS',
  LOAD_PEDIDO_STATUS: 'pedidos/LOAD_PEDIDO_STATUS',
  // Pedido padrão
  LOAD_PEDIDO_PADRAO: 'pedidos/LOAD_PEDIDO_PADRAO',
  LOAD_LIST_PEDIDO_PADRAO: 'pedidos/LOAD_LIST_PEDIDO_PADRAO',
}

// Reducers
export default function pedidosReducers(state = initialState, action) {
  switch (action.type) {
    case Types.LOADING:
      return { ...state, loading: { ...state.loading, ...action.payload } }
    case Types.FILTRANDO:
      return { ...state, filtrando: !state.filtrando }
    case Types.UPDATE_FIELD_FILTRO:
      return { ...state, filtro: { ...state.filtro, ...action.payload } }
    case Types.LOAD_PEDIDO:
      return { ...state, pedido: action.payload }
    case Types.LOAD_PEDIDOS:
      return { ...state, pedidos: action.payload }
    case Types.LOAD_PEDIDO_STATUS:
      return { ...state, pedStatus: action.payload }
    // Pedido padrão
    case Types.LOAD_PEDIDO_PADRAO:
      return { ...state, pedidoPadrao: { ...state.pedidoPadrao, form: { ...action.payload } } }
    case Types.LOAD_LIST_PEDIDO_PADRAO:
      return { ...state, pedidoPadrao: { ...state.pedidoPadrao, list: action.payload } }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  onLoadingPedido: payload => (dispatch) => {
    dispatch({ type: Types.LOADING, payload })
  },
  onFiltrando: () => (dispatch) => {
    dispatch({ type: Types.FILTRANDO })
  },
  onUpdateFieldFiltro: fields => (dispatch) => {
    dispatch({ type: Types.UPDATE_FIELD_FILTRO, payload: fields })
  },
  onLoadPedidos: (statusId = null, dias = null) => (dispatch, getState) => {
    dispatch(Actions.onFiltrando())
    const { usuario } = getState().usuario
    const {
      id, cliente, unidade, auth,
    } = usuario
    const params = {
      token: auth.token,
      pageTotal: 30,
      unidadeId: unidade.id,
      clienteId: cliente.id,
      usuarioId: id,
      statusId,
      dias,
    }
    filtroGridPedidosApi(params).then((pedidos) => {
      dispatch(Actions.onFiltrando())
      if (pedidos) {
        dispatch({ type: Types.LOAD_PEDIDOS, payload: pedidos })
      }
    })
  },
  onLoadPedido: ({
    pedidoId,
    pedidoRef,
    codigoEmpresa,
    codigoFilial,
  }) => (dispatch, getState) => {
    const {
      usuario: {
        usuario: { auth },
      },
    } = getState()
    dispatch(Actions.onLoadingPedido({ loadPedidoById: true, cancelandoPedido: false }))
    if (pedidoId) {
      getPedidosApi({ id: pedidoId, token: auth.token }).then((res) => {
        if (res && res.id !== null) {
          dispatch({ type: Types.LOAD_PEDIDO, payload: res })
        } else {
          if (res && res.status && res.status === 400) {
            NotificationManager.error(res.message, 'Atenção:', 12000)
          }
          dispatch({ type: Types.LOAD_PEDIDO, payload: {} })
        }
        dispatch(Actions.onLoadingPedido({ loadPedidoById: false }))
      })
    }
    if (pedidoRef) {
      getPedidosByRefApi({
        token: auth.token, pedidoRef, codigoEmpresa, codigoFilial,
      }).then((res) => {
        if (res && res.id !== null) {
          dispatch({ type: Types.LOAD_PEDIDO, payload: res })
        } else {
          if (res && res.status && res.status === 400) {
            NotificationManager.error(res.message, 'Atenção:', 12000)
          }
          dispatch({ type: Types.LOAD_PEDIDO, payload: {} })
        }
        dispatch(Actions.onLoadingPedido({ loadPedidoById: false }))
      })
    }
  },
  onLoadPedidoStatus: () => (dispatch, getState) => {
    const {
      usuario: {
        usuario: {
          auth, unidade, cliente, id,
        },
      },
    } = getState()
    getPedidosStatus({
      token: auth.token,
      usuarioId: id,
      clienteId: cliente.id,
      unidadeId: unidade.id,
    }).then((payload) => {
      if (payload && _.isArray(payload)) {
        getUltimosPedidosCount({
          token: auth.token,
          dias: 30,
          usuarioId: id,
          clienteId: cliente.id,
          unidadeId: unidade.id,
        }).then((ultPayload) => {
          dispatch({
            type: Types.LOAD_PEDIDO_STATUS,
            payload: [...payload, { pedidoStatusId: 999, total: ultPayload.total }],
          })
        })
      } else {
        dispatch({ type: Types.LOAD_PEDIDO_STATUS, payload: [] })
      }
    })
  },
  onCancelarPedido: pedidoId => (dispatch, getState) => {
    const {
      usuario: {
        usuario: { auth },
      },
    } = getState()
    const dto = { token: auth.token, pedidoId }
    dispatch(Actions.onLoadingPedido({ cancelandoPedido: true }))
    cancelarPedidosApi(dto).then((resp) => {
      dispatch(Actions.onLoadingPedido({ cancelandoPedido: false }))
      if (resp) {
        dispatch(Actions.onLoadPedido({ pedidoId }))
        dispatch(ConfigActions.onAddEvento(EventosEnum.PEDIDO_CANCELADO_SUCESSO))
      }
    })
  },
  // Pedido padrão
  onLoadPedidoPadrao: id => (dispatch, getState) => {
    const {
      usuario: {
        usuario: { auth },
      },
    } = getState()
    dispatch(Actions.onLoadingPedido({ loadPedidoPadraoById: true }))
    getPedidoPadraoApi({ token: auth.token, id }).then((payload) => {
      dispatch(Actions.onLoadingPedido({ loadPedidoPadraoById: false }))
      if (payload) {
        dispatch({ type: Types.LOAD_PEDIDO_PADRAO, payload })
      }
    }).catch(() => dispatch(Actions.onLoadingPedido({ loadPedidoPadraoById: false })))
  },
  getPedidoPadraoCarrinho: id => (dispatch, getState) => {
    const {
      usuario: {
        usuario: { auth, unidade },
      },
    } = getState()
    dispatch(Actions.onLoadingPedido({ loadPedidoPadraoById: true }))
    getPedidoPadraoCarrinhoApi({ token: auth.token, id, filial: unidade.id }).then((payload) => {
      dispatch(Actions.onLoadingPedido({ loadPedidoPadraoById: false }))
      if (payload) {
        const {
          itens,
          pedidoPadrao: {
            itens: itensPedidoPadrao = [],
          },
        } = payload
        itens.forEach((i) => {
          const {
            quantidade: produtoQuant = 0,
            tabelaPrecoSku,
          } = itensPedidoPadrao.find(ppi => ppi.produtoId === i.produtoId) || {}
          const isObra = !!i.obra
          dispatch(CarrinhoActions.onAddProdutoCarrinho({
            ...i.produto, produtoQuant: +produtoQuant,
          }))
          if (isObra) {
            dispatch(CarrinhoActions.onAddProdutoObra(tabelaPrecoSku, i.obra))
          }
        })
      }
    }).catch(() => dispatch(Actions.onLoadingPedido({ loadPedidoPadraoById: false })))
  },
  onLoadListPedidoPadrao: filter => (dispatch, getState) => {
    const {
      usuario: {
        usuario: { auth },
      },
    } = getState()
    dispatch(Actions.onLoadingPedido({ loadListPedidoPadrao: true }))
    listPedidoPadraoApi({ token: auth.token, filter }).then((payload) => {
      dispatch(Actions.onLoadingPedido({ loadListPedidoPadrao: false }))
      if (payload && _.isArray(payload)) {
        dispatch({ type: Types.LOAD_LIST_PEDIDO_PADRAO, payload })
      }
    }).catch(() => dispatch(Actions.onLoadingPedido({ loadListPedidoPadrao: false })))
  },
  onSendPedidoPadrao: (dto, callback = null) => (dispatch, getState) => {
    const {
      usuario: {
        usuario: { auth },
      },
    } = getState()
    const { pedidoId, descricao } = dto
    if (!pedidoId || !descricao) {
      NotificationManager.warning('', 'Atenção:', 12000)
    } else {
      dispatch(Actions.onLoadingPedido({ pedidoPadrao: true }))
      sendPedidoPadraoApi({ token: auth.token, pedidoId, descricao }).then((payload) => {
        dispatch(Actions.onLoadingPedido({ pedidoPadrao: false }))
        if (payload && payload.status === 201) {
          NotificationManager.success(payload.message, 'Atenção:', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        } else {
          NotificationManager.warning(payload.error, 'Atenção:', 8000)
        }
      }).catch(() => dispatch(Actions.onLoadingPedido({ pedidoPadrao: false })))
    }
  },
  onDeletePedidoPadraoApi: (id, callback = null) => (dispatch, getState) => {
    const {
      usuario: {
        usuario: { auth },
      },
    } = getState()
    dispatch(Actions.onLoadingPedido({ loadListPedidoPadrao: true }))
    deletePedidoPadraoApi({ token: auth.token, id }).then((payload) => {
      dispatch(Actions.onLoadingPedido({ loadListPedidoPadrao: false }))
      if (payload && payload.status === 204) {
        NotificationManager.success('Pedido padrão excluído com sucesso!', 'Atenção:', 12000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    }).catch(() => dispatch(Actions.onLoadingPedido({ loadListPedidoPadrao: false })))
  },
}
