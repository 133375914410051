import React from 'react';
import { Dialog, DialogActions, DialogTitle, Button, Typography } from '@material-ui/core';

function DeleteEntregaModal({ open, onClose, entrega, onDelete }) {
    console.log(entrega)
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogTitle>Excluir Entrega</DialogTitle>
            <div style={{ padding: '20px' }}>
                <Typography variant="body1">
                    Tem certeza de que deseja excluir a entrega com as seguintes informações?
                </Typography>

                {/* Exibindo informações adicionais */}
                <Typography variant="body2">OPORTUNIDADE: {entrega?.oportunidadeId}</Typography>
                <Typography variant="body2">CLIENTE: {entrega?.clienteNome}</Typography>
                <Typography variant="body2">PRODUTO: {entrega?.produtoDescricao}</Typography>
            </div>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={onDelete} color="secondary">
                    Excluir
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteEntregaModal;