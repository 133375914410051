import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    button: {
        background: 'black',
        color: 'white',
    },
    leftIcon: {
        marginRight: theme.defaults.margin / 2,
    },
    margin: {
        marginRight: theme.spacing(2),
    },
    textField: {
        // flexBasis: 200,
    },
    toolbar: theme.mixins.toolbar,
    // Detalhes
    expansionPanel: {
        marginTop: theme.spacing(2),
    },
    expansionPanelSummary: {
        fontSize: 17,
        fontWeight: '900',
    },
    paper: {
        ...theme.mixins.gutters(),
    },
    root: {
        flexGrow: 1,
    },
    card: {
        width: '100%',
        height: '100%'
    },
    media: {
        height: 250,
        width: '100%',
        marginTop: 10,
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
    },
    noPhotos: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '300px',
        backgroundColor: '#f8f8f8',
        fontSize: '24px',
        fontWeight: 'bold',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

export default styles
