import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  Grid,
  Typography,
  CssBaseline,
  Paper,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Fab,
  Icon,
  Tooltip,
  Collapse,
  Box,
} from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'

// Actions
import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as UsuarioActions } from 'store/ducks/usuario'
import { Actions as DialogActions } from 'store/ducks/dialog'
import { Actions as ChecklistActions } from 'store/ducks/checklist'

import { EStatus, EChecklistItemType, ESimNao } from 'utils/enums'
import { empFilByTenantId } from 'utils'
import GestorHeaderComp from 'components/Gestor/Header'
import GestorDrawerComp from 'components/Gestor/Drawer'
import MoreActionsComp from 'components/MoreActions'
import { StyledTableCell } from 'components/Table'

import DialogFormPeguntas from '../../components/dialog_form_perguntas'
import DialogformAlternativas from '../../components/dialog_form_alternativas'

// Styles
import useStyles from './styles'

const TableRowCollapseComp = (props) => {
  const {
    row,
    rowAlternativas,
    classes,
    onLoadPergunta,
    onDelPergunta,
    // setPerguntaSelected,
    onLoadAlternativa,
    onDelAlternativa,
    // Dialog
    onDialogOpen,
    onDialogClose,
    onDialogActionLoading,
    checkRoleUser,
  } = props

  const [open, setOpen] = useState(false)
  const qtdAlternativas = rowAlternativas.length || 0

  return (
    <>
      <TableRow key={row.id}>
        <TableCell align="center">
          <MoreActionsComp
            row={row}
            actions={[
              {
                key: 'editar',
                title: 'Editar',
                icon: 'edit',
                disabled: !checkRoleUser('ROLE_ADMIN') && row.isParecer,
                action: pRow => onLoadPergunta(pRow.id),
              },
              {
                key: 'excluir',
                title: 'Excluir',
                icon: 'delete',
                disabled: !checkRoleUser('ROLE_ADMIN') && row.isParecer,
                action: pRow => onDialogOpen({
                  size: 'sm',
                  title: 'Excluir?',
                  content: `Você confirma a exclusão da pergunta: "${pRow?.label ?? ''}" ?`,
                  actions: [
                    {
                      key: 1,
                      title: 'Sim',
                      action: () => {
                        onDialogActionLoading({ key: 1, loading: true })
                        onDelPergunta(
                          pRow.id,
                          onDialogClose,
                          () => onDialogActionLoading({ key: 1, loading: false }),
                        )
                      },
                      color: 'secondary',
                    },
                    { key: 2, title: 'Não', action: () => onDialogClose() },
                  ],
                }),
              },
            ]}
          />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          className={classes.linkTable}
          // onClick={() => setPerguntaSelected(row.id)}
          onClick={() => setOpen(!open)}
        >
          {`${!row.new ? `${row.id} - ` : ''} ${row.label}`}
        </TableCell>
        <TableCell align="left">{EChecklistItemType[row.type]}</TableCell>
        <TableCell align="right">{row.ordem}</TableCell>
        <TableCell align="right">{qtdAlternativas}</TableCell>
        <TableCell align="center">{EStatus[row.status]}</TableCell>
        <TableCell align="center">{ESimNao[row.isRequired]}</TableCell>
      </TableRow>
      <TableRow key={`d-${row.id}`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h6" gutterBottom component="div">
                    Alternativas
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!checkRoleUser('ROLE_ADMIN') && row.isParecer}
                    color="secondary"
                    aria-label="Adicionar uma nova alternativa"
                    component="span"
                    onClick={() => onLoadAlternativa(-1, row.id)}
                  >
                    <AddCircle />
                  </IconButton>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">AÇÃO</StyledTableCell>
                      <StyledTableCell>DESCRIÇÃO</StyledTableCell>
                      <StyledTableCell align="center">VALOR</StyledTableCell>
                      <StyledTableCell align="right">SELECIONADO</StyledTableCell>
                      <StyledTableCell align="right">OBRIGATÓRIO</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.orderBy(rowAlternativas, ['id'])
                      // .filter(a => a.checklistItensId === perguntaSelected)
                      .map(a => (
                        <TableRow key={a.id}>
                          <TableCell align="center">
                            <MoreActionsComp
                              row={a}
                              actions={[
                                {
                                  key: 'editar',
                                  title: 'Editar',
                                  icon: 'edit',
                                  disabled: !checkRoleUser('ROLE_ADMIN') && row.isParecer,
                                  action: pRow => onLoadAlternativa(pRow.id),
                                },
                                {
                                  key: 'excluir',
                                  title: 'Excluir',
                                  icon: 'delete',
                                  disabled: !checkRoleUser('ROLE_ADMIN') && row.isParecer,
                                  action: pRow => onDialogOpen({
                                    size: 'sm',
                                    title: 'Excluir?',
                                    content: `Você confirma a exclusão da alternativa: "${pRow?.label ?? ''}" ?`,
                                    actions: [
                                      {
                                        key: 1,
                                        title: 'Sim',
                                        action: () => {
                                          onDialogActionLoading({ key: 1, loading: true })
                                          onDelAlternativa(
                                            pRow.id,
                                            onDialogClose,
                                            () => onDialogActionLoading({ key: 1, loading: false }),
                                          )
                                        },
                                        color: 'secondary',
                                      },
                                      { key: 2, title: 'Não', action: () => onDialogClose() },
                                    ],
                                  }),
                                },
                              ]}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`${!a.new ? `${a.id} - ` : ''} ${a.label}`}
                          </TableCell>
                          <TableCell align="center">{a.value}</TableCell>
                          <TableCell align="right">{a.checked ? 'SIM' : 'NÃO'}</TableCell>
                          <TableCell align="right">{ESimNao[a.isRequired]}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
TableRowCollapseComp.propTypes = {
  row: PropTypes.shape().isRequired,
  rowAlternativas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  classes: PropTypes.shape().isRequired,
  checkRoleUser: PropTypes.func.isRequired,
  onLoadPergunta: PropTypes.func.isRequired,
  onDelPergunta: PropTypes.func.isRequired,
  onLoadAlternativa: PropTypes.func.isRequired,
  onDelAlternativa: PropTypes.func.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onDialogActionLoading: PropTypes.func.isRequired,
}


const GestorChecklistFormPage = (props) => {
  const {
    history,
    match: { params },
    auth: { session: { tenantId = '' } = {} },
    usuario: { usuario },
    checklist: {
      formChecklist = {}, formPeguntas = {},
    },
    onLoadCheckist,
    onFilterChecklist,
    onSaveChecklist,
    onDelChecklist,
    onChecklistFormChecklist,
    onLoadPergunta,
    onDelPergunta,
    onLoadAlternativa,
    onDelAlternativa,
    onDialogOpen,
    onDialogClose,
    onDialogActionLoading,
    checkRoleUser,
  } = props
  const classes = useStyles()
  const [pageConfig, setPageConfig] = useState({
    mode: 'insert',
    subtitle: 'Adicionar novo checklist',
  })
  const [filter, setFilter] = useState({
    params: {},
    page: 0,
    rows: 50,
  })
  const handleFilterChecklist = () => {
    onFilterChecklist(
      { ...filter.params, ...empFilByTenantId(tenantId) },
      filter.page,
      filter.rows,
    )
  }

  useEffect(() => {
    handleFilterChecklist()
  }, [tenantId])

  useEffect(() => {
    if (params.id) {
      setPageConfig(s => ({
        ...s,
        mode: 'update',
        subtitle: `Edição do checklist ID: ${params.id}`,
      }))
      onLoadCheckist(params.id)
    } else {
      onLoadCheckist(-1)
    }
  }, [])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <GestorHeaderComp title={`Gestor - ${usuario?.nome ?? ''}`} />
      <GestorDrawerComp {...props} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={1}>
          {/* Header */}
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" component="h2">
                  Checklist {params.id}
                </Typography>
              </Grid>
              {/* Menus */}
              <Grid item xs={12} md={6}>
                <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Tooltip title="Voltar">
                      <Fab
                        color="primary"
                        aria-label="Voltar"
                        className={classes.fab}
                        onClick={() => history.goBack()}
                      >
                        <Icon>undo</Icon>
                      </Fab>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Excluir">
                      <Fab
                        color="primary"
                        aria-label="Salvar"
                        className={classes.fab}
                        onClick={() => onDialogOpen({
                          size: 'sm',
                          title: 'Excluir?',
                          content: `Você confirma a exclusão do checklist: "${formChecklist?.descricao ?? ''}" ?`,
                          actions: [
                            {
                              key: 1,
                              title: 'Sim',
                              action: () => {
                                onDialogActionLoading({ key: 1, loading: true })
                                onDelChecklist(formChecklist.id, () => history.push('/gestor/checklist'))
                                onDialogClose()
                              },
                              color: 'secondary',
                            },
                            { key: 2, title: 'Não', action: () => onDialogClose() },
                          ],
                        })}
                      >
                        <Icon>delete</Icon>
                      </Fab>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Salvar">
                      <Fab
                        color="primary"
                        aria-label="Salvar"
                        className={classes.fab}
                        onClick={() => onSaveChecklist()}
                      >
                        <Icon>save</Icon>
                      </Fab>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Body */}
          <Grid item xs={12} md={12}>
            <Paper>
              <form className={classes.root} noValidate autoComplete="off">
                <Grid container direction="row" spacing={1}>
                  {/* Title Checklist */}
                  <Grid item xs={12}>
                    <Typography variant="h5" className={classes.instructions}>
                      Checklist
                    </Typography>
                  </Grid>
                  {/* Descrição do checklist */}
                  <Grid item xs={12} md={10}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="checklist-descricao">Descrição do checklist</InputLabel>
                      <Input
                        id="checklist-descricao"
                        label="Descrição do checklist"
                        value={formChecklist.descricao}
                        onChange={evt => onChecklistFormChecklist({
                          descricao: evt.target.value,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  {/* Status */}
                  <Grid item xs={12} md={2}>
                    <FormControl fullWidth>
                      <InputLabel id="checklist-status-label">Status</InputLabel>
                      <Select
                        labelId="checklist-status-label"
                        id="checklist-status"
                        value={formChecklist.status}
                        onChange={evt => onChecklistFormChecklist({
                          status: evt.target.value,
                        })}
                      >
                        <MenuItem value="">
                          <em>SELECIONE</em>
                        </MenuItem>
                        <MenuItem value="P">PENDENTE</MenuItem>
                        <MenuItem value="L">LIBERADO</MenuItem>
                        <MenuItem value="B">BLOQUEADO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Adicionar uma nova pergunta */}
                  <Grid item xs={12}>
                    <div className={classes.titleIcon}>
                      <Typography variant="h5" className={classes.instructions}>
                        Perguntas
                      </Typography>
                      <IconButton
                        color="secondary"
                        aria-label="Adicionar uma nova pergunta"
                        component="span"
                        onClick={() => onLoadPergunta(-1)}
                      >
                        <AddCircle />
                      </IconButton>
                    </div>
                  </Grid>
                  {/* Table */}
                  <Grid item xs={12} md={12}>
                    <TableContainer component={Paper}>
                      <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">AÇÃO</StyledTableCell>
                            <StyledTableCell>PERGUNTA</StyledTableCell>
                            <StyledTableCell align="left">TIPO</StyledTableCell>
                            <StyledTableCell align="right">ORDEM</StyledTableCell>
                            <StyledTableCell align="right">ALTERNATIVAS</StyledTableCell>
                            <StyledTableCell align="center">STATUS</StyledTableCell>
                            <StyledTableCell align="center">OBRIGATÓRIO</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {_.orderBy(formChecklist.itens, ['ordem']).map(row => (
                            <TableRowCollapseComp
                              row={row}
                              rowAlternativas={
                                formPeguntas?.alternativas?.filter(a => a.checklistItensId === row.id) ?? []
                              }
                              classes={classes}
                              checkRoleUser={checkRoleUser}
                              onLoadPergunta={onLoadPergunta}
                              onDelPergunta={onDelPergunta}
                              onLoadAlternativa={onLoadAlternativa}
                              onDelAlternativa={onDelAlternativa}
                              onDialogOpen={onDialogOpen}
                              onDialogClose={onDialogClose}
                              onDialogActionLoading={onDialogActionLoading}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </form>
            </Paper>
            <DialogFormPeguntas />
            <DialogformAlternativas />
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

GestorChecklistFormPage.propTypes = {
  auth: PropTypes.shape().isRequired,
  usuario: PropTypes.shape().isRequired,
  unidade: PropTypes.shape().isRequired,
  checklist: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
  checkRoleUser: PropTypes.func.isRequired,
  onLoadCheckist: PropTypes.func.isRequired,
  onFilterChecklist: PropTypes.func.isRequired,
  onSaveChecklist: PropTypes.func.isRequired,
  onDelChecklist: PropTypes.func.isRequired,
  onChecklistFormChecklist: PropTypes.func.isRequired,
  onLoadPergunta: PropTypes.func.isRequired,
  onDelPergunta: PropTypes.func.isRequired,
  onLoadAlternativa: PropTypes.func.isRequired,
  onDelAlternativa: PropTypes.func.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onDialogActionLoading: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...UsuarioActions,
    ...DialogActions,
    ...ConfigActions,
    ...ChecklistActions,
    // push: crrPush,
  },
  dispatch,
)

const mapStateToProps = state => ({
  auth: state.usuario.usuario.auth,
  usuario: state.usuario.usuario,
  unidade: state.usuario.usuario.unidade,
  checklist: state.checklist,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GestorChecklistFormPage)
