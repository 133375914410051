import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import _ from 'lodash'
// import classNames from 'classnames'
import {
  Paper, Grid, CircularProgress
} from '@material-ui/core'

import { Actions as GestorClientesActions } from '../../../../../../store/ducks/gestor/clientes'
import { Actions as DialogActions } from '../../../../../../store/ducks/dialog'
import TableDataComp from '../../../../../../components/Table'
// import BuscaGenericaComp from '../../../../../../components/Dialogs/Contents/BuscaGenerica'
// import { DialogContentSimples } from '../../../../../../components/Dialogs/Contents'
import useStyles from './styles'

const DadosCategoriasTabContent = (props) => {
  const {
    gestorClientes: { loading, cliente },
    push,
    onDialogOpen,
    onDialogClose,
    // onSearchCategorias,
    // onAddProdutoCategoria,
    // onDeleteProdutoCategoria,
  } = props
  const classes = useStyles()

  const tableCols = [
    { key: 'id', title: 'ID' },
    { key: 'nome', title: 'NOME' },
    { key: 'email', title: 'E-MAIL' },
    { key: 'statusDesc', title: 'STATUS' },
  ]

  const tableActionsList = [
    {
      key: 'detalhe',
      title: 'Detalhe',
      icon: 'list',
      action: row => push(`../usuarios/${row.id}`),
    },
  ]

  return loading.produto && (!cliente && !_.isArray(cliente.usuarios)) ? (
    <CircularProgress />
  ) : (
    <Paper className={classes.paper} elevation={1}>
      {/*
      <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
        <Grid item>
          <Tooltip title="Adicionar">
            <Fab
              disabled
              color="primary"
              aria-label="Add"
              className={classes.fab}
              onClick={() => onDialogOpen({
                size: 'sm',
                title: 'Adicionar novo usuário',
                content: (
                  <BuscaGenericaComp
                    rowKey="id"
                    rowValue="descricao"
                    placeholder="Buscar usuário"
                    onSelectItem={(item) => {
                      // onAddProdutoCategoria(item)
                      onDialogClose()
                    }}
                    onSearchHandle={ipt => null}
                  />
                ),
              })
              }
            >
              <Icon>add</Icon>
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
    */}
      <Grid container direction="row" justifyContent="flex-end" spacing={2}>
        <Grid item xs={12} md={12}>
          <TableDataComp
            loading={loading.cliente}
            actions={tableActionsList}
            cols={tableCols}
            rows={cliente.usuarios}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

DadosCategoriasTabContent.propTypes = {
  loading: PropTypes.shape().isRequired,
  gestorClientes: PropTypes.shape().isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onSearchCategorias: PropTypes.func.isRequired,
  onAddProdutoCategoria: PropTypes.func.isRequired,
  onDeleteProdutoCategoria: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  gestorClientes: state.gestorClientes,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...GestorClientesActions,
    push,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DadosCategoriasTabContent)
