import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'
import { API_URL } from 'api'

const ref = '/checklist-reposta-itens'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} token - { token: String }
 */
export const getChecklistRespostaItensByVistoriaIdApi = ({token, id}) => {
  return fetch(`${API_URL}${ref}/vistoria-id/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}
/**
 *
 * @param {String} token - { token: String }
 */
export const getChecklistRespostaItensByEntregaIdApi = ({token, id}) => {
  return fetch(`${API_URL}${ref}/entrega-id/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}



  


