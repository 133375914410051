import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import _ from 'lodash'
import {
  Paper, TextField, Grid, Button, CircularProgress, Divider,
} from '@material-ui/core'
import { Save } from '@material-ui/icons'

import { Actions as GestorClientesActions } from 'store/ducks/gestor/clientes'
import useStyles from './styles'
import ReactInputMask from 'react-input-mask'

const DadosProdutoTabContent = (props) => {
  const {
    gestorClientes: { loading, cliente },
    onSaveCliente,
    onUpdateClienteField,
    onUpdateClienteEnderecoField,
  } = props
  const classes = useStyles()
  
  const { endereco = {} } = cliente
  return loading.cliente && !cliente ? (
    <CircularProgress />
  ) : (
    <Paper className={classes.paper} elevation={1}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="codigo"
            label="Código:"
            margin="normal"
            variant="outlined"
            value={cliente.codigo}
            className={classes.textField}
            onChange={evt => onUpdateClienteField({ codigo: evt.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            fullWidth
            id="loja"
            label="Loja:"
            margin="normal"
            variant="outlined"
            value={cliente.loja}
            className={classes.textField}
            onChange={evt => onUpdateClienteField({ loja: evt.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="razao"
            label="Nome:"
            margin="normal"
            variant="outlined"
            value={cliente.nome}
            className={classes.textField}
            onChange={evt => onUpdateClienteField({ razao: evt.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            id="cgc"
            label="CPF:"
            margin="normal"
            inputProps={{
              maxlength: 14,
            }}
            variant="outlined"
            value={cliente.cgc}
            className={classes.textField}
            onChange={evt => onUpdateClienteField({ cgc: evt.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={3}>
          <ReactInputMask
            mask="(99) 9999-9999"
            value={cliente.telFixo}
            onChange={evt => onUpdateClienteField({ telFixo: evt.target.value.toUpperCase() })}
            maskChar="_"
          >
            {inputProps => (
              <TextField
                fullWidth
                id="telefone"
                label="Telefone"
                margin="normal"
                variant="outlined"
                value={inputProps.value}
                onChange={inputProps.onChange}
                className={classes.textField}
              />
            )}
          </ReactInputMask>
        </Grid>
        <Grid item xs={3}>
          <ReactInputMask
            mask="(99) 99999-9999"
            value={cliente.telefone}
            onChange={evt => onUpdateClienteField({ telefone: evt.target.value.toUpperCase() })}
            maskChar="_"
          >
            {inputProps => (
              <TextField
                fullWidth
                id="celular"
                label="Celular"
                margin="normal"
                variant="outlined"
                value={inputProps.value}
                onChange={inputProps.onChange}
                className={classes.textField}
              />
            )}
          </ReactInputMask>
        </Grid>

        <Grid item xs={3}>
          <TextField
            fullWidth
            disabled
            id="status"
            label="Status"
            margin="normal"
            variant="outlined"
            value={cliente.statusDesc}
            className={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={7} md={7}>
          <TextField
            autoComplete="off"
            label="Logradouro"
            fullWidth
            margin="normal"
            variant="outlined"
            value={endereco.logradouro}
            onChange={evt => onUpdateClienteEnderecoField({
              logradouro: evt.target.value.toUpperCase(),
            })
            }
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <TextField
            autoComplete="off"
            label="Número"
            fullWidth
            margin="normal"
            variant="outlined"
            value={endereco.numero}
            onChange={evt => onUpdateClienteEnderecoField({
              numero: evt.target.value.toUpperCase(),
            })
            }
          />
        </Grid>
        <Grid item xs={3} md={3}>
          <TextField
            autoComplete="off"
            label="Complemento"
            fullWidth
            margin="normal"
            variant="outlined"
            value={endereco.complemento}
            onChange={evt => onUpdateClienteEnderecoField({
              complemento: evt.target.value.toUpperCase(),
            })
            }
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <TextField
            label="Bairro"
            fullWidth
            margin="normal"
            variant="outlined"
            value={endereco.bairro}
            onChange={evt => onUpdateClienteEnderecoField({
              bairro: evt.target.value.toUpperCase(),
            })
            }
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <TextField
            autoComplete="off"
            label="Cidade"
            fullWidth
            margin="normal"
            variant="outlined"
            value={endereco.cidade}
            onChange={evt => onUpdateClienteEnderecoField({
              cidade: evt.target.value.toUpperCase(),
            })
            }
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <TextField
            autoComplete="off"
            label="CEP"
            fullWidth
            margin="normal"
            variant="outlined"
            value={endereco.cep}
            inputProps={{
              maxlength: 8,
            }}
            onChange={evt => onUpdateClienteEnderecoField({
              cep: evt.target.value.toUpperCase(),
            })
            }
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <TextField
            autoComplete="off"
            label="UF"
            fullWidth
            margin="normal"
            variant="outlined"
            value={endereco.uf}
            inputProps={{
              maxlength: 2,
            }}
            onChange={evt => onUpdateClienteEnderecoField({
              uf: evt.target.value.toUpperCase(),
            })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={6}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onSaveCliente}
              >
                <Save className={classes.leftIcon} />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

DadosProdutoTabContent.propTypes = {
  loading: PropTypes.shape().isRequired,
  gestorClientes: PropTypes.shape().isRequired,
  onUpdateClienteField: PropTypes.func.isRequired,
  onUpdateClienteEnderecoField: PropTypes.func.isRequired,
  onSaveCliente: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  gestorClientes: state.gestorClientes,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...GestorClientesActions,
    push,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DadosProdutoTabContent)
