import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
    Typography,
    Card,
    Grid,
    Fab,
    Icon,
    Tooltip,
    TextField,
    InputAdornment,
    IconButton,
    CssBaseline,
    Input,
    Button,
    CircularProgress,
    DialogTitle,
    Dialog,
    DialogContent,
    DialogContentText,
} from '@material-ui/core'

// import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import GestorHeaderComp from '../../../../components/Gestor/Header'
import GestorDrawerComp from '../../../../components/Gestor/Drawer'
import TableDataComp from '../../../../components/Table'
import useStyles from './styles'
import { cancelVisitaApi, deleteVisitaApi, getVisitasFilterApi } from 'api/visitas'
import { Pagination } from '@material-ui/lab'



const GestorVisitasListPage = (props) => {
    const {
        usuario, push, onDialogOpen, onDialogClose
    } = props
    const classes = useStyles()

    // State
    const [listFilter, setListFilter] = useState([])
    const [filter, setFilter] = useState({
        rows: 15,
        search: '',
    });
    const [currentPage, setCurrentPage] = useState(1)
    const [payload, setPayload] = useState([]);
    const [loading, setLoading] = useState(false);
    const onSearchVisitas = (page = 1) => {
        setLoading(true);
        const updatedFilter = { ...filter, page: page - 1, rows: 15 };
        if (page == 1 && currentPage != page) {
            setCurrentPage(page);
        }
        else {
            getVisitasFilterApi({ token: usuario?.auth?.token ?? '', query: updatedFilter })
                .then((resp) => {
                    setPayload(resp);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    const tableCols = [
        { key: 'data', title: 'DATA' },
        { key: 'visStatusDesc', title: 'STATUS' },
        { key: 'visResultadoDesc', title: 'RESULTADO' },
        { key: '', title: 'UNIDADE CONSUMIDORA' },
        { key: 'entrevNome', title: 'ENTREVISTADO' },
        { key: 'entrevRespImovel', title: 'IMÓVEL' },
        { key: 'respNome', title: 'RESPONSÁVEL' },
        { key: 'termoNum', title: 'TERMO' },
        { key: 'substLampRecebeu', title: 'SUBST LÂMPADA' },
    ]


    useEffect(() => {
        document.title = 'Online - Visitas'
        document.body.style = 'background-image: none;'
        onSearchVisitas()
    }, [])
    useEffect(() => {
        onSearchVisitas(currentPage);
    }, [currentPage]);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    const onIptSearchChange = (evt) => {
        evt.persist();
        setFilter(s => ({ ...s, search: evt?.target?.value?.toUpperCase() ?? '' }))
    }
    const tableActionsList = [
        // ...
        
        {
            key: 'cancelar',
            title: 'Cancelar',
            icon: 'ban',
            action: (row) => () => handleOpenCancel(row),
        },
        {
            key: 'deletar',
            title: 'Deletar',
            icon: 'trash',
            action: (row) => () => handleOpenDelete(row),
        }
    ];
    const handleConfirmCancel = () => {
        if (selectedRow) {
            cancelVisitaApi({ token: usuario?.auth?.token ?? '', id: selectedRow.id });
        }
        handleClose();
    };

    const handleConfirmDelete = () => {
        if (selectedRow) {
            deleteVisitaApi({ token: usuario?.auth?.token ?? '', id: selectedRow.id });
        }
        handleClose();
    };
    const handleOpenCancel = (row) => {
        setSelectedRow(row);
        setOpenCancel(true);
    };

    const handleOpenDelete = (row) => {
        setSelectedRow(row);
        setOpenDelete(true);
    };

    const handleClose = () => {
        setSelectedRow(null);
        setOpenCancel(false);
        setOpenDelete(false);
    };
    const [openCancel, setOpenCancel] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    return (
        <div className={classes.root}>
            <CssBaseline />
            <GestorHeaderComp title={`Portal de Vendas - ${usuario.nome}`} />
            <GestorDrawerComp {...props} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h3" component="h2">
                                    Visitas
                                </Typography>
                            </Grid>
                            {/* Menus */}
                            <Grid item xs={12} md={6}>
                                <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                                    <Grid item>
                                        <TextField
                                            id="outlined-adornment-password"
                                            className={classNames(classes.margin, classes.textField)}
                                            variant="outlined"
                                            label="Pesquisar"
                                            value={filter?.search ?? ''}
                                            onChange={onIptSearchChange}
                                            onKeyPress={(evt) => {
                                                if (evt.charCode === 13) {
                                                    onSearchVisitas()
                                                }
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="Pesquisar"
                                                            onClick={onSearchVisitas}
                                                        >
                                                            <Icon>search</Icon>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Body */}
                    <Grid item xs={12} md={12}>
                        {loading ? (
                            <div className={classes.loadingContainer}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <TableDataComp size="small" loading={false} rows={payload} cols={tableCols} actions={tableActionsList} />
                        )}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div id="mui-pagination">
                            <Pagination
                                count={payload.total ?? 0}
                                page={currentPage} // Add 1 to match the paginator index
                                onChange={handlePageChange}
                                color="primary"
                                className={classes.pagination}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Dialog open={openCancel} onClose={handleClose}>
                    <DialogTitle>Cancelar Visita</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Tem certeza de que deseja cancelar a visita?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={handleConfirmCancel} color="primary">
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openDelete} onClose={handleClose}>
                    <DialogTitle>Deletar Visita</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Tem certeza de que deseja deletar a visita?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={handleConfirmDelete} color="primary">
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </main>
        </div>
    )
}

GestorVisitasListPage.propTypes = {
    usuario: PropTypes.shape().isRequired,
    push: PropTypes.func.isRequired,
    onDialogOpen: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        ...ConfigActions,
        push: crrPush,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GestorVisitasListPage)
