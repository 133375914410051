import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import _ from 'lodash'
// import classNames from 'classnames'
import {
  Paper, Icon, Grid, CircularProgress, Fab, Tooltip,
} from '@material-ui/core'

import { Actions as GestorClientesActions } from '../../../../../../store/ducks/gestor/clientes'
import { Actions as DialogActions } from '../../../../../../store/ducks/dialog'
import TableDataComp from '../../../../../../components/Table'
import BuscaGenericaComp from '../../../../../../components/Dialogs/Contents/BuscaGenerica'
import { DialogContentSimples } from '../../../../../../components/Dialogs/Contents'
import useStyles from './styles'

const DadosCategoriasTabContent = (props) => {
  const {
    gestorClientes: { loading, cliente },
    onDialogOpen,
    onDialogClose,
    onSearchUnidades,
    onAddClienteUnidade,
    onDeleteClienteUnidade,
  } = props
  const classes = useStyles()

  const tableCols = [
    { key: 'id', title: 'ID' },
    { key: 'empresaCod', title: 'EMPRESA' },
    { key: 'filialCod', title: 'FILIAL' },
    { key: 'descricao', title: 'DESCRIÇÃO' },
  ]

  const tableActionsList = [
    {
      key: 'remover',
      title: 'Remover',
      icon: 'delete',
      action: row => onDialogOpen({
        size: 'sm',
        title: 'Remover?',
        content: (
          <DialogContentSimples
            text={`Você confirma a remoção da unidade: "${row.descricao}" da lista de acesso para esse cliente?`}
          />
        ),
        actions: [
          {
            key: 1,
            title: 'SIM',
            action: () => {
              onDeleteClienteUnidade(row)
              onDialogClose()
            },
          },
          { key: 2, title: 'NÃO', action: () => onDialogClose() },
        ],
      }),
    },
  ]

  return loading.produto && (!cliente && !_.isArray(cliente.unidades)) ? (
    <CircularProgress />
  ) : (
    <Paper className={classes.paper} elevation={1}>
      <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
        <Grid item>
          <Tooltip title="Adicionar">
            <Fab
              color="primary"
              aria-label="Add"
              className={classes.fab}
              onClick={() => onDialogOpen({
                size: 'sm',
                title: 'Adicionar nova unidade',
                content: (
                  <BuscaGenericaComp
                    rowKey="id"
                    rowValue="descricao"
                    placeholder="Buscar unidade"
                    onSelectItem={(item) => {
                      onAddClienteUnidade(item)
                      onDialogClose()
                    }}
                    onSearchHandle={ipt => onSearchUnidades(ipt)}
                  />
                ),
              })
              }
            >
              <Icon>add</Icon>
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" spacing={2}>
        <Grid item xs={12} md={12}>
          <TableDataComp
            loading={loading.cliente}
            actions={tableActionsList}
            cols={tableCols}
            rows={cliente.unidades}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

DadosCategoriasTabContent.propTypes = {
  loading: PropTypes.shape().isRequired,
  gestorClientes: PropTypes.shape().isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onSearchUnidades: PropTypes.func.isRequired,
  onAddClienteUnidade: PropTypes.func.isRequired,
  onDeleteClienteUnidade: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  gestorClientes: state.gestorClientes,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...GestorClientesActions,
    push,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DadosCategoriasTabContent)
