import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from 'api'

const ref = '/tracking'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - { token: String, page, rows, search, estado, municipio }
 */
export const getTrackingFilterApi = (dto) => {
  const { token, query } = dto;
  let queryUrl = `?1=1`;

  if (query.search) {
    queryUrl += `&search=${query.search}`;
  }
  if (query.agenteId) {
    queryUrl += `&crmAgenteId=${query.agenteId}`;
  }
  if (query.startDate) {
    queryUrl += `&startDate=${query.startDate}`;
  }
  if (query.finalDate) {
    queryUrl += `&finalDate=${query.finalDate}`;
  }

  return fetch(`${API_URL}${ref}/${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros);
};
