import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'

import { API_URL } from 'api'

const ref = '/visita_imagem'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}


/**
 *
 * @param {String} dto - { token: String, id }
 */
export const findByVisitaIdVisitaImagemApi = ({token, id}) => {
    
    return fetch(`${API_URL}${ref}/find_by_visita/${id}`, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(httpParseJson)
      .then(httpValidState)
      .catch(httpValidErros)
  }