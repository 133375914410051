import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import DateRanger from '@material-ui/icons/DateRange'

import styles from './styles'

const FiltroPedidoDialog = ({
  classes, open, title, onClose, onConfirm, onChange, filtro,
}) => (
  <Dialog maxWidth="xs" open={open} onClose={onClose} aria-labelledby="responsive-dialog-title">
    <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <Grid
        container
        className={classes.root}
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={10}>
          <TextField
            fullWidth
            label="Pedido"
            className={classes.margin}
            value={filtro.pedido}
            onChange={evt => onChange({ pedido: evt.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            type="date"
            label="Data Inicial"
            className={classes.margin}
            value={filtro.dtInicial}
            onChange={evt => onChange({ dtInicial: evt.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DateRanger />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            type="date"
            label="Data Final"
            className={classes.margin}
            value={filtro.dtFinal}
            onChange={evt => onChange({ dtFinal: evt.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DateRanger />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary">
        Cancelar
      </Button>
      <Button onClick={onConfirm} color="primary" autoFocus>
        Filtrar
      </Button>
    </DialogActions>
  </Dialog>
)

FiltroPedidoDialog.propTypes = {
  classes: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  filtro: PropTypes.shape().isRequired,
}

export default withStyles(styles)(FiltroPedidoDialog)
