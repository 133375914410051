import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'

import {
    Grid,
    Icon,
    TextField,
    InputAdornment,
    IconButton,
    CssBaseline,
    Paper,
    Divider,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Typography,
    Button,
} from '@material-ui/core'

import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import TableDataComp from '../../../../../../../../components/Table'
import useStyles from './styles'
import { findByIdVisitaApi, getVisitasFilterApi } from 'api/visitas'
import { DialogContentSimples } from 'components/Dialogs/Contents'
import { editByIdUcApi, findByIdUnidadeConsumidoraApi } from 'api/unidade_consumidora'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import { Save } from '@material-ui/icons'
import { NotificationManager } from 'react-notifications'
import ReactInputMask from 'react-input-mask'

const VisitaUcDetailTab = (props) => {
    const {
        usuario, push, params
    } = props
    const classes = useStyles()

    // State
    const [listFilter, setListFilter] = useState([])
    const [filter, setFilter] = useState({
        page: 0,
        rows: 100,
        search: '',
    })
    const [payload, setpayload] = useState([])
    const tableCols = [
        { key: 'unidNumero', title: 'NUMR.UNIDADE' },
        { key: 'clienteNome', title: 'RESPONSAVEL' },
        { key: 'municipio', title: 'MUNICÍPIO' },
        { key: 'bairro', title: 'BAIRRO' },
        { key: 'subBairro', title: 'SUBBAIRRO' },
        { key: 'cep', title: 'CEP' },
        { key: 'crmAgente', title: 'AGENTE' },
        { key: 'endPontRef', title: 'REFERÊNCIA' },
    ]

    const onUpdatePayloadField = (updateObj) => {
        setpayload((prevPayload) => ({ ...prevPayload, ...updateObj }));
    };

    const OnSerachUc = () => {
        findByIdVisitaApi({ token: usuario?.auth?.token ?? '', id: params.id })
            .then((resp) => {
                if (resp.unidadeConsumidoraId) {
                    findByIdUnidadeConsumidoraApi({ token: usuario?.auth?.token ?? '', id: resp.unidadeConsumidoraId })
                        .then((resp) => {
                            setpayload(resp)
                        })
                } else {
                    // setpayload([]);
                }
            });
    }
    const editUc = async (payload, token) => {
        const resp = await editByIdUcApi({ token: usuario?.auth?.token, id: payload?.id, body: payload });
        setpayload(resp);
        NotificationManager.success(
            'Editado com sucesso!',
            'Atenção',
            8000,
        )
    }

    useEffect(() => {
        document.title = 'Online - Visita'
        document.body.style = 'background-image: none;'
        OnSerachUc()
    }, [])
    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <Grid container spacing={2}>
                    {/* Body */}
                    <Paper className={classes.paper} elevation={1}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="nuemro"
                                    label="Numero:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload['unidNumero'] ? payload['unidNumero'].toString().toUpperCase() : ""}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ unidNumero: evt.target.value.toUpperCase() })}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    disabled
                                    id="status"
                                    label="Nome"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload['clienteNome'] ? payload['clienteNome'].toString().toUpperCase() : ''}
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    disabled
                                    id="respCgc"
                                    label="CPF"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload['respCgc'] ? payload['respCgc'].toString().toUpperCase() : ''}
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider variant="middle" className={classes.customDivider} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    disabled
                                    id="municipio"
                                    label="Município:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload['municipio'] ? payload['municipio'].toString().toUpperCase() : ""}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ municipio: evt.target.value.toUpperCase() })}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    disabled
                                    fullWidth
                                    id="bairro"
                                    label="Bairro:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload['bairro'] ? payload['bairro'].toString().toUpperCase() : ""}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ bairro: evt.target.value.toUpperCase() })}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    disabled
                                    id="subBairro"
                                    label="Sub Bairro"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload['subBairro'] ? payload['subBairro'].toString().toUpperCase() : ''}
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <ReactInputMask
                                    mask="99999-999"
                                    value={payload['cep'] ? payload['cep'].toString().toUpperCase() : ''}
                                    onChange={evt => onUpdatePayloadField({ cep: evt.target.value.toUpperCase() })}
                                >
                                    {() => (
                                        <TextField
                                            fullWidth
                                            id="cep"
                                            label="CEP"
                                            margin="normal"
                                            variant="outlined"
                                            className={classes.textField}
                                        />
                                    )}
                                </ReactInputMask>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="endereco"
                                    label="Endereço"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload['enderecoNome'] ? payload['enderecoNome'].toString().toUpperCase() : ''}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ enderecoNome: evt.target.value.toUpperCase() })}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="complemento"
                                    label="Complemento"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload['complementoNome'] ? payload['complementoNome'].toString().toUpperCase() : ''}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ complementoNome: evt.target.value.toUpperCase() })}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="numero"
                                    label="Número"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload['numeroNum'] ? payload['numeroNum'].toString().toUpperCase() : ''}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ numeroNum: evt.target.value.toUpperCase() })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider variant="middle" className={classes.customDivider} />
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth variant="outlined" >
                                    <InputLabel id="subst-lamp-label">Subst lamp:</InputLabel>
                                    <Select
                                        labelId="subst-lamp-label"
                                        id="subst-lamp"
                                        value={payload.substLampRecebeu ? payload.substLampRecebeu : ''}
                                        onChange={evt => onUpdatePayloadField({ substLampRecebeu: evt.target.value })}
                                        label="Subst lamp"
                                        className={classes.selectField}
                                    >
                                        <MenuItem value="">Selecione</MenuItem>
                                        <MenuItem value="SIM">SIM</MenuItem>
                                        <MenuItem value="NÃO">NÃO</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="subst-qtd-lamp-incand"
                                    label="Qtd lamp incand:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload.substQtdLampIncand ? payload.substQtdLampIncand.toString().toUpperCase() : ""}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ substQtdLampIncand: evt.target.value })}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    id="subst-pot-lamp-incand"
                                    label="Pot lamp incand:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload.substPotLampIncand ? payload.substPotLampIncand.toString().toUpperCase() : ''}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ substPotLampIncand: evt.target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography variant="body2">W</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="subst-qtd-lamp-fluore"
                                    label="Qtd. lamp fluor:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload.substQtdLampFluore ? payload.substQtdLampFluore.toString().toUpperCase() : ''}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ substQtdLampFluore: evt.target.value })}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    id="subst-pot-lamp-fluore"
                                    label="Pot lamp fluor:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload.substPotLampFluore ? payload.substPotLampFluore.toString().toUpperCase() : ''}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ substPotLampFluore: evt.target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography variant="body2">W</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider variant="middle" className={classes.customDivider} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="leitura-qtd-lampadas"
                                    label="Qtd lampadas:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload.leituraQtdLampadas ? payload.leituraQtdLampadas.toString() : ""}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ leituraQtdLampadas: evt.target.value })}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="leitura-pot-lampadas"
                                    label="Pot lampadas:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload.leituraPotLampadas ? payload.leituraPotLampadas.toString() : ""}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ leituraPotLampadas: evt.target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography variant="body2">W</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="leitura-qtd-outros-equip"
                                    label="Qtd outros equipamentos:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload.leituraQtdOutrosEquip ? payload.leituraQtdOutrosEquip.toString() : ""}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ leituraQtdOutrosEquip: evt.target.value })}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="leitura-pot-outros-equip"
                                    label="Pot outros equipamentos:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload.leituraPotOutrosEquip ? payload.leituraPotOutrosEquip.toString() : ""}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ leituraPotOutrosEquip: evt.target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography variant="body2">W</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider variant="middle" className={classes.customDivider} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="leitura-qtd-geladeira"
                                    label="Qtd geladeira:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload.leituraQtdGeladeira ? payload.leituraQtdGeladeira.toString() : ""}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ leituraQtdGeladeira: evt.target.value })}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="leitura-pot-geladeira"
                                    label="Pot geladeira:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload.leituraPotGeladeira ? payload.leituraPotGeladeira.toString() : ""}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ leituraPotGeladeira: evt.target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography variant="body2">W</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="leitura-qtd-chuveiro"
                                    label="Qtd chuveiro:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload.leituraQtdChuveiro ? payload.leituraQtdChuveiro.toString() : ""}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ leituraQtdChuveiro: evt.target.value })}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="leitura-pot-chuveiro"
                                    label="Pot chuveiro:"
                                    margin="normal"
                                    variant="outlined"
                                    value={payload.leituraPotChuveiro ? payload.leituraPotChuveiro.toString() : ""}
                                    className={classes.textField}
                                    onChange={evt => onUpdatePayloadField({ leituraPotChuveiro: evt.target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography variant="body2">W</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={6}>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            onClick={() => editUc(payload, usuario?.auth?.token)}
                                            style={{ marginBottom: '1rem' }}
                                        >
                                            <Save className={classes.leftIcon} />
                                            Salvar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </main>
        </div>
    )
}

VisitaUcDetailTab.propTypes = {
    push: PropTypes.func.isRequired,
    onDialogOpen: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario,
    gestorUsuarios: state.gestorUsuarios,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        ...DialogActions,
        push: crrPush,
    },
    dispatch,
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VisitaUcDetailTab)
