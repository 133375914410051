import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import styles from './styles'

const ClienteObraDialog = ({
  classes, open, onClose, onSelectPrecoObra, data, loading,
}) => {
  const { obras = [] } = data
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>OBRAS:</DialogTitle>
      <DialogContent>
        {loading && <CircularProgress size={24} color="secondary" />}
        {!loading && obras.length > 0 && (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.row}>OBRA</TableCell>
                <TableCell className={classes.row}>PRODUTO</TableCell>
                <TableCell className={[classes.cell, classes.row].join(' ')}>CIF</TableCell>
                <TableCell className={[classes.cell, classes.row].join(' ')}>FOB</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {obras.map(o => (
                <TableRow key={o.id}>
                  <TableCell component="th" scope="row" className={classes.row}>
                    <Typography variant="body2" component="strong" color="primary">
                      {`[${o.obra}] ${o.nome}`}
                    </Typography>
                    {`${o.logradouro}, ${o.bairro} ${o.municipio}`}
                  </TableCell>
                  <TableCell component="th" scope="row" className={classes.row}>
                    <Typography
                      variant="body2"
                      component="strong"
                      color="secondary"
                      className={classes.descricao}
                    >
                      {o.produtoDesc}
                    </Typography>
                    CÓD.:
                    <strong className={classes.descricao}>{o.produtoSku}</strong>
                    U.M.:
                    <strong>{o.produtoUM}</strong>
                  </TableCell>
                  <TableCell className={[classes.cell, classes.row2].join(' ')}>
                    <Button
                      disabled={o.cif <= 0}
                      variant="outlined"
                      onClick={() => onSelectPrecoObra('cif', o)}
                    >
                      CIF
                    </Button>
                  </TableCell>
                  <TableCell
                    disabled={o.fob <= 0}
                    className={[classes.cell, classes.row].join(' ')}
                    style={{ padding: 0 }}
                  >
                    <Button variant="outlined" onClick={() => onSelectPrecoObra('fob', o)}>
                      FOB
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {!loading && obras.length === 0 && (
          <Typography variant="h6" gutterBottom>
            Nenhuma obra cadastrada.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  )
}

ClienteObraDialog.propTypes = {
  classes: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectPrecoObra: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
}

export default withStyles(styles)(ClienteObraDialog)
