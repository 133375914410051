import { push } from 'connected-react-router'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'

import { authActionApi } from '../../api/auth'
import {
  getUserDataApi,
  onUserForgotApi,
  onUserNewPswApi,
  onAprovarReprovarUsuarioApi,
} from '../../api/usuario'
import {
  syncClienteProtheusApi,
  syncProdutosProtheusApi,
  getLimiteCredito,
  getTitulosVencidos,
} from '../../api/cliente'
import { getConfigApi } from '../../api/config'
import { EventosEnum } from '../../utils/enums/eventos'
import { Actions as ConfigActions } from './config'
import { Actions as CarrinhoActions } from './carrinho'
import { Actions as ProdutoActions } from './produto'
// import { Actions as NofityActions } from './notificacoes'

// State Usuario
const initialState = {
  loading: false,
  loadLimiteCredito: false,
  reCaptcha: '',
  error: '',
  redirectTo: '',
  usuario: {
    id: 0,
    nome: '',
    email: '',
    senha: '',
    status: '',
    perfils: [],
    cliente: {},
    clientes: [],
    unidade: {},
    unidades: [],
    auth: {
      id: 0,
      token: '',
    },
    limiteCredito: {
      codigo: '',
      loja: '',
      risco: '',
      limite: 0,
      devedor: 0,
      saldo: 0,
    },
    titulosVencidos: 0,
  },
  dialogForgot: {
    open: false,
    title: '',
    msg: '',
  },
  formForgot: {
    id: null,
    name: '',
    token: '',
    senha: '',
    resenha: '',
  },
}

// Actions Types
export const Types = {
  RESET_LOGIN_PAGE: 'usuario/RESET_LOGIN_PAGE',
  LOADING: 'usuario/LOADING',
  LOADING_LIMITE_CREDITO: 'usuario/LOADING_LIMITE_CREDITO',
  RECAPTCHA: 'usuario/RECAPTCHA',
  LOGIN_ERROR: 'usuario/LOGIN_ERROR',
  LOGIN_REDIRECT_TO: 'usuario/LOGIN_REDIRECT_TO',
  LOGOUT: 'usuario/LOGOUT',
  FIELD_UPDATE: 'usuario/FIELD_UPDATE',
  USUARIO: 'usuario/USUARIO',
  CLIENTE: 'usuario/CLIENTE',
  DIALOG_FORGOT: 'usuario/DIALOG_FORGOT',
  FORM_FORGOT: 'usuario/FORM_FORGOT',
  SELECT_UNIDADE: 'usuario/SELECT_UNIDADE',
  APROVA_REPROVA_USUARIO: 'usuario/APROVA_REPROVA_USUARIO',
}

// Reducers
export default function usuarioReducers(state = initialState, action) {
  switch (action.type) {
    case Types.RESET_LOGIN_PAGE:
      return {
        ...state,
        ...action.payload,
      }
    case Types.LOADING:
      return { ...state, loading: action.payload }
    case Types.LOADING_LIMITE_CREDITO:
      return { ...state, loadLimiteCredito: action.payload }
    case Types.RECAPTCHA:
      return { ...state, reCaptcha: action.payload }
    case Types.LOGIN_ERROR:
      return { ...state, error: action.payload }
    case Types.LOGIN_REDIRECT_TO:
      return { ...state, redirectTo: action.payload }
    case Types.LOGOUT:
      return { ...state, usuario: { ...initialState.usuario } }
    case Types.FIELD_UPDATE:
      return { ...state, usuario: { ...state.usuario, ...action.payload } }
    case Types.USUARIO:
      return { ...state, usuario: action.payload }
    case Types.CLIENTE:
      return { ...state, cliente: action.payload }
    case Types.DIALOG_FORGOT:
      return { ...state, dialogForgot: { ...state.dialogForgot, ...action.payload } }
    case Types.FORM_FORGOT:
      return { ...state, formForgot: { ...state.formForgot, ...action.payload } }
    case Types.SELECT_UNIDADE:
      return { ...state, usuario: { ...state.usuario, unidade: { ...action.payload } } }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  onResetLoginPage: () => (dispatch, getState) => {
    const {
      usuario: { error, redirectTo },
    } = getState()
    let payload = initialState
    if (error || redirectTo) {
      payload = {
        ...initialState,
        error,
        redirectTo,
      }
    }
    dispatch({ type: Types.RESET_LOGIN_PAGE, payload })
  },
  onSelectUnidade: payload => ({ type: Types.SELECT_UNIDADE, payload }),
  onFieldUpdate: field => (dispatch) => {
    dispatch({ type: Types.FIELD_UPDATE, payload: field })
  },
  onFieldUpdateFormForgot: field => (dispatch) => {
    dispatch({ type: Types.FORM_FORGOT, payload: field })
  },
  onUpdateDialogForgot: field => (dispatch) => {
    dispatch({ type: Types.DIALOG_FORGOT, payload: field })
  },
  onReCaptcha: field => (dispatch) => {
    dispatch({ type: Types.RECAPTCHA, payload: field })
  },
  onLoading: status => (dispatch) => {
    dispatch({ type: Types.LOADING, payload: status })
  },
  onLoadingLimiteCredito: (payload = false) => dispatch => dispatch({
    type: Types.LOADING_LIMITE_CREDITO,
    payload,
  }),
  checkRoleUser: pRole => (dispatch, getState) => {
    const {
      usuario: { usuario: { perfils } },
    } = getState()

    return perfils.find(p => p.role === pRole)
  },
  // Rotina de autenticação do sistema.
  onLoginAction: (email, senha) => async (dispatch, getState) => {
    const {
      usuario: { redirectTo },
    } = getState()
    dispatch(Actions.onLoading(true))
    // Reset dados anterior
    dispatch(CarrinhoActions.onResetCarrinho())
    dispatch(ProdutoActions.onResetProduto())
    // Validações basica
    if (!email || !senha) {
      NotificationManager.warning('Usuário ou senha inválido.', 'Atenção', 8000)
      dispatch(Actions.onLoading(false))
      return false
    }
    const auth = await authActionApi({ email, senha })
    if (auth) {
      // Autenticado com sucesso, pegando dados do usuário.
      getUserDataApi({ ...auth }).then(async (data) => {
        const usuario = { ...data, senha: '', auth }
        const { clientes = [] } = usuario
        // CLIENTES - Validações para o usuário do perfil (tipo)
        if (usuario.perfils.find(p => p.role === 'ROLE_CLIENTE')) {
          if (!Actions.onAuthClientValidAction(usuario)) {
            dispatch(Actions.onLoading(false))
            return false
          }
          // Se deu tudo certo vamos bricar agora :D
          // Selecionando a primeira filial possível
          const cliente = clientes.find(f => f.status === 'L' && f.unidades.length > 0)
          Object.assign(usuario, {
            role: 'ROLE_CLIENTE',
            cliente,
            unidade: cliente.unidades[0],
            unidades: cliente.unidades,
            limiteCredito: {
              limite: 0,
            },
          })
          // Atualizando limite de crédito do cliente.
          dispatch(Actions.onLoadLimiteCreditoCliente(usuario))
          // Atualizando titulos vencidos do cliente
          dispatch(Actions.onLoadTitulosVencidosCliente(usuario))
        }
        if (usuario.perfils.find(p => p.role === 'ROLE_ADMIN')) {
          if (!Actions.onAuthAdminValidAction(usuario)) {
            dispatch(Actions.onLoading(false))
            return false
          }
          Object.assign(usuario, {
            role: 'ROLE_ADMIN',
            unidade: usuario.unidades[0],
            unidades: usuario.unidades,
          })
        }
        if (usuario.perfils.find(p => p.role === 'ROLE_VENDEDOR')) {
          if (!Actions.onAuthVendedorValidAction(usuario)) {
            dispatch(Actions.onLoading(false))
            return false
          }
          Object.assign(usuario, {
            role: 'ROLE_VENDEDOR',
            cliente: {},
            unidade: usuario.unidades[0],
            unidades: usuario.unidades,
          })
        }
        if (usuario.perfils.find(p => p.role === 'ROLE_CRM')) {
          if (!Actions.onAuthVendedorValidAction(usuario)) {
            dispatch(Actions.onLoading(false))
            return false
          }
          Object.assign(usuario, {
            role: 'ROLE_CRM',
            cliente: {},
            unidade: usuario.unidades[0],
            unidades: usuario.unidades,
          })
        }
        if (usuario.perfils.find(p => p.role === 'ROLE_GESTOR')) {
          if (!Actions.onAuthVendedorValidAction(usuario)) {
            dispatch(Actions.onLoading(false))
            return false
          }
          Object.assign(usuario, {
            role: 'ROLE_GESTOR',
            cliente: {},
            unidade: usuario.unidades[0],
            unidades: usuario.unidades,
          })
        }
        // Atualizando a STORE com os dados ajustados conforme o PERFIL do usuário!
        dispatch(Actions.onLoginSuccess(usuario))
        // Recuperando as configurações do usuário ( Formulários e Parâmetros )...
        // Get Configs ( Formulários e Parâmetros )
        const configs = await getConfigApi({ ...auth })
        dispatch(ConfigActions.onLoadConfig(configs))
        // Log Eventos
        dispatch(ConfigActions.onAddEvento(EventosEnum.LOGIN_PORTAL_SUCESSO))
        // ! Regras de redirecionamento...
        if (usuario.role === 'ROLE_CLIENTE') {
          // * Rodando o fluxo de atualização de Produtos/Preços para o Perfil => CLIENTE,
          // * e em seguida é feito o Redirecionamento.
          const smTemplate = dispatch(ConfigActions.getParametro('SM_TEMPLATE', 0))
          if (smTemplate === 0) {
            syncProdutosProtheusApi({
              token: auth.token,
              empresaCod: usuario.unidade.empresaCod,
              filialCod: usuario.unidade.filialCod,
              clienteCod: usuario.cliente.codigo,
              clienteLoja: usuario.cliente.loja,
            }).then(() => {
              dispatch(Actions.onLoading(false))
              // Um redirect
              if (redirectTo) {
                dispatch(Actions.onLoginErrorValid(''))
                dispatch(Actions.onLoginRedirectTo(''))
                dispatch(push(redirectTo))
              }
              document.title = 'Online'
              document.body.style = 'background-image: none;'
              // Nav to home
              dispatch(push('/'))
            })
          }
          if (smTemplate === 1) {
            syncClienteProtheusApi({
              token: auth.token,
              empresaCod: usuario.unidade.empresaCod,
              filialCod: usuario.unidade.filialCod,
              clienteCod: usuario.cliente.codigo,
              clienteLoja: usuario.cliente.loja,
            }).then(() => {
              dispatch(Actions.onLoading(false))
              // Um redirect
              if (redirectTo) {
                dispatch(Actions.onLoginErrorValid(''))
                dispatch(Actions.onLoginRedirectTo(''))
                dispatch(push(redirectTo))
              }
              document.title = 'Online'
              document.body.style = 'background-image: none;'
              // Nav to home
              dispatch(push('/'))
            })
          }
        }
        if (usuario.role === 'ROLE_ADMIN') {
          document.title = 'Online'
          document.body.style = 'background-image: none;'
          if (redirectTo) {
            dispatch(Actions.onLoginErrorValid(''))
            dispatch(Actions.onLoginRedirectTo(''))
            dispatch(push(redirectTo))
          } else {
            dispatch(push('/gestor/dashboard'))
          }
        }
        if (usuario.role === 'ROLE_GESTOR') {
          document.title = 'Online'
          document.body.style = 'background-image: none;'
          if (redirectTo) {
            dispatch(Actions.onLoginErrorValid(''))
            dispatch(Actions.onLoginRedirectTo(''))
            dispatch(push(redirectTo))
          } else {
            dispatch(push('/gestor/dashboard'))
          }
        }
        if (usuario.role === 'ROLE_VENDEDOR') {
          dispatch(Actions.onLoginErrorValid(''))
          dispatch(Actions.onLoginRedirectTo(''))
          dispatch(push('/selecionar-cliente'))
        }
        if (usuario.role === 'ROLE_CRM') {
          dispatch(Actions.onLoginErrorValid(''))
          dispatch(Actions.onLoginRedirectTo(''))
          dispatch(push('/gestor/dashboard_crm'))
        }
        return true
      })
    } else {
      dispatch(Actions.onLoading(false))
    }
    return true
  },
  onLogout: () => (dispatch) => {
    dispatch(Actions.onResetLoginPage())
    dispatch(CarrinhoActions.onResetCarrinho())
    dispatch(ProdutoActions.onResetProduto())
    dispatch(push('/login'))
  },
  onAuthClientValidAction: (usuario) => {
    const { status, clientes = [] } = usuario
    // Status do usuário
    if (status !== 'L') {
      NotificationManager.warning(
        'Seu usuário ainda não foi habilitado para uso, aguarde a liberação.',
        'Atenção',
        8000,
      )
      return false
    }
    // Filiais do clientes
    if (clientes.length === 0) {
      NotificationManager.warning(
        'Seu cadastro está incompleto, ele não possui clientes.',
        'Atenção',
        8000,
      )
      return false
    }
    // Validando status da vilial
    const cliente = clientes.find(f => f.status === 'L' && f.unidades.length > 0)
    if (!cliente) {
      NotificationManager.warning(
        'Sua empresa ainda não foi liberada, aguarde liberação!',
        'Atenção',
        8000,
      )
      return false
    }
    // Filiais possui ao menos uma UNIDADE de compra (Fábrica).
    // if (clientes.reduce((p, o) => p + +o.unidades.length, 0) === 0) {
    if (cliente.unidades.length === 0) {
      NotificationManager.warning(
        'Seu cadastro está incompleto, suas filias não possui UNIDADE de compra.',
        'Atenção',
        8000,
      )
      return false
    }
    return true
  },
  onAuthAdminValidAction: (usuario) => {
    const { status, unidades = [] } = usuario
    // Status do usuário
    if (status !== 'L') {
      NotificationManager.warning(
        'Seu usuário ainda não foi habilitado para uso, aguarde a liberação.',
        'Atenção',
        8000,
      )
      return false
    }
    // Unidades(Filiais) do usuário
    if (unidades.length === 0) {
      NotificationManager.warning(
        'Seu cadastro está incompleto, ele não possui filiais.',
        'Atenção',
        8000,
      )
      return false
    }
    return true
  },
  onAuthVendedorValidAction: (usuario) => {
    const { status /* clientes = [] */} = usuario
    // Status do usuário
    if (status !== 'L') {
      NotificationManager.warning(
        'Seu usuário ainda não foi habilitado para uso, aguarde a liberação.',
        'Atenção',
        8000,
      )
      return false
    }
    // Unidades(Filiais) do usuário
    /*
    if (clientes.length === 0) {
      NotificationManager.warning(
        'Seu cadastro está incompleto, ele não possui filiais.',
        'Atenção',
        8000,
      )
      return false
    }
    */
    return true
  },
  onLoginSuccess: pUsuario => (dispatch) => {
    dispatch({ type: Types.USUARIO, payload: pUsuario })
    dispatch(Actions.onLoadClienteData(pUsuario.filial))
  },
  onLoginError: error => (dispatch) => {
    dispatch({ type: Types.LOADING })
    dispatch({ type: Types.LOGIN_ERROR, payload: error })
  },
  onLoadUserData: pUser => (dispatch) => {
    dispatch({ type: Types.USUARIO, payload: pUser })
  },
  onLoginErrorValid: error => ({
    type: Types.LOGIN_ERROR,
    payload: error,
  }),
  onLoginRedirectTo: payload => ({
    type: Types.LOGIN_REDIRECT_TO,
    payload,
  }),
  onTrocaCliente: payload => (dispatch, getState) => {
    const {
      usuario: { usuario },
    } = getState()
    if (usuario.cliente.id !== payload.id) {
      const smTemplate = dispatch(ConfigActions.getParametro('SM_TEMPLATE', 0))
      const {
        codigo, loja, unidades,
      } = payload
      const unidade = payload.unidades[0]
      const dtoSyncProtheus = {
        token: usuario.auth.token,
        empresaCod: unidade.empresaCod,
        filialCod: unidade.filialCod,
        clienteCod: codigo,
        clienteLoja: loja,
      }
      const roda = async () => {
        Object.assign(usuario, {
          role: 'ROLE_CLIENTE',
          cliente: payload,
          unidade,
          unidades,
          limiteCredito: {
            limite: 0,
          },
        })
        // Reset's
        dispatch(CarrinhoActions.onResetCarrinho())
        dispatch(ProdutoActions.onResetProduto())
        // Atualizando limite de crédito do cliente.
        dispatch(Actions.onLoadLimiteCreditoCliente(usuario))
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(10)
          }, 1000)
        })
        // Atualizando titulos vencidos do cliente
        dispatch(Actions.onLoadTitulosVencidosCliente(usuario))
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(10)
          }, 1000)
        })
        // Atualiza produtos do cliente
        dispatch(ProdutoActions.onLoadProdutosCategoria())
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(10)
          }, 1000)
        })
      }

      if (smTemplate === 0) {
        // GVB
        syncProdutosProtheusApi(dtoSyncProtheus).then(() => {
          roda()
          dispatch(push('/home'))
        })
      }
      if (smTemplate === 1) {
        // MIZU
        syncClienteProtheusApi(dtoSyncProtheus).then(() => {
          roda()
        })
      }
    }
  },
  // Cliente
  onLoadClienteData: pCliente => (dispatch) => {
    dispatch({ type: Types.CLIENTE, payload: pCliente })
  },
  onLoadLimiteCreditoCliente: payload => (dispatch, getState) => {
    let {
      usuario: { usuario = payload },
    } = getState()

    if (!usuario.cliente.codigo && payload.cliente.codigo) {
      usuario = payload
    }

    if (usuario.cliente.codigo) {
      dispatch(Actions.onLoadingLimiteCredito(true))
      // setTimeout(() => {
      const dto = {
        token: usuario.auth.token,
        empresa: usuario.unidade.empresaCod,
        filial: usuario.unidade.filialCod,
        codigo: usuario.cliente.codigo,
        loja: usuario.cliente.loja,
      }
      getLimiteCredito(dto).then((limiteCredito) => {
        dispatch(Actions.onLoadingLimiteCredito(false))
        if (limiteCredito) {
          dispatch(Actions.onFieldUpdate({ limiteCredito }))
        }
      })
      // }, 1000)
    }
  },
  onLoadTitulosVencidosCliente: payload => async (dispatch, getState) => {
    let {
      usuario: { usuario = payload },
    } = getState()
    if (!usuario.cliente.codigo && payload.cliente.codigo) {
      usuario = payload
    }
    const dto = {
      token: usuario.auth.token,
      empresa: usuario.unidade.empresaCod,
      filial: usuario.unidade.filialCod,
      codigo: usuario.cliente.codigo,
      loja: usuario.cliente.loja,
    }
    // getTitulosVencidos(dto).then((vencimentos = {}) => {
    const vencimentos = await getTitulosVencidos(dto)
    const { total = 0 } = vencimentos || {}
    if (total > 0) {
      dispatch(Actions.onFieldUpdate({ titulosVencidos: total }))
      NotificationManager.error(
        `Existe ${total} titulo(s) vencido(s), acesse o menu financeiro para mais detalhes.`,
        'ATENÇÃO:',
        12000,
        true,
      )
    }
    // })
  },
  onUserForgot: () => (dispatch, getState) => {
    const {
      reCaptcha,
      usuario: { email },
    } = getState().usuario
    if (!email) {
      NotificationManager.warning('Você deve informar um usuário válido!', 'Atenção!', 8000)
      return false
    }
    if (!reCaptcha) {
      NotificationManager.warning('reCaptcha inválido!', 'Atenção!', 8000)
      return false
    }
    dispatch(Actions.onLoading(true))
    onUserForgotApi({ email, token: reCaptcha }).then((ret) => {
      dispatch(Actions.onLoading(false))
      if (ret) {
        if (ret.status === 204) {
          dispatch(
            Actions.onUpdateDialogForgot({
              open: true,
              title: 'Sucesso!',
              msg: 'Confira a instrução de recuperação de senha que foi enviada para seu e-mail.',
            }),
          )
        } else {
          ret.json().then((r) => {
            NotificationManager.warning(r.message, 'Usuário não localizado em nossa base de dados.', 8000)
          })
        }
      } else {
        NotificationManager.warning(
          'Ocorreu uma falha tente novamente mais tarde.',
          'Atenção!',
          8000,
        )
      }
    })
    return true
  },
  onUserNewPsw: () => (dispatch, getState) => {
    const {
      reCaptcha,
      formForgot: {
        id, name, token, senha, resenha,
      },
    } = getState().usuario
    if (!senha || !resenha) {
      NotificationManager.warning(
        `${name}, você deve informar uma senha e re-senha válida!`,
        'Atenção!',
        8000,
      )
      return false
    }
    if (!reCaptcha) {
      NotificationManager.warning('reCaptcha inválido!', 'Atenção!', 8000)
      return false
    }
    dispatch(Actions.onLoading(true))
    onUserNewPswApi({
      id,
      senha,
      resenha,
      token,
    }).then((ret) => {
      dispatch(Actions.onLoading(false))
      if (ret) {
        if (ret.status === 204) {
          dispatch(
            Actions.onUpdateDialogForgot({
              open: true,
              title: 'Sucesso!',
              msg: 'Sua senha foi alterada com sucesso, parabéns!',
            }),
          )
          dispatch(Actions.onFieldUpdateFormForgot(initialState.formForgot))
        } else {
          ret.json().then((r) => {
            NotificationManager.warning(r.message, 'Ops!', 8000)
          })
        }
      } else {
        NotificationManager.warning(
          'Ocorreu uma falha tente novamente mais tarde.',
          'Atenção!',
          8000,
        )
      }
    })
    return true
  },
  onAprovaReprovaUsuario: (usuario, status, motivo, actionHandle) => (dispatch, getState) => {
    const {
      usuario: {
        usuario: { auth },
      },
      dialog: { generic },
    } = getState()
    const dto = {
      token: auth.token,
      payload: {
        usuarioId: usuario.id,
        filialId: usuario.unidade.id,
        clienteId: usuario.cliente ? usuario.cliente.id : null,
        status,
        motivo: motivo || generic.motivo,
      },
    }
    onAprovarReprovarUsuarioApi(dto).then((resp) => {
      // run action passed...
      if (_.isFunction(actionHandle)) {
        actionHandle()
      }
      if (resp && resp.status === 204 && status === 'L') {
        NotificationManager.success('O usuário foi APROVADO com sucesso!', 'Sucesso!', 4000)
      }
      if (resp && resp.status === 204 && status === 'B') {
        NotificationManager.success('O usuário foi REPROVADO com sucesso!', 'Sucesso!', 4000)
      }
      if (resp && resp.status !== 204) {
        NotificationManager.warning(resp.message, 'Atenção:', 4000)
      }
    })
  },
}
