import { API_URL } from '..'
import { httpValidState, httpValidErros } from '../../utils/http'

const ref = '/dashboard'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const getEventosTiposSum = ({
  token = '',
  filter: {
    eventoTipoId = '', clienteId = '', filialId = [], de = '', ate = '',
  },
}) => {
  let queryUrl = '?1=1'
  queryUrl += eventoTipoId ? `&eventoTipoId=${eventoTipoId}` : ''
  queryUrl += clienteId ? `&clienteId=${clienteId}` : ''
  queryUrl += filialId ? `&filialId=${filialId.join(',')}` : ''
  queryUrl += de ? `&de=${de}` : ''
  queryUrl += ate ? `&ate=${ate}` : ''
  return fetch(`${API_URL}${ref}/eventos-tipos-sum${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getEventosTiposSumExcelApi = ({
  token = '',
  filter: {
    eventoTipoId = '', clienteId = '', filialId = '', de = '', ate = '',
  },
}) => {
  let queryUrl = '?1=1'
  queryUrl += eventoTipoId ? `&eventoTipoId=${eventoTipoId}` : ''
  queryUrl += clienteId ? `&clienteId=${clienteId}` : ''
  queryUrl += filialId ? `&filialId=${filialId}` : ''
  queryUrl += de ? `&de=${de}` : ''
  queryUrl += ate ? `&ate=${ate}` : ''
  return fetch(`${API_URL}${ref}/eventos-tipos-sum/excel${queryUrl}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      Authorization: `Bearer ${token}`,
    },
  })
    .catch(httpValidErros)
}

export const getEventosTiposSumPositionsApi = ({
  token = '',
  filter: {
    eventoTipoId = '', clienteId = '', filialId = '', de = '', ate = '',
  },
}) => {
  let queryUrl = '?1=1'
  queryUrl += eventoTipoId ? `&eventoTipoId=${eventoTipoId}` : ''
  queryUrl += clienteId ? `&clienteId=${clienteId}` : ''
  queryUrl += filialId ? `&filialId=${filialId}` : ''
  queryUrl += de ? `&de=${de}` : ''
  queryUrl += ate ? `&ate=${ate}` : ''
  return fetch(`${API_URL}${ref}/eventos-tipos-sum/positions${queryUrl}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(httpValidState)
    .catch(httpValidErros)
}
export const getFunilVendasData = ({ token }) => {

  return fetch(`${API_URL}${ref}/graficos/funil-vendas`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(httpValidState)
    .catch(httpValidErros)
}
export const getPieChartVisitasData = ({ token }) => {

  return fetch(`${API_URL}${ref}/graficos/pie-chart-visitas`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getPaperData = ({ token }) => {

  return fetch(`${API_URL}${ref}/graficos/papers-data`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(httpValidState)
    .catch(httpValidErros)
}