import { httpParseJson, httpValidState, httpValidErros } from 'utils/http'
import { API_URL } from 'api'

const ref = '/municipio'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 *
 * @param {String} dto - { token: String , userId: String, fileName: String, file: FormData }
 */
export const municipioApi = ({ token, usuario, fileName, file }) => {
  const payload = { usuario, fileName, file }
  return fetch(`${API_URL}${ref}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload)
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/**
 * @param {String} token - JWT token
 * @param {String} estadoId - ID do estado
 */
export const listMunicipiosByEstadoIdApi = (token, estadoId) => {
  return fetch(`${API_URL}${ref}/by-estado/${estadoId}`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    }
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}



