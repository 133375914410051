import { API_URL } from '..'
import { httpParseJson, httpValidState, httpValidErros } from '../../utils/http'

const refVendedor = '/sync/padrao/vendedor'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}


/*
 * Sync Produtos Padrão
 */
export const syncVendedorClientesERPApi = ({
  token, empresa, filial, tpl, cgc, listclientes,
}) => {
  let queryUrl = `${refVendedor}/clientes?empresa=${empresa}&filial=${filial}`
  queryUrl = tpl ? `${queryUrl}&tpl=${tpl}` : queryUrl
  queryUrl = cgc ? `${queryUrl}&cgc=${cgc}` : queryUrl
  // queryUrl = listclientes ? `${queryUrl}&listclientes=${listclientes}` : queryUrl

  return fetch(`${API_URL}${queryUrl}`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(listclientes),
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}

/*
 * Filtro de Pedidos
 */
export const filterVendedorConsultaPedidosApi = ({
  token = '', params = {}, formFilter = {},
}) => {
  const {
    empresa, filial, tpl = 0, cgc,
  } = params
  let queryUrl = `?tpl=${tpl}`
  queryUrl += empresa ? `&empresa=${empresa}` : ''
  queryUrl += filial ? `&filial=${filial}` : ''
  queryUrl += cgc ? `&cgc=${cgc}` : ''

  return fetch(`${API_URL}/vendedor/consultas/pedidos${queryUrl}`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(formFilter),
  })
    .then(httpParseJson)
    .then(httpValidState)
    .catch(httpValidErros)
}
