import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as crrPush } from 'connected-react-router'
import classNames from 'classnames'
import {
  Typography,
  Card,
  Grid,
  Fab,
  Icon,
  Tooltip,
  TextField,
  InputAdornment,
  IconButton,
  CssBaseline,
  Input,
  Button,
  CircularProgress,
} from '@material-ui/core'

// import { Actions as ConfigActions } from '../../../../store/ducks/config'
import { Actions as ConfigActions } from 'store/ducks/config'
import { Actions as DialogActions } from 'store/ducks/dialog'
import GestorHeaderComp from '../../../../../../components/Gestor/Drawer'
import GestorDrawerComp from '../../../../../../components/Gestor/Drawer'
import TableDataComp from '../../../../../../components/Table'
import useStyles from './styles'
import { getVisitasFilterApi } from 'api/visitas'
import { DialogContentSimples } from 'components/Dialogs/Contents'
import ReactPaginate from 'react-paginate'
import { searchClienteApi } from 'api/cliente'
import { findByIdCrmVistoriasApi } from 'api/crm/vistorias'
import { Carousel } from 'react-responsive-carousel'

const FotosVistoriaTabContent = (props) => {
  const {
    usuario,
    push,
    params
  } = props
  const [payload, setpayload] = useState([])
  const [vistoria, setVistoria] = useState([])


  const classes = useStyles()
  // State
  // Função para buscar os dados por ID
  const [loading, setLoading] = useState(false);

  const fetchDataById = async (token, id) => {
    setLoading(true);
    try {
      const response = await findByIdCrmVistoriasApi({ token: token, id: id });
      setVistoria(response);
      setLoading(false);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar os dados por ID:', error);
      setLoading(false);
      return null;
    }
  };

  useEffect(() => {
    document.title = 'Online - Vistoria Detalhe'
    document.body.style = 'background-image: none;'
    fetchDataById(usuario?.auth?.token, params.id)
  }, [])

  return (
    <div className={classes.root}>
      <CssBaseline />
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <main className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Grid container>
                {/* Menus */}
                <Grid item xs={12} md={12}>
                  {vistoria && vistoria.imagens && vistoria.imagens.length > 0 ? (
                    <Carousel>
                      {vistoria.imagens.map((imagem) => (
                        <Grid item key={imagem.id}>
                          <div style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}>
                            <Button
                              color="primary"
                              className={classes.button}
                              href={imagem.imagem}
                              download={`Imagem_${imagem.id}.jpg`}
                              style={{
                                textDecoration: 'none',
                                marginBottom: '10px',
                                justifyContent: 'left',
                                alignSelf: 'flex-start',
                              }}
                            >
                              Baixar
                            </Button>
                          </div>
                          <img
                            src={imagem.imagem}
                            alt={`Imagem ${imagem.id}`}
                            style={{
                              width: '100%',
                              height: 'auto',
                              maxHeight: '500px',
                              objectFit: 'contain',
                            }}
                          />
                        </Grid>
                      ))}
                    </Carousel>
                  ) : (
                    <div className={classes.noPhotos}>
                      <p>Nenhuma foto disponível</p>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </main>
      )}
    </div>
  );
}

FotosVistoriaTabContent.propTypes = {
  loading: PropTypes.shape().isRequired,
  gestorUsuarios: PropTypes.shape().isRequired,
  push: PropTypes.func.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onAddUsuarioCliente: PropTypes.func.isRequired,
  onDeleteUsuarioCliente: PropTypes.func.isRequired,
  onSearchUsuarioCliente: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  gestorUsuarios: state.gestorUsuarios,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...DialogActions,
    ...ConfigActions,
    push: crrPush,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FotosVistoriaTabContent)
