import { NotificationManager } from 'react-notifications'
import _ from 'lodash'

export const httpParseJson = (res) => {
  if (res) {
    if (res.status >= 400) {
      return res.json()
    }
    if (res.status !== 201 && res.status !== 204) {
      return res.json()
    }
  }
  return res
}

export const httpValidState = (res, silent = false) => {
  const { log } = console
  if (silent) return res
  switch (res.status) {
    case 200:
    case 201:
    case 202:
      log('success')
      break
    case 400:
    case 401:
    case 403:
    case 404:
      if (res.errorCode === 9001) {
        NotificationManager.warning(res.message, res.error, 8000)
      } else if (res.errorCode === 9002) {
        NotificationManager.warning(res.message, res.error, 8000)
        setTimeout(() => {
          window.location.href = '/token-expirado'
        }, 3000)
      } else {
        if (_.isEmpty(res.fields)) {
          NotificationManager.warning(`${res.message || res.error}`, 'Atenção:', 8000)
        } else {
          Object.keys(res.fields).forEach((key) => {
            const valueField = res.fields[key]
            if (key === 'msgError') {
              NotificationManager.warning(valueField, 'Atenção:', 8000)
            } else if (key === 'msgInfo') {
              NotificationManager.info(valueField, 'Infomativo:', 8000)
            } else {
              NotificationManager.info(valueField, 'Validação:', 10000)
            }
          })
        }
        log(`Server error, ${res.status} =>`, res)
      }
      return null
    case 500:
    case 502:
    case 503:
      NotificationManager.warning(res.message, res.error, 8000)
      log('Server error 500, try again', res)
      return null
    default:
      log('unhandled:', res)
      break
  }
  return res
}

export const httpValidErros = ({ message }, silent = false) => {
  let msg = ''
  switch (message.trim()) {
    case 'Failed to fetch':
      msg = 'O servidor está off-line!'
      break
    default:
      msg = message
  }
  if (silent) return { message: msg }
  NotificationManager.warning(msg, 'Atenção!', 8000)
  return null
  // throw new Error(msg)
}
