import React, { useState, useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import PropTypes from 'prop-types'
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import _ from 'lodash'

import { filterCrmOportunidadeApi, filterCrmOportunidadeItensApi } from 'api/crm/oportunidade'
import { filterCrmAgentesApi } from 'api/crm/agentes'
import { addAgendaApi } from 'api/agenda'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'
import { DtFormat, moneyFormat } from 'utils'
import Visibility from 'components/Visibility'
import useStyles from './styles'
import { findAllAgendaTipoApi } from 'api/agenda_tipo'

const DialogAddAgendamento = (props) => {
  const {
    token,
    open,
    payload,
    onSave,
    onClose,
  } = props

  const [isSavingForm, setSavingForm] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [form, setForm] = useState()
  const [agentes, setAgentes] = useState([])
  const [oportunidades, setOportunidades] = useState([])
  const [oportunidadeItens, setOportunidadeItens] = useState([])
  const [agendaTipos, setAgendaTipos] = useState([])
  const [agendaTipoId, setAgendaTipoId] = useState('');


  const classes = useStyles()

  const onUpdateField = (data) => {
    setForm(s => ({ ...s, ...data }));
  }
  const onCloseDialog = () => {
    if (_.isFunction(onClose)) {
      setOpen(false)
      onClose()
    }
  }
  const onAddAgendamento = () => {
    if (!form.id) {
      if (form.prospectId && form.dateAgenda && form.prospectId && form.crmOportunidadeId && form.crmOportunidadeItemId) {
        setSavingForm(true)
        addAgendaApi({
          token,
          payload: {
            ...form,
          },
        })
          .then((resp) => {
            const { status } = resp ?? {}
            if (status === 200) {
              NotificationManager.success(
                'Adicionado na agenda com sucesso!',
                'Atenção',
                8000,
              )
              onCloseDialog()
              if (_.isFunction(onSave)) {
                onSave(form, () => setSavingForm(true), () => setSavingForm(false))
              }
            }
          }).finally(() => {
            setSavingForm(false)
            onCloseDialog()
            if (_.isFunction(onSave)) {
              onSave(form, () => setSavingForm(true), () => setSavingForm(false))
            }
          })
      }
      else {
        NotificationManager.warning(
          'Selecionar todos os campos!',
          'Atenção',
          8000,
        )
      }
    }
  }
  const onLoadAgentes = () => {
    // Verifica se "form.crmOportunidadeItemId" existe.
    let item = null;
    if (form?.crmOportunidadeItemId) {
      // Filtra o "oportunidadeItens" com base no "form.crmOportunidadeItemId".
      // A função "filter" retorna um array, então utilizamos [0] para pegar o primeiro item, caso exista.
      item = oportunidadeItens.filter((it) => it.id === form.crmOportunidadeItemId)[0];
    }

    // Certifica-se de que "item" existe antes de acessar "itemPedidoStatusId".
    if (item) {
      // "filterCrmAgentesApi" é chamado com diferentes "agenteTypeChar" dependendo do "itemPedidoStatusId".
      // Observação: Utilizamos "===" para verificar igualdade, não "=" que é usado para atribuição.
      if (item.itemPedidoStatusId === 1) {
        filterCrmAgentesApi({
          token,
          query: {
            dateAgendaFree: form?.dateAgenda,
            agenteTypeChar: "T"
          }
        }).then((resp) => {
          const { data = [] } = resp || {};
          setAgentes(data);
        });
      }
      else if (item.itemPedidoStatusId === 15) {
        filterCrmAgentesApi({
          token,
          query: {
            dateAgendaFree: form?.dateAgenda,
            agenteTypeChar: "E"
          }
        }).then((resp) => {
          const { data = [] } = resp || {};
          setAgentes(data);
        });
      }
    }
  };
  const onLoadOportunidades = () => {
    const prospectId = form?.prospectId
    filterCrmOportunidadeApi({ token, query: { prospectId, cancelado: 'false', agendaTipoId, statusIds: [1, 15] } })
      .then((resp) => {
        const { data = [] } = resp ?? {}
        setOportunidades(data)
      })
  }
  const onLoadOportunidadesItens = () => {
    const oportunidadeId = form?.crmOportunidadeId
    const prospectId = form?.prospectId
    filterCrmOportunidadeItensApi({ token, query: { prospectId, oportunidadeId, cancelado: 'false', statusIds: [1, 15] } })
      .then((resp) => {
        const { data = [] } = resp ?? {}
        setOportunidadeItens(data)
      })
  }
  // * Effects
  useEffect(() => {
  }, [])

  useEffect(() => {
    if (form?.dateAgenda) {
      onLoadOportunidades()
    }
  }, [form?.dateAgenda, agendaTipoId])

  useEffect(() => {
    if (form?.crmOportunidadeItemId) {
      onLoadAgentes()
    }
  }, [form?.crmOportunidadeItemId])

  useEffect(() => {
    if (form?.crmOportunidadeId) {
      onLoadOportunidadesItens()
    } else {
      setOportunidadeItens([])
    }
  }, [form?.crmOportunidadeId])

  useEffect(() => {
    setForm(payload)
  }, [payload])

  useEffect(() => {
    setOpen(open)
  }, [open])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      onClose={onCloseDialog}
      aria-labelledby="dialog-crm-atendimento"
    >
      <DialogTitle id="dialog-crm-atendimento-title">Data agendamento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row">
          {/* Data Agendamento */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={form?.disabled ?? true}
              id="dateAgenda"
              label="Data Agendamento"
              type="datetime-local"
              value={form?.dateAgenda ?? ''}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => onUpdateField({ dateAgenda: e?.target?.value })}
            />
          </Grid>
          {/*  */}
          {/* Oportunidade(s) */}
          <Grid item xs={12}>
            <Visibility show={!(form?.disabled ?? false)}>
              <FormControl disabled={form?.disabled ?? true} fullWidth className={classes.formControl}>
                <InputLabel shrink htmlFor="dialog-ag-oportunidades">
                  {`Oportunidade(s): ${_.size(oportunidades ?? [])} `}
                </InputLabel>
                <Select
                  fullWidth
                  value={form?.crmOportunidadeId ?? ''}
                  onChange={e => onUpdateField({
                    crmOportunidadeId: e?.target?.value,
                  })}
                  inputProps={{
                    name: 'dialog-ag-oportunidades',
                    id: 'dialog-ag-oportunidades',
                  }}
                >
                  <MenuItem value="">SELECIONE</MenuItem>
                  {oportunidades?.map(o => (
                    <MenuItem
                      key={o?.id}
                      value={o?.id}
                    >
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          {`Oportunidade: ${o?.id}, Emissão: ${DtFormat(o?.emissao)}, Por: ${o?.usuarioNome ?? ''}`}
                        </Grid>
                        <Grid item>
                          {`Total: ${moneyFormat(o?.total ?? 0)}, Orçamento: ${o?.orcamento || 'NÃO GERADO'}`}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Visibility>
            <Visibility show={(form?.disabled ?? false)}>
              <TextField
                fullWidth
                disabled
                label="Oportunidade"
                value={`Nº: ${form?.crmOportunidadeId ?? ''}, Emissão: ${DtFormat(form?.crmOportunidadeEmissao ?? '')}, Total: ${moneyFormat(form?.crmOportunidadeTotal ?? '')}`}
                InputLabelProps={{
                  // shrink: true,
                }}
              />
            </Visibility>
          </Grid>
          {/* Item da Oportunidade(s) */}
          <Grid item xs={12}>
            <Visibility show={!(form?.disabled ?? false)}>
              <FormControl disabled={form?.disabled ?? true} fullWidth className={classes.formControl}>
                <InputLabel shrink htmlFor="dialog-ag-oportunidade-item">
                  {`Item(ns) da Oportunidade: ${_.size(oportunidadeItens ?? [])} `}
                </InputLabel>
                <Select
                  fullWidth
                  value={form?.crmOportunidadeItemId ?? ''}
                  onChange={e => onUpdateField({
                    crmOportunidadeItemId: e?.target?.value,
                  })}
                  inputProps={{
                    name: 'dialog-ag-oportunidade-item',
                    id: 'dialog-ag-oportunidade-item',
                  }}
                >
                  <MenuItem value="">SELECIONE</MenuItem>
                  {oportunidadeItens?.map(o => (
                    <MenuItem
                      key={o?.id}
                      value={o?.id}
                    >
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          {`Item: ${o?.produtoDescricao}, Valor: ${moneyFormat(o?.valor)}`}
                        </Grid>
                        <Grid item>
                          {`Condição: ${o?.pagamentoCondicaoDescricao ?? ''}, Forma: ${o?.pagamentoFormaDescricao ?? ''}`}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            </Visibility>
            <Visibility show={(form?.disabled ?? false)}>
              <TextField
                fullWidth
                disabled
                label="Item da Oportunidade"
                value={`Nº: ${form?.crmOportunidadeItemId ?? ''}, Produto: ${form?.crmOportunidadeItemProdutoSku ?? ''}-${form?.crmOportunidadeItemProdutoDescricao ?? ''}`}
                InputLabelProps={{
                  // shrink: true,
                }}
              />
            </Visibility>
          </Grid>
          {/* Agente(s) */}
          <Grid item xs={12}>
            <Visibility show={!(form?.disabled ?? false)}>
              <FormControl disabled={form?.disabled ?? true} fullWidth className={classes.formControl}>
                <InputLabel shrink htmlFor="dialog-ag-crmAgentesId">
                  {`Agente(s): ${_.size(agentes ?? [])} `}
                </InputLabel>
                <Select
                  fullWidth
                  value={form?.crmAgentesId ?? ''}
                  onChange={e => onUpdateField({
                    crmAgentesId: e?.target?.value,
                  })}
                  inputProps={{
                    name: 'dialog-ag-crmAgentesId',
                    id: 'dialog-ag-crmAgentesId',
                  }}
                >
                  <MenuItem value="">SELECIONE</MenuItem>
                  {agentes?.map(a => (
                    <MenuItem
                      key={a?.id}
                      value={a?.id}
                    >
                      {`${a?.code} - ${a?.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Visibility>
            <Visibility show={(form?.disabled ?? false)}>
              <TextField
                fullWidth
                disabled
                label="Agente"
                value={form?.crmAgentesName ?? ''}
                InputLabelProps={{
                  // shrink: true,
                }}
              />
            </Visibility>
          </Grid>
          {/* Titulo */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={form?.disabled ?? true}
              id="title"
              label="Titulo"
              variant="outlined"
              value={form?.title ?? ''}
              onChange={e => onUpdateField({ title: e?.target?.value?.toUpperCase() })}
            />
          </Grid>
          {/* Observação */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={form?.disabled ?? true}
              id="observation"
              label="Observação"
              multiline
              minRows={6}
              maxRows={10}
              variant="outlined"
              value={form?.observation ?? ''}
              onChange={e => onUpdateField({ observation: e?.target?.value?.toUpperCase() })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog} color="secondary">
          Cancelar
        </Button>
        <ButtonLoadingComp loading={isSavingForm} onClick={onAddAgendamento} color="primary" autoFocus>
          Salvar
        </ButtonLoadingComp>
      </DialogActions>
    </Dialog>
  )
}

DialogAddAgendamento.propTypes = {
  // loading: PropTypes.bool,
  token: PropTypes.string.isRequired,
  open: PropTypes.bool,
  payload: PropTypes.shape().isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

DialogAddAgendamento.defaultProps = {
  // loading: false,
  open: false,
}

export default DialogAddAgendamento
